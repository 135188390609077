<div class="content">
  <div class="content-panel-overflow">
    <h2 translate>page.controles.controles.title</h2>

    <form [formGroup]="formSearchControles" #form="ngForm" (ngSubmit)="onSubmit()">
      <div class="form-row">
        <div class="form-group col-md-5 row">
          <label for="nature" class="col-md-5 col-form-label" translate>page.controles.controles.search.nature</label>
          <div class="col-md-6">
            <select id="nature" formControlName="nature" class="form-control" (change)="changeNature()"
              [ngClass]="{'is-invalid': form.submitted && nature.invalid}">
              <option [value]="null" hidden></option>
              <option *ngFor="let nature of refNatures" [ngValue]="nature.id">{{nature.libelle}}</option>
            </select>
          </div>
        </div>

        <div class="form-group col-md-5 row">
          <label for="statuts" class="col-md-5 col-form-label" translate>page.controles.controles.search.statut</label>
          <div class="col-md-6">
            <app-multiselect id="statuts" controlName="statuts" [items]="refStatuts" bindLabel="libelle"
              maxWidth="initial">
            </app-multiselect>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-5 row">
          <label for="types" class="col-md-5 col-form-label" translate>page.controles.controles.search.type</label>
          <div class="col-md-6">
            <app-multiselect id="types" controlName="types" [items]="refTypes" bindLabel="libelle" maxWidth="initial">
            </app-multiselect>
          </div>
        </div>

        <div class="form-group col-md-5 row">
          <label for="types" class="col-md-5 col-form-label" translate>page.controles.controles.search.agent</label>
          <div class="col-md-6">
            <app-multiselect id="agents" controlName="agents" [items]="animateurs" bindLabel="nomComplet"
              maxWidth="initial">
            </app-multiselect>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-5 row">
          <label for="debutDateControle" class="col-md-5 col-form-label"
            translate>page.controles.controles.search.dateControleEntre</label>
          <div class="col-md-6 input-group">
            <input id="debutDateControle" ngbDatepicker #debutDatepickerControle="ngbDatepicker"
              formControlName="debutDateControle" class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="debutDatepickerControle.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group col-md-5 row">
          <label for="finDateControle" class="col-md-5 col-form-label"
            translate>page.controles.controles.search.et</label>
          <div class="col-md-6 input-group">
            <input id="finDateControle" ngbDatepicker #finDatepickerControle="ngbDatepicker"
              formControlName="finDateControle" class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="finDatepickerControle.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-5 row">
          <label class="col-md-5 col-form-label" translate>page.controles.controles.search.numeroControle</label>
          <div class="col-md-6">
            <input type="text" formControlName="numeroControle" class="form-control" />
          </div>
        </div>
        <div class="form-group col-md-5 row" *ngIf="estFiltreOrganoleptique">
          <label class="col-md-5 col-form-label" translate>page.controles.controles.search.numeroCommission</label>
          <div class="col-md-6">
            <input type="text" formControlName="numeroCommission" class="form-control" />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-5 row" *ngIf="estFiltreOrganoleptique||estFiltreAnalytique">
          <label class="col-md-5 col-form-label" translate>page.controles.controles.search.numeroEchantillon</label>
          <div class="col-md-6">
            <input type="text" formControlName="numeroEchantillon" class="form-control" />
          </div>
        </div>
        <div class="form-group col-md-5 row" *ngIf="estFiltreOrganoleptique||estFiltreAnalytique">
          <label class="col-md-5 col-form-label" translate>page.controles.controles.search.numeroCVI</label>
          <div class="col-md-6">
            <input type="text" formControlName="numeroCVI" class="form-control" />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-5 row" *ngIf="estFiltreAnalytique">
          <label for="laboratoires" class="col-md-5 col-form-label"
            translate>page.controles.controles.search.laboratoire</label>
          <div class="col-md-6">
            <app-multiselect id="laboratoires" controlName="laboratoires" [items]="refLaboratoires" bindLabel="libelle"
              maxWidth="initial">
            </app-multiselect>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-10 row">
          <button type="submit" class="btn btn-primary rechercher"
            *hasRole="'DECLARATION_CONTROLE_LECTURE';elseDisable">Rechercher</button>
        </div>
      </div>
    </form>
    <h2>
      <div *ngIf="estRechercheOrganoleptique" >
        <span>
          <button style="position:relative;" type="button" class="btn btn-primary" (click)="telechargerDocument()"
            translate>page.commissions.edit.telechargerDocuments</button>
          <!--<button style="position:relative;margin-left: 1em;" type="button" class="btn btn-primary"
            (click)="envoyerDocumentsMail()" translate>page.commissions.edit.envoyerDocumentsMail</button>-->
        </span>
      </div>
    </h2>

    <ng-container *hasRole="'DECLARATION_CONTROLE_LECTURE'; else aucunDroits">
      <table class="table" *ngIf="controles?.length > 0; else aucunControle">
        <thead>
          <th translate>page.controles.controles.table.numeroControle</th>
          <th translate>page.controles.controles.table.raisonSociale</th>
          <th translate *ngIf="estRechercheAnalytique">page.controles.controles.table.type</th>
          <th translate>page.controles.controles.table.dateControle</th>
          <th translate *ngIf="estRechercheOrganoleptique">page.controles.controles.table.logt_lot</th>
          <th translate *ngIf="estRechercheOrganoleptique">page.controles.controles.table.volume</th>
          <th translate *ngIf="estRechercheAnalytique">page.controles.controles.table.agent</th>
          <th translate>page.controles.controles.table.produit</th>
          <th translate *ngIf="!estRechercheOrganoleptique">page.controles.controles.table.activite</th>
          <th translate *ngIf="estRechercheOrganoleptique || estRechercheAnalytique">
            page.controles.controles.table.numeroEchantillon</th>
          <th translate *ngIf="estRechercheOrganoleptique">page.controles.controles.table.numeroCommission</th>
          <th translate *ngIf="estRechercheOrganoleptique">page.controles.controles.table.numeroAnonymat</th>
          <th translate *ngIf="estRechercheAnalytique">page.controles.controles.table.laboratoire</th>
          <th translate *ngIf="estRechercheAnalytique">page.controles.controles.table.dateEnvoiLaboratoire</th>
          <th translate>page.controles.controles.table.resultat</th>
          <th></th>
        </thead>
        <tbody>
          <tr *ngFor="let controle of controles">
            <td>{{ controle.numeroControle}}</td>
            <td>{{ controle.operateur?.raisonSociale }}<br/>{{ getCVI(controle) }}</td>
            <td *ngIf="estRechercheAnalytique">{{ controle.typeControle?.libelle }}</td>
            <td>{{ controle.dateControle | date:'dd/MM/yyyy'}}</td>
            <td *ngIf="estRechercheOrganoleptique"> {{controle.logement}}<br>{{controle.lot}}</td>
            <td *ngIf="estRechercheOrganoleptique">{{controle.volume}}</td>
            <td *ngIf="estRechercheAnalytique">{{ getNomAgent(controle) }}</td>
            <td>{{ getLibelleProduit(controle)?getLibelleProduit(controle)[0]:'' }}
              <fa-icon icon="ellipsis-h" *ngIf="getLibelleProduit(controle).length>1" [ngbTooltip]="listeProduit">
              </fa-icon>
            </td>
            <td *ngIf="!estRechercheOrganoleptique">{{ getLibelleActivite(controle) }}</td>
            <td *ngIf="estRechercheOrganoleptique || estRechercheAnalytique">{{ controle.numeroEchantillon}}</td>
            <td *ngIf="estRechercheOrganoleptique">{{ controle.numeroCommission}} <br/>{{ controle.numeroJury}}</td>
            <td *ngIf="estRechercheOrganoleptique">{{ controle.numeroAnonymat}}</td>
            <td *ngIf="estRechercheAnalytique">{{ controle.libelleLaboratoire}}</td>
            <td *ngIf="estRechercheAnalytique">{{ controle.dateEnvoiLaboratoire | date:'dd/MM/yyyy'}}</td>
            <td *ngIf="controle.statut?.code === 'TERMINE'">{{ controle.nbManquement}} manquement(s)</td>
            <td *ngIf="controle.statut?.code === 'EN_COURS' || controle.statut?.code === 'PREVU'" class="padding-top-15">
              <fa-icon icon="check-circle" title="Conforme" (click)="updateStatut(controle)" class="green-icon">
              </fa-icon>
              <fa-icon icon="minus-circle" title="Non conforme" (click)="addConstat(controle)" class="red-icon">
              </fa-icon>
            </td>
            <!-- <td>
              <button type="button" class="btn btn-outline-secondary btn-add">
                <fa-icon icon="download" class="primary-icon" (click)="telechargerDocument(controle)"
                  title="Télécharger le document"></fa-icon>
              </button>
            </td> -->

            <ng-template #listeCahier>
              <ul>
                <li *ngFor="let cahier of getLibelleCahier(controle)">{{ cahier }}</li>
              </ul>
            </ng-template>

            <ng-template #listeProduit>
              <ul>
                <li *ngFor="let produit of getLibelleProduit(controle)">{{ produit }}</li>
              </ul>
            </ng-template>
          </tr>
        </tbody>
      </table>
      <app-pagination-bar [pagination]="pagination"></app-pagination-bar>
    </ng-container>
  </div>
</div>

<ng-template #aucunControle>
  <div class="alert alert-warning alert-block" translate>
    <fa-icon icon="exclamation"></fa-icon>
    page.controles.controles.search.aucunControle
  </div>
</ng-template>

<ng-template #aucunDroits>
  <div class="alert alert-warning alert-block" translate>
    <fa-icon icon="exclamation"></fa-icon>
    page.controles.controles.search.aucunDroits
  </div>
</ng-template>
