import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ControlesService } from 'src/app/data/declaration/services/controles/controles.service';
import { Controle } from 'src/app/data/declaration/models/controle.model';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { ManquementResultat } from 'src/app/data/declaration/models/ManquementResultat.model';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { StatutManquement } from 'src/app/data/declaration/models/statut-manquement.model';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { forkJoin } from 'rxjs';
import { ManquementsService } from 'src/app/data/declaration/services/manquements/manquements.service';
import { Manquement } from 'src/app/data/declaration/models/manquement.model';
import { SaisieLot } from 'src/app/data/declaration/interfaces/saisie-lot.interface';
import { SaisirNotificationComponent } from './saisir-notification/saisir-notification.component';
import { SaisirConstatComponent } from '../../../suivi-controles/modals/saisir-constat/saisir-constat.component';
import { NgbActiveModal, NgbTabChangeEvent, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { SaisirDecisionComponent } from './saisir-decision/saisir-decision.component';
import { SaisirRecoursComponent } from './saisir-recours/saisir-recours.component';
import { ControleDetails } from 'src/app/data/declaration/models/controle-details.model';

@Component({
  selector: 'app-edit-manquement',
  templateUrl: './edit-manquement.component.html',
  styleUrls: ['./edit-manquement.component.scss']
})
export class EditManquementComponent implements OnInit {
  /** Onglets */
  @ViewChild('constat') constat: SaisirConstatComponent;
  @ViewChild('notification') notification: SaisirNotificationComponent;
  @ViewChild('decision') decision: SaisirDecisionComponent;
  @ViewChild('recours') recours: SaisirRecoursComponent;
  private ongletSelectionne = 'constat';
  private switchEnCours = false;
  /** Fin des onglets */
  @Input() public manquement: ManquementResultat;
  // Sert à afficher un message pendant le chargement de la fenêtre surgissante
  public chargementEnCours = true;
  public controle: Controle;
  public manquementDetail: Manquement;
  // Sert de valeur pour l'onglet du constat afin d'enclencher le mode édition
  public lot: SaisieLot<Manquement[]>;
  public refStatuts: StatutManquement[];
  private idOrganisme: number;

  public controleDetails: ControleDetails;
  showControlDetails: boolean;


  constructor(
    private readonly controlesService: ControlesService,
    private readonly manquementsService: ManquementsService,
    private readonly loaderService: AdvBootstrapLoaderService,
    public readonly modal: NgbActiveModal,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly referentielService: ReferentielService,
    private readonly alertService: AdvBootstrapModalService
  ) {
    this.showControlDetails = false;
  }

  ngOnInit() {
    // Récupérer l'ID de l'organisme de session
    this.idOrganisme = SessionContext.get('idOrganisme');
    // Charger le contrôle et le manquement
    this.loadData();
  }

  /** Charge le contrôle puis le détail du manquement à modifier */
  private loadData(): void {
    // Charger le contrôle
    forkJoin(
      this.controlesService.getControle(this.idOrganisme, this.manquement.idControle),
      this.referentielService.getReferentiel()
    ).subscribe(([controle, referentiel]) => {
      [this.controle, this.refStatuts] = [controle, referentiel.statutsManquement];
      this.controlesService.getControleDetails(this.controle).subscribe(controleDetails => {
        this.controleDetails = controleDetails;
      });
      this.manquementsService.getManquement(
        this.idOrganisme,
        this.manquement.id,
        this.controle.nature.id,
        this.controle.objet.id
      ).pipe(
        this.loaderService.operator()
      ).subscribe(manquement => {
        this.manquementDetail = manquement;
        // Passer un lot pour mettre la fenêtre surgissante en mode édition
        this.lot = {
          indexAModifier: 0,
          lot: [this.manquementDetail],
          saisieTerminee: false
        } as SaisieLot<Manquement[]>;
        this.chargementEnCours = false;
      });
    });
  }

  /** Ferme la fenête surgissante de modification du manquement */
  public onClose(): void {
    this.modal.dismiss();
  }

  /** Appel la méthode de modification de l'onglet ouvert */
  public onSave(): void {
    switch (this.ongletSelectionne) {
      case 'constat':
        this.constat.onEdit().then((result) =>
          this.displayToastMessage(result, 'page.controles.manquement.edit.constatOk')
        );
        break;
      case 'notification':
        this.notification.onEdit().then((result) =>
          this.displayToastMessage(result, 'page.controles.manquement.edit.notificationOk')
        );
        break;
      case 'decision':
        this.decision.onEdit().then((result) =>
          this.displayToastMessage(result, 'page.controles.manquement.edit.decisionOk')
        );
        break;
      case 'recours':
        this.recours.onEdit().then((result) =>
          this.displayToastMessage(result, 'page.controles.manquement.edit.recoursOk')
        );
        break;
    }
  }

  /**
   * Détecte si le formulaire de l'onglet courant a subit des modifications et affiche
   * un message de prévention à l'utilisateur
   * @param $event L'évènement de changement d'onglet
   * @param tabset La directive Bootstrap d'onglets
   */
  public onTabChange($event: NgbTabChangeEvent, tabset: NgbTabset): void {
    const currentForm = this.getCurrentViewChildForm();
    // Vérifier si le changement d'onglet est provoqué par le tabset.select()
    // Ou s'il y a eut des modifications dans l'onglet courant
    if (this.switchEnCours) {
      // Laisser le changement d'onglet se faire
      this.switchEnCours = !this.switchEnCours;
    } else if (currentForm && currentForm.touched) {
      // Bloque le changement d'onglet
      $event.preventDefault();

      // Afficher une fenêtre surgissante de confirmation pour changer d'onglet
      this.alertService.confirm(
        this.translate.instant(`page.controles.manquement.edit.avertissementModif`),
        this.translate.instant(`page.controles.manquement.edit.avertissementTitre`), {
        cancelText: this.translate.instant(`page.controles.manquement.edit.avertissementCancel`),
        submitText: this.translate.instant(`page.controles.manquement.edit.avertissementConfirm`)
      }
      ).then(() => {
        // Prévenir le cas du onTabChange provoqué par le tabset.select()
        this.switchEnCours = !this.switchEnCours;
        // Aller sur l'onglet si l'utilisateur a confirmé
        tabset.select($event.nextId);
        // Changer le nom de l'onglet courant
        this.ongletSelectionne = $event.nextId;
      }, () => { $event.preventDefault(); });
    } else {
      // Gérer le cas du changement d'onglet standard
      this.ongletSelectionne = $event.nextId;
    }
  }

  /**
   * Retourne le formulaire affiché dans l'onglet courant
   * @returns FormGroup
   */
  private getCurrentViewChildForm(): FormGroup {
    switch (this.ongletSelectionne) {
      case 'constat': return this.constat.formConstat; break;
      case 'notification': return this.notification.formNotification; break;
      case 'decision': return this.decision.formDecision; break;
      case 'recours': return this.recours.formRecours; break;
      default: return this.constat.formConstat;
    }
  }

  /**
   * Affiche la ressource textuelle passée en paramètre si le résultat est vrai
   * @param result Booléen à vrai ou faux
   * @param message Le chemin vers la ressource
   */
  private displayToastMessage(result: boolean, message: string): void {
    if (result) {
      this.translate.get(message).subscribe(traduction =>
        this.toastr.success('', traduction)
      );
    }
  }

  changeManquementStatut(manquement: ManquementResultat, statut: StatutManquement) {
    this.manquementsService.modifierStatutManquement(this.idOrganisme, manquement, statut.id).subscribe(
      () => manquement.statut = statut
    );

  }
}
