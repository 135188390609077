import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Recolte } from '../../models/recolte.model';
import { SaisieRecolte } from '../../models/saisie-recolte.model';
import { Estimation } from '../../models/estimation.model';
import { RecolteEspeceQuantite } from '../../models/recolte-espece-quantite.model';

@Injectable({
  providedIn: 'root'
})
export class RecoltesService {

  recoltes: Recolte[] = [];

  constructor(protected readonly http: HttpClient,
    protected readonly mapper: JsonMapperService
  ) { }

  /**
   * appel back récupération des récoltes d'une entreprise
   * @param idEntreprise 
   * @param idCampagne 
   * @returns 
   */
  getRecoltes(idEntreprise: number, idCampagne: number): Observable<SaisieRecolte> {
    return this.http.get<object[]>(`/api/declaration/private/recolte?idEntreprise=${idEntreprise}&idCampagne=${idCampagne}`
    ).pipe(
      map(response => this.mapper.deserializeObject(response, SaisieRecolte)),
    );
  }

  /**
   * Appel API pour charger les déclarations de récoltes
   * @returns Un observable contenant une collection de {@link DeclarationRecolte}
   */
  public getAllDeclarationsRecoltes(idCampagne?: number): Observable<Array<SaisieRecolte>> {
    let url: string = (idCampagne != null && idCampagne != -1) 
      ? `/api/declaration/private/suivi-recolte?idCampagne=${idCampagne}`
      : '/api/declaration/private/suivi-recolte';
    return this.http.get<Array<object>>(url).pipe(
    //return this.http.get<Array<object>>('/api/declaration/private/suivi-recolte').pipe(
      map((recoltes: Array<object>) => this.mapper.deserializeArray(recoltes, SaisieRecolte))
    );
  }

  /**
   * Appel API pour charger les estimations de récoltes de la campagne en cours
   * @returns Un observable contenant une collection de {@link Estimation}
   */
  public getEstimationsRecoltes(): Observable<Array<Estimation>> {
    return this.http.get<Array<object>>('/api/declaration/private/estimations-recoltes').pipe(
      map((estimations: Array<object>) => this.mapper.deserializeArray(estimations, Estimation))
    );
  }

  /**
   * appel back sauvegarde des récoltes d'une entreprise
   * @param entrepriseId 
   * @param campagneId 
   * @param recoltes 
   * @param estDefinitive 
   * @param commentaire 
   * @returns 
   */
  public saveRecoltes(entrepriseId: number, campagneId: number, recoltes: Recolte[], estDefinitive: boolean, commentaire: string): Observable<void> {
    let saisieRecolte = new SaisieRecolte();
    saisieRecolte.estDefinitive = estDefinitive;
    saisieRecolte.recoltes = recoltes.filter(saisie => !saisie.isTotal);
    saisieRecolte.idRefCampagne = campagneId;
    saisieRecolte.idEntreprise = entrepriseId;
    saisieRecolte.commentaire = commentaire;
    return this.http.post<void>(`/api/declaration/private/recolte`, saisieRecolte);
  }

  /**
   * appel back récupération des récoltes par espèces par entreprise d'une campagne
   * @param idCampagne 
   * @returns 
   */
   getRecoltesEspecesEntreprises(idCampagne: number): Observable<Array<RecolteEspeceQuantite>> {
    return this.http.get<Array<object>>(`/api/declaration/private/recoltes/campagnes/${idCampagne}`
    ).pipe(
      map((recoltes: Array<object>) => this.mapper.deserializeArray(recoltes, RecolteEspeceQuantite)),
    );
  }

}