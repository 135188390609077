import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { TourneeOperateurEdition } from './tournee-operateur-edition.model';
import { TourneeMouvementEdition } from './tournee-mouvement-edition.model';

@JsonObject
export class TourneeSiteEdition {
    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('nom', String)
    @Optional
    nom: string;

    @JsonProperty('adresse', String)
    @Optional
    adresse: string;

    @JsonProperty('codePostal', String)
    @Optional
    codePostal: string;

    @JsonProperty('ville', String)
    @Optional
    ville: string;

    @JsonProperty('telephone', String)
    @Optional
    telephone: string;

    @JsonProperty('operateur', TourneeOperateurEdition)
    operateur: TourneeOperateurEdition;

    @JsonProperty('lots', [TourneeMouvementEdition])
    @Optional
    lots: TourneeMouvementEdition[];
    
}