<div class="row mt-4 pt-4 gloabl-font-size dashboard-main-div">

  <div class="col-lg-12 spacing">
    <div class="row">
      <div class="col-lg-4">
        <div class="cards  border-radius text-center background-secondary search-bloc-height">
          <div class="text-left">
            <div class=" border-radius pt-4 text-center">
              <fa-icon icon="users" class="users-icon"></fa-icon><br />
              <strong class="users-number">{{nombretotalAdherents}}</strong>
              <h5 class="user-title" translate>page.accueil.anpp-organisme.producteurs</h5>
            </div>
            <div class="input-group pl-4  p-4">
              <app-search-adherent-actif-bar style="width: 100%;" (newItemEvent)="searchEntreprise($event)">
              </app-search-adherent-actif-bar>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">

        <div class="shadow pt-2  pb-0 border-radius text-center echarts-card">
          <h5><b translate>page.accueil.anpp-organisme.vergersEcoresponsables.title-chart</b></h5>
          <div *ngIf="graphValues && graphValues.length > 0" id="main" class="echarts-height" echarts
            [options]="chartVerger"></div>
          <div *ngIf="!graphValues || graphValues.length == 0">
              <h5 ><span class="badge badge-green badge-pill"
                translate>page.accueil.pas-donnee</span></h5>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <ul class="list-group  border-radius text-left shadow ">
          <li class="list-group-item text-center">
            <h5><b translate>page.accueil.anpp-organisme.inventaires.title</b></h5>
            <small translate>page.accueil.anpp-organisme.inventaires.subtitle</small>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center"
            *ngFor="let inventaire of inventairesVerger">
            <b>{{inventaire.raisonSocialeEntreprise}} : {{inventaire.dateDeclaration | date:'dd/MM/yyyy'}}</b>
          </li>
          <li class="list-group-item d-flex justify-content-center align-items-center" *ngIf="!inventairesVerger">
            <h5><span class="badge badge-green badge-pill" translate>page.accueil.pas-donnee</span></h5>
          </li>
        </ul>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-4">
        <ul class="list-group  border-radius text-left shadow " *ngIf="interactions">
          <li class="list-group-item text-center">
            <h5><b translate>page.accueil.anpp-organisme.interactions.title</b></h5>
            <small translate>page.accueil.anpp-organisme.interactions.subtitle</small>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center"
            *ngFor="let interaction of interactions">
            <b>Le {{interaction.dateInteraction | date:'dd/MM/yyyy'}}, par {{interaction.auteurMiseAJour}} :
              {{interaction.sujet}}</b>
          </li>
        </ul>
        <div *ngIf="!interactions">
          <small translate>page.accueil.anpp-producteur.pas-interaction</small>
        </div>
      </div>
      <div class="col-lg-4">
        <ul class="list-group  border-radius text-left shadow ">
          <li class="list-group-item text-center">
            <h5><b translate>page.accueil.anpp-producteur.verger-eco.title</b></h5>
          </li>

          <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="engagementVerStats">
            <b translate>page.accueil.anpp-producteur.verger-eco.nb-engagements</b>
            <h5 class="margin-bottom-none"><span
                class="badge badge-green badge-pill">{{engagementVerStats.pourcentageEngagementVer | number:
                '1.0-2'}}%</span>
            </h5>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="engagementVerStats">
            <b translate>page.accueil.anpp-producteur.verger-eco.nb-controles</b>
            <h5 class="margin-bottom-none"><span
                class="badge badge-green badge-pill">{{engagementVerStats.nombreControleEnCours}}</span>
            </h5>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="engagementVerStats">
            <b translate>page.accueil.anpp-producteur.verger-eco.nb-adherents-agrees</b>
            <h5 class="margin-bottom-none"><span
                class="badge badge-green badge-pill">{{engagementVerStats.nombreAdherentsAgrees}}</span>
            </h5>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <b translate>page.accueil.anpp-producteur.verger-eco.cotisation-a-jour</b>
            <h5 class="margin-bottom-none"><span class="badge badge-green badge-pill">{{pourcentageCotisation | number:
                '1.0-2'}}%</span>
            </h5>
          </li>
          <li class="list-group-item d-flex justify-content-center">
            <a class="vertical-menu link-secondary pointer" (click)="goToEngagementVer()" *ngIf="engagementVerStats">
              <span class="pr-4" translate>page.accueil.anpp-producteur.verger-eco.suivi-engagement</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="col-lg-4">
        <ul class="list-group  border-radius text-left shadow ">
          <li class="list-group-item text-center">
            <h5><b translate>page.accueil.anpp-organisme.nombre-adherent.title</b></h5>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <b translate>page.accueil.anpp-organisme.nombre-adherent.organisation-producteur</b>
            <h5><span class="badge badge-green badge-pill"
                *ngIf="nombreAdherents && nombreAdherents.nombreGroupementsProducteurs">{{nombreAdherents.nombreGroupementsProducteurs}}</span>
            </h5>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <b translate>page.accueil.anpp-organisme.nombre-adherent.producteur-organise</b>
            <h5><span class="badge badge-green badge-pill"
                *ngIf="nombreAdherents && nombreAdherents.nombreProducteursOrganises">{{nombreAdherents.nombreProducteursOrganises}}</span>
            </h5>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <b translate>page.accueil.anpp-organisme.nombre-adherent.producteur-independant</b>
            <h5><span class="badge badge-green badge-pill"
                *ngIf="nombreAdherents && nombreAdherents.nombreProducteursIndependants">{{nombreAdherents.nombreProducteursIndependants}}</span>
            </h5>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <b translate>page.accueil.anpp-organisme.nombre-adherent.surface-pomme</b>
            <h5 *ngIf="surfacePomme"><span class="badge badge-green badge-pill">{{surfacePomme | number:
                '1.0-3'}}</span></h5>
            <h5 *ngIf="!surfacePomme"><span class="badge badge-green badge-pill"
                translate>page.accueil.pas-donnee</span></h5>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <b translate>page.accueil.anpp-organisme.nombre-adherent.surface-poire</b>
            <h5 *ngIf="surfacePoire"><span class="badge badge-green badge-pill">{{surfacePoire | number:
                '1.0-3'}}</span></h5>
            <h5 *ngIf="!surfacePoire"><span class="badge badge-green badge-pill"
                translate>page.accueil.pas-donnee</span></h5>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>