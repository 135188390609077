import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


import { AuthentificationService } from '../../services/authentification/authentification.service';
import { Jwt } from '../../models/jwt.model';
import { Scope } from '../../models/scope.model';
import { Domaine } from 'src/app/data/intervenant/models/domaine.model';
import { DomainesService } from 'src/app/data/intervenant/services/domaines/domaines.service';
import { Router } from '@angular/router';

interface Result {
  domaine: Domaine;
  jwt: Jwt;
}

@Component({
  selector: 'app-choix-domaine',
  templateUrl: './choix-domaine.component.html',
  styleUrls: ['./choix-domaine.component.scss']
})
export class ChoixDomaineComponent implements OnInit {
  @Output()
  onSelectDomaine = new EventEmitter<Result>();

  formGroup: FormGroup;
  show = false;

  domaines: Domaine[];

  get domaine() { return this.formGroup.get('domaine'); }
  get organisme() { return this.formGroup.get('organisme'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly authService: AuthentificationService,
    private readonly domainesService: DomainesService,
    private readonly router: Router
  ) { }

  ngOnInit() {
    this.domainesService.getDomaines().subscribe(domaines => {
      this.domaines = domaines;

      if (domaines.length === 0) {
        this.router.navigate(['/auth/no-roles']);
        return;
      }

      this.formGroup = this.fb.group({
        domaine: [undefined, Validators.required]
      });

      if (domaines.length === 1) {
        this.domaine.setValue(domaines[0]);
        this.submit();
      } else {
        this.show = true;
      }
    });
  }

  submit() {
    if (this.formGroup.valid) {
      const domaine = this.domaine.value;
      const scope = new Scope();
      scope.idDomaine = domaine.id;


      this.authService.setScope(scope).subscribe(jwt => {
        this.onSelectDomaine.emit({ domaine, jwt });
      });
    }
  }

}
