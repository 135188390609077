import { Injectable } from '@angular/core';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReferencesFacturation } from '../../models/references-facturation.model';
import { map } from 'rxjs/operators';
import { ClearCache, Cache } from 'src/app/core/services/cache/cache.service';

@Injectable({
  providedIn: 'root'
})
export class ReferencesFacturationService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
  ) {

  }
  /*
    @ClearCache
    clearcache() { }

    @Cache({
      maxAge: 3600,
      key: 'references-facturation'
    })*/

  getReferences(): Observable<ReferencesFacturation> {
    return this.http.get<object>(`/api/facturation/private/references`).pipe(
      map(ref => this.mapper.deserializeObject(ref, ReferencesFacturation))
    );
  }
}



