<div class="content">
  <div class="content-panel">
    <h2 translate>page.inventaire-verger.modals.historique-dpap.title</h2>

    <table class="table table-striped" *ngIf="historiques?.length > 0; else aucunEchantillon">
      <thead>
        <th translate>page.inventaire-verger.modals.historique-dpap.table.annee</th>
        <th translate>page.inventaire-verger.modals.historique-dpap.table.cahier</th>
        <th translate>page.inventaire-verger.modals.historique-dpap.table.date</th>
        <th translate>page.inventaire-verger.modals.historique-dpap.table.surfacePlantee</th>
        <th translate>page.inventaire-verger.modals.historique-dpap.table.surfaceEngagee</th>
        <th></th>
      </thead>

      <tbody>
        <tr *ngFor="let historique of historiques">
          <td>{{ historique.campagne }}</td>
          <td>{{ historique.libelleCahier }}</td>
          <td>{{ historique.date | date:'dd/MM/yyyy' }}</td>
          <td>{{ historique.surfacePlantee }}</td>
          <td>{{ historique.surfaceEngagee }}</td>
          <td>
            <span>
              <button type="button" class="btn btn-outline-secondary btn-add" (click)="visuDpap(historique)">
                <fa-icon icon="eye" class="primary-icon" title="Voir la dpap"></fa-icon>
              </button>
              <button style="margin-left: 1em;" type="button" class="btn btn-outline-secondary btn-add" >
                <fa-icon icon="download" class="primary-icon" title="Télécharger le document"></fa-icon>
              </button>
            </span>
          </td>
        </tr>
      </tbody>
    </table>


  </div>
</div>

<ng-template #aucunEchantillon>
  <div class="alert alert-warning alert-block" translate>
    <fa-icon icon="exclamation"></fa-icon>
    page.inventaire-verger.modals.historique-dpap.aucuneDpap
  </div>
</ng-template>
