import {JsonObject, JsonProperty, Optional} from '@thorolf/json-ts-mapper';

@JsonObject
export class IntervenantCategorie {
  @JsonProperty('id', Number)
  @Optional
  id: number;
  @JsonProperty('idOrganisme', Number)
  @Optional
  idOrganisme: number;
  @JsonProperty('idIntervenant', Number)
  @Optional
  idIntervenant: number;
  @JsonProperty('caveCooperative', Boolean)
  @Optional
  caveCooperative: boolean;
  @JsonProperty('caveParticuliere', Boolean)
  @Optional
  caveParticuliere: boolean;
  @JsonProperty('negociant', Boolean)
  @Optional
  negociant: boolean;
  @JsonProperty('bailleur', Boolean)
  @Optional
  bailleur: boolean;
  @JsonProperty('apporteur', Boolean)
  @Optional
  apporteur: boolean;
}
