import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditUtilisateurComponent } from './pages/edit-utilisateur/edit-utilisateur.component';
import { ListeUtilisateursComponent } from './pages/liste-utilisateurs/liste-utilisateurs.component';
import { UtilisateurDroitsComponent } from './pages/utilisateur-droits/utilisateur-droits.component';

@Component({
  selector: 'app-utilisateurs',
  templateUrl: './utilisateurs.component.html',
  styleUrls: ['./utilisateurs.component.scss']
})
export class UtilisateursComponent implements OnInit {
  static routeData = {
    role: 'INTERVENANT_UTILISATEUR_LECTURE && (ORGANISME || ADMIN)',
    menu: {
      icon: 'users',
      libelle: 'menu.administration-utilisateurs'
    }
  };
  static routes: Routes = [
    { path: '', component: ListeUtilisateursComponent },
    { path: 'add', redirectTo: 'new/edit' },
    { path: ':id/edit', component: EditUtilisateurComponent, data: { roles: ['INTERVENANT_UTILISATEUR_ECRITURE'] } },
    { path: ':id/droits', component: UtilisateurDroitsComponent, data: { roles: ['INTERVENANT_UTILISATEUR_ECRITURE'] } }
  ];

  constructor() { }

  ngOnInit() { }
}
