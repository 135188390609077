import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditStructureComponent } from './edit-structure/edit-structure.component';
import { ListeStructuresComponent } from './liste-structures/liste-structures.component';

@Component({
  selector: 'app-structures',
  templateUrl: './structures.component.html',
  styleUrls: ['./structures.component.scss']
})
export class StructuresComponent implements OnInit {

  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"]
    };
  
    static routes: Routes = [
      { path: '', component: ListeStructuresComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id', component: EditStructureComponent, data: { roles: ['ORGANISME'] } },
      { path: 'new', component: EditStructureComponent, data: { roles: ['ORGANISME'] } }
    ];

  constructor() { }

  ngOnInit() {
  }

}
