<div class="content">
  <div class="content-panel">
    <form *ngIf="formGroup" #form="ngForm" [formGroup]="formGroup" (ngSubmit)="onSubmit()">

      <div class="form-group">
        <input type="text" formControlName="mail" class="form-control" disabled="disabled" />
      </div>

      <div class="form-group">
        <input type="password" formControlName="password" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Mot de passe" />

        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Le mot de passe est requis.</div>
          <div *ngIf="f.password.errors.pattern">
            Minimum : 12 caractères, 1 majuscule, 1 minuscule, 1 chiffre, 1 caract. spé
          </div>
        </div>

      </div>

      <div class="form-group">
        <input type="password" formControlName="confirmPassword" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" placeholder="Mot de passe (confirmation)"
          onpaste="return false;" />
        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
          <div *ngIf="f.confirmPassword.errors.required">La confirmation du mot de passe est obligatoire.</div>
          <div *ngIf="f.confirmPassword.errors.confirmPwd">Les mots de passe ne correspondent pas.</div>
        </div>
      </div>


      <div class="actions-bar">
        <button type="submit" class="btn btn-primary mr-1">Valider</button>
      </div>

    </form>
  </div>

</div>