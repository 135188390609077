<!-- <p>
  affichage-drev works!
  <app-tank [value]="valueG"></app-tank>
  <input type="number" [(ngModel)]="valueG" value="0"/>
</p> -->

<div class="content">
  <div class="content-panel">
    <div id="liste-drev-aoc" *ngIf="declarations?.aocs.length > 0 || declarations?.igps.length > 0; else aucunProduit">
      <!-- AOC -->
      <table table class="table table-striped" *ngIf="declarations?.aocs.length > 0">
        <caption translate [translateParams]="{ annee: annee }">page.declarations.drev.title.aoc</caption>
        <thead>
          <tr>
            <th class="liste-col-header" rowspan="2" translate>page.declarations.drev.table.header.produit_mention</th>
            <th class="liste-col-header" rowspan="2" translate>page.declarations.drev.table.header.surface</th>
            <th class="liste-col-header" rowspan="2" translate>page.declarations.drev.table.header.volumes_revendiques</th>
            <th class="liste-col-header" style="text-align:center;" colspan="3" translate>page.declarations.drev.table.header.details_volumes</th>
            <th class="liste-col-header" rowspan="2" translate>page.declarations.drev.table.header.stock_vci</th>
            <th class="liste-col-header" rowspan="2" translate>page.declarations.drev.table.header.reserve_vsi</th>
            <th rowspan="2"></th>
          </tr>
          <tr style="background-color:#ccc;">
            <th class="liste-col-header" translate>page.declarations.drev.table.header.vci_n-1</th>
            <th class="liste-col-header" translate>page.declarations.drev.table.header.vin_n</th>
            <th class="liste-col-header" translate>page.declarations.drev.table.header.vsi_liberee</th>
          </tr>
        </thead>
        <tbody>
            <tr *ngFor="let declaration of declarations?.aocs">
              <td>{{ declaration.produit?.libelle }}<span *ngIf="declaration.mention"> - {{ declaration.mention }}</span></td>
              <td>{{ declaration.surface | number:'1.4-4' }}</td>
              <td>{{ declaration.volumeNetRevendiqueEncours | number:'1.2-2' }}</td>
              <td>{{ declaration.getStockVciLastYear() | number:'1.2-2' }}</td>
              <td>{{ declaration.getStockWineCurrentYear() | number:'1.2-2' }}</td>
              <td>{{ declaration.reserveVsiLiberee | number:'1.2-2' }}</td>
              <td>{{ declaration.getStockVciCurrentYear() | number:'1.2-2' }}</td>
              <td>{{ declaration.vsi | number:'1.2-2' }}</td>
              <td>
                <fa-icon *ngIf="declaration.produit?.eligibleVci" class="clickable-icon icon-with-background" icon="cubes" (click)="utilisationVci(declaration)" title="{{'page.declarations.drev.table.utilisation-vci' | translate}}"></fa-icon>
              </td>
            </tr>
        </tbody>
      </table>
    </div>

    <!-- IGP -->
    <table table class="table table-striped" *ngIf="declarations?.igps.length > 0">
      <caption translate [translateParams]="{ annee: annee }">page.declarations.drev.title.igp</caption>
      <thead>
        <th class="liste-col-header" translate>page.declarations.drev.table.header.produit_mention</th>
        <th class="liste-col-header" translate>page.declarations.synthese.table.header.surface</th>
        <th class="liste-col-header" translate>page.declarations.synthese.table.header.volumesIssusDR</th>
      </thead>
      <tbody>
        <tr *ngFor="let declaration of declarations?.igps">
          <td>{{declaration.produit?.libelle}}<span *ngIf="declaration.mention"> - {{declaration.mention}}</span></td>
          <td>{{declaration.surface | number:'1.4-4' }}</td>
          <td>{{declaration.volume | number:'1.2-2' }}</td>
        </tr>
      </tbody>
    </table>

    <div class="actions-bar" *ngIf="(declarations?.aocs && declarations?.aocs.length) || (declarations?.igps && declarations?.igps.length)">
      <button type="button" class="btn btn-primary" (click)="sendData()" translate>label.valider</button>
    </div>
  </div>
</div>

<ng-template #aucunProduit>
  <div class="alert alert-warning alert-block" translate [translateParams]="{ annee: annee }">
    <fa-icon icon="exclamation"></fa-icon>
    page.declarations.drev.table.aucunProduit
  </div>
<ng-template>
