import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Interlocuteur } from '../../models/interlocuteur.model';

@Injectable({
  providedIn: 'root'
})
export class InterlocuteursService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
  ) { }

  getInterlocuteurs(idOrganisme: number): Observable<Interlocuteur[]> {
    return this.http.get<object[]>(`/api/intervenant/private/organismes/${idOrganisme}/interlocuteurs`).pipe(
      map(interlocuteurs => this.mapper.deserializeArray(interlocuteurs, Interlocuteur)),
      tap(interlocuteurs => {
        interlocuteurs.forEach(interlocuteur => interlocuteur.nomComplet = `${interlocuteur.prenom} ${interlocuteur.nom}`);
      })
    );
  }
}
