import { JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";
import { Entreprise } from "../../intervenant/models/entreprise.model";
import { Utilisateur } from "../../intervenant/models/utilisateur.model";

@JsonObject
export class SuiviEcoulement {
    @JsonProperty('idDeclaration', Number)
	@Optional
    idDeclaration: number = undefined;
    
	@JsonProperty('idEntreprise', Number)
    @Optional
	idEntreprise: number;

	entreprise: Entreprise;
        
    @JsonProperty('dateDeclaration', Date)
    @Optional
	dateDeclaration: Date;

	@JsonProperty('dateDerniereMaj', Date)
    @Optional
	dateDerniereMaj: Date;
    
	@JsonProperty('dateDerniereValidation', Date)
    @Optional
	dateDerniereValidation: Date;
    
	@JsonProperty('idAuteurDerniereValidation', Number)
    @Optional
	idAuteurDerniereValidation: number;

    @JsonProperty('qteTotale', Number)
    @Optional
	qteTotale: number;

    @JsonProperty('qteBio', Number)
    @Optional
	qteBio: number;

	@JsonProperty('codeEspece', String)
    @Optional
	codeEspece: string;
	
	auteurModification: Utilisateur;

	public estCochee: boolean = false;
}