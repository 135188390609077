import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { remove } from 'lodash';
import { forkJoin } from 'rxjs';
import { RefForme } from 'src/app/data/declaration/models/ref-forme.model';
import { RefFormeService } from 'src/app/data/declaration/services/parcellaire/ref-forme.service';

@Component({
  selector: 'app-liste-formes',
  templateUrl: './liste-formes.component.html',
  styleUrls: ['./liste-formes.component.scss']
})
export class ListeFormesComponent implements OnInit {

  formes : RefForme[];

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalsService : AdvBootstrapModalService,
    private readonly translate: TranslateService,
    private readonly formeService : RefFormeService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
 
    forkJoin(
      this.formeService.getRefForme()
      )
    .pipe(this.loaderService.operator())
    .subscribe(([formes]) => {
      this.formes = formes;     
    });

    

  }

  openConfirmationDialog(forme: RefForme) { 

    this.modalsService.confirm(
      'Veuillez confirmer',
      'Voulez vous supprimer la forme '+ forme.libelle + ' ?', {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.deleteForme(forme);
    }, () => { });

  }

  deleteForme(forme: RefForme) {
    this.formeService.desactiverRefForme(forme).subscribe(() => {
      remove(this.formes, forme);
    });
  }

  editForme(forme: RefForme) {
    this.modalsService.confirm(
      'Veuillez confirmer',
      this.translate.instant(`page.valeur_parametrable.message_modification`), {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.router.navigate(['edit', forme.id], { relativeTo: this.route });
    }, () => { });
  }

}
