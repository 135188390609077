import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { Produit } from '../../habilitation/models/produit.model';

@JsonObject
export class DeclarationIgp {
    @JsonProperty('id', Number)
    id: number = undefined;

    @JsonProperty('codeProduit', String)
    codeProduit: string = undefined;

    @JsonProperty('surface', Number)
    surface: number = undefined;

    @JsonProperty('volumeNetRevendiqueEncours', Number)
    volume: number = undefined;

    @JsonProperty('mention', String)
    @Optional
    mention: string = undefined;

    produit: Produit = undefined;

    @JsonProperty('surfaceModifiee', Number)
    @Optional
    surfaceModifiee: number = 0;

    @JsonProperty('volumeNetRevendiqueEncoursModifie', Number)
    @Optional
    volumeModifie: number = 0;
}
