import {Component, OnInit, Input} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {forkJoin} from 'rxjs';
import * as moment from 'moment';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ControlesService} from 'src/app/data/declaration/services/controles/controles.service';
import {OperateursService} from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import {Produit} from 'src/app/data/habilitation/models/produit.model';
import {Operateur} from 'src/app/data/intervenant/models/operateur.model';
import {Controle} from 'src/app/data/declaration/models/controle.model';
import {SessionContext} from 'src/app/core/services/config/app.settings';
import {PressionResultatItem} from 'src/app/data/declaration/models/pression-resultat-item.model';
import {PressionControle} from 'src/app/data/declaration/models/pression-controle.model';
import {OperateurInformationsDomaine} from 'src/app/data/intervenant/models/operateur-informations-domaine.model';
import {ProduitsService} from 'src/app/data/habilitation/services/produits/produits.service';
import {AnimateursService} from 'src/app/data/commission/services/animateurs/animateurs.service';
import {Animateur} from 'src/app/data/commission/models/animateur.model';
import {AdvBootstrapLoaderService} from '@adv/bootstrap-loader';
import {Laboratoire} from '../../../../../../../data/declaration/models/laboratoire.model';
import {LaboratoiresService} from '../../../../../../../data/declaration/services/laboratoires/laboratoires.service';

@Component({
  selector: 'app-edition-controle',
  templateUrl: './edition-controle.component.html',
  styleUrls: ['./edition-controle.component.scss']
})
export class EditionControleComponent implements OnInit {
  controleForm: FormGroup;
  controle: Controle;
  creation = true;
  produits: Produit[];
  operateur: Operateur;
  idOrganisme: number;
  laboratoires: Laboratoire[];
  @Input() resultatRecherche: PressionResultatItem;
  @Input() pression: PressionControle;
  agents: Animateur[];

  constructor(
    private readonly fb: FormBuilder,
    private readonly modal: NgbActiveModal,
    private readonly controlesService: ControlesService,
    private readonly operateursService: OperateursService,
    private readonly produitsService: ProduitsService,
    private readonly animateursSrvice: AnimateursService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly laboratoireService: LaboratoiresService,
  ) {
  }

  ngOnInit() {
    this.idOrganisme = SessionContext.get('idOrganisme');

    forkJoin(
      this.operateursService.getOperateur(this.resultatRecherche.idOperateur),
      this.operateursService.getInformationsDomaine(this.resultatRecherche.idOperateur),
      this.produitsService.getProduitByCode(this.resultatRecherche.codeProduit),
      this.animateursSrvice.getAnimateurs(this.idOrganisme),
      this.laboratoireService.getLaboratoire(this.idOrganisme)
    ).pipe(
      this.loaderService.operator()
    ).subscribe(([operateur, infos, produit, agents, laboratoire]) => {
      this.operateur = operateur;
      this.produits = [produit];
      this.agents = agents;
      this.laboratoires = laboratoire;

      this.initForm(operateur, infos, this.pression, {
        type: this.resultatRecherche.typeMouvement ? this.resultatRecherche.typeMouvement.libelle : null,
        numeroDossier: this.resultatRecherche.numeroDossier,
      }, produit, laboratoire);
    });
  }

  tryOrReturnDefault<T>(func: () => T, defaultValue: T = null): T {
    try {
      return func();
    } catch (e) {
      return defaultValue;
    }
  }

  initForm(
    operateur: Operateur, infosOperateur: OperateurInformationsDomaine[], pression: PressionControle,
    mouvement: {
      type: string,
      numeroDossier: number
    },
    produit: Produit, laboratoire: Laboratoire) {
    this.controleForm = this.fb.group({
      operateur: this.fb.group({
        cvi: [this.tryOrReturnDefault(() => infosOperateur.find(i => !!i.code.indexOf('CVI')).valeur)],
        raisonSociale: [operateur.raisonSociale],
      }),
      mouvement: this.fb.group({
        type: [mouvement.type],
        numeroDossier: [mouvement.numeroDossier],
        produits: [produit.libelle],
      }),
      pression: this.fb.group({
        cdcs: [pression.cdcs.map(c => c.libelle).join(', ')],
        nature: [pression.nature.libelle],
        objet: [pression.objet.libelle],
      }),
      laboratoire: [undefined],
      dateEnvoiLaboratoire: [undefined],
      dateButoir: [undefined],
      agent: [undefined],
    });

    this.controleForm.get('operateur').disable();
    this.controleForm.get('mouvement').disable();
    this.controleForm.get('pression').disable();
  }

  getField(key: string) {
    return this.controleForm.get(key);
  }

  annuler() {
    this.modal.dismiss();
  }

  submit() {
    if (this.controleForm.valid) {
      const controle = new Controle();
      controle.activites = [];
      controle.codeProduitList = this.produits.map(p => p.code);
      controle.cahiers = this.pression.cdcs;

      if (this.resultatRecherche.idMouvement) {
        controle.idMouvement = this.resultatRecherche.idMouvement;
      } else {
        controle.idOperateur = this.resultatRecherche.idOperateur;
      }

      controle.nature = this.pression.nature;
      controle.objet = this.pression.objet;
      if (this.getField('agent').value) {
        controle.idAgent = this.getField('agent').value.id;
      }
      if (this.getField('laboratoire').value) {
        controle.libelleLaboratoire = this.getField('laboratoire').value.libelle;
        controle.idLaboratoire = this.getField('laboratoire').value.id;
      }
      if (this.getField('dateEnvoiLaboratoire').value) {
        const dateLabo = this.getField('dateEnvoiLaboratoire').value;
        controle.dateEnvoiLaboratoire = moment([dateLabo.year, dateLabo.month - 1, dateLabo.day, 0, 0, 0, 0]);
      }

      const date = this.getField('dateButoir').value;
      if (date) {
        controle.dateButoir = moment([date.year, date.month - 1, date.day, 0, 0, 0, 0]);
      }

      if (this.controle && this.controle.id) {
        controle.id = this.controle.id;
        this.controlesService.modifierControle(this.idOrganisme, controle).subscribe(() => {
          // TODO toast modif OK + redirection
          this.modal.close();
        });
      } else {
        this.controlesService.creerControle(this.idOrganisme, controle).subscribe(id => {
          // TODO toast creatio OK + redirection
          this.modal.close();
        });
      }
    }
  }
}
