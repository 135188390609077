import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { EntrepriseBase } from 'src/app/data/intervenant/models/entreprise-base.model';

@JsonObject
export class EngagementDetail {
  @JsonProperty('id', Number)
  @Optional
  public id: number = undefined;

  @JsonProperty('entreprise', EntrepriseBase)
  public entreprise: EntrepriseBase = undefined;

  @JsonProperty('activiteProduction', Boolean)
  public activiteProduction: boolean = undefined;

  @JsonProperty('activiteConditionnement', Boolean)
  public activiteConditionnement: boolean = undefined;

  @JsonProperty('engagementVergersEcoresponsables', Boolean)
  public engagementVergersEcoresponsables: boolean = undefined;

  @JsonProperty('engagementGlobalGap', Boolean)
  public engagementGlobalGap: boolean = undefined;

  @JsonProperty('engagementHve', Boolean)
  public engagementHve: boolean = undefined;

  @JsonProperty('certificationBrc', Boolean)
  public certificationBrc: boolean = undefined;

  @JsonProperty('certificationIfs', Boolean)
  public certificationIfs: boolean = undefined;

  @JsonProperty('typeEngagementHve', String)
  @Optional
  public typeEngagementHve: string = undefined;

}
