import { JsonProperty, JsonObject, Optional, CustomConverter } from '@thorolf/json-ts-mapper';

import { SiteActivite } from './site-activite.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';

@JsonObject
export class Site {
  @JsonProperty('id', Number)
  @Optional
  id: number = undefined;

  @JsonProperty('adresse', String)
  @Optional
  adresse: string = undefined;

  @JsonProperty('responsable', String)
  @Optional
  responsable: string = undefined;

  @JsonProperty('refSiteActiviteIds', [Number])
  @CustomConverter(DataIdConverterFactory.build(SiteActivite, 'sitesActivites'))
  siteActivites: SiteActivite[] = undefined;
}
