<div class="content">
  <div class="content-panel">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            <span translate>page.common.habilitations.demarches</span>
          </th>
          <th *ngFor="let activite of activites" scope="col" t-id="habiliations-activites">{{activite.libelle}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cahier of cahiersFiltered">
          <th scope="row" t-id="habiliations-cahiers">{{cahier.libelle}}</th>
          <td *ngFor="let activite of activites">
            <ng-container *ngTemplateOutlet="habilitationTemplate;context:{cahier: cahier, activite: activite, habilitation: getHabilitation(cahier, activite)}">
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #habilitationTemplate let-habilitation="habilitation" let-cahier="cahier" let-activite="activite">
  <div *ngIf="habilitation">
    <app-statut-badge [statut]="habilitation.statut"></app-statut-badge>
  </div>
</ng-template>