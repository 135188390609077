import { JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";
import { DomaineSpec } from './domaine-spec.model';
import { Domaine } from './domaine.model';
import { Fonction } from './fonction.model';
import { OrganismeType } from './organisme-type.model';
import { Pays } from './pays.model';
import { Service } from './service.model';
import { Structure } from './structure.model';
import { UtilisateurType } from './utilisateur-type.model';
import { RefSoustypeUtilisateur } from '../../droits/models/ref-soustype-utilisateur.model';
import { Terroir } from './terroir.model';


@JsonObject
export class References {

  @JsonProperty('services', [Service])
  public services: Service[] = undefined;

  @JsonProperty('typesOrganismes', [OrganismeType])
  public typesOrganismes: OrganismeType[] = undefined;

  @JsonProperty('typesUtilisateurs', [UtilisateurType])
  public typesUtilisateurs: UtilisateurType[] = undefined;

  @JsonProperty('sousTypesUtilisateurs', [RefSoustypeUtilisateur])
  public sousTypesUtilisateurs: RefSoustypeUtilisateur[] = undefined;

  @JsonProperty('structures', [Structure])
  public structures: Structure[] = undefined;

  @JsonProperty('domaines', [Domaine])
  public domaines: Domaine[] = undefined;

  @JsonProperty('pays', [Pays])
  public pays: Pays[] = undefined;

  @JsonProperty('terroirs', [Terroir])
  public terroirs: Terroir[] = undefined;
  
  @JsonProperty('fonctions', [Fonction])
  @Optional
  public fonctions: Fonction[] = undefined;
}
