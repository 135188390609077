import { DeclarationRevendication } from './declaration-revendication.model';
import { DeclarationIgp } from './declaration-igp.model';
import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class Declarations {
    @JsonProperty('aocs', [DeclarationRevendication])
    aocs: DeclarationRevendication[] = undefined;

    @JsonProperty('igps', [DeclarationIgp])
    igps: DeclarationIgp[] = undefined;
}
