import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { RefCultureBio } from 'src/app/data/declaration/models/ref-culture-bio.model';
import { RefCultureBioService } from 'src/app/data/declaration/services/parcellaire/ref-culture-bio.service';

@Component({
  selector: 'app-edit-culture-bio',
  templateUrl: './edit-culture-bio.component.html',
  styleUrls: ['./edit-culture-bio.component.scss']
})
export class EditCultureBioComponent implements OnInit {

  formGroup: FormGroup;
  creation = false;
  title: string;
  cultureBio: RefCultureBio;

  get code() { return this.formGroup.get('code'); }
  get libelle() { return this.formGroup.get('libelle'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly cultureBioService: RefCultureBioService,) { }

  ngOnInit() {

    const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    if (!id) {
      this.creation = true;
    }
      this.loadData(id);
    

  }

  loadData(id: number) {

    this.title = this.creation ? 'page.valeur_parametrable.form.title.add_culture_bio': 'page.valeur_parametrable.form.title.edit_culture_bio';
    
    forkJoin(
    !this.creation ? this.cultureBioService.getRefCultureBio() : of(new RefCultureBio())
    )
    .pipe(
      this.loaderService.operator()
    ).subscribe(([gels]) => {
     this.cultureBio = !this.creation ? gels.find(g => g.id === id) : gels ; 

    this.initForm((this.cultureBio.id) ? this.cultureBio : null);
     
    });
  }

  private initForm(gel?: RefCultureBio): void {
 
    this.formGroup = this.fb.group({
      code: [{ value: ((gel) ? gel.code: undefined), disabled: false }, Validators.required],
      libelle: [{ value: ((gel) ? gel.libelle : undefined), disabled: false }, Validators.required]
    });
  }

  submit() {
    if (this.formGroup.valid) {
      this.cultureBio.code = this.code.value;
      this.cultureBio.libelle = this.libelle.value;
     
      if (this.cultureBio.id == null) {
        this.cultureBioService.creerRefCultureBio(this.cultureBio).subscribe(idCultureBio => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
        });
      } else {        
        this.cultureBioService.modifierRefCultureBio(this.cultureBio).subscribe( () => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
         } );
      }
     
    }
  }

}
