import { Directive, Renderer2, Input, TemplateRef, ViewContainerRef, ɵstringify as stringify } from '@angular/core';

import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';

const selector = '[hasDomaine]';
@Directive({
  selector
})
export class HasDomaineDirective {

  private isVisible: boolean;
  private elseAction = 'hide';
  private elseTemplateRef: TemplateRef<any>;

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly renderer: Renderer2,
    private readonly authService: AuthService) {
  }

  @Input()
  set hasDomaine(domaines: string[]) {
 
    if(domaines && domaines.length > 0){
      this.isVisible = domaines.includes(SessionContext.get('codeDomaine'));
      this.updateView();
    } else {
      this.isVisible = true;
      this.updateView();
    }
      
  }

  private updateView() {
    this.viewContainerRef.clear();
    if (this.isVisible) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else if (this.elseAction === 'disabled') {
      const view = this.viewContainerRef.createEmbeddedView(this.templateRef);
      const rootElem = view.rootNodes[0];
      if (rootElem) {
        rootElem.disabled = true;
        this.renderer.addClass(rootElem, 'disabled'); // disable element
        rootElem.style.pointerEvents = 'none'; // disable standar elements
        this.renderer.setProperty(rootElem, 'disabled', true); // add class
      }
    } else if (this.elseAction === 'else') {
      this.viewContainerRef.createEmbeddedView(this.elseTemplateRef);
    }
  }
}
