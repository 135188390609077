import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { Espece } from 'src/app/data/declaration/models/espece.model';
import { Variete } from 'src/app/data/declaration/models/variete.model';
import { VarieteService } from 'src/app/data/declaration/services/parcellaire/variete.service';

@Component({
  selector: 'app-edit-clones',
  templateUrl: './edit-clones.component.html',
  styleUrls: ['./edit-clones.component.scss']
})
export class EditClonesComponent implements OnInit {

  formGroup: FormGroup;
  creation = false;
  title: string;
  clone: Variete;
  varietes: Variete[];

  get code() { return this.formGroup.get('code'); }
  get libelle() { return this.formGroup.get('libelle'); }
  get variete() { return this.formGroup.get('variete'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly varieteService: VarieteService
    ) { }

  ngOnInit() {

    const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    if (!id) {
      this.creation = true;
    }
      this.loadData(id);   

  }

  loadData(id: number) {

    this.title = this.creation ? 'page.valeur_parametrable.form.title.add_clone': 'page.valeur_parametrable.form.title.edit_clone';
    
    forkJoin(
    !this.creation ? this.varieteService.getVariete(+id) : of(new Variete()),
    this.varieteService.getVarietes()
    )
    .pipe(
      this.loaderService.operator()
    ).subscribe(([clone,varietes]) => {
    
    this.clone = clone ; 
    this.varietes = varietes;
    this.initForm((clone.id) ? clone : null);
     
    });
  }

  private initForm(variete?: Variete): void {
 
    this.formGroup = this.fb.group({
      code: [{ value: ((variete) ? variete.code: undefined), disabled: false }, Validators.required],
      libelle: [{ value: ((variete) ? variete.libelle : undefined), disabled: false }, Validators.required],
      variete: [{ value: ((variete) ? this.varietes.find(va => va.id === variete.varieteClone.id) : undefined), disabled: false }, Validators.required]
    });
  }

  submit() {
    if (this.formGroup.valid) {
      this.clone.code = this.code.value;
      this.clone.libelle = this.libelle.value;
      this.clone.varieteClone = this.variete.value;
      this.clone.espece = this.clone.varieteClone.espece;

      if (this.clone.id == null) {
        this.varieteService.creerVariete(this.clone).subscribe(idVariete => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
        });
      } else {        
        this.varieteService.modifierVariete(this.clone).subscribe( () => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
         } );
      }
     
    }
  }

}
