import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Utilisateur } from 'src/app/data/intervenant/models/utilisateur.model';
import { UtilisateursService } from 'src/app/data/intervenant/services/utilisateurs/utilisateurs.service';
import { UtilisateurTypeCode } from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-liste-utilisateurs',
  templateUrl: './liste-utilisateurs.component.html',
  styleUrls: ['./liste-utilisateurs.component.scss']
})
export class ListeUtilisateursComponent implements OnInit {
  utilisateurs: Utilisateur[];
  entreprises: Entreprise[];

  searchStringInput: string;
  searchString: string;
  searchStringUpdate = new Subject<string>();

  order = {
    property: 'id',
    reverse: false
  };

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly utilisateursService: UtilisateursService,
    private readonly authService: AuthService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.getUtilisateurs();
    });

    this.searchStringUpdate.pipe(
      debounceTime(200),
      distinctUntilChanged())
      .subscribe(value => {
        this.searchString = value;
      });
  }

  isAdmin(utilisateur: Utilisateur) {
    return utilisateur.type.code === UtilisateurTypeCode.ADMIN;
  }

  getUtilisateurs() {
    this.utilisateursService.getUtilisateurs().pipe(this.loaderService.operator()).subscribe(utilisateurs => {
      this.utilisateurs = utilisateurs;
    });
  }

  editUtilisateur(utilisateur: Utilisateur) {
    this.router.navigate([utilisateur.id, 'edit'], { relativeTo: this.route });
  }

  editUtilisateurDroits(utilisateur: Utilisateur) {
    this.router.navigate([utilisateur.id, 'droits'], { relativeTo: this.route });
  }

  addUtilisateur() {
    this.router.navigate(['new/edit'], { relativeTo: this.route });
  }

  setOrder(property: string) {
    if (property) {
      if (property === this.order.property) {
        this.order.reverse = !this.order.reverse;
      } else {
        this.order = {
          property,
          reverse: false
        };
      }
    }
  }

  getIcon(property: string) {
    if (property === this.order.property) {
      return this.order.reverse ? 'sort-down' : 'sort-up';
    } else {
      return 'sort';
    }
  }

  isAnpp() {
    return this.authService.isAnpp();
  }
}
