import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { ParametreCampagne } from './parametre-campagne.model';
import { ContactReferent } from './contact-referent.model';
import { RefSoustypeUtilisateur } from '../../droits/models/ref-soustype-utilisateur.model';
import { Terroir } from './terroir.model';

@JsonObject
export class EntrepriseReferent  {
  
  @JsonProperty('id', Number)
  public id: number = undefined;

  @JsonProperty('raisonSociale', String)
  public raisonSociale: string = undefined;

  @JsonProperty('departement', String)
  public departement: string = undefined;

  @JsonProperty('sousType', RefSoustypeUtilisateur)
  public sousType: RefSoustypeUtilisateur = undefined;

  public parametreCampagne: ParametreCampagne;

  public terroir: Terroir;

  @JsonProperty('referentRecolteList', ContactReferent)
  public referentRecolteList: ContactReferent[] = undefined;

  @JsonProperty('referentStockList', ContactReferent)
  public referentStockList: ContactReferent[] = undefined;

  @JsonProperty('referentEcoulementList', ContactReferent)
  public referentEcoulementList: ContactReferent[] = undefined;
 
  @JsonProperty('referentInventaireList', ContactReferent)
  @Optional
  public referentInventaireList: ContactReferent[] = undefined;

}
