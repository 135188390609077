import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { remove } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { Campagne } from 'src/app/data/declaration/models/campagne.model';
import { GroupeVarietalParam } from 'src/app/data/declaration/models/groupe-varietal-param.model';
import { GroupeVarietalRecolte } from 'src/app/data/declaration/models/groupe-varietal-recolte.model';
import { GroupeVarietalService } from 'src/app/data/declaration/services/groupe-varietal/groupe-varietal.service';
import { VarieteService } from 'src/app/data/declaration/services/parcellaire/variete.service';
import { RefCampagnesService } from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';

@Component({
  selector: 'app-liste-groupes-varietaux',
  templateUrl: './liste-groupes-varietaux.component.html',
  styleUrls: ['./liste-groupes-varietaux.component.scss']
})
export class ListeGroupesVarietauxComponent implements OnInit {

  groupesvarietaux : GroupeVarietalParam[];
  groupesvarietauxRecoltes : GroupeVarietalRecolte[];

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalsService : AdvBootstrapModalService,
    private readonly translate: TranslateService,
    private readonly groupevarietalService : GroupeVarietalService,
    private readonly varieteService : VarieteService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
 
    forkJoin(
      this.groupevarietalService.getGroupeVarietaux()
      )
    .pipe(this.loaderService.operator())
    .subscribe(([groupes]) => {
      this.groupesvarietaux = groupes;    
      this.groupesvarietauxRecoltes = [];
      //this.departements = this.terroirs.map(t =>{ return t.departements; }).join(",").split(",");
      
    });
         

  }

  openConfirmationDialog(groupevarietal: GroupeVarietalParam) { 

    this.modalsService.confirm(
      'Veuillez confirmer',
      'Voulez vous supprimer le groupe variétal '+ groupevarietal.libelle + ' ?', {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.deleteGroupevarietal(groupevarietal);
    }, () => { });

  }

  deleteGroupevarietal(groupevarietal: GroupeVarietalParam) {

    this.groupevarietalService.getGroupeVarietauxRecolte()
    .pipe(this.loaderService.operator())
    .subscribe((groupes) => {

      this.groupesvarietauxRecoltes = groupes;

      if(!this.groupesvarietauxRecoltes.find(g => g.idGroupeVarietal === groupevarietal.id)){

      this.groupevarietalService.desactiverGroupeVarietal(groupevarietal).subscribe(() => {

      groupevarietal.varietes.forEach(gvv => { 
        this.groupevarietalService.supprimerGroupeVarietalVariete(gvv).subscribe( () => {} );
      });

      remove(this.groupesvarietaux, groupevarietal);

    });

  }
  else{
    this.toastrService.error('Le groupe variétal ne peut pas être supprimé, il est déjà présent dans une déclaration de récolte');
  }

    });

    
  }

  editGroupevarietal(groupevarietal: GroupeVarietalParam) {
    this.modalsService.confirm(
      'Veuillez confirmer',
      this.translate.instant(`page.valeur_parametrable.message_modification`), {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.router.navigate(['edit', groupevarietal.id], { relativeTo: this.route });
    }, () => { });
  }

}
