import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { DateConverter } from 'src/app/core/services/mapper/converters';

@JsonObject
export class Decision {
    @JsonProperty('dateTransmission', String)
    @CustomConverter(DateConverter)
    @Optional
    dateTransmission: moment.Moment;

    @JsonProperty('dateDecision', String)
    @CustomConverter(DateConverter)
    @Optional
    dateDecision: moment.Moment;

    @JsonProperty('applicationSanction', Boolean)
    @Optional
    applicationSanction: boolean;

    @JsonProperty('auteurDecision', String)
    @Optional
    auteur: string;

    @JsonProperty('commentaireDecision', String)
    @Optional
    commentaire: string;

    @JsonProperty('idSanctionAppliquee', Number)
    @Optional
    idSanction: number;
}
