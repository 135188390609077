import { JsonProperty, JsonObject } from '@thorolf/json-ts-mapper';
import { PressionResultatItem } from './pression-resultat-item.model';

@JsonObject
export class PressionResultat {
  @JsonProperty('objectif', Number)
  objectif: number;

  @JsonProperty('realise', Number)
  realise: number;

  @JsonProperty('resteAFaire', Number)
  resteAFaire: number;

  @JsonProperty('pressionResultatList', [PressionResultatItem])
  pressionResultatList: PressionResultatItem[] = [];
}
