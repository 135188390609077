import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { Entreprise } from '../../intervenant/models/entreprise.model';
import { Utilisateur } from '../../intervenant/models/utilisateur.model';

@JsonObject
export class SuiviInventaireVergerModel {
    @JsonProperty('idDeclaration', Number)
	@Optional
    idDeclaration: number = undefined;
    
	@JsonProperty('idEntreprise', Number)
    @Optional
	idEntreprise: number;

	entreprise: Entreprise;
    
	@JsonProperty('dateDerniereMaj', Date)
    @Optional
	dateDerniereMaj: Date;
    
	@JsonProperty('dateDerniereValidation', Date)
    @Optional
	dateDerniereValidation: Date;
    
	@JsonProperty('idAuteurDerniereValidation', Number)
    @Optional
	idAuteurDerniereValidation: number;

	auteurModification: Utilisateur;
    
	@JsonProperty('surfacePomme', Number)
    @Optional
	surfacePomme: number;
	
	@JsonProperty('surfacePoire', Number)
	@Optional
    surfacePoire: number;

	@JsonProperty('estAdherentAnpp', Boolean)
    @Optional
	estAdherentAnpp: boolean;
	
	@JsonProperty('terroir', String)
    @Optional
	terroir: String;
	
	@JsonProperty('ct', String)
    @Optional
	ct: Utilisateur;
	
	@JsonProperty('typeEntreprise', String)
    @Optional
	typeEntreprise: String;

	estValide:boolean;

	public estCochee: boolean = false;
}
