import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { OperateurDrevManquante } from 'src/app/data/intervenant/interfaces/operateur-drev-manquante.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OperateurComponent } from '../modal/operateur/operateur.component';

@Component({
  selector: 'app-drev-manquantes',
  templateUrl: './drev-manquantes.component.html',
  styleUrls: ['./drev-manquantes.component.scss']
})
export class DrevManquantesComponent implements OnInit {
  static routes: Routes = [{
    path: '',
    redirectTo: 'liste',
    pathMatch: 'full',
    data: {
      role: 'ORGANISME && DECLARATION_MOUVEMENT_LECTURE',
      menu: { icon: '', libelle: 'menu.drev-manquantes', hide: true }
    }
  }, {
    path: 'liste',
    component: DrevManquantesComponent
  }];
  public operateurs: OperateurDrevManquante[];
  get idOrganisme() { return SessionContext.get('idOrganisme'); }

  constructor(
    private readonly operateursService: OperateursService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalService: NgbModal
  ) { }

  ngOnInit() {
    this.loadData();
  }

  private loadData(): void {
    // Obtenir la liste des opérateurs avec DREV manquantes
    this.operateursService.getOperateursDREVManquantes(this.idOrganisme).pipe(
      this.loaderService.operator()
    ).subscribe(operateurs => {
      this.operateurs = operateurs;
    });
  }

  /**
   * Retourne le CVI de l'intervenant depuis la collection stockée dans le composant.
   * @param idIntervenant L'identifiant de l'intervenant.
   */
  public getIntervenantCVI(operateur: OperateurDrevManquante): string {
    const info = operateur.informations.find(info => info.code === 'VIN_CVI');
    return (info) ? info.valeur : '';
  }

  public consulterIntervenant(operateur: OperateurDrevManquante): void {
    const modal = this.modalService.open(OperateurComponent, { backdrop: 'static', size: 'lg' });
    modal.componentInstance.operateur = operateur;
  }
}
