		<div class="modal-content anpp">
			<div class="modal-header">
				<h4 class="modal-title" translate>
					page.recoltesPp.modalEnregistrer.titre
				</h4>
			</div>			
			<div class="modal-body">
				<form [formGroup]="formGroup" #form="ngForm" >
					<div class="tab-content" id="nav-tabContent">
						<div class="row justify-content-md-center">
							<div class="col col-lg-12 text-center">
								<ng-container >
									<div style="font-size:16px" *ngIf="alerteBio" class="form-error mb-5" translate>page.recoltesPp.alert.alerteSaisieBio<br></div>
								</ng-container>								
								<table class="table table-striped mb-5">

									<thead class="center">
										<tr>
											<th scope="col"> </th>
											<th scope="col" class="text-left">
												<div class="bar1"><span style="font-size:16px" translate>page.recoltesPp.table.header.groupeVarietal</span></div>
											</th>
											<th scope="col" colspan="2"><span style="font-size:16px" >{{campagnePrecedente?.libelle}}</span></th>
											<th scope="col" colspan="2" *ngIf="!estDefinitive"><span style="font-size:16px" translate>page.recoltesPp.table.header.dernierePrevision</span></th>
											<th scope="col" colspan="2" class="bg-td"> <span style="font-size:16px">{{ (estDefinitive ? 'page.recoltesPp.table.header.recolteDefinitive' : 'page.recoltesPp.table.header.nouvelleSaisie') | translate}}</span></th>
										</tr>
										<tr>
											<th scope="col" style="position:relative">


											</th>
											<th scope="col" style="position:relative">


											</th>
											<th scope="col" class="center" translate>page.recoltesPp.table.header.qteTotale</th>
											<th scope="col" class="center" translate>page.recoltesPp.table.header.dontBioT</th>
											<th scope="col" class="center" *ngIf="!estDefinitive" translate>page.recoltesPp.table.header.qteTotale</th>
											<th scope="col" class="center" *ngIf="!estDefinitive" translate>page.recoltesPp.table.header.dontBioT</th>
											<th scope="col" class="center bg-td" translate>page.recoltesPp.table.header.qteTotale</th>
											<th scope="col" class="center bg-td" translate>page.recoltesPp.table.header.dontBioT</th>
										</tr>
									</thead>
									<tbody>

										<tr scope="col" *ngFor="let recolte of recoltes;let i = index" [ngClass]="font-weight-bold">
											<td>
												<span *ngIf="recolte.codeEspece==='POMME'"> 
													<fa-icon icon='apple-alt' style="color:black;"></fa-icon>
													<fa-icon icon='apple-alt' style="color:black;"></fa-icon>
												</span>
												<span *ngIf="recolte.codeEspece==='POIRE'"> 
													<img src="/assets/img/pear.png" width="16px" />
													<img src="/assets/img/pear.png" width="16px" />
												</span>
												</td>
											<td scope="col" class="text-left">
												<span [innerHtml]="'page.recoltesPp.table.header.total' | translate"></span>
												&nbsp;{{recolte.codeEspece}}
											</td>

											<td scope="col" class="center">{{recolte.qteTotalePrecedente}}</td>
											<td scope="col" class="center">{{recolte.qteBioPrecedente}}</td>
											<td scope="col" class="center" *ngIf="!estDefinitive">{{recolte.qteTotaleDernierePrevision}}</td>
											<td scope="col" class="center" *ngIf="!estDefinitive">{{recolte.qteBioDernierePrevision}}</td>
											<td scope="col" class="center bg-td">{{recolte.qteTotale}}</td>
											<td scope="col" class="center bg-td">{{recolte.qteBio}}</td>											
										</tr>


									</tbody>
								</table>

							</div>

							<div *ngIf="hasErrors" class="form-error" style="font-size: 18px; text-align: center;" translate>label.obligatoire</div>

							<div class="col col-lg-6 text-center" *ngIf="!estDefinitive">
								<p>
									<label>
										<input type="radio" class="with-gap" id="prevision" formControlName="previsionOuDefinitive" value=false required />
										<span style="font-size: 20px; margin: 10px;"  [innerHtml]="'page.recoltesPp.modalEnregistrer.declaration-prevision' | translate"></span>									  
									</label>
								</p>
							</div>
							<div class="col col-lg-6 text-center" *ngIf="!estDefinitive">

								<p>
									<label>
										<input type="radio" class="with-gap" id="definitive" formControlName="previsionOuDefinitive" value=true required />
										<span style="font-size: 20px; margin: 10px;" [innerHtml]="'page.recoltesPp.modalEnregistrer.declaration-definitive' | translate"></span>
									</label>
								</p>
							</div>
							<ng-container >
								<div style="font-size:16px; text-align: center;" *ngIf="alerteSaisie" class="form-error" translate>page.recoltesPp.alert.alerteSaisieTropGrande<br></div>
							</ng-container>
						</div>
					</div>
				</form>
			</div>

			<div class="actions-bar">
				<button type="button" (click)="cancel()" class="btn btn-light mr-2" translate>label.annuler</button>
				<button *ngIf="!estDefinitive" type="submit" (click)="save()" class="btn btn-secondary  btnprimary" style="background:#41905c;border:1px solid #41905c"
				  translate>label.valider</button>
				<button *ngIf="estDefinitive" type="submit" (click)="save()" class="btn btn-secondary  btnprimary" style="background:#41905c;border:1px solid #41905c"
				  translate>label.modifier</button>
			  </div>
		</div>
