import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { remove } from 'lodash';
import { forkJoin } from 'rxjs';
import { RefProtectionGrele } from 'src/app/data/declaration/models/ref-protection-grele.model';
import { RefProtectionGreleService } from 'src/app/data/declaration/services/parcellaire/ref-protection-grele.service';

@Component({
  selector: 'app-listes-protections-grele',
  templateUrl: './listes-protections-grele.component.html',
  styleUrls: ['./listes-protections-grele.component.scss']
})
export class ListesProtectionsGreleComponent implements OnInit {

  protectionsGrele : RefProtectionGrele[];

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalsService : AdvBootstrapModalService,
    private readonly translate: TranslateService,
    private readonly protectionsGreleService : RefProtectionGreleService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
 
    forkJoin(
      this.protectionsGreleService.getRefProtectionGrele()
      )
    .pipe(this.loaderService.operator())
    .subscribe(([protectionsGrele]) => {
      this.protectionsGrele = protectionsGrele;     
    });

  }

  openConfirmationDialog(protectionGrele: RefProtectionGrele) { 

    this.modalsService.confirm(
      'Veuillez confirmer',
      'Voulez vous supprimer ' + this.translate.instant(`label.RefProtectionGrele`) + protectionGrele.libelle + ' ?', {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.deleteProtectionGrele(protectionGrele);
    }, () => { });

  }

  deleteProtectionGrele(protectionGrele: RefProtectionGrele) {
    this.protectionsGreleService.desactiverRefProtectionGrele(protectionGrele).subscribe(() => {
      remove(this.protectionsGrele, protectionGrele);
    });
  }

  editProtectionGrele(protectionGrele: RefProtectionGrele) {this.modalsService.confirm(
      'Veuillez confirmer',
      this.translate.instant(`page.valeur_parametrable.message_modification`), {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.router.navigate(['edit', protectionGrele.id], { relativeTo: this.route });
    }, () => { });
  }

}
