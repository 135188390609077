<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
      page.declarations.synthese.modal.saisir-transaction.title
    </h4>
  </div>

  <div class="modal-body">
    <form [formGroup]="formGroup" #form="ngForm" (ngSubmit)="submit()" *ngIf="loaded" novalidate>
      <div class="cols">
        <div class="col">
          <div class="form-group">
            <label for="produit">{{ 'page.declarations.synthese.modal.saisir-transaction.produit' | translate }}</label>
            <input type="text" formControlName="produit" id="produit" name="produit" class="form-control" />
          </div>

          <div class="form-group" *ngIf="typeCahier == 'IGP'">
            <label for="select-cepages" translate>page.declarations.synthese.modal.saisir-transaction.cepages</label>
            <app-multiselect id="select-cepages" maxWidth="initial" bindLabel="libelle" controlName="cepages" [items]="cepages" [ngClass]="{'is-invalid': form.submitted && getField('cepages').invalid}"></app-multiselect>
          </div>

          <hr/>

          <div class="form-group">
            <label for="date">{{ 'page.declarations.synthese.modal.saisir-transaction.date-retiraison' | translate }}</label>
            <div class="input-group">
              <input type="text" formControlName="date" id="date" name="date" class="form-control" ngbDatepicker #datePicker="ngbDatepicker" required [ngClass]="{'is-invalid': form.submitted && getField('date').invalid}" />
              <div class="input-group-append">
                <button type="button" (click)="datePicker.toggle()" class="btn btn-secondary">
                  <fa-icon icon="calendar-alt"></fa-icon>
                </button>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="logement" translate>page.declarations.synthese.modal.saisir-transaction.logement</label>
            <textarea formControlName="logement" class="form-control" [ngClass]="{'is-invalid': form.submitted && getField('logement').invalid}"></textarea>
          </div>

          <div class="form-group">
            <label for="lot" translate>page.declarations.synthese.modal.saisir-transaction.numeroLot</label>
            <input type="text" formControlName="lot" class="form-control" [ngClass]="{'is-invalid': form.submitted && getField('lot').invalid}" />
          </div>

          <div class="form-group">
            <label for="contrat" translate>page.declarations.synthese.modal.saisir-transaction.numeroContrat</label>
            <input type="text" formControlName="contrat" class="form-control" [ngClass]="{'is-invalid': form.submitted && getField('contrat').invalid}" />
          </div>
        
          <hr />
        
          <div class="form-group">
            <label for="achteur" translate>page.declarations.synthese.modal.saisir-transaction.acheteur</label>
            <div class="flex flex-row acheteur-form-row">
              <input type="text" [ngModelOptions]="{standalone: true}" [ngModel]="raisonSocialeAcheteur" id="acheteur" name="acheteur" class="form-control" [ngClass]="{'is-invalid': form.submitted && getField('acheteur').invalid}" disabled/>
              <button type="button" class="btn btn-light" (click)="rechercherAcheteur()" translate>page.declarations.synthese.modal.saisir-transaction.modifier-acheteur</button>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="volume" translate>page.declarations.synthese.modal.saisir-transaction.volume</label>
            <div class="flex flex-row">
              <input type="number" formControlName="volume" class="form-control" [ngClass]="{'is-invalid': form.submitted && getField('volume').invalid}" />
              <span *ngIf="typeCahier !== 'IGP'"  class="volume-max">&nbsp;({{ 'page.declarations.synthese.modal.saisir-transaction.assemblage.max' | translate: { volume:(stockCourant?.stock) ? stockCourant.stock : 0 } }})</span>  
            </div>

            <div class="alert-information" *ngIf="displayAlertMillesime" translate>
              <fa-icon icon="exclamation"></fa-icon>
              page.declarations.synthese.modal.saisir-transaction.alertMillesime
            </div>
          </div>

          <hr/>

          <!-- Formulaire assemblages -->
          <div formArrayName="assemblages" *ngIf="typeCahier !== 'IGP'">
            <table *ngIf="getAssemblages().controls?.length" class="table-assemblages form-group">
              <tr>
                <td><label translate>page.declarations.synthese.modal.saisir-transaction.assemblage.annee</label></td>
                <td><label translate>page.declarations.synthese.modal.saisir-transaction.assemblage.volume</label></td>
              </tr>
              <tr *ngFor="let assemblage of getAssemblages().controls; let i = index" [formGroupName]="i">
                <td>
                  <select formControlName="annee" class="form-control" required [ngClass]="{'is-invalid': form.submitted && getField('assemblages.' + i + '.annee').invalid}">
                    <option [ngValue]="null" hidden selected></option>
                    <option *ngFor="let stock of stocksProduit" [ngValue]="stock">{{ stock.annee }}</option>
                  </select>
                </td>
                <td>
                  <input type="number" lang="en" style="width:12em;" formControlName="volume" class="form-control" [readonly]="getField('assemblages.' + i + '.annee').value == null" [ngClass]="{'is-invalid': form.submitted && getField('assemblages.' + i + '.volume').invalid}" />
                </td>
                <td>
                  <span class="volume-max" *ngIf="getField('assemblages.' + i + '.annee').value">({{ 'page.declarations.synthese.modal.saisir-transaction.assemblage.max' | translate:{volume:getField('assemblages.' + i + '.annee').value.stock} }})</span>
                </td>
                <td>
                  <fa-icon class="pointer deleteIcon" icon="trash" title="{{ 'page.declarations.synthese.modal.saisir-transaction.supprimerAssemblage' | translate }}" (click)="removeAssemblage(i)"></fa-icon>
                </td>
              </tr>
            </table>

            <button class="lienAffichage" type="button" (click)="addAssemblage()" [disabled]="stocksProduit.length == 0">
              <fa-icon icon="plus"></fa-icon> {{ 'page.declarations.synthese.modal.saisir-transaction.creerAssemblage' | translate }}
            </button>
            <div class="alert-information" *ngIf="stocksProduit.length == 0" translate>
              <fa-icon icon="exclamation"></fa-icon>
              page.declarations.synthese.modal.saisir-transaction.alertAucunStock
            </div>
          </div>

          <hr/>

          <div class="form-group">
            <label for="volumeTotal">{{ 'page.declarations.synthese.modal.saisirConditionnement.volumeTotal' | translate }} ({{ 'page.declarations.synthese.modal.saisirConditionnement.hL' | translate }})</label>
            <input type="number" [ngModelOptions]="{standalone: true}" [ngModel]="volumeTotalValue" id="volumeTotal" name="volumeTotal" class="form-control" disabled/>
          </div>

          <hr/>

          <div class="form-group">
            <label for="entreposage" translate>page.declarations.synthese.modal.saisir-transaction.entreposage</label>
            <select formControlName="entreposage" class="form-control" [ngClass]="{'is-invalid': form.submitted && getField('entreposage').invalid, 'select-placeholder' : (getField('entreposage').value === null && !getField('entreposage').dirty)}">
              <option [ngValue]="null" hidden translate>page.declarations.synthese.modal.saisir-transaction.liste-sites</option>
              <option *ngFor="let site of sitesOperateur" [ngValue]="site">{{ site.adresse }}</option>
            </select>
          </div>

          <div class="form-group" *ngIf="typeCahier == 'IGP'">
            <label for="observation" translate>page.declarations.synthese.modal.saisir-revendication.observation</label>
            <textarea formControlName="observation" class="form-control" [ngClass]="{'is-invalid': form.submitted && getField('observation').invalid}"></textarea>
          </div>
        </div>
      </div>

      <div class="actions-bar">
        <button type="button" (click)="close()" class="btn btn-light" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary" translate>label.valider</button>
      </div>
    </form>
  </div>
</div>
