import { Component, OnInit, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { IntensiteDefaut } from 'src/app/data/declaration/models/enums/intensite-defaut.enum';
import { Gravite } from 'src/app/data/declaration/models/enums/gravite.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Cahier } from 'src/app/data/habilitation/models/cahier.model';
import { Produit } from 'src/app/data/habilitation/models/produit.model';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { ProduitsService } from 'src/app/data/habilitation/services/produits/produits.service';
import { Manquement } from 'src/app/data/declaration/models/manquement.model';
import { ManquementsService } from 'src/app/data/declaration/services/manquements/manquements.service';
import { Controle } from 'src/app/data/declaration/models/controle.model';
import { PointAMaitriser } from 'src/app/data/declaration/models/point-a-maitriser.model';
import { ManquementConstate } from 'src/app/data/declaration/models/manquement-constate.model';
import { Sanction } from 'src/app/data/declaration/models/sanction.model';
import { SaisieLot } from 'src/app/data/declaration/interfaces/saisie-lot.interface';
import { Animateur } from 'src/app/data/commission/models/animateur.model';
import { AnimateursService } from 'src/app/data/commission/services/animateurs/animateurs.service';
import { Constat } from 'src/app/data/declaration/models/constat.model';
import { Onglet } from 'src/app/data/intervenant/interfaces/onglet.interface';
import * as moment from 'moment';

@Component({
  selector: 'app-saisir-constat',
  templateUrl: './saisir-constat.component.html',
  styleUrls: ['./saisir-constat.component.scss']
})
export class SaisirConstatComponent implements OnInit, Onglet {
  @Input() @Output() public manquements: Manquement[] = [];
  @Input() public controle: Controle;
  @Input() public estOnglet = false;
  @Input() public saisieLot: SaisieLot<Manquement[]>;
  public auteurs: Animateur[];
  public cdcs: Cahier[];
  public gravites = Object.keys(Gravite).map(gravite =>
    Gravite[gravite as string]
  );
  private idOrganisme: number;
  public intensites = Object.keys(IntensiteDefaut).map(intensite =>
    IntensiteDefaut[intensite as string]
  );
  public manquementsConstates: ManquementConstate[];
  public produits: Produit[];
  public points: PointAMaitriser[];
  public sanctions: Sanction[];
  // Formulaire de saisie d'un constat
  public formConstat: FormGroup;
  get date() { return this.formConstat.get('date'); }
  get auteur() { return this.formConstat.get('auteur'); }
  get cdc() { return this.formConstat.get('cdc'); }
  get produit() { return this.formConstat.get('produit'); }
  get pointAMaitriser() { return this.formConstat.get('pointAMaitriser'); }
  get manquementConstate() { return this.formConstat.get('manquementConstate'); }
  get intensite() { return this.formConstat.get('intensite'); }
  get gravite() { return this.formConstat.get('gravite'); }
  get sanction() { return this.formConstat.get('sanction'); }
  get commentaire() { return this.formConstat.get('commentaire'); }

  constructor(
    private readonly fb: FormBuilder,
    public readonly modal: NgbActiveModal,
    private readonly produitsService: ProduitsService,
    private readonly manquementsService: ManquementsService,
    private readonly animateursService: AnimateursService
  ) { }

  ngOnInit() {
    // Récupérer l'identifiant de l'organisme
    this.idOrganisme = SessionContext.get('idOrganisme');

    // Initialiser le formulaire de saisie
    this.formConstat = this.fb.group({
      date: [undefined, this.ongletValidator.bind(this)],
      auteur: [undefined, this.ongletValidator.bind(this)],
      cdc: [undefined, Validators.required],
      produit: [undefined, this.produitValidator.bind(this)],
      pointAMaitriser: [undefined, Validators.required],
      manquementConstate: [undefined, Validators.required],
      intensite: [IntensiteDefaut.MOYENNE, this.intensiteValidator.bind(this)],
      gravite: [Gravite.MAJEURE, Validators.required],
      sanction: [undefined, Validators.required],
      commentaire: undefined
    });

    // Désactiver la liste des produits si aucun CDC sélectionné par défaut
    this.produit.disable();

    // Déclarer l'écouteur sur la sélection du CDC pour charger les produits liés
    this.cdc.valueChanges.subscribe(cahier => {
      this.loadProducts(cahier);
      this.loadReferenceDatas(cahier);
    });

    // Déclarer l'écouteur sur la sélection du point à maîtriser pour charger les manquements constatés liés
    this.pointAMaitriser.valueChanges.subscribe(point => {
      this.manquementsConstates = (point) ? point.manquementsConstates : [];
      // Vérifier si l'on provient de la synthèse
      if (this.saisieLot && this.saisieLot.indexAModifier > -1 && !this.formConstat.touched) {
        // Affecter le manquement constaté du manquement en cours de modification
        this.manquementConstate.setValue(this.manquementsConstates.find(manquement =>
          manquement.id === this.saisieLot.lot[this.saisieLot.indexAModifier].constat.manquementConstate.id
        ));
      } else {
        this.manquementConstate.setValue(null);
        this.sanction.setValue(null);
      }
    });

    // Déclarer l'écouteur sur la sélection du manquement à constater pour charger les sanctions liées
    this.manquementConstate.valueChanges.subscribe(manquement => {
      this.sanctions = (manquement) ? manquement.sanctionsEncourues : [];
      // Vérifier si l'on provient de la synthèse
      if (this.saisieLot && this.saisieLot.indexAModifier > -1 && !this.formConstat.touched) {
        // Affecter la sanction du manquement en cours de modification
        this.sanction.setValue(this.sanctions.find(sanction =>
          sanction.id === this.saisieLot.lot[this.saisieLot.indexAModifier].constat.sanctionEncourue.id
        ));
      } else {
        this.sanction.setValue(null);
      }
    });

    // Vérifier si on provient de la synthèse
    if (this.saisieLot && this.saisieLot.lot && this.saisieLot.lot.length > 0) {
      // Récupérer le lot de manquements
      this.manquements = this.saisieLot.lot;
      if (this.saisieLot.indexAModifier > -1) {
        this.loadAnimateurs().then(() =>
          // Pré-remplir le formulaire
          this.preSetForm(this.saisieLot.indexAModifier)
        );
      }
    }

    // Sélectionner le CDC par défaut s'il n'y en a qu'un seul
    if (!this.cdc.value && this.controle.cahiers && this.controle.cahiers.length === 1) {
      this.cdc.setValue(this.controle.cahiers[0]);
    }
  }

  /**
   * Charge les produits liés à un CDC et sélectionnés dans le contrôle et active/désactive
   * le liste déroulante des produits en fonction du résultat
   * @param cahier Le CDC dont dépendent les produits
   */
  private loadProducts(cahier: Cahier): void {
    this.produits = [];
    // Si l'utilisateur a sélectionné un CDC, charger les produits associés
    if (cahier) {
      this.produitsService.getProduitsByCdcs(this.idOrganisme, [cahier.id]).subscribe(produits => {
        // N'afficher les produits du CDC sélectionnés et concernés par le contrôle
        this.produits = produits.filter(produit => this.controle.codeProduitList.includes(produit.code));
        (this.produits.length > 0) ? this.produit.enable() : this.produit.disable();
        if (this.produits.length === 1) { this.produit.setValue(this.produits[0]); }
      });
    }
  }

  /**
   * Charge les données de référence des manquements pour remplir les listes
   * déroulantes liées à partir d'un CDC
   * @param cahier Le CDC dont dépendent les références
   */
  private loadReferenceDatas(cahier: Cahier): void {
    // Vérifier que le cahier ne soit pas null
    if (cahier) {
      this.manquementsService.getReferenceDatas(
        cahier.id,
        this.controle.nature.id,
        this.controle.objet.id
      ).subscribe((references: PointAMaitriser[]) => {
        this.points = references;

        // Vérifier si l'on provient de la synthèse
        if (this.saisieLot && this.saisieLot.indexAModifier > -1) {
          // Affecter le point du manquement en cours de modification
          this.pointAMaitriser.setValue(this.points.find(point =>
            point.id === this.saisieLot.lot[this.saisieLot.indexAModifier].constat.pointAMaitriser.id
          ));
        } else if (this.points && this.points.length === 1) {
          // Affecter le seul point de la liste
          this.pointAMaitriser.setValue(this.points[0]);
          this.manquementConstate.setValue(null);
          this.sanction.setValue(null);
        } else {
          this.pointAMaitriser.setValue(null);
          this.manquementConstate.setValue(null);
          this.sanction.setValue(null);
        }
      });
    } else {
      // Déclarer une liste vide si le choix de la liste déroulante des CDC est null
      this.points = [];
    }
  }

  /**
   * Méthode asynchrone qui charge la liste des animateurs de l'organisme et retourne
   * une promesse lorsque terminée
   * @returns Promise<unknown>
   * @see Promise
   */
  private async loadAnimateurs(): Promise<unknown> {
    return new Promise(resolve => {
      if (this.estOnglet) {
        this.animateursService.getAnimateurs(this.idOrganisme).subscribe(animateurs => {
          this.auteurs = animateurs;
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  /**
   * Vérifie si une intensité a été sélectionnée seulement si la nature
   * du contrôle est ORGANOLEPTIQUE
   * @param control Le contrôle à valider
   */
  private intensiteValidator(control: FormControl) {
    return (this.controle.nature.code === 'ORGANOLEPTIQUE' && !control.value) ?
      { invalid: true } : null;
  }

  /**
   * Vértifie s'il y a un produit sélectionné seulement s'il y a des produits
   * liés au CDC qui sont rattachés au contrôle
   * @param control Le contrôle à valider
   */
  private produitValidator(control: FormControl) {
    return (this.produits && this.produits.length > 0 && !control.value) ?
      { invalid: true } : null;
  }

  /**
   * Ne rend obligatoire le contrôle que si l'on est en mode "Onglet"
   * @param control Le contrôle de date de constat ou d'auteur à valider
   */
  private ongletValidator(control: FormControl) {
    return (this.estOnglet && !control.value) ?
      { invalid: true } : null;
  }

  /**
   * Réinitialise le formulaire de saisie
   * @param form Le formulaire de réinitialisation
   */
  private resetForm(form: FormGroupDirective): void {
    form.resetForm({
      cdc: undefined,
      produit: undefined,
      pointAMaitriser: undefined,
      manquementConstate: undefined,
      intensite: IntensiteDefaut.MOYENNE,
      gravite: Gravite.MAJEURE,
      sanction: undefined,
      commentaire: undefined
    });

    this.produit.disable();

    // Sélectionner le CDC par défaut s'il n'y en a qu'un seul
    if (this.controle.cahiers && this.controle.cahiers.length === 1) {
      this.cdc.setValue(this.controle.cahiers[0]);
    }
  }

  /**
   * Remplit le formulaire avec les informations du manquement à modifier
   * (sauf les points à maîtriser, manquements constatés et sanctions encourues
   * gérés dans les écouteurs de modification car les listes sont liées)
   * @param index L'indice du manquement à modifier depuis le lot
   */
  private preSetForm(index: number): void {
    const manquement = this.saisieLot.lot[index];
    if (manquement) {
      this.formConstat.patchValue({
        cdc: manquement.constat.cahierDesCharges,
        produit: manquement.constat.produit,
        intensite: manquement.constat.intensite,
        gravite: manquement.constat.gravite,
        commentaire: manquement.constat.commentaire
      });

      if (this.estOnglet) {
        const auteur = this.auteurs.find(_ => _.id === manquement.constat.auteur.id);
        if (auteur) {
          this.auteur.setValue(auteur);
        }
        this.date.setValue({
          year: manquement.constat.date.year(),
          month: manquement.constat.date.month() + 1,
          day: manquement.constat.date.date()
        });
      }
    }
  }

  /** Ferme la fenêtre surgissante */
  public onClose(): void {
    this.modal.dismiss();
  }

  /** Ajoute la saisie de l'utilisateur au lot de manquements */
  public onSubmit(formDirective: FormGroupDirective = null): void {
    if (this.formConstat.valid) {
      // Récupérer la saisie de l'utilisateur
      const manquement = this.getSaisie();
      if (manquement) {
        if (this.saisieLot && this.saisieLot.indexAModifier > -1) {
          this.manquements[this.saisieLot.indexAModifier] = manquement;
          this.saisieLot.indexAModifier = -1;
        } else {
          // Ajouter la saisie dans le lot
          this.manquements.push(manquement);
        }

        // Réinitialiser le formulaire si l'utilisateur souhaite ajouter
        // un autre manquement
        if (formDirective) {
          this.resetForm(formDirective);
        }
      }
    }
  }

  /**
   * Retourne une instance de manquement si le formulaire est valide
   * @see Manquement
   * @returns Manquement ou null si form invalide
   */
  private getSaisie(): Manquement {
    let manquement: Manquement = null;

    // Vérifier que le formulaire soit valide
    if (this.formConstat.valid) {
      // Récupérer la saisie
      manquement = Object.assign(new Manquement(), {
        constat: Object.assign(new Constat(), {
          cahierDesCharges: this.cdc.value,
          codeProduit: (this.produit.value) ? this.produit.value.code : '',
          idPointAMaitriser: this.pointAMaitriser.value.id,
          pointAMaitriser: this.pointAMaitriser.value,
          idManquementConstate: this.manquementConstate.value.id,
          manquementConstate: this.manquementConstate.value,
          intensite: (this.controle.nature.code === 'ORGANOLEPTIQUE') ? this.intensite.value : null,
          gravite: this.gravite.value,
          idSanctionEncourue: this.sanction.value.id,
          sanctionEncourue: this.sanction.value,
          commentaire: this.commentaire.value,
          produit: this.produit.value
        })
      });

      if (this.estOnglet) {
        manquement.constat.auteur = this.auteur.value;
        manquement.constat.date = moment([ this.date.value.year, this.date.value.month - 1, this.date.value.day, 0, 0, 0]);
      }
    }

    return manquement;
  }

  /**
   * Méthode asynchrone appelée par le conteneur d'onglets qui enregistre la partie constat
   * du manquement et qui retourne une promesse
   * @see Promise
   * @returns Promise<boolean> à true si enregistrement réussi, false si non
   */
  public async onEdit(): Promise<boolean> {
    return new Promise(resolve => {
      const manquement = this.getSaisie();
      if (manquement) {
        manquement.id = this.saisieLot.lot[this.saisieLot.indexAModifier].id;
        this.saisieLot.lot[this.saisieLot.indexAModifier].constat = manquement.constat;
        // Mettre à jour le constat
        this.manquementsService.putConstat(this.idOrganisme, this.controle.id, manquement).subscribe(
          () => {
            // Marquer le formulaire non-touché pour pouvoir changer d'onglet
            this.formConstat.markAsUntouched();
            // Vérifier si la sanction de la décision est toujours dans la liste des sanctions
            // possibles du constat
            const decision = this.saisieLot.lot[this.saisieLot.indexAModifier].decision;
            if (decision.idSanction) {
              if (!this.sanctions.find(sanction => sanction.id === decision.idSanction)) {
                this.saisieLot.lot[this.saisieLot.indexAModifier].decision.idSanction = null;
                // Mettre à jour la décision sans sanction
                this.manquementsService.putDecision(
                  this.idOrganisme,
                  this.controle.id,
                  this.saisieLot.lot[this.saisieLot.indexAModifier]
                ).subscribe();
              }
            }

            resolve(true);
          });
      } else {
        resolve(false);
      }
    });
  }

  /**
   * Ajoute la saisie de l'utilisateur au lot de manquements et clos
   * la fenêtre surgissante
   */
  public onTerminer(): void {
    this.onSubmit();
    this.modal.close(this.manquements);
  }
}
