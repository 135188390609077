import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditContactComponent } from '../contacts/pages/edit-contact/edit-contact.component';
import { ListeContactEntrepriseComponent } from '../contacts/pages/liste-contact-entreprise/liste-contact-entreprise.component';
import { EditEntrepriseComponent } from './pages/edit-entreprise/edit-entreprise.component';
import { FicheEntrepriseComponent } from './pages/fiche-entreprise/fiche-entreprise/fiche-entreprise.component';
import { ListeEntreprisesComponent } from './pages/liste-entreprises/liste-entreprises.component';
import { ListePoComponent } from './pages/liste-po/liste-po/liste-po.component';
import { VergerEntrepriseComponent } from './pages/verger-entreprise/verger-entreprise.component';

@Component({
  selector: 'app-entreprises',
  templateUrl: './entreprises.component.html',
  styleUrls: ['./entreprises.component.scss']
})
export class EntreprisesComponent implements OnInit {

  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"],
    menu: {
      libelle: 'menu.administration-entreprises',
      icon: 'key'
      }
    };
  
    static routes: Routes = [
      { path: '', component: ListeEntreprisesComponent },
      { path: 'new', component: EditEntrepriseComponent, data: { roles: ['ORGANISME'] } },
      { path: 'fiche/:id', component: FicheEntrepriseComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id', component: EditEntrepriseComponent, data: { roles: ['ORGANISME'] } },
      { path: 'fiche/:id/po', component: ListePoComponent, data: { roles: ['ORGANISME'] } },
      { path: 'fiche/:id/contacts/edit/:idc', component: EditContactComponent, data: { roles: ['ORGANISME'] } },
      { path: 'fiche/:id/contacts/new', component: EditContactComponent, data: { roles: ['ORGANISME'] } },
      { path: 'fiche/:id/contacts', component: FicheEntrepriseComponent, data: { roles: ['ORGANISME'] } },
      { path: 'verger/:id', component: VergerEntrepriseComponent, data: { roles: ['ORGANISME'] } }
    ];
  

  constructor() { }

  ngOnInit() {
  }

}
