import { Component, OnInit, Input } from '@angular/core';
import { NavigationContext, SessionContext } from 'src/app/core/services/config/app.settings';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { Operateur } from 'src/app/data/intervenant/models/operateur.model';
import { Router } from '@angular/router';
import { forkJoin, Observable, EMPTY, of } from 'rxjs';
import { OperateurSitesService } from 'src/app/data/declaration/services/operateur-sites/operateur-sites.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { HabilitationsService } from 'src/app/data/habilitation/services/habilitations/habilitations.service';
import { EChartOption } from 'echarts';
import { Habilitation } from 'src/app/data/habilitation/models/habilitation.model';
import { UtilisateurTypeCode } from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';
import { ManquementsService } from 'src/app/data/declaration/services/manquements/manquements.service';
import { url } from 'inspector';

@Component({
  selector: 'app-operateur-dashboard',
  templateUrl: './operateur-dashboard.component.html',
  styleUrls: ['./operateur-dashboard.component.scss']
})
export class OperateurDashboardComponent implements OnInit {
  static routes = [
    {
      path: '',
      component: OperateurDashboardComponent,
      data: {
        // role: '',
        menu: {
          icon: 'chart-bar',
          libelle: 'menu.operateurs-dashboard'
        }
      }
    }];

  private get idOperateur(): number {
    if (NavigationContext.has('idOperateur')) {
      return NavigationContext.get('idOperateur');
    } else {
      return SessionContext.get('idIntervenant');
    }
  }
  operateur: Operateur;
  nbSites: number

  estOperateur: boolean; 
  chartHabilitation: EChartOption;
  habilitations: Habilitation[];
  nbHabilites: number;
  nbEnCours: number;
  nbRefuses: number;
  nbRetires: number;
  nbManquements: number;

  urlFacturationPanel: string;

@Input()
options: Object;
  constructor(private readonly router: Router,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly operateurService: OperateursService,
    private readonly sitesService: OperateurSitesService,
    private readonly habilitationsService: HabilitationsService,
    private readonly manquementsService: ManquementsService) { }

  ngOnInit() {
    this.estOperateur = SessionContext.get('utilisateurType') === UtilisateurTypeCode.OPERATEUR;

    if (!this.idOperateur) {
      this.router.navigate(['/main/operateurs/or/recherche-affilie']);
    }
    forkJoin(
      this.operateurService.getOperateur(this.idOperateur),
      this.sitesService.getSitesOperateur(this.idOperateur),
      SessionContext.get('utilisateurType') === UtilisateurTypeCode.OPERATEUR ?
      this.habilitationsService.getHabilitations():this.habilitationsService.getOperateurHabilitations(this.idOperateur),
      this.manquementsService.countManquementEncoursOperateur(this.idOperateur),
      SessionContext.get('utilisateurType') === UtilisateurTypeCode.ORGANISME ?
      this.operateurService.getOperateursFacturePanel(this.idOperateur, SessionContext.get("idOrganisme")): 
      of({})
    ).pipe(
      this.loaderService.operator()
    ).subscribe(([operateur, sites, habilitations, nbManquements, urlPanel]) => {
      this.operateur = operateur;
      if (sites) {
        this.nbSites = sites.length;
      } else {
        this.nbSites = 0;
      }

      this.habilitations = habilitations;
           
      this.loadHabilitations();
      this.nbManquements = nbManquements;
      this.urlFacturationPanel = urlPanel;
    });
  }

  loadHabilitations(): void {
    this.nbHabilites = this.habilitations.filter(habilitation => habilitation.statut && habilitation.statut.code === 'HABILITE').length;
    this.nbEnCours = this.habilitations.filter(habilitation => habilitation.statut && habilitation.statut.code === 'ENCOURS').length;
    this.nbRefuses = this.habilitations.filter(habilitation => habilitation.statut && habilitation.statut.code === 'REFUSE').length;
    this.nbRetires = this.habilitations.filter(habilitation => habilitation.statut && habilitation.statut.code === 'RETIRE').length;

    this.chartHabilitation = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: "5%",
        left: "auto"

      },
      series: [
        {
          name: 'Habilitations',
          type: 'pie',
          radius: ['30%', '60%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: this.nbEnCours, name: 'En cours', itemStyle: { color: '#ac83d7' } },
            { value: this.nbHabilites, name: 'Habilités', itemStyle: { color: '#a9d86e' } },
            { value: this.nbRefuses, name: 'Refusés', itemStyle: { color: '#ff6c60' } },
            { value: this.nbRetires, name: 'Retirés', itemStyle: { color: '#777777' } }
          ]
        }
      ]
    };
  }

  goToDetail(): void {
    if (SessionContext.get('utilisateurType') === UtilisateurTypeCode.ORGANISME) {
      this.router.navigate(['main/operateurs/or/' + this.operateur.id + '/informations']);
    } else {
      this.router.navigate(['main/operateurs/op/informations']);
    }
    
  }

  goToSynthese(): void {
    if (SessionContext.get('utilisateurType') === UtilisateurTypeCode.ORGANISME) {
      this.router.navigate(['main/operateurs/or/' + this.operateur.id + '/synthese']);
    } else {
      this.router.navigate(['main/declarations/synthese']);
    }
  }

  goToHistorique(): void {
    if (SessionContext.get('utilisateurType') === UtilisateurTypeCode.ORGANISME) {
      this.router.navigate(['main/operateurs/or/' + this.operateur.id + '/historique']);
    } else {
      this.router.navigate(['main/declarations/historique']);
    }
  }

  goToImport(): void {
    if (SessionContext.get('utilisateurType') === UtilisateurTypeCode.ORGANISME) {
      this.router.navigate(['main/operateurs/or/' + this.operateur.id + '/dr']);
    } else {
      this.router.navigate(['main/declarations/dr']);
    }
  }

  goToFactures(): void {
    window.open(this.urlFacturationPanel, "_blank");
  }
}
