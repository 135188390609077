import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DestinationVendange } from 'src/app/data/declaration/models/enums/destination-vendange.enum';
import { UniteCulturale } from 'src/app/data/declaration/models/unite-culturale.model';

@Component({
  selector: 'app-visu-recap-uc',
  templateUrl: './visu-recap-uc.component.html',
  styleUrls: ['./visu-recap-uc.component.scss']
})
export class VisuRecapUcComponent implements OnInit {

  @Input() uniteCulturale: UniteCulturale;


  destinations = DestinationVendange;
  enumKeys = Object.keys(DestinationVendange);

  constructor(
    
    public readonly modal: NgbActiveModal,
  ) { }

  ngOnInit() {
    
  }

  cancel() {
    this.modal.close();
  }

}
