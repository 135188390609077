import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class RefCampagne {
  @JsonProperty('id', Number)
  public id: number = undefined;

  @JsonProperty('code', String)
  public code: string = undefined;

  @JsonProperty('annee', Number)
  public annee: number = undefined;
}
