import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';
import { UtilisateurTypeCode } from './enums/type-utilisateur.enum';

@JsonObject
export class UtilisateurType {
  @JsonProperty('id', Number)
  public id: number = undefined;

  @JsonProperty('code', String)
  public code: UtilisateurTypeCode = undefined;

  @JsonProperty('libelle', String)
  public libelle: string = undefined;
}
