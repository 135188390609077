import {Component, Input, OnInit} from '@angular/core';

import {MenuItem, MenuService} from 'src/app/core/services/menu/menu.service';
import {environment} from 'src/environments/environment';
import {VersionsService} from '../../services/versions/versions.service';
import {NavigationContext, SessionContext} from 'src/app/core/services/config/app.settings';

interface Version {
  name: string;
  version: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menu: String;
  items: MenuItem[];
  openAccordion = [];
  @Input() isAnpp: boolean;

  versions: Version[] = [{
    name: 'front',
    version: environment.version
  }];
  isShowMenu: boolean = true;

  constructor(
    private readonly menuService: MenuService,
    private readonly versionsService: VersionsService
  ) {
  }

  ngOnInit() {
    let domaine = SessionContext.get('codeDomaine');
    if (domaine) {
      this.menu = 'menu.' + domaine + '.';
    } else {
      this.menu = 'menu.';
    }
    this.items = this.menuService.getRoutes();
    this.versionsService.getVersions().subscribe(versions => {
      this.versions = this.versions.concat(versions);
    });

  }

  getPath(item: MenuItem) {
    const segments = item.path.split('/');

    for (const key in segments) {
      const segment = segments[key];

      if (segment.startsWith(':')) {
        const param = segment.slice(1);
        if (NavigationContext.has(param as any)) {
          segments[key] = NavigationContext.get(param as any);
        } else {
          return false;
        }
      }
    }

    return segments.join('/');
  }

  openCloseAccordion(i: number): void {
    for (const key in this.openAccordion) {
      this.openAccordion[key] = false;
    }
    this.openAccordion[i] = !this.openAccordion[i];
  }

  closeAll(): void {
    for (const key in this.openAccordion) {
      this.openAccordion[key] = false;
    }
  }

}
