import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditGroupeDiffusionComponent } from './edit-groupe-diffusion/edit-groupe-diffusion.component';
import { ListeGroupesDiffusionComponent } from './liste-groupes-diffusion/liste-groupes-diffusion.component';

@Component({
  selector: 'app-groupes-diffusion',
  templateUrl: './groupes-diffusion.component.html',
  styleUrls: ['./groupes-diffusion.component.scss']
})
export class GroupesDiffusionComponent implements OnInit {

  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"]
    };
  
    static routes: Routes = [
      { path: '', component: ListeGroupesDiffusionComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id', component: EditGroupeDiffusionComponent, data: { roles: ['ORGANISME'] } },
      { path: 'new', component: EditGroupeDiffusionComponent, data: { roles: ['ORGANISME'] } }
    ];

  constructor() { }

  ngOnInit() {
  }

}
