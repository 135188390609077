import { Component, OnInit, Input } from '@angular/core';
import { Manquement } from 'src/app/data/declaration/models/manquement.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SaisieLot } from 'src/app/data/declaration/interfaces/saisie-lot.interface';
import { ManquementsService } from 'src/app/data/declaration/services/manquements/manquements.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Constat } from 'src/app/data/declaration/models/constat.model';

@Component({
  selector: 'app-synthese-constats',
  templateUrl: './synthese-constats.component.html',
  styleUrls: ['./synthese-constats.component.scss']
})
export class SyntheseConstatsComponent implements OnInit {
  @Input() public controle: any;
  @Input() public manquements: Manquement[];
  public numerosManquements: number[];

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly manquementsService: ManquementsService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService
  ) { }

  ngOnInit() { }

  /** Enregistre les nouveaux manquements  */
  public onSubmit(): void {
    if (this.manquements && this.manquements.length > 0) {
      // Ajouter le lot de manquements
      const constats: Constat[] = this.manquements.map(manquement => manquement.constat);
      this.manquementsService.postManquements(this.controle.id, constats).subscribe((numerosManquements) => {
        // Récupérer les numéros de manquements
        this.numerosManquements = numerosManquements;
        this.translate.get('page.controles.manquement.constat.modal.lot-ok').subscribe(msg => {
          this.toastr.success('', msg);
        });
      });
    }
  }

  /**
   * Ferme la modal et retourne un objet de type SaisieLot<Manquement[]>
   * pour retourner dans la modal de saisie
   * @see SaisieLot
   */
  onAddSaisie() {
    this.modal.close({
      saisieTerminee: false,
      lot: this.manquements
    } as SaisieLot<Manquement[]>);
  }

  /** Ferme la modal */
  public onClose(estTermine: boolean = false) {
    (estTermine) ? this.modal.close({ saisieTerminee: true } as SaisieLot<Manquement[]>) : this.modal.dismiss();
  }

  /**
   * Ferme la modal et retourne un objet de type SaisieLot<Manquement[]> pour retourner dans
   * la modal de saisie et lui passer l'index de la revendication que l'on
   * veut modifier
   * @param index L'indice de l'élément à modifier
   * @see SaisieLot
   */
  public onEdit(index: number): void {
    this.modal.close({
      saisieTerminee: false,
      lot: this.manquements,
      indexAModifier: index
    } as SaisieLot<Manquement[]>);
  }

  /**
   * Supprime l'élément de la collection de manquements à l'indice spécifié
   * @param index L'indice de l'élément à supprimer
   */
  public onDelete(index: number): void {
    if (this.manquements.length > 1) {
      this.manquements.splice(index, 1);
    }
  }
}
