import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Arrachage } from 'src/app/data/declaration/models/arrachage.model';
import { UniteCulturale } from 'src/app/data/declaration/models/unite-culturale.model';
import { UniteCulturaleService } from 'src/app/data/declaration/services/parcellaire/unite-culturale.service';

@Component({
  selector: 'app-division',
  templateUrl: './division.component.html',
  styleUrls: ['./division.component.scss']
})
export class DivisionComponent implements OnInit {



  constructor(public readonly modal: NgbActiveModal,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly fb: FormBuilder,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly uniteCulturaleService: UniteCulturaleService,
    private readonly translate: TranslateService) {
}

formGroup: FormGroup;
@Input() uniteCulturale: UniteCulturale;
surface: number;

get nouvelleSurface() {
  return this.formGroup.get('nouvelleSurface');
}

ngOnInit() {
  this.formGroup = this.fb.group({
    nouvelleSurface: [undefined, [Validators.required,Validators.pattern('^-?[0-9]+(\.[0-9]+)?$')]]
  });
}

cancel() {
  this.modal.dismiss();
}

submit() {
  if (this.nouvelleSurface.value === 0) {
    this.modalsService.error(this.translate.instant(`page.inventaire-verger.modals.division.erreurDivision`), null , ).then(() => {
  });
  } else if (this.formGroup.valid) {
    this.uniteCulturaleService.diviserUniteCulturale(this.uniteCulturale.id, this.nouvelleSurface.value).subscribe(() => {
    this.modal.close();
  });
  }
}

private surfaceValidator(control: FormControl) {
  return (this.nouvelleSurface.value >= this.uniteCulturale.uniteCulturaleVigne.surfacePlantee ) ?
    {invalid: true} : null;
}


controleSurfaceMax() {
  if (this.nouvelleSurface.value === 0) {
    this.modalsService.error(this.translate.instant(`page.inventaire-verger.modals.division.erreurDivision`), null ).then(() => {
    });
  }
  if (this.nouvelleSurface.value >= this.uniteCulturale.uniteCulturaleVigne.surfacePlantee) {
    this.modalsService.error(this.translate.instant(`page.inventaire-verger.modals.division.erreurDivision0`), null ).then(() => {
    this.nouvelleSurface.setValue(0);
    });
  } else {
  }

}

keyPressNumbers(event) {
var charCode = (event.which) ? event.which : event.keyCode;
// Only Numbers 0-9
if ((charCode < '0'.charCodeAt(0) || charCode > '9'.charCodeAt(0))) {
event.preventDefault();
return false;
} else {
return true;
}
}


}
