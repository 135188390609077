import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Contact } from 'src/app/data/intervenant/models/contact.model';

@Component({
  selector: 'app-detail-contact',
  templateUrl: './detail-contact.component.html',
  styleUrls: ['./detail-contact.component.scss']
})
export class DetailContactComponent implements OnInit {
  static routes = [
    { path: '', redirectTo: 'or', pathMatch: 'full' }
  ];
  contact: Contact;
  constructor(public readonly modal: NgbActiveModal) { }

  ngOnInit() {
  }

  cancel() {
    this.modal.dismiss();
  }

}
