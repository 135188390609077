<form novalidate class="anpp">
  <div class="modal-header">
    <h5 class="modal-title" translate>Document préparatoire pour OC</h5>
  </div>

  <div class="modal-body">
    <div [formGroup]="formGroup" class="form-group">
      <h6 class="modal-title"> Pour quel Organisme Certificateur souhaitez vous uploader le document ?</h6>
      <select formControlName="orgaCollecteur" (change)="changeValueOrgaCollecteur()">
        <option [ngValue]="null"></option>
        <option *ngFor="let orgaCollecteur of orgaCollecteursList" [ngValue]="orgaCollecteur">{{orgaCollecteur.raisonSociale}}</option>
      </select>
    <hr>
     <!-- <h6 class="modal-title"> Quels sont les adhérents concernés?</h6>
      <form [formGroup]="formGroup" *ngIf="adherentsList.length>0" #form="ngForm" novalidate>
        <app-multiselect id="multis"
                         controlName="multis"
                         [items]="adherentsList"
                         bindLabel="raisonSociale"
                         maxWidth="initial"
                         [ngClass]="{ 'is-invalid': form.submitted && multis.invalid }">
        </app-multiselect>
      </form> -->

    </div>
  </div>

  <div class="modal-footer">
    <button type="button" (click)="cancel()" class="btn btn-light" translate>label.annuler</button>
    <button type="submit" (click)="buildDocumentAudit()" class="btn btn-primary" translate>label.valider</button>
  </div>
</form>
