import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { ClearCache, Cache, CacheKey } from 'src/app/core/services/cache/cache.service';
import { DroitProfil } from '../../models/droit-profil.model';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';
import { ReferencesDroitsService } from '../references/references-droits.service';
import { DroitRole } from '../../models/droit-role.model';
import { DroitFonctionnel } from '../../models/droit-fonctionnel.model';
import { find } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ProfilsService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly refIntervenant: ReferencesService,
    private readonly refDroits: ReferencesDroitsService
  ) { }

  @ClearCache
  clearCache() { }

  @Cache()
  getProfils(): Observable<DroitProfil[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/intervenant/private/profils`),
      this.refIntervenant.getReferences(),
      this.refDroits.getReferences()
    ).pipe(
      map(([profils, refIntervenant, refDroits]) => this.mapper.deserializeArray(profils, DroitProfil, Object.assign({}, refIntervenant, refDroits)))
    );
  }

  getDroitFonctionnnel(idProfil: number): Observable<DroitFonctionnel[]> {
    return forkJoin(
      this.http.get<number[]>(`/api/intervenant/private/profils/${idProfil}/droits-fonctionnels`),
      this.refDroits.getReferences()
    ).pipe(
      map(([idsDroits, ref]) => idsDroits.map(id => find(ref.droitsFonctionnels, d => d.id === id)))
    );
  }

  ajouterDroitFonctionnnel(idProfil: number, droitFonctionnel: number | DroitFonctionnel): Observable<void> {
    const idDroitFonctionnel: number = droitFonctionnel instanceof DroitFonctionnel ? droitFonctionnel.id : droitFonctionnel;

    return this.http.post(`/api/intervenant/private/profils/${idProfil}/droits-fonctionnels`, idDroitFonctionnel).pipe(
      tap(() => this.clearCache()),
      map(() => { })
    );
  }

  supprimerDroitFonctionnnel(idProfil: number, droitFonctionnel: number | DroitFonctionnel): Observable<void> {
    const idDroitFonctionnel: number = droitFonctionnel instanceof DroitFonctionnel ? droitFonctionnel.id : droitFonctionnel;

    return this.http.delete(`/api/intervenant/private/profils/${idProfil}/droits-fonctionnels/${idDroitFonctionnel}`).pipe(
      tap(() => this.clearCache()),
      map(() => { })
    );
  }
}
