<div class="modal-content">
  <!-- header fenêtre surgissante -->
  <div class="modal-header">
    <h5 class="modal-title" translate><fa-icon icon="file-alt"></fa-icon> page.suivi.suivi-recoltes.estimations-modal.title</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- body fenêtre surgissante -->
  <div class="modal-body">
    <table class="table table-striped">
      <thead>
        <tr>
          <th translate>page.suivi.suivi-recoltes.estimations-modal.espece</th>
          <th translate>page.suivi.suivi-recoltes.estimations-modal.groupe-varietal</th>
          <th translate>page.suivi.suivi-recoltes.estimations-modal.estimation</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngIf="nbEstimations === 0">
          <td colspan="3" style="text-align: center;" translate>page.suivi.suivi-recoltes.estimations-modal.aucune-donnee</td>
        </tr>
        <tr *ngFor="let estimation of (estimations$ | async); let index = index" [ngClass]="{ 'pointilles': index == indexEspecesDifferentes }">
          <td>
            <fa-icon *ngIf="estimation.espece == 'Pomme'" icon="apple-alt"></fa-icon>
            <img *ngIf="estimation.espece == 'Poire'" class="pear-img" src="/assets/img/pear.png" alt="Poire" />
          </td>
          <td>{{ estimation.groupeVarietal }}</td>
          <td *ngIf="estimation.taux == 0" class="td-vert"></td>
          <td *ngIf="estimation.taux > 0">{{ estimation.taux | number: '1.2' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
