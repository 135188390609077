import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { remove } from 'lodash';
import { forkJoin } from 'rxjs';
import { RefSituation } from 'src/app/data/declaration/models/ref-situation.model';
import { RefSituationService } from 'src/app/data/declaration/services/parcellaire/ref-situation.service';


@Component({
  selector: 'app-liste-situations',
  templateUrl: './liste-situations.component.html',
  styleUrls: ['./liste-situations.component.scss']
})
export class ListeSituationsComponent implements OnInit {

  situations : RefSituation[];

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalsService : AdvBootstrapModalService,
    private readonly translate: TranslateService,
    private readonly situationService : RefSituationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
 
    forkJoin(
      this.situationService.getRefSituation()
      )
    .pipe(this.loaderService.operator())
    .subscribe(([situations]) => {
      this.situations = situations;     
    });

    

  }

  openConfirmationDialog(situation: RefSituation) { 

    this.modalsService.confirm(
      'Veuillez confirmer',
      'Voulez vous supprimer la situation '+ situation.libelle + ' ?', {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.deleteSituation(situation);
    }, () => { });

  }

  deleteSituation(situation: RefSituation) {
    this.situationService.desactiverRefSituation(situation).subscribe(() => {
      remove(this.situations, situation);
    });
  }

  editSituation(situation: RefSituation) {this.modalsService.confirm(
      'Veuillez confirmer',
      this.translate.instant(`page.valeur_parametrable.message_modification`), {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.router.navigate(['edit', situation.id], { relativeTo: this.route });
    }, () => { });
  }

}
