import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { flatMap, map, tap, catchError } from 'rxjs/operators';
import { Observable, of, throwError, Subject, BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Jwt } from '../../models/jwt.model';
import { CookieService } from 'ngx-cookie-service';
import { Cookie } from '../../models/cookie.model';
import { ApiCredentials } from '../../models/api-credentials.model';
import { LoginCredentials } from '../../models/login-credentials.model';
import { Scope } from '../../models/scope.model';
import { RefreshCredentials } from '../../models/refresh-credentials.model';
import { Router } from '@angular/router';
import { PortailService } from '../portail/portail.service';
import { UtilisateurTypeCode } from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';
import { Fail } from 'src/app/shared/errors/fail.error';

const AUTH_COOKIE_KEY = 'auth-credentials';
const LOGIN_URL = '/auth/login';
const CONNEXION_URL = '/auth/login';
const AUTH_URL = '/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {

  private readonly apiCredentialsSubject: BehaviorSubject<ApiCredentials>;
  private initialized = false;

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly cookieService: CookieService,
    private readonly router: Router,
    private readonly portailService: PortailService
  ) {
    this.apiCredentialsSubject = new BehaviorSubject(null);
    // this.apiCredentialsSubject.subscribe(
    //   cred => { console.info('[Authentification Service] new credentials value : ', cred); },
    //   error => { console.warn('[Authentification Service] credentials error : ', error); }
    // );
  }

  //////////////////////////////
  //   web services calls     //
  //////////////////////////////
  getToken(credentials: LoginCredentials): Observable<Jwt> {
    return this.http.post<object>('/api/intervenant/public/connexion/token',
      this.mapper.serialize(credentials)/*, { headers: { DisableInterceptors: HttpErrorInterceptor.KEY } }*/)
      .pipe(
        map(response => this.mapper.deserializeObject(response, Jwt)),
      );
  }

  setScope(scope: Scope): Observable<Jwt> {
    return this.http.post<object>('/api/intervenant/private/connexion/scope',
      this.mapper.serialize(scope))
      .pipe(
        map(response => this.mapper.deserializeObject(response, Jwt)),
      );
  }

  refreshToken(credentials: RefreshCredentials): Observable<Jwt> {
    return this.http.post<object>('/api/intervenant/public/connexion/refresh',
      this.mapper.serialize(credentials))
      .pipe(
        map(response => this.mapper.deserializeObject(response, Jwt)),
      );
  }

  //////////////////////////////
  //   cookies management     //
  //////////////////////////////
  saveCookie(jwt: Jwt, idDomaine: number, idOrganisme: number) {
    const cookie = Object.assign(new Cookie(), {
      jwt, idDomaine, idOrganisme
    });
    localStorage.setItem(AUTH_COOKIE_KEY, this.mapper.serializeToString(cookie));
    //this.cookieService.set(AUTH_COOKIE_KEY, this.mapper.serializeToString(cookie), cookie.jwt.refreshTokenTTL.clone().add(1, 'day').toDate(), '/', null, false, 'Lax');
  }

  deleteCookie(): void {
    this.cookieService.delete(AUTH_COOKIE_KEY, '/');
  }

  loadCookie(): Cookie {
    if (localStorage.getItem(AUTH_COOKIE_KEY)) {
      //if (this.cookieService.check(AUTH_COOKIE_KEY)) {
      // console.debug('[Authentification Service] raw cookie : ', this.cookieService.get(AUTH_COOKIE_KEY))
      //const cookie = this.mapper.deserializeObject(this.cookieService.get(AUTH_COOKIE_KEY), Cookie);
      const cookie = this.mapper.deserializeObject(localStorage.getItem(AUTH_COOKIE_KEY), Cookie);
      return cookie;
    }
    return null;
  }

  //////////////////////////////
  //   fonctional             //
  //////////////////////////////
  changeScope(idDomaine: number, idOrganisme: number): Observable<ApiCredentials> {
    const scope = Object.assign(new Scope(), { idDomaine, idOrganisme });
    return this.setScope(scope).pipe(
      map(jwt => {
        this.saveCookie(jwt, idDomaine, idOrganisme);
        const credentials = Object.assign(new ApiCredentials(), {
          token: jwt.token,
          idDomaine,
          idOrganisme
        });

        return credentials;
      })
    );
  }

  public logOut(withRedirection = false) {
    this.logout(withRedirection, this.apiCredentialsSubject.getValue());
  }

  private logout(withRedirection = false, lastcredentials: ApiCredentials) {
    const redirection = withRedirection ? { queryParams: { 'redirect-path': window.location.pathname } } : {};
    //this.cookieService.delete(AUTH_COOKIE_KEY, '/');
    localStorage.removeItem(AUTH_COOKIE_KEY);
    const utilisateurType = lastcredentials ? lastcredentials.utilisateurType : UtilisateurTypeCode.NONE;
    switch (utilisateurType) {
      case UtilisateurTypeCode.NONE:
      case UtilisateurTypeCode.ADMIN:
      case UtilisateurTypeCode.ORGANISME:
      case UtilisateurTypeCode.PI:
      case UtilisateurTypeCode.PO:
      case UtilisateurTypeCode.GP:
      case UtilisateurTypeCode.CT:
      case UtilisateurTypeCode.OC:
        this.router.navigate([LOGIN_URL], redirection);
        break;
      case UtilisateurTypeCode.OPERATEUR:
        //window.location.href = CONNEXION_URL;
        this.router.navigate([CONNEXION_URL], redirection);
        break;
      default:
        Fail.never(utilisateurType);
    }
  }

  redirectToChangeScope() {
    this.router.navigate([LOGIN_URL], { queryParams: { 'redirect-path': window.location.pathname, 'change-scope': 'true' } });
  }

  //////////////////////////////
  //   credentials management //
  //////////////////////////////
  public getApiCredentials(): Subject<ApiCredentials> {
    if (!this.initialized) {
      this.initialized = true;
      this.getApiCredentialsFromCookie().subscribe(
        credentials => { this.onNewApiCredentials(credentials); },
        error => { this.onError(error); }
      );
    }
    return this.apiCredentialsSubject;
  }

  public onNewApiCredentials(credentials: ApiCredentials): void {
    // console.debug('[Authentification Service] emit new credentials', credentials);
    this.apiCredentialsSubject.next(credentials);
  }

  public onError(error: any): void {
    if (!window.location.pathname.startsWith(AUTH_URL)) {
      // console.debug('[Authentification Service] redirect to login page');
      this.logout(true, error.lastCredentials);
    }
    // this.apiCredentialsSubject.error(error);
  }

  public refreshApiCredentials(): Observable<ApiCredentials> {
    return this.getApiCredentialsFromCookie(true).pipe(
      tap(credentials => this.onNewApiCredentials(credentials)),
      catchError(error => {
        this.onError(error);
        return throwError(error);
      })
    );
  }

  public getApiCredentialsFromCookie(forceRefresh = false): Observable<ApiCredentials> {
    // console.debug('[Authentification Service] load cookie');
    const cookie = this.loadCookie();

    if (cookie) {
      // console.debug('[Authentification Service] cookie found', cookie);
      const credentials = Object.assign(new ApiCredentials(), {
        token: cookie.jwt.token,
        idDomaine: cookie.idDomaine,
        idOrganisme: cookie.idOrganisme,
        utilisateurType: cookie.jwt.type,
        roles: cookie.jwt.roles,
        idIntervenant: cookie.jwt.idIntervenant,
        mailIntervenant: cookie.jwt.mailIntervenant,
        idPortail: cookie.jwt.idPortail,
        codePortail: cookie.jwt.codePortail,
      });

      if (!forceRefresh && cookie.jwt.tokenTTL.subtract(10, 'seconds').isAfter(moment())) {
        // console.debug('[Authentification Service] token not expired');
        return of(credentials);
      } else if (cookie.jwt.refreshTokenTTL.subtract(10, 'seconds').isAfter(moment())) {

        // console.debug('[Authentification Service] refresh-token not expired');
        const refreshCredentials = Object.assign(new RefreshCredentials(), {
          refreshToken: cookie.jwt.refreshToken,
          idDomaine: cookie.idDomaine,
          idOrganisme: cookie.idOrganisme
        });

        // console.debug('[Authentification Service] refresh token');
        return this.refreshToken(refreshCredentials).pipe(
          tap((jwt: Jwt) => {
            // console.debug('[Authentification Service] token refreshed');
            // console.debug('[Authentification Service] save token in cookie');
            this.saveCookie(jwt, cookie.idDomaine, cookie.idOrganisme);
          }),
          map((jwt: Jwt) => {
            const credentials = Object.assign(new ApiCredentials(), {
              token: jwt.token,
              idDomaine: cookie.idDomaine,
              idOrganisme: cookie.idOrganisme,
              utilisateurType: jwt.type,
              roles: jwt.roles,
              idIntervenant: jwt.idIntervenant,
              mailIntervenant: jwt.mailIntervenant,
              idPortail: jwt.idPortail,
              codePortail: jwt.codePortail,
            });
            return credentials;
          })
        );
      }
      return throwError({ message: 'auth cookie exipred', lastCredentials: credentials });
    }
    // console.debug('[Authentification Service] cookie unavailable or exipred');
    return throwError({ message: 'auth cookie unavailable' });
  }

  getOperateurToken(identifiantIntervenant: string, domaine: string, identifiantParent: string, token: string, portail: string): Observable<Jwt> {
    if (token && portail) {
      const httpHeaders: any = {};
      httpHeaders.Authorization = 'Bearer '.concat(token);
      httpHeaders['B-PORTAL-NAME'] = portail;

      return this.http.post<object>(`/api/intervenant/portal/portail/token`,
        {
          identifiantIntervenant,
          domaine,
          identifiantParent
        },
        {
          headers: new HttpHeaders(httpHeaders)
        }
      ).pipe(
        map(token => this.mapper.deserializeObject(token, Jwt))
      );

    } else {
      return this.portailService.getMockToken().pipe(
        flatMap(mockToken => {
          const httpHeaders: any = {};
          httpHeaders[mockToken.authHeader] = mockToken.authValue;
          httpHeaders[mockToken.portailHeader] = mockToken.portailCode;
          return this.http.post<object>(`/api/intervenant/portal/portail/token`,
            {
              identifiantIntervenant,
              domaine,
              identifiantParent
            },
            {
              headers: new HttpHeaders(httpHeaders)
            }
          ).pipe(
            map(token => this.mapper.deserializeObject(token, Jwt))
          );
        })
      );
    }
  }
}
