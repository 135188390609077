import { Injectable } from '@angular/core';

import { CahiersService as HabCahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import {Cahier} from '../../../habilitation/models/cahier.model';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CahiersService extends HabCahiersService {
  protected BASE_URL = '/api/declaration';

  public getCahierById(idCahier: number): Observable<Cahier | undefined> {
    // Si les cahiers sont déjà chargés, les utiliser directement
    if (CahiersService.cahiers) {
      return of(CahiersService.cahiers.find(cahier => cahier.id === idCahier));
    } else {
      // Sinon, charger les cahiers puis chercher celui correspondant à l'ID
      return this.getCahiers().pipe(
        map(cahiers => cahiers.find(cahier => cahier.id === idCahier)),
        catchError(error => {
          console.error('Error loading cahiers', error);
          return of(undefined); // Gestion basique des erreurs
        })
      );
    }
  }
}
