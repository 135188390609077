import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class HistogrammeRecolte {
  @JsonProperty('quantitePrevisionInitiale', Number)
  @Optional
  quantitePrevisionInitiale: number = undefined;

  @JsonProperty('quantiteDernierePrevision', Number)
  @Optional
  quantiteDernierePrevision: number = undefined;

  @JsonProperty('quantiteRecolte', Number)
  @Optional
  quantiteRecolte: number = undefined;
}
