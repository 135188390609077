<div class="content anpp">
  <div
    class="cards border-radius-onglet text-center"
    style="background: #fff; min-width: 1100px"
  >
    <div class="row" *ngIf="isPi || isGp || isAnpp">
      <div class="col-lg-7 text-left pl-4 pt-4">
        <select
          (change)="loadData()"
          class="form-control"
          style="display: inline; width: auto"
          [(ngModel)]="campagneSelected"
        >
          <option [value]="null" hidden></option>
          <option *ngFor="let camp of campagnes" [ngValue]="camp">
            {{ camp.libelle }}
          </option>
        </select>
        <span
          (click)="goToFormulaire()"
          class="pt-2 pl-2 mr-2 lienMain"
          type="button"
          *ngIf="peutCreerEngagement"
        >
          <fa-icon class="mr-1" icon="plus-square"></fa-icon>
          <span style="text-decoration: underline" translate
            >page.engagements.grid.actions.ajouter</span
          >
        </span>
      </div>
      <div class="col-lg-2 pt-4 pr-4 text-right">
        <div class="btn-group dropdown">
          <div ngbDropdown placement="bottom-start">
            <button
              [disabled]="!isAnpp || engagements.length === 0"
              class="btn btn-secondary dropdown-toggle btnprimary"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              ngbDropdownToggle
              aria-haspopup="true"
              aria-expanded="true"
              translate
            >
              <fa-icon icon="upload"></fa-icon>
              page.engagements.diffuser
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
              <button
                (click)="docPrepaOC()"
                ngbDropdownItem
                class="dropdown-item"
                translate
              >
                <fa-icon icon="file-alt"></fa-icon>
                page.engagements.doc-oc
              </button>
              <div class="dropdown-divider"></div>
              <button
                (click)="docPrepaCT()"
                ngbDropdownItem
                class="dropdown-item"
                translate
              >
                <fa-icon icon="file-alt"></fa-icon>
                page.engagements.doc-ct
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 pt-4 pr-4 text-right" *ngIf="isPi || isGp">
        <button
          [disabled]="engagements.length === 0"
          class="btn btn-secondary btnprimary"
          (click)="genererPdf()"
          translate
        >
          <fa-icon icon="upload"></fa-icon>
          page.engagements.upload
        </button>
      </div>
    </div>
    <div *ngIf="isPi || isGp || isAnpp" class="tableScroll">
      <table
        *ngIf="engagements"
        class="table table-striped overflow table-engagment"
      >
        <thead class="sticky-top">
          <tr>
            <th scope="col" translate>
              page.engagements.grid.column.operations
            </th>
            <th
              *ngIf="show('raisonSociale')"
              scope="col"
              style="position: relative"
            >
              <div translate>page.engagements.grid.column.raison-sociale</div>
            </th>
            <th
              *ngIf="show('typeEntreprise')"
              scope="col"
              style="position: relative"
            >
              <div translate>page.engagements.grid.column.type-entreprise</div>
            </th>
            <th
              *ngIf="show('statutEngagement')"
              scope="col"
              style="position: relative"
            >
              <div translate>page.engagements.grid.column.statut</div>
            </th>
            <th
              *ngIf="show('dateEngagement')"
              scope="col"
              style="position: relative"
            >
              <div translate>page.engagements.grid.column.date-engagement</div>
            </th>
            <th
              *ngIf="show('dateAudit')"
              scope="col"
              style="position: relative"
            >
              <div translate>page.engagements.grid.column.date</div>
            </th>
            <th
              *ngIf="show('activiteProduction')"
              scope="col"
              style="position: relative"
            >
              <div translate>page.engagements.grid.column.act-production</div>
            </th>
            <th
              *ngIf="show('activiteConditionnement')"
              scope="col"
              style="position: relative"
            >
              <div translate>
                page.engagements.grid.column.act-conditionnement
              </div>
            </th>
            <th
              *ngIf="show('utilisationLogo')"
              scope="col"
              style="position: relative"
            >
              <div translate>page.engagements.grid.column.utilisation-logo</div>
            </th>
            <th *ngIf="show('ville')" scope="col" style="position: relative">
              <div translate>page.engagements.grid.column.ville</div>
            </th>
            <th
              *ngIf="show('statutEngagementPrecedent')"
              scope="col"
              style="position: relative"
            >
              <div translate>
                page.engagements.grid.column.statut-engagement-precedent
              </div>
            </th>
            <th
              *ngIf="show('globalGap')"
              scope="col"
              style="position: relative"
            >
              <div translate>page.engagements.grid.column.global-gap</div>
            </th>
            <th *ngIf="show('hve')" scope="col" style="position: relative">
              <div translate>page.engagements.grid.column.hve</div>
            </th>
            <th *ngIf="show('brc')" scope="col" style="position: relative">
              <div translate>page.engagements.grid.column.brc</div>
            </th>
            <th *ngIf="show('ifs')" scope="col" style="position: relative">
              <div translate>page.engagements.grid.column.ifs</div>
            </th>
            <th
              *ngIf="show('organismeCertification')"
              scope="col"
              style="position: relative"
            >
              <div translate>
                page.engagements.grid.column.organisme-certification
              </div>
            </th>
            <th>
              <fa-icon
                class="clickable-icon"
                icon="ellipsis-h"
                (click)="showList()"
              ></fa-icon>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="engagements.length === 0">
            <td colspan="99" class="p-4">
              <fa-icon icon="exclamation-triangle"></fa-icon>&nbsp;
              <span translate>page.engagements.liste-vide</span>
            </td>
          </tr>
          <ng-container *ngIf="engagements.length > 0">
            <tr *ngFor="let engagement of engagements">
              <td>
                <div class="btn-group dropright">
                  <div ngbDropdown placement="bottom-left">
                    <button
                      class="btn btn-secondary dropdown-toggle btnprimary btn-sm"
                      id="dropdownConfig"
                      ngbDropdownToggle
                    >
                      <fa-icon icon="cog"></fa-icon>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                      <button
                        (click)="modifierEngagement(engagement)"
                        ngbDropdownItem
                        class="dropdown-item"
                        *ngIf="isAnpp"
                        translate
                      >
                        <fa-icon icon="pencil-alt"></fa-icon>
                        page.engagements.grid.actions.modifier-engagement
                      </button>
                      <button
                        (click)="modifierEngagement(engagement)"
                        ngbDropdownItem
                        class="dropdown-item"
                        *ngIf="isGp || isPi"
                        translate
                      >
                        <fa-icon icon="pencil-alt"></fa-icon>
                        page.engagements.grid.actions.voir
                      </button>
                      <div
                        class="dropdown-divider"
                        *ngIf="
                          isAnpp &&
                          engagement.codeStatut != 'DP' &&
                          engagement.codeStatut != 'AE' &&
                          engagement.codeStatut != 'ABE'
                        "
                      ></div>
                      <button
                        (click)="modifierStatutEngagement(engagement)"
                        *ngIf="
                          isAnpp &&
                          engagement.codeStatut != 'DP' &&
                          engagement.codeStatut != 'AE' &&
                          engagement.codeStatut != 'ABE'
                        "
                        ngbDropdownItem
                        class="dropdown-item"
                        translate
                      >
                        <fa-icon icon="copy"></fa-icon>
                        page.engagements.grid.actions.modifier-statut
                      </button>
                      <button
                        (click)="genererPdf(engagement)"
                        *ngIf="isAnpp"
                        ngbDropdownItem
                        class="dropdown-item"
                        translate
                      >
                        <fa-icon icon="upload"></fa-icon>
                        page.engagements.upload
                      </button>
                    </div>
                  </div>
                </div>
              </td>
              <td *ngIf="show('raisonSociale')">
                {{ engagement.entreprise.raisonSociale }}
              </td>
              <td *ngIf="show('typeEntreprise')">
                {{ engagement.entreprise.sousType }}
              </td>
              <td *ngIf="show('statutEngagement')">
                {{ engagement.libelleStatut }}
              </td>
              <td *ngIf="show('dateEngagement')">
                {{ engagement.dateEngagement | date : "shortDate" }}
              </td>
              <td *ngIf="show('dateAudit')">
                {{ engagement.dateAudit | date : "shortDate" }}
              </td>
              <td *ngIf="show('activiteProduction')">
                {{ engagement.activiteProduction ? "Oui" : "Non" }}
              </td>
              <td *ngIf="show('activiteConditionnement')">
                {{ engagement.activiteConditionnement ? "Oui" : "Non" }}
              </td>
              <td *ngIf="show('utilisationLogo')">
                {{ engagement.utilisationLogo ? "Oui" : "Non" }}
              </td>
              <td *ngIf="show('ville')">{{ engagement.entreprise.commune }}</td>
              <td *ngIf="show('statutEngagementPrecedent')">
                {{ engagement.libelleStatutPrecedent }}
              </td>
              <td *ngIf="show('globalGap')">
                {{
                  bindValue(engagement.entreprise.sousType)
                    ? engagement.listEngagementDetail[0].engagementGlobalGap
                      ? "Oui"
                      : "Non"
                    : ""
                }}
              </td>
              <td *ngIf="show('hve')">
                {{
                  bindValue(engagement.entreprise.sousType)
                    ? engagement.listEngagementDetail[0].engagementHve
                      ? "Oui"
                      : "Non"
                    : ""
                }}
              </td>
              <td *ngIf="show('brc')">
                {{
                  bindValue(engagement.entreprise.sousType)
                    ? engagement.listEngagementDetail[0].certificationBrc
                      ? "Oui"
                      : "Non"
                    : ""
                }}
              </td>
              <td *ngIf="show('ifs')">
                {{
                  bindValue(engagement.entreprise.sousType)
                    ? engagement.listEngagementDetail[0].certificationIfs
                      ? "Oui"
                      : "Non"
                    : ""
                }}
              </td>
              <td *ngIf="show('organismeCertification')">
                {{ engagement.organismeCertification.raisonSociale }}
              </td>
              <td></td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <br />
    <h3 *ngIf="isCt || isOc">
      Liste des engagements {{ campagneSelected.libelle }}
    </h3>
    <div *ngIf="isCt || isOc" style="overflow: auto; max-height: 75vh">
      <table *ngIf="listCtOc" class="table table-striped">
        <thead>
          <tr>
            <th scope="col" style="position: relative">
              <div translate>page.engagements.grid.column.raison-sociale</div>
            </th>
            <th scope="col" style="position: relative">
              <div translate>page.engagements.grid.column.type-entreprise</div>
            </th>
            <th scope="col" style="position: relative">
              <div translate>page.engagements.grid.column.date-engagement</div>
            </th>
            <th scope="col" style="position: relative">
              <div translate>page.engagements.grid.column.conseiller</div>
            </th>
            <!-- <th scope="col" style="position: relative">
              <div translate>page.engagements.grid.column.surface-totale</div>
            </th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="listCtOc.length === 0">
            <td colspan="99" class="p-4">
              <fa-icon icon="exclamation-triangle"></fa-icon>&nbsp;
              <span translate>page.engagements.liste-vide</span>
            </td>
          </tr>
          <ng-container *ngIf="listCtOc.length > 0">
            <tr *ngFor="let item of listCtOc">
              <td>{{ item.raisonSociale }}</td>
              <td>{{ item.typeEntreprise }}</td>
              <td>{{ item.dateEngagement | date : "shortDate" }}</td>
              <td>{{ item.conseillerTechnique }}</td>
              <!-- <td>{{ item.surfaceTotale | number : "1.0-2" }}</td> -->
              <td></td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
