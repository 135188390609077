import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Observable } from 'rxjs';
import { SiteActivite } from '../../models/site-activite.model';
import { map, tap } from 'rxjs/operators';
import { ClearCache, Cache } from 'src/app/core/services/cache/cache.service';

@Injectable({
  providedIn: 'root'
})
export class SitesActivitesService {

  static sitesActivites: SiteActivite[];

  static getSitesAcitivtes() {
    return SitesActivitesService.sitesActivites;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
  ) { }

  @ClearCache
  clearCache() { }

  @Cache()
  public getSitesAcitivtes(idDomaine: number): Observable<SiteActivite[]> {
    return this.http.get<object[]>(`/api/declaration/private/domaines/${idDomaine}/sites-activites`).pipe(
      map(sitesActivites => this.mapper.deserializeArray(sitesActivites, SiteActivite)),
      tap(sitesActivites => SitesActivitesService.sitesActivites = sitesActivites)
    );
  }
}
