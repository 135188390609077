import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';

import { AcheteursService } from 'src/app/data/declaration/services/acheteurs/acheteurs.service';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { AcheteurExport } from 'src/app/data/declaration/models/acheteur-export.model';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';
import { Pays } from 'src/app/data/intervenant/models/pays.model';
import { DomaineSpec } from 'src/app/data/intervenant/models/domaine-spec.model';
import { Operateur } from 'src/app/data/intervenant/models/operateur.model';

@Component({
  selector: 'app-rechercher-acheteur',
  templateUrl: './rechercher-acheteur.component.html',
  styleUrls: ['./rechercher-acheteur.component.scss']
})
export class RechercherAcheteurComponent implements OnInit {

  readonly CHOIX_TYPE_ACHETEUR = 'CHOIX_TYPE_ACHETEUR';
  readonly SEARCH_FRANCE = 'SEARCH_FRANCE';
  readonly ADD_FRANCE = 'ADD_FRANCE';
  readonly SEARCH_EXPORT = 'SEARCH_EXPORT';
  readonly CREATE_EXPORT = 'CREATE_EXPORT';

  step = this.CHOIX_TYPE_ACHETEUR;

  @Input()
  idOperateur: number;
  pays: Pays[];
  acheteursExport: AcheteurExport[];
  acheteursFrance: Operateur[];

  formAcheteur: FormGroup;
  formOperateur: FormGroup;

  searchStringInput: string;
  searchString: string;
  searchStringUpdate = new Subject<string>();

  constructor(
    private readonly acheteursService: AcheteursService,
    private readonly operateurService: OperateursService,
    private readonly modal: NgbActiveModal,
    private readonly fb: FormBuilder,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly loader: AdvBootstrapLoaderService,
    private readonly refService: ReferencesService
  ) { }

  ngOnInit() {
    this.searchStringUpdate.pipe(
      debounceTime(200),
      distinctUntilChanged())
      .subscribe(value => {
        this.searchString = value;
      });

    this.formAcheteur = this.fb.group({
      raisonSociale: [undefined, Validators.required],
      adresse: [undefined],
      codePostal: [undefined, Validators.required],
      ville: [undefined, Validators.required],
      pays: [undefined, Validators.required],
    });

    this.formOperateur = this.fb.group({
      siret: [undefined, Validators.required]
    });
  }

  changeStep(step: string) {
    this.step = step;

    switch (step) {
      case this.SEARCH_FRANCE:
        this.acheteursFrance = [];
        this.acheteursService.getAcheteursFrance(this.idOperateur).pipe(
          this.loader.operator()
        ).subscribe(acheteurs => {
          this.acheteursFrance = acheteurs;
        });
        break;
      case this.ADD_FRANCE:
        this.formOperateur.get('siret').reset(undefined, { emitEvent: false });
        break;
      case this.SEARCH_EXPORT:
        this.acheteursExport = [];
        this.acheteursService.getAcheteursExport(this.idOperateur).pipe(
          this.loader.operator()
        ).subscribe(acheteurs => {
          this.acheteursExport = acheteurs;
        });
        break;
      case this.CREATE_EXPORT:
        this.refService.getReferences().subscribe(ref => {
          this.pays = ref.pays;
        });
        break;
    }
  }

  dismiss() {
    this.modal.dismiss();
  }

  createAcheteurExport() {
    if (this.formAcheteur.valid) {
      const acheteur = new AcheteurExport();
      acheteur.raisonSociale = this.formAcheteur.get('raisonSociale').value;
      acheteur.adresse = this.formAcheteur.get('adresse').value;
      acheteur.codePostal = this.formAcheteur.get('codePostal').value;
      acheteur.ville = this.formAcheteur.get('ville').value;
      acheteur.pays = this.formAcheteur.get('pays').value;

      this.acheteursService.creerAcheteur(this.idOperateur, acheteur).subscribe(id => {
        this.translate.get('page.declarations.synthese.modal.recherche-acheteur.creation-ok').subscribe(msg => {
          this.toastr.success('', msg);
        });

        acheteur.id = id;
        this.modal.close({ acheteurExport: acheteur });
      });
    }
  }

  searchAchteurFrance() {
    if (this.formOperateur.valid) {
      this.operateurService.getAutresOperateurs({ code: 'SIRET' } as DomaineSpec, this.formOperateur.get('siret').value).pipe(
        this.loader.operator()
      ).subscribe(operateurs => {
        this.acheteursFrance = operateurs;
      });
    }
  }

  selectAcheteurExport(acheteur: AcheteurExport) {
    this.modal.close({ acheteurExport: acheteur });
  }

  selectAcheteurFrance(acheteur: Operateur) {
    this.modal.close({ acheteurFrance: acheteur });
  }
}
