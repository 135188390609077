import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class LoginCredentials {

  @JsonProperty('login', String)
  login: string = undefined;

  @JsonProperty('password', String)
  password: string = undefined;
}
