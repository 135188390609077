import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DomainesService } from 'src/app/data/intervenant/services/domaines/domaines.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { DomaineSpec } from 'src/app/data/intervenant/models/domaine-spec.model';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import {
  EditInformationsMineuresComponent
} from "../informations-operateur/modals/edit-informations-mineures/edit-informations-mineures.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AddOperateurComponent} from "./modal/add-operateur.component";

@Component({
  selector: 'app-ajout-operateur',
  templateUrl: './ajout-operateur.component.html',
  styleUrls: ['./ajout-operateur.component.scss']
})
export class AjoutOperateurComponent implements OnInit {
  formGroup: FormGroup;

  specs: DomaineSpec[];

  noResult = false;

  get spec() { return this.formGroup.get('spec'); }
  get search() { return this.formGroup.get('search'); }

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly domainesService: DomainesService,
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly operateursService: OperateursService,
    private readonly router: Router,
    private readonly modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      spec: [undefined, Validators.required],
      search: [undefined, Validators.required]
    });

    this.formGroup.valueChanges.subscribe(val => {
      this.noResult = false;
    });

    this.domainesService.getSpecs(SessionContext.get('idDomaine')).pipe(
      this.loaderService.operator()
    ).subscribe(domaineSpecs => {
      this.specs = domaineSpecs;

      if (this.specs.length > 0) {
        this.spec.setValue(this.specs[0]);
      }
    });
  }

  submit() {
    if (this.formGroup.valid) {
      this.operateursService.getAutresOperateurs(this.spec.value, this.search.value).pipe(
        this.loaderService.operator()
      ).subscribe(operateurs => {
        if (operateurs.length === 1) {
          const operateur = operateurs[0];

          this.router.navigate([operateur.id, 'habilitations'], { relativeTo: this.activatedRoute.parent });
        } else {
          this.noResult = true;
        }
      });
    }
  }

  addOperateur() {
    const modale = this.modalService.open(AddOperateurComponent, { backdrop: 'static', size: 'lg' });
    modale.result.then(
      (result) => {
        if (result === 'operateur_created') {
          window.location.reload(); // Méthode pour rafraîchir les données
        }
      },
      error => {
        throw error;
      }
    );
  }
}
