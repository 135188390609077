import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AdvBootstrapLoaderService } from "@adv/bootstrap-loader";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AdvBootstrapModalService } from "@adv/bootstrap-modal";
import { UniteCulturaleService } from "../../../../../../../data/declaration/services/parcellaire/unite-culturale.service";
import { TranslateService } from "@ngx-translate/core";
import { RefCampagnesService } from "../../../../../../../data/declaration/services/ref-campagnes/ref-campagnes.service";
import { Campagne } from "../../../../../../../data/declaration/models/campagne.model";
import { UniteCulturale } from "../../../../../../../data/declaration/models/unite-culturale.model";
import * as moment from "moment";
import { ExportToCsv } from "export-to-csv";
import { Entreprise } from "../../../../../../../data/declaration/models/entreprise.model";
import { UniteCulturaleExport } from "src/app/data/declaration/models/unite-culturale-export.model";
import { Fichier } from "src/app/data/edition/models/fichier.model";
import { FileDownloadTools } from "src/app/shared/file-download-tools/file-download-tools";
import { SessionContext } from "src/app/core/services/config/app.settings";
import { SyntheseInventaireEdition } from "src/app/data/edition/models/synthese-inventaire-edition";
import { SyntheseInventaireUniteEdition } from "src/app/data/edition/models/synthese-inventaire-unite-edition";
import { SyntheseInventaireEspeceEdition } from "src/app/data/edition/models/synthese-inventaire-espece-edition";

@Component({
  selector: "app-export-verger",
  templateUrl: "./export-verger.component.html",
  styleUrls: ["./export-verger.component.scss"],
})
export class ExportVergerComponent implements OnInit {
  formGroup: FormGroup;
  refCampagnes: Campagne[];
  entreprises: number[] = [];
  camp: Campagne;
  uniteCulturales: UniteCulturaleExport[] = [];
  @Input() uniteCulturalesSelectionnees: UniteCulturale[];
  @Input() idEntreprise: number;
  @Input() syntheseInventaireEdition: SyntheseInventaireEdition;

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly fb: FormBuilder,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly uniteCulturaleService: UniteCulturaleService,
    private readonly translate: TranslateService,
    private readonly campagneService: RefCampagnesService
  ) {}

  get campagne() {
    return this.formGroup.get("campagne");
  }

  get type() {
    return this.formGroup.get("type");
  }

  ngOnInit() {
    this.campagneService.getCampagneLastThreeYears().subscribe((campagnes) => {
      this.refCampagnes = campagnes;
    });
    this.formGroup = this.fb.group({
      campagne: [undefined, Validators.required],
      type: ["pdf", Validators.required],
    });
  }

  cancel() {
    this.modal.dismiss();
  }

  submit() {
    if (this.formGroup.valid) {
      this.entreprises = [];
      this.entreprises.push(this.idEntreprise);
      this.uniteCulturaleService
        .exporterInventaireVerger(this.entreprises, this.campagne.value, false)
        .subscribe((unites) => {
          this.uniteCulturales = unites.sort((a, b) =>
            a.numBlocFruitier > b.numBlocFruitier ? 1 : -1
          );
          const selectedCampagne = this.refCampagnes.find(
            (camp) => camp.id == this.campagne.value
          );
          this.syntheseInventaireEdition.campagne = selectedCampagne.libelle;
          if (this.uniteCulturales.length > 0) {
            switch (this.type.value) {
              case "pdf":
                this.syntheseInventaireEdition = this.genererSyntheseEditionPDF(
                  this.syntheseInventaireEdition
                );
                this.exporterInventaireVergersPdf(
                  this.syntheseInventaireEdition
                );
                break;
              case "csv":
                this.uniteCulturaleService.exporterInventaireVergerCsv(
                  this.uniteCulturales
                );
                break;
            }
            this.modal.close();
          } else {
            this.modalsService
              .error(
                this.translate.instant(
                  `page.inventaire-verger.modals.exporterInventaire.exportError`
                ),
                null,
                { windowClass: "anpp" }
              )
              .then(() => {});
          }
        });
    } else {
      this.modalsService
        .error(
          this.translate.instant(
            `page.inventaire-verger.modals.exporterInventaire.exportSelectError`
          ),
          null,
          { windowClass: "anpp" }
        )
        .then(() => {});
    }
  }

  exporterInventaireVergersPdf(
    syntheseInventaireEdition: SyntheseInventaireEdition
  ): void {
    this.uniteCulturaleService
      .exporterInventaireVergerPdf(
        SessionContext.get("idOrganisme"),
        syntheseInventaireEdition
      )
      .subscribe((fichier: Fichier): void => {
        const fileURL = URL.createObjectURL(
          FileDownloadTools.b64ToBlob(fichier.base64, "application/pdf")
        );
        window.open(fileURL);
      });
  }

  genererSyntheseEditionPDF(
    syntheseInventaireEdition: SyntheseInventaireEdition
  ): SyntheseInventaireEdition {
    syntheseInventaireEdition.syntheseInventaireEspeceEditionList = [];
    syntheseInventaireEdition.surfaceTotale = 0;
    syntheseInventaireEdition.surfaceIrriguee = 0;
    syntheseInventaireEdition.surfaceGel = 0;
    syntheseInventaireEdition.surfaceBio = 0;
    syntheseInventaireEdition.surfaceGrele = 0;

    this.uniteCulturales.forEach((uniteCulturale) => {
      let especeExistante =
        syntheseInventaireEdition.syntheseInventaireEspeceEditionList.find(
          (espece) => espece.libelleEspece === uniteCulturale.espece
        );
      let uniteAcreer: SyntheseInventaireUniteEdition =
        new SyntheseInventaireUniteEdition();
      uniteAcreer.numBlocFruitier = uniteCulturale.numBlocFruitier;
      uniteAcreer.codeParcelle = uniteCulturale.codeParcelle;
      if (uniteCulturale.lieuDit) {
        uniteAcreer.lieuDit = uniteCulturale.lieuDit;
      }
      if (uniteCulturale.commune) {
        uniteAcreer.commune = uniteCulturale.commune;
      }
      if (uniteCulturale.refCadastrale) {
        uniteAcreer.referenceCadastrale = uniteCulturale.refCadastrale;
      }

      uniteAcreer.numTracabilite = uniteCulturale.numTracabilite;
      uniteAcreer.variete = uniteCulturale.variete;
      uniteAcreer.clone = uniteCulturale.clone;
      if (uniteCulturale.porteGreffe) {
        uniteAcreer.porteGreffe = uniteCulturale.porteGreffe;
      }
      if (uniteCulturale.forme) {
        uniteAcreer.forme = uniteCulturale.forme;
      }
      uniteAcreer.distanceInterRang =
        Math.round(uniteCulturale.distanceInterRang * 10000) / 10000;
      uniteAcreer.distanceIntraRang =
        Math.round(uniteCulturale.distanceIntraRang * 10000) / 10000;
      uniteAcreer.nbArbre = uniteCulturale.nbArbre;
      uniteAcreer.surfaceCalculee =
        Math.round(uniteCulturale.surfaceCalculee * 10000) / 10000;
      uniteAcreer.anneePlantation = Number.parseInt(
        uniteCulturale.anneePlantation
      );
      uniteAcreer.anneePremiereFeuille = Number.parseInt(
        uniteCulturale.anneePremiereFeuille
      );
      uniteAcreer.anneeSurgreffage = Number.parseInt(
        uniteCulturale.anneeSurgreffage
      );
      uniteAcreer.refSystemeIrrigation =
        uniteCulturale.systemeIrrigation &&
        uniteCulturale.systemeIrrigation != "Pas d'équipement";
      uniteAcreer.refProtectionGel =
        uniteCulturale.protectionGel &&
        uniteCulturale.protectionGel != "Pas d'équipement";
      uniteAcreer.refProtectionGrele =
        uniteCulturale.protectionGrele &&
        uniteCulturale.protectionGrele != "Pas d'équipement";
      uniteAcreer.refCultureBio =
        uniteCulturale.cultureBio && uniteCulturale.cultureBio != "Non bio";
      if (especeExistante) {
        especeExistante.uniteCulturaleEditionList.push(uniteAcreer);
        especeExistante.surface =
          Math.round(
            (especeExistante.surface + uniteAcreer.surfaceCalculee) * 10000
          ) / 10000;
        if (uniteAcreer.refSystemeIrrigation == true) {
          especeExistante.surfaceIrriguee =
            Math.round(
              (especeExistante.surfaceIrriguee + uniteAcreer.surfaceCalculee) *
                10000
            ) / 10000;
        }
        if (uniteAcreer.refProtectionGel == true) {
          especeExistante.surfaceGel =
            Math.round(
              (especeExistante.surfaceGel + uniteAcreer.surfaceCalculee) * 10000
            ) / 10000;
        }
        if (uniteAcreer.refProtectionGrele == true) {
          especeExistante.surfaceGrele =
            Math.round(
              (especeExistante.surfaceGrele + uniteAcreer.surfaceCalculee) *
                10000
            ) / 10000;
        }
        if (uniteAcreer.refCultureBio == true) {
          especeExistante.surfaceBio =
            Math.round(
              (especeExistante.surfaceBio + uniteAcreer.surfaceCalculee) * 10000
            ) / 10000;
        }
      } else {
        let especeACreee: SyntheseInventaireEspeceEdition =
          new SyntheseInventaireEspeceEdition();
        especeACreee.libelleEspece = uniteCulturale.espece;
        especeACreee.uniteCulturaleEditionList = [];
        especeACreee.uniteCulturaleEditionList.push(uniteAcreer);
        especeACreee.surface =
          Math.round(uniteAcreer.surfaceCalculee * 10000) / 10000;
        especeACreee.surfaceIrriguee = 0;
        especeACreee.surfaceGel = 0;
        especeACreee.surfaceBio = 0;
        especeACreee.surfaceGrele = 0;
        if (uniteAcreer.refSystemeIrrigation == true) {
          especeACreee.surfaceIrriguee =
            Math.round(uniteAcreer.surfaceCalculee * 10000) / 10000;
        }
        if (uniteAcreer.refProtectionGel == true) {
          especeACreee.surfaceGel =
            Math.round(uniteAcreer.surfaceCalculee * 10000) / 10000;
        }
        if (uniteAcreer.refProtectionGrele == true) {
          especeACreee.surfaceGrele =
            Math.round(uniteAcreer.surfaceCalculee * 10000) / 10000;
        }
        if (uniteAcreer.refCultureBio == true) {
          especeACreee.surfaceBio =
            Math.round(uniteAcreer.surfaceCalculee * 10000) / 10000;
        }
        syntheseInventaireEdition.syntheseInventaireEspeceEditionList.push(
          especeACreee
        );
      }
    });
    syntheseInventaireEdition.syntheseInventaireEspeceEditionList.forEach(
      (espece) => {
        syntheseInventaireEdition.surfaceTotale =
          Math.round(
            (syntheseInventaireEdition.surfaceTotale + espece.surface) * 10000
          ) / 10000;
        syntheseInventaireEdition.surfaceIrriguee =
          Math.round(
            (syntheseInventaireEdition.surfaceIrriguee +
              espece.surfaceIrriguee) *
              10000
          ) / 10000;
        syntheseInventaireEdition.surfaceGel =
          Math.round(
            (syntheseInventaireEdition.surfaceGel + espece.surfaceGel) * 10000
          ) / 10000;
        syntheseInventaireEdition.surfaceBio =
          Math.round(
            (syntheseInventaireEdition.surfaceBio + espece.surfaceBio) * 10000
          ) / 10000;
        syntheseInventaireEdition.surfaceGrele =
          Math.round(
            (syntheseInventaireEdition.surfaceGrele + espece.surfaceGrele) *
              10000
          ) / 10000;
      }
    );
    return syntheseInventaireEdition;
  }
}
