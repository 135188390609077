import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';
import { Produit } from '../../habilitation/models/produit.model';

@JsonObject
export class SyntheseVolumesIGP {
  @JsonProperty('codeProduit', String)
  codeProduit: string = undefined;

  produit: Produit;

  @JsonProperty('surface', Number)
  surface = 0;

  @JsonProperty('resteARevendiquer', Number)
  resteARevendiquer = 0;

  @JsonProperty('varRevendiqueChangementsDeno', Number)
  varRevendiqueChangementsDeno = 0;

  @JsonProperty('varRevendiqueDeclassement', Number)
  varRevendiqueDeclassement = 0;

  @JsonProperty('volConditionnements', Number)
  volConditionnements = 0;

  @JsonProperty('volIssusDr', Number)
  volIssusDr = 0;

  @JsonProperty('volRevendiques', Number)
  volRevendiques = 0;

  @JsonProperty('volTransactions', Number)
  volTransactions = 0;
}
