<div class="content">
  <div class="content-panel">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            <span translate>page.common.habilitations.demarches</span>
            <div ngbDropdown class="dropdown" *hasRole="'HABILITATION_HABILITATION_ECRITURE'">
              <strong ngbDropdownToggle class="btn-sm dropdown-toggle pointer" data-toggle="dropdown" translate="">
                page.operateurs.habilitations.ajout-demarche
              </strong>
              <div class="dropdown-menu" ngbDropdownMenu>
                <span *ngFor="let cahier of getUnusedCahiers()" class="dropdown-item pointer" (click)="addCahier(cahier)">{{cahier.libelle}}</span>
              </div>
            </div>
          </th>
          <th *ngFor="let activite of activites" scope="col" t-id="habiliations-activites">{{activite.libelle}}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="cahiersFiltered && (cahiersFiltered.length > 0); else noHabilitation">
          <tr *ngFor="let cahier of cahiersFiltered">
            <th scope="row" t-id="habiliations-cahiers">{{cahier.libelle}}</th>
            <td *ngFor="let activite of activites">
              <ng-container *ngTemplateOutlet="habilitationTemplate;context:{cahier: cahier, activite: activite, habilitation: getHabilitation(cahier, activite)}">
              </ng-container>
            </td>
          </tr>
        </ng-container>

        <ng-template #noHabilitation>
          <tr>
            <td [attr.colspan]="activites ? (activites.length + 1) : 0">
              <div translate>page.operateurs.habilitations.vide</div>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
</div>

<ng-template #habilitationTemplate let-habilitation="habilitation" let-cahier="cahier" let-activite="activite">
  <div *ngIf="habilitation">
    <app-statut-badge (click)="updateHabilitation(habilitation)" *hasRole="'HABILITATION_HABILITATION_ECRITURE'; elseDisable"
      [statut]="habilitation.statut" class="pointer"></app-statut-badge>
  </div>
  <div *ngIf="!habilitation">
    <div *hasRole="'HABILITATION_HABILITATION_ECRITURE'">
      <button type="button" *ngIf="cahierHasActivite(cahier, activite); else addNotAllowed" (click)="createHabilitation(cahier, activite)"
        class="btn btn-outline-secondary btn-add" t-id="add-habilitation-button">
        <fa-icon icon="plus"></fa-icon>
      </button>
      <ng-template #addNotAllowed>
        <button type="button" class="btn btn-outline-secondary btn-add" disabled>
          <fa-icon icon="ban"></fa-icon>
        </button>
      </ng-template>
    </div>
  </div>
</ng-template>