import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { ControleManquementEdition } from './controle-manquement-edition.model';
import { ControleEchantillonEdition } from './controle-echantillon-edition.model';
import { ControleOperateurEdition } from './controle-operateur-edition.model';
import { ControleSiteEdition } from './controle-site-edition.model';
import { OrganismeEdition } from './organisme-edition.model';

@JsonObject
export class ControleEdition {
    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('typeControle', String)
    @Optional
    typeControle: string;

    @JsonProperty('dateNotification', String)
    @Optional
    dateNotification: string;

    @JsonProperty('actionCorrectiveProposee', String)
    @Optional
    actionCorrectiveProposee: string;

    @JsonProperty('estConforme', String)
    @Optional
    estConforme: string;

    @JsonProperty('manquements', [ControleManquementEdition])
    @Optional
    manquements: ControleManquementEdition[];

    @JsonProperty('echantillon', ControleEchantillonEdition)
    @Optional
    echantillon: ControleEchantillonEdition;

    @JsonProperty('operateur', ControleOperateurEdition)
    @Optional
    operateur: ControleOperateurEdition;

    @JsonProperty('site', ControleSiteEdition)
    @Optional
    site: ControleSiteEdition;

    @JsonProperty('organisme', [OrganismeEdition])
    @Optional
    organisme: OrganismeEdition;
}
