import { JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";
import { ManquementCommissionEdition } from "./manquement-commission-edition.model";
import { ManquementMouvementEdition } from "./manquement-mouvement-edition.model";
import { ManquementOperateurEdition } from "./manquement-operateur-edition.model";
import { OrganismeEdition } from "./organisme-edition.model";

@JsonObject
export class ManquementEdition{

    @JsonProperty('numero', String)
    @Optional
    numero: string;
    @JsonProperty('libelle', String)
    @Optional
    libelle: string;
    @JsonProperty('gravite', String)
    @Optional
    gravite: string;
    @JsonProperty('commentaireConstat', String)
    @Optional
    commentaireConstat: string;
    @JsonProperty('commentaireOperateur', String)
    @Optional
    commentaireOperateur: string;
    @JsonProperty('commentaireOrganisme', String)
    @Optional
    commentaireOrganisme: string;
    @JsonProperty('commentaireDecision', String)
    @Optional
    commentaireDecision: string;
    @JsonProperty('dateNotification', String)
    @Optional
    dateNotification: string;
    @JsonProperty('dateConstat', String)
    @Optional
    dateConstat: string;

    @JsonProperty('mouvement', ManquementMouvementEdition)
    @Optional
    mouvement: ManquementMouvementEdition;

    @JsonProperty('operateur', ManquementOperateurEdition)
    operateur: ManquementOperateurEdition;
    
    @JsonProperty('commission', ManquementCommissionEdition)
    @Optional
    commission: ManquementCommissionEdition;

    @JsonProperty('organisme', [OrganismeEdition])
    @Optional
    organisme: OrganismeEdition;
}