import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Groupe } from '../../models/groupe.models';

@Injectable({
  providedIn: 'root'
})
export class GroupesDiffusionService {

  constructor(  
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
    ) { }
    

    getGroupes(): Observable<Groupe[]> {
      return  this.http.get<Groupe[]>(`/api/intervenant/private/groupes`).pipe(
        map(groupe => this.mapper.deserializeArray(groupe, Groupe))
      );        
    }

    public creerGroupe(groupe: Groupe): Observable<number> {
      return this.http.post(`/api/intervenant/private/groupes`, this.mapper.serialize(groupe,Groupe) , { observe: 'response' }).pipe(
        map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
      );
    }  
    
    public modifierGroupe(groupe: Groupe): Observable<void> {
      return this.http.put<void>(`/api/intervenant/private/groupes/${groupe.id}`, groupe);
    }

    public supprimerGroupe(idGroupe: number): Observable<void> {
      return this.http.delete<void>(`/api/intervenant/private/groupes/${idGroupe}`);
    }

    public creerContactGroupe(idContact: number, idGroupe: number): Observable<void> {
      return this.http.put<void>(`/api/intervenant/private/groupes/${idGroupe}/${idContact}`,idContact);
    }  

    public supprimerContactGroupe(idContact: number, idGroupe: number): Observable<void> {
      return this.http.delete<void>(`/api/intervenant/private/groupes/${idGroupe}/${idContact}`);
    }
}
