import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Variete } from 'src/app/data/declaration/models/variete.model';
import { Espece } from 'src/app/data/declaration/models/espece.model';
import { VarieteService } from 'src/app/data/declaration/services/parcellaire/variete.service';
import { forkJoin, of } from 'rxjs';
import { EspeceService } from 'src/app/data/declaration/services/parcellaire/espece.service';

@Component({
  selector: 'app-edit-variete',
  templateUrl: './edit-variete.component.html',
  styleUrls: ['./edit-variete.component.scss']
})
export class EditVarieteComponent implements OnInit {

  formGroup: FormGroup;
  creation = false;
  title: string;
  variete: Variete;
  especes: Espece[];

  get code() { return this.formGroup.get('code'); }
  get libelle() { return this.formGroup.get('libelle'); }
  get espece() { return this.formGroup.get('espece'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly varieteService: VarieteService,
    private readonly especeService: EspeceService) { }

  ngOnInit() {

    const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    if (!id) {
      this.creation = true;
    }
      this.loadData(id);   

  }

  loadData(id: number) {

    this.title = this.creation ? 'page.valeur_parametrable.form.title.add_variete': 'page.valeur_parametrable.form.title.edit_variete';
    
    forkJoin(
    !this.creation ? this.varieteService.getVariete(+id) : of(new Variete()),
    this.especeService.getEspeces()
    )
    .pipe(
      this.loaderService.operator()
    ).subscribe(([variete,especes]) => {
    
    this.variete = variete ; 
    this.especes = especes;
    this.initForm((variete.id) ? variete : null);
     
    });
  }

  private initForm(variete?: Variete): void {
 
    this.formGroup = this.fb.group({
      code: [{ value: ((variete) ? variete.code: undefined), disabled: false }, Validators.required],
      libelle: [{ value: ((variete) ? variete.libelle : undefined), disabled: false }, Validators.required],
      espece: [{ value: ((variete) ? this.especes.find(es => es.id === variete.espece.id) : undefined), disabled: false }, Validators.required]
    });
  }

  submit() {
    if (this.formGroup.valid) {
      this.variete.code = this.code.value;
      this.variete.libelle = this.libelle.value;
      this.variete.espece = this.espece.value;
     
      if (this.variete.id == null) {
        this.varieteService.creerVariete(this.variete).subscribe(idVariete => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
        });
      } else {        
        this.varieteService.modifierVariete(this.variete).subscribe( () => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
         } );
      }
     
    }
  }

}
