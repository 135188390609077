import { JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";

@JsonObject
export class ManquementOperateurEdition{
    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('raisonSociale', String)
    @Optional
    raisonSociale: string;

    @JsonProperty('siret', String)
    @Optional
    siret: string;

    @JsonProperty('adresse', String)
    @Optional
    adresse: string;

    @JsonProperty('codePostal', String)
    @Optional
    codePostal: string;

    @JsonProperty('cvi', String)
    @Optional
    cvi: string;

    @JsonProperty('ville', String)
    @Optional
    ville: string;

    @JsonProperty('telephone', String)
    @Optional
    telephone: string;

    @JsonProperty('portable', String)
    @Optional
    portable: string;

    @JsonProperty('mail', String)
    @Optional
    mail: string;
}