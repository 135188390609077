import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';

import { ListeOrganismesComponent } from './pages/liste-organismes/liste-organismes.component';
import { EditOrganismeComponent } from './pages/edit-organisme/edit-organisme.component';
import { EditAbonnementsComponent } from './pages/edit-abonnements/edit-abonnements.component';

@Component({
  selector: 'app-organismes',
  templateUrl: './organismes.component.html',
  styleUrls: ['./organismes.component.scss']
})
export class OrganismesComponent implements OnInit {
  static routeData = {
    role: 'INTERVENANT_ORGANISME_LISTE_LECTURE',
    menu: {
      icon: 'building',
      libelle: 'menu.administration-organismes'
    }
  };
  static routes: Routes = [
    { path: '', component: ListeOrganismesComponent },
    { path: 'new', component: EditOrganismeComponent, data: { roles: ['INTERVENANT_ORGANISME_CREATION'] } },
    {
      path: 'edit/:id', component: EditOrganismeComponent, data: {
        roles: ['INTERVENANT_ORGANISME_DETAIL_LECTURE', 'INTERVENANT_ORGANISME_MODIFICATION']
      }
    },
    {
      path: 'edit-abonnements/:id', component: EditAbonnementsComponent, data: {
        roles: ['INTERVENANT_ORGANISME_ABONNEMENT_LECTURE', 'INTERVENANT_ORGANISME_ABONNEMENT_ECRITURE']
      }
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
