import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class StockProduit {
    @JsonProperty('codeProduit', String)
    codeProduit: string = undefined;

    @JsonProperty('annee', Number)
    annee: number = undefined;

    @JsonProperty('stock', Number)
    stock: number = undefined;
}
