import {Component, OnInit} from '@angular/core';
import {Routes, Router} from '@angular/router';
import {EChartOption} from 'echarts';
import {HabilitationsService} from 'src/app/data/habilitation/services/habilitations/habilitations.service';
import {SessionContext} from 'src/app/core/services/config/app.settings';
import {Habilitation} from 'src/app/data/habilitation/models/habilitation.model';
import {CalendarView, DAYS_OF_WEEK} from 'angular-calendar';
import {OperateursService} from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import {MouvementsProduitsService} from 'src/app/data/declaration/services/mouvements/mouvements-produits.service';
import {ControlesService} from 'src/app/data/declaration/services/controles/controles.service';
import {EchantillonsService} from 'src/app/data/declaration/services/echantillons/echantillons.service';
import {forkJoin} from 'rxjs';
import {AdvBootstrapLoaderService} from '@adv/bootstrap-loader';
import {ManquementsService} from 'src/app/data/declaration/services/manquements/manquements.service';
import {CommissionAgenda} from 'src/app/data/commission/models/commission-agenda.model';
import {CommissionsService} from 'src/app/data/commission/services/commissions/commissions.service';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-home-innov',
  templateUrl: './home-innov.component.html',
  styleUrls: ['./home-innov.component.scss']
})
export class HomeInnovComponent implements OnInit {

  static routes: Routes = [
    {
      path: '',
      data: {
        role: 'ORGANISME',
        domaines: ['VIN', 'ANPP'],
        menu: {
          icon: 'tachometer-alt',
          libelle: 'menu.home'
        }
      },
      component: HomeInnovComponent
    }
  ];
  isAnpp: boolean;
  userSearch: string;
  nbOperateurs: number;
  chartHabilitation: EChartOption;
  habilitations: Map<string, number>;
  nbHabilites: number;
  nbEnCours: number;
  nbRefuses: number;
  nbRetires: number;
  nbSuspendus: number;
  nbDeclarationsNonLues: number;
  nbDrevManquantes: number;
  nbControlesPrevus: number;
  nbEchantillons: number[] = [];
  nbManquement: number;
  commissions: CommissionAgenda[];
  groupCommissions: any[];

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];


  constructor(private readonly habilitationService: HabilitationsService,
              private readonly operateurService: OperateursService,
              private readonly mouvementsProduitsService: MouvementsProduitsService,
              private readonly controleService: ControlesService,
              private readonly echantillonsService: EchantillonsService,
              private readonly manquementService: ManquementsService,
              private readonly commissionService: CommissionsService,
              private readonly loaderService: AdvBootstrapLoaderService,
              private readonly router: Router) {
  }

  ngOnInit() {
    forkJoin(
      this.operateurService.getNombreOperateursAffilies(),
      this.habilitationService.getOrganismeCountHabilitations(SessionContext.get('idOrganisme')),
      this.mouvementsProduitsService.countSuiviMouvement(),
      this.controleService.countControlesPrevus(),
      this.echantillonsService.countEchantillonsEnCoursEtAPrelever(),
      this.operateurService.countOperateursDREVManquantes(SessionContext.get('idOrganisme')),
      this.manquementService.countManquementEncours(),
      this.commissionService.getAgendaCommissions(SessionContext.get('idOrganisme'), moment(), moment().add(7, 'days')),
    ).pipe(
      this.loaderService.operator()
    ).subscribe(([nbOperateurs, habilitations, nbDeclarationsNonLues, nbControle, nbEchantillons, nbDrevManquantes, nbManquements, commissions]) => {
      this.nbOperateurs = nbOperateurs;
      this.habilitations = habilitations;

      this.nbDeclarationsNonLues = nbDeclarationsNonLues
      this.nbControlesPrevus = nbControle;
      this.nbEchantillons = nbEchantillons;
      this.nbDrevManquantes = nbDrevManquantes;

      this.nbManquement = nbManquements;

      this.commissions = commissions;

      const reGroup = (list: CommissionAgenda[]) => {
        const groups = [];
        list.forEach(item => {


          var dateComm = item.debut.format("dddd DD MMM");

          let groupIndex = groups.findIndex((gi) => gi.key === dateComm);
          if (groupIndex === -1) {
            groups.push({key: dateComm, items: []});
            groupIndex = groups.length - 1;
          }
          const newItem = Object.assign({}, item);
          groups[groupIndex].items.push(newItem);
        });
        return groups;
      };


      this.commissions.sort((a, b) => {
        const nameA = a.debut;
        const nameB = b.debut;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

      this.groupCommissions = reGroup(this.commissions);
      this.loadData();
    })
  }

  loadData(): void {
     this.nbHabilites = this.habilitations['HABILITE'] ;
     this.nbEnCours = this.habilitations['ENCOURS'];
     this.nbRefuses = this.habilitations['REFUSE'];
     this.nbRetires = this.habilitations['RETIRE'];
     this.nbSuspendus = this.habilitations['SUSPENDU'];

    this.chartHabilitation = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: "5%",
        left: "auto"

      },
      series: [
        {
          name: 'Habilitations',
          type: 'pie',
          radius: ['30%', '60%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            {value: this.nbEnCours, name: 'En cours', itemStyle: {color: '#ac83d7'}},
            {value: this.nbHabilites, name: 'Habilités', itemStyle: {color: '#a9d86e'}},
            {value: this.nbRefuses, name: 'Refusés', itemStyle: {color: '#ff6c60'}},
            {value: this.nbRetires, name: 'Retirés', itemStyle: {color: '#777777'}},
            {value: this.nbSuspendus, name: 'Suspendus', itemStyle: {color: '#99CCFF'}}
          ]
        }
      ]
    };
  }

  searchUser(): void {
    this.router.navigate(['/main/operateurs/or/recherche-affilie'], {queryParams: {search: this.userSearch}});
  }

  goToSuivi(): void {
    this.router.navigate(['/main/suivi-declarations/liste']);
  }

  goToManquantes(): void {
    this.router.navigate(['/main/drev-manquantes/liste']);
  }

  goToCommission(ca: CommissionAgenda): void {
    this.router.navigate(['/main/controles/commissions/' + ca.id + '/edit']);
  }
}
