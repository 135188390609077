<form [formGroup]="formNotification" #form="ngForm" novalidate>
    <div class="cols">
      <div class="col">
          <div class="form-group">
              <label for="dateNotification" translate>page.controles.manquement.notification.modal.form.dateNotification</label>
              <div class="input-group">
                <input ngbDatepicker #dateNotification="ngbDatepicker" formControlName="dateNotification" class="form-control" />
                <div class="input-group-append">
                  <button type="button" (click)="dateNotification.toggle()" class="btn btn-secondary">
                    <fa-icon icon="calendar-alt"></fa-icon>
                  </button>
                </div>
              </div>
          </div>
          <div class="form-group">
              <label translate>page.controles.manquement.notification.modal.form.dateReponseMax</label>
              <input type="text" formControlName="dateReponseMax" class="form-control">
            </div>
          <div class="form-group">
              <label translate>page.controles.manquement.notification.modal.form.reponseOperateur</label><br />
              <input type="radio" class="mr-1" id="reponseOperateurTrue" formControlName="reponseOperateur" [value]=true />
              <label for="reponseOperateurTrue" translate>Oui</label>
              <input type="radio" class="ml-5 mr-1" id="reponseOperateurFalse" formControlName="reponseOperateur" [value]=false />
              <label for="reponseOperateurFalse" translate>Non</label>
            </div>


          <div class="form-group">
              <label for="dateReponseOperateurId" translate>page.controles.manquement.notification.modal.form.dateReponseOperateur</label>
              <div class="input-group">
                <input ngbDatepicker #dateReponseOperateurId="ngbDatepicker" formControlName="dateReponseOperateur" class="form-control" />
                <div class="input-group-append">
                  <button type="button" (click)="dateReponseOperateurId.toggle()" class="btn btn-secondary">
                    <fa-icon icon="calendar-alt"></fa-icon>
                  </button>
                </div>
              </div>
          </div>
          <div class="form-group">
              <label translate>page.controles.manquement.notification.modal.form.auteurNotification</label>
              <input type="text" formControlName="auteurNotification" class="form-control">
            </div>
            <div class="form-group">
                <div>
                    <label translate>page.controles.manquement.notification.modal.form.reponse</label>
                </div>
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="recours" formControlName="recours">
                    <label class="form-check-label" for="recours" translate>page.controles.manquement.notification.modal.form.recours</label>
                </div>
              </div>
          
      </div>
      <div class="col">
          <div class="form-group">
            <label for="commentaireOperateur" translate>page.controles.manquement.notification.modal.form.commentaireOperateur</label>
            <textarea id="commentaireOperateur" formControlName="commentaireOperateur" class="form-control"></textarea>
          </div>
          <div class="form-group">
            <label for="commentaireOrganisme" translate>page.controles.manquement.notification.modal.form.commentaireOrganisme</label>
            <textarea id="commentaireOrganisme" formControlName="commentaireOrganisme" class="form-control"></textarea>
          </div>     
      </div>
    </div>
</form>
