<div [ngClass]="{'anpp': isAnpp}">
  <div class="modal-header">
    <h4 class="modal-title" translate>
      page.recoltesPp.modalAjouterCommentaire.titre
    </h4>
  </div>


  <div class="modal-body">
    <form [formGroup]="formGroup" #form="ngForm" (submit)="onSubmit()" novalidate>
      <textarea formControlName="commentaire" class="form-control"></textarea>

      <div class="actions-bar">
        <button type="button" (click)="onClose()" class="btn btn-light mr-2" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary" translate>label.valider</button>
      </div>
    </form>
  </div>

</div>