import { Component, OnInit, Input } from '@angular/core';
import { LotSaisiesRevendications } from 'src/app/data/declaration/models/lot-saisies-revendications.model';
import { SaisieRevendication } from 'src/app/data/declaration/models/saisie-revendication.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MouvementsProduitsService } from 'src/app/data/declaration/services/mouvements/mouvements-produits.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FacturationService } from 'src/app/data/facturation/services/facturation/facturation.service';
import { ModeleFacturationService } from 'src/app/data/facturation/services/modele-facturation/modele-facturation.service';
import { ModeleFacturationLight } from 'src/app/data/facturation/models/modele-facturation-light.model';
import { ProduitsService } from 'src/app/data/habilitation/services/produits/produits.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';

@Component({
  selector: 'app-synthese-saisie-revendication',
  templateUrl: './synthese-saisie-revendication.component.html',
  styleUrls: ['./synthese-saisie-revendication.component.scss']
})
export class SyntheseSaisieRevendicationComponent implements OnInit {
  @Input() lotRevendications: LotSaisiesRevendications;
  numeroDossier: string;
  viewUrl: string;
  facturationDirecte: ModeleFacturationLight[] = [];


  constructor(
    public readonly modal: NgbActiveModal,
    private readonly mouvementsProduitsService: MouvementsProduitsService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly modelefacturationService: ModeleFacturationService,
    private readonly produitService: ProduitsService,
    private readonly facturationService: FacturationService,
    private readonly loaderService: AdvBootstrapLoaderService
  ) { }

  ngOnInit() {

    if(LotSaisiesRevendications.length>1){
    const codeproduit = this.lotRevendications.revendications[0].codeProduit;

    this.produitService.getProduitByCode(codeproduit).subscribe((produit) => {

     this.modelefacturationService.facturationDirecte('REVENDICATION_IGP',produit.idCahier).subscribe((modelefacturations) => {
        this.facturationDirecte = modelefacturations;
     });

   })
  };

   }

  /**
   * Ferme la modal et retourne un objet de type SaisieRevendicationResult
   * pour retourner dans la modal de saisie
   * @see SaisieRevendicationResult
   */
  onAddSaisie() {
    this.modal.close({
      saisieTerminee: false,
      lotRevendications: this.lotRevendications
    });
  }

  /** Ferme la modal */
  onClose(estTermine: boolean = false) {
    (estTermine) ? this.modal.close({ saisieTerminee: true }) : this.modal.dismiss();
  }

  /**
   * Retire l'élément situé à l'index donné du lot de revendications
   * @param index L'index de l'élément à supprimer
   */
  onDelete(index: number) {
    if (this.lotRevendications.revendications.length > 1) {
      this.lotRevendications.revendications.splice(index, 1);
    }
  }

  /**
   * Ferme la modal et retourne un objet de type SaisieRevendicationResult
   * pour retourner dans la modal de saisie et lui passer l'index de la revendication
   * que l'on veut modifier
   * @param index L'index de la revendication à modifier
   * @see SaisieRevendicationResult
   */
  onEdit(index: number) {
    this.modal.close({
      saisieTerminee: false,
      lotRevendications: this.lotRevendications,
      indexAModifier: index
    });
  }

  /** Enregistre le lot de revendications sur le serveur */
  onSubmit() {

if(this.facturationDirecte.length > 0)
{
  this.mouvementsProduitsService.creerLotRevendicationsAvantFacture(this.lotRevendications).subscribe((objetFactures) => {  
    
    let fontionnelIds = [];

    objetFactures.forEach(o => { if(!fontionnelIds.includes(o.fonctionnelId)){fontionnelIds.push(o.fonctionnelId)};});

    this.mouvementsProduitsService.getNumeroDossier(fontionnelIds)
    .pipe(this.loaderService.operator())
    .subscribe(numDossier =>{
        this.facturationService.postFacturationAfterMouvement(this.facturationDirecte[0].id,objetFactures, numDossier.join())
        .pipe(this.loaderService.operator())
        .subscribe(reponse => {     
          this.translate.get('page.declarations.synthese.modal.synthese-saisies-revendications.facture-ok').subscribe(msg => {
            this.toastr.success('', msg);
          });
          window.open(reponse, "_blank");
          this.modal.close();
        });
    });    
  });

}
else{
      this.mouvementsProduitsService.creerLotRevendications(this.lotRevendications).subscribe((numeroDossier) => {
      this.numeroDossier = numeroDossier;
      this.translate.get('page.declarations.synthese.modal.synthese-saisies-revendications.lot-ok').subscribe(msg => {
        this.toastr.success('', msg);
      });
    });
  }
}

  /**
   * Retourne la liste des libellés concaténés des cépages d'une revendication
   * @param saisie La saisie pour laquelle on veut les cépages
   * @see SaisieRevendication
   * @see Cepage
   */
  getCepages(saisie: SaisieRevendication): string {
    return (saisie.cepages) ?
      saisie.cepages.map(cepage => cepage.libelle).join(', ') : '';
  }

  /**
   * Retourne la liste des libellés concaténés des destinations d'une revendication
   * @param saisie La saisie pour laquelle on veut les destinations
   * @see SaisieRevendication
   * @see MouvementDestination
   */
  getDestinations(saisie: SaisieRevendication): string {
    return (saisie.destinations) ?
      saisie.destinations.map(destination => destination.libelle).join(', ') : '';
  }
}
