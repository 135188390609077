<div class="anpp">
  <div class="content">
    <form [formGroup]="formGroup" *ngIf="formGroup" #form="ngForm">
      <label translate>Campagne </label> :
      <!-- <input formControlName="anneeInv" maxlength="4" (keyup)="majSynthese()"
        [ngClass]="{'is-invalid': anneeInv.dirty && anneeInv.invalid}" class="form-control annee-input" /> -->
        <select id="refCampagne" class="custom-select bar1" formControlName="anneeInv" (change)="majSynthese()">
          <option *ngFor="let refCampagne of refCampagneList" [ngValue]="refCampagne">{{refCampagne.libelle}}
        </select>
    </form>
  <div>
    <button type="button" (click)="goToInventaire()" class="btn btn-secondary btnprimary m-3" translate>page.entreprises.verger.inventaire</button>
    <table class="table table-striped" *ngIf="syntheseEspeces?.length > 0 else aucunVerger">
      <thead>
        <tr>
          <th scope="col" translate>page.entreprises.verger.especes</th>
          <th scope="col" translate>page.entreprises.verger.varietes</th>
          <th scope="col" translate>page.entreprises.verger.surfaces</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let synthese of syntheseEspeces">
          <td>{{ synthese.codeEspece }}</td>
          <td>{{ synthese.libelleVariete }}</td>
          <td>{{ synthese.surface | number : '1.0-3'}} Ha</td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>
</div>

<ng-template #aucunVerger>
  <div class="alert alert-warning alert-block" translate [translateParams]="{ annee : annee }">
    <fa-icon icon="exclamation"></fa-icon>
    page.entreprises.verger.aucunVerger
  </div>
<ng-template>