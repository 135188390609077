
    <div class="form-group">
      <form *ngIf="formGroup && show" [formGroup]="formGroup" #form="ngForm" (submit)="submit()" novalidate>
        <div class="form-group">
          <select [formControl]="domaine" required [ngClass]="{'is-invalid': form.submitted && domaine.invalid, 'select-placeholder' : (domaine.value === null && !domaine.dirty)}" class="form-control">
            <option [ngValue]="null" hidden selected>{{'page.auth.form.domaine' | translate}}</option>
            <option *ngFor="let domaine of domaines" [ngValue]="domaine">{{domaine.libelle}}</option>
          </select>

          <ng-container *ngIf="form.submitted">
            <div *ngIf="domaine.errors?.required" class="form-error" translate>label.obligatoire</div>
          </ng-container>
        </div>
        <div>
          <button type="submit" class="btn btn-lg border-radius btn-block btn-secondary text-white" style="background:#985a99"><strong translate>label.suivant</strong></button>
        </div>
      </form>
    </div>
