import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AdvBootstrapLoaderService} from '@adv/bootstrap-loader';
import {AdvBootstrapModalService} from '@adv/bootstrap-modal';
import {
  UniteCulturaleService
} from '../../../../../../../data/declaration/services/parcellaire/unite-culturale.service';
import {TranslateService} from '@ngx-translate/core';
import {UniteCulturale} from '../../../../../../../data/declaration/models/unite-culturale.model';
import {Arrachage} from '../../../../../../../data/declaration/models/arrachage.model';

@Component({
  selector: 'app-arrachage',
  templateUrl: './arrachage.component.html',
  styleUrls: ['./arrachage.component.scss']
})
export class ArrachageComponent implements OnInit {

  constructor(public readonly modal: NgbActiveModal,
              private readonly loaderService: AdvBootstrapLoaderService,
              private readonly fb: FormBuilder,
              private readonly modalsService: AdvBootstrapModalService,
              private readonly uniteCulturaleService: UniteCulturaleService,
              private readonly translate: TranslateService) {
  }

  formGroup: FormGroup;
  @Input() uniteCulturale: UniteCulturale;
  surface: number;

  get nbArbre() {
    return this.formGroup.get('nbArbre');
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      nbArbre: [undefined, Validators.required]
    });
  }

  cancel() {
    this.modal.dismiss();
  }

  submit() {
    if (this.nbArbre.value === 0) {
      this.modalsService.error(this.translate.instant(`page.inventaire-verger.modals.surgreffage.erreurNbArbres0`), null , {windowClass: 'anpp'}).then(() => {
      });
    } else if (this.formGroup.valid) {
      const arrachage = new Arrachage();
      arrachage.nbArbre = this.nbArbre.value;
      this.uniteCulturaleService.arrachagePartiel(this.uniteCulturale.id, arrachage).subscribe(() => {
        this.modal.close();
      });
    }
  }

  calculeSurface() {
    this.surface = ((this.uniteCulturale.distanceInterRang * this.uniteCulturale.distanceIntraRang) * this.nbArbre.value) / 10000;
  }

  controleNbArbreMaxMin() {
    if (this.nbArbre.value === 0) {
      this.modalsService.error(this.translate.instant(`page.inventaire-verger.modals.surgreffage.erreurNbArbres0`), null , {windowClass: 'anpp'}).then(() => {
      });
    }
    if (this.nbArbre.value > this.uniteCulturale.nbArbre) {
      this.modalsService.error(this.translate.instant(`page.inventaire-verger.modals.surgreffage.erreurNbArbres`) + this.uniteCulturale.nbArbre + '.', null , {windowClass: 'anpp'}).then(() => {
        this.nbArbre.setValue(0);
        this.calculeSurface();
      });
    } else {
      this.calculeSurface();
    }

  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < '0'.charCodeAt(0) || charCode > '9'.charCodeAt(0))) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
