import {JsonProperty, JsonObject, Optional, CustomConverter} from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import {DataIdConverterFactory} from 'src/app/core/services/mapper/data-id.converter';
import {NatureControle} from './nature-controle.model';
import {ObjetControle} from './objet-controle.model';
import {Cahier} from '../../habilitation/models/cahier.model';
import {Activite} from '../../habilitation/models/activite.model';
import {DateConverter} from 'src/app/core/services/mapper/converters';
import {Operateur} from '../../intervenant/models/operateur.model';
import {OperateurInformationsDomaine} from '../../intervenant/models/operateur-informations-domaine.model';
import {Produit} from '../../habilitation/models/produit.model';
import {Type} from '@angular/compiler';
import {TypeControle} from './type-controle.model';
import {StatutControle} from './statut-controle.model';

@JsonObject
export class Controle {
  @JsonProperty('id', Number)
  @Optional
  id: number;

  @JsonProperty('idNature', Number)
  @CustomConverter(DataIdConverterFactory.build(NatureControle, 'naturesControle'))
  nature: NatureControle;

  @JsonProperty('idObjet', Number)
  @CustomConverter(DataIdConverterFactory.build(ObjetControle, 'objetsControle'))
  objet: ObjetControle;

  @JsonProperty('idStatut', Number)
  @CustomConverter(DataIdConverterFactory.build(StatutControle, 'statutsControle'))
  @Optional
  statut: StatutControle;

  @JsonProperty('numeroControle', String)
  @Optional
  numeroControle: string;

  @JsonProperty('idAgent', Number)
  @Optional
  idAgent: number;

  @JsonProperty('idOperateur', Number)
  @Optional
  idOperateur: number;
  operateur: Operateur;
  infosOperateur: OperateurInformationsDomaine[] = [];

  @JsonProperty('idMouvement', Number)
  @Optional
  idMouvement: number;

  @JsonProperty('idEchantillon', Number)
  @Optional
  idEchantillon: number;

  @JsonProperty('idCahierList', [Number])
  @CustomConverter(DataIdConverterFactory.build(Cahier, 'cahiers'))
  @Optional
  cahiers: Cahier[] = [];

  @JsonProperty('idActiviteList', [Number])
  @CustomConverter(DataIdConverterFactory.build(Activite, 'activites'))
  @Optional
  activites: Activite[] = [];

  @JsonProperty('codeProduitList', [String])
  @Optional
  codeProduitList: string[] = [];
  produits: Produit[] = [];

  @JsonProperty('dateButoir', String)
  @CustomConverter(DateConverter)
  @Optional
  dateButoir: moment.Moment;

  @JsonProperty('dateControle', String)
  @CustomConverter(DateConverter)
  @Optional
  dateControle: moment.Moment;

  @JsonProperty('idTypeControle', Number)
  @CustomConverter(DataIdConverterFactory.build(TypeControle, 'typesControle'))
  @Optional
  typeControle: TypeControle;

  @JsonProperty('numeroEchantillon', String)
  @Optional
  numeroEchantillon: string;

  @JsonProperty('numeroCommission', String)
  @Optional
  numeroCommission: string;

  @JsonProperty('numeroJury', String)
  @Optional
  numeroJury: string;

  @JsonProperty('numeroAnonymat', String)
  @Optional
  numeroAnonymat: string;

  @JsonProperty('idLaboratoire', Number)
  @Optional
  idLaboratoire: number;

  @JsonProperty('libelleLaboratoire', String)
  @Optional
  libelleLaboratoire: string;

  @JsonProperty('dateEnvoiLaboratoire', String)
  @CustomConverter(DateConverter)
  @Optional
  dateEnvoiLaboratoire: moment.Moment;

  @JsonProperty('nbManquement', Number)
  @Optional
  nbManquement: number;

  @JsonProperty('volume', Number)
  @Optional
  volume: number;

  @JsonProperty('logement', String)
  @Optional
  logement: string;

  @JsonProperty('lot', String)
  @Optional
  lot: string;
}
