import { JsonProperty, JsonObject } from '@thorolf/json-ts-mapper';
import { RefSoustypeUtilisateur } from './ref-soustype-utilisateur.model';
import { DroitFonctionnel } from './droit-fonctionnel.model';

@JsonObject
export class ReferencesDroits {
  @JsonProperty('refSousTypeDTOS', [RefSoustypeUtilisateur])
  soustypesUtilisateurs: RefSoustypeUtilisateur[];

  @JsonProperty('refDroitFonctionnelDTOS', [DroitFonctionnel])
  droitsFonctionnels: DroitFonctionnel[];
}
