<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
      page.commissions.edit.modals.filtre-echantillons-igp.title
    </h4>
  </div>

  <div class="modal-body" *ngIf="echantillons">
    <form [formGroup]="formGroup" #form="ngForm" (submit)="onSubmit()">

      <div class="modal-body" >        
        
      <div class="form-row">
        <div class="form-group col-md-6" >
        <label for="sortField1">Selection 1er tri: </label>
        <select id="sortField1"  class="form-control" (change)="onSortChange1($event)">
          <option selected>Choisir</option>
          <option value="cahier">Cahier des charges</option>
          <option value="couleur">Couleur</option>
          <option value="degre">Degre</option>
        </select>
        </div>

        <div class="form-group col-md-6" >
        <label for="sortField2">2ème tri: </label>
        <select id="sortField2" (change)="onSortChange2($event)" class="form-control">
          <option selected>Choisir</option>
          <option value="cahier">Cahier des charges</option>
          <option value="couleur">Couleur</option>
          <option value="degre">Degre</option>
        </select>
        </div>
      </div>

        <table class="table" *ngIf="echantillons.length > 0; else aucunEchantillon">
                        <thead>
                              <th translate>page.commissions.edit.modals.filtre-echantillons-igp.table.header.raisonsociale</th>
                              <th translate>page.commissions.edit.modals.filtre-echantillons-igp.table.header.produit</th>
                              <th translate>page.commissions.edit.modals.filtre-echantillons-igp.table.header.couleur</th>
                              <th translate>page.commissions.edit.modals.filtre-echantillons-igp.table.header.millesime</th>
                              <th translate>page.commissions.edit.modals.filtre-echantillons-igp.table.header.numeroAnonymat</th>
                              <th translate>page.commissions.edit.modals.filtre-echantillons-igp.table.header.volume</th>
                              <th translate>page.commissions.edit.modals.filtre-echantillons-igp.table.header.degre</th>
                              </thead>
                            <tbody>
                              <tr *ngFor="let echantillon of echantillons; let i=index;" >
                                <ng-container *ngIf="echantillon.echantillon">
                                  <td>{{getRaisonSociale(echantillon.echantillon)}}<br/>{{getCVI(echantillon.echantillon)}}</td>
                                  <td>{{echantillon.echantillon.produit.libelle | truncate:[50, '...']}}</td>
                                  <td>{{getCouleur(echantillon.echantillon.codeProduit) }}</td>
                                  <td>{{echantillon.echantillon.millesime? echantillon.echantillon.millesime: ''}}</td>
                                  <td>{{echantillon.numeroAnonymat}}</td>  
                                  <td>{{echantillon.echantillon.volume}} Hl</td>
                                  <td>{{echantillon.echantillon.degre}} °</td>
                                </ng-container>
                                <ng-container *ngIf="echantillon.leurre">
                                  <td></td>
                                  <td>{{echantillon.leurre.produit.libelle | truncate:[50, '...']}}</td>
                                  <td>{{getCouleur(echantillon.leurre.codeProduit) }}</td>
                                  <td>{{echantillon.leurre.millesime? echantillon.leurre.millesime: ''}}</td>
                                  <td>{{echantillon.numeroAnonymat}}</td> 
                                  <td></td>
                                  <td></td>
                                </ng-container>
                              </tr>
                            </tbody>
                          </table>              

      </div>                    

      
      
      <div class="actions-bar">        
        <button type="button" class="btn btn-primary" (click)="onClose()"
          translate>page.commissions.edit.modals.filtre-echantillons-igp.valider</button>
      </div>
    </form>
  </div>
</div>

<ng-template #aucunEchantillon>
  <div translate>page.commissions.edit.modals.filtre-echantillons-igp.table.aucun_echantillon</div>
</ng-template>

