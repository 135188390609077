import { JsonProperty, JsonObject } from '@thorolf/json-ts-mapper';

@JsonObject
export class EchantillonMouvementEdition {
    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('numeroLot', String)
    numeroLot: string;

    @JsonProperty('numeroDossier', String)
    numeroDossier: string;

    @JsonProperty('cepage', String)
    cepage: number;

    @JsonProperty('volume', String)
    volume: string;

    @JsonProperty('type', String)
    type: string;

    @JsonProperty('logement', String)
    logement: string;

    @JsonProperty('contenant', String)
    contenant: string;

    @JsonProperty('date', String)
    date: string;

    @JsonProperty('dateRetiraison', String)
    dateRetiraison: string;

    @JsonProperty('typeConditionnement', String)
    typeConditionnement: string;

    @JsonProperty('qteRestanteStock', String)
    qteRestanteStock: string;

    @JsonProperty('degre', String)
    degre: string;
}
