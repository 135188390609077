export enum Role {
  INTERVENANT_UTILISATEUR_LECTURE = 'INTERVENANT_UTILISATEUR_LECTURE',
  INTERVENANT_UTILISATEUR_ECRITURE = 'INTERVENANT_UTILISATEUR_ECRITURE',
  INTERVENANT_UTILISATEUR_DESACTIVATION = 'INTERVENANT_UTILISATEUR_DESACTIVATION',
  INTERVENANT_ORGANISME_CREATION = 'INTERVENANT_ORGANISME_CREATION',
  INTERVENANT_ORGANISME_MODIFICATION = 'INTERVENANT_ORGANISME_MODIFICATION',
  INTERVENANT_ORGANISME_DESACTIVATION = 'INTERVENANT_ORGANISME_DESACTIVATION',
  INTERVENANT_ORGANISME_LISTE_LECTURE = 'INTERVENANT_ORGANISME_LISTE_LECTURE',
  INTERVENANT_ORGANISME_DETAIL_LECTURE = 'INTERVENANT_ORGANISME_DETAIL_LECTURE',
  INTERVENANT_ORGANISME_ABONNEMENT_LECTURE = 'INTERVENANT_ORGANISME_ABONNEMENT_LECTURE',
  INTERVENANT_ORGANISME_ABONNEMENT_ECRITURE = 'INTERVENANT_ORGANISME_ABONNEMENT_ECRITURE',
  INTERVENANT_DOMAINE_LECTURE = 'INTERVENANT_DOMAINE_LECTURE',
  HABILITATION_HABILITATION_LECTURE = 'HABILITATION_HABILITATION_LECTURE',
  HABILITATION_HABILITATION_ECRITURE = 'HABILITATION_HABILITATION_ECRITURE',
  INTERVENANT_PORTAIL_OPERATEUR = 'INTERVENANT_PORTAIL_OPERATEUR',
  DECLARATION_CONTROLE_ECRITURE = 'DECLARATION_CONTROLE_ECRITURE'
}
