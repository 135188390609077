import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { Cahier } from '../../habilitation/models/cahier.model';
import { Produit } from '../../habilitation/models/produit.model';
import { PointAMaitriser } from './point-a-maitriser.model';
import { ManquementConstate } from './manquement-constate.model';
import { IntensiteDefaut } from './enums/intensite-defaut.enum';
import { Gravite } from './enums/gravite.enum';
import { Sanction } from './sanction.model';
import * as moment from 'moment';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { Animateur } from '../../commission/models/animateur.model';

@JsonObject
export class Constat {
    @JsonProperty('idCahier', Number)
    @CustomConverter(DataIdConverterFactory.build(Cahier, 'cahiers'))
    cahierDesCharges: Cahier;

    @JsonProperty('codeProduit', String)
    @Optional
    codeProduit: string;
    produit: Produit;

    @JsonProperty('idPointMaitrise', Number)
    idPointAMaitriser: number;
    pointAMaitriser: PointAMaitriser;

    @JsonProperty('idManquementConstate', Number)
    idManquementConstate: number;
    manquementConstate: ManquementConstate;

    @JsonProperty('intensite', String)
    @Optional
    intensite: IntensiteDefaut = IntensiteDefaut.MOYENNE;

    @JsonProperty('gravite', String)
    gravite: Gravite = Gravite.MAJEURE;

    @JsonProperty('idSanctionEncourue', Number)
    idSanctionEncourue: number;
    sanctionEncourue: Sanction;

    @JsonProperty('dateConstat', String)
    @CustomConverter(DateConverter)
    @Optional
    date: moment.Moment;

    @JsonProperty('idAuteurConstat', Number)
    @CustomConverter(DataIdConverterFactory.build(Animateur, 'agents'))
    @Optional
    auteur: Animateur;

    @JsonProperty('commentaire', String)
    @Optional
    commentaire: string;
}
