import {Injectable} from '@angular/core';
import {College} from '../../models/college.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {JsonMapperService} from '../../../../core/services/mapper/mapper.service';
import {Cache} from '../../../../core/services/cache/cache.service';
import {forkJoin, Observable, of} from 'rxjs';
import {flatMap, map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CollegeService {
  static colleges: College[];

  protected BASE_URL = '/api/declaration';

  constructor(private readonly http: HttpClient,
              private readonly mapper: JsonMapperService
  ) {
  }

  static getColleges(): College[] {
    const colleges = CollegeService.colleges;
    if (colleges === undefined) {
      throw new Error('service-college.static-data.unavailable');
    }
    return colleges;
  }

  @Cache()
  public getColleges(): Observable<College[]> {
    return forkJoin([
      this.http.get<object[]>(`${this.BASE_URL}/private/colleges`),
    ])
      .pipe(
        map(([colleges]) => this.mapper.deserializeArray(colleges, College)),
        tap(colleges => {
          CollegeService.colleges = colleges;
        })
      );
  }
}
