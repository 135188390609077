<form [formGroup]="formGroup" #form="ngForm" (submit)="submit()" novalidate class="anpp">
  <div class="modal-header">
    <h4 class="modal-title" translate>Changement de statut</h4>
  </div>

  <div class="modal-body" *ngIf="statuts">
    <div class="form-group">
      <label for="statut" translate>page.operateurs.habilitations.modal-update.label.statut</label>
      <select id="statut" formControlName="statut" required [ngClass]="{'is-invalid': form.submitted && statut.invalid}"
        class="form-control">
        <option *ngFor="let statut of statuts" [ngValue]="statut">{{statut.libelle}}</option>
      </select>

      <ng-container *ngIf="form.submitted">

        <div *ngIf="statut.errors?.required" class="form-error" translate>label.obligatoire</div>
      </ng-container>
    </div>
    <div class="form-group" *ngIf="statut && statut.value && statut.value.code == 'AR'">
      
      <label for="dateAudit" translate>Date de l'audit</label>

      <div class="input-group">
        <input id="dateAudit" formControlName="dateAudit" ngbDatepicker #dateAuditPicker="ngbDatepicker" [required]="engagement.codeStatut == 'DEOC'"
          [ngClass]="{'is-invalid': form.submitted && dateAudit.invalid}" class="form-control" />
        <div class="input-group-append">
          <button type="button" (click)="dateAuditPicker.toggle()" class="btn btn-secondary">
            <fa-icon icon="calendar-alt"></fa-icon>
          </button>
        </div>
      </div>

      <ng-container *ngIf="form.submitted">
        <div *ngIf="dateAudit.errors?.required" class="form-error" translate>label.obligatoire</div>
      </ng-container>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" (click)="cancel()" class="btn btn-light" translate>label.annuler</button>
    <button type="submit" class="btn btn-primary" translate>label.valider</button>
  </div>
</form>