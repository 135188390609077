import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditProtectionGelComponent } from './edit-protection-gel/edit-protection-gel.component';
import { ListesProtectionsGelComponent } from './listes-protections-gel/listes-protections-gel.component';

@Component({
  selector: 'app-protections-gel',
  templateUrl: './protections-gel.component.html',
  styleUrls: ['./protections-gel.component.scss']
})
export class ProtectionsGelComponent implements OnInit {

  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"]
    };
  
    static routes: Routes = [
      { path: '', component: ListesProtectionsGelComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id', component: EditProtectionGelComponent, data: { roles: ['ORGANISME'] } },
      { path: 'new', component: EditProtectionGelComponent, data: { roles: ['ORGANISME'] } }
    ];


  constructor() { }

  ngOnInit() {
  }

}
