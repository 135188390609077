import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { EntrepriseBase } from 'src/app/data/intervenant/models/entreprise-base.model';

@JsonObject
export class EngagementSc {
  @JsonProperty('id', Number)
  @Optional
  public id: number = undefined;

  @JsonProperty('entreprise', EntrepriseBase)
  public entreprise: EntrepriseBase = undefined;
  
  @JsonProperty('utilisationLogo', Boolean)
  public utilisationLogo: boolean = undefined;

  @JsonProperty('certificationBrc', Boolean)
  public certificationBrc: boolean = undefined;

  @JsonProperty('certificationIfs', Boolean)
  public certificationIfs: boolean = undefined;

}
