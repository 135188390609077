import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { EchantillonMouvementEdition } from './echantillon-mouvement-edition.model';
import { EchantillonOperateurEdition } from './echantillon-operateur-edition.model';
import { EchantillonSiteEdition } from './echantillon-site-edition.model';
import { OrganismeEdition } from './organisme-edition.model';

@JsonObject
export class EchantillonEdition {
    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('numeroPassage', String)
    numeroPassage: string;

    @JsonProperty('numero', String)
    numero: string;

    @JsonProperty('millesime', String)
    millesime: string;

    @JsonProperty('cahier', String)
    cahier: string;

    @JsonProperty('produit', String)
    produit: string;

    @JsonProperty('mouvement', EchantillonMouvementEdition)
    mouvement: EchantillonMouvementEdition;

    @JsonProperty('operateur', EchantillonOperateurEdition)
    operateur: EchantillonOperateurEdition;

    @JsonProperty('site', EchantillonSiteEdition)
    site: EchantillonSiteEdition;

    @JsonProperty('organisme', [OrganismeEdition])
    @Optional
    organisme: OrganismeEdition;
}
