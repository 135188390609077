import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class MultiselectComponent {
  @Input()
  maxSelectedItems?: number;

  @Input()
  items: any[];

  @Input()
  bindLabel: string;

  @Input()
  controlName: string;

  @Input()
  maxWidth ?= '20vw';
}
