import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { JureSynthese } from 'src/app/data/commission/models/jure-synthese.model';
import { JuresService } from 'src/app/data/commission/services/jures/jures.service';
import { forkJoin } from 'rxjs';
import { ExportToCsv } from 'export-to-csv';
import { DetailSyntheseComponent } from './modals/detail-synthese/detail-synthese.component';

@Component({
  selector: 'app-synthese-jure',
  templateUrl: './synthese-jure.component.html',
  styleUrls: ['./synthese-jure.component.scss']
})
export class SyntheseJureComponent implements OnInit {
  
  private idOrganisme: number;
  public syntheses: JureSynthese[] = [];

  public formSearchCommissions: FormGroup;

  get debutDateCommission() { return this.formSearchCommissions.get('debutDateCommission'); }
  get finDateCommission() { return this.formSearchCommissions.get('finDateCommission'); }

  constructor(
    private readonly fb: FormBuilder,    
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalService: NgbModal,
    private readonly jureService: JuresService
    ) { }

  ngOnInit() {
     // Récupérer l'ID organisme
     this.idOrganisme = SessionContext.get('idOrganisme');

     // Instancier le formulaire de recherche
     this.formSearchCommissions = this.fb.group({       
       debutDateCommission: undefined,
       finDateCommission: undefined
     });
 
     // Charger les données pour les listes déroulantes
     this.loadData();
  }

  private loadData(): void {
    
  }

  getField(name: string) {
    return this.formSearchCommissions.get(name);
  }

    /**
   * Transforme une date provenant d'un Datepicker en date momentJS
   * @param date La date provenant d'un Datepicker
   * @see moment.Moment
   */
    private getMomentDate(date: {
      year: number,
      month: number,
      day: number
    }): moment.Moment {
      return (date) ? moment([date.year, date.month - 1, date.day, 0, 0, 0]) : null;
    }

  public onSubmit(): void {

    forkJoin(
      this.jureService.getSyntheseJures(this.idOrganisme,  this.getMomentDate(this.debutDateCommission.value),this.getMomentDate(this.finDateCommission.value))
    ).
    pipe(
      this.loaderService.operator()
      ).
    subscribe(([synthese]) => {
      this.syntheses = synthese;
    });

  }

  viewDetail(jury: JureSynthese) {


    forkJoin(
      this.jureService.getDetailJures(this.idOrganisme, jury.id,  this.getMomentDate(this.debutDateCommission.value),this.getMomentDate(this.finDateCommission.value))
    ).
    pipe(
      this.loaderService.operator()
      ).
    subscribe(([synthese]) => {
     
      const modal = this.modalService.open(DetailSyntheseComponent, { size: 'lg', backdrop: 'static' });
      modal.componentInstance.detailJures = synthese;
      modal.componentInstance.jury = jury;
      modal.componentInstance.debut = this.debutDateCommission.value;
      modal.componentInstance.fin = this.finDateCommission.value;
      modal.result.then(() => { }, () => {});

    });
       
  }

  public exporter(): void {
    const data = [];
    this.syntheses.forEach(exp => {

      data.push({
        nom: exp.nom,
        mail: exp.mail,
        nbConvocation: exp.nbConvocation,
        nbPresent: exp.nbPresent,
        nbExcuse: exp.nbExcuse,
        nbAbsent: exp.nbAbsent,
        nbDesinscrit: exp.nbDesinscrit,
        nbConvoque: exp.nbConvoque,
        nbAConvoque: exp.nbAConvoque,

      });
    });


    const options = {
      filename: 'EXPORT_SYNTHESE'.concat('_').concat(moment().format('YYYYMMDD')),
      fieldSeparator: ';',
      quoteStrings: '',
      decimalSeparator: ',',
      showLabels: true,
      showTitle: false,
      title: 'EXPORT_SYNTHESE'.concat('_').concat(moment().format('YYYYMMDD')),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        'Juré',
        'Email',
        'Convocation',
        'Présent',
        'Excusé',
        'Absent',
        'Désinscrit',
        'Convoqué',
        'A convoquer',
      ]
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }

}
