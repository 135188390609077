import { Component, Input, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import {AdvBootstrapModalService} from "@adv/bootstrap-modal";
import {TranslateService} from "@ngx-translate/core";



@Component({
  selector: 'app-changer-producteur',
  templateUrl: './changer-producteur.component.html',
  styleUrls: ['./changer-producteur.component.scss']
})
export class ChangerProducteurComponent implements OnInit {

  constructor(public readonly modal: NgbActiveModal,
              private readonly fb: FormBuilder,
              private readonly modalsService: AdvBootstrapModalService,
              private readonly translate: TranslateService,
              private readonly authService: AuthService) {
  }

  @Input() adherentConnecte: Entreprise;
  @Input() producteurSelectionne: Entreprise;
  adherentCible: Entreprise;
  producteur: Entreprise;
  formGroup: FormGroup;

  showProducteurBarre = false;

  showAdherentBarre(): boolean {
    return this.isAnpp();
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
    });

    if (this.isAnpp()) {
      this.adherentCible = undefined;
    } else if (this.adherentConnecte && this.adherentConnecte.refSousType.code === 'GP_DEFAUT') {
      this.adherentCible = this.adherentConnecte;
      this.showProducteurBarre = true;
    }
  }

  isAnpp() {
    return this.authService.isAnpp();
  }

  submit() {
    if (this.producteur != null) {
      if (this.producteur.id === this.producteurSelectionne.id) {
        this.modalsService.error(this.translate.instant(`page.inventaire-verger.modals.changerProducteur.changerProducteurErrorCas1`), null, {windowClass : 'anpp'
        }).then(() => {
        });
      } else {
        this.modal.close(this.producteur);
      }
    } else {
      this.modalsService.error(this.translate.instant(`page.inventaire-verger.modals.changerProducteur.changerProducteurError`), null, {windowClass : 'anpp'
      }).then(() => {
      });
    }
  }

  cancel() {
    this.modal.dismiss();
  }


  selectItemAdherent(newItem: Entreprise) {
    this.adherentCible = newItem;
    if (this.adherentCible && this.adherentCible.refSousType.code != 'PI_DEFAUT') {
      this.showProducteurBarre = true;
      this.producteur = undefined;
    } else {
      this.showProducteurBarre = false;
      this.producteur = newItem;
    }
  }

  selectItemProducteur(newItem: Entreprise) {
    this.producteur = newItem;
  }
}
