import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';

import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';

import { Assemblage } from './assemblage.model';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { Cepage } from './cepage.model';
import { AcheteurExport } from './acheteur-export.model';
import { Site } from './site.model';

@JsonObject
export class SaisieTransaction {
  @JsonProperty('codeProduit', String)
  codeProduit: string;

  @JsonProperty('numeroContrat', String)
  numeroContrat: string;

  @JsonProperty('dateRetiraison', String)
  @CustomConverter(DateConverter)
  date: moment.Moment;

  @JsonProperty('logement', String)
  logement: string;

  @JsonProperty('numeroLot', String)
  numeroLot: string;

  @JsonProperty('volume', Number)
  volume: number;

  @JsonProperty('idSite', Number)
  @CustomConverter(DataIdConverterFactory.build(Site, 'sites'))
  site: Site;

  @JsonProperty('observation', String)
  observation: string;

  @JsonProperty('idsCepages', [Number])
  @CustomConverter(DataIdConverterFactory.build(Cepage, 'cepages'))
  @Optional
  cepages: Cepage[];

  @JsonProperty('assemblages', [Assemblage])
  @Optional
  assemblages: Assemblage[] = [];

  @JsonProperty('idAcheteurFrance', Number)
  @Optional
  idAcheteurFrance: number;

  @JsonProperty('idAcheteurExport', Number)
  @Optional
  idAcheteurExport: number;
}
