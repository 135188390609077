import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {


  constructor() {
    this.searchStringUpdate.pipe(
      debounceTime(this.debounceTime || 300),
      distinctUntilChanged())
      .subscribe(value => {
        this.newValue(value);
      });
  }

  @Input()
  debounceTime: number;

  @Input()
  value: string;

  @Output()
  valueChange = new EventEmitter<string>();


  searchStringInput: string;
  searchStringUpdate = new Subject<string>();

  newValue(value: string) {
    this.value = value;
    this.valueChange.emit(value);
  }

  ngOnInit() {
    this.searchStringInput = this.value;
  }
}
