<div class="content">
  <div class="content-panel">
    <h2 translate>page.facturation.liste-modeles.title</h2>

    <table table class="table table-striped" *ngIf="modelesFacturation?.length > 0; else aucunModeleFacturation">
      <thead>
        <tr>
          <th class="liste-col-header" translate>page.facturation.liste-modeles.table.header.libelle</th>
          <th class="liste-col-header" translate>page.facturation.liste-modeles.table.header.objet-facture</th>
          <th class="liste-col-header" translate>page.facturation.liste-modeles.table.header.grouper-operateur</th>
          <th class="liste-col-header" translate>page.facturation.liste-modeles.table.header.annee-reference</th>
          <th class="liste-col-header" translate>page.facturation.liste-modeles.table.header.articles</th>
          <th class="liste-col-header" translate>page.facturation.liste-modeles.table.header.actions</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let modeleFacturation of modelesFacturation"
          [style.backgroundColor]="modeleFacturation.isSupprime ? '#FFCECE' : ''">
          <td>{{modeleFacturation.libelle}}</td>
          <td>{{modeleFacturation.refObjetFacture.libelle}}</td>
          <td>{{getGrouperOperateurValue(modeleFacturation.isGroupeOperateur)}}</td>
          <td>{{modeleFacturation.refAnneeType.libelle}}</td>
          <td>
            <ul *ngFor="let article of modeleFacturation.modeleArticleList">
              <li>
                {{ article.libelle }}
              </li>
            </ul>
          </td>
          <td>
            <span *ngIf="!modeleFacturation.isSupprime">
              <fa-icon class="clickable-icon" icon="edit" (click)="editModeleFacturation(modeleFacturation)"></fa-icon>
            </span>
            <span *ngIf="!modeleFacturation.isSupprime">
              <fa-icon class="clickable-icon" icon="trash" (click)="deleteModeleFacturation(modeleFacturation)">
              </fa-icon>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="actions-bar">
      <button type="button" class="btn btn-primary" [routerLink]="['new/edit']">Créer un modèle</button>
    </div>
  </div>
</div>

<!-- Template aucun historique -->
<ng-template #aucunModeleFacturation>
  <div class="alert alert-warning alert-block" translate>
    <fa-icon icon="exclamation"></fa-icon> page.facturation.liste-modeles.aucunModeleFacturation
  </div>
</ng-template>
<!-- Fin template aucun historique -->