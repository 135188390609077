import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { Espece } from 'src/app/data/declaration/models/espece.model';
import { RefPorteGreffe } from 'src/app/data/declaration/models/ref-porte-greffe.model';
import { EspeceService } from 'src/app/data/declaration/services/parcellaire/espece.service';
import { RefPorteGreffeService } from 'src/app/data/declaration/services/parcellaire/ref-porte-greffe.service';

@Component({
  selector: 'app-edit-porte-greffe',
  templateUrl: './edit-porte-greffe.component.html',
  styleUrls: ['./edit-porte-greffe.component.scss']
})
export class EditPorteGreffeComponent implements OnInit {

  formGroup: FormGroup;
  creation = false;
  title: string;
  portegreffe: RefPorteGreffe;
  especes: Espece[];

  get code() { return this.formGroup.get('code'); }
  get libelle() { return this.formGroup.get('libelle'); }
  get espece() { return this.formGroup.get('espece'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly portegreffeService: RefPorteGreffeService,
    private readonly especeService: EspeceService) { }

  ngOnInit() {

    const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    if (!id) {
      this.creation = true;
    }
      this.loadData(id);   

  }

  loadData(id: number) {

    this.title = this.creation ? 'page.valeur_parametrable.form.title.add_portegreffe': 'page.valeur_parametrable.form.title.edit_portegreffe';
    
    forkJoin(
    !this.creation ? this.portegreffeService.getRefPorteGreffeById(+id) : of(new RefPorteGreffe()),
    this.especeService.getEspeces()
    )
    .pipe(
      this.loaderService.operator()
    ).subscribe(([portegreffe,especes]) => {
    
    this.portegreffe = portegreffe ; 
    this.especes = especes;
    this.initForm((portegreffe.id) ? portegreffe : null);
     
    });
  }

  private initForm(portegreffe?: RefPorteGreffe): void {
 
    this.formGroup = this.fb.group({
      code: [{ value: ((portegreffe) ? portegreffe.code: undefined), disabled: false }, Validators.required],
      libelle: [{ value: ((portegreffe) ? portegreffe.libelle : undefined), disabled: false }, Validators.required],
      espece: [{ value: ((portegreffe) ? this.especes.find(es => es.id === portegreffe.espece.id) : undefined), disabled: false }, Validators.required]
    });
  }

  submit() {
    if (this.formGroup.valid) {
      this.portegreffe.code = this.code.value;
      this.portegreffe.libelle = this.libelle.value;
      this.portegreffe.espece = this.espece.value;
     
      if (this.portegreffe.id == null) {
        this.portegreffeService.creerRefPorteGreffe(this.portegreffe).subscribe(idVariete => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
        });
      } else {        
        this.portegreffeService.modifierRefPorteGreffe(this.portegreffe).subscribe( () => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
         } );
      }
     
    }
  }
}
