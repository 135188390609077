import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StockProduit } from '../../models/stock-produit.model';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StockProduitService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
  ) { }

  getStockByProductCodeAndMoveType(
    idOperateur: number,
    codeProduit: string,
    typeMouvement: string
  ): Observable<StockProduit[]> {
    // return this.http.get<object[]>(`/assets/mocks/stocks-produit.json`).pipe(
    return this.http.get<object[]>(`/api/declaration/private/operateurs/${idOperateur}/produits/${btoa(codeProduit)}/stocks/${typeMouvement}`).pipe(
      map(stocks => this.mapper.deserializeArray(stocks, StockProduit))
    );
  }
}
