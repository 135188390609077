<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
      page.commissions.edit.modals.echantillons.title
    </h4>
  </div>

  <ngb-tabset #t="ngbTabset" [(activeId)]="activeTabId">
    <ngb-tab id="tab-echantillons">
      <ng-template ngbTabTitle><span translate>page.commissions.edit.modals.echantillons.tab.echantillons</span></ng-template>
      <ng-template ngbTabContent><ng-container *ngTemplateOutlet="tabEchantillons"></ng-container></ng-template>
    </ngb-tab>
    <ngb-tab id="tab-leurre">
      <ng-template ngbTabTitle><span translate>page.commissions.edit.modals.echantillons.tab.leurre</span></ng-template>
      <ng-template ngbTabContent><ng-container *ngTemplateOutlet="tabLeurre"></ng-container></ng-template>
    </ngb-tab>
    </ngb-tabset>
</div>

<!-- echantillons  -->
<ng-template #tabEchantillons>
    <div class="modal-body" *ngIf="loaded">
      <div class="row">

      <div class="col">
        <app-search-bar [(value)]="searchString" *ngIf="items.length > 0"></app-search-bar>
      </div>   
      <div class="col">
        <div class="">
          <label>
            <input id="radio1" name="statut" type="radio" class="mr-1" 
            (click)="changeStatut(false)" [value]="false" [(ngModel)]="statutAPreleve" />
            <span translate>page.commissions.edit.modals.echantillons.form_leurre.preleve</span>
          </label>
          <label>
            <input id="radio2" name="statut" type="radio" class="ml-5 mr-1" 
            (click)="changeStatut(true)" [value]="true" [(ngModel)]="statutAPreleve" />
            <span translate>page.commissions.edit.modals.echantillons.form_leurre.aprelever</span>
          </label>
        </div>
      </div> 
      </div>
      <table class="table" *ngIf="items.length > 0; else aucunJure">
        <thead>
              <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'item.produit.libelleCahier', libelle:'page.commissions.edit.modals.echantillons.table.header.cahier'}"></ng-container>
              <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'item.produit.libelle', libelle:'page.commissions.edit.modals.echantillons.table.header.produit'}"></ng-container>
              <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'item.millesime', libelle:'page.commissions.edit.modals.echantillons.table.header.millesime'}"></ng-container>
              <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'item.operateur.siret', libelle:'page.commissions.edit.modals.echantillons.table.header.operateur'}"></ng-container>
              <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'item.datePrelevement', libelle:'page.commissions.edit.modals.echantillons.table.header.datePrelevement'}"></ng-container>
            </thead>
            <tbody>
              <tr *ngFor="let item of items | filter : '*': searchString | orderBy:order.property:order.reverse:true" [ngClass]="{'selected':item.selected, 'disabled':item.disabled}" (click)="toggleSelectionItem(item)">
                <td>{{item.item.produit?.libelleCahier}}</td>
                <td>{{item.item.produit?.libelle}}</td>
                <td>{{item.item.millesime}}</td>
                <td>{{item.item.operateur?.siret}} - {{item.item.operateur?.raisonSociale}}</td>
                <td>{{item.item.datePrelevement | date:'shortDate' }}</td>
              </tr>
            </tbody>
          </table>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-light" (click)="cancel()" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary" (click)="submit()" [disabled]="selectedItems.length === 0" [translateParams]="{nb:selectedItems.length}" translate>page.commissions.edit.modals.echantillons.valider</button>
      </div>
</ng-template>
<!--  -->

<!-- leurre  -->
<ng-template #tabLeurre>
  <form [formGroup]="formGroupLeurre" #form="ngForm" (submit)="submitFormEchantillon()" novalidate>
    <div class="modal-body" *ngIf="loaded">
      <div class="form-group">
        <label translate>page.commissions.edit.modals.echantillons.form_leurre.label.millesime</label>
        <input type="number" formControlName="millesime" [ngClass]="{'is-invalid': form.submitted && getFormField('millesime').invalid}" class="form-control"/>
      </div>

        <div class="form-group">
          <label translate>page.commissions.edit.modals.echantillons.form_leurre.label.produit</label>
          <select formControlName="produit" [ngClass]="{'is-invalid': form.submitted && getFormField('produit').invalid}" class="form-control">
            <option *ngFor="let produit of produits" [ngValue]="produit">{{produit.libelle}}</option>
          </select>
        </div>

        <div class="form-group">
          <label translate>page.commissions.edit.modals.echantillons.form_leurre.label.conditionnement</label>
          <select formControlName="conditionnement" [ngClass]="{'is-invalid': form.submitted && getFormField('conditionnement').invalid}" class="form-control">
            <option *ngFor="let conditionnement of conditionnements" [ngValue]="conditionnement">{{conditionnement.libelle}}</option>
          </select>
        </div>

        <div class="form-group">
          <label translate>page.commissions.edit.modals.echantillons.form_leurre.label.type</label>
          <select formControlName="type" [ngClass]="{'is-invalid': form.submitted && getFormField('type').invalid}" class="form-control">
            <option *ngFor="let type of typesLeurre" [ngValue]="type">{{type.libelle}}</option>
          </select>
        </div>

        <div class="form-group">
          <label translate>page.commissions.edit.modals.echantillons.form_leurre.label.defauts</label>
          <app-multiselect id="select-defauts" maxWidth="initial" bindLabel="libelle" controlName="defauts" [items]="defauts" [ngClass]="{'is-invalid': form.submitted && getFormField('defauts').invalid}"></app-multiselect>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-light" (click)="cancel()" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary" translate>page.commissions.edit.modals.echantillons.form_leurre.creer_leurre</button>
      </div>
    </form>
  </ng-template>
<!--  -->

<ng-template #aucunJure>
    <div translate>page.commissions.edit.modals.echantillons.table.aucun_echantillon</div>
</ng-template>

<ng-template #tableHeader let-property="sortProperty" let-libelle="libelle">
  <th *ngIf="property" (click)="setOrder(property)" [class.sortable]="property">
    {{libelle | translate}}
    <fa-icon *ngIf="property" [icon]="getIcon(property)"></fa-icon>
  </th>
  <th *ngIf="!property">{{libelle | translate}}</th>
</ng-template>
