<div class="modal-content" >
  <div class="modal-header">
    <b translate> page.inventaire-verger.modals.add-bloc.detail-uc</b>
    <button (click)="cancel()" type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <h5 class="sub-popup-title" translate>page.inventaire-verger.modals.add-bloc.localisationTitle</h5>
      <div class="row">
        <div class="col-lg-4">
          <span translate>page.inventaire-verger.departement</span>
          <span>: {{uniteCulturale.localisation?.departement ? uniteCulturale.localisation.departement:'N/C'}}</span>
        </div>
        <div class="col-lg-4">
          <span translate>page.inventaire-verger.commune</span>
          <span>: {{uniteCulturale.localisation?.commune ? uniteCulturale.localisation.commune:'N/C'}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <span translate>page.inventaire-verger.lieuDit</span>
          <span>: {{uniteCulturale.localisation?.lieuDit ? uniteCulturale.localisation.lieuDit:'N/C'}}</span>
        </div>
        <div class="col-lg-4">
          <span translate>page.inventaire-verger.refCadastrale</span>
          <span>: {{uniteCulturale.localisation?.referenceCadastrale ? uniteCulturale.localisation.referenceCadastrale:'N/C'}}</span>
        </div>
      </div>
      <hr>
      <h5 class="sub-popup-title" translate>page.inventaire-verger.modals.add-bloc.tracabiliteTitle</h5>
      <div class="row">
        <div class="col-lg-3">
          <span translate>page.inventaire-verger.numBloc</span>
          <span>: {{uniteCulturale.numBlocFruitier ? uniteCulturale.numBlocFruitier:'N/C'}}</span>
        </div>  
        <div class="col-lg-3">
        </div>
      </div>
      <hr>
      <h5 class="sub-popup-title" translate>page.inventaire-verger.modals.add-bloc.botaniqueTitle</h5>
      <div class="row">
        <div class="col-lg-3">
          <span translate>page.inventaire-verger.cepage</span>
          <span>: {{uniteCulturale.variete?.libelle ? uniteCulturale.variete.libelle:'N/C'}}</span>
        </div>        
      </div>
      <div class="row">
        <div class="col-lg-4">
          <span translate>page.inventaire-verger.anneePlantation</span>
          <span>: {{uniteCulturale.anneePlantation ? uniteCulturale.anneePlantation:'N/C'}}</span>
        </div>
        <div class="col-lg-4">
          <span translate>page.inventaire-verger.distanceInterRang</span>
          <span>: {{uniteCulturale.distanceInterRang ? uniteCulturale.distanceInterRang:'N/C'}}</span>
        </div>
        <div class="col-lg-4">
          <span translate>page.inventaire-verger.distanceIntraRang</span>
          <span>: {{uniteCulturale.distanceIntraRang ? uniteCulturale.distanceIntraRang:'N/C'}}</span>
        </div>        
      </div>
      <div class="row">
        <div class="col-lg-4">
          <span translate>page.inventaire-verger.surfacePlantee</span>
          <span>: {{uniteCulturale.uniteCulturaleVigne.surfacePlantee ? uniteCulturale.uniteCulturaleVigne.surfacePlantee:'N/C'}}</span>
        </div>
        <div class="col-lg-4">
          <span translate>page.inventaire-verger.manquant</span>
          <span>: {{uniteCulturale.uniteCulturaleVigne.manquant ? uniteCulturale.uniteCulturaleVigne.manquant:''}}</span>
        </div>
        <div class="col-lg-4">
          <span translate>page.inventaire-verger.densite</span>
          <span>: {{uniteCulturale.uniteCulturaleVigne.densite ? uniteCulturale.uniteCulturaleVigne.densite:''}}</span>
        </div> 
      </div>
      <hr>
      <h5 class="sub-popup-title" translate>page.inventaire-verger.modals.add-bloc.caracteristiqueTitle</h5>
      <div class="row">
        <div class="col-lg-3">
          <span translate>page.inventaire-verger.systemeIrrigation</span>
          <span>: {{uniteCulturale.caracteristiqueParcelle.refSystemeIrrigation?.libelle ? uniteCulturale.caracteristiqueParcelle.refSystemeIrrigation?.libelle:'N/C'}}</span>
        </div>
        <div class="col-lg-3">
          <span translate>page.inventaire-verger.systemeIrrigation</span>
          <span>: {{uniteCulturale.caracteristiqueParcelle.refSystemeIrrigation?.libelle ? uniteCulturale.caracteristiqueParcelle.refSystemeIrrigation?.libelle:'N/C'}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
        </div>
      </div>
      <hr>
      <h5 class="sub-popup-title" translate>page.inventaire-verger.modals.add-bloc.destinationTitle</h5>
      <div class="row">
        <div class="col-lg-6">
          <span translate>page.inventaire-verger.modals.add-bloc.destination</span>
          <span>: {{ uniteCulturale.uniteCulturaleVigne?.destinationVendange ? destinations[uniteCulturale.uniteCulturaleVigne.destinationVendange] : '' }}</span>
        </div>
        <div class="col-lg-6">
          <span translate>page.inventaire-verger.destinataire</span>
          <span>: {{uniteCulturale.uniteCulturaleVigne.entreprise?.raisonSociale ? uniteCulturale.uniteCulturaleVigne.entreprise?.raisonSociale :'N/C'}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <span translate>page.inventaire-verger.produitIdentifie</span>
          <span>: {{uniteCulturale.uniteCulturaleVigne.produitIdentifie ? uniteCulturale.uniteCulturaleVigne.produitIdentifie:'N/C'}}</span>
        </div>
        <div class="col-lg-6">
          <span translate>page.inventaire-verger.produitDestination</span>
          <span>: {{uniteCulturale.uniteCulturaleVigne.produitDestination ? uniteCulturale.uniteCulturaleVigne.produitDestination:'N/C'}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <span translate>page.inventaire-verger.surfaceEngagee</span>
          <span>: {{uniteCulturale.uniteCulturaleVigne.surfaceEngagee ? uniteCulturale.uniteCulturaleVigne.surfaceEngagee:'N/C'}}</span>
        </div>
      </div>
    </div>
</div>
