import {
  CustomConverter,
  JsonObject,
  JsonProperty,
  Optional,
} from "@thorolf/json-ts-mapper";
import { DateConverter } from "src/app/core/services/mapper/converters";

@JsonObject
export class EngagementCtOc {
  @JsonProperty("dateDerniereMajInventaire", String)
  @CustomConverter(DateConverter)
  @Optional
  public dateDerniereMajInventaire: moment.Moment;

  @JsonProperty("idAuteur", Number)
  @Optional
  public idAuteur: number = undefined;

  @JsonProperty("nomPrenomAuteur", String)
  @Optional
  public nomPrenomAuteur: string = undefined;

  @JsonProperty("surfaceTotale", Number)
  @Optional
  public surfaceTotale: number = undefined;

  @JsonProperty("raisonSociale", String)
  @Optional
  public raisonSociale: string = undefined;

  @JsonProperty("typeEntreprise", String)
  @Optional
  public typeEntreprise: string = undefined;

  @JsonProperty("idEntreprise", Number)
  @Optional
  public idEntreprise: number = undefined;

  public dateEngagement: moment.Moment = undefined;

  public conseillerTechnique: String = undefined;
}
