import {JsonObject, JsonProperty, Optional} from '@thorolf/json-ts-mapper';
import {Stock} from './stock.model';

@JsonObject
export class DeclarationStock {
  @JsonProperty('idRefCampagne', Number)
  @Optional
  public idRefCampagne: number;

  @JsonProperty('idEntreprise', Number)
  @Optional
  public idEntreprise: number;

  @JsonProperty('numMois', Number)
  @Optional
  public numMois: number;

  @JsonProperty('stocks', [Stock])
  @Optional
  public stocks: Stock[] = [];

}
