import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { Domaine } from './domaine.model';
import { OrganismeType } from './organisme-type.model';
import { Pays } from './pays.model';
import { Structure } from './structure.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';

@JsonObject
export class Organisme {

  @JsonProperty('id', Number)
  @Optional
  public id: number = undefined;

  @JsonProperty('idType', Number)
  @CustomConverter(DataIdConverterFactory.build(OrganismeType, 'typesOrganismes'))
  public type: OrganismeType = undefined;

  @JsonProperty('idStructure', Number)
  @CustomConverter(DataIdConverterFactory.build(Structure, 'structures'))
  public structure: Structure = undefined;

  @JsonProperty('siret', String)
  @Optional
  public siret: string = undefined;

  @JsonProperty('raisonSociale', String)
  @Optional
  public raisonSociale: string = undefined;

  @JsonProperty('adresse', String)
  @Optional
  public adresse: string = undefined;

  @JsonProperty('codePostal', String)
  @Optional
  public codePostal: string = undefined;

  @JsonProperty('ville', String)
  @Optional
  public ville: string = undefined;

  @JsonProperty('idPays', Number)
  @CustomConverter(DataIdConverterFactory.build(Pays, 'pays'))
  @Optional
  public pays: Pays = undefined;

  @JsonProperty('cedex', String)
  @Optional
  public cedex: string = undefined;

  @JsonProperty('telephoneFixe', String)
  @Optional
  public telephone: string = undefined;

  @JsonProperty('telephoneMobile', String)
  @Optional
  public portable: string = undefined;

  @JsonProperty('fax', String)
  @Optional
  public fax: string = undefined;

  @JsonProperty('mail', String)
  @Optional
  public mail: string = undefined;

  @JsonProperty('domaines', [Number])
  @CustomConverter(DataIdConverterFactory.build(Domaine, 'domaines'))
  @Optional
  public domaines: Domaine[] = [];

  @JsonProperty('logo', String)
  @Optional
  logo: string;

  // TODO abbonements
}
