<div class="content">
  <div class="content-panel">
    <h2 translate>page.edition.generation-documents.exporter-declarations.titre</h2>
    <div class="m-3">
      <form [formGroup]="formDeclaration" #form="ngForm" (ngSubmit)="onSubmit()" *ngIf="formDeclaration">
        <div class="form-row">
          <div class="col-md-2">
            <div class="form-group">
              <label class="form-check-label" for="annees" translate>page.edition.generation-documents.exporter-declarations.form.campagne</label>
              <app-multiselect id="annees"
                               controlName="annees"
                               [items]="refAnnees"
                               bindLabel="annee"
                               maxWidth="initial"
                               maxSelectedItems="2"
                               [ngClass]="{'is-invalid': form.submitted && annees.errors}">
              </app-multiselect>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="form-check-label" for="cdcs" translate>page.edition.generation-documents.exporter-declarations.form.cdcs</label>
              <app-multiselect id="cdcs"
                               controlName="cdcs"
                               [items]="refCahiers"
                               bindLabel="libelle"
                               maxWidth="initial"
                               maxSelectedItems="4"
              >
              </app-multiselect>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="form-check-label" for="dateMin" translate>page.edition.generation-documents.exporter-declarations.form.dateMin</label>
              <div class="input-group">
                <input id="dateMin" ngbDatepicker #dateMin="ngbDatepicker" formControlName="dateMin"
                       class="form-control"/>
                <div class="input-group-append">
                  <button type="button" (click)="dateMin.toggle()" class="btn btn-secondary">
                    <fa-icon icon="calendar-alt"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="form-check-label" for="dateMax" translate>page.edition.generation-documents.exporter-declarations.form.dateMax</label>
              <div class="input-group">
                <input id="dateMax" ngbDatepicker #dateMax="ngbDatepicker" formControlName="dateMax"
                       class="form-control"/>
                <div class="input-group-append">
                  <button type="button" (click)="dateMax.toggle()" class="btn btn-secondary">
                    <fa-icon icon="calendar-alt"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="form-row">
          <div class="col-md-5">
            <div class="form-group">
              <label for="mvts"
                     translate>page.edition.generation-documents.exporter-declarations.form.typesDeclaration</label>
              <app-multiselect id="mvts"
                               controlName="mvts"
                               [items]="refMouvements"
                               bindLabel="libelle"
                               maxWidth="initial"
                               maxSelectedItems="5">
              </app-multiselect>
            </div>
          </div>
          <div class="col-md-5  pt-3">
            <div *ngIf="dateRangeError" class="alert alert-danger">
              {{ 'page.edition.generation-documents.exporter-declarations.form.dateRangeError' | translate }}
            </div>
          </div>
        </div>

        <!-- Boutons d'action formulaire -->
        <div class="actions-bar">
          <button type="submit" class="btn btn-primary ml-2" translate>label.exporter</button>
        </div>
        <!-- Fin boutons d'action formulaire -->
      </form>
    </div>
  </div>
</div>
