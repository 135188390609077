<div class="content">
  <div class="content-panel">
    <div class="actions">
      <div class="actions-left">
        <button 
          type="button" 
          class="btn-sm" 
          [ngClass]="{ 
            'btn-primary': (selectedTab$ | async) === gedOngletEnum.PUBLIC,
            'btn-secondary': (selectedTab$ | async) !== gedOngletEnum.PUBLIC
          }"
          (click)="onChangeSelectedTab(gedOngletEnum.PUBLIC)"
          translate
        >
          page.documents.documents-publics
        </button>

        <button 
          type="button" 
          class="btn-sm" 
          [ngClass]="{ 
            'btn-primary': (selectedTab$ | async) === gedOngletEnum.PRIVE, 
            'btn-secondary': (selectedTab$ | async) !== gedOngletEnum.PRIVE 
          }"
          (click)="onChangeSelectedTab(gedOngletEnum.PRIVE)"
          translate
        >
          page.documents.documents-prives
        </button>

        <button
          type="button"
          class="btn-sm"
          [ngClass]="{ 
            'btn-primary': (selectedTab$ | async) === gedOngletEnum.ARCHIVE, 
            'btn-secondary': (selectedTab$ | async) !== gedOngletEnum.ARCHIVE 
          }"
          (click)="onChangeSelectedTab(gedOngletEnum.ARCHIVE)"
          translate
        >
          page.documents.documents-archives
          
        </button>
      </div>

      <div class="actions-right">
        <button *hasRole="'EDITION_GED_ECRITURE'" 
          type="button"
          class="btn-sm btn-primary"
          (click)="onAddFile()"
          translate
        >
          page.documents.upload
        </button>
      </div>
    </div>

    <ng-container 
      *ngIf="(selectedTab$ | async) === gedOngletEnum.PUBLIC"
      [ngTemplateOutlet]="fichiers"
      [ngTemplateOutletContext]="{ fichiers: (fichiersPubliques$ | async) }"
    ></ng-container>

    <ng-container 
      *ngIf="(selectedTab$ | async) === gedOngletEnum.PRIVE"
      [ngTemplateOutlet]="fichiers"
      [ngTemplateOutletContext]="{ fichiers: (fichiersPrives$ | async) }"
    ></ng-container>

    <ng-container 
      *ngIf="(selectedTab$ | async) === gedOngletEnum.ARCHIVE"
      [ngTemplateOutlet]="fichiers"
      [ngTemplateOutletContext]="{ fichiers: (fichiersArchives$ | async) }"
    ></ng-container>
  </div>
</div>  
<div class="tableScroll">
<ng-template #fichiers let-fichiers="fichiers">
  <table class="table">
    <thead class="sticky-top">
      <th translate>page.documents.nom</th>
      <th translate>page.documents.type-document</th>
      <th translate>page.documents.description</th>
      <th translate>page.documents.date-ajout</th>
      <th class="tab-actions"></th>
    </thead>
    <tbody>
      <tr *ngFor="let fichier of fichiers">
        <td><a (click)="onDownloadFile(fichier.uuid)">{{ fichier.libelle }}</a></td>
        <td>{{ fichier.typeFichier.libelle }}</td>
        <td>{{ fichier.description }}</td>
        <td>{{ fichier.dateUpload | date:'dd/MM/yyyy HH:mm' }}</td>
        <td>
          <fa-icon icon="download" (click)="onDownloadFile(fichier.uuid)" title="Télécharger"></fa-icon>
          <span *hasRole="'EDITION_GED_ECRITURE'" >
            <fa-icon icon="edit" (click)="onUpdateFile(fichier)" title="Mettre à jour les informations"></fa-icon>
            <fa-icon *ngIf="(selectedTab$ | async) !== gedOngletEnum.ARCHIVE" icon="archive" (click)="onArchiver(fichier.id)" title="Archiver"></fa-icon>  
          </span>
        </td>
      </tr>
      <tr *ngIf="!fichiers || fichiers?.length === 0">
        <td class="text-center" colspan="5" translate>page.documents.aucun-document</td>
      </tr>
    </tbody>
  </table>
</ng-template>
</div>
