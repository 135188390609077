import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-ajout-commentaire',
  templateUrl: './ajout-commentaire.component.html',
  styleUrls: ['./ajout-commentaire.component.scss']
})
export class AjoutCommentaireComponent implements OnInit {

  @Input() public commentaire ;
  @ViewChild('form') form: NgForm;
  formGroup : FormGroup;
  
  isAnpp: boolean;

  constructor(
    public readonly fb: FormBuilder,
    public readonly modal: NgbActiveModal,
    public readonly authService: AuthService
  ) { }

  ngOnInit() {
    this.isAnpp = this.authService.isAnpp();
    this.formGroup = this.fb.group({
      commentaire: [this.commentaire, Validators.required],
    });
  }


  onSubmit(){
    this.commentaire = this.formGroup.get('commentaire').value
    this.modal.close(this.commentaire );
  
    }
  
    onClose(){
      this.modal.dismiss();
    }
}
