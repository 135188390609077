<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
      page.controles.controles.modals.telecharger-document.title
    </h4>
  </div>

  <div class="modal-body">
    <form [formGroup]="formGroup" #form="ngForm" (submit)="onSubmit()">

      <div class="modal-body" *ngFor="let document of documents; let i=index;">
        <input type="checkbox" formControlName="document" [(ngModel)]="documents[i].checked"
          (change)="isDocumentsCheck()"
          [ngClass]="{ 'is-invalid': form.submitted && formGroup.get('document').invalid }">
        {{document.libelle}}
      </div>
      <div *ngIf="noDocuments" style="color: red;"> Veuillez sélectionner au moins un document.</div>
      <div class="actions-bar">
        <button type="button" (click)="onClose()" class="btn btn-light mr-2" translate>label.annuler</button>
        <button *ngIf="!(commissionSendMail && categorie == 'COMMISSION')" type="submit" class="btn btn-primary"
          translate>label.telecharger</button>
        <button *ngIf="commissionSendMail && categorie == 'COMMISSION'" type="button" (click)="next()"
          class="btn btn-primary" translate>label.suivant</button>
      </div>
    </form>
  </div>
</div>