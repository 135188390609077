import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import { Organisme } from 'src/app/data/intervenant/models/organisme.model';
import { OrganismeType } from 'src/app/data/intervenant/models/organisme-type.model';
import { Structure } from 'src/app/data/intervenant/models/structure.model';
import { Pays } from 'src/app/data/intervenant/models/pays.model';
import { OrganismesService } from 'src/app/data/intervenant/services/organismes/organismes.service';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';

@Component({
  selector: 'app-edit-organisme',
  templateUrl: './edit-organisme.component.html',
  styleUrls: ['./edit-organisme.component.scss']
})
export class EditOrganismeComponent implements OnInit {
  creation = false;
  listePays: Pays[];
  private organisme: Organisme;
  structures: Structure[];
  typesOrganismes: OrganismeType[];

  formGroup: FormGroup;
  get type() { return this.formGroup.get('type'); }
  get structure() { return this.formGroup.get('structure'); }
  get raisonSociale() { return this.formGroup.get('raisonSociale'); }
  get siret() { return this.formGroup.get('siret'); }
  get adresse() { return this.formGroup.get('adresse'); }
  get codePostal() { return this.formGroup.get('codePostal'); }
  get ville() { return this.formGroup.get('ville'); }
  get pays() { return this.formGroup.get('pays'); }
  get cedex() { return this.formGroup.get('cedex'); }
  get telephone() { return this.formGroup.get('telephone'); }
  get portable() { return this.formGroup.get('portable'); }
  get fax() { return this.formGroup.get('fax'); }
  get mail() { return this.formGroup.get('mail'); }
  get logo() { return this.formGroup.get('logo'); }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly organismeService: OrganismesService,
    private readonly referencesService: ReferencesService,
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly loaderService: AdvBootstrapLoaderService
  ) { }

  ngOnInit() {
    const idOrganisme = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.loadData(idOrganisme);
  }

  loadData(idOrganisme: number) {
    this.creation = !idOrganisme;

    return forkJoin(
      !this.creation ? this.organismeService.getOrganisme(idOrganisme) : of(new Organisme()),
      this.referencesService.getReferences()
    ).pipe(
      this.loaderService.operator()
    ).subscribe(([organisme, references]) => {
      this.organisme = organisme;
      this.buildForm(organisme);

      this.typesOrganismes = references.typesOrganismes;
      this.structures = references.structures;
      this.listePays = references.pays;
    });
  }

  buildForm(organisme: Organisme) {
    this.formGroup = this.formBuilder.group({
      type: [{ value: organisme.type, disabled: !!organisme.id }, Validators.required],

      structure: [organisme.structure],
      raisonSociale: [organisme.raisonSociale, Validators.required],
      siret: [organisme.siret],

      adresse: [organisme.adresse],
      codePostal: [organisme.codePostal],
      ville: [organisme.ville],
      pays: [organisme.pays],
      cedex: [organisme.cedex],

      telephone: [organisme.telephone],
      portable: [organisme.portable],
      fax: [organisme.fax],
      mail: [organisme.mail],
      logo: [organisme.logo]
    });

    if (!this.auth.hasRole('INTERVENANT_ORGANISME_MODIFICATION')) {
      this.formGroup.disable();
    }
  }

  submit() {
    if (!this.auth.hasRole('INTERVENANT_ORGANISME_MODIFICATION')) {
      this.navigateToAbonements(this.organisme.id);
    } else if (this.formGroup.valid) {
      const organisme = new Organisme();

      organisme.type = this.type.value;
      organisme.structure = this.structure.value;
      organisme.raisonSociale = this.raisonSociale.value;
      organisme.siret = this.siret.value;
      organisme.adresse = this.adresse.value;
      organisme.codePostal = this.codePostal.value;
      organisme.ville = this.ville.value;
      organisme.pays = this.pays.value;
      organisme.cedex = this.cedex.value;
      organisme.telephone = this.telephone.value;
      organisme.portable = this.portable.value;
      organisme.fax = this.fax.value;
      organisme.mail = this.mail.value;
      organisme.logo = this.logo.value

      if (this.creation) {
        this.organismeService.createOrganisme(organisme).subscribe(idOrganisme => {
          this.navigateToOrganismesList();
        });
      } else {
        organisme.id = this.organisme.id;
        this.organismeService.modifyOrganisme(organisme).subscribe(() => {
          this.navigateToOrganismesList();
        });
      }
    }
  }

  private navigateToAbonements(idOrganisme: number) {
    const url = (this.creation ? '' : '../') + '../edit-abonnements';
    this.router.navigate([url, idOrganisme], { relativeTo: this.route });
  }

  private navigateToOrganismesList(): void {
    const url = this.creation ? '../' : '../../';
    this.router.navigate([ url ], { relativeTo: this.route });
  }

  public onFileSelected(event): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        this.organisme.logo = reader.result as string;
        this.logo.setValue(reader.result as string);
    };
  }

}
