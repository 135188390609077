import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { GedFichier } from './ged-fichier.model';

@JsonObject
export class DashboardGed {

    @JsonProperty('newsletters', [GedFichier])
    @Optional
    newsletters: GedFichier[] = undefined;

    @JsonProperty('documents', [GedFichier])
    @Optional
    documents: GedFichier[] = undefined;
}
