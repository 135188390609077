import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { remove } from 'lodash';
import { forkJoin } from 'rxjs';
import { Fonction } from 'src/app/data/intervenant/models/fonction.model';
import { FonctionsService } from 'src/app/data/intervenant/services/fonctions/fonctions.service';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';

@Component({
  selector: 'app-liste-fonctions',
  templateUrl: './liste-fonctions.component.html',
  styleUrls: ['./liste-fonctions.component.scss']
})
export class ListeFonctionsComponent implements OnInit {  

  fonctions : Fonction[];

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly referenceService: ReferencesService,
    private readonly modalsService : AdvBootstrapModalService,
    private readonly translate: TranslateService,
    private readonly fonctionService : FonctionsService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
 
    //this.fonctions = ReferencesService.getReference('fonctions');

    forkJoin(
      this.referenceService.getReferences(true)
      )
    .pipe(this.loaderService.operator())
    .subscribe(([fonctions]) => {
      this.fonctions = fonctions["fonctions"];     
    });

    

  }

  openConfirmationDialog(fonction: Fonction) { 

    this.modalsService.confirm(
      'Veuillez confirmer',
      'Voulez vous supprimer la fonction '+ fonction.libelle + ' ?', {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.deleteFonction(fonction);
    }, () => { });

  }

  deleteFonction(fonction: Fonction) {
    this.fonctionService.supprimerFonction(fonction.id).subscribe(() => {
      remove(this.fonctions, fonction);
    });
  }

  editFonction(fonction: Fonction) {
    this.modalsService.confirm(
      'Veuillez confirmer',
      this.translate.instant(`page.valeur_parametrable.message_modification`), {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.router.navigate(['edit', fonction.id], { relativeTo: this.route });
    }, () => { });
  }

}
