<div class="content">
  <div class="content-panel">
    <h2>
      <span *ngIf="edition == false" translate>page.tournees.edit.titleCreer</span>
      <span *ngIf="edition == true" translate>page.tournees.edit.titleEditer</span>
      <span class="chiffres">        
        <fa-icon icon="user-plus" title="{{'page.tournees.edit.tooltip.operateurs'|translate}}"></fa-icon>
        {{nbOperateurs}} -
        <fa-icon icon="wine-glass-alt" title="{{'page.tournees.edit.tooltip.echantillons'|translate}}"></fa-icon>
        {{nbEchantillons}}
        <span *ngIf="edition == true">
          <label translate style="font-size: 20px; padding-right: 10px; padding-left: 50px;">Terminée</label>
          <input type="checkbox" (change)="checkValue(tourneeIsOver? true:false)" [(ngModel)]="tourneeIsOver" />
        </span>
      </span>
      <div *ngIf="edition == true">
        <span>
          <button style="position:relative;" type="button" class="btn btn-primary" (click)="telechargerDocument()"
            translate>page.tournees.edit.telechargerDocuments</button>         
        </span>
      </div>
    </h2>
    <form *ngIf="formGroup" [formGroup]="formGroup" #form="ngForm" (ngSubmit)="submit()" novalidate>

      <div class="form-section">
        <div class="form-group">
          <label translate>page.tournees.edit.date</label>

          <div class="input-group">
            <input formControlName="date" ngbDatepicker #datePicker="ngbDatepicker"
              [ngClass]="{'is-invalid': form.submitted && formGroup.get('date').invalid}" class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="datePicker.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label translate>page.tournees.edit.heureDebut</label>
          <ngb-timepicker formControlName="heureDebut"
            [ngClass]="{'is-invalid': form.submitted && formGroup.get('heureDebut').invalid}" class="form-control">
          </ngb-timepicker>
        </div>
        <div class="form-group">
          <label translate>page.tournees.edit.heureFin</label>
          <ngb-timepicker formControlName="heureFin"
            [ngClass]="{'is-invalid': form.submitted && formGroup.get('heureFin').invalid}" class="form-control">
          </ngb-timepicker>
        </div>
      </div>    
      <div class="form-section">
        <div class="form-group">
          <label translate>page.tournees.edit.technicien</label>
          <select formControlName="technicien"
            [ngClass]="{'is-invalid': form.submitted && formGroup.get('technicien').invalid}" class="form-control">
            <option *ngFor="let technicien of techniciens" [ngValue]="technicien">{{technicien.prenom}} {{technicien.nom}}
            </option>
          </select>
        </div>
      </div>    
      <div>
        <button style="position:relative;left:66%;margin:1em;" type="button" class="btn btn-primary" (click)="addEchantillons()" translate><fa-icon icon="plus" ></fa-icon> page.tournees.edit.affecter-echantillons</button>        
      </div>

<table table class="table table-striped" *ngIf="echantillons?.length > 0; else aucunEchantillon">
  <thead>
    <tr>
      <th></th>
      <th translate>page.tournees.edit.table.raisonSociale</th>
      <th translate>page.tournees.edit.table.cvi</th>
      <th translate>page.tournees.edit.table.produit</th>
      <th translate>page.tournees.edit.table.millesime</th>
      <th translate>page.tournees.edit.table.volume</th>
      <th translate>page.tournees.edit.table.logement</th>
      <th translate>page.tournees.edit.table.adresse</th>
      <th></th>
    </tr>
  </thead>
  <tbody cdkDropList id="echantillons-{{index}}" (cdkDropListDropped)="moveEchantillon($event)" >
      <tr  *ngFor="let echantillon of echantillons" cdkDrag cdkDragLockAxis="y">
        <td> <div class="listes-dad-placeholder" *cdkDragPlaceholder></div> 
          <fa-icon cdkDragHandle icon="bars" class="grab" style="font-size:0.8em;"></fa-icon> </td>
        <td>{{getRaisonSociale(echantillon)}}</td>
        <td>{{getCVI(echantillon)}}</td>
        <td>{{echantillon.produit?.libelle}}</td>
        <td>{{echantillon.millesime? echantillon.millesime : ''}}</td>
        <td>{{echantillon.volume}}</td>
        <td>{{echantillon.logement? echantillon.logement : ''}}</td>
        <td>{{echantillon.site?.adresse}}</td>
        <td> 
          <button type="button" class="btn btn-outline-secondary btn-add"
          *ngIf="echantillon.codeStatut === 'A_PRELEVER'" (click)="onAddPrelevement(echantillon)">
          <fa-icon icon="plus" class="primary-icon" title="Saisir le prélèvement"></fa-icon>
        </button>
        <button type="button" class="btn btn-outline-secondary" *ngIf="echantillon.codeStatut === 'A_PRELEVER'" (click)="removeEchantillon(echantillon)">
          <fa-icon icon="trash" title="Enlever le prélèvement de la tournée" class="pointer"></fa-icon>
        </button> 
         </td>
      </tr>
  </tbody>
</table>  
      <div class="actions-bar">
        <span style="flex:1"></span>
        <button type="button" class="btn btn-primary" (click)="enregistrer()" translate>label.enregistrer</button>
        <button type="submit"  style="position:relative;margin-left: 1em;" class="btn btn-primary" translate>label.fermer</button>
      </div>
    </form>
  </div>
  <div style="height:20vh"></div>
</div>
<ng-template #aucunEchantillon>
  <div class="alert alert-warning alert-block" translate>
    <fa-icon icon="exclamation"></fa-icon> page.tournees.edit.aucunEchantillon
  </div>
</ng-template>

