import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Recolte } from 'src/app/data/declaration/models/recolte.model';
import { RecoltesService } from 'src/app/data/declaration/services/recoltes/recoltes.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AjoutGroupeVarietalComponent } from './modals/ajout-groupe-varietal/ajout-groupe-varietal.component';
import { PrevisionDefinitiveComponent } from './modals/prevision-definitive/prevision-definitive.component';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { ChoixColonnesComponent } from '../../../../../shared/components/choix-colonnes/choix-colonnes.component';
import { AjoutCommentaireComponent } from './modals/ajout-commentaire/ajout-commentaire.component';
import { GroupeVarietal } from 'src/app/data/declaration/models/groupe-varietal.model';
import { RefCampagnesService } from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';
import { Campagne } from 'src/app/data/declaration/models/campagne.model';
import { DeclarationsService } from 'src/app/data/declaration/services/declarations/declarations.service';
import { UtilisateursService } from 'src/app/data/intervenant/services/utilisateurs/utilisateurs.service';
import { forkJoin, from } from 'rxjs';
import { UniteCulturaleService } from 'src/app/data/declaration/services/parcellaire/unite-culturale.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';

@Component({
  selector: 'app-recoltes',
  templateUrl: './recoltes.component.html',
  styleUrls: ['./recoltes.component.scss']
})
export class RecoltesComponent implements OnInit {
  formGroup: FormGroup;
  recoltes: Recolte[];
  campagnes: Campagne[];
  campagne: Campagne;
  campagnePrecedente: Campagne;
  estDefinitive: boolean = false;
  commentaire: string;
  entrepriseId: number;
  dateLastMajInventaire: Date = undefined;

  get campagneSel() { return this.formGroup.get('selectionCampagne'); }

  headers = [
    { name: 'surfTotale', label: 'Surface Totale', checked: false },
    { name: 'surfBio', label: 'Surface Bio', checked: false },

    { name: 'rdtTotalPrec', label: 'Rendement Total Précédent', checked: false },
    { name: 'rdtBioPrec', label: 'Rendement Bio Précédent', checked: false },

    { name: 'prevInitialeTotale', label: 'Prévision Initiale Totale', checked: false },
    { name: 'rdtInitialTotal', label: 'Rendement initial Total', checked: false },

    { name: 'prevInitialeBio', label: 'Prévision Initiale Bio', checked: false },
    { name: 'rdtInitialBio', label: 'Rendement Initial Bio', checked: false },

    { name: 'rdtTotalDernierePrev', label: 'Rendement Total Dernière Prevision', checked: false },
    { name: 'rdtBioDernierePrev', label: 'Rendement Bio Dernière Prévision', checked: false }
  ];

  static routes: Routes = [
    {
      path: '', component: RecoltesComponent, data: {
        role: 'ORGANISME || DECLARATION_RECOLTE_ECRITURE',
        domaines: ["ANPP"],
        menu: {
          libelle: 'menu.recoltes',
          icon: 'key'
        }
      },
    },
    {
      path: ':idEntreprise', component: RecoltesComponent, data: {
        role: 'ORGANISME',
        domaines: ["ANPP"],
      }
    }
  ];


  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly recoltesService: RecoltesService,
    private readonly refCampagneService: RefCampagnesService,
    private fb: FormBuilder,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly modalService: NgbModal,
    private readonly declarationService: DeclarationsService,
    private readonly utilisateursService: UtilisateursService,
    private readonly uniteCulturaleService: UniteCulturaleService,
  ) { }


  onCampagneChange() {
    this.campagne = this.formGroup.get('selectionCampagne').value as Campagne;
    this.refCampagneService.getCampagneAnnee(this.campagne.annee - 1).subscribe(campagnePre => {
      this.campagnePrecedente = campagnePre;
    }, error => { console.warn(error) });
    this.getRecoltes();
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      selectionCampagne: [this.campagne,]
    });

    this.declarationService.activeTabId = "recoltes"
    // récupération des campagnes
    this.refCampagneService.getCampagneAll().subscribe(
      referentiel => {
        this.campagnes = referentiel.sort((a, b) => {
          if (a && b && a.annee > b.annee) return -1;
          if (a && b && a.annee < b.annee) return 1;
          return 0;
        });
        // récupération de la campagne par défaut (bascule au 1er juin)
        let today = new Date();
        let dateLimit = new Date(today.getFullYear(), 5, 1);
        let anneeParDefaut = dateLimit < today ? today.getFullYear() : today.getFullYear() - 1;
        forkJoin([
          this.refCampagneService.getCampagneAnnee(anneeParDefaut),
          this.refCampagneService.getCampagneEnCours()
        ]).pipe(this.loaderService.operator()).subscribe(([campagneAnnee, campagneEnCours]) => {
          if(dateLimit < today) {
            this.campagne = campagneEnCours;
          } else {
            this.campagne = campagneAnnee;
          }
          this.refCampagneService.getCampagneAnnee(anneeParDefaut - 1).subscribe(campagnePre => {
            this.campagnePrecedente = campagnePre;
          });
          if (this.declarationService.entrepriseSelectionnee) {
            this.entrepriseId = this.declarationService.entrepriseSelectionnee.id;
            if (this.entrepriseId) {
              this.getRecoltes();
              this.getLastDateMaJInventaire();
            }
          } else {
            this.utilisateursService.getUtilisateur(SessionContext.get('idIntervenant')).subscribe(utilisateur => {
              if (!utilisateur || (utilisateur && !utilisateur.entreprise)) {
                this.entrepriseId = null;
                this.declarationService.entrepriseSelectionnee = null;
              } else {
                this.declarationService.entrepriseSelectionnee = utilisateur.entreprise;
                this.entrepriseId = utilisateur.entreprise.id;
                if (this.entrepriseId) {
                  this.getRecoltes();
                  this.getLastDateMaJInventaire();
                }
              }
            })
          }
          if (this.campagne) {
            const camp = this.campagnes.find(campagne => campagne.id == this.campagne.id);
            this.campagneSel.setValue(camp);
          }
        });
      })
  }

  /**
   * récupération des données de récolte de la campagne sélectionnée
   */
  getRecoltes() {
    this.recoltesService.getRecoltes(this.entrepriseId, this.campagne.id).subscribe(saisieRecolte => {
      this.recoltes = saisieRecolte.recoltes;
      this.commentaire = saisieRecolte.commentaire;
      this.estDefinitive = saisieRecolte.estDefinitive;
      this.ajoutTotaux();
      this.updateTotaux();
      this.recoltes.sort((a, b) => {
        return a.sorting().localeCompare(b.sorting());
      });


    });
  }

  getLastDateMaJInventaire() {
    this.uniteCulturaleService.getDateLastModifInventaireVergerEntreprisesLiees(this.entrepriseId).pipe(this.loaderService.operator()).subscribe((infos) => {
      if (infos){
        this.dateLastMajInventaire = infos.dateDerniereMajInventaire;
      }

      if (!this.dateLastMajInventaire) {
        this.toastr.warning(
          this.translate.instant('page.recoltesPp.alert.erreurInventaireVerger'),
          this.translate.instant('label.erreur'),
          { timeOut: 10 * 1000 }
        );

      }
    });
  }

  /**
   * appel modale récapitulative, puis sauvegarde
   */
  onSave() {
    if (this.getFormValidationErrors()) {
      this.toastr.warning(
        this.translate.instant('page.recoltesPp.alert.erreurSaisie'),
        this.translate.instant('label.erreur'),
        { timeOut: 10 * 1000 }
      );

    } else {

      const modalRef = this.modalService.open(PrevisionDefinitiveComponent, { size: 'lg', backdrop: 'static' });
      modalRef.componentInstance.recoltes = this.recoltes.filter(recolte => recolte.isTotal);
      modalRef.componentInstance.campagnePrecedente = this.campagnePrecedente;
      modalRef.componentInstance.estDefinitive = this.estDefinitive;

      modalRef.componentInstance.alerteBio = this.recoltes.filter(r => r.qteBio && r.qteBio > 0).length > 0 && this.recoltes.filter(r => r.surfaceBio && r.surfaceBio > 0).length === 0;
      modalRef.componentInstance.alerteSaisie = this.recoltes.filter(r => (r.getRdtTotalSaisi() && r.getRdtTotalSaisi() > 120) || (r.getRdtBioSaisi && r.getRdtBioSaisi() > 120)).length > 0;

      modalRef.result.then((result) => {
        if (result) {
          this.recoltesService.saveRecoltes(this.entrepriseId, this.campagne.id, this.recoltes, result, this.commentaire).subscribe(
            response => {
              this.toastr.success(
                this.translate.instant('page.recoltesPp.alert.creation.message'),
                this.translate.instant('page.recoltesPp.alert.creation.title'),
                { timeOut: 10 * 1000 }
              );
              this.getRecoltes();
            }
          );
        }
      });
    }
  }

  /**
   * ouverture modale ajout d'un groupe variétal
   */
  ajouterGroupeVarietal() {
    const modalRef = this.modalService.open(AjoutGroupeVarietalComponent, { size: 'lg', backdrop: 'static' });

    modalRef.result.then((result) => {
      if (result) {

        if (this.recoltes.filter(recolte => recolte.idGroupeVarietal == (result as GroupeVarietal).id).length > 0) {
          this.toastr.warning(
            this.translate.instant('page.recoltesPp.modalAjouterGroupeVarietal.dejaPresent'),
            this.translate.instant('label.erreur'),
            { timeOut: 10 * 1000 }
          );
        } else {
          let newGv = new Recolte();
          newGv.idGroupeVarietal = (result as GroupeVarietal).id;
          newGv.groupeVarietal = (result as GroupeVarietal).libelle;
          newGv.groupeVarietalVarietes = (result as GroupeVarietal).varietes;
          newGv.codeEspece = (result as GroupeVarietal).codeEspece;
          newGv.qteTotale = 0;
          newGv.qteBio = 0;
          this.recoltes.push(newGv);
          this.ajoutTotaux();
          this.updateTotaux();
          this.recoltes.sort((a, b) => {
            return a.sorting().localeCompare(b.sorting());
          });
        }

      }
    }
    ).catch(e => { });
  }

  /**
   * modale pour sélection des colonnes
   */
  afficherListe() {
    const modalRef = this.modalService.open(ChoixColonnesComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.headers = this.headers;

    from(modalRef.result).subscribe(headers => {
      this.headers = headers;
    });
  }

  /**
 * modale pour sélection des colonnes
 */
  ajouterCommentaire() {
    const modalRef = this.modalService.open(AjoutCommentaireComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.commentaire = this.commentaire;

    from(modalRef.result).subscribe(commentaire => {
      this.commentaire = commentaire;
    });
  }

  /**
   *
   * @param obj affichage des colonnes optionnelles
   * @returns
   */
  show(obj) {
    return (this.headers.find(h => h['name'] === obj)).checked;
  }

  /**
   * ajoute une ligne total pour une espèce si elle n'existe pas encore
   */
  ajoutTotaux(): void {
    (new Set(this.recoltes.map(recolte => recolte.codeEspece)))
      .forEach(codeEspece => {
        var total = this.recoltes.filter(r => r.isTotal && r.codeEspece === codeEspece).find((o) => { return o != null; });
        if (total == undefined) {
          var newTotal = new Recolte();
          newTotal.codeEspece = codeEspece;
          newTotal.groupeVarietal = "TOTAL";
          newTotal.isTotal = true;
          newTotal.idGroupeVarietal = codeEspece === "POMME" ? 9999 : 8888;
          newTotal.qteTotale = 0;
          newTotal.qteBio = 0;
          this.recoltes.push(newTotal);
        }
      })
  }

  updateTotaux(): void {
    // on fait un pseudo tableau des especes présentes
    (new Set(this.recoltes.map(recolte => recolte.codeEspece)))
      .forEach(codeEspece => {
        // on parcourt les especes
        var total = this.recoltes.filter(r => r.isTotal && r.codeEspece === codeEspece).find((o) => { return o != null; });
        if (total) {
          (total as Recolte).qteTotale = this.recoltes.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.qteTotale, 0);
          (total as Recolte).qteBio = this.recoltes.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.qteBio, 0);

          (total as Recolte).qteBioDernierePrevision = this.recoltes.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.qteBioDernierePrevision, 0);
          (total as Recolte).qteBioPrecedente = this.recoltes.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.qteBioPrecedente, 0);
          (total as Recolte).qteTotaleDernierePrevision = this.recoltes.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.qteTotaleDernierePrevision, 0);
          (total as Recolte).qteTotalePrecedente = this.recoltes.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.qteTotalePrecedente, 0);
          (total as Recolte).surfaceBio = this.recoltes.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.surfaceBio, 0);
          (total as Recolte).surfaceTotale = this.recoltes.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.surfaceTotale, 0);

          (total as Recolte).previsionInitialeTotale = this.recoltes.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.previsionInitialeTotale, 0);
          (total as Recolte).previsionInitialeBio = this.recoltes.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.previsionInitialeBio, 0);

        }
      })
  }


  getFormValidationErrors(): boolean {
    let retour = false;
    this.recoltes.forEach(r => {
      if (!r.isTotal && (r.qteBio == undefined || r.qteTotale == undefined)) {
        retour = true;
      }
    })
    return retour;
  }


  // Only Integer Numbers
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < '0'.charCodeAt(0) || charCode > '9'.charCodeAt(0))) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

}
