import { JsonProperty, JsonObject, Optional, CustomConverter } from '@thorolf/json-ts-mapper';

import { RefObjetFacture } from './ref-objet-facture.model';
import { Cahier } from '../../habilitation/models/cahier.model';
import { RefQuantiteFacture } from './ref-quantite-facture.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { QuantiteInfos } from './quantite-infos.models';
import { Operateur } from '../../intervenant/models/operateur.model';

@JsonObject
export class ObjetFactureTriNormal {
    @JsonProperty('id', Number)
    @Optional
    id: number;

    idsToSend: number[];

    @JsonProperty('idObjet', Number)
    idObjet: number;

    @JsonProperty('fonctionnelId', String)
    fonctionnelId: string;

    @JsonProperty('idOperateur', Number)
    idOperateur: number;

    @JsonProperty('raisonSocialeOperateur', String)
    raisonSocialeOperateur: string;

    @JsonProperty('idCahier', Number)
    @Optional
    @CustomConverter(DataIdConverterFactory.build(Cahier, 'cahiers'))
    cahier: Cahier;

    @JsonProperty('annee', Number)
    annee: number;

    @JsonProperty('quantite', [Number])
    quantite: number[];

    @JsonProperty('idRefQuantiteFacture', Number)
    @Optional
    @CustomConverter(DataIdConverterFactory.build(RefObjetFacture, 'refQuantiteFactureList'))
    refQuantiteFacture: RefQuantiteFacture[];

    @JsonProperty('estFacture', Boolean)
    @Optional
    estFacture: boolean;

    @Optional
    checked?: boolean;

    quantiteInfos: QuantiteInfos[];
}
