import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { RefSituation } from 'src/app/data/declaration/models/ref-situation.model';
import { RefSituationService } from 'src/app/data/declaration/services/parcellaire/ref-situation.service';

@Component({
  selector: 'app-edit-situation',
  templateUrl: './edit-situation.component.html',
  styleUrls: ['./edit-situation.component.scss']
})
export class EditSituationComponent implements OnInit {

  formGroup: FormGroup;
  creation = false;
  title: string;
  situation: RefSituation;

  get code() { return this.formGroup.get('code'); }
  get libelle() { return this.formGroup.get('libelle'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly situationService: RefSituationService,) { }

  ngOnInit() {

    const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    if (!id) {
      this.creation = true;
    }
      this.loadData(id);
    

  }

  loadData(id: number) {

    this.title = this.creation ? 'page.valeur_parametrable.form.title.add_situation': 'page.valeur_parametrable.form.title.edit_situation';
    
    forkJoin(
    !this.creation ? this.situationService.getRefSituation() : of(new RefSituation())
    )
    .pipe(
      this.loaderService.operator()
    ).subscribe(([gels]) => {
     this.situation = !this.creation ? gels.find(g => g.id === id) : gels ; 

    this.initForm((this.situation.id) ? this.situation : null);
     
    });
  }

  private initForm(situation?: RefSituation): void {
 
    this.formGroup = this.fb.group({
      code: [{ value: ((situation) ? situation.code: undefined), disabled: false }, Validators.required],
      libelle: [{ value: ((situation) ? situation.libelle : undefined), disabled: false }, Validators.required]
    });
  }

  submit() {
    if (this.formGroup.valid) {
      this.situation.code = this.code.value;
      this.situation.libelle = this.libelle.value;
     
      if (this.situation.id == null) {
        this.situationService.creerRefSituation(this.situation).subscribe(idCultureBio => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
        });
      } else {        
        this.situationService.modifierRefSituation(this.situation).subscribe( () => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
         } );
      }
     
    }
  }

}
