import { JsonObject, JsonProperty, Any, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { Assemblage } from './assemblage.model';
import { Contenant } from './contenant.model';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import * as moment from 'moment';
import { Cepage } from './cepage.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';

@JsonObject
export class SaisieConditionnement {
  @JsonProperty('codeProduit', String)
  codeProduit: string;

  @JsonProperty('date', String)
  @CustomConverter(DateConverter)
  date: moment.Moment;

  @JsonProperty('logement', String)
  logement: string;

  @JsonProperty('numeroLot', String)
  numeroLot: string;

  @JsonProperty('volume', Number)
  volume: number;

  @JsonProperty('idSite', Number)
  idSite: number;

  @JsonProperty('assemblages', [Assemblage])
  assemblages: Assemblage[];

  @JsonProperty('contenants', [Contenant])
  contenants: Contenant[];

  @JsonProperty('idsCepages', [Number])
  @CustomConverter(DataIdConverterFactory.build(Cepage, 'cepages'))
  @Optional
  cepages: Cepage[];

  @JsonProperty('observation', String)
  @Optional
  observations: string;
}
