import { CustomConverter, JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";
import { Entreprise } from "../../intervenant/models/entreprise.model";
import { Utilisateur } from "../../intervenant/models/utilisateur.model";
import { Operateur } from "../../intervenant/models/operateur.model";
import { Produit } from "../../habilitation/models/produit.model";
import { DateConverter } from "src/app/core/services/mapper/converters";

@JsonObject
export class SuiviParcellairesViticoles {

    @JsonProperty('idDeclaration', Number)
	@Optional
    idDeclaration: number = undefined;
    
	@JsonProperty('idEntreprise', Number)
    @Optional
	idEntreprise: number;

	entreprise: Entreprise;

    @JsonProperty('idUniteCulturale', Number)
    @Optional
	idUniteCulturale: number;


    @JsonProperty('dateDerniereMaj', String)
    @CustomConverter(DateConverter)
    @Optional
    dateDerniereMaj: moment.Moment;
    
	@JsonProperty('dateDerniereValidation', String)
    @CustomConverter(DateConverter)
    @Optional
	dateDerniereValidation: moment.Moment;
    
	@JsonProperty('idAuteurDerniereValidation', Number)
    @Optional
	idAuteurDerniereValidation: number;

	auteurModification: Utilisateur;

    @JsonProperty('surfacePlantee', Number)
    @Optional
	surfacePlantee: number;

    @JsonProperty('surfaceEngagee', Number)
    @Optional
	surfaceEngagee: number;

    @JsonProperty('variete', String)
    @Optional
	variete: string;

    @JsonProperty('annee', String)
    @Optional
	annee: string;

    @JsonProperty('anneePlantation', String)
    @Optional
	anneePlantation: string;

    @JsonProperty('tracabilite', String)
    @Optional
	tracabilite: string;

    @JsonProperty('referenceCadastrale', String)
    @Optional
	referenceCadastrale: string;

    @JsonProperty('commune', String)
    @Optional
	commune: string;

    @JsonProperty('libelleProduit', String)
    @Optional
	libelleProduit: string;



    operateur: Operateur = new Operateur();
    produit: Produit = new Produit();
}