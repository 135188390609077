import { JsonObject, JsonProperty } from "@thorolf/json-ts-mapper";

@JsonObject
export class Estimation {
  @JsonProperty('espece', String)
  public espece: string;

  @JsonProperty('groupeVarietal', String)
  public groupeVarietal: string;

  @JsonProperty('taux', Number)
  public taux: number;
}
