import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {JsonMapperService} from 'src/app/core/services/mapper/mapper.service';
import {Jure} from '../../models/jure.model';
import {Observable, forkJoin, of} from 'rxjs';
import {CahiersService} from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import {map, mergeMap, tap} from 'rxjs/operators';
import {OperateursService} from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import {ReferencesService} from '../references/references.service';
import {CacheKey, Cache, ClearCache} from 'src/app/core/services/cache/cache.service';
import {JureCdc} from '../../models/jure-cdc.model';
import * as moment from 'moment';
import {JureSynthese} from '../../models/jure-synthese.model';
import {DateConverter} from 'src/app/core/services/mapper/converters';
import {CollegeService} from '../college/college.service';
import {ComJure} from '../../models/com-jure.model';
import {ComQualif} from '../../models/com-qualif.model';

@Injectable({
  providedIn: 'root'
})
export class JuresService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly cahiersService: CahiersService,
    private readonly refService: ReferencesService,
    private readonly operateursService: OperateursService,
    private readonly collegeService: CollegeService
  ) {
  }

  @ClearCache
  clezrCache() {
  }

  @Cache()
  getJuresByCdcs(@CacheKey idOrganisme: number, @CacheKey idsCahiers: number[]): Observable<JureCdc[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/declaration/private/organismes/${idOrganisme}/jures/cahiers?cdc=${idsCahiers.join(',')}`),
      this.cahiersService.getCahiers(),
      this.refService.getReferences()
    ).pipe(
      map(([jures, cahiers, ref]) => this.mapper.deserializeArray(jures, JureCdc, Object.assign({cahiers}, ref))),
      mergeMap(jures => {
        if (jures.length === 0) {
          return of([]);
        }
        return forkJoin(
          jures.map(jure => this.loadOperateursJure(jure))
        );
      })
    );
  }

  @Cache()
  getJuresInactifsByCahiers(@CacheKey idOrganisme: number, @CacheKey idsCahiers: number[]): Observable<JureCdc[]> {
    return forkJoin(
      // tslint:disable-next-line:max-line-length
      this.http.get<JureCdc[]>(`/api/declaration/private/organismes/${idOrganisme}/juresInactifs/cahiers?cdc=${idsCahiers.join(',')}`),
      this.cahiersService.getCahiers(),
      this.refService.getReferences()
    ).pipe(
      map(([jures, cahiers, ref]) => this.mapper.deserializeArray(jures, JureCdc, Object.assign({cahiers}, ref))),
      mergeMap(jures => {
        if (jures.length === 0) {
          return of([]);
        }
        return forkJoin(
          jures.map(jure => this.loadOperateursJure(jure))
        );
      })
    );
  }

  getJureById(jureId: number): Observable<ComJure> {
    return forkJoin(
      this.http.get<object>(`/api/declaration/private/jure/${jureId}`),
      this.collegeService.getColleges(),
    ).pipe(
      map(([comJure, colleges]) => {
          return this.mapper.deserializeObject(comJure, ComJure, Object.assign({colleges}));
        }
      ),
      tap(comJure => comJure.qualifications.forEach(item => {
          item.idsOperateurs.forEach(operateurId => {
            this.operateursService.getOperateur(operateurId)
              .subscribe(operateurRecu => item.operateurs.push(operateurRecu));
          });
        })
      ));
  }

  getJures(idOrganisme: number): Observable<Jure[]> {

    return forkJoin(
      this.http.get<object[]>(`/api/declaration/private/organismes/${idOrganisme}/jures`),
      this.cahiersService.getCahiers(),
      this.refService.getReferences()
    ).pipe(
      map(([jures, cahiers, ref]) => this.mapper.deserializeArray(jures, Jure, Object.assign({cahiers}, ref)))
    );
  }

  private loadOperateursJure(jure: JureCdc): Observable<JureCdc> {
    if (!jure.idsOperateurs || jure.idsOperateurs.length === 0) {
      return of(jure);
    }

    return forkJoin(jure.idsOperateurs.map(id => this.operateursService.getOperateur(id))).pipe(
      map(operateurs => {
        jure.operateurs = operateurs;
        return jure;
      })
    );
  }

  public getSyntheseJures(idOrganisme: number, dateDebut: moment.Moment, dateFin: moment.Moment): Observable<JureSynthese[]> {

    const dateConverter = new DateConverter();

    const debut = dateConverter.serialize(dateDebut);
    const fin = dateConverter.serialize(dateFin);

    let params = new HttpParams();

    params = (dateDebut) ? params.set('debut', dateConverter.serialize(dateDebut)) : params;
    params = (dateFin) ? params.set('fin', dateConverter.serialize(dateFin)) : params;

    return this.http.get<JureSynthese[]>(`/api/declaration/private/organismes/${idOrganisme}/jures-synthese`, {params});
  }

  public getDetailJures(idOrganisme: number, idJure: number, dateDebut: moment.Moment, dateFin: moment.Moment): Observable<JureCdc[]> {

    const dateConverter = new DateConverter();

    const debut = dateConverter.serialize(dateDebut);
    const fin = dateConverter.serialize(dateFin);

    let params = new HttpParams();

    params = (dateDebut) ? params.set('debut', dateConverter.serialize(dateDebut)) : params;
    params = (dateFin) ? params.set('fin', dateConverter.serialize(dateFin)) : params;


    return forkJoin(
      this.http.get<JureCdc[]>(`/api/declaration/private/organismes/${idOrganisme}/jure-details/${idJure}`, {params}),
      this.cahiersService.getCahiers(),
      this.refService.getReferences()
    ).pipe(
      map(([jures, cahiers, ref]) => this.mapper.deserializeArray(jures, JureCdc, Object.assign({cahiers}, ref)))
    );

  }

  updateJureById(idJure: number, comJure: ComJure): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/jure/${idJure}`, comJure);
  }

  updateQualifById(idQualif: number, comQualif: ComQualif): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/qualification/${idQualif}`, this.mapper.serialize(comQualif));
  }

  addQualification(comQualif: ComQualif): Observable<void> {
    return this.http.post<void>(`/api/declaration/private/qualification`, this.mapper.serialize(comQualif));
  }

  deleteComQualif(idJure: number, comQualif: ComQualif) {
    return this.http.delete<void>(`/api/declaration/private/jure/${idJure}/qualification/${comQualif.id}`);
  }

  reactivateComQualif(idJure: number, idQualif: number, comQualif: ComQualif) {
    return this.http.put<void>(`/api/declaration/private/jure/${idJure}/qualification/${idQualif}/reactivate`,
      this.mapper.serialize(comQualif));
  }

  deleteAllComQualif(idJure: number) {
    return this.http.delete<void>(`/api/declaration/private/jurequalif/${idJure}`);
  }

  addJure(comJure: ComJure): Observable<any> {
    return this.http.post<any>(`/api/declaration/private/jure`, comJure);
  }

  getQualificationById(idQualif: number) {
    return forkJoin(this.http.get<object>(`/api/declaration/private/qualification/${idQualif}`),
      this.collegeService.getColleges(),
    ).pipe(
      map(([comQualif, colleges]) => {
        return this.mapper.deserializeObject(comQualif, ComQualif, Object.assign({colleges}));
      }),
      tap(comQualif => comQualif.idsOperateurs.forEach(operateurId => {
        this.operateursService.getOperateur(operateurId).subscribe(operateurRecu => comQualif.operateurs.push(operateurRecu));
      }))
    );
  }
}
