import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { JsonMapperService } from "src/app/core/services/mapper/mapper.service";
import { EngagementBase } from "../../models/engagements/engagement-base";
import { EngagementCtOc } from "../../models/engagements/engagement-ct-oc";
import { EngagementGraph } from "../../models/engagements/engagement-graph";
import { EngagementStats } from "../../models/engagements/engagement-stats";
import { RefStatutEngagement } from "../../models/engagements/ref-statut-engagement";
import { InfosGenerationAudit } from "../../../declaration/models/infos-generation-audit.model";
import { InfosPoSurface } from "../../../declaration/models/infos-po-surface.model";
import { Fichier } from "src/app/data/edition/models/fichier.model";

@Injectable({
  providedIn: "root",
})
export class EngagementVergersEcoService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
  ) {}

  public creerEngagementVergersEco(
    engagementBase: EngagementBase,
    codeType: String
  ): Observable<number> {
    return this.http
      .post(
        `/api/habilitation/private/engagementVergersEco?codeType=${codeType}`,
        engagementBase,
        { observe: "response" }
      )
      .pipe(
        map((response) =>
          parseInt(response.headers.get("location").split("/").pop(), 10)
        )
      );
  }

  public modifierEngagementVergersEco(
    engagementBase: EngagementBase
  ): Observable<void> {
    return this.http.put<void>(
      `/api/habilitation/private/engagementVergersEco/${engagementBase.id}`,
      engagementBase
    );
  }

  public modifierStatutEngagementVergersEco(
    engagementBase: EngagementBase
  ): Observable<void> {
    return this.http.put<void>(
      `/api/habilitation/private/engagementVergersEco/${engagementBase.id}/changeStatut`,
      engagementBase
    );
  }

  public getEngagementEcoById(
    idEngagement: number
  ): Observable<EngagementBase> {
    return this.http
      .get<EngagementBase>(
        `/api/habilitation/private/engagementsVergersEco/${idEngagement}`
      )
      .pipe(
        map((engagement) =>
          this.mapper.deserializeObject(engagement, EngagementBase)
        )
      );
  }

  public getEngagementEcoByEntrepriseAndCampagne(
    idEntreprise: number,
    codeCampagne: String
  ): Observable<EngagementBase> {
    return this.http
      .get<EngagementBase>(
        `/api/habilitation/private/entreprises/${idEntreprise}/campagnes/${codeCampagne}/engagementVergerEco`
      )
      .pipe(
        map((engagement) =>
          engagement != null
            ? this.mapper.deserializeObject(engagement, EngagementBase)
            : null
        )
      );
  }

  public getEngagementEcoByEntreprise(
    idEntreprise: number
  ): Observable<EngagementBase[]> {
    return this.http
      .get<Object[]>(
        `/api/habilitation/private/entreprises/${idEntreprise}/engagementVergerEco`
      )
      .pipe(
        map((engagements) =>
          this.mapper.deserializeArray(engagements, EngagementBase)
        )
      );
  }

  public getEngagementEcoByCampagne(
    codeCampagne: String
  ): Observable<EngagementBase[]> {
    return this.http
      .get<Object[]>(
        `/api/habilitation/private/campagnes/${codeCampagne}/engagementVergerEco`
      )
      .pipe(
        map((engagements) =>
          this.mapper.deserializeArray(engagements, EngagementBase)
        )
      );
  }

  public getRefStatutEngagementSuivant(
    idStatut: number
  ): Observable<RefStatutEngagement[]> {
    return this.http
      .get<Object[]>(
        `/api/habilitation/private/refStatutEngagementSuivant/${idStatut}`
      )
      .pipe(
        map((statuts) =>
          this.mapper.deserializeArray(statuts, RefStatutEngagement)
        )
      );
  }
  public generationDocumentAudit(
    codeCampagne: String,
    infosGenerationAudit: InfosGenerationAudit
  ): Observable<Blob> {
    return this.http.post(
      `/api/habilitation/private/entreprises/campagnes/${codeCampagne}/engagementVergerEco`,
      infosGenerationAudit,
      { responseType: "blob" }
    );
  }

  public generationPdf(
    idEntreprise: number,
    codeCampagne: String,
    infosPoSurface: InfosPoSurface
  ): any {
    return this.http.post<Fichier[]>(
      `/api/habilitation/private/entreprises/${idEntreprise}/campagnes/${codeCampagne}/engagementVergerEco/generationPdf`,
      infosPoSurface
    );
  }

  public getEngagementVerStats(
    codeCampagne: String
  ): Observable<EngagementStats> {
    return this.http
      .get<EngagementStats>(
        `/api/habilitation/private/campagnes/${codeCampagne}/engagementsVergersEcoStats`
      )
      .pipe(
        map((engagementStats) =>
          engagementStats != null
            ? this.mapper.deserializeObject(engagementStats, EngagementStats)
            : null
        )
      );
  }

  public getEngagementGraphValues(
    codeCampagne: String
  ): Observable<EngagementGraph[]> {
    return this.http
      .get<Object[]>(
        `/api/habilitation/private/campagnes/${codeCampagne}/engagementsVerGraphValues`
      )
      .pipe(
        map((values) => this.mapper.deserializeArray(values, EngagementGraph))
      );
  }

  public getListEngagementCtOc(
    idsEntreprise: number[],
    codeCampagne: String
  ): Observable<EngagementCtOc[]> {
    return this.http
      .get<Object[]>(
        `/api/declaration/private/campagnes/${codeCampagne}/engagementVerCtOc?ids=${idsEntreprise.join(
          ","
        )}`
      )
      .pipe(
        map((values) => this.mapper.deserializeArray(values, EngagementCtOc))
      );
  }

  public getSurfaceByPoAndEspece(
    idEntreprise: number,
    codeEspece: String,
    codeCampagne: String
  ): Observable<Map<number, number>> {
    return this.http.get<Map<number, number>>(
      `/api/declaration/private/campagnes/${codeCampagne}/entreprises/${idEntreprise}/especes/${codeEspece}/engagementVerSurfacePo`
    );
  }

  public getIdsEntreprisesLieesOc(
    idEntreprise: number,
    codeCampagne: String,
    audit: Boolean
  ): Observable<Number[]> {
    return this.http.get<number[]>(
      `/api/habilitation/private/entreprises/${idEntreprise}/campagnes/${codeCampagne}/idsEntreprisesLiees?audit=${audit}`
    );
  }

  public getIdsEntreprisesLieesCt(
    idIntervenant: number,
    codeCampagne: String,
    audit: Boolean
  ): Observable<Number[]> {
    return this.http.get<number[]>(
      `/api/habilitation/private/intervenants/${idIntervenant}/campagnes/${codeCampagne}/idsEntreprisesLiees?audit=${audit}`
    );
  }

  public getIdsProducteursOrganisesByGroupement(
    idGroupementProducteurs: number,
    codeCampagne: String
  ): Observable<Number[]> {
    return this.http.get<number[]>(
      `/api/habilitation/private/entreprises/${idGroupementProducteurs}/campagnes/${codeCampagne}/idsProducteursOrganises`
    );
  }

  public getEngagementEcoOcCtByCampagne(
    codeCampagne: String,
    infosGenerationAudit: InfosGenerationAudit
  ): Observable<EngagementBase[]> {
    return this.http
      .post<Object[]>(
        `/api/habilitation/private/entreprises/campagnes/${codeCampagne}/engagementVergerEcoOcCt`,
        infosGenerationAudit
      )
      .pipe(
        map((engagements) =>
          this.mapper.deserializeArray(engagements, EngagementBase)
        )
      );
  }
}
