import { Component, OnInit, Output, Input } from '@angular/core';
import { Manquement } from 'src/app/data/declaration/models/manquement.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ManquementsService } from 'src/app/data/declaration/services/manquements/manquements.service';
import * as moment from 'moment';
import { SessionContext } from 'src/app/core/services/config/app.settings';

@Component({
  selector: 'app-saisir-notification',
  templateUrl: './saisir-notification.component.html',
  styleUrls: ['./saisir-notification.component.scss']
})
export class SaisirNotificationComponent implements OnInit {
  private idOrganisme: number;
  @Input() @Output() public manquement: Manquement;
  @Input() public estOnglet = false;
  public formNotification: FormGroup;
  get dateNotification() { return this.formNotification.get('dateNotification'); }
  get dateReponseMax() { return this.formNotification.get('dateReponseMax'); }
  get commentaireOperateur() { return this.formNotification.get('commentaireOperateur'); }
  get commentaireOrganisme() { return this.formNotification.get('commentaireOrganisme'); }
  get reponseOperateur() { return this.formNotification.get('reponseOperateur'); }
  get dateReponseOperateur() { return this.formNotification.get('dateReponseOperateur'); }
  get auteurNotification() { return this.formNotification.get('auteurNotification'); }
  get recours() { return this.formNotification.get('recours'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly manquementsService: ManquementsService
  ) { }

  ngOnInit() {
    // Récupérer l'identifiant de l'organisme
    this.idOrganisme = SessionContext.get('idOrganisme');

    // Initialiser le formulaire de saisie d'une notification
    this.formNotification = this.fb.group({
      dateNotification: this.manquement.notification.dateNotification ? {
        year: this.manquement.notification.dateNotification.year(),
        month: this.manquement.notification.dateNotification.month() + 1,
        day: this.manquement.notification.dateNotification.date()
      } : null,
      dateReponseMax: { value: this.manquement.notification.dateNotification ? this.estJourFerieOuWeekEnd(this.manquement.notification.dateNotification).format('DD/MM/YYYY') : null, disabled: true},
      commentaireOperateur: this.manquement.notification.commentaireOperateur,
      commentaireOrganisme: this.manquement.notification.commentaireOrganisme,
      reponseOperateur: this.manquement.notification.reponseOperateur,
      dateReponseOperateur: this.manquement.notification.dateReponseOperateur ? {
        year: this.manquement.notification.dateReponseOperateur.year(),
        month: this.manquement.notification.dateReponseOperateur.month() + 1,
        day: this.manquement.notification.dateReponseOperateur.date()
      } : null,
      auteurNotification: this.manquement.notification.auteurNotification,
      recours: this.manquement.notification.recours
    });

    this.dateNotification.valueChanges.subscribe(date => {
      this.dateReponseMax.setValue(this.estJourFerieOuWeekEnd(this.getMomentDate(date)).format('DD/MM/YYYY'));
    });
  }

  private getMomentDate(date: {
    year: number,
    month: number,
    day: number
  }): moment.Moment {
    return (date) ? moment([date.year, date.month - 1, date.day, 0, 0, 0]) : null;
  }

  private estJourFerieOuWeekEnd(dateNotification: moment.Moment): moment.Moment {
    const dateClone = dateNotification.clone();
    // dateClone.add(10, 'days');

    let date: Date = new Date(dateClone.year(), dateClone.month(), dateClone.date());

    const an = date.getFullYear();

    const JourAn = new Date(an, 0, 1);
    const FeteTravail = new Date(an, 4, 1);
    const Victoire1945 = new Date(an, 4, 8);
    const FeteNationale = new Date(an, 6, 14);
    const Assomption = new Date(an, 7, 15);
    const Toussaint = new Date(an, 10, 1);
    const Armistice = new Date(an, 10, 11);
    const Noel = new Date(an, 11, 25);
    const JourAnApres = new Date(an + 1, 0, 1);

    const G = an % 19;
    const C = Math.floor(an / 100);
    const H = (C - Math.floor(C / 4) - Math.floor((8 * C + 13) / 25) + 19 * G + 15) % 30;
    const I = H - Math.floor(H / 28) * (1 - Math.floor(H / 28) * Math.floor(29 / (H + 1)) * Math.floor((21 - G) / 11));
    const J = (an * 1 + Math.floor(an / 4) + I + 2 - C + Math.floor(C / 4)) % 7;
    const L = I - J;
    const MoisPaques = 3 + Math.floor((L + 40) / 44);
    const JourPaques = L + 28 - 31 * Math.floor(MoisPaques / 4);
    const Paques = new Date(an, MoisPaques - 1, JourPaques);
    const LundiPaques = new Date(an, MoisPaques - 1, JourPaques + 1);
    const Ascension = new Date(an, MoisPaques - 1, JourPaques + 39);
    const Pentecote = new Date(an, MoisPaques - 1, JourPaques + 49);
    const LundiPentecote = new Date(an, MoisPaques - 1, JourPaques + 50);

    const joursFeries: Date[] = [];
    joursFeries.push(JourAn);
    joursFeries.push(FeteTravail);
    joursFeries.push(Victoire1945);
    joursFeries.push(FeteNationale);
    joursFeries.push(Assomption);
    joursFeries.push(Armistice);
    joursFeries.push(Toussaint);
    joursFeries.push(Noel);
    joursFeries.push(LundiPaques);
    joursFeries.push(Ascension);
    joursFeries.push(Pentecote);
    joursFeries.push(LundiPentecote);
    joursFeries.push(JourAnApres);

    let estJourFerie: Boolean = true;
    let estWeekend: Boolean = true;

    for (let i = 0 ; i < 10 ; i++ ) {
      dateClone.add(1, 'days');
      date = new Date(dateClone.year(), dateClone.month(), dateClone.date());

      const jourFerie = joursFeries.find(jour => jour.getDate() === date.getDate()
        && jour.getMonth() === date.getMonth()
        && jour.getFullYear() === date.getFullYear()
      );

      estJourFerie = jourFerie ? true : false;

      const weekEnd = moment(date);
      estWeekend = weekEnd.day() === 0 || weekEnd.day() === 6 ? true : false;


      if (estJourFerie || estWeekend) {
        i--;
      }
    }

    return moment(date) ;
  }

  /**
   * Méthode asynchrone appelée par le conteneur d'onglets qui enregistre la partie notification
   * du manquement et qui retourne une promesse
   * @see Promise
   * @returns Promise<boolean> à true si enregistrement réussi, false si non
   */
  public async onEdit(): Promise<boolean> {
    return new Promise(resolve => {
      this.manquement.notification.dateNotification = this.getMomentDate(this.dateNotification.value);
      this.manquement.notification.reponseOperateur = this.reponseOperateur.value ? !!this.reponseOperateur.value : null;
      this.manquement.notification.dateReponseOperateur = this.getMomentDate(this.dateReponseOperateur.value);
      this.manquement.notification.auteurNotification = this.auteurNotification.value;
      this.manquement.notification.recours = this.recours.value;
      this.manquement.notification.commentaireOperateur = this.commentaireOperateur.value;
      this.manquement.notification.commentaireOrganisme = this.commentaireOrganisme.value;

      this.manquementsService.updateManquementNotification(
        this.idOrganisme,
        this.manquement
      ).subscribe(() => {
        this.formNotification.markAsUntouched();
        resolve(true);
      });
    });
  }
}
