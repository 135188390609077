
    <form *ngIf="formGroup" [formGroup]="formGroup" #form="ngForm" (submit)="submit()" novalidate>
      <div class="input-group mb-4">
        <div class="input-group-prepend">
          <div class="input-group-text"><fa-icon icon="at"></fa-icon>
          </div>
        </div>
        <input type="text" [formControl]="login" t-id="loginForm-login" required
          [ngClass]="{'is-invalid': form.submitted && login.invalid}" class="form-control"
          placeholder="{{ 'page.auth.form.login' | translate }}" />
          <ng-container *ngIf="form.submitted">
            <div *ngIf="login.errors?.required" class="form-error" translate>label.obligatoire</div>
          </ng-container>
      </div>
      <div class="input-group mb-4">
        <div class="input-group-prepend">
          <div class="input-group-text"><fa-icon icon="lock"></fa-icon>
          </div>
        </div>
        <input type="password" [formControl]="password" t-id="loginForm-password" required
          [ngClass]="{'is-invalid': form.submitted && login.invalid}" class="form-control"
          placeholder="{{ 'page.auth.form.password' | translate }}" />

        <ng-container *ngIf="form.submitted">
          <div *ngIf="password.errors?.required" class="form-error" translate>label.obligatoire</div>
        </ng-container>
      </div>
      <div style="margin-bottom: 5px;">
        <a style="cursor:pointer" (click)="forgotPasswordModal()"
        translate>label.mdpOublie</a>
      </div>
      
      <div class="">
        <button type="submit" t-id="loginForm-next" class="btn btn-lg  border-radius btn-block btn-secondary text-white" 
        style="background:#985a99"><strong translate>label.connexion</strong></button>
      </div>
    </form>
