<form [formGroup]="formDecision" #frmDecision="ngForm" novalidate>
  <div class="cols">
    <div class="col">
      <div class="form-group">
        <label for="dateTransmission" translate>page.controles.manquement.decision.form.dateTransmission</label>
        <div class="input-group">
          <input ngbDatepicker #dateTransmission="ngbDatepicker" formControlName="dateTransmission" class="form-control" />
          <div class="input-group-append">
            <button type="button" (click)="dateTransmission.toggle()" class="btn btn-secondary">
              <fa-icon icon="calendar-alt"></fa-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="dateDecision" translate>page.controles.manquement.decision.form.dateDecision</label>
        <div class="input-group">
          <input ngbDatepicker #dateDecision="ngbDatepicker" formControlName="dateDecision" class="form-control" />
          <div class="input-group-append">
            <button type="button" (click)="dateDecision.toggle()" class="btn btn-secondary">
              <fa-icon icon="calendar-alt"></fa-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="auteur" translate>page.controles.manquement.decision.form.auteur</label>
        <input type="text" id="auteur" formControlName="auteur" class="form-control" />
      </div>
    </div>

    <div class="col">
      <div class="form-group">
        <input type="checkbox" id="applicationSanction" formControlName="applicationSanction" class="form-check-input" (change)="onSanctionCheckedChange()" />
        <label for="applicationSanction" class="form-check-label" translate>page.controles.manquement.decision.form.applicationSanction</label>
        <select id="sanction" formControlName="sanction" class="form-control">
          <option [ngValue]="null" selected hidden translate>page.controles.manquement.decision.form.applicationSanction</option>
          <option *ngFor="let sanction of manquement.constat.manquementConstate.sanctionsEncourues" [ngValue]="sanction.id">{{ sanction.libelle }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="commentaire" translate>page.controles.manquement.decision.form.commentaire</label>
        <textarea id="commentaire" formControlName="commentaire" class="form-control"></textarea>
      </div>
    </div>
  </div>
</form>
