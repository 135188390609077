import { JsonProperty, JsonObject, Optional, CustomConverter } from '@thorolf/json-ts-mapper';

import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { RefAnneeType } from './ref-annee-type.model';
import { RefObjetFacture } from './ref-objet-facture.model';
import { ModeleArticle } from './modele-article.model';
import { Cahier } from '../../habilitation/models/cahier.model';
import { RefQuantiteFacture } from './ref-quantite-facture.model';
import { Operateur } from '../../intervenant/models/operateur.model';

@JsonObject
export class ObjetFacture {
    @JsonProperty('id', Number)
    @Optional
    id: number;

    @JsonProperty('idObjet', Number)
    idObjet: number;

    @JsonProperty('fonctionnelId', String)
    fonctionnelId: string;

    @JsonProperty('idOperateur', Number)
    idOperateur: number;

    @JsonProperty('raisonSocialeOperateur', String)
    @Optional
    raisonSocialeOperateur: string;

    @JsonProperty('idCahier', Number)
    @Optional
    @CustomConverter(DataIdConverterFactory.build(Cahier, 'cahiers'))
    cahier: Cahier;

    @JsonProperty('annee', Number)
    annee: number;

    @JsonProperty('quantite', Number)
    quantite: number;

    @JsonProperty('idRefQuantiteFacture', Number)
    @Optional
    @CustomConverter(DataIdConverterFactory.build(RefObjetFacture, 'refQuantiteFactureList'))
    refQuantiteFacture: RefQuantiteFacture;

    @JsonProperty('estFacture', Boolean)
    @Optional
    estFacture: boolean;

    @Optional
    checked?: boolean;
}
