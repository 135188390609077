import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { RefProtectionGrele } from 'src/app/data/declaration/models/ref-protection-grele.model';
import { RefProtectionGreleService } from 'src/app/data/declaration/services/parcellaire/ref-protection-grele.service';

@Component({
  selector: 'app-edit-protection-grele',
  templateUrl: './edit-protection-grele.component.html',
  styleUrls: ['./edit-protection-grele.component.scss']
})
export class EditProtectionGreleComponent implements OnInit {

  formGroup: FormGroup;
  creation = false;
  title: string;
  protectionGrele: RefProtectionGrele;

  get code() { return this.formGroup.get('code'); }
  get libelle() { return this.formGroup.get('libelle'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly protectionGreleService: RefProtectionGreleService,) { }

  ngOnInit() {

    const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    if (!id) {
      this.creation = true;
    }
      this.loadData(id);
    

  }

  loadData(id: number) {

    this.title = this.creation ? 'page.valeur_parametrable.form.title.add_protection_grele': 'page.valeur_parametrable.form.title.edit_protection_grele';
    
    forkJoin(
    !this.creation ? this.protectionGreleService.getRefProtectionGrele() : of(new RefProtectionGrele())
    )
    .pipe(
      this.loaderService.operator()
    ).subscribe(([gels]) => {
     this.protectionGrele = !this.creation ? gels.find(g => g.id === id) : gels ;    
        this.initForm((this.protectionGrele.id) ? this.protectionGrele : null);
     
    });
  }

  private initForm(gel?: RefProtectionGrele): void {
 
    this.formGroup = this.fb.group({
      code: [{ value: ((gel) ? gel.code: undefined), disabled: false }, Validators.required],
      libelle: [{ value: ((gel) ? gel.libelle : undefined), disabled: false }, Validators.required]
    });
  }

  submit() {
    if (this.formGroup.valid) {
      this.protectionGrele.code = this.code.value;
      this.protectionGrele.libelle = this.libelle.value;
     
      if (this.protectionGrele.id == null) {
        this.protectionGreleService.creerRefProtectionGrele(this.protectionGrele).subscribe(idPprotectionGel => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
        });
      } else {        
        this.protectionGreleService.modifierRefProtectionGrele(this.protectionGrele).subscribe( () => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
         } );
      }
     
    }
  }

}
