import {Component, OnInit} from '@angular/core';
import {Routes, Router} from '@angular/router';
import {DeclarationsService} from 'src/app/data/declaration/services/declarations/declarations.service';
import {Campagne} from '../../../../../data/declaration/models/campagne.model';
import {Stock} from '../../../../../data/declaration/models/stock.model';
import {RefCampagnesService} from '../../../../../data/declaration/services/ref-campagnes/ref-campagnes.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder} from '@angular/forms';
import {UtilisateursService} from '../../../../../data/intervenant/services/utilisateurs/utilisateurs.service';
import {SessionContext} from '../../../../../core/services/config/app.settings';
import {AjoutGroupeVarietalComponent} from '../recoltes/modals/ajout-groupe-varietal/ajout-groupe-varietal.component';
import {GroupeVarietal} from '../../../../../data/declaration/models/groupe-varietal.model';
import {StocksService} from '../../../../../data/declaration/services/stocks/stocks.service';
import {ChoixColonnesComponent} from '../../../../../shared/components/choix-colonnes/choix-colonnes.component';
import {from} from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.scss']
})
export class StocksComponent implements OnInit {

  headers = [
    { name: 'groupeVarietal', label: this.translate.instant('page.declarations-stocks.table.header.groupeVarietal'), checked: true },
    { name: 'stockCampPrec', label: this.translate.instant('page.declarations-stocks.table.header.stockCampPrec'), checked: true },

    { name: 'stockMoisPrec', label: this.translate.instant('page.declarations-stocks.table.header.stockMoisPrec'), checked: true },
    { name: 'stockMoisCourant', label: this.translate.instant('page.declarations-stocks.table.header.stockMoisCourant'), checked: true },

    { name: 'recolte', label: this.translate.instant('page.declarations-stocks.table.header.recolte'), checked: false },
    { name: 'stockMoisSuiv', label: this.translate.instant('page.declarations-stocks.table.header.stockMoisSuiv'), checked: false },

    { name: 'varRecolte', label: this.translate.instant('page.declarations-stocks.table.header.varRecolte'), checked: false },
    { name: 'varMoisPrec', label: this.translate.instant('page.declarations-stocks.table.header.varMoisPrec'), checked: false }
  ];

  static routes: Routes = [
    {
      path: '', component: StocksComponent, data: {
        role: 'ORGANISME || DECLARATION_STOCK_LECTURE',
        domaines: ['ANPP'],
        menu: {
          libelle: 'menu.stocks',
          icon: 'key'
        }
      }
    },
    {path: ':idEntreprise', component: StocksComponent, data: {
        role: 'ORGANISME || PI || GP',
        domaines: ['ANPP'],
      }
    }
  ];
  campagnes: Campagne[];
  campagne: Campagne;
  formGroup: any;
  stocks: Stock[] = [];
  isCampagneCourante = true;
  mois: number[] = [11, 12, 1, 2, 3, 4, 5, 6, 7, 8];
  numMois = 0;
  numMoisCourant = 0;
  moisHorsLimites = false;

  entrepriseId: number;
  anneeCourante: number;

  public estAnpp = this.authService.isAnpp();

  get campagneSel() { return this.formGroup.get('selectionCampagne'); }
  get moisSel() { return this.formGroup.get('selectionMois'); }

  constructor(
    private readonly declarationService: DeclarationsService,
    private readonly refCampagneService: RefCampagnesService,
    private fb: FormBuilder,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly modalService: NgbModal,
    private readonly stockService: StocksService,
    private readonly utilisateursService: UtilisateursService,
    private readonly authService: AuthService,

  ) {  }

  onMoisChange() {
    this.numMois = this.formGroup.get('selectionMois').value as number;

    this.getStocks();
  }


  getStocks() {
   this.stockService.getStocks(this.entrepriseId, this.campagne.id, this.numMois).subscribe((declaration) => {
      this.stocks = declaration.stocks;
  
      this.stocks.sort((a, b) => {
        return a.sorting().localeCompare(b.sorting());
      });
      //for (let index = 0; index < this.stocks.length; index++) {
      //  if (index === this.stocks.length -1 && this.stocks[index+1]) {
      //    this.stocks[index].isDernierPommePoire = true;
      //  } else if (this.stocks[index+1] &&this.stocks[index].codeEspece !== this.stocks[index+1].codeEspece) {
      //    this.stocks[index].isDernierPommePoire = true;
      //  }
     // }
      this.ajoutTotaux();
      this.updateTotaux();
      this.stocks.sort((a, b) => {
        return a.sorting().localeCompare(b.sorting());
      });

      },
      error => {
        console.log('Pas de stocks');
        this.stocks = [];
        this.ajoutTotaux();
        this.updateTotaux();
      });
  }


  ngOnInit() {
    this.declarationService.activeTabId = 'stocks';
    setTimeout(() => {}, 10);

    this.formGroup = this.fb.group({
      selectionCampagne: [this.campagne, ],
      selectionMois: [this.numMois, ],
    });
    // récupération des campagnes
    this.refCampagneService.getCampagneAll().subscribe(
      referentiel => {
        this.campagnes = referentiel.sort((a, b) => {
          if (a && b && a.annee > b.annee) { return 1; }
          if (a && b && a.annee < b.annee) { return -1; }
          return 0;
        });
        // récupération de la campagne par défaut (bascule au 1er aout)
        const today = new Date();
        this.anneeCourante = (new Date(today.getFullYear(), 8, 1)) < today ? today.getFullYear() : today.getFullYear() - 1;
        this.numMois = (today.getDate() < 25) ? (today.getMonth() + 1)  : (today.getMonth() + 2);
        if (this.numMois === 9 || this.numMois === 10) {
          this.numMois = 8;
          this.moisHorsLimites = true;
        }
        this.numMoisCourant = this.numMois;
        if (!this.mois.indexOf(this.numMois)) {
          this.numMois = this.mois[0];
        }
        const leMois = this.mois.find(m => m === this.numMois);
        this.moisSel.setValue(leMois);

        this.refCampagneService.getCampagneAnnee(this.anneeCourante).subscribe(campagne => {
          this.campagne = campagne;

          if (this.declarationService.entrepriseSelectionnee) {
            this.entrepriseId = this.declarationService.entrepriseSelectionnee.id;
            if (this.entrepriseId) {
              this.getStocks();
            }
          } else {
            this.utilisateursService.getUtilisateur(SessionContext.get('idIntervenant')).subscribe(utilisateur => {
              if (!utilisateur || (utilisateur && !utilisateur.entreprise)) {
                this.entrepriseId = null;
                this.declarationService.entrepriseSelectionnee = null;
              } else {
                this.declarationService.entrepriseSelectionnee = utilisateur.entreprise;
                this.entrepriseId = utilisateur.entreprise.id;
                if (this.entrepriseId) {
                  this.getStocks();
                }
              }
            });
          }
          if (this.campagne) {
            const camp = this.campagnes.find(c => c.id === this.campagne.id);
            this.campagneSel.setValue(camp);
          }
        });
      });
  }

  /**
   * ouverture modale ajout d'un groupe variétal
   */
  ajouterGroupeVarietal() {
    const modalRef = this.modalService.open(AjoutGroupeVarietalComponent, { size: 'lg', backdrop: 'static' });

    modalRef.result.then((result) => {
        if (result) {
          if (this.stocks.filter(stock => stock.idGroupeVarietal == (result as GroupeVarietal).id).length > 0) {
            this.toastr.warning(
              this.translate.instant('page.declarations-stocks.modalAjouterGroupeVarietal.dejaPresent'),
              this.translate.instant('label.erreur'),
              { timeOut: 10 * 1000 }
            );
          } else {
            const newGv = new Stock();
            newGv.idGroupeVarietal = (result as GroupeVarietal).id;
            newGv.groupeVarietal = (result as GroupeVarietal).libelle;
            newGv.codeEspece = (result as GroupeVarietal).codeEspece;
            this.stocks.push(newGv);
            this.ajoutTotaux();
            this.updateTotaux();
            this.stocks.sort((a, b) => {
              return a.sorting().localeCompare(b.sorting());
            });
          }

        }
      }
    ).catch(e => {
      console.log(e);
    });
  }

  /**
   * sauvegarde
   */
  onSave() {
    if (this.getFormValidationErrors()) {
      this.toastr.warning(
        this.translate.instant('page.recoltesPp.alert.erreurSaisie'),
        this.translate.instant('label.erreur'),
        { timeOut: 10 * 1000 }
      );

    } else {
          const stockTosave = 
          this.stockService.saveStocks(this.entrepriseId, this.campagne.id, this.numMois, this.stocks.filter(stock => !stock.isTotal)).subscribe(
            response => {
              this.toastr.success(
                this.translate.instant('page.declarations-stocks.alert.creation.message'),
                this.translate.instant('page.declarations-stocks.alert.creation.title'),
                { timeOut: 10 * 1000 }
              );
              this.getStocks();
            }
          );
    }
  }
  getFormValidationErrors() {
    return false;
  }

  getLibelleMois(numMois: number): string {
    const date = new Date(2022, numMois - 1, 15);
    return date.toLocaleString(undefined,  { month: 'long' });
  }


  avancerMois() {
    if (this.mois.indexOf(this.numMois) < (this.mois.length - 1)) {
      this.numMois = this.mois[this.mois.indexOf(this.numMois) + 1];
      const leMois = this.mois.find(m => m === this.numMois);
      this.moisSel.setValue(leMois);
      this.getStocks();
    }
  }
  reculerMois() {
    if (!this.isFirstMonth()) {
      this.numMois = this.mois[this.mois.indexOf(this.numMois) - 1];
      const leMois = this.mois.find(m => m === this.numMois);
      this.moisSel.setValue(leMois);
      this.getStocks();
    }
  }

  isFirstMonth() {
    // est-ce le premier mois de la liste
    return (this.mois.indexOf(this.numMois) <= 0);
  }
  isLastMonth() {
    // est-ce le dernier mois de la liste
    return (this.mois.indexOf(this.numMois) >= this.mois.length - 1);
  }

  stockAZero() {
    this.stockService.stocksAZero(this.entrepriseId, this.campagne.id, this.numMois).subscribe(
      response => {
        this.toastr.success(
          this.translate.instant('page.declarations-stocks.alert.creation.message'),
          this.translate.instant('page.declarations-stocks.alert.creation.title'),
          { timeOut: 10 * 1000 }
        );
        this.getStocks();
      }
    );
  }
  /**
   * modale pour sélection des colonnes
   */
  afficherListe() {
    const modalRef = this.modalService.open(ChoixColonnesComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.headers = this.headers;

    from(modalRef.result).subscribe(headers => {
      this.headers = headers;
    });
  }
  /**
   *
   * @param obj affichage des colonnes optionnelles
   * @returns
   */
  show(obj) {
    return (this.headers.find(h => h['name'] === obj)).checked;
  }

  keyPressNumbers(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < '0'.charCodeAt(0) || charCode > '9'.charCodeAt(0))) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

   /**
   * ajoute une ligne total pour une espèce si elle n'existe pas encore
   */
   ajoutTotaux(): void {
    (new Set(this.stocks.map(stock => stock.codeEspece)))
      .forEach(codeEspece => {
        var total = this.stocks.filter(r => r.isTotal && r.codeEspece === codeEspece).find((o) => { return o != null; });
        if (total == undefined) {
          var newTotal = new Stock();
          newTotal.codeEspece = codeEspece;
          newTotal.groupeVarietal = "TOTAL";
          newTotal.isTotal = true;
          newTotal.idGroupeVarietal = codeEspece === "POMME" ? 9999 : 8888;
          this.stocks.push(newTotal);
        }
      })
  }

  updateTotaux(): void {
    // on fait un pseudo tableau des especes présentes
    (new Set(this.stocks.map(stock => stock.codeEspece)))
      .forEach(codeEspece => {
        // on parcourt les especes
        let  total:Stock = this.stocks.filter(r => r.isTotal && r.codeEspece === codeEspece).find((o) => { return o != null; });
        if (total) {
          total.stockTotalMoisCourant = this.stocks.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.stockTotalMoisCourant, 0);
          total.stockBioMoisCourant = this.stocks.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.stockBioMoisCourant, 0);
          
          total.stockTotalCampPrec = this.stocks.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.stockTotalCampPrec, 0);
          total.stockBioCampPrec = this.stocks.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.stockBioCampPrec, 0);
          
          total.stockTotalMoisPrec = this.stocks.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.stockTotalMoisPrec, 0);
          total.stockBioMoisPrec = this.stocks.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.stockBioMoisPrec, 0);
          
          total.stockTotalMoisSuiv = this.stocks.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.stockTotalMoisSuiv, 0);
          total.stockBioMoisSuiv = this.stocks.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.stockBioMoisSuiv, 0);
          
          total.stockTotalRecolte = this.stocks.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.stockTotalRecolte, 0);
          total.stockBioRecolte = this.stocks.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.stockBioMoisCourant, 0);
        }
      })
  }
}


