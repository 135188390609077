import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class EngagementStats {
  @JsonProperty('pourcentageEngagementVer', Number)
  @Optional
  public pourcentageEngagementVer: number = undefined;

  @JsonProperty('nombreControleEnCours', Number)
  @Optional
  public nombreControleEnCours: number = undefined;
  
  @JsonProperty('nombreAdherentsAgrees', Number)
  @Optional
  public nombreAdherentsAgrees: number = undefined;

}
