import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditFormeComponent } from './edit-forme/edit-forme.component';
import { ListeFormesComponent } from './liste-formes/liste-formes.component';

@Component({
  selector: 'app-formes',
  templateUrl: './formes.component.html',
  styleUrls: ['./formes.component.scss']
})
export class FormesComponent implements OnInit {

  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"]
    };
  
    static routes: Routes = [
      { path: '', component: ListeFormesComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id', component: EditFormeComponent, data: { roles: ['ORGANISME'] } },
      { path: 'new', component: EditFormeComponent, data: { roles: ['ORGANISME'] } }
    ];

  constructor() { }

  ngOnInit() {
  }

}
