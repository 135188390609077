import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class NombreAdherents {
  
  @JsonProperty('nombreProducteursOrganises', Number)
  public nombreProducteursOrganises: number = undefined;

  @JsonProperty('nombreProducteursIndependants', Number)
  public nombreProducteursIndependants: number = undefined;
  
  @JsonProperty('nombreGroupementsProducteurs', Number)
  public nombreGroupementsProducteurs: number = undefined;
 
  // @JsonProperty('surfaceTotalePommes', Number)
  // public surfaceTotalePommes: number = undefined;
  
  // @JsonProperty('surfaceTotalePoires', Number)
  // public surfaceTotalePoires: number = undefined;
}
