import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Cache, CacheKey, ClearCache, NoCache } from 'src/app/core/services/cache/cache.service';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Abonnement } from '../../models/abonnement.model';
import { ReferencesService } from '../references/references.service';

@Injectable({
  providedIn: 'root'
})
export class AbonnementsService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly referencesService: ReferencesService
  ) { }

  @ClearCache
  clearCache() { }

  @Cache()
  getAbonnements(@CacheKey idOrganisme: number, @NoCache noCache?: boolean): Observable<Abonnement[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/intervenant/private/organismes/${idOrganisme}/abonnements`),
      this.referencesService.getReferences()
    )
      .pipe(
        map(([abonnements, ref]) => this.mapper.deserializeArray(abonnements, Abonnement, ref))
      );
  }

  getAllAbonnements(): Observable<Abonnement[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/intervenant/private/abonnements`),
      this.referencesService.getReferences()
    )
      .pipe(
        map(([abonnements, ref]) => this.mapper.deserializeArray(abonnements, Abonnement, ref))
      );
  }

  createAbonnement(idOrganisme: number, abonnement: Abonnement): Observable<number> {
    return this.http.post(`/api/intervenant/private/organismes/${idOrganisme}/abonnements`,
      this.mapper.serialize(abonnement), { observe: 'response' })
      .pipe(
        tap(() => this.clearCache()),
        map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
      );
  }

  deleteAbonnement(idOrganisme: number, abonnement: Abonnement | number): Observable<void> {
    const idAbonnement = abonnement instanceof Abonnement ? abonnement.id : abonnement;

    return this.http.delete(`/api/intervenant/private/organismes/${idOrganisme}/abonnements/${idAbonnement}`)
      .pipe(
        tap(() => this.clearCache()),
        map(() => { })
      );
  }
}
