<div class="content">
  <div class="content-panel">
    <h2 translate>page.admin.jure.title</h2>
    <form [formGroup]="formJureSearch" #form="ngForm">
      <div class="form-row">
        <div class="form-group col-md-6 row">
          <label for="nomJure" class="col-md-3 col-form-label" translate>page.admin.jure.liste.search.nom</label>
          <div class="col-md-6">
            <input type="text" id="nomJure" formControlName="nomJure" class="form-control"/>
          </div>
        </div>

        <div class="form-group col-md-6 row">
          <label for="college" class="col-md-3 col-form-label" translate>page.admin.jure.liste.search.college</label>
          <div class="col-md-6">
            <select id="college" formControlName="college" class="form-control">
              <option [value]="null" hidden></option>
              <option *ngFor="let college of refColleges" [ngValue]="college">{{college.libelle}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6 row">
          <label for="cdcs" class="col-md-3 col-form-label" translate>page.admin.jure.liste.search.cdcs</label>
          <app-multiselect id="cdcs" class="col-md-6"
                           controlName="cdcs"
                           [items]="refCahiers"
                           bindLabel="libelle"
                           maxWidth="initial"
                           [ngClass]="{ 'is-invalid': form.submitted && cdcs.invalid }">
          </app-multiselect>
        </div>
        <div class="form-group col-md-6 row">
          <label class="col-md-3 col-form-label" for="showInactifs">Afficher inactifs</label>
          <div class="col-md-6">
            <input type="checkbox" id="showInactifs" formControlName="showInactifs" class="form-check-input">
          </div>
        </div>
      </div>

    </form>

    <div class="actions-bar" *hasRole="'INTERVENANT_UTILISATEUR_ECRITURE'">
      <button type="button" class="btn btn-primary" [routerLink]="['../jure/addJure']">Ajouter un juré</button>
    </div>
    <div class="tableScroll">
      <table *ngIf="juresCdc" table class="table table-striped">
        <thead class="sticky-top">
        <tr>
          <th>
            <ng-container
              *ngTemplateOutlet="tableHeader;context:{sortProperty:'nom', libelle:'page.admin.jure.liste.table.header.nom'}"></ng-container>
          </th>
          <th>
            <ng-container
              *ngTemplateOutlet="tableHeader;context:{sortProperty:'telephone', libelle:'page.admin.jure.liste.table.header.telephone'}"></ng-container>
          </th>
          <th>
            <ng-container
              *ngTemplateOutlet="tableHeader;context:{sortProperty:'email', libelle:'page.admin.jure.liste.table.header.email'}"></ng-container>
          </th>
          <th>
            <ng-container
              *ngTemplateOutlet="tableHeader;context:{sortProperty:'qualifications', libelle:'page.admin.jure.liste.table.header.qualifications'}"></ng-container>
          </th>
          <th>
            <ng-container
              *ngTemplateOutlet="tableHeader;context:{sortProperty:'operateursLies', libelle:'page.admin.jure.liste.table.header.operateursLies'}"></ng-container>
          </th>
          <th translate>page.admin.jure.liste.table.header.actions</th>
        </tr>
        </thead>
        <tbody>
        <tr
          *ngFor="let jure of juresCdcFiltered | filter: ['nom']:searchString | orderBy:order.property:order.reverse:true"
          [ngClass]="{'inactifs':jure.checked}">
          <!--<td>
            <app-utilisateur-statut [jure]="jure"></app-utilisateur-statut>
          </td> -->
          <td>{{jure.nom}}</td>
          <td>{{ jure.telFixe ? jure.telFixe : (jure.telPortable ? jure.telPortable : '') }}</td>
          <td>{{jure.mail}}</td>
          <td>{{getLibellesColleges(jure)}}</td>
          <td>{{getRaisonSocialeOperateurs(jure)}}</td>
          <td class="actions">
            <span>
              <fa-icon class="clickable-icon" icon="edit" [routerLink]="['../jure/updateJure', jure?.id]"></fa-icon>
            </span>
            <span><fa-icon class="clickable-icon" icon="trash" (click)="deleteJure(jure?.id)"></fa-icon></span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #tableHeader let-property="sortProperty" let-libelle="libelle">
  <th *ngIf="property" (click)="setOrder(property)" [class.sortable]="property">
    {{libelle | translate}}
    <fa-icon *ngIf="property" [icon]="getIcon(property)"></fa-icon>
  </th>
  <th *ngIf="!property">{{libelle | translate}}</th>
</ng-template>
