<div class="anpp content">
  <div class="content-panel border-radius">
    <div class="border-radius text-center" style="background:#fff">
      <div class="row">
        <div class="col-lg text-left pl-4 pt-4">
          <a class="pl-4 link-secondary" [routerLink]="['./new']">
            <span style="text-decoration:underline"><fa-icon icon="plus-square"></fa-icon> Ajouter un contact</span></a>
            <a>
          <span id="bar1" class="pl-4" (click)="afficheFiltre()" style="text-decoration:underline">
            <fa-icon icon="filter"></fa-icon> Filtrer
          </span></a>
        </div>

        <div class="col-lg text-right pt-2 pr-4">
          <fa-icon icon="upload" class="clickable-icon" style="font-size:25px" (click)="exporterContacts()"></fa-icon>

        </div>
      </div>
      <div class="table-responsive tableScroll">
      <table class="table table-striped" *ngIf="contacts?.length > 0; else aucunContact">
        <thead class="sticky-top">
          <tr>
            <th scope="col">Opérations</th>
            <th *ngIf="show('civilite');" scope="col" style="position:relative">
              <div translate>page.contacts.table.civilite </div>
              <div *ngIf="!filtrer;">
                <input type="text" [id]="civilite" class="form-control bar1" [(ngModel)]="civiliteFilter"
                  (change)="filter()">
              </div>
            </th>
            <th *ngIf="show('nom');" scope="col" style="position:relative">
              <div translate>page.contacts.table.nom </div>
              <div *ngIf="!filtrer;">
                <input type="text" [id]="nom" class="form-control bar1" [(ngModel)]="nomFilter" (change)="filter()">
              </div>
            </th>
            <th *ngIf="show('prenom');" scope="col" style="position:relative">
              <div translate>page.contacts.table.prenom</div>
              <div *ngIf="!filtrer;">
                <input type="text" [id]="prenom" class="form-control bar1" [(ngModel)]="prenomFilter"
                  (change)="filter()">
              </div>
            </th>
            <th *ngIf="show('entreprise');" scope="col" style="position:relative">
              <div translate>page.contacts.table.entreprise</div>
              <div *ngIf="!filtrer;">               
                <input type="text" [id]="entreprise" class="form-control bar1" [(ngModel)]="entrepriseFilter"
                  (change)="filter()">
              </div>
            </th>
            <th *ngIf="show('telephone');" scope="col" style="position:relative">

              <div translate>page.contacts.table.telephone</div>
              <div *ngIf="!filtrer;">
                <input type="text" [id]="telephone" class="form-control bar1" [(ngModel)]="telephoneFilter"
                  (change)="filter()">
              </div>
            </th>
            <th *ngIf="show('mail');" scope="col" style="position:relative">

              <div translate>page.contacts.table.mail</div>
              <div *ngIf="!filtrer;">
                <input type="text" [id]="mail" class="form-control bar1" [(ngModel)]="mailFilter" (change)="filter()">
              </div>
            </th>
            <th *ngIf="show('adresse');" scope="col" style="position:relative">
              <div translate>page.contacts.table.adresse</div>
              <div *ngIf="!filtrer;">
                <input type="text" [id]="adresse" class="form-control bar1" [(ngModel)]="adresseFilter"
                  (change)="filter()">
              </div>
            </th>
            <th *ngIf="show('codepostal');" scope="col" style="position:relative">
              <div translate>page.contacts.table.codepostal</div>
              <div *ngIf="!filtrer;">
                <input type="text" [id]="codepostal" class="form-control bar1" [(ngModel)]="codepostalFilter"
                  (change)="filter()">
              </div>
            </th>
            <th *ngIf="show('commune');" scope="col" style="position:relative">
              <div translate>page.contacts.table.commune</div>
              <div *ngIf="!filtrer;">
                <input type="text" [id]="commune" class="form-control bar1" [(ngModel)]="communeFilter"
                  (change)="filter()">
              </div>
            </th>
            <th *ngIf="show('fonction');" scope="col" style="position:relative">
              <div translate>page.contacts.table.fonction</div>
              <div *ngIf="!filtrer;">
                <select id="fonction" class="custom-select bar1" [(ngModel)]="fonctionFilter" (change)="filter()">
                  <option></option>
                  <option *ngFor="let fonction of fonctions" [ngValue]="fonction">{{fonction.libelle}}</option>
                </select>
              </div>
            </th>
            <th *ngIf="show('estintervenant');" scope="col" style="position:relative">
              <div translate>page.contacts.table.estintervenant</div>
              <div *ngIf="!filtrer;">
                <select id="estintervenant" class="custom-select bar1" [(ngModel)]="estintervenantFilter"
                  (change)="filter()">
                  <option [ngValue]="undefined"></option>
                  <option [ngValue]="true">Oui</option>
                  <option [ngValue]="false">Non</option>
                </select>
              </div>
            </th>
            <th *ngIf="show('portable');" scope="col" style="position:relative">

              <div translate>page.contacts.table.portable</div>
              <div *ngIf="!filtrer;">
                <input type="text" [id]="portable" class="form-control bar1" [(ngModel)]="portableFilter"
                  (change)="filter()">
              </div>
            </th>
            <th *ngIf="show('referents');" scope="col" style="position:relative">
              <div translate>page.contacts.table.referent</div>
              <div *ngIf="!filtrer;">
                <select id="referents" class="custom-select bar1" [(ngModel)]="referentFilter" (change)="filter()">
                  <option></option>
                  <option *ngFor="let referent of referents" [ngValue]="referent">{{referent.libelle}}</option>
                </select>
              </div>
            </th>
            <th *ngIf="show('typeRefs');" scope="col" style="position:relative">
              <div translate>page.contacts.table.typeRef</div>
              <div *ngIf="!filtrer;">
                <select id="typeRefs" class="custom-select bar1" [(ngModel)]="typerefFilter" (change)="filter()">
                  <option></option>
                  <option *ngFor="let typeRef of typeRefs" [ngValue]="typeRef">{{typeRef.libelle}}</option>
                </select>
              </div>
            </th>
            <th *ngIf="show('estadherent');" scope="col" style="position:relative">
              <div translate>page.contacts.table.estadherent</div>
              <div *ngIf="!filtrer;">
                <select id="estadherent" class="custom-select bar1" [(ngModel)]="estadherentFilter" (change)="filter()">
                  <option [ngValue]="undefined"></option>
                  <option [ngValue]="true">Oui</option>
                  <option [ngValue]="false">Non</option>
                </select>
              </div>
            </th>
            <th *ngIf="show('groupes');" scope="col" style="position:relative">
              <div translate>page.contacts.table.groupe</div>
              <div *ngIf="!filtrer;">
                <select id="groupes" class="custom-select bar1" [(ngModel)]="groupeFilter" (change)="filter()">
                  <option></option>
                  <option *ngFor="let groupe of groupes" [ngValue]="groupe">{{groupe.libelle}}</option>
                </select>
              </div>
            </th>


            <th>
              <fa-icon class="clickable-icon" icon="ellipsis-h" (click)="afficherListe()"></fa-icon>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let contact of contactFilter | orderBy : 'nom' ">
            <td>
              <div class="btn-group dropright  ">
                <div ngbDropdown placement="right-start">
                  <button class="btn btn-secondary dropdown-toggle btnprimary btn-sm " id="dropdownConfig"
                    ngbDropdownToggle>
                    <fa-icon icon="cog"></fa-icon>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                    <button (click)="editContact(contact)" ngbDropdownItem class="dropdown-item">
                      <fa-icon icon="id-card"></fa-icon>
                      Fiche du contact
                    </button>
                    <button (click)="interactionContact(contact)" ngbDropdownItem class="dropdown-item">
                      <fa-icon icon="id-card"></fa-icon>
                      Interactions avec le contact
                    </button>
                    <button (click)="openConfirmationDialog(contact)" ngbDropdownItem class="dropdown-item">
                      <fa-icon icon="trash"></fa-icon>
                      Supprimer le contact
                    </button>
                  </div>
                </div>
              </div>
            </td>
            <td *ngIf="show('civilite');">{{ contact.civilite }}</td>
            <td *ngIf="show('nom');">{{ contact.nom }}</td>
            <td *ngIf="show('prenom');">{{ contact.prenom }}</td>
            <td *ngIf="show('entreprise');">{{ contact.entreprise?.raisonSociale }}</td>
            <td *ngIf="show('telephone');">{{ contact.telephonefixe }}</td>
            <td *ngIf="show('mail');">{{ contact.mail }}</td>
            <td *ngIf="show('adresse');">{{ contact.adresse }}</td>
            <td *ngIf="show('codepostal');">{{ contact.codepostal }}</td>
            <td *ngIf="show('commune');">{{ contact.commune }}</td>
            <td *ngIf="show('fonction');">{{ contact.fonction?.libelle }}</td>
            <td *ngIf="show('estintervenant');">
              <input type="checkbox" [checked]="contact.estintervenant" [disabled]="true" />
            </td>
            <td *ngIf="show('portable');">{{ contact.telephoneportable }}</td>
            <td *ngIf="show('referents');">
              <span class="badge badge-secondary badge-green" *ngFor="let ref of contact.referents">{{ref.code}} </span>
            </td>
            <td *ngIf="show('typeRefs');">{{ contact.entreprise.refSousType.libelle }}</td>
            <td *ngIf="show('estadherent');"><input type="checkbox" [checked]="contact.entreprise.estAdherent"
                [disabled]="true" /></td>
            <td *ngIf="show('groupes');">
              <span *ngFor="let ref of contact.groupes">{{ref.code}} </span>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
  </div>
</div>

<ng-template #aucunContact>
  <div translate>page.contacts.table.aucun_contact</div>
</ng-template>