import { Component, Input, OnInit } from '@angular/core';

import { RefStatut } from 'src/app/data/habilitation/models/statut.ref.model';

@Component({
  selector: 'app-statut-badge',
  templateUrl: './statut-badge.component.html',
  styleUrls: ['./statut-badge.component.scss']
})
export class StatutBadgeComponent implements OnInit {
  @Input() statut: RefStatut;

  constructor() { }

  ngOnInit() {
  }

}
