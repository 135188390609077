<div class="anpp">
  <div class="modal-header">
    <h4 class="modal-title" translate>
      page.recoltesPp.modalAjouterGroupeVarietal.titre
    </h4>
  </div>


  <div class="modal-body">
    
      <div class="form-group">

        <label for="typeahead-http" translate>page.recoltesPp.modalAjouterGroupeVarietal.label</label>
  
        <!-- <input 
        #someInput
        id="typeahead-http" 
        [(ngModel)]="userSearch" 
        type="search" 
        [ngbTypeahead]="search" 
        (selectItem)="selectOperateur($event)"
        (focus)="focus$.next($event.target.value)"
        placeholder="{{'page.recoltesPp.modalAjouterGroupeVarietal.placeholder' | translate}}" 
        [editable]="false"
        class="form-control"
        /> 
  
        <div class="mt-1">
          <span *ngIf="searching" translate>label.chargement</span>
          <span *ngIf="noResult" translate>label.aucunResultat</span>
        </div>-->

        <select id="groupe"  class="form-control" (change)="selectOperateur()" [(ngModel)]="groupeFilter"
       >
        <option *ngFor="let groupe of groupes" [ngValue]="groupe">{{groupe.libelle}}
        </option>
      </select>

      </div>
      <div class="actions-bar">
        <button type="button" (click)="onClose()" class="btn btn-light mr-2" translate>label.annuler</button>
      </div>
    
  </div>

</div>