import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { Recolte } from './recolte.model';
import * as moment from 'moment';

@JsonObject
export class SaisieRecolte {
    
    @JsonProperty('idRefCampagne', Number)
    @Optional
    public idRefCampagne: number = undefined;

    @JsonProperty('idRefCampagnePrecedente', Number)
    @Optional
    public idRefCampagnePrecedente: number = undefined;

    @JsonProperty('idEntreprise', Number)
    @Optional
    public idEntreprise: number = undefined;

    @JsonProperty('estDefinitive', Boolean)
    public estDefinitive: boolean = false;

    @JsonProperty('recoltes', [Recolte])
    @Optional
    public recoltes: Recolte[] = [];

    @JsonProperty('commentaire', String)
    @Optional
    public commentaire: string;

    /**
     * Récolte unique pour la page de suivi et affectée dans la façade
     */
    public recolteSuivi: Recolte = new Recolte();
    /**
     * Délai calculé et affecté par la façade
     */
    public delai: number;
    public estCochee: boolean = false;

    updateTotaux(){
       (new Set(this.recoltes.map(recolte=>recolte.codeEspece)))
       .forEach(codeEspece=>{
           var total = this.recoltes.filter(r=>r.isTotal && r.codeEspece === codeEspece).find((o) => {return o != null;});
           (total as Recolte).qteTotale = this.recoltes.filter(r=>!r.isTotal && r.codeEspece===codeEspece).reduce((qteTotale, current) => qteTotale + current.qteTotale, 0);
           (total as Recolte).qteBio = this.recoltes.filter(r=>!r.isTotal && r.codeEspece===codeEspece).reduce((qteTotale, current) => qteTotale + current.qteBio, 0);           
       })
    }

    public getSurfaceTotale(): number {
      return this.recoltes
        .map((recolte: Recolte) => recolte.surfaceTotale)
        .reduce((sufraceTotale: number, surfaceCourante: number) => 
          sufraceTotale + surfaceCourante, 0
        );
    }

    public getPrevisionInitialeTotale(): number {
      return this.recoltes
        .map((recolte: Recolte) => recolte.previsionInitialeTotale)
        .reduce((previsionInitialeTotale: number, previsionInitialeCourante: number) => 
          previsionInitialeTotale + previsionInitialeCourante, 0
        );
    }

    public getRandementInitialTotal(): number {
      return this.recoltes
        .map((recolte: Recolte) => recolte.getRdtInitialTotal())
        .reduce((randementInitialTotal: number, randementInitialCourant: number) => 
          randementInitialTotal + randementInitialCourant, 0
        );
    }

    public getRecolteEffectiveTotale(): number {
      return this.recoltes
        .map((recolte: Recolte) => recolte.qteTotale)
        .reduce((recolteTotale: number, recolteCourante: number) => 
          recolteTotale + recolteCourante, 0
        );
    }

    public getRandementEffectifTotal(): number {
      return this.recoltes
        .map((recolte: Recolte) => recolte.getRdtTotal())
        .reduce((randementTotal: number, randementCourant: number) => 
          randementTotal + randementCourant, 0
        );
    }

}