import { JsonObject, JsonProperty } from "@thorolf/json-ts-mapper";

@JsonObject
export class ManquementCommissionEdition{
    @JsonProperty('date', String)
    date: string;
    @JsonProperty('adresse', String)
    adresse: string;
    @JsonProperty('numero', String)
    numero: string;
}