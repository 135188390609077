import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MainRoutingModule } from './main-routing.module';
import { AdministrationComponent } from './pages/administration/administration.component';
import { OrganismesComponent } from './pages/administration/pages/organismes/organismes.component';
import { EditAbonnementsComponent } from './pages/administration/pages/organismes/pages/edit-abonnements/edit-abonnements.component';
import { EditOrganismeComponent } from './pages/administration/pages/organismes/pages/edit-organisme/edit-organisme.component';
import { ListeOrganismesComponent } from './pages/administration/pages/organismes/pages/liste-organismes/liste-organismes.component';
import { EditUtilisateurComponent } from './pages/administration/pages/utilisateurs/pages/edit-utilisateur/edit-utilisateur.component';
import {
  ListeUtilisateursComponent
} from './pages/administration/pages/utilisateurs/pages/liste-utilisateurs/liste-utilisateurs.component';
import {
  UtilisateurDroitsComponent
} from './pages/administration/pages/utilisateurs/pages/utilisateur-droits/utilisateur-droits.component';
import { UtilisateursComponent } from './pages/administration/pages/utilisateurs/utilisateurs.component';
import {
  HistoriqueItemComponent
} from './pages/operateurs/pages/operateur-habilitations/components/historique-item/historique-item.component';
import { StatutBadgeComponent } from './pages/habilitations/components/statut-badge/statut-badge.component';
import { HabilitationsComponent } from './pages/habilitations/habilitations.component';
import {
  UpdateHabilitationComponent
} from './pages/operateurs/pages/operateur-habilitations/modals/update-habilitation/update-habilitation.component';
import { HomeComponent } from './pages/home/home.component';
import { SidebarComponent } from './pages/main/components/sidebar/sidebar.component';
import { MainComponent } from './pages/main/main.component';
import { RouterModule } from '@angular/router';
import { AuthentificationModule } from '../authentification/authentification.module';
import { OperateursComponent } from './pages/operateurs/operateurs.component';
import {
  RechercheOperateurAffilieComponent
} from './pages/operateurs/pages/recherche-operateur-affilie/recherche-operateur-affilie.component';
import { OperateurDashboardComponent } from './pages/operateurs/pages/operateur-dashboard/operateur-dashboard.component';
import {
  UtilisateurStatutComponent
} from './pages/administration/pages/utilisateurs/components/utilisateur-statut/utilisateur-statut.component';
import { AjoutOperateurComponent } from './pages/operateurs/pages/ajout-operateur/ajout-operateur.component';
import { OperateurHabilitationsComponent } from './pages/operateurs/pages/operateur-habilitations/operateur-habilitations.component';
import { OperateurOrganismesComponent } from './pages/operateur-organismes/operateur-organismes.component';
import { DeclarationsComponent } from './pages/declarations/declarations.component';
import { AffichageDrComponent } from './pages/declarations/pages/affichage-dr/affichage-dr.component';
import { AffichageDrevComponent } from './pages/declarations/pages/affichage-drev/affichage-drev.component';
import { TankComponent } from './pages/declarations/components/tank/tank.component';
import { InformationsOperateurComponent } from './pages/operateurs/pages/informations-operateur/informations-operateur.component';
import { SyntheseVolumesComponent } from './pages/declarations/pages/synthese-volumes/synthese-volumes.component';
import { ChangementsDenominationComponent } from './pages/declarations/pages/synthese-volumes/modals/changements-denomination/changements-denomination.component';
import { CommissionsComponent } from './pages/commissions/commissions.component';
import { EditCommissionComponent } from './pages/commissions/pages/edit-commission/edit-commission.component';
import { PlanningCommissionsComponent } from './pages/commissions/pages/planning-commissions/planning-commissions.component';
import { SaisirConditionnementComponent } from './pages/declarations/pages/synthese-volumes/modals/saisir-conditionnement/saisir-conditionnement.component';
import { RechercheJureComponent } from './pages/commissions/pages/edit-commission/modals/recherche-jure/recherche-jure.component';
import { RechercheCdcsComponent } from './pages/commissions/pages/edit-commission/modals/recherche-cdcs/recherche-cdcs.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { RechercheEchantillonsComponent } from './pages/commissions/pages/edit-commission/modals/recherche-echantillons/recherche-echantillons.component';
import { DroitsComponent } from './pages/administration/pages/droits/droits.component';
import { RechercheProduitComponent } from './pages/declarations/pages/synthese-volumes/modals/recherche-produit/recherche-produit.component';
import { SaisirRevendicationComponent } from './pages/declarations/pages/synthese-volumes/modals/saisir-revendication/saisir-revendication.component';
import { ChoixStockComponent } from './pages/declarations/pages/synthese-volumes/modals/choix-stock/choix-stock.component';
import { SaisirTransactionComponent } from './pages/declarations/pages/synthese-volumes/modals/saisir-transaction/saisir-transaction.component';
import { RechercherAcheteurComponent } from './pages/declarations/pages/synthese-volumes/modals/rechercher-acheteur/rechercher-acheteur.component';
import { SaisirConditionnementIgpComponent } from './pages/declarations/pages/synthese-volumes/modals/saisir-conditionnement-igp/saisir-conditionnement-igp.component';
import { DrevManquantesComponent } from './pages/drev-manquantes/drev-manquantes/drev-manquantes.component';
import { OperateurComponent } from './pages/drev-manquantes/modal/operateur/operateur.component';
import { SaisieVciComponent } from './pages/declarations/pages/affichage-drev/modals/saisie-vci/saisie-vci.component';
import { HistoriqueComponent } from './pages/declarations/pages/historique/historique.component';
import { SuiviDeclarationsComponent } from './pages/suivi-declarations/suivi-declarations.component';
import { SyntheseSaisieRevendicationComponent } from './pages/declarations/pages/synthese-volumes/modals/saisir-revendication/synthese-saisie-revendication/synthese-saisie-revendication.component';
import { AffectationEchantillonsComponent } from './pages/commissions/pages/edit-commission/modals/affectation-echantillons/affectation-echantillons.component';
import { ProfilsComponent } from './pages/administration/pages/profils/profils.component';
import { ControlesComponent } from './pages/controles/controles.component';
import { EditionControleComponent } from './pages/controles/pages/list-controles/modals/edition-controle/edition-controle.component';
import { ListControlesComponent } from './pages/controles/pages/list-controles/list-controles.component';
import { PressionControleComponent } from './pages/controles/pages/pression-controle/pression-controle.component';
import { ListEchantillonsComponent } from './pages/controles/pages/list-echantillons/list-echantillons.component';
import { SaisirPrelevementComponent } from './pages/controles/pages/list-echantillons/modals/saisir-prelevement/saisir-prelevement.component';
import { SuiviControlesComponent } from './pages/controles/pages/suivi-controles/suivi-controles.component';
import { SaisirConstatComponent } from './pages/controles/pages/suivi-controles/modals/saisir-constat/saisir-constat.component';
import { SyntheseConstatsComponent } from './pages/controles/pages/suivi-controles/modals/synthese-constats/synthese-constats.component';
import { SearchManquementsComponent } from './pages/controles/pages/search-manquements/search-manquements.component';
import { EditManquementComponent } from './pages/controles/pages/search-manquements/modals/edit-manquement/edit-manquement.component';
import { SaisirNotificationComponent } from './pages/controles/pages/search-manquements/modals/edit-manquement/saisir-notification/saisir-notification.component';
import { SaisirDecisionComponent } from './pages/controles/pages/search-manquements/modals/edit-manquement/saisir-decision/saisir-decision.component';
import { EditMdpComponent } from './pages/edit-mdp/edit-mdp.component';
import { SaisirRecoursComponent } from './pages/controles/pages/search-manquements/modals/edit-manquement/saisir-recours/saisir-recours.component';
import { ConsultationDrevComponent } from './pages/declarations/pages/consultation-drev/consultation-drev.component';
import { ListFacturationComponent } from './pages/facturation/pages/list-facturation/list-facturation.component';
import { EditFacturationComponent } from './pages/facturation/pages/edit-facturation/edit-facturation.component';
import { FacturationComponent } from './pages/facturation/facturation.component';
import { EditArticleComponent } from './pages/facturation/modals/edit-article/edit-article/edit-article.component';
import { GenererFacturesComponent } from './pages/facturation/pages/generer-factures/generer-factures.component';
import { DonneesFacturationComponent } from './pages/facturation/modals/donnees-facturation/donnees-facturation.component';
import { TreeModule } from 'angular-tree-component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { GenerationHabilitationsComponent } from './pages/edition/pages/generation-csv/generation-csv.component';
import { AddDocumentComponent } from './pages/edition/modals/add-document/add-document.component';
import { GestionTemplatesComponent } from './pages/edition/pages/gestion-templates/gestion-templates.component';
import { TelechargerDocumentComponent } from './pages/main/components/telecharger-document/telecharger-document.component';
import { SelectJuresComponent } from './pages/commissions/pages/edit-commission/modals/select-jures/select-jures.component';
import { EditionComponent } from './pages/edition/edition.component';
import { CanDeactivateGuard } from './pages/facturation/pages/edit-facturation/changes-in-progress/can-deactivate-guard.service';
import { ChangesInProgressComponent } from './pages/facturation/pages/edit-facturation/changes-in-progress/changes-in-progress.component';
import { DialogService } from './pages/facturation/pages/edit-facturation/changes-in-progress/dialog.service';
import { NgxEchartsModule } from 'ngx-echarts';
import { GenerationDeclarationsComponent } from './pages/edition/pages/generation-declarations/generation-declarations.component';
import * as echarts from 'echarts';
import { ContactsComponent } from './pages/administration/pages/contacts/contacts.component';
import { CampagnesComponent } from './pages/administration/pages/campagnes/campagnes.component';
import { RecoltesComponent } from './pages/declarations/pages/recoltes/recoltes.component';
import { StocksComponent } from './pages/declarations/pages/stocks/stocks.component';
import { EcoulementsComponent } from './pages/declarations/pages/ecoulements/ecoulements.component';
import { ParcellairesComponent } from './pages/declarations/pages/parcellaires/parcellaires.component';
import { HomeInnovComponent } from './pages/home/pages/home-innov/home-innov.component';
import { AnppOrganismeComponent } from './pages/home/pages/anpp-organisme/anpp-organisme.component';
import { AnppProducteurComponent } from './pages/home/pages/anpp-producteur/anpp-producteur.component';
import { ImportInventaireVergersComponent } from './pages/declarations/pages/import-inventaire-vergers/import-inventaire-vergers.component';
import { SelectionFichierComponent } from './pages/declarations/pages/import-inventaire-vergers/modals/selection-fichier/selection-fichier.component';
import { MonCompteComponent } from './pages/mon-compte/mon-compte.component';
import { DetailContactComponent } from './pages/mon-compte/detail-contact/detail-contact.component';
import { AddBlocComponent } from './pages/declarations/pages/parcellaires/modals/add-bloc/add-bloc.component';
import { SelectFieldsColumnComponent } from './pages/declarations/pages/parcellaires/modals/select-fields-column/select-fields-column.component';
import { ValidInventaireComponent } from './pages/declarations/pages/parcellaires/modals/valid-inventaire/valid-inventaire.component';
import { SurgreffageComponent } from './pages/declarations/pages/parcellaires/modals/surgreffage/surgreffage.component';
import { ArrachageComponent } from './pages/declarations/pages/parcellaires/modals/arrachage/arrachage.component';
import { CultureBioComponent } from './pages/declarations/pages/parcellaires/modals/culture-bio/culture-bio.component';
import { ChangerProducteurComponent } from './pages/declarations/pages/parcellaires/modals/changer-producteur/changer-producteur.component';
import { ExportVergerComponent } from './pages/declarations/pages/parcellaires/modals/export-verger/export-verger.component';
import { EntreprisesComponent } from './pages/administration/pages/entreprises/entreprises.component';
import { ListeEntreprisesComponent } from './pages/administration/pages/entreprises/pages/liste-entreprises/liste-entreprises.component';
import { EditEntrepriseComponent } from './pages/administration/pages/entreprises/pages/edit-entreprise/edit-entreprise.component';
import { SelectEntrepriseColonneComponent } from './pages/administration/pages/entreprises/pages/liste-entreprises/modals/select-entreprise-colonne/select-entreprise-colonne.component';
import { ListePoComponent } from './pages/administration/pages/entreprises/pages/liste-po/liste-po/liste-po.component';
import { FicheEntrepriseComponent } from './pages/administration/pages/entreprises/pages/fiche-entreprise/fiche-entreprise/fiche-entreprise.component';
import { ListeContactsComponent } from './pages/administration/pages/contacts/pages/liste-contacts/liste-contacts.component';
import { EditContactComponent } from './pages/administration/pages/contacts/pages/edit-contact/edit-contact.component';
import { SelectContactColonneComponent } from './pages/administration/pages/contacts/pages/liste-contacts/modals/select-contact-colonne/select-contact-colonne.component';
import { ListeContactEntrepriseComponent } from './pages/administration/pages/contacts/pages/liste-contact-entreprise/liste-contact-entreprise.component';
import { VergerEntrepriseComponent } from './pages/administration/pages/entreprises/pages/verger-entreprise/verger-entreprise.component';
import { PrevisionDefinitiveComponent } from './pages/declarations/pages/recoltes/modals/prevision-definitive/prevision-definitive.component';
import { AjoutGroupeVarietalComponent } from './pages/declarations/pages/recoltes/modals/ajout-groupe-varietal/ajout-groupe-varietal.component';
import { AjoutCommentaireComponent } from './pages/declarations/pages/recoltes/modals/ajout-commentaire/ajout-commentaire.component';
import { ValeursParametrablesComponent } from './pages/administration/pages/valeurs-parametrables/valeurs-parametrables.component';
import { ListeFonctionsComponent } from './pages/administration/pages/valeurs-parametrables/pages/fonctions/liste-fonctions/liste-fonctions.component';
import { ListeStructuresComponent } from './pages/administration/pages/valeurs-parametrables/pages/structures/liste-structures/liste-structures.component';
import { EditFonctionComponent } from './pages/administration/pages/valeurs-parametrables/pages/fonctions/edit-fonction/edit-fonction.component';
import { FonctionsComponent } from './pages/administration/pages/valeurs-parametrables/pages/fonctions/fonctions.component';
import { StructuresComponent } from './pages/administration/pages/valeurs-parametrables/pages/structures/structures.component';
import { EditStructureComponent } from './pages/administration/pages/valeurs-parametrables/pages/structures/edit-structure/edit-structure.component';
import { TerroirsComponent } from './pages/administration/pages/valeurs-parametrables/pages/terroirs/terroirs.component';
import { ListeTerroirsComponent } from './pages/administration/pages/valeurs-parametrables/pages/terroirs/liste-terroirs/liste-terroirs.component';
import { EditTerroirComponent } from './pages/administration/pages/valeurs-parametrables/pages/terroirs/edit-terroir/edit-terroir.component';
import { ProtectionsGelComponent } from './pages/administration/pages/valeurs-parametrables/pages/protections-gel/protections-gel.component';
import { ListesProtectionsGelComponent } from './pages/administration/pages/valeurs-parametrables/pages/protections-gel/listes-protections-gel/listes-protections-gel.component';
import { EditProtectionGelComponent } from './pages/administration/pages/valeurs-parametrables/pages/protections-gel/edit-protection-gel/edit-protection-gel.component';
import { ProtectionsGreleComponent } from './pages/administration/pages/valeurs-parametrables/pages/protections-grele/protections-grele.component';
import { ListesProtectionsGreleComponent } from './pages/administration/pages/valeurs-parametrables/pages/protections-grele/listes-protections-grele/listes-protections-grele.component';
import { EditProtectionGreleComponent } from './pages/administration/pages/valeurs-parametrables/pages/protections-grele/edit-protection-grele/edit-protection-grele.component';
import { CulturesBioComponent } from './pages/administration/pages/valeurs-parametrables/pages/cultures-bio/cultures-bio.component';
import { EditCultureBioComponent } from './pages/administration/pages/valeurs-parametrables/pages/cultures-bio/edit-culture-bio/edit-culture-bio.component';
import { ListeCulturesBioComponent } from './pages/administration/pages/valeurs-parametrables/pages/cultures-bio/liste-cultures-bio/liste-cultures-bio.component';
import { FormesComponent } from './pages/administration/pages/valeurs-parametrables/pages/formes/formes.component';
import { SituationsComponent } from './pages/administration/pages/valeurs-parametrables/pages/situations/situations.component';
import { ListeFormesComponent } from './pages/administration/pages/valeurs-parametrables/pages/formes/liste-formes/liste-formes.component';
import { EditFormeComponent } from './pages/administration/pages/valeurs-parametrables/pages/formes/edit-forme/edit-forme.component';
import { EditSituationComponent } from './pages/administration/pages/valeurs-parametrables/pages/situations/edit-situation/edit-situation.component';
import { ListeSituationsComponent } from './pages/administration/pages/valeurs-parametrables/pages/situations/liste-situations/liste-situations.component';
import { SystemesIrrigationsComponent } from './pages/administration/pages/valeurs-parametrables/pages/systemes-irrigations/systemes-irrigations.component';
import { ListeSystemesIrrigationsComponent } from './pages/administration/pages/valeurs-parametrables/pages/systemes-irrigations/liste-systemes-irrigations/liste-systemes-irrigations.component';
import { EditSystemeIrrigationComponent } from './pages/administration/pages/valeurs-parametrables/pages/systemes-irrigations/edit-systeme-irrigation/edit-systeme-irrigation.component';
import { VarietesComponent } from './pages/administration/pages/valeurs-parametrables/pages/varietes/varietes.component';
import { ClonesComponent } from './pages/administration/pages/valeurs-parametrables/pages/clones/clones.component';
import { ListeVarietesComponent } from './pages/administration/pages/valeurs-parametrables/pages/varietes/liste-varietes/liste-varietes.component';
import { EditVarieteComponent } from './pages/administration/pages/valeurs-parametrables/pages/varietes/edit-variete/edit-variete.component';
import { ListeClonesComponent } from './pages/administration/pages/valeurs-parametrables/pages/clones/liste-clones/liste-clones.component';
import { EditClonesComponent } from './pages/administration/pages/valeurs-parametrables/pages/clones/edit-clones/edit-clones.component';
import { GroupesVarietauxComponent } from './pages/administration/pages/valeurs-parametrables/pages/groupes-varietaux/groupes-varietaux.component';
import { ListeGroupesVarietauxComponent } from './pages/administration/pages/valeurs-parametrables/pages/groupes-varietaux/liste-groupes-varietaux/liste-groupes-varietaux.component';
import { EditGroupeVarietalComponent } from './pages/administration/pages/valeurs-parametrables/pages/groupes-varietaux/edit-groupe-varietal/edit-groupe-varietal.component';
import { PortesGreffesComponent } from './pages/administration/pages/valeurs-parametrables/pages/portes-greffes/portes-greffes.component';
import { ListesPortesGreffesComponent } from './pages/administration/pages/valeurs-parametrables/pages/portes-greffes/listes-portes-greffes/listes-portes-greffes.component';
import { EditPorteGreffeComponent } from './pages/administration/pages/valeurs-parametrables/pages/portes-greffes/edit-porte-greffe/edit-porte-greffe.component';
import { ListeValeursParametrablesComponent } from './pages/administration/pages/valeurs-parametrables/pages/liste-valeurs-parametrables/liste-valeurs-parametrables.component';
import { GroupesDiffusionComponent } from './pages/administration/pages/valeurs-parametrables/pages/groupes-diffusion/groupes-diffusion.component';
import { ListeGroupesDiffusionComponent } from './pages/administration/pages/valeurs-parametrables/pages/groupes-diffusion/liste-groupes-diffusion/liste-groupes-diffusion.component';
import { EditGroupeDiffusionComponent } from './pages/administration/pages/valeurs-parametrables/pages/groupes-diffusion/edit-groupe-diffusion/edit-groupe-diffusion.component';
import { SuiviInventairesVergersComponent } from './pages/suivis/pages/suivi-inventaires-vergers/suivi-inventaires-vergers.component';
import { SuiviDeclarationsRecolteComponent } from './pages/suivis/pages/suivi-declarations-recolte/suivi-declarations-recolte.component';
import { SuivisComponent } from './pages/suivis/suivis.component';
import { ChoixColonnesComponent } from '../shared/components/choix-colonnes/choix-colonnes.component';
import { LocalisationsComponent } from './pages/declarations/pages/parcellaires/modals/localisations/localisations.component';
import { EstimationsRecoltesComponent } from './pages/suivis/pages/suivi-declarations-recolte/modals/estimations-recoltes/estimations-recoltes.component';
import { EngagementsComponent } from './pages/engagements/engagements.component';
import { VergersEcoresponsablesComponent } from './pages/engagements/pages/vergers-ecoresponsables/vergers-ecoresponsables.component';
import { FormulairePiComponent } from './pages/engagements/pages/formulaires/formulaire-pi/formulaire-pi.component';
import { FormulaireGpComponent } from './pages/engagements/pages/formulaires/formulaire-gp/formulaire-gp.component';
import { ChangementStatutComponent } from './pages/engagements/pages/modals/changement-statut/changement-statut.component';
import { DocPreparatoireOCComponent } from './pages/engagements/pages/modals/doc-preparatoire-oc/doc-preparatoire-oc.component';
import { DocPreparatoireCtComponent } from './pages/engagements/pages/modals/doc-preparatoire-ct/doc-preparatoire-ct.component';
import { EstimationStockComponent } from './pages/suivis/pages/suivi-stocks/modals/estimation-stock/estimation-stock.component';
import { SuiviStocksComponent } from './pages/suivis/pages/suivi-stocks/suivi-stocks.component';
import { InteractionsComponent } from './pages/administration/pages/interactions/interactions.component';
import { ListeInteractionsComponent } from './pages/administration/pages/interactions/pages/liste-interactions/liste-interactions.component';
import { EditInteractionComponent } from './pages/administration/pages/interactions/pages/edit-interaction/edit-interaction.component';
import { TypeInteractionsComponent } from './pages/administration/pages/valeurs-parametrables/pages/type-interactions/type-interactions.component';
import { ListeTypeInteractionsComponent } from './pages/administration/pages/valeurs-parametrables/pages/type-interactions/liste-type-interactions/liste-type-interactions.component';
import { EditTypeInteractionComponent } from './pages/administration/pages/valeurs-parametrables/pages/type-interactions/edit-type-interaction/edit-type-interaction.component';
import { InteractionsEntrepriseComponent } from './pages/administration/pages/entreprises/pages/interactions-entreprise/interactions-entreprise.component';
import { InteractionsContactComponent } from './pages/administration/pages/contacts/pages/interactions-contact/interactions-contact.component';
import { SuiviEcoulementsComponent } from './pages/suivis/pages/suivi-ecoulements/suivi-ecoulements.component';
import { RecapitulatifComponent } from './pages/declarations/pages/ecoulements/modals/recapitulatif/recapitulatif.component';
import { EstimationEcoulementComponent } from './pages/suivis/pages/suivi-ecoulements/modals/estimation-ecoulement/estimation-ecoulement.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { EditDocumentComponent } from './pages/documents/modals/edit-document/edit-document.component';
import { DatePipe } from '@angular/common';
import { DonneesDrComponent } from './pages/declarations/pages/synthese-volumes/modals/donnees-dr/donnees-dr.component';
import { SyntheseJureComponent } from './pages/commissions/pages/synthese-jure/synthese-jure.component';
import { DetailSyntheseComponent } from './pages/commissions/pages/synthese-jure/modals/detail-synthese/detail-synthese.component';
import { DetailControleComponent } from './pages/suivi-declarations/modal/detail-controle/detail-controle.component';
import { DetailOperateurComponent } from './pages/operateurs/modal/detail-operateur/detail-operateur.component';
import { TourneesComponent } from './pages/tournees/tournees.component';
import { PlanningTourneesComponent } from './pages/tournees/pages/planning-tournees/planning-tournees.component';
import { EditTourneeComponent } from './pages/tournees/pages/edit-tournee/edit-tournee.component';
import { RechercheEchantillonsTourneeComponent } from './pages/tournees/pages/edit-tournee/modals/recherche-echantillons-tournee/recherche-echantillons-tournee.component';
import { ListConseillerComponent } from './pages/administration/pages/entreprises/pages/list-conseiller/list-conseiller.component';
import {JuresComponent} from './pages/administration/pages/jures/jures.component';
import {ListeJuresComponent} from './pages/administration/pages/jures/pages/liste-jures/liste-jures.component';
import {
  AddEditQualificationComponent
} from './pages/administration/pages/jures/modal/add-edit-qualification/add-edit-qualification.component';
import {UpdateJureComponent} from './pages/administration/pages/jures/pages/update-jure/update-jure.component';
import { ParcellaireViticoleComponent } from './pages/parcellaire-viticole/parcellaire-viticole.component';
import { HistoriqueParcellaireComponent } from './pages/parcellaire-viticole/pages/historique-parcellaire/historique-parcellaire.component';
import { DivisionComponent } from './pages/declarations/pages/parcellaires/modals/division/division.component';
import { ValidDpapComponent } from './pages/declarations/pages/parcellaires/modals/valid-dpap/valid-dpap.component';
import { VisuDpapComponent } from './pages/parcellaire-viticole/modals/visu-dpap/visu-dpap.component';
import { VisuRecapUcComponent } from './pages/parcellaire-viticole/modals/visu-recap-uc/visu-recap-uc.component';
import { FiltreEchantillonsIgpComponent } from './pages/commissions/pages/edit-commission/modals/filtre-echantillons-igp/filtre-echantillons-igp.component';
import {
  EditInformationsMineuresComponent
} from './pages/operateurs/pages/informations-operateur/modals/edit-informations-mineures/edit-informations-mineures.component';
import {
  EditInformationsMajeuresComponent
} from './pages/operateurs/pages/informations-operateur/modals/edit-informations-majeures/edit-informations-majeures.component';
import {AddOperateurComponent} from './pages/operateurs/pages/ajout-operateur/modal/add-operateur.component';
import { SuiviParcellairesViticolesComponent } from './pages/suivi-parcellaires-viticoles/suivi-parcellaires-viticoles.component';

@NgModule({
  declarations: [
    HabilitationsComponent,
    HomeComponent,
    MainComponent,
    SidebarComponent,
    UpdateHabilitationComponent,
    StatutBadgeComponent,
    HistoriqueItemComponent,
    UtilisateursComponent,
    ListeUtilisateursComponent,
    AdministrationComponent,
    OrganismesComponent,
    ListeOrganismesComponent,
    EditOrganismeComponent,
    EditUtilisateurComponent,
    UtilisateurDroitsComponent,
    EditAbonnementsComponent,
    OperateursComponent,
    RechercheOperateurAffilieComponent,
    OperateurDashboardComponent,
    UtilisateurStatutComponent,
    AjoutOperateurComponent,
    OperateurHabilitationsComponent,
    OperateurOrganismesComponent,
    DeclarationsComponent,
    AffichageDrComponent,
    AffichageDrevComponent,
    TankComponent,
    InformationsOperateurComponent,
    SyntheseVolumesComponent,
    ChangementsDenominationComponent,
    CommissionsComponent,
    EditCommissionComponent,
    PlanningCommissionsComponent,
    ChangementsDenominationComponent,
    SaisirConditionnementComponent,
    RechercheJureComponent,
    RechercheCdcsComponent,
    RechercheEchantillonsComponent,
    DroitsComponent,
    RechercheProduitComponent,
    SaisirRevendicationComponent,
    ChoixStockComponent,
    SaisirTransactionComponent,
    RechercherAcheteurComponent,
    SaisirConditionnementIgpComponent,
    DrevManquantesComponent,
    OperateurComponent,
    SaisieVciComponent,
    HistoriqueComponent,
    SaisieVciComponent,
    SuiviDeclarationsComponent,
    SyntheseSaisieRevendicationComponent,
    AffectationEchantillonsComponent,
    ProfilsComponent,
    ControlesComponent,
    EditionControleComponent,
    ListControlesComponent,
    ProfilsComponent,
    ControlesComponent,
    PressionControleComponent,
    ListEchantillonsComponent,
    SaisirPrelevementComponent,
    SuiviControlesComponent,
    SaisirConstatComponent,
    SyntheseConstatsComponent,
    SearchManquementsComponent,
    EditManquementComponent,
    SaisirNotificationComponent,
    SaisirDecisionComponent,
    EditMdpComponent,
    SaisirRecoursComponent,
    ConsultationDrevComponent,
    FacturationComponent,
    ListFacturationComponent,
    EditFacturationComponent,
    EditArticleComponent,
    GenererFacturesComponent,
    DonneesFacturationComponent,
    GenerationHabilitationsComponent,
    GestionTemplatesComponent,
    AddDocumentComponent,
    TelechargerDocumentComponent,
    SelectJuresComponent,
    EditionComponent,
    ChangesInProgressComponent,
    GenerationDeclarationsComponent,
    ContactsComponent,
    CampagnesComponent,
    RecoltesComponent,
    StocksComponent,
    EcoulementsComponent,
    ParcellairesComponent,
    ParcellairesComponent,
    ImportInventaireVergersComponent,
    SelectionFichierComponent,
    HomeInnovComponent,
    AnppOrganismeComponent,
    AnppProducteurComponent,
    MonCompteComponent,
    DetailContactComponent,
    AddBlocComponent,
    SelectFieldsColumnComponent,
    ValidInventaireComponent,
    SurgreffageComponent,
    ArrachageComponent,
    CultureBioComponent,
    ChangerProducteurComponent,
    ExportVergerComponent,
    EntreprisesComponent,
    ListeEntreprisesComponent,
    EditEntrepriseComponent,
    SelectEntrepriseColonneComponent,
    ListePoComponent,
    FicheEntrepriseComponent,
    ListeContactsComponent,
    EditContactComponent,
    SelectContactColonneComponent,
    ListeContactEntrepriseComponent,
    VergerEntrepriseComponent,
    PrevisionDefinitiveComponent,
    AjoutGroupeVarietalComponent,
    AjoutCommentaireComponent,
    ChoixColonnesComponent,
    ValeursParametrablesComponent,
    ListeFonctionsComponent,
    ListeStructuresComponent,
    EditFonctionComponent,
    FonctionsComponent,
    StructuresComponent,
    EditStructureComponent,
    TerroirsComponent,
    ListeTerroirsComponent,
    EditTerroirComponent,
    ProtectionsGelComponent,
    ListesProtectionsGelComponent,
    EditProtectionGelComponent,
    ProtectionsGreleComponent,
    ListesProtectionsGreleComponent,
    EditProtectionGreleComponent,
    CulturesBioComponent,
    EditCultureBioComponent,
    ListeCulturesBioComponent,
    FormesComponent,
    SituationsComponent,
    ListeFormesComponent,
    EditFormeComponent,
    EditSituationComponent,
    ListeSituationsComponent,
    SystemesIrrigationsComponent,
    ListeSystemesIrrigationsComponent,
    EditSystemeIrrigationComponent,
    VarietesComponent,
    ClonesComponent,
    ListeVarietesComponent,
    EditVarieteComponent,
    ListeClonesComponent,
    EditClonesComponent,
    GroupesVarietauxComponent,
    ListeGroupesVarietauxComponent,
    EditGroupeVarietalComponent,
    PortesGreffesComponent,
    ListesPortesGreffesComponent,
    EditPorteGreffeComponent,
    ListeValeursParametrablesComponent,
    GroupesDiffusionComponent,
    ListeGroupesDiffusionComponent,
    EditGroupeDiffusionComponent,
    SuivisComponent,
    SuiviInventairesVergersComponent,
    SuiviDeclarationsRecolteComponent,
    InteractionsComponent,
    ListeInteractionsComponent,
    EditInteractionComponent,
    LocalisationsComponent,
    EstimationsRecoltesComponent,
    EngagementsComponent,
    VergersEcoresponsablesComponent,
    FormulairePiComponent,
    FormulaireGpComponent,
    ChangementStatutComponent,
    DocPreparatoireOCComponent,
    DocPreparatoireCtComponent,
    TypeInteractionsComponent,
    ListeTypeInteractionsComponent,
    EditTypeInteractionComponent,
    InteractionsEntrepriseComponent,
    InteractionsContactComponent,
    SuiviEcoulementsComponent,
    RecapitulatifComponent,
    EstimationEcoulementComponent,
    EstimationStockComponent,
    SuiviStocksComponent,
    DocumentsComponent,
    EditDocumentComponent,
    DonneesDrComponent,
    SyntheseJureComponent,
    DetailSyntheseComponent,
    DetailControleComponent,
    DetailOperateurComponent,
    TourneesComponent,
    PlanningTourneesComponent,
    EditTourneeComponent,
    RechercheEchantillonsTourneeComponent,
    ListConseillerComponent,
    JuresComponent,
    AddEditQualificationComponent,
    ListeJuresComponent,
    UpdateJureComponent,
    ParcellaireViticoleComponent,
    HistoriqueParcellaireComponent,
    DivisionComponent,
    ValidDpapComponent,
    VisuDpapComponent,
    VisuRecapUcComponent,
    FiltreEchantillonsIgpComponent,
    EditInformationsMajeuresComponent,
    EditInformationsMineuresComponent,
    AddOperateurComponent,
    SuiviParcellairesViticolesComponent
  ],
  imports: [
    // HttpClientModule,
    SharedModule,
    RouterModule,
    AuthentificationModule,
    MainRoutingModule,
    BrowserAnimationsModule,
    EditorModule,
    TreeModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgxEchartsModule
  ],
  providers: [
    DialogService,
    CanDeactivateGuard,
    DatePipe
  ],
  entryComponents: [
    UpdateHabilitationComponent,
    ChangementsDenominationComponent,
    SaisirConditionnementComponent,
    SaisirConditionnementIgpComponent,
    SyntheseSaisieRevendicationComponent,
    RechercheJureComponent,
    RechercheCdcsComponent,
    RechercheEchantillonsComponent,
    RechercheProduitComponent,
    SaisirRevendicationComponent,
    ChoixStockComponent,
    SaisirTransactionComponent,
    RechercherAcheteurComponent,
    OperateurComponent,
    SaisieVciComponent,
    AffectationEchantillonsComponent,
    EditionControleComponent,
    SaisirPrelevementComponent,
    SyntheseConstatsComponent,
    SaisirConstatComponent,
    EditManquementComponent,
    EditArticleComponent,
    DonneesFacturationComponent,
    TelechargerDocumentComponent,
    SelectJuresComponent,
    AddDocumentComponent,
    ChangesInProgressComponent,
    DetailContactComponent,
    AddBlocComponent,
    SelectFieldsColumnComponent,
    ValidInventaireComponent,
    SurgreffageComponent,
    ArrachageComponent,
    CultureBioComponent,
    ChangerProducteurComponent,
    ExportVergerComponent,
    SelectEntrepriseColonneComponent,
    SelectContactColonneComponent,
    PrevisionDefinitiveComponent,
    AjoutGroupeVarietalComponent,
    AjoutCommentaireComponent,
    ChoixColonnesComponent,
    RecapitulatifComponent,
    LocalisationsComponent,
    EstimationsRecoltesComponent,
    EditDocumentComponent,
    ChangementStatutComponent,
    DocPreparatoireOCComponent,
    DocPreparatoireCtComponent,
    StocksComponent,
    EstimationsRecoltesComponent,
    EstimationStockComponent,
    EstimationEcoulementComponent,
    DonneesDrComponent,
    DetailSyntheseComponent,
    DetailControleComponent,
    DetailOperateurComponent,
    RechercheEchantillonsTourneeComponent,
    AddEditQualificationComponent,
    DivisionComponent,
    ValidDpapComponent,
    HistoriqueParcellaireComponent,
    VisuDpapComponent,
    VisuRecapUcComponent,
    FiltreEchantillonsIgpComponent,
    EditInformationsMajeuresComponent,
    EditInformationsMineuresComponent,
    AddOperateurComponent,
    ParcellairesComponent
  ]
})
export class MainModule { }
