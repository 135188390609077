import { JsonProperty, JsonObject, CustomConverter } from '@thorolf/json-ts-mapper';
import { DateConverter } from 'src/app/core/services/mapper/converters';

@JsonObject
export class DashBoardDeclaration {
  @JsonProperty('dateDeclaration', String)
  @CustomConverter(DateConverter)
  dateDeclaration: moment.Moment;

  @JsonProperty('codeMouvement', String)
  codeMouvement: string;

}
