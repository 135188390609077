import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {JsonMapperService} from 'src/app/core/services/mapper/mapper.service';
import {Contact} from 'src/app/data/intervenant/models/contact.model';
import {map} from 'rxjs/operators';
import {Referent} from '../../models/referent.model';
import {Groupe} from '../../models/groupe.models';
import {ContactGroupe} from '../../models/contact-groupe.model';
import {ContactReferent} from '../../models/contact-referent.model';
import {ContactInteraction} from '../../models/contact-interaction.model';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService) {
  }

  getContacts(): Observable<Contact[]> {
    return this.http.get<Contact[]>(`/api/intervenant/private/contacts`).pipe(
      map(contact => this.mapper.deserializeArray(contact, Contact))
    );
  }

  getContactsGroupe(): Observable<ContactGroupe[]> {
    return this.http.get<ContactGroupe[]>(`/api/intervenant/private/contacts_groupe`).pipe(
      map(contact => this.mapper.deserializeArray(contact, ContactGroupe))
    );
  }

  getContact(idContact: number): Observable<Contact> {
    return this.http.get<Contact>(`/api/intervenant/private/contacts/${idContact}`).pipe(
      map(contact => this.mapper.deserializeObject(contact, Contact))
    );
  }

  getContactsByEntreprise(idEntreprise: number): Observable<Contact[]> {
    return this.http.get<Contact[]>(`/api/intervenant/private/entreprises/${idEntreprise}/contacts`).pipe(
      map(contact => this.mapper.deserializeArray(contact, Contact))
    );
  }

  getContactInteractionsByEntreprise(idEntreprise: number): Observable<ContactInteraction[]> {
    return this.http.get<ContactInteraction[]>(`/api/intervenant/private/entreprises/${idEntreprise}/contact_interactions`).pipe(
      map(contact => this.mapper.deserializeArray(contact, ContactInteraction))
    );
  }

  public creerContact(contact: Contact): Observable<number> {
    return this.http.post(`/api/intervenant/private/contacts`, contact, {observe: 'response'}).pipe(
      map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
    );
  }

  public modifierContact(contact: Contact): Observable<void> {
    return this.http.put<void>(`/api/intervenant/private/contacts/${contact.id}`, contact);
  }

  public supprimerContact(idContact: number): Observable<void> {
    return this.http.delete<void>(`/api/intervenant/private/contacts/${idContact}`);
  }

  public getReferents(): Observable<Referent[]> {
    return this.http.get<Referent[]>(`/api/intervenant/private/referents`);
  }

  public getGroupes(): Observable<Groupe[]> {
    return this.http.get<Groupe[]>(`/api/intervenant/private/groupes`);
  }

  getMailContactByGroupes(idGroupe: number): Observable<String[]> {
    return this.http.get<String[]>(`/api/intervenant/private/contacts/mails/${idGroupe}`).pipe(

    );

  }

}
