import { JsonObject, JsonProperty } from "@thorolf/json-ts-mapper";

@JsonObject
export class RecolteEspeceQuantite {
    @JsonProperty('idEspece', Number)
    idEspece: number;

    @JsonProperty('codeEspece', String)
    codeEspece: string;

    @JsonProperty('idEntreprise', Number)
    idEntreprise: number;

    @JsonProperty('quantite', Number)
    quantite: number;
}