import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { FormBuilder, NgForm, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AbstractRechercheItemComponent } from '../../../commissions/pages/edit-commission/modals/abstract-recherche-items.component';
import { Observable } from 'rxjs';
import { Document } from 'src/app/data/edition/models/document.model';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss']
})
export class AddDocumentComponent extends AbstractRechercheItemComponent<Document> implements OnInit {
  @ViewChild('form') form: NgForm;
  formGroup: FormGroup;

  getData(): Observable<Document[]> {
    throw new Error('Method not implemented.');
  }

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly fb: FormBuilder,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
  ) {
    super(modal);
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      nomDocument: [undefined, Validators.required],
    });
  }

  save() {
    this.modal.close(this.formGroup.get('nomDocument').value);
  }

  cancel() {
    this.modal.close();
  }

}
