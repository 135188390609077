<div>
  <div class="modal-header">
    <h4 class="modal-title">
      {{(creation ? 'page.controles.edit.title.creation' : 'page.controles.edit.title.modification')|translate}}
    </h4>
  </div>

  <div class="modal-body">
    <form *ngIf="controleForm" [formGroup]="controleForm" #form="ngForm" (ngSubmit)="submit()" novalidate>
      <div class="cols">
        <!-- Div col gauche -->
        <div class="col">
          <div formGroupName="operateur">
            <div class="form-group">
              <label translate>page.controles.edit.form.cvi</label>
              <input type="text" formControlName="cvi" class="form-control">
            </div>

            <div class="form-group">
              <label translate>page.controles.edit.form.raisonSociale</label>
              <input type="text" formControlName="raisonSociale" class="form-control">
            </div>
          </div>

          <hr/>

          <div formGroupName="mouvement">
            <div class="form-group">
              <label translate>page.controles.edit.form.type</label>
              <input type="text" formControlName="type" class="form-control">
            </div>

            <div class="form-group">
              <label translate>page.controles.edit.form.numeroDossier</label>
              <input type="text" formControlName="numeroDossier" class="form-control">
            </div>

            <div class="form-group">
              <label translate>page.controles.edit.form.produits</label>
              <textarea type="text" formControlName="produits" class="form-control"></textarea>
            </div>
          </div>
        </div>
        <!-- Fin div col gauche -->

        <!-- Div col droite -->
        <div class="col">
          <div formGroupName="pression">
            <div class="form-group">
              <label translate>page.controles.edit.form.cdcs</label>
              <input type="text" formControlName="cdcs" class="form-control">
            </div>

            <div class="form-group">
              <label translate>page.controles.edit.form.nature</label>
              <input type="text" formControlName="nature" class="form-control">
            </div>

            <div class="form-group">
              <label translate>page.controles.edit.form.objet</label>
              <input type="text" formControlName="objet" class="form-control">
            </div>
          </div>
          <div class="form-group" *ngIf="pression.nature.code === 'ANALYTIQUE'">
            <label translate>page.controles.edit.form.laboratoire</label>
            <select class="form-control" formControlName="laboratoire"
                    [ngClass]="{'is-invalid': form.submitted && getField('laboratoire').invalid}">
              <option [value]="null"></option>
              <option *ngFor="let laboratoire of laboratoires"
                      [ngValue]="laboratoire">{{laboratoire.libelle}}</option>
            </select>
          </div>

          <div class="form-group" *ngIf="pression.nature.code === 'ANALYTIQUE'">
            <label translate>page.controles.edit.form.dateEnvoiLaboratoire</label>
            <div class="input-group">
              <input formControlName="dateEnvoiLaboratoire" ngbDatepicker #datePickerLaboratoire="ngbDatepicker"
                     [ngClass]="{'is-invalid': form.submitted && getField('dateEnvoiLaboratoire').invalid}"
                     class="form-control"/>
              <div class="input-group-append">
                <button type="button" (click)="datePickerLaboratoire.toggle()" class="btn btn-secondary">
                  <fa-icon icon="calendar-alt"></fa-icon>
                </button>
              </div>
            </div>
          </div>


          <hr/>

          <div class="form-group">
            <label translate>page.controles.edit.form.date_butoir</label>
            <div class="input-group">
              <input formControlName="dateButoir" ngbDatepicker #datePicker="ngbDatepicker"
                     [ngClass]="{'is-invalid': form.submitted && getField('dateButoir').invalid}" class="form-control"/>
              <div class="input-group-append">
                <button type="button" (click)="datePicker.toggle()" class="btn btn-secondary">
                  <fa-icon icon="calendar-alt"></fa-icon>
                </button>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label translate>page.controles.edit.form.agent</label>
            <select class="form-control" formControlName="agent"
                    [ngClass]="{'is-invalid': form.submitted && getField('agent').invalid}">
              <option [value]="null"></option>
              <option *ngFor="let agent of agents" [ngValue]="agent">{{agent.nom}} {{agent.prenom}}</option>
            </select>
          </div>
        </div>
        <!-- Fin div col droite -->
      </div>
      <!-- Fin div cols -->

      <div class="actions-bar">
        <button type="button" class="btn btn-light" (click)="annuler()" translate>label.annuler</button>
        <span style="flex:1"></span>
        <button type="submit" class="btn btn-primary" translate>label.enregistrer</button>
      </div>
    </form>
  </div>
</div>
