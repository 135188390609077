<div class="content">
  <div class="content-panel">
    <div id="abonnements-container" *hasRole="'INTERVENANT_ORGANISME_ABONNEMENT_ECRITURE'; elseDisable">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th *ngFor="let service of services">{{ service.libelle }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let domaine of domaines">
            <td>{{ domaine.libelle }}</td>
            <td *ngFor="let service of services">
              <ng-container *ngTemplateOutlet="buttonAbonnement; context: { domaine: domaine, service: service }"></ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #buttonAbonnement let-domaine="domaine" let-service="service">
  <button type="button" *ngIf="getAbonnement(domaine, service); else noAbo" (click)="deleteAbonnement(domaine, service)"
    class="btn btn-outline-secondary btn-remove" t-id="add-abonnement-button">
    <fa-icon icon="check-circle" class="green-icon"></fa-icon>
  </button>
  
  <ng-template #noAbo>
    <button type="button" (click)="createAbonnement(domaine, service)"
      class="btn btn-outline-secondary btn-add" t-id="add-abonnement-button">
      <fa-icon icon="plus" class="primary-icon"></fa-icon>
    </button>
  </ng-template>
</ng-template>
