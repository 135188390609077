import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class Contenant {
    @JsonProperty('idType', Number)
    idType: number = undefined;

    @JsonProperty('nombre', Number)
    nombre: number = undefined;

    @JsonProperty('volume', Number)
    volume: number = undefined;
}
