import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Routes } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { Campagne } from 'src/app/data/declaration/models/campagne.model';
import { Ecoulement } from 'src/app/data/declaration/models/ecoulement.model';
import { GroupeVarietal } from 'src/app/data/declaration/models/groupe-varietal.model';
import { DeclarationsService } from 'src/app/data/declaration/services/declarations/declarations.service';
import { EcoulementsService } from 'src/app/data/declaration/services/ecoulements/ecoulements.service';
import { RefCampagnesService } from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';
import { UtilisateursService } from 'src/app/data/intervenant/services/utilisateurs/utilisateurs.service';
import { AjoutGroupeVarietalComponent } from '../recoltes/modals/ajout-groupe-varietal/ajout-groupe-varietal.component';
import { RecapitulatifComponent } from './modals/recapitulatif/recapitulatif.component';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';

@Component({
  selector: 'app-ecoulements',
  templateUrl: './ecoulements.component.html',
  styleUrls: ['./ecoulements.component.scss']
})
export class EcoulementsComponent implements OnInit {

  static routes: Routes = [
    {
      path: '', component: EcoulementsComponent, data: {
        role: 'ORGANISME || DECLARATION_ECOULEMENT_ECRITURE',
        domaines: ["ANPP"],
        menu: {
          libelle: 'menu.ecoulements',
          icon: 'key'
        }
      }
    },
    {path: ':idEntreprise', component: EcoulementsComponent, data: {
      role: 'ORGANISME || PI || GP',
      domaines: ["ANPP"],
     }
    }
  ];
  campagnes: Campagne[];
  campagne: Campagne;
  formGroup: any;
  ecoulements: Ecoulement[] = [];
  isSaisissable:boolean =true;
  mois: number[] = [11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  numMois: number = 0;
  numMoisCourant: number = 0;
  moisHorsLimites = false;

  entrepriseId: number;
  anneeCourante: number;

  public estAnpp = this.authService.isAnpp();

  get campagneSel() { return this.formGroup.get('selectionCampagne'); }
  get moisSel() { return this.formGroup.get('selectionMois'); }

  constructor(
    private readonly declarationService: DeclarationsService,
    private readonly refCampagneService: RefCampagnesService,
    private fb: FormBuilder,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly modalService: NgbModal,
    private readonly ecoulementsService: EcoulementsService,
    private readonly utilisateursService: UtilisateursService,
    private readonly authService: AuthService,
    private readonly loaderService: AdvBootstrapLoaderService,

    ) {  }

  onCampagneChange() {
    this.campagne = this.formGroup.get('selectionCampagne').value as Campagne;

    this.isSaisissable = (this.campagne.annee === this.anneeCourante && (this.numMoisCourant === this.numMois || this.estAnpp));

    this.getEcoulements();
  }

  onMoisChange() {
    this.numMois = this.formGroup.get('selectionMois').value as number;
    this.isSaisissable = (this.campagne.annee === this.anneeCourante && (this.numMoisCourant === this.numMois || this.estAnpp));
    this.getEcoulements();
  }


  getEcoulements() {
    this.ecoulementsService.getEcoulements(this.entrepriseId, this.campagne.id, this.numMois).pipe(this.loaderService.operator()).subscribe(declaration => {
      this.ecoulements = declaration.ecoulements;
      this.ajoutTotaux();
      this.updateTotaux();
      this.ecoulements.sort((a, b) => {
        return a.sorting().localeCompare(b.sorting());
      });
    },
      error => {
        this.ecoulements = [];
        this.ajoutTotaux();
        this.updateTotaux();
      })
  }


  ngOnInit() {
    this.declarationService.activeTabId = "ecoulements";
    setTimeout(() => {}, 10);

    this.formGroup = this.fb.group({
      selectionCampagne: [this.campagne, ],
      selectionMois: [this.numMois, ],
    });

    
    // récupération des campagnes
    this.refCampagneService.getCampagneAll().subscribe(
      referentiel => {
        this.campagnes = referentiel.sort((a, b) => {
          if (a && b && a.annee > b.annee) return -1;
          if (a && b && a.annee < b.annee) return 1;
          return 0;
        });
        // récupération de la campagne par défaut (bascule au 1er aout)
        let today = new Date();
        this.anneeCourante = (new Date(today.getFullYear(), 8, 1)) < today ? today.getFullYear() : today.getFullYear() - 1;

        this.numMois = (today.getDate() < 25) ? (today.getMonth() + 1)  : (today.getMonth() + 2);

        if (this.numMois === 9 || this.numMois === 10) {
          // this.numMois = 8;
          // this.moisHorsLimites = true;
          this.numMois = 11;
        }

        this.numMoisCourant = this.numMois;
        if (!this.mois.indexOf(this.numMois)){
          this.numMois = this.mois[0];
        }
        const leMois = this.mois.find(m => m == this.numMois);
        this.moisSel.setValue(leMois);

        this.refCampagneService.getCampagneAnnee(this.anneeCourante).subscribe(campagne => {
          this.campagne = campagne;

          if (this.declarationService.entrepriseSelectionnee) {
            this.entrepriseId = this.declarationService.entrepriseSelectionnee.id;
            if (this.entrepriseId) {
              this.getEcoulements();
            }
          } else {
            this.utilisateursService.getUtilisateur(SessionContext.get('idIntervenant')).subscribe(utilisateur => {
              if (!utilisateur || (utilisateur && !utilisateur.entreprise)) {
                this.entrepriseId = null;
                this.declarationService.entrepriseSelectionnee = null;
              } else {
                this.declarationService.entrepriseSelectionnee = utilisateur.entreprise;
                this.entrepriseId = utilisateur.entreprise.id;
                if (this.entrepriseId) {
                  this.getEcoulements();
                }
              }
            })
          }
          if (this.campagne) {
            const camp = this.campagnes.find(campagne => campagne.id == this.campagne.id);
            this.campagneSel.setValue(camp);
          }
        })
      })
  }

  /**
   * ouverture modale ajout d'un groupe variétal
   */
   ajouterGroupeVarietal() {
    const modalRef = this.modalService.open(AjoutGroupeVarietalComponent, { size: 'lg', backdrop: 'static' });

    modalRef.result.then((result) => {
      if (result) {
        if (this.ecoulements.filter(ecoulement => ecoulement.idGroupeVarietal == (result as GroupeVarietal).id).length > 0) {
          this.toastr.warning(
            this.translate.instant('page.ecoulement.modalAjouterGroupeVarietal.dejaPresent'),
            this.translate.instant('label.erreur'),
            { timeOut: 10 * 1000 }
          );
        } else {
          let newGv = new Ecoulement();
          newGv.idGroupeVarietal = (result as GroupeVarietal).id;
          newGv.groupeVarietal = (result as GroupeVarietal).libelle;
          newGv.codeEspece = (result as GroupeVarietal).codeEspece;
          this.ecoulements.push(newGv);
          this.ajoutTotaux();
          this.updateTotaux();
          this.ecoulements.sort((a, b) => {
            return a.sorting().localeCompare(b.sorting());
          });
        }

      }
    }
    ).catch(e => {
      console.log(e)
    });
  }

  /**
   * ajoute une ligne total pour une espèce si elle n'existe pas encore
   */
   ajoutTotaux(): void {
    (new Set(this.ecoulements.map(ecoulement => ecoulement.codeEspece)))
      .forEach(codeEspece => {
        var total = this.ecoulements.filter(r => r.isTotal && r.codeEspece === codeEspece).find((o) => { return o != null; });
        if (total == undefined) {
          var newTotal = new Ecoulement();
          newTotal.codeEspece = codeEspece;
          newTotal.groupeVarietal = "TOTAL";
          newTotal.isTotal = true;
          newTotal.idGroupeVarietal = codeEspece === "POMME" ? 9999 : 8888;
          this.ecoulements.push(newTotal);
        }
      })
  }

  updateTotaux(): void {
    // on fait un pseudo tableau des especes présentes
    (new Set(this.ecoulements.map(ecoulement => ecoulement.codeEspece)))
      .forEach(codeEspece => {
        // on parcourt les especes
        let  total:Ecoulement = this.ecoulements.filter(r => r.isTotal && r.codeEspece === codeEspece).find((o) => { return o != null; });
        if (total) {
          total.qteTotaleFrance = this.ecoulements.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.qteTotaleFrance, 0);
          total.qteBioFrance = this.ecoulements.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.qteBioFrance, 0);

          total.qteTotaleExport = this.ecoulements.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.qteTotaleExport, 0);
          total.qteBioExport = this.ecoulements.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.qteBioExport, 0);

          total.qteTotaleIndustrie = this.ecoulements.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.qteTotaleIndustrie, 0);
          total.qteBioIndustrie = this.ecoulements.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.qteBioIndustrie, 0);

          total.qteTotaleFreinte = this.ecoulements.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.qteTotaleFreinte, 0);
          total.qteBioFreinte = this.ecoulements.filter(r => !r.isTotal && r.codeEspece === codeEspece).reduce((x, current) => x + current.qteBioFreinte, 0);
        }
      })
  }

    /**
   * appel modale récapitulative, puis sauvegarde
   */
  onSave() {
      if (this.getFormValidationErrors()) {
        this.toastr.warning(
          this.translate.instant('page.recoltesPp.alert.erreurSaisie'),
          this.translate.instant('label.erreur'),
          { timeOut: 10 * 1000 }
        );

      } else {

        const modalRef = this.modalService.open(RecapitulatifComponent, { size: 'lg', backdrop: 'static' });
        modalRef.componentInstance.ecoulements = this.ecoulements.filter(recolte => recolte.isTotal);

        modalRef.result.then((result) => {
          if (result) {
            this.ecoulementsService.saveEcoulements(this.entrepriseId, this.campagne.id, this.numMois, this.ecoulements).subscribe(
              response => {
                this.toastr.success(
                  this.translate.instant('page.ecoulement.alert.creation.message'),
                  this.translate.instant('page.ecoulement.alert.creation.title'),
                  { timeOut: 10 * 1000 }
                );
                this.getEcoulements();
              }
            );
          }
        });
      }
    }
  getFormValidationErrors() {
    return false;
  }

  getLibelleMois(numMois: number): string{
    let date = new Date(2022, numMois-1, 15);
    return date.toLocaleString(undefined,  { month: 'long' });
  }


  avancerMois(){
    if (this.mois.indexOf(this.numMois) < (this.mois.length-1)){
      this.numMois = this.mois[this.mois.indexOf(this.numMois) + 1];
      const leMois = this.mois.find(m => m == this.numMois);
      this.moisSel.setValue(leMois);
      this.isSaisissable = (this.campagne.annee === this.anneeCourante && (this.numMoisCourant === this.numMois || this.estAnpp));
      this.getEcoulements();
    }
  }
  reculerMois(){
    if (!this.isFirstMonth()){
      this.numMois = this.mois[this.mois.indexOf(this.numMois) - 1];
      const leMois = this.mois.find(m => m == this.numMois);
      this.moisSel.setValue(leMois);
      this.isSaisissable = (this.campagne.annee === this.anneeCourante && (this.numMoisCourant === this.numMois || this.estAnpp));
      this.getEcoulements();
    }
  }

  isFirstMonth() {
    // est-ce le premier mois de la liste
    return (this.mois.indexOf(this.numMois) <= 0);
  }
  isLastMonth() {
    // est-ce le dernier mois de la liste
    return (this.mois.indexOf(this.numMois) >= this.mois.length - 1);
  }

  keyPressNumbers(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < '0'.charCodeAt(0) || charCode > '9'.charCodeAt(0))) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
