import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { remove } from 'lodash';
import { forkJoin } from 'rxjs';
import { RefPorteGreffe } from 'src/app/data/declaration/models/ref-porte-greffe.model';
import { RefPorteGreffeService } from 'src/app/data/declaration/services/parcellaire/ref-porte-greffe.service';

@Component({
  selector: 'app-listes-portes-greffes',
  templateUrl: './listes-portes-greffes.component.html',
  styleUrls: ['./listes-portes-greffes.component.scss']
})
export class ListesPortesGreffesComponent implements OnInit {

  portegreffes : RefPorteGreffe[];

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalsService : AdvBootstrapModalService,
    private readonly translate: TranslateService,
    private readonly portegreffeService : RefPorteGreffeService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
 
    forkJoin(
      this.portegreffeService.getRefPorteGreffe()
      )
    .pipe(this.loaderService.operator())
    .subscribe(([portegreffes]) => {
      this.portegreffes = portegreffes;     
    });
  }

  openConfirmationDialog(portegreffe: RefPorteGreffe) { 

    this.modalsService.confirm(
      'Veuillez confirmer',
      'Voulez vous supprimer ' + this.translate.instant(`label.portegreffe`)+ portegreffe.libelle + ' ?', {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.deletePorteGreffe(portegreffe);
    }, () => { });

  }

  deletePorteGreffe(portegreffe: RefPorteGreffe) {
    this.portegreffeService.desactiverRefPorteGreffe(portegreffe).subscribe(() => {
      remove(this.portegreffes, portegreffe);
    });
  }

  editPortegreffe(portegreffe: RefPorteGreffe) {
    this.modalsService.confirm(
      'Veuillez confirmer',
      this.translate.instant(`page.valeur_parametrable.message_modification`), {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.router.navigate(['edit', portegreffe.id], { relativeTo: this.route });
    }, () => { });
  }
  
}
