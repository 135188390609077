import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, merge, switchMap, tap } from 'rxjs/operators';
import { Operateur } from 'src/app/data/intervenant/models/operateur.model';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';

@Component({
  selector: 'app-search-intervenant-organisme-bar',
  templateUrl: './search-intervenant-organisme-bar.component.html',
  styleUrls: ['./search-intervenant-organisme-bar.component.scss']
})
export class SearchIntervenantOrganismeBarComponent implements OnInit {

  placeholder: string;
  minLength = 2;

  searching = false;
  noResult = false;
  userSearch: any;

  @Input() operateur: Operateur;

  @Output() newItemEvent = new EventEmitter<Operateur>();


  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly operateurService: OperateursService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) { }

  focus$ = new Subject<string>();
  @ViewChild('someInput') searchElement: ElementRef;
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus$),
      tap(() => {
        this.searching = true;
        this.noResult = false;
      }),
      switchMap(term => {
        //if ((term != null) && (term.length >= this.minLength)) {
          return this.operateurService.getOperateursAffilies(term).pipe(
            tap(operateurs => {
              this.noResult = !operateurs || (operateurs.length === 0);
            }),
            catchError(() => {
              return of([]);
            }));
        //} else {
         // return of([]);
        //}
      }),
      tap(() => this.searching = false),
    )

  formatter = (operateurs: Operateur) => `${operateurs.raisonSociale}`;
  
  selectOperateur(event) {
    if (event.item != null) {
      const operateur = event.item as Operateur;
      this.newItemEvent.emit(operateur);
    }
  }

  ngOnInit() { 

    if (this.operateur) {
      this.userSearch = this.operateur;
    } else {
    this.route.queryParams.subscribe(params => {
      this.userSearch = params.search;
      setTimeout(()=>{
       // this.searchElement.nativeElement.focus();
        },100)
    });
  }
  }
}
