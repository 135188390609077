import { Injectable } from '@angular/core';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Prelevement } from '../../models/prelevement.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PrelevementsService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
  ) { }

  /**
   * Ajoute un prélèvement à un échantillon
   * @param idEchantillon L'ID de l'échantillon auquel ajouter le prélèvement
   * @param prelevement Le prélèvement à ajouter
   * @see Prelevement
   */
  patchPrelevement(idEchantillon: number, prelevement: Prelevement): Observable<void> {
    return this.http.patch(
      `/api/declaration/private/echantillons/${idEchantillon}/prelevement`,
      this.mapper.serialize(prelevement)
    ).pipe(
      map(() => { })
    );
  }
}
