import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RefProtectionGel} from '../../models/ref-protection-gel.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RefProtectionGelService {

  constructor( private readonly http: HttpClient) { }

  getRefProtectionGel(): Observable<RefProtectionGel[]> {
    return this.http.get<RefProtectionGel[]>(`/api/declaration/private/referentielProtectionGel`);
  }

  public creerRefProtectionGel(gel: RefProtectionGel): Observable<number> {
    return this.http.post(`/api/declaration/private/referentielProtectionGel`, gel, { observe: 'response' }).pipe(
      map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
    );
  }  
  
  public modifierRefProtectionGel(gel: RefProtectionGel ): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/referentielProtectionGel/${gel.id}`, gel );
  }
  
  public desactiverRefProtectionGel(gel: RefProtectionGel): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/referentielProtectionGel/${gel.id}?desactivation=${true}`, gel );
  }

}
