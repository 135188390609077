import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SuivisService {
  activeTabId: string;


  constructor() { }
  
  setActiveTabId(id: string) {
    this.activeTabId = id;
  }
  getActiveTabId(): string {
    return this.activeTabId;
  }

}
