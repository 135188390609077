<div class="content">
  <div class="content-panel">
    <table class="table">
      <thead>
        <tr>
          <th>SIRET</th>
          <th>CVI</th>
          <th>Raison sociale</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let operateur of operateurs">
          <td>{{ operateur.operateur.siret }}</td>
          <td>{{ getIntervenantCVI(operateur) }}</td>
          <td>{{ operateur.operateur.raisonSociale }}</td>
          <td><fa-icon class="clickable-icon" icon="edit" (click)="consulterIntervenant(operateur)"></fa-icon></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
