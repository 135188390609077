import { CustomConverter, JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { EntrepriseBase } from './entreprise-base.model';
import * as moment from 'moment';
import { Structure } from './structure.model';

@JsonObject
export class Entreprise extends EntrepriseBase {
  
    @JsonProperty('structureJuridique', Structure)
    @Optional
    public structureJuridique: Structure = undefined;

    @JsonProperty('numeroAdherent', String)
    @Optional
    public numeroAdherent: string = undefined;

    @JsonProperty('cotisationAJour', Boolean)
    @Optional
    public cotisationAJour: boolean = undefined;

    @JsonProperty('dateAdhesion', String)
    @CustomConverter(DateConverter)
    @Optional
    dateAdhesion: moment.Moment ;

    @JsonProperty('dateDemission', String)
    @CustomConverter(DateConverter)
    @Optional
    dateDemission: moment.Moment = undefined;

    @JsonProperty('estMorale', Boolean)
    @Optional
    public estMorale: boolean = undefined;

    @JsonProperty('siret', String)
    @Optional
    public siret: string = undefined;

    @JsonProperty('numeroTVA', String)
    @Optional
    public numeroTVA: string = undefined;

    @JsonProperty('adresse', String)
    @Optional
    public adresse: string = undefined;

    @JsonProperty('codePostal', String)
    @Optional
    public codePostal: string = undefined;

    @JsonProperty('commune', String)
    @Optional
    public commune: string = undefined;

    @JsonProperty('departement', String)
    @Optional
    public departement: string = undefined;

    @JsonProperty('numeroReconnaissance', String)
    @Optional
    public numeroReconnaissance: string = undefined;

    @JsonProperty('entrepriseLiee', EntrepriseBase)
    @Optional
    public entrepriseLiee: EntrepriseBase = undefined;

    @JsonProperty('cultureBio', Boolean)
    @Optional
    public cultureBio: boolean = undefined;

    @JsonProperty('codeComptable', String)
    @Optional
    public codeComptable: string = undefined;

    @JsonProperty('commentaire', String)
    @Optional
    public commentaire: string = undefined;
    
    @Optional
    public especesProduites: string = undefined;

    @Optional
    public estEngageeVer: boolean = undefined;

}
