import {JsonObject, JsonProperty, Optional} from '@thorolf/json-ts-mapper';
import {Entreprise} from './entreprise.model';
import {CaracteristiqueParcelle} from './caracteristique-parcelle.model';


@JsonObject
export class Localisation {
  @JsonProperty('id', Number)
  @Optional
  public id: number = undefined;

  @JsonProperty('entreprise', Entreprise)
  @Optional
  public entreprise: Entreprise = undefined;

  @JsonProperty('caracteristiqueParcelle', CaracteristiqueParcelle)
  @Optional
  public caracteristiqueParcelle: CaracteristiqueParcelle = undefined;

  @JsonProperty('departement', String)
  @Optional
  public departement: string = undefined;

  @JsonProperty('codePostal', String)
  @Optional
  public codePostal: string = undefined;

  @JsonProperty('commune', String)
  @Optional
  public commune: string = undefined;

  @JsonProperty('lieuDit', String)
  @Optional
  public lieuDit: string = undefined;

  @JsonProperty('referenceCadastrale', String)
  @Optional
  public referenceCadastrale: string = undefined;

  @JsonProperty('surfaceCadastrale', Number)
  @Optional
  public surfaceCadastrale: number = undefined;

  @JsonProperty('desactive', Boolean)
  desactive: boolean = undefined;

  @JsonProperty('nbBloc', Number)
  @Optional
  public nbBloc: number = undefined;
}
