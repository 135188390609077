<div class="anpp">
  <div class="content">
    <div class="content-panel" *ngIf="groupeVarietal">
      <div class="title-bar">
        <h4>
          {{ title | translate }}
        </h4>
      </div>

      <form *ngIf="formGroup" [formGroup]="formGroup" #form="ngForm" (submit)="submit()" novalidate>

        <div class="m-3">

          <div class="form-group">
            <label for="code" translate>page.valeur_parametrable.form.label.code</label>

            <input type="text" id="code" formControlName="code"
              [ngClass]="{'is-invalid': form.submitted && code.invalid}" class="form-control" />

            <ng-container *ngIf="form.submitted">
              <div *ngIf="code.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>

          <div class="form-group">
            <label for="libelle" translate>page.valeur_parametrable.form.label.libelle</label>

            <input type="text" id="libelle" formControlName="libelle"
              [ngClass]="{'is-invalid': form.submitted && libelle.invalid}" class="form-control" />

            <ng-container *ngIf="form.submitted">
              <div *ngIf="libelle.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>

          <div class="form-group" *ngIf="!creation">
         
            <div class="container">
            <div class="row justify-content-md-center" >

              <div class="col col-lg-5" cdkDropList id="depts-terroir" [cdkDropListConnectedTo]="" [cdkDropListEnterPredicate]="canDropCdc"
                (cdkDropListDropped)="moveVar($event)" [cdkDropListData]="varietes_groupe.value">
            <label for="variete" translate>page.valeur_parametrable.form.label.variete_groupevarietal</label>
                <div *ngFor="let variete of variete_groupe.value" cdkDrag class=" jury-liste-item" [cdkDragData]="variete">
                  <div class="listes-dad-placeholder" *cdkDragPlaceholder></div>
                  <fa-icon cdkDragHandle icon="bars" class="grab" style="font-size:0.8em;"></fa-icon>
                  {{variete.libelle}}
                  <span class="flex-expand"></span>
                  <fa-icon icon="trash" (click)="removeDep(index, variete)" class="pointer jury-liste-delete"></fa-icon>
                </div>
              </div>
              <div class="col-md-auto">
              </div>



              <div class="col col-lg-5" cdkDropList id="depts-libre" [cdkDropListConnectedTo]="" [cdkDropListEnterPredicate]="canDropCdc"
                (cdkDropListDropped)="moveVarLibre($event)" [cdkDropListData]="varietes_libres.value">
                
            <label for="departement" translate>page.valeur_parametrable.form.label.variete_libre</label>
                <div *ngFor="let variete of varietes_libres.value" cdkDrag class="jury-liste-item" [cdkDragData]="variete">
                  <div class="listes-dad-placeholder" *cdkDragPlaceholder></div>
                  <fa-icon cdkDragHandle icon="bars" class="grab" style="font-size:0.8em;"></fa-icon>
                  {{variete.libelle}}
                  <!-- <span class="flex-expand"></span>
            <fa-icon icon="trash" (click)="removeDep(index, dep)" class="pointer jury-liste-delete"></fa-icon> -->
                </div>
              </div>

            </div>
            </div>
          </div>
        </div>

        <div class="actions-bar" fxLayoutAlign="space-between">
          <button class="btn btn-primary" *ngIf="!creation" [routerLink]="'../..'" translate>label.retour</button>
          <button class="btn btn-primary" *ngIf="creation" [routerLink]="'../'" translate>label.retour</button>

          <button type="submit" class="btn btn-primary" translate>{{!creation
            ? 'label.suivant': 'label.valider'}}</button>
        </div>
      </form>
    </div>
  </div>
</div>