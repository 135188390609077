<div class="page-content p-2 anpp">

  <div class="row"><div class="col-lg-12 pl-0 mt-2 pl-4"></div></div>

  <div class="row tableau">
    <div class="col-lg-12">
      <div class="cards border-radius-onglet text-center" style="background:#fff">
        <div class="row">

          <!-- Lien filtrer -->
          <div class="col-lg-3 text-left mt-4">
            <a class="pl-4" (click)="onFilterClick()" translate><fa-icon icon="filter"></fa-icon> label.filtrer</a>
          </div>

          <div class="col-lg-3 text-center">
            <div class="pt-2 m-2 stats-wrapper">
              <a href="" placement="bottom" [ngbTooltip]="'page.suivi.suivi-recoltes.previsions-tooltip' | translate">
                <b>{{ previsionsStat | number:'1.2-2' }}%</b><br />
                <small>{{ 'page.suivi.suivi-recoltes.previsions' | translate }} <fa-icon icon="info-circle"></fa-icon></small>
              </a>
            </div>
          </div>

          <div class="col-lg-3 text-center">
            <div class="pt-2 m-2 stats-wrapper">
              <a href="" placement="bottom" [ngbTooltip]="'page.suivi.suivi-recoltes.recoltes-tooltip' | translate">
                <b>{{ recoltesStat | number:'1.2-2' }}%</b><br />
                <small>{{ 'page.suivi.suivi-recoltes.recoltes' | translate }} <fa-icon icon="info-circle"></fa-icon></small>
              </a>
            </div>
          </div>
            
          <div class="col-lg-3 text-right p-2 pr-4">
            <button 
              type="button" 
              class="btn btn-lg btn-secondary btnprimary" 
              data-toggle="modal" 
              data-target="#estimation"
              (click)="onOpenEstimations()"
            >
              <fa-icon icon="chart-line"></fa-icon> {{ 'page.suivi.suivi-recoltes.estimations' | translate }}
            </button>
          </div>

        </div>

        <!-- Bloc de filtrage -->
        <div *ngIf="showFilters" class="bar1 p-2 filters-bar">
          <form [formGroup]="filtersForm" novalidate>
            <div class="row">
                  
              <div class="col-lg-2 text-center pb-0 m-0">
                <select formControlName="campagne" class="custom-select bar1">
                  <option value="-1" translate>page.suivi.suivi-recoltes.campagne</option>
                  <option *ngFor="let campagne of campagnes$ | async" value="{{ campagne.id }}">
                    {{ campagne.libelle }}
                  </option>
                </select>
              </div>

              <div class="col-lg-2 text-center pb-0 m-0">
                <select formControlName="espece" class="custom-select bar1" (change)="filter()">
                  <option value="-1" translate>page.suivi.suivi-recoltes.especes</option>
                  <option *ngFor="let espece of especes$ | async" value="{{ espece.id }}">
                    {{ espece.libelle }}
                  </option>
                </select>
              </div>

              <div class="col-lg-1 text-center pb-0 m-0">
                <select formControlName="type" class="custom-select bar1" (change)="filter()">
                  <option value="-1" translate>page.suivi.suivi-recoltes.type</option>
                  <option *ngFor="let type of references.sousTypesUtilisateurs" value="{{ type.id }}">
                    {{ type.libelle }}
                  </option>
                </select>
              </div>

              <div class="col-lg-2 text-center pb-0 m-0">
                <select formControlName="terroir" class="custom-select bar1" (change)="filter()">
                  <option value="-1" translate>page.suivi.suivi-recoltes.terroir</option>
                  <option *ngFor="let terroir of references.terroirs" value="{{ terroir.departements }}">
                    {{ terroir.libelle }}
                  </option>
                </select>
              </div>

              <div class="col-lg-1 text-center pb-0 m-0">
                <select formControlName="nbJours" class="custom-select bar1" (change)="filter()">
                  <option value="-1" translate>page.suivi.suivi-recoltes.nb-jours</option>
                  <option *ngFor="let item of nbJours" value="{{ item.code }}">{{ item.libelle | translate }}</option>
                </select>
              </div>

              <div class="col-lg-2 text-center pb-0 m-0">
                <select formControlName="previsions" class="custom-select bar1" (change)="filter()">
                  <option value="-1" translate>page.suivi.suivi-recoltes.previsions</option>
                  <option *ngFor="let etat of etats" value="{{ etat.code }}">{{ etat.libelle | translate }}</option>
                </select>
              </div>

              <div class="col-lg-2 text-center pb-0 m-0">
                <select formControlName="recoltes" class="custom-select bar1" (change)="filter()">
                  <option value="-1" translate>page.suivi.suivi-recoltes.recoltes</option>
                  <option *ngFor="let etat of etats" value="{{ etat.code }}">{{ etat.libelle | translate }}</option>
                </select>
              </div>             
            </div>
          </form>
        </div>
        <div class="tableScroll">
        <table class="table table-striped">      
          <thead class="sticky-top">
            <tr>
              <th scope="col">
                <div class="custom-control custom-checkbox">
                  <input 
                    type="checkbox" 
                    class="custom-control-input" 
                    id="relance" 
                    name="relance"
                    (change)="onCheckAllDeclarations($event)"
                  />
                  <label class="custom-control-label" for="relance"></label>
                </div>
              </th>
              <th scope="col" translate>page.suivi.suivi-recoltes.operations</th>
              <th scope="col" translate>page.suivi.suivi-recoltes.raison-sociale</th>
              <th></th>
              <th translate>page.suivi.suivi-recoltes.surface-ha</th>
              <th colspan="2" translate>page.suivi.suivi-recoltes.prevision-initiale</th>
              <th translate><fa-icon icon="calendar"></fa-icon> page.suivi.suivi-recoltes.derniere-prevision</th> 
              <th translate>page.suivi.suivi-recoltes.delai <a href="#" [ngbTooltip]="'page.suivi.suivi-recoltes.delai-tooltip' | translate"><fa-icon icon="info-circle"></fa-icon></a></th>
              <th translate><fa-icon icon="calendar"></fa-icon> page.suivi.suivi-recoltes.declaration-effective</th>
              <th colspan="2" translate>page.suivi.suivi-recoltes.recolte-effective</th>
            </tr>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th></th>
              <th></th>
              <th><small translate>page.suivi.suivi-recoltes.qteT</small></th>
              <th><small translate>page.suivi.suivi-recoltes.rdt</small></th>
              <th></th>
              <th></th>
              <th></th>
              <th><small translate>page.suivi.suivi-recoltes.qteT</small></th>
              <th><small translate>page.suivi.suivi-recoltes.rdt</small></th>
            </tr>          
          </thead>

          <tbody>
            <tr *ngFor="let declaration of filteredDeclarations; let index = index" [ngClass]="{ 'tr-definitif': declaration.estDefinitive }">
              <td>
                <div class="custom-control custom-checkbox" >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="relance-{{ index }}"
                    name="relance-{{ index }}"
                    [checked]="declaration.estCochee"
                    (change)="onCheckDeclaration(declaration)"
                  />
                  <label class="custom-control-label" for="relance-{{ index }}"></label>
                </div>
              </td>
              <td>
                <div class="btn-group dropright">
                  <div ngbDropdown placement='top-end'>
                    <button 
                      type="button"
                      class="btn btn-secondary dropdown-toggle btnprimary btn-sm"
                      ngbDropdownToggle
                    >
                      <fa-icon icon="cog"></fa-icon> 
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                      <button type="button" class="dropdown-item" ngDropdownItem translate (click)="onGetEmails(declaration.idEntreprise)">
                        <fa-icon icon="user"></fa-icon> page.suivi.suivi-recoltes.contacts-referents
                      </button>
                      <div class="dropdown-divider"></div>
                      <button type="button" class="dropdown-item" ngDropdownItem translate (click)="onGoToPrevisionRecolte(declaration.idEntreprise)">
                        <fa-icon icon="file-alt"></fa-icon> page.suivi.suivi-recoltes.declaration-recolte
                      </button>
                      <div class="dropdown-divider"></div>
                      <button type="button" class="dropdown-item" ngDropdownItem translate (click)="onRelance(declaration.idEntreprise)">
                        <fa-icon icon="envelope"></fa-icon> page.suivi.suivi-recoltes.relance
                      </button>
                    </div>
                  </div>
                </div>	
              </td>
              <td>{{ getLibelleEntreprise(declaration.idEntreprise) }}</td>
              <td>
                <span *ngIf="declaration.recolteSuivi.codeEspece==='POMME'">
                  <fa-icon icon='apple-alt' style="color:black;"></fa-icon>
                </span>
                <span *ngIf="declaration.recolteSuivi.codeEspece==='POIRE'">
                  <img src="/assets/img/pear.png" width="16px" />
                </span>
              </td>   
              <ng-container [ngTemplateOutlet]="cellule" [ngTemplateOutletContext]="{ 
                displayValue: declaration.recolteSuivi.surfaceTotale,
                testValue: declaration.recolteSuivi.surfaceTotale,
                valueType: 'number-3'
              }"></ng-container>
              <ng-container [ngTemplateOutlet]="cellule" [ngTemplateOutletContext]="{ 
                displayValue: declaration.recolteSuivi.previsionInitialeTotale,
                testValue: declaration.recolteSuivi.previsionInitialeTotale,
                valueType: 'number'
              }"></ng-container>
              <ng-container [ngTemplateOutlet]="cellule" [ngTemplateOutletContext]="{ 
                displayValue: declaration.recolteSuivi.getRdtInitialTotal(),
                testValue: declaration.recolteSuivi.getRdtInitialTotal(),
                valueType: 'number'
              }"></ng-container>
              <ng-container [ngTemplateOutlet]="cellule" [ngTemplateOutletContext]="{ 
                displayValue: declaration.recolteSuivi.datePrevisionActuelle,
                testValue: declaration.recolteSuivi.datePrevisionActuelle,
                valueType: 'date'
              }"></ng-container>
              <ng-container [ngTemplateOutlet]="cellule" [ngTemplateOutletContext]="{ 
                displayValue: declaration.delai,
                testValue: declaration.delai,
                valueType: 'string'
              }"></ng-container>
              <ng-container [ngTemplateOutlet]="cellule" [ngTemplateOutletContext]="{ 
                displayValue: declaration.recolteSuivi.dateSaisieRecolte,
                testValue: declaration.recolteSuivi.dateSaisieRecolte,
                valueType: 'date'
              }"></ng-container>
              <ng-container [ngTemplateOutlet]="cellule" [ngTemplateOutletContext]="{ 
                displayValue: declaration.recolteSuivi.getQteTotaleSuiviRecolte(declaration.estDefinitive),
                testValue: declaration.recolteSuivi.getQteTotaleSuiviRecolte(declaration.estDefinitive),
                valueType: 'number'
              }"></ng-container>
              <ng-container [ngTemplateOutlet]="cellule" [ngTemplateOutletContext]="{ 
                displayValue: declaration.recolteSuivi.getRdtTotalSuiviRecolte(declaration.estDefinitive),
                testValue: declaration.recolteSuivi.getRdtTotalSuiviRecolte(declaration.estDefinitive),
                valueType: 'number'
              }"></ng-container>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>
  </div>

  <!-- Bouton de relance -->    
  <div class="btn-relance">
    <div class="row">
      <div class="col-lg-8 pt-2 pb-2">
        <button
          type="button"
          class="btn btn-secondary btnprimary"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          (click)="onRelanceGroupee()"
          translate
        >
          <fa-icon icon="envelope"></fa-icon> {{ 'page.suivi.suivi-recoltes.relance' | translate }}
        </button>
      </div>
    </div>
  </div>
        
</div>

<ng-template #cellule let-displayValue="displayValue" let-testValue="testValue" let-valueType="valueType">
  <td *ngIf="!testValue" class="tr-rouge">-</td>
  <td *ngIf="testValue && valueType === 'string'">{{ displayValue }}</td>
  <td *ngIf="testValue && valueType === 'date'">{{ displayValue | date:'shortDate' }}</td>
  <td *ngIf="testValue && valueType === 'number'">{{ displayValue | number:'1.2-2' }}</td>
  <td *ngIf="testValue && valueType === 'number-3'">{{ displayValue | number:'1.3-3' }}</td>
</ng-template>