import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AdvBootstrapLoaderService} from '@adv/bootstrap-loader';
import {UniteCulturale} from '../../../../../../../data/declaration/models/unite-culturale.model';
import {Variete} from '../../../../../../../data/declaration/models/variete.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {VarieteService} from '../../../../../../../data/declaration/services/parcellaire/variete.service';
import {AdvBootstrapModalService} from '@adv/bootstrap-modal';
import {TranslateService} from '@ngx-translate/core';
import {
  UniteCulturaleService
} from '../../../../../../../data/declaration/services/parcellaire/unite-culturale.service';
import {Surgreffage} from '../../../../../../../data/declaration/models/surgreffage.model';

@Component({
  selector: 'app-surgreffage',
  templateUrl: './surgreffage.component.html',
  styleUrls: ['./surgreffage.component.scss']
})
export class SurgreffageComponent implements OnInit {

  constructor(public readonly modal: NgbActiveModal,
              private readonly loaderService: AdvBootstrapLoaderService,
              private readonly fb: FormBuilder,
              private readonly modalsService: AdvBootstrapModalService,
              private readonly uniteCulturaleService: UniteCulturaleService,
              private readonly varieteService: VarieteService,
              private readonly translate: TranslateService) {
  }

  formGroup: FormGroup;
  @Input() uniteCulturale: UniteCulturale;
  @Input() varietes: Variete[];
  @Input() varietesClone: Variete[];
  surface: number;
  @Input() surgreffagePartielBool: boolean;

  get variete() {
    return this.formGroup.get('variete');
  }

  get clone() {
    return this.formGroup.get('clone');
  }

  get nbArbre() {
    return this.formGroup.get('nbArbre');
  }

  get anneeSurgreffage() {
    return this.formGroup.get('anneeSurgreffage');
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      variete: [undefined, Validators.required],
      clone: [undefined],
      anneeSurgreffage: [undefined, Validators.required]
    });
    if (this.surgreffagePartielBool) {
      this.formGroup.addControl('nbArbre', new FormControl(undefined, Validators.required));
    }
    this.varieteService.getVarietesEspece(this.uniteCulturale.espece.id).pipe(this.loaderService.operator()).subscribe((varietes) => {
      this.varietes = varietes;
      this.varietes.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
    });
  }

  cancel() {
    this.modal.dismiss();
  }

  calculeSurface() {
    this.surface = ((this.uniteCulturale.distanceInterRang * this.uniteCulturale.distanceIntraRang) * this.nbArbre.value) / 10000;
  }

  submit() {
    if (this.nbArbre && this.nbArbre.value === 0) {
      this.modalsService.error(this.translate.instant(`page.inventaire-verger.modals.arrachage.erreurNbArbres0`), null , {windowClass: 'anpp'}).then(() => {
      });
    } else if (this.formGroup.valid) {
      const surgreffage = new Surgreffage();
      if (this.clone.value) {
        surgreffage.variete = this.clone.value.id;
      } else {
        surgreffage.variete = this.variete.value.id;
      }
      surgreffage.anneeSurgreffage = this.anneeSurgreffage.value;
      if (this.surgreffagePartielBool) {
        surgreffage.nbArbre = this.nbArbre.value;
        this.uniteCulturaleService.surgreffageTotalPartiel(this.uniteCulturale.id, this.surgreffagePartielBool, surgreffage).subscribe(() => {
          this.modal.close();
        });
      } else if (!this.surgreffagePartielBool) {
        this.uniteCulturaleService.surgreffageTotalPartiel(this.uniteCulturale.id, this.surgreffagePartielBool, surgreffage).subscribe(() => {
          this.modal.close();
        });
      }

    }

  }

  changeValueVariete(): void {
    this.varieteService.getClonesByVariete(this.variete.value.id).pipe(this.loaderService.operator()).subscribe((clones) => {
      this.varietesClone = clones;
      this.varietesClone.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
    });
  }

  controleNbArbreMaxMin() {
    if (this.nbArbre.value === 0) {
      this.modalsService.error(this.translate.instant(`page.inventaire-verger.modals.arrachage.erreurNbArbres0`), null , {windowClass: 'anpp'}).then(() => {
      });
    }
    if (this.nbArbre.value > this.uniteCulturale.nbArbre) {
      this.modalsService.error(this.translate.instant(`page.inventaire-verger.modals.arrachage.erreurNbArbres`) + this.uniteCulturale.nbArbre + '.', null , {windowClass: 'anpp'}).then(() => {
        this.nbArbre.setValue(0);
        this.calculeSurface();
      });
    } else {
      this.calculeSurface();
    }

  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < '0'.charCodeAt(0) || charCode > '9'.charCodeAt(0))) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
