import { CommissionEdition } from './commission-edition.model';

export class CommissionEditionMail {
    commissionEdition: CommissionEdition;
    mailsJures: string[];

    constructor(commissionEdition: CommissionEdition, mailsJures: string[]) {
        this.commissionEdition = commissionEdition;
        this.mailsJures = mailsJures;
    }
}
