import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {OperateursService} from '../../../../../../data/intervenant/services/operateurs/operateurs.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DomaineSpec} from '../../../../../../data/intervenant/models/domaine-spec.model';
import {Pays} from '../../../../../../data/intervenant/models/pays.model';

@Component({
  selector: 'app-add-operateur',
  templateUrl: './add-operateur.component.html',
  styleUrls: ['./add-operateur.component.scss']
})
export class AddOperateurComponent implements OnInit {
  formCreationOperateur: FormGroup;
  domaineSpecs: DomaineSpec[] = [];
  refPays: Pays[];

  get siret() {
    return this.formCreationOperateur.get('siret');
  }

  get email() {
    return this.formCreationOperateur.get('email');
  }

  constructor(private operateurService: OperateursService,
              private readonly fb: FormBuilder,
              private readonly modal: NgbActiveModal) {
    this.initForm();
  }

  ngOnInit() {
    this.loadDomaineSpecs();
  }

  initForm() {
    this.loadPays();
    this.formCreationOperateur = this.fb.group({
      structureJuridique: [''],
      raisonSociale: ['', Validators.required],
      siret: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(14)]],
      tvaIntracommunautaire: [''],
      email: ['', [Validators.required, this.singleEmailValidator]],
      nom: [''],
      prenom: [''],
      telFixe: [''],
      telMobile: [''],
      fax: [''],
      adresse: [''],
      codePostal: [''],
      ville: [''],
      cedex: [''],
      pays: [''],
      domaineSpecs: this.fb.group({})
    });
  }
  loadPays() {
    this.operateurService.getPays().subscribe(
      (pays: Pays[]) => {
        this.refPays = pays;
      },
      error => {
        console.error('Erreur détaillée lors du chargement des pays:', error);
      }
    );
  }
  private loadDomaineSpecs() {
    this.operateurService.getAllInformationsDomaine().subscribe(
      specs => {
        this.domaineSpecs = specs;
        this.addDomaineSpecsToForm();
      },
      error => console.error('Erreur lors du chargement des spécifications de domaine', error)
    );
  }

  private addDomaineSpecsToForm() {
    const domaineSpecsGroup = this.formCreationOperateur.get('domaineSpecs') as FormGroup;
    this.domaineSpecs.forEach(spec => {
      const control = this.fb.control('');
      if (spec.code === 'VIN_CVI') {
        control.setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10)]);
      }
      domaineSpecsGroup.addControl(spec.code, control);
    });
  }

  private formatPhoneNumberBeforeSubmit(phoneNumber: string | undefined): string {
    return phoneNumber ? phoneNumber.replace(/\D/g, '') : '';
  }
  onSubmit() {
    if (this.formCreationOperateur.valid) {
      // Logic to handle form submission
      const formData = this.formCreationOperateur.value;

      // Formater les numéros de téléphone
      formData.telephoneFixe = this.formatPhoneNumberBeforeSubmit(formData.telFixe);
      formData.telephoneMobile = this.formatPhoneNumberBeforeSubmit(formData.telMobile);
      formData.fax = this.formatPhoneNumberBeforeSubmit(formData.fax);

      // Préparer les données des domaines spécifiques
      const domaineSpecsData = formData.domaineSpecs;

      // Créer l'objet à envoyer
      const newOperateur = {
        ...formData,
        idPays: formData.pays,
        refType: 3, // Assurez-vous d'inclure refType avec l'ID 3
        intervenantDomaineSpecs: Object.keys(domaineSpecsData).map(code => ({
          code: this.domaineSpecs.find(spec => spec.code === code).code,
          valeur: domaineSpecsData[code]
        }))
      };

      // Supprimer les propriétés non nécessaires
      delete newOperateur.domaineSpecs;
      delete newOperateur.telFixe;
      delete newOperateur.telMobile;
      delete newOperateur.pays;
      // Modification
      this.operateurService.createOperateur(newOperateur).subscribe(
        result => {
          this.modal.close('operateur_created');
        },
        error => {
          console.error('Erreur lors de la creation de l\'opérateur', error);
        }
      );
    }
  }

  formatPhoneNumber(controlName: string) {
    const control = this.formCreationOperateur.get(controlName);
    if (control) {
      const value = control.value;
      const formattedValue = this.formatNumber(value);
      control.setValue(formattedValue, {emitEvent: false});
    }
  }

  // Méthode pour formater les numéros de téléphones et de fax pour plus de lisibilité (XX XX XX XX XX)
  private formatNumber(value: string): string {
    if (!value) {
      return value;
    }
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 2) {
      return phoneNumber;
    }
    if (phoneNumberLength < 4) {
      return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2)}`;
    }
    if (phoneNumberLength < 6) {
      return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 4)} ${phoneNumber.slice(4)}`;
    }
    // tslint:disable-next-line:max-line-length
    if (phoneNumberLength < 8) {
      return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 4)} ${phoneNumber.slice(4, 6)} ${phoneNumber.slice(6)}`;
    }
    // tslint:disable-next-line:max-line-length
    return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 4)} ${phoneNumber.slice(4, 6)} ${phoneNumber.slice(6, 8)} ${phoneNumber.slice(8, 10)}`;
  }

  // Fonction de validation pour une seule adresse email
  singleEmailValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return null;
    }

    // Vérifiez s'il y a plusieurs adresses e-mail en utilisant une regex simple pour détecter les séparateurs courants
    const emails = value.split(/[\s,;]+/);
    if (emails.length > 1) {
      return {multipleEmails: true};
    }

    // Vérifiez si l'adresse e-mail est valide
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      return {invalidEmail: true};
    }

    return null;
  }

  onEmailBlur() {
    this.email.markAsTouched();
  }

  onEmailInput() {
    this.email.updateValueAndValidity();
  }

  onSiretBlur() {
    this.siret.markAsTouched();
  }
  onCviBlur(code: string) {
    const control = this.formCreationOperateur.get('domaineSpecs').get(code);
    if (control) {
      control.markAsTouched();
    }
  }

  onClose() {
    this.modal.dismiss();
  }

}
