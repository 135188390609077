
  
  
  <!-- Div de contenu de la fenêtre surgissante -->
  <div class="modal-body">
  <div class="pointer">
      <fa-icon icon="times" (click)="onClose()"></fa-icon>
    </div>  
    <div >
      <div class="panel">
       
        <div class="flex" *ngIf="operateur && infosDomaine">
          <div>
            <div *ngIf="operateur.structureJuridique"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.structureJuridique</label>{{operateur.structureJuridique}}</div>
            <div *ngIf="operateur.raisonSociale"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.raisonSociale</label>{{operateur.raisonSociale}}</div>
            <div *ngIf="operateur.siret"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.siret</label>{{operateur.siret}}</div>
    
            <div *ngIf="operateur.tvaIntracommunautaire"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.tvaIntracommunautaire</label>{{operateur.tvaIntracommunautaire}}</div>
            <div *ngFor="let info of infosDomaine"><label class="entreprise-label">{{info.libelle}}</label>{{info.valeur}}</div>
          </div>
          <div>
            <div *ngIf="operateur.nom"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.nom</label>{{operateur.nom}}</div>
            <div *ngIf="operateur.prenom"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.prenom</label>{{operateur.prenom}}</div>
            <div *ngIf="operateur.adresse"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.adresse</label>{{operateur.adresse}}</div>
            <div *ngIf="operateur.codePostal"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.codePostal</label>{{operateur.codePostal}}</div>
            <div *ngIf="operateur.ville"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.ville</label>{{operateur.ville}}</div>
            <div *ngIf="operateur.cedex"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.cedex</label>{{operateur.cedex}}</div>
            <div *ngIf="operateur.pays"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.pays</label>{{operateur.pays?.libelle}}</div>
            <div *ngIf="operateur.email"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.email</label>{{operateur.email}}</div>
            <div *ngIf="operateur.telephoneFixe"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.telephoneFixe</label>{{operateur.telephoneFixe | phoneFormat}}</div>
            <div *ngIf="operateur.telephoneMobile"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.telephoneMobile</label>{{operateur.telephoneMobile | phoneFormat}}</div>
            <div *ngIf="operateur.fax"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.fax</label>{{operateur.fax| phoneFormat}}</div>
          </div>
        </div>
      </div>
    
      <!-- <div class="content-panel">
        <h2 class="section-title" translate>page.operateurs.informations.sites.title</h2>
        <div class="liste-sites-container">
          <table class="table liste-sites" *ngIf="sites">
            <thead>
              <tr>
                <th translate>page.operateurs.informations.sites.title</th>
                <th translate>page.operateurs.informations.sites.table.header.adresse</th>
                <th translate>page.operateurs.informations.sites.table.header.responsable</th>
                <th translate>page.operateurs.informations.sites.table.header.activites</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let site of sites; let idx = index">
                  <td>{{idx+1}}</td>
                  <td>{{site.adresse}}</td>
                  <td>{{site.responsable}}</td>
                  <td>
                    <div *ngFor="let activite of site.siteActivites">
                      {{activite.libelle}}
                    </div>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->

        <div class="panel">
          <div class="flex" >
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  <span translate>page.common.habilitations.demarches</span>                 
                </th>
                <th *ngFor="let activite of activitesFiltered" scope="col" t-id="habiliations-activites">{{activite.libelle}}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="cahiersFiltered && (cahiersFiltered.length > 0); else noHabilitation">
                <tr *ngFor="let cahier of cahiersFiltered">
                  <th scope="row" t-id="habiliations-cahiers">{{cahier.libelle}}</th>
                  <td *ngFor="let activite of activitesFiltered">
                    <ng-container *ngTemplateOutlet="habilitationTemplate;context:{cahier: cahier, activite: activite, habilitation: getHabilitation(cahier, activite)}">
                    </ng-container>
                  </td>
                </tr>
              </ng-container>
      
              <ng-template #noHabilitation>
                <tr>
                  <td [attr.colspan]="activitesFiltered ? (activitesFiltered.length + 1) : 0">
                    <div translate>page.operateurs.habilitations.vide</div>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
        </div>
    </div>   
    
  </div>


<ng-template #habilitationTemplate let-habilitation="habilitation" let-cahier="cahier" let-activite="activite">
        <div *ngIf="habilitation">
          <app-statut-badge 
            [statut]="habilitation.statut" class="pointer"></app-statut-badge>
        </div>
      </ng-template>
