import { JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";
import { ControleEdition } from "./controle-edition.model";

@JsonObject
export class ControleEditionList {
    

    @JsonProperty('controles', ControleEdition)
    @Optional
    controles: ControleEdition[];

    @JsonProperty('controles_conformes', ControleEdition)
    @Optional
    controlesConformes: ControleEdition[];
}