import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class HistogrammeStock {
  @JsonProperty('numMois', Number)
  @Optional
  numMois: number = undefined;

  @JsonProperty('quantite', Number)
  @Optional
  quantite: number = undefined;
}
