<div class="modal-content anpp">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel"><fa-icon icon="upload"></fa-icon> <b translate> page.inventaire-verger.modals.exporterInventaire.exporterInventaireTitle</b></h5>
    <button (click)="cancel()" type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="formGroup" #form="ngForm" (ngSubmit)="submit()" novalidate>
      <div class="form-group">
        <p> Type de document :</p>
        <div class="row">
          <div class="col-lg-12">
            <input required [ngClass]="{'is-invalid': form.submitted}" type="radio" class="mr-2 style-radio-buttton" id="pdf" formControlName="type" value="pdf" />
            <label for="pdf" class="mr-5" translate style="vertical-align: top;">Synthèse pdf</label>
            <input required [ngClass]="{'is-invalid': form.submitted}" type="radio" class="mr-2 style-radio-buttton" id="csv" formControlName="type" value="csv" />
            <label for="csv" class="mr-5" translate style="vertical-align: top;">Inventaire csv</label>
          </div>
        </div>
        <p>Campagne : </p>
        <ng-container *ngFor="let campagne of refCampagnes">
          <div class="row">
            <div class="col-lg-12">
              <input required [ngClass]="{'is-invalid': form.submitted}" type="radio" class="mr-2 style-radio-buttton" id="{{'campagne' + campagne.id}}" formControlName="campagne" value="{{ campagne.id }}" />
              <label for="{{'campagne' + campagne.id}}" class="mr-5" translate style="vertical-align: top;">{{ campagne.libelle }}</label>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" data-dismiss="modal" aria-label="Close">Exporter
        </button>
      </div>
    </form>
  </div>
</div>
