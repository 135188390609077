import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, pipe, throwError } from 'rxjs';
import { SuiviInventaireVergerModel } from '../../models/suivi-inventaire-verger.model';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { UtilisateursService } from '../../../intervenant/services/utilisateurs/utilisateurs.service';
import { EntreprisesService } from '../../../intervenant/services/entreprises/entreprises.service';
import { Structure } from '../../../intervenant/models/structure.model';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SuiviInventaireVergerService {
refSousType:Structure[];

  constructor(protected readonly http: HttpClient, protected readonly mapper: JsonMapperService,
    protected readonly utilisateurService: UtilisateursService,
    protected readonly entrepriseService: EntreprisesService,
    ) { }

  getSuivisInventairesVergers(idEntreprise: number): Observable<SuiviInventaireVergerModel[]> {
    let uri = `/api/declaration/private/inventaire/suivi?id=`;
    if (idEntreprise){
      uri += idEntreprise; 
    }
    return this.http.get<SuiviInventaireVergerModel[]>(uri);

      
  }
}
