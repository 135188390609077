<div class="content" > 
  <div class="vin" > 
    

    <ngb-tabset class="mt-2" #tabSet="ngbTabset" style="min-height: unset">
      <ngb-tab *ngFor="let tab of tabs" [title]="tab.title">
        <ng-template ngbTabContent>
          <ng-container *ngComponentOutlet="tab.component"></ng-container>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>

    <router-outlet></router-outlet>
</div>
</div>