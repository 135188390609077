import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { DateConverter } from 'src/app/core/services/mapper/converters';

@JsonObject
export class Recours {

    @JsonProperty('dateRecours', String)
    @CustomConverter(DateConverter)
    @Optional
    dateRecours: moment.Moment;

    @JsonProperty('dateNouvelleExpertise', String)
    @CustomConverter(DateConverter)
    @Optional
    dateNouvelleExpertise: moment.Moment;

    @JsonProperty('nouveauPrelevement', Boolean)
    @Optional
    nouveauPrelevement: Boolean;

    @JsonProperty('idCommission', Number)
    @Optional
    idCommission: number;
}
