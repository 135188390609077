import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { EngagementBase } from 'src/app/data/habilitation/models/engagements/engagement-base';
import { RefStatutEngagement } from 'src/app/data/habilitation/models/engagements/ref-statut-engagement';
import { EngagementVergersEcoService } from 'src/app/data/habilitation/services/engagements/engagement-vergers-eco.service';

@Component({
  selector: 'app-changement-statut',
  templateUrl: './changement-statut.component.html',
  styleUrls: ['./changement-statut.component.scss']
})
export class ChangementStatutComponent implements OnInit {

  formGroup: FormGroup;
  @Input() public engagement: EngagementBase;
  statuts: RefStatutEngagement[];
  get statut() { return this.formGroup.get('statut'); }
  get dateAudit() { return this.formGroup.get('dateAudit'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly modal: NgbActiveModal,
    private readonly engagementVergersEcoService: EngagementVergersEcoService) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      statut: [undefined, Validators.required],
      dateAudit: [undefined]
    });
    if(this.engagement) {
        this.engagementVergersEcoService.getRefStatutEngagementSuivant(this.engagement.idStatut).subscribe(statuts => {
          this.statuts = statuts;
        });
    }
  }

  cancel() {
    this.modal.dismiss();
  }

  submit() {
    if (this.formGroup.valid) {
      let statut: RefStatutEngagement = this.statut.value;
      this.engagement.idStatut = statut.id;
      this.engagement.libelleStatut = statut.libelle;
      this.engagement.codeStatut = statut.code;
      if (this.dateAudit.value != null) {
        this.engagement.dateAudit = moment([
          this.dateAudit.value.year,
          this.dateAudit.value.month - 1,
          this.dateAudit.value.day
        ]);
      }
    
      this.engagementVergersEcoService.modifierStatutEngagementVergersEco(this.engagement).subscribe(retour => {
        this.modal.close();
      });
    }
  }
}
