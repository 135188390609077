import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { DateConverter } from 'src/app/core/services/mapper/converters';

@JsonObject
/**
 * Id, Code Libelle
 **/
class Icl {
  @JsonProperty('id', Number)
  id: number;

  @JsonProperty('code', String)
  code: string;

  @JsonProperty('libelle', String)
  libelle: string;
}

@JsonObject
/**
 * Id, actif, datedebut,
 **/
class Iad {
  @JsonProperty('id', Number)
  @Optional
  id: number;

  @JsonProperty('actif', Boolean)
  actif: boolean;

  @JsonProperty('dateDebut', String)
  @Optional
  @CustomConverter(DateConverter)
  dateDebut: moment.Moment;
}

@JsonObject
/**
 * Id, Libelle, actif, datedebut,
 **/
class Ilad extends Iad {
  @JsonProperty('libelle', String)
  @Optional
  libelle: string;

}

@JsonObject
/**
 * Id, code, Libelle, actif, datedebut,
 **/
class Iclad extends Ilad {
  @JsonProperty('code', String)
  @Optional
  code: string;
}

@JsonObject
class Intervenant extends Iad {
  @JsonProperty('mail', String)
  mail: string;
}

@JsonObject
class Service extends Icl {
  @JsonProperty('libelleCourt', String)
  libelleCourt: string;
}

@JsonObject
class Fonctionnel extends Icl {
  @JsonProperty('roles', [Icl])
  roles: Icl[];
}

@JsonObject
class Organisme extends Iad {
  @JsonProperty('raisonSociale', String)
  raisonSociale: string;
}


@JsonObject
class Profil {
  @JsonProperty('domaine', Iclad)
  @Optional
  domaine: Iclad = new Iclad();

  @JsonProperty('intervenant', Intervenant)
  @Optional
  intervenant: Intervenant = new Intervenant();

  @JsonProperty('profil', Ilad)
  @Optional
  profil: Ilad = new Ilad();

  @JsonProperty('service', Service)
  @Optional
  service: Service = new Service();

  @JsonProperty('type', Iclad)
  @Optional
  type: Iclad = new Iclad();

  @JsonProperty('organisme', Organisme)
  @Optional
  organisme: Organisme = new Organisme();
}

@JsonObject
export class UtilisateurDroit {

  @JsonProperty('fonctionnels', [Fonctionnel])
  fonctionnels: Fonctionnel[];

  @JsonProperty('profil', Profil)
  profil: Profil;
}
