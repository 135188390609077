import { JsonProperty, JsonObject } from '@thorolf/json-ts-mapper';

@JsonObject
export class DashBoardSurface {

  @JsonProperty('idEspece', Number)
  idEspece: number;

  @JsonProperty('codeEspece', String)
  codeEspece: string;

  @JsonProperty('surface', Number)
  surface: number;

}
