<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
    page.declarations.synthese.modal.saisir-revendication.title
    </h4>
  </div>

  <div class="modal-body">
    <!-- Formulaire de saisie d'une revendication -->
    <form [formGroup]="formGroup" #form="ngForm" (ngSubmit)="onSubmit(form)" novalidate>
      <div class="cols">
        <!-- Colonne de gauche -->
        <div class="col">
          <div class="form-group">
            <label for="cahier" translate>page.declarations.synthese.modal.saisir-revendication.cahier</label>
            <select formControlName="cahier" class="form-control" (change)="loadProducts()" [ngClass]="{'is-invalid': form.submitted && form_cahier.invalid}">
              <option *ngFor="let cahier of cahiers" [ngValue]="cahier">{{ cahier?.libelle }}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="produits" translate>page.declarations.synthese.modal.saisir-revendication.produit</label>
            <select formControlName="produits" class="form-control" [ngClass]="{'is-invalid': form.submitted && form_produits.invalid}">
              <option *ngFor="let produit of produits" [ngValue]="produit">{{ produit.libelle }}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="date" translate>page.declarations.synthese.modal.saisir-revendication.date</label>
            <div class="input-group">
              <input type="text" formControlName="date" class="form-control" ngbDatepicker #datePicker="ngbDatepicker" [ngClass]="{'is-invalid': form.submitted && form_date.invalid}" />
              <div class="input-group-append">
                <button type="button" (click)="datePicker.toggle()" class="btn btn-secondary">
                  <fa-icon icon="calendar-alt"></fa-icon>
                </button>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="entreposage" translate>page.declarations.synthese.modal.saisir-revendication.entreposage</label>
            <select formControlName="entreposage" class="form-control" [ngClass]="{'is-invalid': form.submitted && form_entreposage.invalid, 'select-placeholder' : (form_entreposage.value === null && !form_entreposage.dirty)}">
              <option [ngValue]="null" hidden translate>page.declarations.synthese.modal.saisir-revendication.liste-sites</option>
              <option *ngFor="let site of sites" [ngValue]="site">{{ site?.adresse }}</option>
            </select>
          </div>
          <hr />

          <div class="form-group">
            <label for="select-cepages" translate>page.declarations.synthese.modal.saisir-revendication.cepages</label>
            <app-multiselect id="select-cepages" maxWidth="initial" bindLabel="libelle" controlName="cepages" [items]="cepages" [ngClass]="{'is-invalid': form.submitted && form_cepages.invalid}"></app-multiselect>
          </div>

          <div class="form-group">
            <label for="logement" translate>page.declarations.synthese.modal.saisir-revendication.logement</label>
            <textarea formControlName="logement" class="form-control" [ngClass]="{'is-invalid': form.submitted && form_logement.invalid}"></textarea>
          </div>
        </div>
        <!-- Fin colonne de gauche -->

        <!-- Colonne de droite -->
        <div class="col">
          <div class="form-group">
            <label for="lot" translate>page.declarations.synthese.modal.saisir-revendication.numeroLot</label>
            <input type="text" formControlName="lot" class="form-control" [ngClass]="{'is-invalid': form.submitted && form_lot.invalid}" />
          </div>

          <div class="form-group">
            <label for="degre" translate>page.declarations.synthese.modal.saisir-revendication.degre</label>
            <input type="number" formControlName="degre" class="form-control" lang="en" [ngClass]="{'is-invalid': form.submitted && form_degre.invalid}" />
          </div>

          <div class="form-group">
            <label for="volume" translate>page.declarations.synthese.modal.saisir-revendication.volume</label>
            <input type="number" formControlName="volume" class="form-control" lang="en" [ngClass]="{'is-invalid': form.submitted && form_volume.invalid}" />
          </div>
          <hr />

          <div class="form-group">
            <label for="select-destinations" translate>page.declarations.synthese.modal.saisir-revendication.destinations</label>
            <app-multiselect id="select-destinations" maxWidth="initial" bindLabel="libelle" controlName="destinations" [items]="destinations" [ngClass]="{'is-invalid': form.submitted && form_destinations.invalid}"></app-multiselect>
          </div>
          <hr />

          <div class="form-group">
            <label for="observation" translate>page.declarations.synthese.modal.saisir-revendication.observation</label>
            <textarea formControlName="observation" class="form-control" [ngClass]="{'is-invalid': form.submitted && form_observation.invalid}"></textarea>
          </div>
        </div>
        <!-- Fin colonne de droite -->
      </div>

      <!-- Boutons d'action -->
      <div class="actions-bar">
        <button type="button" class="btn btn-light" (click)="onClose()" translate>label.annuler</button>
        <button type="button" class="btn btn-primary" 
          (click)="onPrevious()" 
          [disabled]="!(lotRevendications?.revendications?.length > 0) || (indexModification > -1)"
          translate>
          label.precedent
        </button>
        <button type="submit" class="btn btn-primary" translate>label.suivant</button>
        <button type="submit" class="btn btn-primary"
          (click)="onTerminer()"  
          [disabled]="!formGroup.valid"
          translate>
          label.terminer
        </button>
      </div>
      <!-- Fin boutons d'action -->
    </form>
    <!-- Fin formulaire de saisie d'une revendication -->
  </div>
</div>
