import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { MouvementsProduitsService } from 'src/app/data/declaration/services/mouvements/mouvements-produits.service';
import { HistoriqueMouvement } from 'src/app/data/declaration/models/historique-mouvement.model';
import { Referentiel } from 'src/app/data/declaration/models/referentiel.model';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { forkJoin } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CategorieEnum } from 'src/app/data/edition/models/enums/categorie.enum.model';
import { TelechargerDocumentComponent } from '../main/components/telecharger-document/telecharger-document.component';
import { DetailControleComponent } from './modal/detail-controle/detail-controle.component';

@Component({
  selector: 'app-suivi-declarations',
  templateUrl: './suivi-declarations.component.html',
  styleUrls: ['./suivi-declarations.component.scss']
})
export class SuiviDeclarationsComponent implements OnInit {
  static routes: Routes = [{
    path: '',
    redirectTo: 'liste',
    pathMatch: 'full',
    data: {
      role: 'ORGANISME',
      menu: { icon: 'wine-bottle', libelle: 'menu.suivi-declarations', hide: true }
    }
  }, {
    path: 'liste',
    component: SuiviDeclarationsComponent
  }];
  public historiques: HistoriqueMouvement[];
  public referentiel: Referentiel;
  public searchForm: FormGroup;

  
  get debutDate() { return this.searchForm.get('debutDate'); }
  get finDate() { return this.searchForm.get('finDate'); }
  get annee() { return this.searchForm.get('annee'); }
  get non_controlable() { return this.searchForm.get('non_controlable'); }
  get raisonSociale() { return this.searchForm.get('raisonSociale'); }
  get suivi() { return this.searchForm.get('suivi'); }

  
  constructor(
    private readonly loader: AdvBootstrapLoaderService,
    private readonly mouvementsProduitsService: MouvementsProduitsService,
    private readonly fb: FormBuilder,
    private readonly referentielService: ReferentielService,
    private readonly modalService: NgbModal
  ) { }

  ngOnInit() {

// Instancier le formulaire de recherche
  this.searchForm = this.fb.group({
    raisonSociale: undefined,
    annee: new Date().getFullYear(),
    non_controlable: undefined,
    debutDate: undefined,
    finDate: undefined,
    suivi: false
  });

    this.loadData();
  }

  /** Charge la liste des mouvements non lus et le référentiel */
  private loadData() {
    forkJoin(
      this.mouvementsProduitsService.getSuiviMouvement('', new Date().getFullYear().toString(),'',null,null,'' ),
      this.referentielService.getReferentiel()
    ).pipe(this.loader.operator()).subscribe(
      ([historiques, referentiel]) => {
        this.historiques = historiques;
        this.referentiel = referentiel;
      });
  }

  /**
   * Retourne le type d'un mouvement en fonction de son code
   * @param code String représentant le code du mouvement recherché
   */
  public getTypeMouvement(code: string): string {
    let libelle = '';

    if (this.referentiel && this.referentiel.refMouvements) {
      const mouvement = this.referentiel.refMouvements.find(mouvement => mouvement.code === code);
      libelle = (mouvement) ? mouvement.libelle : '';
    }

    return libelle;
  }

  /**
   * Met à jour le statut de tous les mouvements
   * @param lu Booléen représentant l'état auquel passer les mouvements
   */
  public onPasserToutALuOuNonLu(lu: boolean): void {
    if (this.historiques) {
      const idsMouvements: number[] = [];
      this.historiques.forEach((historique) => {
        if (!historique.estLu) {
          historique.estLu = lu;
          idsMouvements.push(historique.idMouvement);
        }
      });
      this.mouvementsProduitsService.setMouvementLu(idsMouvements).subscribe();
    }
  }

  /**
   * Inverse le statut d'un seul mouvement
   * @param historique Un HistoriqueMouvement dont l'état est à modifier
   * @see HistoriqueMouvement
   */
  public onChangerStatut(historique: HistoriqueMouvement): void {
    historique.estLu = !historique.estLu;
    this.mouvementsProduitsService.setMouvementLu([ historique.idMouvement ]).subscribe();
  }

  public onChangerStatutNonLu(historique: HistoriqueMouvement): void {
    historique.estLu = !historique.estLu;
    this.mouvementsProduitsService.setMouvementNonLu([ historique.idMouvement ]).subscribe();
  }

  search() {

    if (this.searchForm.valid) {
      
      const annee = this.searchForm.get('annee').value as string;
      const raisonSociale = this.searchForm.get('raisonSociale').value as string;
      const non_controlable = this.searchForm.get('non_controlable').value as string;
      const suivi = this.searchForm.get('suivi').value as string;
      const finDate = this.finDate.value;
      const debutDate = this.debutDate.value;

      forkJoin(
        this.mouvementsProduitsService.getSuiviMouvement(
          raisonSociale,
          annee,
          non_controlable,
          this.getMomentDate(debutDate),
          this.getMomentDate(finDate),
          suivi)
      ).pipe(this.loader.operator()).subscribe(
        ([historiques]) => {
          this.historiques = historiques;
        });        
      
    }
  }

  changeStatus(mouvement : HistoriqueMouvement, ischeck: boolean){
    this.mouvementsProduitsService.setMouvementNonControlable(mouvement.idMouvement, ischeck).pipe(
      this.loader.operator()
    ).subscribe(() => {
        mouvement.nonControlable = ischeck;
    });
    }

    /**
   * Transforme une date provenant d'un Datepicker en date momentJS
   * @param date La date provenant d'un Datepicker
   * @see moment.Moment
   */
  private getMomentDate(date: {
    year: number,
    month: number,
    day: number
  }): moment.Moment {
    return (date) ? moment([date.year, date.month - 1, date.day, 0, 0, 0]) : null;
  }

  public telechargerDocument(mouvement: HistoriqueMouvement) {
    const modalRef = this.modalService.open(TelechargerDocumentComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.declaration = mouvement;
    modalRef.componentInstance.categorie = CategorieEnum.DECLARATION;
    modalRef.result.then(
      () => { },
      error => { throw error; }
    );
  }

  public onEdit(mouvement: HistoriqueMouvement): void {
    const modal = this.modalService.open(DetailControleComponent, { backdrop: 'static', windowClass: 'app-modal-1000px' });
    modal.componentInstance.idOperateur = mouvement.idOperateur;
    modal.componentInstance.annee = mouvement.annee;

    modal.result.then(() => { }, () => {});
  }

  

}
