import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { distinctUntilChanged, map, skipWhile, tap } from "rxjs/operators";
import { SaisieRecolte } from "../../models/saisie-recolte.model";
import { RecoltesService } from "../../services/recoltes/recoltes.service";
import { sortBy } from 'lodash';
import { Estimation } from "../../models/estimation.model";

/**
 * Façade qui gère des observables sur les déclarations de récoltes
 * @author Guillaume Alabarbe <guillaume.alabarbe.ext@adventiel.fr>
 */
@Injectable({ providedIn: 'root' })
export class RecoltesFacade {

  /**
   * Observable qui émet toutes les {@link SaisieRecolte} sans tri préalable
   */
  public declarationsRecoltes$: Observable<Array<SaisieRecolte>> = this.recoltesService.getAllDeclarationsRecoltes().pipe(
    skipWhile((declarationsRecoltes: Array<SaisieRecolte>) => declarationsRecoltes == null),
    distinctUntilChanged(),
    map((declarationsRecoltes: Array<SaisieRecolte>) => declarationsRecoltes)
  );

  public splittedDeclarationsRecoltes$: Observable<Array<SaisieRecolte>> = this.declarationsRecoltes$.pipe(
    skipWhile((declarationsRecoltes: Array<SaisieRecolte>) => declarationsRecoltes == null),
    distinctUntilChanged(),
    map((declarationRecoltes: Array<SaisieRecolte>) => this.splitDeclarationsRecoltesByEspeces(declarationRecoltes))
  );

  /**
   * Observable qui émet toutes les {@link Estimation} de récoltes
   */
  public estimationsRecoltes$: Observable<Array<Estimation>> = this.recoltesService.getEstimationsRecoltes().pipe(
    skipWhile((estimations: Array<Estimation>) => estimations == null),
    distinctUntilChanged(),
    map((estimations: Array<Estimation>) => estimations)
  );

  constructor(private recoltesService: RecoltesService) {}

  /**
   * Appelle le service déclarations pour charger les données des {@link SaisieRecolte}
   */
  public loadDeclarationsRecoltes(): void {
    this.declarationsRecoltes$.subscribe();
  }

  /**
   * Appelle le service déclaration pour charger les données des {@link Estimation} dans l'observable estimationRecoltes$
   */
  public loadEstimations(): void {
    this.estimationsRecoltes$.subscribe();
  }

  public getPrevisionsStat(declarationsRecoltes: Array<SaisieRecolte>): number {
    let previsions = 0;
    if (declarationsRecoltes) {
      const previsionTotale = declarationsRecoltes.length;
      const previsionEffective = declarationsRecoltes
        .filter((declaration: SaisieRecolte) => declaration.recolteSuivi.datePrevisionActuelle != null);
      previsions = (previsionEffective.length / previsionTotale) * 100;
    }
    return previsions;
  }

  public getRecoltesStat(declarationsRecoltes: Array<SaisieRecolte>): number {
    let recoltes = 0;
    if (declarationsRecoltes) {
      const surfaceTotale = declarationsRecoltes
        .map((declaration: SaisieRecolte) => declaration.getSurfaceTotale())
        .reduce((accumulator: number, surfaceCourante: number) => accumulator + surfaceCourante, 0);
      const surfaceEffective = declarationsRecoltes
        .filter((declaration: SaisieRecolte) => declaration.getRecolteEffectiveTotale() > 0)
        .map((declaration: SaisieRecolte) => declaration.getSurfaceTotale())
        .reduce((accumulator: number, surfaceCourante: number) => accumulator + surfaceCourante, 0);
      recoltes = (surfaceEffective / surfaceTotale) * 100;
    }
    return recoltes;
  }

  /**
   * Crée une déclaration par récolte pour la page de suivi
   * @param declarations La collection de déclarations à spliter 
   * @returns Une collection de {@link SaisieRecolte} avec une seule recolte et ordonnée par espèce
   */
  public splitDeclarationsRecoltesByEspeces(declarations: Array<SaisieRecolte>) {
    let declarationsRecoltes: Array<SaisieRecolte> = [];

    for (let declaration of declarations) {
      for (let recolte of declaration.recoltes) {
        let copie = Object.assign(new SaisieRecolte(), {
          idRefCampagne: declaration.idRefCampagne,
          idEntreprise: declaration.idEntreprise,
          estDefinitive: declaration.estDefinitive,
          recolteSuivi: recolte,
          recoltes: [recolte],
          delai: recolte.getNbJoursDepuisPrevision()
        });
        declarationsRecoltes.push(copie);
      }
    }

    return sortBy(declarationsRecoltes, 'recolteSuivi.idEspece');
  }

}
