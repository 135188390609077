import { AdvBootstrapLoaderService } from "@adv/bootstrap-loader";
import { Component, OnInit } from "@angular/core";
import { Router, Routes } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, from } from "rxjs";
import { AuthService } from "src/app/core/services/auth/auth.service";
import { SessionContext } from "src/app/core/services/config/app.settings";
import { Campagne } from "src/app/data/declaration/models/campagne.model";
import { RefCampagnesService } from "src/app/data/declaration/services/ref-campagnes/ref-campagnes.service";
import { EngagementBase } from "src/app/data/habilitation/models/engagements/engagement-base";
import { EngagementVergersEcoService } from "src/app/data/habilitation/services/engagements/engagement-vergers-eco.service";
import { Utilisateur } from "src/app/data/intervenant/models/utilisateur.model";
import { EntreprisesService } from "src/app/data/intervenant/services/entreprises/entreprises.service";
import { UtilisateursService } from "src/app/data/intervenant/services/utilisateurs/utilisateurs.service";
import { SelectFieldsColumnComponent } from "../../../declarations/pages/parcellaires/modals/select-fields-column/select-fields-column.component";
import { ChangementStatutComponent } from "../modals/changement-statut/changement-statut.component";
import { DocPreparatoireOCComponent } from "../modals/doc-preparatoire-oc/doc-preparatoire-oc.component";
import { DocPreparatoireCtComponent } from "../modals/doc-preparatoire-ct/doc-preparatoire-ct.component";
import { EngagementCtOc } from "../../../../../data/habilitation/models/engagements/engagement-ct-oc";
import { SuivisService } from "src/app/data/suivis/services/suivis.service";
import { InfosPoSurface } from "../../../../../data/declaration/models/infos-po-surface.model";
import { ContactsService } from "src/app/data/intervenant/services/contacts/contacts.service";
import { DeclarationsService } from "src/app/data/declaration/services/declarations/declarations.service";
import { AdvBootstrapModalService } from "@adv/bootstrap-modal";
import { InfosGenerationAudit } from "src/app/data/declaration/models/infos-generation-audit.model";
import * as moment from "moment";
import { FileDownloadTools } from "src/app/shared/file-download-tools/file-download-tools";
import { Fichier } from "src/app/data/edition/models/fichier.model";

@Component({
  selector: "app-vergers-ecoresponsables",
  templateUrl: "./vergers-ecoresponsables.component.html",
  styleUrls: ["./vergers-ecoresponsables.component.scss"],
})
export class VergersEcoresponsablesComponent implements OnInit {
  public isGp = false;
  public isPi = false;
  public isCt = false;
  public isOc = false;
  public isAnpp = false;
  public peutCreerEngagement = false;
  public engagements: EngagementBase[] = [];
  public listCtOc: EngagementCtOc[] = [];
  public campagnes: Campagne[];
  public campagneVerEnCours: Campagne;
  public campagneEngagement: Campagne;
  public campagneSelected: Campagne;
  public utilisateur: Utilisateur;
  public colonnes = [];
  public infosPoSurface: InfosPoSurface;

  static routes: Routes = [
    {
      path: "",
      component: VergersEcoresponsablesComponent,
      data: {
        domaines: ["ANPP"],
        menu: {
          libelle: "menu.vergers-ecoresponsable",
        },
      },
    },
  ];

  constructor(
    private readonly router: Router,
    private readonly utilisateursService: UtilisateursService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly refCampagneService: RefCampagnesService,
    private readonly modalService: NgbModal,
    private readonly authService: AuthService,
    private readonly engagementVergersEco: EngagementVergersEcoService,
    private readonly entreprisesService: EntreprisesService,
    private readonly translate: TranslateService,
    private readonly modal: NgbModal,
    public readonly suivisService: SuivisService,
    private readonly contactsService: ContactsService,
    private readonly declarationsService: DeclarationsService,
    private readonly modalsService: AdvBootstrapModalService
  ) {}

  ngOnInit() {
    const date = new Date();
    this.suivisService.setActiveTabId("vergersEcoresponsables");
    this.loadColonnes();
    this.isAnpp = this.authService.isAnpp();
    forkJoin([
      this.utilisateursService.getUtilisateur(
        SessionContext.get("idIntervenant")
      ),
      this.refCampagneService.getCampagneVerEnCours(),
      this.refCampagneService.getCampagneVerAll(),
    ])
      .pipe(this.loaderService.operator())
      .subscribe(([utilisateur, campagneEncours, allCampagne]) => {
        this.utilisateur = utilisateur;
        this.campagneEngagement = campagneEncours;
        this.campagnes = allCampagne.sort((a, b) => {
          if (a && b && a.annee > b.annee) return -1;
          if (a && b && a.annee < b.annee) return 1;
          return 0;
        });
        if (utilisateur) {
          this.isGp = utilisateur.type.code === "GP";
          this.isPi = utilisateur.type.code === "PI";
          this.isCt =
            utilisateur.type.code === "CT" ||
            utilisateur.estConseillerTechnique === true;
          this.isOc = utilisateur.type.code === "OC";
        }

        const camp = this.campagnes.find((c) => c.id === campagneEncours.id);
        const campEngagement = this.campagnes.find(
          (c) => c.id === campagneEncours.id
        );
        this.campagneVerEnCours = camp;
        this.campagneSelected = campEngagement;
        this.loadData();
      });
  }

  loadData() {
    this.engagements = [];
    if (this.isAnpp) {
      this.engagementVergersEco
        .getEngagementEcoByCampagne(this.campagneSelected.code)
        .pipe(this.loaderService.operator())
        .subscribe((engagements) => {
          this.engagements = engagements;
        });
    } else {
      this.engagementVergersEco
        .getEngagementEcoByEntrepriseAndCampagne(
          this.utilisateur.entreprise.id,
          this.campagneSelected.code
        )
        .pipe(this.loaderService.operator())
        .subscribe((engagement) => {
          if (engagement) {
            this.engagements = [];
            this.engagements.push(engagement);
          }
          this.setPeutCreerEngagement();
        });
      if (this.isCt) {
        this.engagementVergersEco
          .getIdsEntreprisesLieesCt(
            this.utilisateur.id,
            this.campagneSelected.code,
            false
          )
          .pipe(this.loaderService.operator())
          .subscribe((ids) => {
            if (ids && ids.length > 0) {
              let infosGenerationAudit: InfosGenerationAudit =
                new InfosGenerationAudit();
              infosGenerationAudit.idEntrepriseList = ids;
              infosGenerationAudit.codeOCCT = "CT";
              this.engagementVergersEco
                .getEngagementEcoOcCtByCampagne(
                  this.campagneSelected.code,
                  infosGenerationAudit
                )
                .pipe(this.loaderService.operator())
                .subscribe((engagementList) => {
                  this.engagements = engagementList;
                  this.loadListCtOc(ids);
                });
            }
          });
      } else if (this.isOc) {
        this.engagementVergersEco
          .getIdsEntreprisesLieesOc(
            this.utilisateur.entreprise.id,
            this.campagneSelected.code,
            false
          )
          .pipe(this.loaderService.operator())
          .subscribe((ids) => {
            if (ids && ids.length > 0) {
              let infosGenerationAudit: InfosGenerationAudit =
                new InfosGenerationAudit();
              infosGenerationAudit.idEntrepriseList = ids;
              infosGenerationAudit.codeOCCT = "OC";
              this.engagementVergersEco
                .getEngagementEcoOcCtByCampagne(
                  this.campagneSelected.code,
                  infosGenerationAudit
                )
                .pipe(this.loaderService.operator())
                .subscribe((engagementList) => {
                  this.engagements = engagementList;
                  this.loadListCtOc(ids);
                });
            }
          });
      }
    }
  }

  loadListCtOc(ids: number[]) {
    this.engagementVergersEco
      .getListEngagementCtOc(ids, this.campagneSelected.code)
      .pipe(this.loaderService.operator())
      .subscribe((result) => {
        if (result) {
          this.listCtOc = result;
          this.recupererNomAuteur();
        }
      });
  }

  recupererNomAuteur() {
    this.utilisateursService.getAuteurs(this.listCtOc).subscribe((list) => {
      this.listCtOc = list;
      this.recupererDateEngagement();
      this.listCtOc = this.listCtOc.sort(function (left, right) {
        return moment
          .utc(right.dateEngagement)
          .diff(moment.utc(left.dateEngagement));
      });
    });
  }

  recupererDateEngagement() {
    this.listCtOc.forEach((engaCtOC) => {
      const engagementSelect = this.engagements.find(
        (engagement) => engagement.entreprise.id === engaCtOC.idEntreprise
      );
      if (engagementSelect) {
        engaCtOC.dateEngagement = engagementSelect.dateEngagement;
        if (engagementSelect.conseillerTechnique) {
          engaCtOC.conseillerTechnique =
            engagementSelect.conseillerTechnique.prenom +
            " " +
            engagementSelect.conseillerTechnique.nom;
        } else {
          engaCtOC.conseillerTechnique =
            engagementSelect.conseillerTechnique2.prenom +
            " " +
            engagementSelect.conseillerTechnique.nom;
        }
      }
    });
  }

  modifierEngagement(engagenement: EngagementBase) {
    this.entreprisesService
      .getEntreprise(engagenement.entreprise.id)
      .subscribe((entreprise) => {
        if (entreprise.refSousType.code == "GP_DEFAUT") {
          this.router.navigate([
            "main/engagements/formulaire-gp/" + engagenement.id,
          ]);
        } else {
          this.router.navigate([
            "main/engagements/formulaire-pi/" + engagenement.id,
          ]);
        }
      });
  }

  setPeutCreerEngagement() {
    if (
      this.isAnpp ||
      this.engagements.length > 0 ||
      (this.campagneEngagement &&
        this.campagneEngagement.code != this.campagneSelected.code)
    ) {
      this.peutCreerEngagement = false;
    } else {
      this.peutCreerEngagement = true;
    }
  }

  modifierStatutEngagement(engagement: EngagementBase) {
    const modalRef = this.modalService.open(ChangementStatutComponent, {
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.engagement = engagement;
  }

  goToFormulaire(): void {
    if (!this.authService.isAnpp()) {
      forkJoin([
        this.utilisateursService.getUtilisateur(
          SessionContext.get("idIntervenant")
        ),
        this.refCampagneService.getCampagneEnCours(),
      ]).subscribe(([utilisateur, campagne]) => {
        if (utilisateur.type.code !== "CT" && utilisateur.type.code !== "OC") {
          this.declarationsService
            .getInventaireVergerDefinitiveStatus(
              utilisateur.entreprise.id,
              campagne.id
            )
            .pipe(this.loaderService.operator())
            .subscribe((result) => {
              if (result == false) {
                this.modalsService
                  .error(
                    this.translate.instant(
                      `page.engagements.errors.inventaire-invalid`
                    ),
                    null,
                    { windowClass: "anpp" }
                  )
                  .then(() => {
                    this.router.navigate(["/main/declarations-pp/parcellaire"]);
                  });
              } else {
                this.contactsService
                  .getContactsByEntreprise(utilisateur.entreprise.id)
                  .subscribe((contacts) => {
                    if (
                      !contacts ||
                      contacts.length == 0 ||
                      contacts.filter((c) =>
                        c.referents.find((r) => r.code === "VER")
                      ).length == 0
                    ) {
                      this.modalsService
                        .error(
                          this.translate.instant(
                            `page.engagements.errors.no-contact`
                          ),
                          null,
                          { windowClass: "anpp" }
                        )
                        .then(() => {
                          this.router.navigate(["/main/home"]);
                        });
                    } else {
                      this.navigateFormulaire();
                    }
                  });
              }
            });
        }
      });
    } else {
      this.navigateFormulaire();
    }
  }
  private navigateFormulaire(): void {
    if (this.isPi) {
      this.router.navigate(["main/engagements/formulaire-pi"]);
    } else if (this.isGp) {
      this.router.navigate(["main/engagements/formulaire-gp"]);
    }
  }

  show(obj) {
    return this.colonnes.find((h) => h.name === obj).checked;
  }

  showList() {
    const modalRef = this.modal.open(SelectFieldsColumnComponent, {
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.headers = this.colonnes;

    from(modalRef.result).subscribe((colonnes) => {
      this.colonnes = colonnes;
    });
  }

  private loadColonnes() {
    this.colonnes = [
      {
        name: "raisonSociale",
        label: this.translate.instant(
          "page.engagements.grid.column.raison-sociale"
        ),
        checked: true,
      },
      {
        name: "typeEntreprise",
        label: this.translate.instant(
          "page.engagements.grid.column.type-entreprise"
        ),
        checked: true,
      },
      {
        name: "statutEngagement",
        label: this.translate.instant("page.engagements.grid.column.statut"),
        checked: true,
      },
      {
        name: "dateEngagement",
        label: this.translate.instant("page.engagements.grid.column.date"),
        checked: true,
      },
      {
        name: "dateAudit",
        label: this.translate.instant("page.engagements.grid.column.date"),
        checked: false,
      },
      {
        name: "activiteProduction",
        label: this.translate.instant(
          "page.engagements.grid.column.act-production"
        ),
        checked: true,
      },
      {
        name: "activiteConditionnement",
        label: this.translate.instant(
          "page.engagements.grid.column.act-conditionnement"
        ),
        checked: true,
      },
      {
        name: "utilisationLogo",
        label: this.translate.instant(
          "page.engagements.grid.column.utilisation-logo"
        ),
        checked: true,
      },
      {
        name: "ville",
        label: this.translate.instant("page.engagements.grid.column.ville"),
        checked: false,
      },
      {
        name: "statutEngagementPrecedent",
        label: this.translate.instant(
          "page.engagements.grid.column.statut-engagement-precedent"
        ),
        checked: false,
      },
      {
        name: "globalGap",
        label: this.translate.instant(
          "page.engagements.grid.column.global-gap"
        ),
        checked: false,
      },
      {
        name: "hve",
        label: this.translate.instant("page.engagements.grid.column.hve"),
        checked: false,
      },
      {
        name: "brc",
        label: this.translate.instant("page.engagements.grid.column.brc"),
        checked: false,
      },
      {
        name: "ifs",
        label: this.translate.instant("page.engagements.grid.column.ifs"),
        checked: false,
      },
      {
        name: "organismeCertification",
        label: this.translate.instant(
          "page.engagements.grid.column.organisme-certification"
        ),
        checked: true,
      },
    ];
  }

  docPrepaOC() {
    const modalRef = this.modalService.open(DocPreparatoireOCComponent, {
      size: "lg",
      backdrop: "static",
    });
    const campagneInventaire =
      this.campagneSelected.code === this.campagneEngagement.code
        ? this.campagneVerEnCours
        : this.campagneSelected;
    modalRef.componentInstance.campagne = this.campagneSelected;
    modalRef.componentInstance.campagneInventaire = campagneInventaire;
    modalRef.componentInstance.engagements = this.engagements;
    from(modalRef.result).subscribe(() => {
      this.loadData();
    });
  }

  docPrepaCT() {
    const modalRef = this.modalService.open(DocPreparatoireCtComponent, {
      size: "lg",
      backdrop: "static",
    });
    const campagneInventaire =
      this.campagneSelected.code === this.campagneEngagement.code
        ? this.campagneVerEnCours
        : this.campagneSelected;
    modalRef.componentInstance.campagne = this.campagneSelected;
    modalRef.componentInstance.campagneInventaire = campagneInventaire;
    modalRef.componentInstance.engagements = this.engagements;
  }

  genererPdf(engagement?: EngagementBase) {
    if (this.engagements.length > 0) {
      this.infosPoSurface = new InfosPoSurface();
      this.infosPoSurface.listPoSurfacePomme = new Map<number, number>();
      this.infosPoSurface.listPoSurfacePoire = new Map<number, number>();
      //Récupérer la bonne campagne
      const campagneAUtiliser =
        this.campagneSelected.code === this.campagneEngagement.code
          ? this.campagneVerEnCours
          : this.campagneSelected;
      console.log(campagneAUtiliser);
      this.refCampagneService
        .getCampagneAnppByDateDebutCampagneVer(campagneAUtiliser.dateDebut)
        .pipe(this.loaderService.operator())
        .subscribe((campagne) => {
          forkJoin(
            this.engagementVergersEco.getSurfaceByPoAndEspece(
              this.isAnpp
                ? engagement.entreprise.id
                : this.utilisateur.entreprise.id,
              "POMME",
              campagne.code
            ),
            this.engagementVergersEco.getSurfaceByPoAndEspece(
              this.isAnpp
                ? engagement.entreprise.id
                : this.utilisateur.entreprise.id,
              "POIRE",
              campagne.code
            )
          )
            .pipe(this.loaderService.operator())
            .subscribe(([responsePomme, responsePoire]) => {
              this.infosPoSurface.listPoSurfacePomme = responsePomme;
              this.infosPoSurface.listPoSurfacePoire = responsePoire;
              //TODO ping pong pour get les ids PO uniquement en cas d'un GP pour ensuite interoger le service intervenant et récupérer une
              //map avec un idProducteurOrganisé et un idContact
              if (
                this.isGp ||
                (this.isAnpp &&
                  engagement.entreprise.sousType === "Groupement producteur")
              ) {
                //Récupération de la liste des ids producteurs organisés assicés
                //Je passe par le service habilitation pour avoir la liste extraite des tables engagement pour refleter l'instant T
                this.engagementVergersEco
                  .getIdsProducteursOrganisesByGroupement(
                    this.isAnpp
                      ? engagement.entreprise.id
                      : this.utilisateur.entreprise.id,
                    this.campagneSelected.code
                  )
                  .pipe(this.loaderService.operator())
                  .subscribe((list) => {
                    //On ajoute le GP pour récupérer la structure juridique
                    let idEntreprise: number = this.isAnpp
                      ? engagement.entreprise.id
                      : this.utilisateur.entreprise.id;
                    list.push(idEntreprise);
                    //On ajoute les ids des sc pour récupérer la structure juridique
                    console.log(engagement);
                    if (
                      engagement &&
                      engagement.listEngagementSc != null &&
                      engagement.listEngagementSc.length > 0
                    ) {
                      engagement.listEngagementSc.forEach((e) => {
                        list.push(e.entreprise.id);
                      });
                    } else if (this.engagements.length === 1) {
                      let eng = this.engagements[0];
                      if (
                        eng &&
                        eng.listEngagementSc != null &&
                        eng.listEngagementSc.length > 0
                      ) {
                        eng.listEngagementSc.forEach((e) => {
                          list.push(e.entreprise.id);
                        });
                      }
                    }

                    this.entreprisesService
                      .getInformationsComplementairesPdfVer(list)
                      .pipe(this.loaderService.operator())
                      .subscribe((result) => {
                        this.infosPoSurface.listComplementPoPdfVer = result;
                        this.getPdf(this.isAnpp ? engagement : null);
                      });
                  });
              } else {
                let list: number[] = [];
                let idEntreprise: number = this.isAnpp
                  ? engagement.entreprise.id
                  : this.utilisateur.entreprise.id;
                list.push(idEntreprise);
                this.entreprisesService
                  .getInformationsComplementairesPdfVer(list)
                  .pipe(this.loaderService.operator())
                  .subscribe((result) => {
                    this.infosPoSurface.listComplementPoPdfVer = result;
                    this.getPdf(this.isAnpp ? engagement : null);
                  });
              }
            });
        });
    }
  }
  private getPdf(engagement?: EngagementBase): void {
    this.engagementVergersEco
      .generationPdf(
        this.isAnpp ? engagement.entreprise.id : this.utilisateur.entreprise.id,
        this.campagneSelected.code,
        this.infosPoSurface
      )
      .pipe(this.loaderService.operator())
      .subscribe((fichier: Fichier): void => {
        const fileURL = URL.createObjectURL(
          FileDownloadTools.b64ToBlob(fichier.base64, "application/pdf")
        );
        window.open(fileURL);
      });
  }

  downloadFile(data: Blob) {
    const blob = new Blob([data], { type: "application/pdf" });
    window.open(window.URL.createObjectURL(blob));
  }

  public bindValue(sousType: string): boolean {
    if (sousType == "Producteur indépendant") {
      return true;
    }
    return false;
  }
}
