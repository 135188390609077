import {JsonObject, JsonProperty} from '@thorolf/json-ts-mapper';
import {ComQualif} from './com-qualif.model';

@JsonObject
export class ComJure {
  @JsonProperty('id', Number)
  id: number;

  @JsonProperty('nom', String)
  nom: string;

  @JsonProperty('mail', String)
  mail: string;

  @JsonProperty('telFixe', String)
  telFixe: string;

  @JsonProperty('telPortable', String)
  telPortable: string;

  @JsonProperty('comQualifications', [ComQualif])
  qualifications: ComQualif[];
}
