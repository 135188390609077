import { JsonObject, JsonProperty, CustomConverter } from '@thorolf/json-ts-mapper';
import { IdCodeLibelle } from '../../id-code-libelle.abstract.model';
import { Service } from '../../intervenant/models/service.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { DroitRole } from './droit-role.model';

@JsonObject
export class DroitFonctionnel extends IdCodeLibelle {

  @JsonProperty('alias', String)
  alias: string;

  @JsonProperty('idService', Number)
  @CustomConverter(DataIdConverterFactory.build(Service, 'services'))
  service: Service;

  roles: DroitRole[] = [];
}
