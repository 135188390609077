import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { RefSystemeIrrigation } from 'src/app/data/declaration/models/ref-systeme-irrigation.model';
import { RefSystemeIrrigationService } from 'src/app/data/declaration/services/parcellaire/ref-systeme-irrigation.service';

@Component({
  selector: 'app-edit-systeme-irrigation',
  templateUrl: './edit-systeme-irrigation.component.html',
  styleUrls: ['./edit-systeme-irrigation.component.scss']
})
export class EditSystemeIrrigationComponent implements OnInit {

  formGroup: FormGroup;
  creation = false;
  title: string;
  irrigation: RefSystemeIrrigation;

  get code() { return this.formGroup.get('code'); }
  get libelle() { return this.formGroup.get('libelle'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly irrigationService: RefSystemeIrrigationService,) { }

  ngOnInit() {

    const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    if (!id) {
      this.creation = true;
    }
      this.loadData(id);
    

  }

  loadData(id: number) {

    this.title = this.creation ? 'page.valeur_parametrable.form.title.add_irrigation': 'page.valeur_parametrable.form.title.edit_irrigation';
    
    forkJoin(
    !this.creation ? this.irrigationService.getRefSystemeIrrigation() : of(new RefSystemeIrrigation())
    )
    .pipe(
      this.loaderService.operator()
    ).subscribe(([irrigations]) => {
     this.irrigation = !this.creation ? irrigations.find(g => g.id === id) : irrigations ; 

    this.initForm((this.irrigation.id) ? this.irrigation : null);
     
    });
  }

  private initForm(irrigation?: RefSystemeIrrigation): void {
 
    this.formGroup = this.fb.group({
      code: [{ value: ((irrigation) ? irrigation.code: undefined), disabled: false }, Validators.required],
      libelle: [{ value: ((irrigation) ? irrigation.libelle : undefined), disabled: false }, Validators.required]
    });
  }

  submit() {
    if (this.formGroup.valid) {
      this.irrigation.code = this.code.value;
      this.irrigation.libelle = this.libelle.value;
     
      if (this.irrigation.id == null) {
        this.irrigationService.creerRefSystemeIrrigation(this.irrigation).subscribe(idIrrigation => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
        });
      } else {        
        this.irrigationService.modifierRefSystemeIrrigation(this.irrigation).subscribe( () => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
         } );
      }
     
    }
  }

}
