import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class Fonction {
  @JsonProperty('id', Number)
  public id: number;

  @JsonProperty('code', String)
  public code: string;

  @JsonProperty('libelle', String)
  public libelle: string;
}