import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditClonesComponent } from './edit-clones/edit-clones.component';
import { ListeClonesComponent } from './liste-clones/liste-clones.component';

@Component({
  selector: 'app-clones',
  templateUrl: './clones.component.html',
  styleUrls: ['./clones.component.scss']
})
export class ClonesComponent implements OnInit {

  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"]
    };
  
    static routes: Routes = [
      { path: '', component: ListeClonesComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id', component: EditClonesComponent, data: { roles: ['ORGANISME'] } },
      { path: 'new', component: EditClonesComponent, data: { roles: ['ORGANISME'] } }
    ];

  constructor() { }

  ngOnInit() {
  }

}
