<div class="anpp" [ngClass]="{'content': !idEngagement }">
  <div [ngClass]="{'content-panel': !idEngagement }">
    <div class="title-bar" *ngIf="!idEngagement">
      <h4 translate>page.engagements.title</h4>
    </div>
    <form  [formGroup]="formGroup" #form="ngForm" (submit)="submit()" novalidate>
      <div class="m-3">
        <div class="row">
          <div class="form-group col-lg-4">
            <label for="raisonsociale" translate>page.engagements.form.labels.raison-sociale</label>
            <input type="text" formControlName="raisonsociale"
              [ngClass]="{'is-invalid': form.submitted && raisonsociale.invalid}" class="form-control" />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-4">
            <label for="responsable" translate>page.engagements.form.labels.responsable</label>
            <select id="responsable" formControlName="responsable" class="form-control" [ngClass]="{'is-invalid': form.submitted && responsable.invalid}">
              <option *ngFor="let responsable of contacts" [ngValue]="responsable.id">{{responsable.nom}} {{responsable.prenom}}</option>
            </select>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="responsable.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-3">
            <label for="activiteProduction" translate>page.engagements.form.labels.activite-production</label>
            <div class="form-group">
              <input type="radio" class="mr-1" id="actProOui" formControlName="activiteProduction" [value]="true" />
              <label for="actProOui" translate>page.engagements.form.choix.oui</label>
              <input type="radio" class="ml-5 mr-1" id="actProNon" formControlName="activiteProduction" [value]="false" />
              <label for="actProNon" translate>page.engagements.form.choix.non</label>
            </div>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="activiteProduction.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group col-lg-3" *ngIf="activiteProduction.value">
            <label for="globalGap" translate>page.engagements.form.labels.global-gap</label>
            <div>
              <input type="radio" class="mr-1" id="globalGapOui" formControlName="globalGap" [required]="activiteProduction.value" [value]="true" />
              <label for="globalGapOui" translate>page.engagements.form.choix.oui</label>
              <input type="radio" class="ml-5 mr-1" id="globalGapNon" formControlName="globalGap" [required]="activiteProduction.value" [value]="false" />
              <label for="globalGapNon" translate>page.engagements.form.choix.non</label>
            </div>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="globalGap.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-3" *ngIf="activiteProduction.value">
            <label for="engagementHve" translate>page.engagements.form.labels.engagement-hve</label>
            <div>
              <input type="radio" class="mr-1" id="engagementHveOui" formControlName="engagementHve" [required]="activiteProduction.value" [value]="true" />
              <label for="engagementHveOui" translate>page.engagements.form.choix.oui</label>
              <input type="radio" class="ml-5 mr-1" id="engagementHveNon" formControlName="engagementHve" [required]="activiteProduction.value" [value]="false" />
              <label for="engagementHveNon" translate>page.engagements.form.choix.non</label>
            </div>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="engagementHve.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group col-lg-3" *ngIf="activiteProduction.value && engagementHve.value">
            <label for="typeEngagementHve" translate>page.engagements.form.labels.type-engagement-hve</label>
            <div>
              <input type="radio" class="mr-1" id="renouvellement" formControlName="typeEngagementHve" value="renouvellement" />
              <label for="renouvellement" translate>page.engagements.form.choix.renouvellement</label>
              <input type="radio" class="ml-5 mr-1" id="auditInitial" formControlName="typeEngagementHve" value="auditInitial" />
              <label for="auditInitial" translate>page.engagements.form.choix.audit-initial</label>
            </div>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="typeEngagementHve.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-3">
            <label for="activiteConditionnement" translate>page.engagements.form.labels.activite-conditionnement</label>
            <div>
              <input type="radio" class="mr-1" id="actCondOui" formControlName="activiteConditionnement" [value]="true" />
              <label for="actCondOui" translate>page.engagements.form.choix.oui</label>
              <input type="radio" class="ml-5 mr-1" id="actCondNon" formControlName="activiteConditionnement" [value]="false" />
              <label for="actCondNon" translate>page.engagements.form.choix.non</label>
            </div>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="activiteConditionnement.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group col-lg-3" *ngIf="activiteConditionnement.value">
            <label for="brc" translate>page.engagements.form.labels.brc</label>
            <div>
              <input type="radio" class="mr-1" id="brcOui" formControlName="brc" [required]="activiteConditionnement.value" [value]="true" />
              <label for="brcOui" translate>page.engagements.form.choix.oui</label>
              <input type="radio" class="ml-5 mr-1" id="brcNon" formControlName="brc" [required]="activiteConditionnement.value" [value]="false" />
              <label for="brcNon" translate>page.engagements.form.choix.non</label>
            </div>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="brc.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group col-lg-3" *ngIf="activiteConditionnement.value">
            <label for="ifs" translate>page.engagements.form.labels.ifs</label>
            <div>
              <input type="radio" class="mr-1" id="ifsOui" formControlName="ifs" [required]="activiteConditionnement.value" [value]="true" />
              <label for="ifsOui" translate>page.engagements.form.choix.oui</label>
              <input type="radio" class="ml-5 mr-1" id="ifsNon" formControlName="ifs" [required]="activiteConditionnement.value" [value]="false" />
              <label for="ifsNon" translate>page.engagements.form.choix.non</label>
            </div>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="ifs.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group col-lg-3" *ngIf="activiteConditionnement.value">
            <label for="utilisationLogo" translate>page.engagements.form.labels.utilisation-logo</label>
            <div>
              <input type="radio" class="mr-1" id="utilisationLogoOui" formControlName="utilisationLogo" [required]="activiteConditionnement.value" [value]="true" />
              <label for="utilisationLogoOui" translate>page.engagements.form.choix.oui</label>
              <input type="radio" class="ml-5 mr-1" id="utilisationLogoNon" formControlName="utilisationLogo" [required]="activiteConditionnement.value" [value]="false" />
              <label for="utilisationLogoNon" translate>page.engagements.form.choix.non</label>
            </div>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="utilisationLogo.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-4">
            <label for="contactReferentEco" translate>page.engagements.form.labels.contact-referent</label>
            <select id="contactReferentEco" formControlName="contactReferentEco" class="form-control" [ngClass]="{'is-invalid': form.submitted && contactReferentEco.invalid}">
              <option *ngFor="let contactReferentEco of contactsReferents" [ngValue]="contactReferentEco.id">{{contactReferentEco.nom}} {{contactReferentEco.prenom}}</option>
            </select>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="contactReferentEco.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-4">
            <label for="conseillerTechnique" translate>page.engagements.form.labels.conseiller-technique</label>
            <select id="conseillerTechnique" formControlName="conseillerTechnique" class="form-control" [ngClass]="{'is-invalid': form.submitted && conseillerTechnique.invalid}">
              <option *ngFor="let conseillerTechnique of conseillers" [ngValue]="conseillerTechnique.id">{{conseillerTechnique.nom}} {{conseillerTechnique.prenom}}, {{conseillerTechnique.entreprise.raisonSociale}}
              </option>
            </select>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="conseillerTechnique.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-4">
            <label for="organismeCertification" translate>page.engagements.form.labels.organisme-certification</label>
            <select id="organismeCertification" formControlName="organismeCertification" class="form-control" [ngClass]="{'is-invalid': form.submitted && organismeCertification.invalid}">
              <option *ngFor="let organismeCertification of organismesCertification" [ngValue]="organismeCertification">{{organismeCertification.raisonSociale}}</option>
            </select>
          </div>
          <ng-container *ngIf="form.submitted">
            <div *ngIf="organismeCertification.errors?.required" class="form-error" translate>label.obligatoire</div>
          </ng-container>
        </div>
        <!--LABORATOIRE SOLS -->
        <div class="row">
          <div class="form-group col-lg-4">
            <label for="laboratoireSols" translate>page.engagements.form.labels.laboratoire-sols</label>
            <select id="laboratoireSols" formControlName="laboratoireSols" class="form-control" [ngClass]="{'is-invalid': form.submitted && laboratoireSols.invalid}">
              <option></option>
              <option *ngFor="let laboratoireSols of laboratoireSolsList" [ngValue]="laboratoireSols">{{laboratoireSols.libelle}}</option>
            </select>
          </div>
        </div>
        <!--LABORATOIRE RESIDUS -->
        <div class="row"  *ngIf="activiteConditionnement.value">
          <div class="form-group col-lg-4">
            <label for="laboratoireResidus" translate>page.engagements.form.labels.laboratoire-residus</label>
            <select id="laboratoireResidus" formControlName="laboratoireResidus" class="form-control" [ngClass]="{'is-invalid': form.submitted && laboratoireResidus.invalid}">
              <option></option>
              <option *ngFor="let laboratoireResidus of laboratoireResidusList" [ngValue]="laboratoireResidus">{{laboratoireResidus.libelle}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="actions-bar" fxLayoutAlign="space-between" *ngIf="!idEngagement">
        <button type="button" class="btn btn-primary btnprimary" (click)="retour()" translate>page.engagements.form.bouton.retour</button>
        <button type="submit" class="btn btn-primary btnprimary" translate *ngIf="!readOnly">page.engagements.form.bouton.enregistrer</button>
      </div>
    </form>
  </div>
</div>