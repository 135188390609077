import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { Animateur } from '../../commission/models/animateur.model';

@JsonObject
export class Notification {

    @JsonProperty('dateNotification', String)
    @CustomConverter(DateConverter)
    @Optional
    dateNotification: moment.Moment;

    @JsonProperty('reponseOperateur', Boolean)
    @Optional
    reponseOperateur: Boolean;

    @JsonProperty('dateReponseOperateur', String)
    @CustomConverter(DateConverter)
    @Optional
    dateReponseOperateur: moment.Moment;

    @JsonProperty('auteurNotification', String)
    @Optional
    auteurNotification: String;

    @JsonProperty('recours', Boolean)
    @Optional
    recours: Boolean;

    @JsonProperty('commentaireOperateur', String)
    @Optional
    commentaireOperateur: string;

    @JsonProperty('commentaireOrganisme', String)
    @Optional
    commentaireOrganisme: string;
}
