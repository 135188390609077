<div class="modal-content anpp">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel"><fa-icon icon="user"></fa-icon> <b translate> page.inventaire-verger.modals.changerProducteur.changerProducteurTitle</b></h5>
    <button (click)="cancel()" type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="formGroup" #form="ngForm" (ngSubmit)="submit()" novalidate>
      <div class="form-group">
        <app-search-adherent-actif-bar (newItemEvent)="selectItemAdherent($event)" *ngIf="showAdherentBarre()"></app-search-adherent-actif-bar>
        <div *ngIf="!isAnpp()">
          <strong>Adhérent : </strong><span *ngIf="adherentCible" class="col-lg-4">{{adherentCible.raisonSociale}}</span>
        </div>

        <app-search-producteur-organise-bar [idAdherent]="adherentCible.id" *ngIf="showProducteurBarre" (newItemEvent)="selectItemProducteur($event)">
        </app-search-producteur-organise-bar>


      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" style="background:#41905c;border:1px solid #41905c"
                data-dismiss="modal" aria-label="Close"translate>page.inventaire-verger.buttonEnregistrer
        </button>
      </div>
    </form>
  </div>
</div>
