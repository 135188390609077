import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationContext, SessionContext } from 'src/app/core/services/config/app.settings';
import { DeclarationIgp } from 'src/app/data/declaration/models/declaration-igp.model';
import { DeclarationsService } from 'src/app/data/declaration/services/declarations/declarations.service';
import { Produit } from 'src/app/data/habilitation/models/produit.model';

@Component({
  selector: 'app-donnees-dr',
  templateUrl: './donnees-dr.component.html',
  styleUrls: ['./donnees-dr.component.scss']
})
export class DonneesDrComponent implements OnInit {
  public declarationsIgp: DeclarationIgp[];
  public annee: number;
  public syntheseProduit: Produit;
  public formValid: boolean = true;
  public total: {};
  constructor(
    private readonly declarationsService: DeclarationsService,
    private readonly modal: NgbActiveModal
  ) { }

  ngOnInit() {
    if(this.annee && this.syntheseProduit) {
      this.declarationsService.getDrevIgp(this.idIntervenant, this.annee.toString(), this.syntheseProduit.id).subscribe(result => {
        this.declarationsIgp = result;
        this.calculTotal();
      });
    }
  }
  
  private get idIntervenant(): number {
    if (NavigationContext.has('idOperateur')) {
      return NavigationContext.get('idOperateur');
    } else {
      return SessionContext.get('idIntervenant');
    }
  }

  public dismiss() : void {
    this.modal.dismiss();
  }

  public validerDr() : void {
    this.declarationsService.updateDrevIgp(this.declarationsIgp, this.idIntervenant).subscribe(result => {
      this.modal.close();
    });
  }

  public valideValue() : void {
    this.formValid = true;
    this.declarationsIgp.forEach(item => {
      if(!item.surfaceModifiee || !item.volumeModifie) {
        this.formValid=false;
        return;
      }
    });
    this.calculTotal();
  }

  private calculTotal() : void {
    let surface: number = 0;
    let volume: number = 0;
    let surfaceModifiee: number = 0;
    let volumeModifie: number = 0;
    this.declarationsIgp.forEach(item => {
      surface = surface + item.surface;
      volume = volume + item.volume;
      surfaceModifiee = surfaceModifiee + item.surfaceModifiee;
      volumeModifie = volumeModifie + item.volumeModifie;
    });
    this.total = {
      volume: volume,
      surface: surface,
      volumeModifie: volumeModifie,
      surfaceModifiee: surfaceModifiee
    };
  }
}
