<div class="content">
  <div class="content-panel" *ngIf="utilisateur">
    <div class="title-bar">
      <app-utilisateur-statut *ngIf="!creation" [utilisateur]="utilisateur"></app-utilisateur-statut>
      <h4>
        {{ title | translate:utilisateur }}
      </h4>
     
    </div>
   
    
    <form [formGroup]="formGroup" #form="ngForm" (submit)="onSubmit()" novalidate *hasRole="'INTERVENANT_UTILISATEUR_ECRITURE'; elseDisabled">
      <div class="m-3">
        <div class="form-group" *ngIf="isAnpp()">
          <label style="margin-right: 20px;">Salarié anpp</label>
          <input type="radio" id="oui" value="OUI" [checked]="isSalarieAnpp" (change)="onSalarieAnppCheckedChange($event)" /> Oui
          <input type="radio" id="non" value="NON" [checked]="!isSalarieAnpp" (change)="onSalarieAnppCheckedChange($event)" style="margin-left: 20px;" /> Non
        </div>
        <div class="form-group" *ngIf="!isAnpp()">
          <label style="margin-right: 20px;">Utilisateur anpp</label>
          <input type="radio" id="oui" value="OUI" [checked]="isUtilisateurAnpp"  (change)="onUtilisateurAnppCheckedChange($event)" /> Oui
          <input type="radio" id="non" value="NON" [checked]="!isUtilisateurAnpp" (change)="onUtilisateurAnppCheckedChange($event)" style="margin-left: 20px;" /> Non
        </div>
        <div class="form-group">
          <label style="margin-right: 20px;">Conseiller technique</label>
          <input type="radio" id="oui" value="OUI" [checked]="isConseillerTechnique" [disabled]="disableIsCt" (change)="onEstConseillerTechniqueCheckedChange($event)" /> Oui
          <input type="radio" id="non" value="NON" [checked]="!isConseillerTechnique" [disabled]="disableIsCt" (change)="onEstConseillerTechniqueCheckedChange($event)" style="margin-left: 20px;" /> Non
        </div>
        <div class="form-group" *ngIf="!isAnpp()">
          <label for="type" translate>page.admin.utilisateur.form.label.type</label>

          <select id="type" formControlName="type" (change)="onTypeChange(type)" class="form-control">
            <option *ngFor="let type of types" [ngValue]="type">{{type.libelle}}</option>
          </select>

          <ng-container *ngIf="form.submitted">
            <div *ngIf="type.errors?.required" class="form-error" translate>label.obligatoire</div>
          </ng-container>
        </div>

        <div class="form-group">
          <label for="email" translate>page.admin.utilisateur.form.label.email</label>

          <input type="email" id="email" formControlName="email"
            [ngClass]="{'is-invalid': form.submitted && email.invalid}" class="form-control" />

          <ng-container *ngIf="form.submitted">
            <div *ngIf="email.errors?.required" class="form-error" translate>label.obligatoire</div>
          </ng-container>
          <ng-container *ngIf="form.submitted">
            <div *ngIf="email.errors?.email" class="form-error" translate>label.format-email</div>
          </ng-container>
        </div>

        <div class="form-group">
          <label for="nom" translate>page.admin.utilisateur.form.label.nom</label>

          <input type="text" id="nom" formControlName="nom" [ngClass]="{'is-invalid': form.submitted && nom.invalid}"
            class="form-control" />

          <ng-container *ngIf="form.submitted">
            <div *ngIf="nom.errors?.required" class="form-error" translate>label.obligatoire</div>
          </ng-container>
        </div>

        <div class="form-group">
          <label for="prenom" translate>page.admin.utilisateur.form.label.prenom</label>

          <input type="text" id="prenom" formControlName="prenom" [ngClass]="{'is-invalid': form.submitted && prenom.invalid}"
            class="form-control" />

          <ng-container *ngIf="form.submitted">
            <div *ngIf="prenom.errors?.required" class="form-error" translate>label.obligatoire</div>
          </ng-container>
        </div>

        <div class="form-group" *ngIf="showEntreprise">
          <label for="entreprise" translate>page.admin.utilisateur.form.label.entreprise</label>

          <select id="entreprise" formControlName="entreprise" class="form-control" [ngClass]="{'is-invalid': form.submitted && entreprise.invalid}"
          (change)="onEntrepriseChange(entreprise.value)">
            <option *ngFor="let entreprise of entreprises" [ngValue]="entreprise">{{entreprise.raisonSociale}}</option>
          </select>
        
          <ng-container *ngIf="form.submitted">
            <div *ngIf="entreprise.errors?.required" class="form-error" translate>label.obligatoire</div>
          </ng-container>
        </div>
      </div>

      <div class="actions-bar" fxLayoutAlign="space-between">
        <button class="btn btn-primary" [routerLink]="'../../'" translate>label.retour</button>

        <button type="submit" class="btn btn-primary" *hasRole="'INTERVENANT_UTILISATEUR_ECRITURE'" translate>{{creation
          ? 'label.suivant': 'label.valider'}}</button>
      </div>
    </form>
  </div>
</div>
