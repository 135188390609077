<div class="content">
  <div fxLayout="column">

    <div class="content-panel">
      <div class="title-bar">
        <h4>
          {{ 'page.admin.utilisateur.droits.title' | translate:utilisateur }}
        </h4>
      </div>

      <form [formGroup]="formGroup" #form="ngForm" (submit)="submit()" novalidate>
        <table table class="table" *hasRole="'INTERVENANT_UTILISATEUR_ECRITURE'">
          <thead>
            <tr>
              <th *hasRole="'ADMIN'" scope="col">Organisme</th>
              <th *hasRole="'ADMIN'" scope="col">Domaine</th>

              <th *ngFor="let service of services" scope="col">{{service.libelleCourt}}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let organisme of filteredOrganismes | orderBy:'raisonSociale'; odd as isOdd"
              formGroupName="{{organisme.id}}">
              <tr *ngFor="let domaine of organisme.domaines | orderBy:'libelle'; first as isFirst" formGroupName="{{domaine.id}}"
                [ngClass]="{'odd': isOdd}">
                <ng-container *hasRole="'ADMIN'">
                  <td *ngIf="isFirst" [attr.rowspan]="organisme.domaines.length" class="organisme">{{organisme.raisonSociale}}</td>
                  <td>{{domaine.libelle}}</td>
                </ng-container>

                <td *ngFor="let service of services" [ngClass]="{'abonne': hasAbonnement(organisme, domaine, service)}">
                  <select *ngIf="hasProfils(organisme, service)" [compareWith]="compareProfils" formControlName="{{service.id}}"
                    class="form-control">
                    <option [ngValue]="undefined"></option>
                    <option *ngFor="let profil of getProfils(organisme, service)" [ngValue]="profil">{{profil?.libelle}}</option>
                  </select>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>

        <div class="actions-bar">
          <button type="submit" class="btn btn-primary" *hasRole="'INTERVENANT_UTILISATEUR_ECRITURE'; else btnRetour"
            translate>label.valider</button>
          <ng-template #btnRetour>
            <button class="btn btn-primary" [routerLink]="'../../'" translate>label.retour</button>
          </ng-template>
        </div>
      </form>
    </div>

    <ng-container *hasRole="'INTERVENANT_UTILISATEUR_ECRITURE'">
      <div *ngIf="otherOrganismes && otherOrganismes.length > 0" class="content-panel actions-bar">
        <div fxLayout="row" fxLayoutAlign="end" class="form-inline">
          <select [(ngModel)]="organismeSelected" (change)="selectOrganisme()" class="form-control mr-2">
            <option *ngFor="let organisme of otherOrganismes" [ngValue]="organisme">{{organisme.raisonSociale}}</option>
          </select>

          <select *ngIf="organismeSelected" [(ngModel)]="domaineSelected" class="form-control mr-2">
            <option *ngFor="let domaine of organismeSelected.domaines" [ngValue]="domaine">{{domaine.libelle}}</option>
          </select>

          <button type="button" (click)="addProfil()" [disabled]="!organismeSelected || !domaineSelected" class="btn btn-primary"
            translate>page.admin.utilisateur.droits.ajouter</button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
