<div class="row mt-4 pt-4" *ngIf="operateur">
  <div class="col-lg-12">
    <div class="cards  border-radius text-center" style="background:#f8faff">
      <div class="row">
        <div class="col-lg-4 text-center">
          <h4 class="text-grey pl-4 pt-4 pb-0  m-0"> Tableau de Bord </h4>

          <div class=" border-radius pt-2 text-center">

            <img src="/assets/img/user.svg" width="50%" /><br />
            <strong style="font-size:22px">{{operateur.prenom}} {{operateur.nom | uppercase}}</strong><br />
            <a (click)="goToDetail()">
              <fa-icon icon="map-marked-alt" class="mt-2 p-2" style="font-size:20px"></fa-icon><strong>{{nbSites}}
                Site(s)</strong>
              <fa-icon icon="chevron-right" class="ml-2"></fa-icon>
            </a>
          </div>
        </div>
        <div class="col-lg-4 text-right pt-2">
          <ul class="list-group list-group-flush text-left border-radius" style="font-size:13px">
            <li class="list-group-item  bg-violet ml-4 mr-4"><small>Statut juridique</small>
              <strong>{{operateur.structureJuridique}}</strong>
            </li>
            <li class="list-group-item bg-violet ml-4 mr-4"><small>Raison sociale</small>
              <strong>{{operateur.raisonSociale}}</strong>
            </li>
            <li class="list-group-item bg-violet ml-4 mr-4"><small>N° SIRET</small> <strong>{{operateur.siret}}</strong>
            </li>
            <li class="list-group-item  bg-violet ml-4 mr-4"><small>Adresse</small>
              <strong>{{operateur.adresse}} {{operateur.codePostal}} {{operateur.ville}}
              </strong>
            </li>
            <li class="list-group-item  bg-violet ml-4 mr-4"><small>Téléphone</small>
              <strong *ngIf="operateur.telephoneMobile">{{operateur.telephoneMobile | phoneFormat}}</strong>
            </li>
            <li class="list-group-item  bg-violet ml-4 mr-4"><small>Email</small>
              <strong>{{operateur.email}}</strong>
            </li>
          </ul>
          <a (click)="goToDetail()"><strong>Détails </strong>
            <fa-icon icon="chevron-right" class="right"></fa-icon>
          </a>
        </div>
        <div class="col-lg-4 text-left pt-2 border-radius">
          <fa-icon icon="clipboard-check"></fa-icon><strong class="mt-4"> Habilitations</strong>
          <div id="main" style="height:300px;" echarts [options]="chartHabilitation"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-4">
  <div class="col-lg-6">
    <div class="cards  border-radius p-4 pb-2 text-center">
      <fa-icon icon="file-contract" class="mt-2 p-2" style="font-size:20px"></fa-icon><strong> Télédéclarations</strong>
      <div class="row">
        <div class="col-lg-4">
          <div class=" border-radius p-2" (click)="goToSynthese()" style="cursor: pointer;">
            <div class=" badge-light  badge-light-blue">
              <fa-icon icon="balance-scale"></fa-icon>
            </div>
            <p><small style="display:block;">Synthèse</small></p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class=" border-radius p-2" (click)="goToImport()" style="cursor: pointer;">
            <div class=" badge-light  badge-light-blue">
              <fa-icon icon="file-alt"></fa-icon>
            </div>
            <p><small style="display:block;">Import Prodouane</small></p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class=" border-radius p-2" (click)="goToHistorique()" style="cursor: pointer;">
            <div class=" badge-light  badge-light-blue">
              <fa-icon icon="folder-open"></fa-icon>
            </div>
            <p><small style="display:block;">Historique</small></p>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="col-lg-4">
    <div class="cards  border-radius  p-4 pb-2 text-center">
      <fa-icon icon="clipboard-check" class="mt-2 p-2" style="font-size:20px"></fa-icon><strong> Contrôles</strong>
      <div class="row">



        <div class="col-lg-6">
          <div class=" border-radius p-2">
            <div class=" badge-light  badge-light-vert">
              -
            </div>
            <p><small style="display:block;">contrôle(s) produit en cours</small></p>
          </div>
        </div>

        <div class="col-lg-6">
          <div class=" border-radius p-2">
            <div class=" badge-light  badge-light-red">
              {{nbManquements}}
            </div>
            <p><small style="display:block;">manquement(s)</small></p>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="col-lg-2" *ngIf="!estOperateur && urlFacturationPanel">
    <div class="cards  border-radius  p-4  text-left cursor" (click)= "goToFactures()">
      <fa-icon icon="euro-sign"  class="p-2" style="font-size:20px"></fa-icon><strong> Factures</strong>
      <div class="row">
      </div>
    </div>
  </div>
</div>