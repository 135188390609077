import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { sortBy } from 'lodash';

import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Habilitation } from '../../models/habilitation.model';
import { StatutHabilitation } from '../../models/statut-habilitation.model';
import { ActivitesService } from '../activites/activites.service';
import { CahiersService } from '../cahiers/cahiers.service';
import { ReferencesService } from '../references/references.service';
import { ClearCache, Cache, CacheKey } from 'src/app/core/services/cache/cache.service';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { HabilitationExport } from 'src/app/data/edition/models/habilitation-export.model';

@Injectable({
  providedIn: 'root'
})
export class HabilitationsService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly activitesService: ActivitesService,
    private readonly cahiersService: CahiersService,
    private readonly referencesService: ReferencesService,
    private readonly operateursService: OperateursService
  ) { }

  @ClearCache
  clearCache() { }

  @Cache({ maxAge: 60 })
  public getOperateurHabilitations(@CacheKey idOperateur: number): Observable<Habilitation[]> {
    const url = `/api/habilitation/private/operateurs/${idOperateur}/habilitations`;

    return forkJoin([
      this.http.get<object[]>(url),
      this.activitesService.getActivites(), // activites must be loaded before deserialisation
      this.cahiersService.getCahiers(),
      this.referencesService.getReferences()
    ])
      .pipe(
        map(([habiltations, activites, cahiers, ref]) => this.mapper.deserializeArray(habiltations, Habilitation, Object.assign({ activites, cahiers }, ref))),
        map((habiltations) => habiltations.map(habilitation => {
          habilitation.historique = sortBy(habilitation.historique, 'id').reverse();
          return habilitation;
        }))
      );
  }

  public getOrganismeHabilitations(idOrganisme: number): Observable<HabilitationExport[]> {
    const url = `/api/habilitation/private/organismes/${idOrganisme}/habilitations`;

    return forkJoin([
      this.http.get<object[]>(url),
      this.activitesService.getActivites(), // activites must be loaded before deserialisation
      this.cahiersService.getCahiers(),
      this.referencesService.getReferences()
    ])
      .pipe(
        map(([habiltations, activites, cahiers, ref]) => this.mapper.deserializeArray(habiltations, HabilitationExport, Object.assign({ activites, cahiers }, ref))),
        map((habiltations) => habiltations.map(habilitation => {
          habilitation.historique = sortBy(habilitation.historique, 'id').reverse();
          return habilitation;
        }))
      );
  }

  public getOrganismeCountHabilitations(idOrganisme: number): Observable<Map<String, Number>> {
    const url = `/api/habilitation/private/organismes/${idOrganisme}/habilitations/count`;

    return this.http.get<Map<string, number>>(url).pipe();
      
  }

  @Cache({ maxAge: 60 })
  public getHabilitations(): Observable<Habilitation[]> {
    const url = `/api/habilitation/private/habilitations`;

    return forkJoin([
      this.http.get<object[]>(url),
      this.activitesService.getActivites(), // activites must be loaded before deserialisation
      this.cahiersService.getCahiers(),
      this.referencesService.getReferences()
    ])
      .pipe(
        map(([habiltations, activites, cahiers, ref]) => this.mapper.deserializeArray(habiltations, Habilitation, Object.assign({ activites, cahiers }, ref))),
      );
  }

  public createHabilitation(idOperateur: number, habilitation: Habilitation): Observable<number> {
    const url = `/api/habilitation/private/operateurs/${idOperateur}/habilitations`;

    return this.http.post(url, this.mapper.serialize(habilitation), { observe: 'response' }).pipe(
      map(response => parseInt(response.headers.get('location').split('/').pop(), 10)),
      tap(() => this.clearCache())
    );
  }

  public changeStatut(idOperateur: number, idHabilitation: number, statut: StatutHabilitation): Observable<void> {
    const url = `/api/habilitation/private/operateurs/${idOperateur}/habilitations/${idHabilitation}/statuts`;

    return this.http.post(url, this.mapper.serialize(statut)).pipe(
      map(() => { }),
      tap(() => this.clearCache())
    );
  }

  public createHabilitationsListe(idOperateur: number, habilitations: Habilitation[]): Observable<Habilitation[]> {
    const url = `/api/habilitation/private/operateurs/${idOperateur}/habilitations-liste`;

    return forkJoin(
      this.http.post<object[]>(url, this.mapper.serialize(habilitations)),
      this.activitesService.getActivites(), // activites must be loaded before deserialisation
      this.cahiersService.getCahiers(),
      this.referencesService.getReferences()
    ).pipe(
      map(([habiltations, activites, cahiers, ref]) => this.mapper.deserializeArray(habiltations, Habilitation, Object.assign({ activites, cahiers }, ref))),
      tap(() => this.clearCache())
    );
  }

  public changeStatutsListe(idOperateur: number, statuts: StatutHabilitation[]): Observable<void> {
    const url = `/api/habilitation/private/operateurs/${idOperateur}/habilitations-statuts-liste`;

    return this.http.post(url, this.mapper.serialize(statuts)).pipe(
      map(() => { }),
      tap(() => this.clearCache())
    );
  }
}
