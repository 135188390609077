<div class="content">
  <div class="content-panel">
    <div class="searchBar">
      <label for="searchBar-input" translate>page.admin.utilisateur.liste.table.filtrer</label>
      <input type="text" id="searchBar-input" (ngModelChange)="searchStringUpdate.next($event)" [(ngModel)]="searchStringInput"
        placeholder="{{'label.filtre'|translate}}">
    </div>
    <table *ngIf="droits" table class="table table-striped">
      <thead>
        <tr>
          <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'__defaultOrder', libelle:'Email'}"></ng-container>
          <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'profil.type.code', libelle:'Type'}"></ng-container>
          <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'profil.domaine.libelle', libelle:'Domaine'}"></ng-container>
          <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'profil.service.libelleCourt', libelle:'Service'}"></ng-container>
          <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'profil.organisme.raisonSociale', libelle:'Organisme'}"></ng-container>
          <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'profil.profil.libelle', libelle:'Profil'}"></ng-container>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let droit of droits | filter : '*': searchString | orderBy:order.property:order.reverse:true; let idx = index;">
          <tr >
            <td [ngClass]="{'inactif':droit.profil.intervenant?.actif === false}">{{droit.profil.intervenant?.mail}}</td>
            <td [ngClass]="{'inactif':droit.profil.type?.actif === false}">{{droit.profil.type?.code}}</td>
            <td [ngClass]="{'inactif':droit.profil.domaine?.actif === false}">{{droit.profil.domaine?.libelle}}</td>
            <td>{{droit.profil.service?.libelleCourt}}</td>
            <td [ngClass]="{'inactif':droit.profil.organisme?.actif === false}">{{droit.profil.organisme?.raisonSociale}}</td>
            <td [ngClass]="{'inactif':droit.profil.profil?.actif === false}">{{droit.profil.profil?.libelle}}</td>
          </tr>
          <tr>
            <td colspan="6" class="liste-roles">
              <div>
                <fa-icon *ngIf="!droit.display_data" icon="plus" (click)="droit.display_data = true;"></fa-icon>
                <fa-icon *ngIf="droit.display_data" icon="minus" (click)="droit.display_data = false;"></fa-icon>
                Données
              </div>
              <pre *ngIf="droit.display_data" class="columns">{{droit.profil | json}}</pre>
              <div>
                <fa-icon *ngIf="!droit.display_roles" icon="plus" (click)="droit.display_roles = true;"></fa-icon>
                <fa-icon *ngIf="droit.display_roles" icon="minus" (click)="droit.display_roles = false;"></fa-icon>
                Droits fonctionnels et roles
              </div>
              <ul *ngIf="droit.display_roles" class="columns">
                <li *ngFor="let fct of droit.fonctionnels" class="columns-nobreak" [title]="fct.id+' - '+fct.code">
                  {{fct.libelle}}
                  <ul>
                    <li *ngFor="let role of fct.roles" [title]="role.id+' - '+role.code">{{role.libelle}}</li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>


<ng-template #tableHeader let-property="sortProperty" let-libelle="libelle">
  <th *ngIf="property" (click)="setOrder(property)" [class.sortable]="property">
    {{libelle | translate}}
    <fa-icon *ngIf="property" [icon]="getIcon(property)"></fa-icon>
  </th>
  <th *ngIf="!property">{{libelle | translate}}</th>
</ng-template>
