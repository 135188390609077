import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class HabilitationStatutWorkflow {

  @JsonProperty('id', Number)
  public id: number = undefined;

  @JsonProperty('idRefStatutOld', Number)
  public idStatut: number = undefined;

  @JsonProperty('idRefStatutNew', Number)
  public idNextStatut: number = undefined;
}
