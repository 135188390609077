import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class Groupe {

  @JsonProperty('id', Number)
  @Optional
  public id: number = undefined;

  @JsonProperty('code', String)
  @Optional
  public code: string = undefined;

  @JsonProperty('libelle', String)
  @Optional
  public libelle: string = undefined;
}