import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { remove } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, of } from 'rxjs';
import { Campagne } from 'src/app/data/declaration/models/campagne.model';
import { GroupeVarietalParam } from 'src/app/data/declaration/models/groupe-varietal-param.model';
import { GroupeVarietalVariete } from 'src/app/data/declaration/models/groupe-varietal-variete.model';
import { GroupeVarietal } from 'src/app/data/declaration/models/groupe-varietal.model';
import { Variete } from 'src/app/data/declaration/models/variete.model';
import { GroupeVarietalService } from 'src/app/data/declaration/services/groupe-varietal/groupe-varietal.service';
import { VarieteService } from 'src/app/data/declaration/services/parcellaire/variete.service';
import { RefCampagnesService } from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';

@Component({
  selector: 'app-edit-groupe-varietal',
  templateUrl: './edit-groupe-varietal.component.html',
  styleUrls: ['./edit-groupe-varietal.component.scss']
})
export class EditGroupeVarietalComponent implements OnInit {

  formGroup: FormGroup;
  creation = false;
  title: string;
  groupeVarietal: GroupeVarietalParam;
  groupesVarietaux: GroupeVarietalParam[];

  varietes: Variete[];
  varietes_groupe: Variete[];
  varietes_libre: Variete[];

  varietes_utilisees: Variete[];

  get code() { return this.formGroup.get('code'); }
  get libelle() { return this.formGroup.get('libelle'); }
  get variete_groupe() { return this.formGroup.get('variete_groupe'); }
  get varietes_libres() { return this.formGroup.get('varietes_libres'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly refCampagnesService: RefCampagnesService,
    private readonly varieteService: VarieteService,
    private readonly groupevarietalService: GroupeVarietalService,
    private toastrService: ToastrService
    ) { }

  ngOnInit() {
    const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    if (!id) {
      this.creation = true;
    }

    forkJoin(
      this.refCampagnesService.getCampagneEnCours()
    )
    .pipe(
      this.loaderService.operator()
    ).subscribe((anneeRef) => {
      this.loadData(id, anneeRef);
    }
    );
      
  }

  loadData(id: number, annee: Campagne) {

    this.title = this.creation ? 'page.valeur_parametrable.form.title.add_groupevarietal': 'page.valeur_parametrable.form.title.edit_groupevarietal';

    forkJoin(
    
    this.varieteService.getVarietes(),
    this.groupevarietalService.getGroupeVarietaux()
    )
    .pipe(
      this.loaderService.operator()
    ).subscribe(([varietes, groupeVarietaux]) => {

      if(!this.creation){
        this.groupeVarietal = groupeVarietaux.filter(gv => gv.id === id)[0];
      }
      else{
        this.groupeVarietal =  new GroupeVarietalParam();
      }
      this.groupesVarietaux = groupeVarietaux;    
      this.varietes = varietes;
      this.varietes_utilisees = [];
      this.groupesVarietaux.map(t =>{ 
        t.varietes.map(
          v => this.varietes_utilisees.push(this.varietes.find(variete => variete.id === v.idVariete)) 
          ) ; 
      });
      this.varietes_libre = this.varietes.filter(variete => variete.espece.code === 'POMME' ||  variete.espece.code === 'POIRE'  ).filter(variete =>  !this.varietes_utilisees.find(v => v.id === variete.id));      

      if(this.groupeVarietal.varietes){
        this.varietes_groupe = this.varietes.filter(variete =>  this.groupeVarietal.varietes.find(v => v.idVariete === variete.id));
      }
      else this.varietes_groupe = [];

      this.initForm((this.groupeVarietal.id) ? this.groupeVarietal : null);     
    });
  }

  private initForm(groupeVarietal?: GroupeVarietalParam): void {
 
    this.formGroup = this.fb.group({
      variete_groupe: [{ value: ((groupeVarietal) ? this.varietes_groupe: undefined), disabled: false }],
      varietes_libres: [{ value: ((groupeVarietal) ? this.varietes_libre: undefined), disabled: false }],
      libelle: [{ value: ((groupeVarietal) ? groupeVarietal.libelle : undefined), disabled: false }, Validators.required],
      code: [{ value: ((groupeVarietal) ? groupeVarietal.code : undefined), disabled: false }, Validators.required]
    });
  }

  removeDep(indexDep: number,variete: Variete) {

    remove(this.variete_groupe.value, variete);
    this.varietes_libre.push(variete);

    this.formGroup.get(`variete_groupe`).updateValueAndValidity();
    this.formGroup.get('varietes_libres').updateValueAndValidity();
    
    this.supprimerGroupeVarietalVariete(variete.id);

  }

  canDropDep(drag: CdkDrag<Variete>, drop: CdkDropList<Variete[]>) {
    return drop.data.findIndex(gvv => gvv.id === drag.data.id) === -1;
  }

  moveVar(event: CdkDragDrop<Variete[]>) {
    remove(this.variete_groupe.value, event.item.data);

    this.varietes_libre.push(event.item.data);
    
    this.formGroup.get('variete_groupe').updateValueAndValidity();
    this.formGroup.get('varietes_libres').updateValueAndValidity();
    
    this.supprimerGroupeVarietalVariete(event.item.data.id);
  }

  moveVarLibre(event: CdkDragDrop<Variete[]>) {

      if(this.varietes_groupe.length == 0 || (this.varietes_groupe.length > 0 && this.varietes_groupe[0].espece.id === event.item.data.espece.id) )
      {
        
        remove(this.varietes_libres.value, event.item.data);

        this.varietes_groupe.push(event.item.data);

        this.formGroup.get('variete_groupe').updateValueAndValidity();
        this.formGroup.get('varietes_libres').updateValueAndValidity();

        this.creerGroupeVarietalVariete(event.item.data.id);
        
      }
      else{
        this.toastrService.error('Dans un groupe variétal, il ne peut pas y avoir plusieurs espèces');
      }

    

  }

  creerGroupeVarietalVariete(id: number){
    const groupeVarietalVariete = new GroupeVarietalVariete();

    groupeVarietalVariete.idGroupeVarietal = this.groupeVarietal.id;
    groupeVarietalVariete.idVariete = id;
    
    this.groupevarietalService.creerGroupeVarietalVariete(groupeVarietalVariete).subscribe( () => {} );;
  }

  supprimerGroupeVarietalVariete(id: number){
   
    const groupeVarietalVariete = this.groupeVarietal.varietes.find(v => v.idVariete === id);
    
    this.groupevarietalService.supprimerGroupeVarietalVariete(groupeVarietalVariete).subscribe( () => {} );;
  }

  submit() {
    if (this.formGroup.valid) {

      this.groupeVarietal.libelle = this.libelle.value;
      this.groupeVarietal.code = this.code.value;
     
      if (this.groupeVarietal.id == null) {
        this.groupevarietalService.creerGroupeVarietal(this.groupeVarietal).subscribe(idGroupeVarietal => { 
          this.groupeVarietal.id = idGroupeVarietal;
          this.creation = false;
          this.initForm(this.groupeVarietal);
        });
      } else {            
        this.groupevarietalService.modifierGroupeVarietal(this.groupeVarietal).subscribe( () => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
         } );
      }
     
    }
  }

}
