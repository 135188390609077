import {JsonObject, JsonProperty, Optional} from '@thorolf/json-ts-mapper';
import {RefSystemeIrrigation} from './ref-systeme-irrigation.model';
import {RefProtectionGel} from './ref-protection-gel.model';
import {RefProtectionGrele} from './ref-protection-grele.model';
import {RefCultureBio} from './ref-culture-bio.model';
import {RefForme} from './ref-forme.model';
import {RefSituation} from './ref-situation.model';
import { RefModeConduite } from './ref-mode-conduite.model';

@JsonObject
export class CaracteristiqueParcelle {

  @JsonProperty('id', Number)
  @Optional
  public id: number = undefined;

  @JsonProperty('refSystemeIrrigation', RefSystemeIrrigation)
  @Optional
  public refSystemeIrrigation: RefSystemeIrrigation = undefined;

  @JsonProperty('refProtectionGel', RefProtectionGel)
  @Optional
  public refProtectionGel: RefProtectionGel = undefined;

  @JsonProperty('refProtectionGrele', RefProtectionGrele)
  @Optional
  public refProtectionGrele: RefProtectionGrele = undefined;

  @JsonProperty('refCultureBio', RefCultureBio)
  @Optional
  public refCultureBio: RefCultureBio = undefined;

  @JsonProperty('refForme', RefForme)
  @Optional
  public refForme: RefForme = undefined;

  @JsonProperty('refSituation', RefSituation)
  @Optional
  public refSituation: RefSituation = undefined;

  @JsonProperty('refModeConduite', RefModeConduite)
  @Optional
  public refModeConduite: RefModeConduite = undefined;

  @JsonProperty('derogationTaille', Boolean)
  @Optional
  derogationTaille: boolean;
}
