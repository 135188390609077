import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RefSystemeIrrigation} from '../../models/ref-systeme-irrigation.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RefSystemeIrrigationService {

  constructor( private readonly http: HttpClient) { }

  getRefSystemeIrrigation(): Observable<RefSystemeIrrigation[]> {
    return this.http.get<RefSystemeIrrigation[]>(`/api/declaration/private/referentielSystemeIrrigation`);
  }

  public creerRefSystemeIrrigation(irrigation: RefSystemeIrrigation): Observable<number> {
    return this.http.post(`/api/declaration/private/referentielSystemeIrrigation`, irrigation, { observe: 'response' }).pipe(
      map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
    );
  }  
  
  public modifierRefSystemeIrrigation(irrigation: RefSystemeIrrigation ): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/referentielSystemeIrrigation/${irrigation.id}`, irrigation );
  }
  
  public desactiverRefSystemeIrrigation(irrigation: RefSystemeIrrigation): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/referentielSystemeIrrigation/${irrigation.id}?desactivation=${true}`, irrigation );
  }
}
