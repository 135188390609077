
import { JsonObject, JsonProperty, CustomConverter, Optional } from "@thorolf/json-ts-mapper";
import { DateTimeConverter } from "src/app/core/services/mapper/converters";
import { DataIdConverterFactory } from "src/app/core/services/mapper/data-id.converter";
import { Conditionnement } from "../../declaration/models/conditionnement.model";
import { StatutEchantillon } from "../../declaration/models/statut-echantillon.model";
import { Produit } from "../../habilitation/models/produit.model";
import { OperateurInformationsDomaine } from "../../intervenant/models/operateur-informations-domaine.model";
import { Operateur } from "../../intervenant/models/operateur.model";
import { Organisme } from "../../intervenant/models/organisme.model";
import { Site } from "../../declaration/models/site.model";

@JsonObject
export class TourneeEchantillon {
  @JsonProperty('id', Number)
  id: number;

  @JsonProperty('codeProduit', String)
  codeProduit: string;

  produit: Produit;

  @JsonProperty('idOperateur', Number)
  idOperateur: number;
  infosOperateur: OperateurInformationsDomaine[] = [];
  operateur: Operateur;

  @JsonProperty('idMouvement', Number)
  @Optional
  idMouvement: number;

  @JsonProperty('millesime', Number)
  @Optional
  millesime: number;

  @JsonProperty('numero', String)
  @Optional
  numero: string;

  @JsonProperty('ordre', Number)
  @Optional
  ordre: number;
  
  @JsonProperty('codeStatut', String)
  @Optional
  codeStatut: string;

  @JsonProperty('logement', String)
  @Optional
  logement: string;

  @JsonProperty('volume', Number)
  @Optional
  volume: number;

  @JsonProperty('idSite', Number)
  @Optional
  idSite: number;
  site: Site;

  statut: StatutEchantillon;
}
