import { JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";
import { RefSoustypeUtilisateur } from "../../droits/models/ref-soustype-utilisateur.model";
import { Fonction } from "./fonction.model";
import { Groupe } from "./groupe.models";

@JsonObject
export class ContactGroupe {

    @JsonProperty('idContact', Number)
    @Optional
    public idContact: number = undefined;
  
    @JsonProperty('nom', String)
    @Optional
    public nom: string = undefined;

    @JsonProperty('prenom', String)
    @Optional
    public prenom: string = undefined;
  
    @JsonProperty('raisonsociale', String)
    @Optional
    public raisonsociale: string = undefined;  
  
    @JsonProperty('fonction', Fonction)
    @Optional
    public fonction : Fonction = undefined;
    
    @JsonProperty('groupes', [Groupe])
    @Optional
    public groupes: [Groupe] = undefined;

    @JsonProperty('refSousType', RefSoustypeUtilisateur)
    @Optional
    public refSousType: RefSoustypeUtilisateur = undefined;

}
