import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class Navigator {

  @JsonProperty('version', String)
  public version: string = undefined;

  @JsonProperty('platform', String)
  public platform: string = undefined;

  @JsonProperty('locale', String)
  public locale: string = undefined;
}
