import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Routes, Router } from '@angular/router';

import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';

import { RechercheOperateurAffilieComponent } from './pages/recherche-operateur-affilie/recherche-operateur-affilie.component';
import { OperateurDashboardComponent } from './pages/operateur-dashboard/operateur-dashboard.component';
import { NavigationContext } from 'src/app/core/services/config/app.settings';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { Operateur } from 'src/app/data/intervenant/models/operateur.model';
import { AjoutOperateurComponent } from './pages/ajout-operateur/ajout-operateur.component';
import { OperateurHabilitationsComponent } from './pages/operateur-habilitations/operateur-habilitations.component';
import { InformationsOperateurComponent } from './pages/informations-operateur/informations-operateur.component';
import { SyntheseVolumesComponent } from '../declarations/pages/synthese-volumes/synthese-volumes.component';
import { HistoriqueComponent } from '../declarations/pages/historique/historique.component';
import { ConsultationDrevComponent } from '../declarations/pages/consultation-drev/consultation-drev.component';
import { AffichageDrComponent } from '../declarations/pages/affichage-dr/affichage-dr.component';
import { AffichageDrevComponent } from '../declarations/pages/affichage-drev/affichage-drev.component';
import { DeclarationsComponent } from '../declarations/declarations.component';
import { ParcellairesComponent } from '../declarations/pages/parcellaires/parcellaires.component';
import { ParcellaireViticoleComponent } from '../parcellaire-viticole/parcellaire-viticole.component';

@Component({
  selector: 'app-operateurs',
  templateUrl: './operateurs.component.html',
  styleUrls: ['./operateurs.component.scss']
})
export class OperateursComponent implements OnInit {
  static routes: Routes = [
    { path: '', redirectTo: 'or', pathMatch: 'full' },
    {
      path: 'or', data: {
        role: 'ORGANISME',
        domaines: ['VIN'],
        menu: {
          icon: 'users-cog',
          libelle: 'menu.operateurs'
        }
      },
      children: [
        { path: '', redirectTo: 'recherche-affilie', pathMatch: 'full' },
        { path: 'recherche-affilie', component: RechercheOperateurAffilieComponent },
        { path: 'ajout-operateur', component: AjoutOperateurComponent },
        {
          path: ':idOperateur', component: OperateursComponent, children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', children: OperateurDashboardComponent.routes },
            { path: 'habilitations', children: OperateurHabilitationsComponent.routes },
            { path: 'informations', children: InformationsOperateurComponent.routes },
            { path: 'parcellaire', component: ParcellaireViticoleComponent, data: { menu: { libelle: 'Parcellaires' }, role: ['DECLARATION_INVENTAIRE_VERGER_LECTURE'] }  }, 
            { path: 'synthese', children: SyntheseVolumesComponent.routes },
            { path: 'historique', children: HistoriqueComponent.routes },
            { path: 'dr', component: AffichageDrComponent, data: { menu: { libelle: 'Import Prodouane' } } }, // TODO delete data property
            { path: 'drev', component: AffichageDrevComponent },
            { path: 'consultation', children: ConsultationDrevComponent.routes }
          ]
        }
      ]
    },
    {
      path: 'op',
      data: { role: 'OPERATEUR' },
      children: [
        { path: '', redirectTo: 'home', pathMatch: 'full' },
        { path: 'home', children: OperateurDashboardComponent.routes },
        { path: 'informations', children: InformationsOperateurComponent.routes }
      ]
    }
  ];

  operateur: Operateur;

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly operateursService: OperateursService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) { }

  ngOnInit() {
    let idOperateur: number;
    if (this.route.snapshot.paramMap.has('idOperateur')) {
      idOperateur = parseInt(this.route.snapshot.paramMap.get('idOperateur'), 10);
    }

    if (Number.isInteger(idOperateur)) {
      NavigationContext.set('idOperateur', idOperateur);
      setTimeout(() => { this.loadOperateur(idOperateur); });
    } else {
      this.router.navigate(['recherche-affilie'], { relativeTo: this.route.parent });
    }
  }

  loadOperateur(idOperateur: number) {
    this.operateursService.getOperateur(idOperateur).pipe(
      this.loaderService.operator()
    ).subscribe(operateur => {
      this.operateur = operateur;
    });
  }

  changeOperateur() {
    NavigationContext.set('idOperateur', undefined);

    this.router.navigate(['recherche-affilie'], { relativeTo: this.route.parent });
  }
}
