import { AdvBootstrapLoaderModule } from '@adv/bootstrap-loader';
import { AdvBootstrapModalModule } from '@adv/bootstrap-modal';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { AppConfig } from 'src/app/core/services/config/app.config.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler/error-handler.service';
import { TokenInterceptor } from './interceptors/http-token.interceptor';

@NgModule({
  declarations: [],
  imports: [
    AdvBootstrapLoaderModule,
    AdvBootstrapModalModule,
    HttpClientModule,
    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AppConfig,
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfig], multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
    // { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
    // { provide: HTTP_INTERCEPTORS, useClass: KongInterceptor, multi: true }
  ]
})
export class CoreModule { }

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}
