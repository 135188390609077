import { JsonProperty, JsonObject, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class ControleManquementEdition {
    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('numero', String)
    @Optional
    numero: string;

    @JsonProperty('motif', String)
    @Optional
    motif: string;

    @JsonProperty('observation', String)
    @Optional
    observation: string;

    @JsonProperty('auteurConstat', String)
    @Optional
    auteurConstat: string;

    @JsonProperty('fonctionAuteur', String)
    @Optional
    fonctionAuteur: string;

    @JsonProperty('idAuteurConstat', Number)
    @Optional
    idAuteurConstat: number;
}
