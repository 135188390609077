<div class="modal-header">
  <h4 class="modal-title" translate>
    page.declarations.synthese.modal.donnees-dr.title
  </h4>
</div>

<div class="modal-body">
  <div *ngIf="declarationsIgp && declarationsIgp.length; else aucunProduit">
  <div class="content-panel" *ngIf="declarationsIgp">
      <table table class="table table-striped" style="margin-bottom: 20px;">
        <thead>
          <th class="liste-col-header" translate>page.declarations.synthese.modal.donnees-dr.table.produit</th>
          <th class="liste-col-header" translate>page.declarations.synthese.modal.donnees-dr.table.surface-prodouane</th>
          <th class="liste-col-header" translate>page.declarations.synthese.modal.donnees-dr.table.vol-prodouane</th>
          <th class="liste-col-header" translate>page.declarations.synthese.modal.donnees-dr.table.surface-modifiee</th>
          <th class="liste-col-header" translate>page.declarations.synthese.modal.donnees-dr.table.vol-modifie</th>
        </thead>
        <tbody>
          <tr *ngFor="let declaration of declarationsIgp">
            <td>{{declaration.produit?.libelle}}<span *ngIf="declaration.mention"> - {{declaration.mention}}</span></td>
            <td>{{declaration.surface | number:'1.4-4' }}</td>
            <td>{{declaration.volume | number:'1.2-2' }}</td>
            <td><input type="number" class="input" [(ngModel)]="declaration.surfaceModifiee" (change)="valideValue()" /></td>
            <td><input type="number" class="input" [(ngModel)]="declaration.volumeModifie" (change)="valideValue()" /></td>
          </tr>
          <tr>
            <td translate>page.declarations.synthese.modal.donnees-dr.table.total</td>
            <td>{{total.surface | number:'1.4-4' }}</td>
            <td>{{total.volume | number:'1.2-2' }}</td>
            <td>{{total.surfaceModifiee | number:'1.4-4' }}</td>
            <td>{{total.volumeModifie | number:'1.2-2' }}</td>
          </tr>
        </tbody>
      </table>
  </div>
  <div class="actions-bar">
    <button class="btn btn-light" (click)="dismiss()" translate>label.annuler</button>
    <button class="btn btn-primary" (click)="validerDr()" [disabled]="!formValid" translate>label.valider</button>
  </div>
</div>

<ng-template #aucunProduit>
  <div translate>
    page.declarations.synthese.modal.donnees-dr.aucune-donnee
  </div>
  <div class="actions-bar">
    <button class="btn btn-light" (click)="dismiss()" translate>label.annuler</button>
  </div>
</ng-template>