import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Organisme } from '../../models/organisme.model';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Cache, NoCache, CacheKey, ClearCache } from 'src/app/core/services/cache/cache.service';
import { ReferencesService } from '../references/references.service';

@Injectable({
  providedIn: 'root'
})
export class OrganismesService {

  static organismes: Organisme[] = [];

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly referencesService: ReferencesService
  ) { }

  static getOrganismes(): Organisme[] {
    return this.organismes;
  }

  @ClearCache
  clearCache() { }

  @Cache()
  getOrganismes(@NoCache noCache?: boolean): Observable<Organisme[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/intervenant/private/organismes`),
      this.referencesService.getReferences(noCache)
    )
      .pipe(
        map(([organismes, ref]) => this.mapper.deserializeArray(organismes, Organisme, ref)),
        tap(organismes => { OrganismesService.organismes = organismes; })
      );
  }

  @Cache()
  getOrganisme(@CacheKey idOrganisme: number, @NoCache noCache?: boolean): Observable<Organisme> {
    return forkJoin(
      this.http.get<object>(`/api/intervenant/private/organismes/${idOrganisme}`),
      this.referencesService.getReferences()
    )
      .pipe(
        map(([organisme, ref]) => this.mapper.deserializeObject(organisme, Organisme, ref)),
    );
  }

  createOrganisme(organisme: Organisme): Observable<number> {
    return this.http.post(`/api/intervenant/private/organismes`, this.mapper.serialize(organisme), { observe: 'response' })
      .pipe(
        tap(() => this.clearCache()),
        map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
      );
  }

  modifyOrganisme(organisme: Organisme): Observable<void> {
    return this.http.put(`/api/intervenant/private/organismes/${organisme.id}`, this.mapper.serialize(organisme))
      .pipe(
        tap(() => this.clearCache()),
        map(() => { })
      );
  }
}
