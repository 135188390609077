import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { RefProtectionGel } from 'src/app/data/declaration/models/ref-protection-gel.model';
import { RefProtectionGelService } from 'src/app/data/declaration/services/parcellaire/ref-protection-gel.service';

@Component({
  selector: 'app-edit-protection-gel',
  templateUrl: './edit-protection-gel.component.html',
  styleUrls: ['./edit-protection-gel.component.scss']
})
export class EditProtectionGelComponent implements OnInit {

  formGroup: FormGroup;
  creation = false;
  title: string;
  protectionGel: RefProtectionGel;

  get code() { return this.formGroup.get('code'); }
  get libelle() { return this.formGroup.get('libelle'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly protectionGelService: RefProtectionGelService,) { }

  ngOnInit() {

    const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    if (!id) {
      this.creation = true;
    }
      this.loadData(id);
    

  }

  loadData(id: number) {

    this.title = this.creation ? 'page.valeur_parametrable.form.title.add_protection_gel': 'page.valeur_parametrable.form.title.edit_protection_gel';


    
    forkJoin(
    !this.creation ? this.protectionGelService.getRefProtectionGel() : of(new RefProtectionGel())
    )
    .pipe(
      this.loaderService.operator()
    ).subscribe(([gels]) => {
     this.protectionGel = !this.creation ? gels.find(g => g.id === id) : gels ;    
        this.initForm((this.protectionGel.id) ? this.protectionGel : null);
     
    });
  }

  private initForm(gel?: RefProtectionGel): void {
 
    this.formGroup = this.fb.group({
      code: [{ value: ((gel) ? gel.code: undefined), disabled: false }, Validators.required],
      libelle: [{ value: ((gel) ? gel.libelle : undefined), disabled: false }, Validators.required]
    });
  }

  submit() {
    if (this.formGroup.valid) {
      this.protectionGel.code = this.code.value;
      this.protectionGel.libelle = this.libelle.value;
     
      if (this.protectionGel.id == null) {
        this.protectionGelService.creerRefProtectionGel(this.protectionGel).subscribe(idPprotectionGel => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
        });
      } else {        
        this.protectionGelService.modifierRefProtectionGel(this.protectionGel).subscribe( () => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
         } );
      }
     
    }
  }

}
