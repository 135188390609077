import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AbstractRechercheItemComponent } from '../abstract-recherche-items.component';
import { EchantillonsService } from 'src/app/data/commission/services/echantillons/echantillons.service';
import { Echantillon } from 'src/app/data/commission/models/echantillon.model';
import { ProduitsService } from 'src/app/data/habilitation/services/produits/produits.service';
import { Produit } from 'src/app/data/habilitation/models/produit.model';
import { ReferencesService } from 'src/app/data/commission/services/references/references.service';
import { TypeLeurre } from 'src/app/data/commission/models/type-leurre.model';
import { Defaut } from 'src/app/data/commission/models/defaut.model';
import { Leurre } from 'src/app/data/commission/models/leurre.model';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-recherche-echantillons',
  templateUrl: './recherche-echantillons.component.html',
  styleUrls: ['./recherche-echantillons.component.scss']
})
export class RechercheEchantillonsComponent extends AbstractRechercheItemComponent<Echantillon> implements OnInit {
  activeTabId: string;
  @Input() idOrganisme: number;
  @Input() idsCdcs: number[];
  @Input() alreadySelectedEchantillonsIds: number[];
  produits: Produit[];
  conditionnements: unknown[];
  typesLeurre: TypeLeurre[];
  defauts: Defaut[];
  formGroupLeurre: FormGroup;
  statutAPreleve = false;

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly fb: FormBuilder,
    private readonly echantillonsService: EchantillonsService,
    private readonly produitsService: ProduitsService,
    private readonly refService: ReferencesService,
    private readonly refServiceDeclaration: ReferentielService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private modalsService: AdvBootstrapModalService,
    private translate: TranslateService
  ) {
    super(modal);
  }

  ngOnInit() {
    super.ngOnInit();

    this.formGroupLeurre = this.fb.group({
      produit: [undefined, Validators.required],
      millesime: [undefined, [Validators.pattern(/^\d{4}$/)]],
      conditionnement: [undefined, Validators.required],
      type: [undefined, Validators.required],
      defauts: [undefined],
    });
  }

  getFormField(key: string): FormControl {
    return this.formGroupLeurre.get(key) as FormControl;
  }

  changeStatut(value: boolean) {
    if(value != this.statutAPreleve) {
      this.statutAPreleve = !this.statutAPreleve;
      this.modalsService.confirm(
        this.translate.instant(`page.commissions.edit.modals.echantillons.form_leurre.modalConfirmText`),
        this.translate.instant(`page.commissions.edit.modals.echantillons.form_leurre.modalConfirmTitle`), {
        cancelText: this.translate.instant(`label.annuler`),
        submitText: this.translate.instant(`label.valider`)
      }).then(() => {
        this.loadData();
      }, () => { 
        this.statutAPreleve = !this.statutAPreleve;
      });
    }
  }

  getData() {
    return forkJoin(
      this.echantillonsService.getEchantillonsByCdcs(this.idOrganisme, this.idsCdcs, this.statutAPreleve),
      this.produitsService.getProduitsByCdcs(this.idOrganisme, this.idsCdcs),
      this.refService.getReferences(),
      this.refServiceDeclaration.getReferentiel()
    ).pipe(
      this.loaderService.operator(),
      map(([echantillons, produits, ref, ref2]) => {
        this.produits = produits;
        this.typesLeurre = ref.typesLeurres;
        this.conditionnements = ref2.conditionnements;
        this.defauts = ref.defauts;

        return echantillons;
      }),
      map(echantillons => {
        return echantillons.filter(echantillon => this.alreadySelectedEchantillonsIds.indexOf(echantillon.id) === -1);
      }),
      catchError(error => {
        this.modal.dismiss(error);
        throw error;
      })
    );
  }

  submitFormEchantillon() {
    if (this.formGroupLeurre.valid) {
      const leurre = new Leurre();
      leurre.codeProduit = this.getFormField('produit').value.code;
      leurre.produit = this.getFormField('produit').value;
      leurre.conditionnement = this.getFormField('conditionnement').value;
      leurre.defauts = this.getFormField('defauts').value;
      leurre.millesime = this.getFormField('millesime').value;
      leurre.type = this.getFormField('type').value;

      this.modal.close({ leurre });
    }
  }

  submit() {
    this.modal.close({ echantillons: this.selectedItems });
  }
}
