import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';

import * as moment from 'moment';

import { JsonMapperService } from '../mapper/mapper.service';
import { LogError } from './models/log-error.model';

@Injectable({
  providedIn: 'root'
})
export class ErrorLoggerService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
  ) { }

  handleError(error: any) {
    const logError = this.getLogError();

    if (error instanceof HttpErrorResponse) {
      logError.message = error.message;
      logError.stackTrace = {
        status: error.status,
        url: error.url,
        body: error.error
      };
    } else {
      if (error.message && error.message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') === 0) {
        // console.warn('ExpressionChangedAfterItHasBeenCheckedError', { error });
        return;
      }

      // log error in console
      console.error(error);

      logError.message = error.message;
      logError.stackTrace = error.stack;
    }

    this.sendError(logError);
  }

  private getLogError(): LogError {
    const logError = new LogError();

    logError.navigator.locale = window.navigator.language;
    logError.navigator.platform = window.navigator.platform;
    logError.navigator.version = window.navigator.appVersion;

    logError.date = moment();

    return logError;
  }

  private sendError(error: LogError) {
    console.warn(error);
    // TODO : sauvegarder les erreurs non transmises pour envoi differé

    // this.http.post('/api-mocks/error', this.mapper.serialize(error)).subscribe(() => { });
  }
}
