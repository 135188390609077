
    <div class="form-group">
      <form *ngIf="formGroup && show" [formGroup]="formGroup" #form="ngForm" (submit)="submit()" novalidate>
        <div class="form-group">
          <select [formControl]="organisme" t-id="loginForm-organisme" required [ngClass]="{'is-invalid': form.submitted && organisme.invalid, 'select-placeholder' : (organisme.value === null && !organisme.dirty)}" class="form-control">
            <option [ngValue]="null" hidden selected>{{'page.auth.form.organisme' | translate}}</option>
            <option *ngFor="let organisme of organismes" [ngValue]="organisme">{{organisme.type.code}} - {{organisme.raisonSociale}}</option>
          </select>

          <ng-container *ngIf="form.submitted">
            <div *ngIf="organisme.errors?.required" class="form-error" translate>label.obligatoire</div>
          </ng-container>
        </div>
        <div>
          <button type="submit" t-id="loginForm-next" class="btn btn-lg border-radius btn-block btn-secondary text-white" style="background:#985a99" ><strong translate>label.suivant</strong></button>
        </div>
      </form>
    </div>


