import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { InteractionAccueil } from '../../models/accueil-anpp/interaction-accueil.model';
import { NombreAdherents } from '../../models/accueil-anpp/nombre-adherents';
import { Interaction } from '../../models/interaction.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardIntervenantService {

  constructor(private readonly http: HttpClient,
    private readonly mapper: JsonMapperService) { }

  getNombreAdherents(): Observable<NombreAdherents> {
    return this.http.get<any>(`/api/intervenant/private/dashboard/stats-adherents`).pipe(
      map(nombreAdherents => this.mapper.deserializeObject(nombreAdherents, NombreAdherents))
    );        
  }  

  public getPourcentageCotisationsAJour(): Observable<number>{
    return this.http.get<number>(`/api/intervenant/private/dashboard/cotisations-a-jour`);
  }

  public getDernieresInteractions(): Observable<InteractionAccueil[]> {
    return this.http.get<Object[]>(`/api/intervenant/private/dashboard/dernieres-interactions`)
    .pipe(
      map(interactions => this.mapper.deserializeArray(interactions, InteractionAccueil))
    );
  }
}
