enum ERROR_CODE {
  COMMISSION_NO_CDC_SELECTED = 'COMMISSION_NO_CDC_SELECTED',
  JSON_MAPPING_ERROR_SERIALISATION = 'JSON_MAPPING_ERROR_SERIALISATION',
  JSON_MAPPING_ERROR_DESERIALISATION = 'JSON_MAPPING_ERROR_DESERIALISATION',
}

export class FunctionalError extends Error {
  static ERROR_CODE = ERROR_CODE;

  constructor(public code: string, public context = {}, message?: string, causedBy?: Error) {
    super((message || code) + ' | ');

    if (causedBy && causedBy.stack) {
      this.stack = this.stack.split('\n').shift() + causedBy.stack;
    } else {
      if ((Error as any).hasOwnProperty('captureStackTrace')) {
        (Error as any).captureStackTrace(this, FunctionalError);
      }
    }
    this.name = 'FunctionalError';

    Object.setPrototypeOf(this, FunctionalError.prototype); // cas particulier lors qu'une classe etend Error
  }
}
