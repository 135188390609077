import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditVarieteComponent } from './edit-variete/edit-variete.component';
import { ListeVarietesComponent } from './liste-varietes/liste-varietes.component';

@Component({
  selector: 'app-varietes',
  templateUrl: './varietes.component.html',
  styleUrls: ['./varietes.component.scss']
})
export class VarietesComponent implements OnInit {

  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"]
    };
  
    static routes: Routes = [
      { path: '', children: ListeVarietesComponent.routes, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id', component: EditVarieteComponent, data: { roles: ['ORGANISME'] } },
      { path: 'new', component: EditVarieteComponent, data: { roles: ['ORGANISME'] } }
    ];

  constructor() { }

  ngOnInit() {
  }

}
