import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {OperateursService} from '../../../../../../../data/intervenant/services/operateurs/operateurs.service';
import {DomaineSpec} from '../../../../../../../data/intervenant/models/domaine-spec.model';

@Component({
  selector: 'app-edit-informations-majeures',
  templateUrl: './edit-informations-majeures.component.html',
  styleUrls: ['./edit-informations-majeures.component.scss']
})
export class EditInformationsMajeuresComponent implements OnInit {

  @Input() idOperateur: number;
  @Input() informationsMajeures: any;
  @Input() operateur: any;
  @Input() infosDomaine: any[];

  domaineSpecs: DomaineSpec[] = [];
  formInformationsMajeures: FormGroup;

  get siret() {
    return this.formInformationsMajeures.get('siret');
  }

  constructor(private readonly fb: FormBuilder,
              private readonly modal: NgbActiveModal,
              private operateurService: OperateursService) {
  }

  ngOnInit() {
    this.initForm();
    this.loadDomaineSpecs();
  }

  initForm() {
    this.formInformationsMajeures = this.fb.group({
      structureJuridique: [this.operateur.structureJuridique || ''],
      raisonSociale: [this.operateur.raisonSociale || '', Validators.required],
      siret: [this.operateur.siret || '', [Validators.required, Validators.minLength(14), Validators.maxLength(14)]],
      tvaIntracommunautaire: [this.operateur.tvaIntracommunautaire || ''],
      domaineSpecs: this.fb.group({})
    });
  }

  private loadDomaineSpecs() {
    this.operateurService.getAllInformationsDomaine().subscribe(
      specs => {
        this.domaineSpecs = specs;
        this.addDomaineSpecsToForm();
      },
      error => console.error('Erreur lors du chargement des spécifications de domaine', error)
    );
  }

  private addDomaineSpecsToForm() {
    const domaineSpecsGroup = this.formInformationsMajeures.get('domaineSpecs') as FormGroup;
    this.domaineSpecs.forEach(spec => {
      // Recherche de la valeur existante dans infosDomaine
      const existingInfo = this.infosDomaine.find(info => info.code === spec.code);
      const existingValue = existingInfo ? existingInfo.valeur : '';

      const control = this.fb.control(existingValue);
      if (spec.code === 'VIN_CVI') {
        control.setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10)]);
      }
      domaineSpecsGroup.addControl(spec.code, control);
    });
  }

  onSubmit() {
    if (this.formInformationsMajeures.valid) {
      const formData = this.formInformationsMajeures.value;
      const domaineSpecsData = formData.domaineSpecs;

      // Préparez les données pour l'API
      const updatedData = {
        ...formData,
        intervenantDomaineSpecs: Object.keys(domaineSpecsData).map(code => ({
          code: this.domaineSpecs.find(spec => spec.code === code).code,
          valeur: domaineSpecsData[code]
        }))
      };
      delete updatedData.domaineSpecs;

      this.operateurService.updateInformationsMajeures(this.idOperateur, updatedData).subscribe(
        result => {
          this.modal.close('informations_majeures_updated');
        },
        error => {
          console.error('Erreur lors de la mise à jour des informations majeures', error);
        }
      );
    }
  }

  onSiretBlur() {
    this.siret.markAsTouched();
  }
  onCviBlur(code: string) {
    const control = this.formInformationsMajeures.get('domaineSpecs').get(code);
    if (control) {
      control.markAsTouched();
    }
  }

  onClose() {
    this.modal.dismiss();
  }
}
