<div class="content">
  <div class="content-panel">
    <h2 translate>page.commissions.synthese-jure.title</h2>

    <div class="p-2">
    <form [formGroup]="formSearchCommissions" #form="ngForm" (ngSubmit)="onSubmit()">       
      <div class="form-row">
        <div class="form-group col-md-4 row">
          <label for="debutDateCommission" class="col-md-7 col-form-label"
            translate>page.commissions.synthese-jure.search.dateCommissionEntre</label>
          <div class="col-md-5 input-group">
            <input id="debutDateCommission" ngbDatepicker #debutDatepickerCommission="ngbDatepicker"
              formControlName="debutDateCommission" class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="debutDatepickerCommission.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group col-md-4 row">
          <label for="finDateCommission" class="col-md-4 col-form-label"
            translate>page.commissions.synthese-jure.search.et</label>
          <div class="col-md-6 input-group">
            <input id="finDateCommission" ngbDatepicker #finDatepickerCommission="ngbDatepicker"
              formControlName="finDateCommission" class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="finDatepickerCommission.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
      
        <div class="col-md-4">
          <button type="submit" class="btn btn-primary rechercher">Rechercher</button>
        </div>
        <div class="col-md-6">
          <button type="button" class="btn btn-primary ml-2" (click)="exporter()" translate>label.exporter</button>
        </div>
      </div>
    </form>
    </div>
    <table class="table" *ngIf="syntheses?.length > 0; else aucuneSynthese">
      <thead>
        <th translate>page.commissions.synthese-jure.table.nom</th>
        <th translate>page.commissions.synthese-jure.table.mail</th>
        <th translate>page.commissions.synthese-jure.table.nbConvocations</th>
        <th translate>page.commissions.synthese-jure.table.nbPresent</th>
        <th translate>page.commissions.synthese-jure.table.nbExcuse</th>
        <th translate>page.commissions.synthese-jure.table.nbAbsent</th>
        <th translate>page.commissions.synthese-jure.table.nbConvoque</th>
        <th translate>page.commissions.synthese-jure.table.nbAConvoque</th>
        <th></th>
      </thead>
      <tbody>
        <tr *ngFor="let synthese of syntheses">
          <td>{{ synthese.nom }}</td>
          <td>{{ synthese.mail }}</td>
          <td>{{ synthese.nbConvocation }}</td>
          <td>{{ synthese.nbPresent }}</td>
          <td>{{ synthese.nbExcuse }}</td>
          <td>{{ synthese.nbAbsent }}</td>
          <td>{{ synthese.nbConvoque }}</td>
          <td>{{ synthese.nbAConvoque }}</td>      
          <td>
            <span>
              <button type="button" class="btn btn-outline-secondary btn-add" (click)="viewDetail(synthese)" >
                <fa-icon icon="plus" class="primary-icon" title="Détail"></fa-icon>
              </button>             
            </span>
          </td>
        </tr>
      </tbody>
    </table>
 </div>
</div>

    <ng-template #aucuneSynthese>
      <div class="alert alert-warning alert-block" translate>
        <fa-icon icon="exclamation"></fa-icon>
        page.commissions.synthese-jure.search.aucuneSynthese
      </div>
    </ng-template>

 
