import { Component, OnInit, Output, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, merge } from 'rxjs/operators';
import { EntrepriseBase } from 'src/app/data/intervenant/models/entreprise-base.model';

@Component({
  selector: 'app-search-producteur-organise-bar',
  templateUrl: './search-producteur-organise-bar.component.html',
  styleUrls: ['./search-producteur-organise-bar.component.scss']
})
export class SearchProducteurOrganiseBarComponent implements OnInit {

  placeholder: string;
  minLength = 0;

  searching = false;
  noResult = false;
  userSearch: any;

  @Output() newItemEvent = new EventEmitter<Entreprise>();
  @Input() idAdherent: number;
  @Input() producteur: Entreprise;


  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly entrepriseService: EntreprisesService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  focus$ = new Subject<string>();
  @ViewChild('someInput') searchElement: ElementRef;
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus$),
      tap(() => {
        this.searching = true;
        this.noResult = false;
      }),
      switchMap(term => {
        //if ((term != null) && (term.length >= this.minLength)) {
          return this.entrepriseService.getEntreprisesByIdEntrepriseLieeSearch(this.idAdherent, term).pipe(
            tap(entreprises => {
              this.noResult = !entreprises || (entreprises.length === 0);
            }),
            catchError(() => {
              return of([]);
            }));
        /*} else {
          return of([]);
        }*/
      }),
      tap(() => this.searching = false),
    )

  formatter = (entreprise: EntrepriseBase) => `${entreprise.raisonSociale}`;
  
  selectOperateur(event) {
    if (event.item != null) {
      const entreprise = event.item as Entreprise;
      this.newItemEvent.emit(entreprise);
    }
  }

  ngOnInit() {
    if (this.producteur) {
      this.userSearch = this.producteur;
    } else {
      this.route.queryParams.subscribe(params => {
        this.userSearch = params.search;
        setTimeout(() => {
         //this.searchElement.nativeElement.focus();
        }, 100)
      });
    }
  }
}