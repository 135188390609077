import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { References } from 'src/app/data/intervenant/models/references.model';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';


@Injectable({
  providedIn: 'root'
})
export class ReferencesResolver implements Resolve<References> {
  constructor(private readonly referencesService: ReferencesService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.referencesService.getReferences();
  }
}
