import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { OperateurSitesService } from 'src/app/data/declaration/services/operateur-sites/operateur-sites.service';
import { SessionContext, NavigationContext } from 'src/app/core/services/config/app.settings';
import { Site } from 'src/app/data/declaration/models/site.model';
import { SaisieConditionnement } from 'src/app/data/declaration/models/saisie-conditionnement.model';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { Referentiel } from 'src/app/data/declaration/models/referentiel.model';
import { Contenant } from 'src/app/data/declaration/models/contenant.model';
import { MouvementsProduitsService } from 'src/app/data/declaration/services/mouvements/mouvements-produits.service';
import { Produit } from 'src/app/data/habilitation/models/produit.model';
import { UtilisateurTypeCode } from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';
import { Assemblage } from 'src/app/data/declaration/models/assemblage.model';

@Component({
  selector: 'app-saisir-conditionnement-igp',
  templateUrl: './saisir-conditionnement-igp.component.html',
  styleUrls: ['./saisir-conditionnement-igp.component.scss']
})
export class SaisirConditionnementIgpComponent implements OnInit {
  @Input()
  public campagne: string;
  public idOperateur: number;
  public referentiel: Referentiel = new Referentiel();
  public sitesOperateur: Site[] = [];
  public syntheseProduit: Produit = new Produit();

  public formSaisieConditionnement: FormGroup;
  get produit() { return this.formSaisieConditionnement.get('produit'); }
  get date() { return this.formSaisieConditionnement.get('date'); }
  get cepages() { return this.formSaisieConditionnement.get('cepages'); }
  get observations() { return this.formSaisieConditionnement.get('observations'); }
  get logement() { return this.formSaisieConditionnement.get('logement'); }
  get lot() { return this.formSaisieConditionnement.get('lot'); }
  get volume() { return this.formSaisieConditionnement.get('volume'); }
  get entreposage() { return this.formSaisieConditionnement.get('entreposage'); }

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly fb: FormBuilder,
    private readonly toastr: ToastrService,
    private readonly operateurSitesService: OperateurSitesService,
    private readonly referentielService: ReferentielService,
    private readonly translate: TranslateService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly mouvementsProduitsService: MouvementsProduitsService
  ) { }

  ngOnInit() {
    this.idOperateur = SessionContext.get('utilisateurType') === UtilisateurTypeCode.OPERATEUR ?
      SessionContext.get('idIntervenant') :
      NavigationContext.get('idOperateur');

    this.formSaisieConditionnement = this.fb.group({
      produit: [{ value: this.syntheseProduit.libelle, disabled: true }],
      date: [undefined, Validators.required],
      cepages: [undefined],
      observations: [undefined],
      logement: [undefined, Validators.required],
      lot: [undefined, Validators.required],
      volume: [undefined, [
        Validators.pattern(/(^\d+$)|(^\d+\.\d{1,2}$)|(^\d+\,\d{1,2}$)/),
        this.positifStrictValidator
      ]],
      entreposage: [undefined, Validators.required],
      contenants: this.fb.array([], Validators.compose([this.contenantsValidator.bind(this)]))
    });

    this.formSaisieConditionnement.get('volume').valueChanges.subscribe(() => {
      const contenants = this.formSaisieConditionnement.get('contenants') as FormArray;
      contenants.controls.forEach((contenant: FormGroup) => contenant.get('volume').updateValueAndValidity());
    });

    this.loadData();
  }

  close() { this.modal.dismiss(); }

  loadData() {
    // Charger les contenants et les adresses
    forkJoin(
      this.referentielService.getReferentiel(),
      this.operateurSitesService.getSitesOperateur(this.idOperateur)
    )
      .pipe(this.loaderService.operator())
      .subscribe(([ref, operateurs]) => {
        // Affecter les contenants
        this.referentiel = ref;
        // Affecter les adresses
        this.sitesOperateur = operateurs;
      });
  }

  add(type: string) {
    const control = this.formSaisieConditionnement.get(type) as FormArray;
    control.push(this.init(type));
  }

  selectedYearsOrContenantValidator(formulaire: string, champ: string): ValidatorFn {
    return (control: FormControl): ValidatorFn | any => {
      const value = control.value;
      const controls = (this.formSaisieConditionnement.get(formulaire) as FormArray).controls;

      // Renvoyer invalid si le même contenant a été sélectionné deux fois
      let trouve = 0;
      let cpt = 0;
      while ((trouve < 2) && (cpt < controls.length)) {
        trouve += (controls[cpt++].get(champ).value == value) ? 1 : 0;
      }

      return (trouve >= 2) ? { invalid: true } : null;
    };
  }

  init(type: string): FormGroup {
    let form: FormGroup = null;

    if (type === 'contenants') {
      form = this.fb.group({
        type: [undefined, [this.selectedYearsOrContenantValidator(type, 'type').bind(this)]],
        nombre: [undefined, [Validators.pattern(/^[0-9]\d*$/), this.positifStrictValidator]],
        volume: [undefined, [
          Validators.pattern(/(^\d+$)|(^\d+\.\d{1,2}$)|(^\d+\,\d{1,2}$)/),
          this.positifStrictValidator,
          this.contenantValidator.bind(this)
        ]]
      });

      // Calculer automatiquement le volume lorsque l'on modifie le nombre
      form.get('nombre').valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(
        value => this.calculerVolumeContenant(value, form)
      );

      // Calculer automatiquement le nombre lorsque l'on modifie le volume
      form.get('volume').valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(
        value => this.calculerNombreContenant(value, form)
      );
    }

    return form;
  }

  positifStrictValidator(control: FormControl) {
    const value = parseFloat(control.value);
    if (isNaN(value) && control.value) {
      return { invalid: true };
    }
    return (control.value > 0) ? null : { invalid: true };
  }

  contenantValidator(control: FormControl) {
    return (control.value > this.volume.value) ? { invalid: true } : null;
  }

  contenantsValidator() {
    let result = null;
    let volumes = 0;
    let volumeTotal = 0;
    const arrayVolumes: AbstractControl[] = [];

    if (this.formSaisieConditionnement) {
      const form = this.formSaisieConditionnement.get('contenants') as FormArray;
      volumeTotal = this.volume.value;
      form.controls.forEach(control => {
        volumes += control.value.volume;
        arrayVolumes.push(control);
      });
    }

    if (volumes > volumeTotal) {
      result = { invalid: true };
      arrayVolumes.forEach(control => {
        const errors = control.get('volume').errors || {};
        errors.incorrect = true;
        control.get('volume').setErrors(errors);
      });
    } else {
      arrayVolumes.forEach(control => {
        let errors = control.get('volume').errors || {};
        delete errors.incorrect;
        if (Object.keys(errors).length === 0) {
          errors = null;
        }
        control.get('volume').setErrors(errors);
      });
    }

    return result;
  }

  remove(type: string, index: number) {
    const control = this.formSaisieConditionnement.get(type) as FormArray;
    control.removeAt(index);
  }

  calculerVolumeContenant(value: number, form: FormGroup) {
    value = Math.floor(value);
    form.patchValue({ nombre: value });

    if (value > 0) {
      const type = form.get('type').value;
      if (type) {
        form.patchValue({
          volume: this.bypassFloatingPointPrecision(type.volumeContenant * value)
        });
      }
    }
  }

  calculerNombreContenant(value: number, form: FormGroup) {
    if (value > 0) {
      const type = form.get('type').value;
      if (type) {
        const nombre = Math.floor(this.bypassFloatingPointPrecision(value / type.volumeContenant));

        form.patchValue({
          nombre: nombre.toString(),
          volume: this.bypassFloatingPointPrecision(nombre * type.volumeContenant)
        });
      }
    }
  }

  submit() {
    if (this.formSaisieConditionnement.valid) {
      // Récupérer les contenants
      const contenants: Contenant[] = [];
      (this.formSaisieConditionnement.get('contenants') as FormArray).controls.forEach(contenant => {
        contenants.push(Object.assign(new Contenant(), {
          idType: contenant.get('type').value.idContenant,
          nombre: contenant.get('nombre').value,
          volume: contenant.get('volume').value
        }));
      });

      // Construire le conditionnement
      const conditionnement = Object.assign(new SaisieConditionnement(), {
        codeProduit: this.syntheseProduit.code,
        date: moment([
          this.date.value.year,
          this.date.value.month - 1,
          this.date.value.day
        ]),
        cepages: this.cepages.value,
        observations: this.observations.value,
        logement: this.logement.value,
        numeroLot: this.lot.value,
        volume: this.volume.value,
        idSite: this.entreposage.value.id,
        assemblages: [Object.assign(new Assemblage(), { annee: parseInt(this.campagne, 10), volume: this.volume.value })],
        contenants
      });

      // Envoyer le conditionnement à l'API
      this.mouvementsProduitsService.setConditionnement(this.idOperateur, this.campagne, conditionnement)
        .subscribe(() => {
          this.translate.get('page.declarations.synthese.modal.saisirConditionnement.update-ok').subscribe(msg => {
            this.toastr.success('', msg);
          });
          this.modal.close();
        });
    }
  }

  getAssemblages() {
    return (this.formSaisieConditionnement.get('assemblages') as FormArray).controls;
  }

  getContenants() {
    return (this.formSaisieConditionnement.get('contenants') as FormArray).controls;
  }

  onTypeChange(i: number) {
    (this.formSaisieConditionnement.get(`contenants.${i}`) as FormControl)
      .patchValue({ nombre: '', volume: '' });
  }

  private bypassFloatingPointPrecision(nombre: number): number {
    return Math.round(nombre * 1000) / 1000;
  }
}
