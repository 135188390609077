import { JsonObject, JsonProperty, CustomConverter } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { DateTimeConverter } from 'src/app/core/services/mapper/converters';

@JsonObject
export class Prelevement {
    @JsonProperty('datePrelevement', String)
    @CustomConverter(DateTimeConverter)
    date: moment.Moment;

    @JsonProperty('observation', String)
    observation: string;

    @JsonProperty('representant', String)
    representant: string;
}
