import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Departement} from '../../models/departement.model';
import {HttpClient} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class DepartementService {

  private url = 'https://geo.api.gouv.fr/departements';
  constructor(private readonly http: HttpClient) {
  }

  getLocalisationDepartements(): Observable<Departement[]> {
    return this.http.get<Departement[]>(this.url);
  }

  getDepartement(code : string ): Observable<Departement> {

    return this.http.get<Departement>(this.url + '?code=' + code);
  }
}
