import { Component, OnDestroy, OnInit } from '@angular/core';
import { AffichageDrComponent } from './pages/affichage-dr/affichage-dr.component';
import { Routes, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AffichageDrevComponent } from './pages/affichage-drev/affichage-drev.component';
import { SyntheseVolumesComponent } from './pages/synthese-volumes/synthese-volumes.component';
import { HistoriqueComponent } from './pages/historique/historique.component';
import { ConsultationDrevComponent } from './pages/consultation-drev/consultation-drev.component';
import { RecoltesComponent } from './pages/recoltes/recoltes.component';
import { StocksComponent } from './pages/stocks/stocks.component';
import { EcoulementsComponent } from './pages/ecoulements/ecoulements.component';
import { ParcellairesComponent } from './pages/parcellaires/parcellaires.component';
import { ImportInventaireVergersComponent } from './pages/import-inventaire-vergers/import-inventaire-vergers.component';
import { NgbTabChangeEvent, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { Route } from '@angular/compiler/src/core';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { DeclarationsService } from 'src/app/data/declaration/services/declarations/declarations.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { ParcellaireViticoleComponent } from '../parcellaire-viticole/parcellaire-viticole.component';

@Component({
  selector: 'app-declarations',
  templateUrl: './declarations.component.html',
  styleUrls: ['./declarations.component.scss']
})
export class DeclarationsComponent implements OnInit, OnDestroy {

  static routes: Routes = [
    {
      path: '', data: {
        role: 'OPERATEUR',
        menu: {
          icon: 'file-signature',
          libelle: 'menu.declarations'
        }
      },
      children: [
        { path: '', redirectTo: 'dr', pathMatch: 'full' },
        { path: 'parcellaire', component: ParcellaireViticoleComponent, data: { menu: { libelle: 'Parcellaire' }, role: 'DECLARATION_INVENTAIRE_VERGER_LECTURE' } },  
        { path: 'dr', component: AffichageDrComponent, data: { menu: { libelle: 'Import Prodouane' } } }, // TODO delete data property
        { path: 'drev', component: AffichageDrevComponent }, // TODO delete data property
        { path: 'consultation', children: ConsultationDrevComponent.routes },
        { path: 'synthese', children: SyntheseVolumesComponent.routes }, // TODO delete data property
        { path: 'historique', children: HistoriqueComponent.routes } // TODO : delete data property
      ]
    }
  ];

  static routesAnpp: Routes = [
    {
      path: '', 
      component: DeclarationsComponent,
      data: {
        role: 'ORGANISME || DECLARATION_STOCK_LECTURE || DECLARATION_INVENTAIRE_VERGER_LECTURE || DECLARATION_RECOLTE_ECRITURE || DECLARATION_ECOULEMENT_ECRITURE',
        domaines: ["ANPP"],
        menu: {
          icon: 'file-signature',
          libelle: 'menu.declarations'
        }
      },children: [
        { path: '', redirectTo: 'recoltes', pathMatch: 'full' },
        { path: 'parcellaire', children: ParcellairesComponent.routes }, 
        { path: 'recoltes', children: RecoltesComponent.routes}, 
        { path: 'stocks', children: StocksComponent.routes },
        { path: 'ecoulements', children: EcoulementsComponent.routes },
        { path: 'import-inventaire-vergers', children: ImportInventaireVergersComponent.routes, data: { masquageOngletsEtRecherche: true } },
      ]
      
    }
  ];


  selectedEntreprise: Entreprise;
  activeIdString: string;
  masquageOngletsEtRecherche: false;
  subscription: any;

  constructor(private readonly router: Router,
    private readonly route: ActivatedRoute, 
    public readonly declarationService: DeclarationsService, 
    private readonly authService: AuthService) {}

  ngOnInit() {
    this.masquageOngletsEtRecherche = this.route.snapshot && this.route.snapshot.children[0] &&
      this.route.snapshot.children[0].data && this.route.snapshot.children[0].data.masquageOngletsEtRecherche;

    this.subscription =
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.route),
          map((route) => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          switchMap((route) => route.data)
        )
        .subscribe((data) => {
          this.masquageOngletsEtRecherche = data.masquageOngletsEtRecherche;
        });


    if(!this.isAnpp()){
      this.declarationService.entrepriseSelectionnee=null;
    }
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  ngOnChanges(){
  }

  public onTabChange($event: NgbTabChangeEvent, tabset: NgbTabset): void {
    
    let entrepriseUrl = "";
    if (this.declarationService.entrepriseSelectionnee) {
      entrepriseUrl = "/" + this.declarationService.entrepriseSelectionnee.id; 
    }
    switch($event.nextId){
      case "vergers":
        this.router.navigate(['/main/declarations-pp/parcellaire']);
        break;
      case "recoltes":
        this.router.navigate(['/main/declarations-pp/recoltes']);
        break;
      case "stocks":
        this.router.navigate(['/main/declarations-pp/stocks']);
        break;
      case "ecoulements":
        this.router.navigate(['/main/declarations-pp/ecoulements']);
        break;
    }
    
  }

  addItem(newItem: Entreprise) {
    this.declarationService.entrepriseSelectionnee = newItem;
    this.selectedEntreprise = newItem;
    let entrepriseUrl = "";
    if (this.declarationService.entrepriseSelectionnee) {
      entrepriseUrl = "/" + this.declarationService.entrepriseSelectionnee.id; 
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    switch(this.declarationService.activeTabId){
      case "vergers":
        this.router.navigated = false;
        this.router.navigate(['/main/declarations-pp/parcellaire'+entrepriseUrl]);
        break;
      case "recoltes":
        this.router.navigate(['/main/declarations-pp/recoltes'+entrepriseUrl]);
        break;
      case "stocks":
        this.router.navigate(['/main/declarations-pp/stocks'+entrepriseUrl]);
        break;
      case "ecoulements":
        this.router.navigate(['/main/declarations-pp/ecoulements'+entrepriseUrl]);
        break;
    }
  }

  isAnpp() {   
    return this.authService.isAnpp();
  }

}
