import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Campagne } from 'src/app/data/declaration/models/campagne.model';
import { RefCampagnesService } from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';
import { SyntheseEspece } from 'src/app/data/intervenant/models/synthese-espece.model';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { Router } from '@angular/router';
import { DeclarationsService } from 'src/app/data/declaration/services/declarations/declarations.service';

@Component({
  selector: 'app-verger-entreprise',
  templateUrl: './verger-entreprise.component.html',
  styleUrls: ['./verger-entreprise.component.scss']
})
export class VergerEntrepriseComponent implements OnInit {

  syntheseEspeces: SyntheseEspece[];
  @Input() id: number;

  public annee: number;
  //refCampagne: Campagne;
  refCampagneList: Campagne[];
  refCampagneFilter: Campagne;

  public formGroup: FormGroup;
  get anneeInv() { return this.formGroup.get('anneeInv'); }


  constructor(
    private readonly refCampagnesService: RefCampagnesService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly entrepriseService: EntreprisesService,
    private readonly declarationService: DeclarationsService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
  ) { }

  ngOnInit() {

    this.refCampagneList= [];
    

            forkJoin(
              this.refCampagnesService.getCampagneEnCours(),
              this.refCampagnesService.getCampagneAll()
            ).pipe(this.loaderService.operator())
              .subscribe(([anneeRef, anneeList ]) => {
                     
                      this.formGroup = this.fb.group({
                          anneeInv: [this.refCampagneList.find(r => r.annee === anneeRef.annee)  , [Validators.required]]
                        });                        
                        
                        this.annee = anneeRef.annee;
                        this.refCampagneList = anneeList;
                        this.anneeInv.setValue(this.refCampagneList.find(r => r.annee === anneeRef.annee));

                forkJoin(
                  this.entrepriseService.getSurfaceEspeceByEntrepriseAndCampagne(this.id, anneeRef.id)
                  )
                    .pipe(this.loaderService.operator())
                    .subscribe(([syntheses]) => {
                      this.syntheseEspeces = syntheses;

                    });        

                  }
                );  
  }

  majSynthese(){

        this.annee = this.anneeInv.value.libelle;

    forkJoin(
      this.refCampagnesService.getCampagneAnnee(this.anneeInv.value.annee)
    ).pipe(this.loaderService.operator())
      .subscribe((anneeRef: Campagne) => {
        forkJoin(
          this.entrepriseService.getSurfaceEspeceByEntrepriseAndCampagne(this.id, anneeRef[0].id)
        )
          .pipe(this.loaderService.operator())
          .subscribe(([syntheses]) => {
            this.syntheseEspeces = syntheses;

          });        
        }
      );  
   
  }

  goToInventaire() {
    let gp: Number = null;
    this.entrepriseService.getEntreprise(this.id).subscribe(entrepriseSelectionnee => {
      if (entrepriseSelectionnee.refSousType.code === "PO_DEFAUT") {
        this.entrepriseService.getEntreprise(entrepriseSelectionnee.entrepriseLiee.id).subscribe(groupement => {
          gp = groupement.id;
          this.router.navigate(['/main/declarations-pp/parcellaire'], { queryParams: { id: this.id, gp: gp } });
        }, error => { })
      } else {
        this.declarationService.entrepriseSelectionnee = entrepriseSelectionnee;
        this.router.navigate(['/main/declarations-pp/parcellaire'], { queryParams: { id: this.id } });
      }
    })
  }



}
