import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditFonctionComponent } from './edit-fonction/edit-fonction.component';
import { ListeFonctionsComponent } from './liste-fonctions/liste-fonctions.component';

@Component({
  selector: 'app-fonctions',
  templateUrl: './fonctions.component.html',
  styleUrls: ['./fonctions.component.scss']
})
export class FonctionsComponent implements OnInit {

  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"]
    };
  
    static routes: Routes = [
      { path: '', component: ListeFonctionsComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id', component: EditFonctionComponent, data: { roles: ['ORGANISME'] } },
      { path: 'new', component: EditFonctionComponent, data: { roles: ['ORGANISME'] } }
    ];

  constructor() { }

  ngOnInit() {
  }

}
