<div class="anpp">
  <div class="content">
    <div class="content-panel">
      <div
        class="cards border-radius-onglet text-center"
        style="background: #fff"
      >
        <table class="table table-striped" *ngIf="entreprises?.length > 0">
          <thead>
            <tr>
              <th scope="col">Opérations</th>
              <th scope="col" translate>
                page.entreprises.table.raisonsociale
              </th>
              <th scope="col" translate>page.entreprises.table.commune</th>
              <th scope="col" translate>page.entreprises.table.telephone</th>
              <th scope="col" translate>page.entreprises.table.mail</th>
              <th scope="col" translate>page.entreprises.table.culturebio</th>
              <th scope="col">Vergers écoresponsables</th>
              <th scope="col">Terroir</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let entreprise of entreprises">
              <td>
                <span
                  ><fa-icon
                    class="clickable-icon"
                    icon="edit"
                    (click)="ficheEntreprise(entreprise)"
                  ></fa-icon
                ></span>
              </td>
              <td>{{ entreprise.raisonSociale }}</td>
              <td>{{ entreprise.commune }}</td>
              <td>{{ entreprise.telephone }}</td>
              <td>{{ entreprise.mail }}</td>
              <td>
                <input
                  type="checkbox"
                  [checked]="entreprise.cultureBio"
                  [disabled]="true"
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  [checked]="entreprise.estAdherent"
                  [disabled]="true"
                />
              </td>
              <td>{{ getTerroir(entreprise.departement) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
