import { JsonObject, JsonProperty, Optional, CustomConverter } from '@thorolf/json-ts-mapper';
import { Domaine } from './domaine.model';
import { Service } from './service.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';

@JsonObject
export class Abonnement {

  @JsonProperty('id', Number)
  @Optional
  public id: number = undefined;

  @JsonProperty('idOrganisme', Number)
  public idOrganisme: number = undefined;

  @JsonProperty('idService', Number)
  @CustomConverter(DataIdConverterFactory.build(Service, 'services'))
  public service: Service = undefined;

  @JsonProperty('idDomaine', Number)
  @CustomConverter(DataIdConverterFactory.build(Domaine, 'domaines'))
  public domaine: Domaine = undefined;
}
