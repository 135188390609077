<div class="anpp">
  <div class="content">
    <div class="content-panel">
      <div
        class="cards border-radius-onglet text-center"
        style="background: #fff"
      >
        <table
          class="table table-striped"
          *ngIf="conseillers?.length > 0"
          aria-describedby="Liste des coneillers technique"
        >
          <thead>
            <tr>
              <th scope="col" translate>page.entreprises.table.mail</th>
              <th scope="col" translate>page.entreprises.table.nom</th>
              <th scope="col" translate>page.entreprises.table.prenom</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="conseillers.length === 0">
              <td colspan="99" class="p-4">
                <fa-icon icon="exclamation-triangle"></fa-icon>&nbsp;
                <span translate
                  >page.entreprises.conseillers.aucunConseiller</span
                >
              </td>
            </tr>
            <ng-container *ngIf="conseillers.length > 0">
              <tr *ngFor="let conseiller of conseillers">
                <td>{{ conseiller.email }}</td>
                <td>{{ conseiller.nom }}</td>
                <td>{{ conseiller.prenom }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
