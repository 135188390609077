<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>page.commissions.edit.modals.cdcs.title</h4>
  </div>

  <div class="modal-body" *ngIf="loaded && items">
    <app-search-bar [(value)]="searchString" *ngIf="items.length > 0"></app-search-bar>

    <table class="table" *ngIf="items.length > 0; else aucunCdc">
      <thead>
        <ng-container *ngTemplateOutlet="tableHeader; context: { sortProperty: 'item.libelle', libelle: 'page.commissions.edit.modals.cdcs.table.header.nom' }"></ng-container>
      </thead>

      <tbody>
        <tr *ngFor="let item of items | filter: 'item':searchString | orderBy: order.property:order.reverse:true" [ngClass]="{ 'selected': item.selected, 'disabled':item.disabled }" (click)="toggleSelectionItem(item)">
          <td>{{ item.item.libelle }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary mr-5" (click)="selectAll()" *ngIf="items && items.length > 0" translate>page.commissions.edit.modals.cdcs.ajouterTout</button>
    <button type="submit" class="btn btn-light" (click)="cancel()" translate>label.annuler</button>
    <button type="submit" class="btn btn-primary" (click)="submit()" [disabled]="selectedItems.length === 0" [translateParams]="{nb:selectedItems.length}" translate>page.commissions.edit.modals.cdcs.valider</button>
  </div>
</div>

<ng-template #aucunCdc>
  <div translate>page.commissions.edit.modals.cdcs.table.aucun_cdc</div>
</ng-template>

<ng-template #tableHeader let-property="sortProperty" let-libelle="libelle">
  <th *ngIf="property" (click)="setOrder(property)" [class.sortable]="property">
    {{ libelle | translate }} <fa-icon *ngIf="property" [icon]="getIcon(property)"></fa-icon>
  </th>
  <th *ngIf="!property">{{ libelle | translate }}</th>
</ng-template>
