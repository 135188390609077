<div class="content">
  <div class="content-panel">
    <h2 translate>page.controles.list.title</h2>
    <form [formGroup]="searchForm" #form="ngForm" (ngSubmit)="search()" novalidate *ngIf="pressions">
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label translate>page.controles.list.pression</label>
            <select formControlName="pression" class="form-control" [ngClass]="{'is-invalid': form.submitted && searchForm.get('pression').invalid}">
              <option [value]="null" hidden></option>
              <option *ngFor="let pression of pressions" [ngValue]="pression">{{pression.libelle}}</option>
            </select>
          </div>
        </div>

        <div class="col-xs-2">
          <button type="button" [disabled]="!selectedPression" [routerLink]="['../pression-controle/edit', selectedPression?.id]" class="btn btn-light btn-ajout-pression" translate>label.modifier</button>
          <button type="button" [routerLink]="['../pression-controle/new']" class="btn btn-light" translate>label.ajouter</button>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label translate>page.controles.list.annee</label>
            <input type="number" lang="en" formControlName="annee" class="form-control" [ngClass]="{'is-invalid': form.submitted && searchForm.get('annee').invalid}" />
          </div>
        </div>


        <div class="col-md-1">
          <button type="submit" class="btn btn-primary" translate>label.rechercher</button>
        </div>
      </div>



    <div class="flex flex-row objectifs_cnt">
      <div><span translate>page.controles.list.objectif</span> : {{ objectif == 0 ? '/' : objectif }}</div>
      <div><span translate>page.controles.list.realise</span> : {{realise}}</div>
      <div><span translate>page.controles.list.resteAFaire</span> : {{ resteAFaire == 0 ? '/' : resteAFaire }}</div>
    </div>


      <div class="row">
    <div class="col-md-2">
      <div class="form-group">
        <label translate>page.controles.list.non_controlable</label>
        <select formControlName="non_controlable" class="form-control" [ngClass]="{'is-invalid': form.submitted && searchForm.get('non_controlable').invalid}">
          <option Value="false">Contrôlable</option>
          <option Value="true">Non contrôlable</option>
        </select>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label translate>page.controles.list.raisonSociale</label>
        <input type="text" lang="en" formControlName="raisonSociale" class="form-control" />
      </div>
    </div>

    <div class="col-md-1">
      <button type="submit" class="btn btn-primary" translate>label.filtrer</button>
    </div>
  </div>
 </form>

    <div class="list_cnt" *ngIf="resultats">
    <table class="table table-striped" *ngIf="resultats.length > 0; else aucunResultat">
      <thead>
        <tr>
          <!-- <th translate>page.controles.list.table.cvi</th> -->
          <th translate>page.controles.list.table.raison_sociale</th>
          <!-- <th translate>page.controles.list.table.adresse</th> -->
          <th translate>page.controles.list.table.produit</th>
          <th translate>page.controles.list.table.volume</th>
          <th translate>page.controles.list.table.numeroLot</th>
          <th translate>page.controles.list.table.date</th>
          <th translate>page.controles.list.table.creer_controle</th>
          <th translate>page.controles.list.table.non_controlable</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let resultat of resultats">
          <td>{{getCVI(resultat)}}<br/>{{resultat.operateur?.raisonSociale}}
              <span  (click)="onEdit(resultat)">
                <fa-icon icon="info-circle"></fa-icon>
              </span>
          </td>

          <!-- <td>{{resultat.adresse}}</td> -->
          <td>{{resultat.produit?.libelle}}</td>
          <td>{{resultat.volume}}</td>
          <td>{{resultat.numeroLot}}</td>
          <td>{{resultat.dateMouvement | date:'dd/MM/yyyy'}}</td>
          <td style="text-align:center">
            <button type="button" class="btn btn-light" [hidden]="resultat.estNonControlable"  (click)="creerControle(resultat)">+</button>
          </td>
          <td style="text-align:center"><input type="checkbox" [checked]="resultat.estNonControlable" (change)="changeStatus(resultat,$event.target?.checked)">
            <div >
              <span (click)="onEditDetail(resultat)">
                <fa-icon icon="info-circle"></fa-icon>                 
              </span>
            </div>
          </td>
          
        </tr>
      </tbody>
    </table>
  </div>
    <app-pagination-bar [pagination]="pagination"></app-pagination-bar>
  </div>
</div>

<ng-template #aucunResultat>
  <div translate>page.controles.list.table.aucun_resultat</div>
</ng-template>
