import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class HistogrammeEcoulement {
  @JsonProperty('numMois', Number)
  @Optional
  numMois: number = undefined;

  @JsonProperty('quantite', Number)
  @Optional
  quantite: number = undefined;

  @JsonProperty('quantiteFrance', Number)
  @Optional
  quantiteFrance: number = undefined;

  @JsonProperty('quantiteExport', Number)
  @Optional
  quantiteExport: number = undefined;

  @JsonProperty('quantiteIndustrie', Number)
  @Optional
  quantiteIndustrie: number = undefined;

  @JsonProperty('quantiteFreinte', Number)
  @Optional
  quantiteFreinte: number = undefined;
}
