import { JsonProperty, JsonObject, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class ModeleFacturationLight {
    @JsonProperty('id', Number)
    @Optional
    id: number;

    @JsonProperty('libelle', String)
    libelle: string;

    @JsonProperty('isGroupeOperateur', Boolean)
    isGroupeOperateur: boolean;
}
