import { Component, OnInit, Input } from '@angular/core';

import { Utilisateur } from 'src/app/data/intervenant/models/utilisateur.model';
import { UtilisateursService } from 'src/app/data/intervenant/services/utilisateurs/utilisateurs.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-utilisateur-statut',
  templateUrl: './utilisateur-statut.component.html',
  styleUrls: ['./utilisateur-statut.component.scss']
})
export class UtilisateurStatutComponent implements OnInit {
  @Input() utilisateur: Utilisateur;

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly utilisateursService: UtilisateursService,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly translate: TranslateService
  ) { }

  ngOnInit() { }

  updateStatut() {
    const confirmTitle = this.translate.instant('page.admin.utilisateur.confirm.'
      + (this.utilisateur.actif ? 'desactivation' : 'activation'));

    this.modalsService.confirm(confirmTitle).then(() => {
      this.utilisateursService.updateUtilisateurStatut(this.utilisateur, !this.utilisateur.actif).pipe(
        this.loaderService.operator()
      ).subscribe(() => {
        this.utilisateur.actif = !this.utilisateur.actif;
      });
    });
  }
}
