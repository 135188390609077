import { JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";
import { Entreprise } from "../../intervenant/models/entreprise.model";

@JsonObject
export class UniteCulturaleVigne{

  @JsonProperty('id', Number)
  @Optional
  id: number = undefined;

  @JsonProperty('surfacePlantee', Number)
  @Optional
  surfacePlantee: number = undefined;

  @JsonProperty('surfaceEngagee', Number)
  @Optional
  surfaceEngagee: number = undefined;

  @JsonProperty('manquant', Number)
  @Optional
  manquant: number;

  @JsonProperty('densite', Number)
  @Optional
  densite: number;

  @JsonProperty('idProduitIdentifie', Number)
  @Optional
  idProduitIdentifie: number = undefined;

  @JsonProperty('idProduitDestination', Number)
  @Optional
  idProduitDestination: number = undefined;

  @JsonProperty('idEntrepriseDestination', Number)
  @Optional
  idEntrepriseDestination: number = undefined;

  @JsonProperty('idModeConduite', Number)
  @Optional
  idModeConduite: number = undefined;

  @JsonProperty('derogationTaille', Boolean)
  @Optional
  derogationTaille: boolean = undefined

  @JsonProperty('destinationVendange', String)
  @Optional
  destinationVendange: string = undefined;

  @Optional
  produitIdentifie: string = undefined;

  @Optional
  produitDestination: string = undefined;

  @Optional
  entreprise: Entreprise = undefined;


    
}