
import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangesInProgressComponent } from './changes-in-progress.component';


@Injectable()
export class DialogService {
  constructor(private ngbModal: NgbModal) {
  }

  confirm(message?: string) {
    const m = this.ngbModal.open(ChangesInProgressComponent);
    m.componentInstance.confMessage = message;
    return m.result;
  }
}
