import { Injectable } from '@angular/core';

import { some } from 'lodash';

import { Context, SessionContext } from '../config/app.settings';
import { AuthentificationService } from 'src/app/authentification/services/authentification/authentification.service';
import { ApiCredentials } from 'src/app/authentification/models/api-credentials.model';
import { map, tap } from 'rxjs/operators';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { CacheService } from '../cache/cache.service';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token: string;
  private readonly rolesSubject: Subject<string[]> = new BehaviorSubject([]);

  constructor(
    private readonly authentificationService: AuthentificationService
  ) {
    // console.debug('[Auth] call get token');
    this.authentificationService.getApiCredentials()
      .subscribe(credentials => {
        this.setCredentials(credentials);
      });
  }

  public refreshToken(): Observable<string> {
    // console.debug('[Auth] call refresh token');
    return this.authentificationService.refreshApiCredentials().pipe(
      tap(credentials => this.setCredentials(credentials)),
      map(credenitals => credenitals.token)
    );
  }

  public getRoles(): Subject<string[]> {
    return this.rolesSubject;
  }
  public onNewRoles(roles: string[]) {
    roles = roles || [];
    this.rolesSubject.next(roles);
  }

  public setCredentials(credentials: ApiCredentials) {
    if (!credentials) {
      Context.clear();
    } else {
      if (SessionContext.get('idDomaine') !== credentials.idDomaine
        || SessionContext.get('idOrganisme') !== credentials.idOrganisme) {
        CacheService.clearAll();
      }
      
      // console.debug('[Auth] get new api credentials ', credentials);
      if(credentials.token != 'token'){
         this.token = credentials.token;
      const decoded = jwt_decode(this.token);
      credentials.codeDomaine = decoded.dom_name;
      }
     
      SessionContext.set(credentials);
    }

    this.onNewRoles(SessionContext.get('roles'));
  }

  public getToken() {
    return this.token;
  }

  public logOut() {
    this.token = null;
    CacheService.clearAll();
    Context.clear();
    this.authentificationService.logOut();
  }

  public changeScope() {
    this.authentificationService.redirectToChangeScope();
  }

  public isAuthenticated(): boolean {
    return !!this.token;
  }
  
  public isAnpp(): boolean {
    let domaine = SessionContext.get("codeDomaine");
    let isOrganisme = this.hasRole('ORGANISME');
    return domaine && domaine == "ANPP" && isOrganisme;
  }

  public hasRole(requiredRoles: string | string[]) {
    if (!Array.isArray(requiredRoles)) {
      if (requiredRoles === undefined || requiredRoles === null) {
        return true;
      }
      requiredRoles = [requiredRoles];
    }

    if (requiredRoles.length === 0) {
      return true;
    }

    return requiredRoles.reduce((res, role) => this.checkHasRole(role) && res, true); // !some(requiredRoles, role => !this.checkHasRole(role));
  }

  private checkHasRole(roles: string): boolean {
    const userRoles = [...SessionContext.get('roles'), SessionContext.get('utilisateurType')];
    const rolesExpr = roles.replace(/\w+/g, item => userRoles.indexOf(item) !== -1 ? '1' : '0');
    // on check qu'il ne reste plus rien d'autre qu'un expression logique dans la chaine
    if (/[^01&|!() ]/i.test(rolesExpr)) {
      throw Error(`Roles expression invalid : "${roles}"`);
    }
    // la chaine est propre, on peut se permettre un 'eval'
    // tslint:disable-next-line:no-eval
    return !!eval(rolesExpr);
  }
}
