import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditProtectionGreleComponent } from './edit-protection-grele/edit-protection-grele.component';
import { ListesProtectionsGreleComponent } from './listes-protections-grele/listes-protections-grele.component';

@Component({
  selector: 'app-protections-grele',
  templateUrl: './protections-grele.component.html',
  styleUrls: ['./protections-grele.component.scss']
})
export class ProtectionsGreleComponent implements OnInit {

  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"]
    };
  
    static routes: Routes = [
      { path: '', component: ListesProtectionsGreleComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id', component: EditProtectionGreleComponent, data: { roles: ['ORGANISME'] } },
      { path: 'new', component: EditProtectionGreleComponent, data: { roles: ['ORGANISME'] } }
    ];

  constructor() { }

  ngOnInit() {
  }

}
