import {Any, JsonObject, JsonProperty} from '@thorolf/json-ts-mapper';

@JsonObject
export class InfosGenerationAudit {

  @JsonProperty('idEntrepriseList', Number)
  idEntrepriseList: number[];

  @JsonProperty('mapDateValidationInventaire', Any)
  mapDateValidationInventaire: Map<number, Date>[];

  @JsonProperty('codeOCCT', String)
  codeOCCT: string;
}
