<div class="content">
  <div class="content-panel">
    <form *ngIf="formGroup" [formGroup]="formGroup" #form="ngForm" (submit)="submit()" novalidate>
      <div class="form-group">
        <input type="text" name="identifiant" [formControl]="identifiant" required [ngClass]="{'is-invalid': form.submitted && identifiant.invalid}"
          class="form-control" placeholder="Identifiant" />

        <ng-container *ngIf="form.submitted">
          <div *ngIf="identifiant.errors?.required" class="form-error">Obligatoire</div>
        </ng-container>
      </div>

      <div class="form-group">
        <input type="text" name="domaine" [formControl]="domaine" required [ngClass]="{'is-invalid': form.submitted && domaine.invalid}"
          class="form-control" placeholder="Domaine" />

        <ng-container *ngIf="form.submitted">
          <div *ngIf="domaine.errors?.required" class="form-error">Obligatoire</div>
        </ng-container>
      </div>

      <div class="actions-bar">
        <button type="submit" class="btn btn-primary">Inscription</button>
      </div>
    </form>
  </div>
</div>