import { Injectable } from '@angular/core';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Observable, of, forkJoin, Notification } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, flatMap, tap } from 'rxjs/operators';
import { PointAMaitriser } from '../../models/point-a-maitriser.model';
import { Manquement } from '../../models/manquement.model';
import { PaginationService, PaginateResponseStream } from 'src/app/shared/services/pagination/pagination.service';
import * as moment from 'moment';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { Operateur } from 'src/app/data/intervenant/models/operateur.model';
import { ReferentielService } from '../referentiel/referentiel.service';
import { Referentiel } from '../../models/referentiel.model';
import { ManquementResultat } from '../../models/ManquementResultat.model';
import { ProduitsService } from 'src/app/data/habilitation/services/produits/produits.service';
import { CahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import { Cahier } from 'src/app/data/habilitation/models/cahier.model';
import { AnimateursService } from 'src/app/data/commission/services/animateurs/animateurs.service';
import { Animateur } from 'src/app/data/commission/models/animateur.model';
import { IntensiteDefaut } from '../../models/enums/intensite-defaut.enum';
import { Gravite } from '../../models/enums/gravite.enum';
import { Constat } from '../../models/constat.model';

@Injectable({
  providedIn: 'root'
})
export class ManquementsService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly paginationService: PaginationService,
    private readonly operateursService: OperateursService,
    private readonly referentielService: ReferentielService,
    private readonly produitsService: ProduitsService,
    private readonly cahiersService: CahiersService,
    private readonly animateursService: AnimateursService
  ) { }

  /**
   * Obtient une collection filtrée et paginée de manquements en fonction des paramètres passés à la méthode
   * @param idOrganisme L'ID de l'organisme de connexion
   * @param idNature L'ID de la nature des manquements
   * @param idsStatuts Les IDs des statuts des manquements
   * @param dateDebut La date mininmale du manquement
   * @param dateFin La date maximale du manquement
   * @param idsAgents Les IDs des agents des manquements
   * @param numeroManquement Le numéro du manquement
   * @param numeroControle Le numéro du contrôle
   * @see PaginateResponseStream
   * @see ManquementResultat
   * @returns Observable<PaginateResponseStream<ManquementResultat[]>>
   */
  getFilteredManquements(
    idOrganisme: number,
    idNature: number,
    idsStatuts?: number[],
    dateDebut?: moment.Moment,
    dateFin?: moment.Moment,
    idsAgents?: number[],
    numeroManquement?: string,
    numeroControle?: string,
    numeroCVI?: string
  ): Observable<PaginateResponseStream<ManquementResultat[]>> {
    const dateConverter = new DateConverter();
    // Générer les paramètres de la requête
    let params = new HttpParams().set('idNature', idNature.toString());
    params = (idsStatuts) ? params.set('idManquementStatutList', idsStatuts.join(',')) : params;
    params = (dateDebut) ? params.set('dateConstatMin', dateConverter.serialize(dateDebut)) : params;
    params = (dateFin) ? params.set('dateConstatMax', dateConverter.serialize(dateFin)) : params;
    params = (idsAgents) ? params.set('idAgentList', idsAgents.join(',')) : params;
    params = (numeroManquement) ? params.set('numeroManquement', numeroManquement) : params;
    params = (numeroControle) ? params.set('numeroControle', numeroControle) : params;
    params = (numeroCVI) ? params.set('numeroCVI', numeroCVI) : params;

    // Exécuter la requête de pagination
    return this.paginationService.paginateGetAsStream<object[]>(
      `/api/declaration/private/organismes/${idOrganisme}/manquements`,
      undefined,
      undefined,
      { params },
      true
    ).pipe(
      // Concaténer les données de référence à l'observable de la pagination
      flatMap(response => {
        return forkJoin(
          this.referentielService.getReferentiel(),
          this.cahiersService.getCahiers(),
          this.animateursService.getAnimateurs(idOrganisme)
        ).pipe(
          map(([referentiel, cahiers, agents]) =>
            [response, referentiel, cahiers, agents] as [PaginateResponseStream<object[]>, Referentiel, Cahier[], Animateur[]]
          )
        );
      }),
      // Générer le resultat paginé
      map(([response, referentiel, cahiers, agents]) => {
        response.response = this.mapper.deserializeArray(
          response.response,
          ManquementResultat,
          Object.assign({ cahiers, agents }, referentiel)
        );
        return response as PaginateResponseStream<ManquementResultat[]>;
      }),
      // Charger l'opérateur et le produit
      tap(response =>
        response.response.forEach(manquement =>
          forkJoin(
            this.operateursService.getOperateur(manquement.idOperateur),
            (manquement.codeProduit) ? this.produitsService.getProduitByCode(manquement.codeProduit) : of(undefined),
            this.operateursService.getInformationsDomaine(manquement.idOperateur)
          ).subscribe(([operateur, produit, infos]) => {
            [manquement.operateur, manquement.produit] = [operateur, produit];
            const cvi = infos.find(infos => !!~infos.code.indexOf('CVI'));
            manquement.cvi = (cvi) ? cvi.valeur : null;
          })
        )
      )
    );
  }

  /**
   * Enregistre une collection de nouveaux manquements et retourne le numéro de manquement
   * associé
   * @param idControle L'ID du contrôle auquel se rattache le manquement
   * @param constats La collection de constats à enregistrer
   * @see Constat
   * @returns Tableau d'entiers contenant les numéros de chaque manquement
   */
  postManquements(idControle: number, constats: Constat[]): Observable<number[]> {
    return this.http.post<number[]>(
      `/api/declaration/private/controles/${idControle}/manquements/`,
      this.mapper.serialize(constats)
    ).pipe(map(result => result));
  }

  /**
   * Met à jour le constat d'un manquement
   * @param idOrganisme L'ID de l'organisme rattaché au manquement
   * @param idControle L'ID du contrôle dont dépend le manquement
   * @param manquement Le manquement
   * @see Manquement
   * @Returns Observable<void>
   */
  putConstat(idOrganisme: number, idControle: number, manquement: Manquement): Observable<void> {
    return this.http.put(
      `/api/declaration/private/organismes/${idOrganisme}/controles/${idControle}/manquements/${manquement.id}/constats`,
      this.mapper.serialize(manquement.constat)
    ).pipe(map(() => { }));
  }

  /**
   * Met à jour la décision d'un manquement
   * @param idOrganisme L'ID de l'organisme rattaché au manquement
   * @param idControle L'ID du contrôle dont dépend le manquement
   * @param manquement Le manquement
   * @see Manquement
   * @Returns Observable<void>
   */
  putDecision(idOrganisme: number, idControle: number, manquement: Manquement): Observable<void> {
    return this.http.put(
      `/api/declaration/private/organismes/${idOrganisme}/controles/${idControle}/manquements/${manquement.id}/decisions`,
      this.mapper.serialize(manquement.decision)
    ).pipe(map(() => { }));
  }

  /**
   * Retourne un manquement
   * @param idOrganisme L'ID de l'organisme de rattachement du manquement
   * @param idManquement L'ID du manquement recherché
   * @returns Observable<Manquement>
   * @see Manquement
   */
  getManquement(
    idOrganisme: number,
    idManquement: number,
    idNature: number,
    idObjet: number
  ): Observable<Manquement> {
    // Récupérer le manquement
    return this.http.get<object>(`/api/declaration/private/organismes/${idOrganisme}/manquements/${idManquement}`).pipe(
      flatMap(manquement => {
        // Récupérer le référentiel, les cahiers et les animateurs
        return forkJoin(
          this.referentielService.getReferentiel(),
          this.cahiersService.getCahiers(),
          this.animateursService.getAnimateurs(idOrganisme)
        ).pipe(
          map(([referentiel, cahiers, agents]) =>
            [manquement, referentiel, cahiers, agents] as [Manquement, Referentiel, Cahier[], Animateur[]]
          )
        );
      }),
      map(([manquement, referentiel, cahiers, agents]) => {
        const result = this.mapper.deserializeObject(manquement, Manquement, Object.assign({ cahiers, agents }, referentiel));
        // Mapper l'intensité retournée par le back
        switch (result.constat.intensite.toLowerCase()) {
          case 'faible': result.constat.intensite = IntensiteDefaut.FAIBLE; break;
          case 'moyenne': result.constat.intensite = IntensiteDefaut.MOYENNE; break;
          case 'forte': result.constat.intensite = IntensiteDefaut.FORTE; break;
          default: result.constat.intensite = IntensiteDefaut.MOYENNE;
        }
        // Mapper la gravité retournée par le back
        switch (result.constat.gravite.toLowerCase()) {
          case 'mineure': result.constat.gravite = Gravite.MINEURE; break;
          case 'majeure': result.constat.gravite = Gravite.MAJEURE; break;
          case 'grave': result.constat.gravite = Gravite.GRAVE; break;
          default: result.constat.gravite = Gravite.MAJEURE;
        }
        return result;
      }),
      tap(manquement => {
        // Charger les données de référence
        this.getReferenceDatas(manquement.constat.cahierDesCharges.id, idNature, idObjet).subscribe(points => {
          // Récupérer le point à maîtriser
          const point = points.find(_ => _.id === manquement.constat.idPointAMaitriser);
          if (point) {
            // Récupérer le manquement constaté
            manquement.constat.pointAMaitriser = point;
            const manquementConstate = point.manquementsConstates.find(_ => _.id === manquement.constat.idManquementConstate);
            if (manquementConstate) {
              manquement.constat.manquementConstate = manquementConstate;
              // Récupérer la sanction encourue
              const sanctionEncourue = manquementConstate.sanctionsEncourues.find(_ => _.id === manquement.constat.idSanctionEncourue);
              if (sanctionEncourue) {
                manquement.constat.sanctionEncourue = sanctionEncourue;
              }
            }
          }
        });

        // Récupérer le produit si un code produit a été renseigné
        if (manquement.constat.codeProduit) {
          this.produitsService.getProduitByCode(manquement.constat.codeProduit).subscribe(produit =>
            manquement.constat.produit = produit
          );
        }
      })
    );
  }

  /**
   * Retourne la liste des données de référence d'un CDC (points à maîtriser, manquements
   * constatés et sanctions encourues)
   * @param idCdc L'ID du CDC concerné
   * @see PointAMaitriser
   * @see ManquementConstate
   * @see Sanction
   */
  getReferenceDatas(idCdc: number, idNature: number, idObjet: number): Observable<PointAMaitriser[]> {
    return this.http.get<object[]>(`/api/declaration/private/cahier/${idCdc}/nature/${idNature}/objet/${idObjet}/manquements-references`).pipe(
      map((references) => this.mapper.deserializeArray(references, PointAMaitriser))
    );
  }

  updateManquementNotification(idOrganisme: number, manquement: Manquement): Observable<void> {
    return this.http.put(`/api/declaration/private/organismes/${idOrganisme}/manquements/${manquement.id}/notifications`, this.mapper.serialize(manquement.notification))
    .pipe(
      map(() => { })
    );
  }

  updateManquementRecours(idOrganisme: number, idControle: number, manquement: Manquement): Observable<void> {
    return this.http.put(
      `/api/declaration/private/organismes/${idOrganisme}/controles/${idControle}/manquements/${manquement.id}/recours`,
      this.mapper.serialize(manquement.recours)
    ).pipe(map(() => { }));
  }

  modifierStatutManquement(idOrganisme: number, manquement: ManquementResultat, idStatut: number): Observable<void> {
    return this.http.patch(`/api/declaration/private/organismes/${idOrganisme}/manquements/${manquement.id}`, idStatut).pipe(
      map(() => { }),
    );
  }

  public countManquementEncours(): Observable<number> {
    return this.http.get<number>(`/api/declaration/private/manquements/encours/count`);
  }

  public countManquementEncoursOperateur(idOperateur: number): Observable<number> {
    return this.http.get<number>(`/api/declaration/private/intervenants/${idOperateur}/manquements/encours/count`);
  }
}
