import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JuresService } from 'src/app/data/commission/services/jures/jures.service';
import { Jure } from 'src/app/data/commission/models/jure.model';
import { map, catchError } from 'rxjs/operators';
import { AbstractRechercheItemComponent } from '../abstract-recherche-items.component';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';

@Component({
  selector: 'app-recherche-jure',
  templateUrl: './recherche-jure.component.html',
  styleUrls: ['./recherche-jure.component.scss']
})
export class RechercheJureComponent extends AbstractRechercheItemComponent<Jure> implements OnInit {
  @Input() idOrganisme: number;
  @Input() idsCdcs: number[];
  @Input() alreadySelectedJuresIds: number[];

  jures: Jure[] = [];

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly juresService: JuresService,
    private readonly loaderService: AdvBootstrapLoaderService
  ) {
    super(modal);
  }

  getData() {
    return this.juresService.getJuresByCdcs(this.idOrganisme, this.idsCdcs).pipe(
      this.loaderService.operator(),
      map(
        jures => {
        return jures.filter(jure => this.alreadySelectedJuresIds.indexOf(jure.id) === -1);
      }),
      catchError(error => {
        this.modal.dismiss(error);
        throw error;
      })
    );
  }
}
