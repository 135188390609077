<ng-select [bindLabel]="bindLabel" [formControlName]="controlName" [items]="items" [multiple]="true" [closeOnSelect]="false" [ngStyle]="{'max-width': maxWidth}" [searchable]="true" [maxSelectedItems]="maxSelectedItems">

  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm" let-option="item$">

    <label class="control control-checkbox" (click)="$event.preventDefault()">
        {{item[bindLabel]}}
        <input type="checkbox" [checked]="option.selected" />
        <div class="control_indicator"></div>
    </label>

  </ng-template>

</ng-select>
