import { Component, OnInit, Input, Output } from '@angular/core';
import { Manquement } from 'src/app/data/declaration/models/manquement.model';
import { Controle } from 'src/app/data/declaration/models/controle.model';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { ManquementsService } from 'src/app/data/declaration/services/manquements/manquements.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import * as moment from 'moment';
import { CommissionsService } from 'src/app/data/commission/services/commissions/commissions.service';
import { CommissionRecours } from 'src/app/data/commission/models/commissionRecours';
import { forkJoin } from 'rxjs';
import { resolve } from 'url';

@Component({
  selector: 'app-saisir-recours',
  templateUrl: './saisir-recours.component.html',
  styleUrls: ['./saisir-recours.component.scss']
})
export class SaisirRecoursComponent implements OnInit {

  @Input() public estOnglet = false;
  @Input() public controle: Controle;
  @Input() @Output() public manquement: Manquement;
  private idOrganisme: number;
  public formRecours: FormGroup;
  public commissionsRecours: CommissionRecours[];
  get dateRecours() { return this.formRecours.get('dateRecours'); }
  get dateNouvelleExpertise() { return this.formRecours.get('dateNouvelleExpertise'); }
  get nouveauPrelevement() { return this.formRecours.get('nouveauPrelevement'); }
  get commissionRecours() { return this.formRecours.get('commissionRecours'); }

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly fb: FormBuilder,
    private readonly manquementsService: ManquementsService,
    private readonly commissionService: CommissionsService
  ) { }

  ngOnInit() {
    this.idOrganisme = SessionContext.get('idOrganisme');
    this.loadData();
    this.formRecours = this.fb.group({
      dateRecours: [this.getCustomDateFromMoment(this.manquement.recours.dateRecours), Validators.required],
      dateNouvelleExpertise: this.getCustomDateFromMoment(this.manquement.recours.dateNouvelleExpertise),
      nouveauPrelevement: this.manquement.recours.nouveauPrelevement,
      commissionRecours: [this.manquement.recours.idCommission, Validators.required]
    });

  }

  private loadData(): void {
    forkJoin(
      this.commissionService.getCommissionsRecours(this.idOrganisme)
    ).subscribe(([commissionsRecours]) => {
      this.commissionsRecours = commissionsRecours;
    });
  }

  public async onEdit(): Promise<boolean> {
    return new Promise(resolve => {
      if (this.formRecours.valid) {
        this.manquement.recours.dateRecours = this.getMomentDate(this.dateRecours.value);
        this.manquement.recours.dateNouvelleExpertise = this.getMomentDate(this.dateNouvelleExpertise.value);
        this.manquement.recours.nouveauPrelevement = this.nouveauPrelevement.value;
        this.manquement.recours.idCommission = this.commissionRecours.value;

        this.manquementsService.updateManquementRecours(
          this.idOrganisme,
          this.controle.id,
          this.manquement
        ).subscribe(() => {
          this.formRecours.markAsUntouched();
          resolve(true);
        });
      } else {
        resolve(false);
      }

    });


  }

  private getCustomDateFromMoment(date: moment.Moment) {
    return (date) ? {
      year: date.year(),
      month: date.month() + 1,
      day: date.date()
    } : undefined;
  }

  private getMomentDate(date: {
    year: number,
    month: number,
    day: number
  }): moment.Moment {
    return (date) ? moment([date.year, date.month - 1, date.day, 0, 0, 0]) : null;
  }
}
