<div class="modal-content" >
  <div class="modal-header">
   
    <button (click)="cancel()" type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    
    <div class="tableCustom tableScroll">
      <table class="table table-striped overflow"  *ngIf="unitesCulturales?.length > 0; else aucuneDpap">

        <thead class="sticky-top">
        <tr>
          <th *ngIf="show('numBloc');" scope="col" style="position:relative">
            <div translate>page.inventaire-verger.numBloc</div>
          </th>
          <th *ngIf="show('infosTracabilite');" scope="col" style="position:relative">
            <div translate>page.inventaire-verger.infosTracabilite</div>
          </th>
          <th *ngIf="show('codeParcelle');" scope="col" style="position:relative">
            <div translate>page.inventaire-verger.codeParcelle</div>
          </th>
          <th *ngIf="show('refCadastrale');" scope="col" style="position:relative">
            <div translate>page.inventaire-verger.refCadastrale</div>
          </th>
          <th *ngIf="show('numTracabilite');" scope="col" style="position:relative">
            <div translate>page.inventaire-verger.numDeTracabilite</div>
          </th>
          <th *ngIf="show('lieuDit');" scope="col" style="position:relative">
            <div translate>page.inventaire-verger.lieuDit</div>
          </th>
          <th *ngIf="show('commune');" scope="col" style="position:relative">
            <div translate>page.inventaire-verger.commune</div>
          </th>
          <th *ngIf="show('produitIdentifie');" scope="col" style="position:relative">
            <div translate>page.inventaire-verger.produitIdentifie</div>
          </th>
          <th *ngIf="show('produitDestination');" scope="col" style="position:relative">
            <div translate>page.inventaire-verger.produitDestination</div>
          </th>
          <th *ngIf="show('variete');" scope="col" style="position:relative">
            <div translate>page.inventaire-verger.variete</div>
          </th>
          <th *ngIf="show('surfacePlantee');" scope="col" style="position:relative">
            <div translate>page.inventaire-verger.surfacePlantee</div>
          </th>
          <th *ngIf="show('annee');" scope="col" style="position:relative">
            <div translate>page.inventaire-verger.annee</div>
          </th>
          <th *ngIf="show('cultureBio');" scope="col" style="position:relative">
            <div translate>page.inventaire-verger.cultureBio</div>
          </th>
          <th *ngIf="show('departement');" scope="col" style="position:relative">
            <div translate>page.inventaire-verger.departement</div>
          </th>
          <th *ngIf="show('distanceInterRang');" scope="col" style="position:relative">
            <div translate>page.inventaire-verger.distanceInterRang</div>
          </th>
          <th *ngIf="show('distanceIntraRang');" scope="col" style="position:relative">
            <div translate>page.inventaire-verger.distanceIntraRang</div>            
          </th>
          <th *ngIf="show('systemeIrrigation');" scope="col" style="position:relative">
            <div translate>page.inventaire-verger.systemeIrrigation</div>            
          </th>
          <th>
            <fa-icon class="clickable-icon" icon="ellipsis-h" (click)="showList()"></fa-icon>
          </th>
        </tr>
        </thead>
        <tbody>        
          <tr *ngFor="let uniteCulturale of unitesCulturales">    
            <td *ngIf="show('numBloc');">{{ uniteCulturale.numBlocFruitier}}</td>
            <td *ngIf="show('infosTracabilite');">{{ uniteCulturale.getInfosTracabilite()}} </td>
            <td *ngIf="show('codeParcelle');">{{ uniteCulturale.codeParcelle}}</td>
            <td *ngIf="show('refCadastrale');">{{ uniteCulturale.localisation.referenceCadastrale}}</td>
            <td *ngIf="show('numTracabilite');">{{ uniteCulturale.numTracabilite}}</td>
            <td *ngIf="show('lieuDit');">{{ uniteCulturale.localisation.lieuDit}}</td>
            <td *ngIf="show('commune');">{{ uniteCulturale.localisation.commune}}</td>
            <td *ngIf="show('produitIdentifie');">{{ uniteCulturale.uniteCulturaleVigne.produitIdentifie}}</td>
            <td *ngIf="show('produitDestination');">{{ uniteCulturale.uniteCulturaleVigne.produitDestination}}</td>
            <td
              *ngIf="show('variete');">{{ uniteCulturale.variete.varieteClone ? uniteCulturale.variete.varieteClone.libelle : uniteCulturale.variete.libelle}}</td>
           <td *ngIf="show('surfacePlantee');">{{ uniteCulturale.uniteCulturaleVigne.surfacePlantee | number:'1.3-3'}}</td>
            <td *ngIf="show('annee');">{{ uniteCulturale.getInfosAnnee()}}</td>
           <td
              *ngIf="show('cultureBio');">{{ uniteCulturale.caracteristiqueParcelle.refCultureBio ? uniteCulturale.caracteristiqueParcelle.refCultureBio.libelle : ''}}</td>
            <td *ngIf="show('departement');">{{ uniteCulturale.localisation.departement}}</td>
           <td *ngIf="show('distanceInterRang');">{{ uniteCulturale.distanceInterRang | number:'1.2-2'}}</td>
            <td *ngIf="show('distanceIntraRang');">{{ uniteCulturale.distanceIntraRang | number:'1.2-2'}}</td>
            <td
              *ngIf="show('systemeIrrigation');">{{ uniteCulturale.caracteristiqueParcelle.refSystemeIrrigation ? uniteCulturale.caracteristiqueParcelle.refSystemeIrrigation.libelle : ''}}</td>
           <td> 
            <span>
              <button type="button" class="btn btn-outline-secondary btn-add" (click)="visuUC(uniteCulturale)">
                <fa-icon icon="clipboard-list" class="primary-icon" title="Récapitulatif"></fa-icon>
              </button>            
            </span>
          </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #aucuneDpap>
  <div class="alert alert-warning alert-block" translate>
    <fa-icon icon="exclamation"></fa-icon>
    page.inventaire-verger.modals.historique-dpap.aucuneDpap
  </div>
</ng-template>
