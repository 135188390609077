import { CustomConverter, JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { DateConverter } from 'src/app/core/services/mapper/converters';

@JsonObject
export class AccueilAnppProducteur {
  
  @JsonProperty('idEntreprise', Number)
  public idEntreprise: number = undefined;
  
  @JsonProperty('raisonSociale', String)
  public raisonSociale: string = undefined;

  @JsonProperty('email', String)
  public email: string = undefined;

  @JsonProperty('telephone', String)
  public telephone: string = undefined;

  @JsonProperty('groupement', String)
  @Optional
  public groupement: string = undefined;

  @JsonProperty('dateDeclarationRecolte', String)
  @CustomConverter(DateConverter)
  @Optional
  dateDeclarationRecolte: moment.Moment;

  @JsonProperty('dateDeclarationStock', String)
  @CustomConverter(DateConverter)
  @Optional
  dateDeclarationStock: moment.Moment;

  @JsonProperty('dateDeclarationrEcoulement', String)
  @CustomConverter(DateConverter)
  @Optional
  dateDeclarationrEcoulement: moment.Moment;

  @JsonProperty('dateModificationInventaireVerger', String)
  @CustomConverter(DateConverter)
  @Optional
  dateModificationInventaireVerger: moment.Moment;
}
