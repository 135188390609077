import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { map } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { ModeleFacturation } from '../../models/modele-facturation.model';
import { ReferencesFacturationService } from '../references-facturation/references-facturation.service';
import { ModeleArticle } from '../../models/modele-article.model';

@Injectable({
  providedIn: 'root'
})
export class ModeleArticleService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly refFacturationService: ReferencesFacturationService
  ) { }

  getModelArticleById(idOrganisme: number, idModeleArticle: number): Observable<ModeleArticle> {
    return forkJoin(
      this.http.get<object>(`/api/facturation/private/organismes/${idOrganisme}/modeles/articles/${idModeleArticle}`),
      this.refFacturationService.getReferences()// ,
      // this.modeleArticleService.getArticles(),
    ).pipe(
      map(([modeleArticle, ref]) => this.mapper.deserializeObject(modeleArticle,
        ModeleArticle, Object.assign({}, ref))),
    );
  }


  createModeleArticle(idOrganisme: number, modeleArticle: ModeleArticle, idModeleFacturation: number): Observable<{ id: number, libelle: string }> {
    return this.http.post(`/api/facturation/private/organismes/${idOrganisme}/modeles/${idModeleFacturation}/articles`, this.mapper.serialize(modeleArticle),
      {
        observe: 'response',
        responseType: 'text'
      })
      .pipe(
        map(response => {
          return {
            id: parseInt(response.headers.get('location').split('/').pop(), 10),
            libelle: response.body
          };
        })
      );
  }

  modifyModeleArticle(idOrganisme: number, modeleArticle: ModeleArticle, idModeleFacturation: number): Observable<{ id: number, libelle: string }> {
    return this.http.put(`/api/facturation/private/organismes/${idOrganisme}/modeles/${idModeleFacturation}/articles/${modeleArticle.id}`, this.mapper.serialize(modeleArticle),
      {
        observe: 'response',
        responseType: 'text'
      })
      .pipe(
        map(response => {
          return {
            id: parseInt(response.headers.get('location').split('/').pop(), 10),
            libelle: response.body
          };
        })
      );
  }

  deleteModeleArticle(idOrganisme: number, modeleArticle: ModeleArticle, idModeleFacturation: number): Observable<void> {
    return this.http.delete(`/api/facturation/private/organismes/${idOrganisme}/modeles/${idModeleFacturation}/articles/${modeleArticle.id}`).pipe(
      map(() => { })
    );
  }
}
