import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class HistogrammeRendement {
  @JsonProperty('libelleGroupeVarietal', String)
  @Optional
  libelleGroupeVarietal: string = undefined;

  @JsonProperty('rendementEntreprise', Number)
  @Optional
  rendementEntreprise: number = undefined;

  @JsonProperty('rendementDepartemental', Number)
  @Optional
  rendementDepartemental: number = undefined;

  @JsonProperty('rendementNational', Number)
  @Optional
  rendementNational: number = undefined;
}
