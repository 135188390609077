import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { CalendarEvent, CalendarView, DAYS_OF_WEEK, CalendarDateFormatter, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { CommissionsService } from 'src/app/data/commission/services/commissions/commissions.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import * as moment from 'moment';
import { CommissionAgenda } from 'src/app/data/commission/models/commission-agenda.model';
import { CustomDateFormatter } from './CustomDateFormatter.provider';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { AnimateursService } from 'src/app/data/commission/services/animateurs/animateurs.service';
import { Animateur } from 'src/app/data/commission/models/animateur.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';

const colors: any = {
  red: { primary: '#ad2121', secondary: '#FAE3E3' },
  blue: { primary: '#1e90ff', secondary: '#D1E8FF' },
  yellow: { primary: '#FFFB00', secondary: '#FFFEBF' },
  orange: { primary: '#FFA500', secondary: '#FFE5B5' }
};

moment.updateLocale('fr', {
  week: {
    dow: DAYS_OF_WEEK.MONDAY,
    doy: 0
  }
});

@Component({
  selector: 'app-planning-commissions',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './planning-commissions.component.html',
  styleUrls: ['./planning-commissions.component.scss'],
  providers: [{ provide: CalendarDateFormatter, useClass: CustomDateFormatter }]
})
export class PlanningCommissionsComponent implements OnInit {
  maxEventsMonthView = 3;
  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  events: CalendarEvent<{ commission: CommissionAgenda }>[] = [];
  activeDayIsOpen = false;
  dateDebut: moment.Moment = moment().startOf('week');
  dateFin: moment.Moment = moment().endOf('week');
  locale = 'fr_FR';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  actions: CalendarEventAction[] = [{
    label: 'Editer  ',
    onClick: ({ event }: { event: CalendarEvent }): void => {
      this.handleEvent('Edited', event);
    }
  }, {
    label: '  Supprimer',
    onClick: ({ event }: { event: CalendarEvent }): void => {
      this.handleEvent('Deleted', event);
    }
  }];
  animateurs: Animateur[];
  formFiltre: FormGroup;

  get listeEvents() {
    const filtreAnimateur = this.formFiltre.get('animateur').value;
    if (filtreAnimateur === null) {
      return this.events;
    } else {
      return this.events.filter(evt => evt.meta.commission.animateur.id === filtreAnimateur.id);
    }
  }

  constructor(
    private readonly router: Router,
    private readonly commissionsService: CommissionsService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly animateursService: AnimateursService,
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService
  ) { }

  ngOnInit() {
    this.formFiltre = this.fb.group({
      animateur: [undefined]
    });
    this.formFiltre.get('animateur').valueChanges.subscribe(animateur => {  this.refresh.next(); });

    this.loadData();
  }

  loadData() {
    this.events = [];
    forkJoin(
      this.commissionsService.getAgendaCommissions(SessionContext.get('idOrganisme'), this.dateDebut, this.dateFin),
      this.animateursService.getAnimateurs(SessionContext.get('idOrganisme'))
    ).pipe(
      this.loaderService.operator()
    ).subscribe(([commissions, animateurs]) => {
      this.animateurs = animateurs;
      let titre = '';
      commissions.forEach((commission: CommissionAgenda) => {
        titre = `<b>${this.translate.instant('page.commissions.planning.numero')} : ${commission.numero}</b><br />`;
        titre += (this.view === CalendarView.Month) ? `${this.translate.instant('page.commissions.planning.horaire')} : ${commission.debut.format('HH:mm')} - ${commission.fin.format('HH:mm')}<br />` : '';
        titre += `- ${this.translate.instant('page.commissions.planning.salle')} : ${commission.salle.nom}<br />
                    - ${this.translate.instant('page.commissions.planning.animateur')} : ${commission.animateur.prenom} ${commission.animateur.nom}<br />
                    - ${this.translate.instant('page.commissions.planning.echantillons')} : ${commission.nbEchantillons}, ${this.translate.instant('page.commissions.planning.jures')} : ${commission.nbJuresPresents + commission.nbJuresInscrits} / ${commission.nbJuresTotal}`;
        this.events.push({
          title: titre,
          start: commission.debut.toDate(),
          end: commission.fin.toDate(),
          resizable: { beforeStart: false, afterEnd: false },
          draggable: false,
          color: commission.terminee ? colors.orange : colors.yellow,
          actions: this.actions,
          meta: { commission }
        });
      });
      this.refresh.next();
    });
  }

  compareAnimateurs(a: Animateur, b: Animateur) {
    return a && b ? a.id === b.id : a === b;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    const dateJour = moment([date.getFullYear(), date.getMonth(), date.getDate()]);
    const dateVue = moment([this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate()]);
    if (dateVue.isSame(dateJour, 'month')) {
      if (
        (dateVue.isSame(dateJour, 'day') && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    if (action === 'Edited') {
      this.router.navigate([`main/controles/commissions/${event.meta.commission.id}/edit`]);
    } else if (action === 'Deleted') {
      if(event.meta.commission.nbEchantillons > 0 || 
        event.meta.commission.nbJuresTotal > 0 || 
        event.meta.commission.nbJuresInscrits > 0 || 
        event.meta.commission.nbJuresPresents > 0) {
          this.modalsService.alert(this.translate.instant('page.commissions.planning.removeDisable'));
      } else {
        this.modalsService.confirm(
          `${this.translate.instant('page.commissions.planning.confirm_remove.message')} ${event.meta.commission.numero} ?`,
          this.translate.instant('page.commissions.planning.confirm_remove.title'),
          { cancelText: this.translate.instant(`label.annuler`), submitText: this.translate.instant(`label.valider`) }
        ).then(() => {
          this.commissionsService.deleteCommission(SessionContext.get('idOrganisme'), event.meta.commission)
          .subscribe(() => {
            this.deleteEvent(event);
            this.refresh.next();
            this.toastr.success(
              this.translate.instant('page.commissions.edit.alert.suppression.message', { numero: event.meta.commission.numero }),
              this.translate.instant('page.commissions.edit.alert.suppression.title'),
              { timeOut: 10 * 1000 }
            );
          });
        });
      }
    }
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
    this.refreshData();
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    this.refreshData();
  }

  refreshDatesIntervalle() {
    switch (this.view) {
      case CalendarView.Month:
        this.dateDebut = moment([this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate()]).startOf('month');
        this.dateFin = moment([this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate()]).endOf('month');
        break;
      case CalendarView.Week:
        this.dateDebut = moment([this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate()]).startOf('week');
        this.dateFin = moment([this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate()]).endOf('week');
        break;
      default:
        this.dateDebut = moment([this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate()]);
        this.dateFin = moment([this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate()]);
    }
  }

  refreshData() {
    this.refreshDatesIntervalle();
    this.loadData();
  }
}
