import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { DeclarationDr } from './declaration-dr.model';
import { DeclarationSV11 } from './declaration-sv11.model';
import { DeclarationSV12 } from './declaration-sv12.model';
import { Ecoulement } from './ecoulement.model';

@JsonObject
export class DeclarationEcoulement {
  @JsonProperty('idRefCampagne', Number)
  @Optional
    public idRefCampagne:  number;

  @JsonProperty('idEntreprise', Number)
  @Optional
    public idEntreprise:  number;

  @JsonProperty('numMois', Number)
  @Optional
    public numMois:  number;

  @JsonProperty('ecoulements', [Ecoulement])
  @Optional
    public ecoulements: Ecoulement[] = [];
}
