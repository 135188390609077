<!-- <p>
  affichage-drev works!
  <app-tank [value]="valueG"></app-tank>
  <input type="number" [(ngModel)]="valueG" value="0"/>
</p> -->

<div class="content">
  <div class="content-panel">
    <form [formGroup]="formGroup" #form="ngForm">
      <label translate>Année</label> :
      <input formControlName="commission" maxlength="4"
        [ngClass]="{'is-invalid': commission.dirty && commission.invalid}" class="form-control commission-input" />
    </form>
    <div id="liste-drev-aoc" *ngIf="declarations?.aocs.length > 0 || declarations?.igps.length > 0; else aucunProduit">
      <!-- AOC -->
      <h2 translate [translateParams]="{ annee: commission.value }">page.declarations.drev.title.aoc</h2>

      <table table class="table table-striped" *ngIf="declarations?.aocs.length > 0">
        <thead>
          <tr>
            <th class="liste-col-header" rowspan="2" translate>page.declarations.drev.table.header.produit_mention</th>
            <th class="liste-col-header" rowspan="2" translate>page.declarations.drev.table.header.surface</th>
            <th class="liste-col-header" rowspan="2" translate>page.declarations.drev.table.header.volumes_revendiques
            </th>
            <th class="liste-col-header" style="text-align:center;" colspan="3" translate>
              page.declarations.drev.table.header.details_volumes</th>
            <th class="liste-col-header" rowspan="2" translate>page.declarations.drev.table.header.stock_vci</th>
            <th class="liste-col-header" rowspan="2" translate>page.declarations.drev.table.header.reserve_vsi</th>
            <th rowspan="2"></th>
          </tr>
          <tr style="background-color:#ccc;">
            <th class="liste-col-header" translate>page.declarations.drev.table.header.vci_n-1</th>
            <th class="liste-col-header" translate>page.declarations.drev.table.header.vin_n</th>
            <th class="liste-col-header" translate>page.declarations.drev.table.header.vsi_liberee</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let declaration of declarations?.aocs">
            <td>{{ declaration.produit?.libelle }}<span *ngIf="declaration.mention"> - {{ declaration.mention }}</span>
            </td>
            <td>{{ declaration.surface | number:'1.4-4' }}</td>
            <td>{{ declaration.volumeNetRevendiqueEncours | number:'1.2-2' }}</td>
            <td>{{ declaration.getStockVciLastYear() | number:'1.2-2' }}</td>
            <td>{{ declaration.getStockWineCurrentYear() | number:'1.2-2' }}</td>
            <td>{{ declaration.reserveVsiLiberee | number:'1.2-2' }}</td>
            <td>{{ declaration.getStockVciCurrentYear() | number:'1.2-2' }}</td>
            <td>{{ declaration.vsi | number:'1.2-2' }}</td>
            <td>
              <fa-icon *ngIf="declaration.produit?.eligibleVci" class="clickable-icon icon-with-background" icon="cubes"
                (click)="utilisationVci(declaration)"
                title="{{'page.declarations.drev.table.utilisation-vci' | translate}}"></fa-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- IGP -->

    <div *ngIf="declarations?.igps.length > 0">
      <h2 translate [translateParams]="{ annee: commission.value }">page.declarations.drev.title.igp</h2>

      <table table class="table table-striped">
        <thead>
          <th class="liste-col-header" translate>page.declarations.drev.table.header.produit_mention</th>
          <th class="liste-col-header" translate>page.declarations.synthese.table.header.surface</th>
          <th class="liste-col-header" translate>page.declarations.synthese.table.header.volumesIssusDR</th>
        </thead>
        <tbody>
          <tr *ngFor="let declaration of declarations?.igps">
            <td>{{declaration.produit?.libelle}}<span *ngIf="declaration.mention"> - {{declaration.mention}}</span></td>
            <td>{{declaration.surface | number:'1.4-4' }}</td>
            <td>{{declaration.volume | number:'1.2-2' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #aucunProduit>
  <div class="alert alert-warning alert-block" translate [translateParams]="{ annee: commission.value }">
    <fa-icon icon="exclamation"></fa-icon>
    page.declarations.drev.table.aucunProduit
  </div>
  <ng-template>