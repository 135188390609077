import { JsonObject, JsonProperty, Optional, CustomConverter } from '@thorolf/json-ts-mapper';
import { Activite } from './activite.model';
import { Cahier } from './cahier.model';
import { StatutHabilitation } from './statut-habilitation.model';
import { RefStatut } from './statut.ref.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';


@JsonObject
export class Habilitation {
  @JsonProperty('id', Number)
  @Optional
  public id: number = undefined;

  @JsonProperty('idCahier', Number)
  @CustomConverter(DataIdConverterFactory.build(Cahier, 'cahiers'))
  public cahier: Cahier = undefined;

  @JsonProperty('idActivite', Number)
  @CustomConverter(DataIdConverterFactory.build(Activite, 'activites'))
  public activite: Activite = undefined;

  @JsonProperty('idStatut', Number)
  @CustomConverter(DataIdConverterFactory.build(RefStatut, 'statutsHabilitation'))
  @Optional
  public statut: RefStatut = undefined;

  @JsonProperty('historiquesStatut', [StatutHabilitation])
  @Optional
  public historique: StatutHabilitation[] = [];
}
