<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>page.commissions.synthese-jure.modals.title</h4>
  </div>

  <div class="modal-body" >


<table class="table" >
  <thead>
    <th translate>page.commissions.synthese-jure.modals.numeroCommission</th>
    <th translate>page.commissions.synthese-jure.modals.dateCommission</th>
    <th translate>page.commissions.synthese-jure.modals.statut</th>
    <th translate>page.commissions.synthese-jure.modals.college</th>
    <th translate>page.commissions.synthese-jure.modals.cdcs</th>
  </thead>

  <tbody>
    <tr *ngFor="let jure of detailJures">
      <td>{{ jure.numeroCommission }}</td>
      <td>{{ jure.dateCommission | date:'shortDate' }}</td>
      <td>{{ jure.statutJure?.libelle }}</td>
      <td class="small">
        <span *ngFor="let college of jure.colleges">
          {{ college.libelle }}<br>
        </span>
      </td>
      <td class="small">
        <span *ngFor="let cahier of jure.cahiers">
          {{ cahier.libelle }} <br>
        </span>
      </td>
  
     
    </tr>
  </tbody>
</table>


  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-light" (click)="onClose()" translate>label.fermer</button>
  </div>
</div>