import { IdCodeLibelle } from '../../id-code-libelle.abstract.model';
import { CustomConverter, JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { DateConverter } from 'src/app/core/services/mapper/converters';

@JsonObject
export class Campagne extends IdCodeLibelle {
    @JsonProperty('annee', Number)
    annee: number = undefined;

    @JsonProperty('dateDebutAnpp', String)
    @CustomConverter(DateConverter)
    @Optional
    dateDebutAnpp: moment.Moment;

    @JsonProperty('dateFinAnpp', String)
    @CustomConverter(DateConverter)
    @Optional
    dateFinAnpp: moment.Moment;

    @JsonProperty('dateDebut', String)
    @CustomConverter(DateConverter)
    @Optional
    dateDebut: moment.Moment;

    @JsonProperty('dateFin', String)
    @CustomConverter(DateConverter)
    @Optional
    dateFin: moment.Moment;
}
