<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>page.commissions.edit.modals.jures.title</h4>
  </div>

  <div class="modal-body" *ngIf="loaded">
    <app-search-bar [(value)]="searchString" *ngIf="items.length > 0"></app-search-bar>

    <table class="table" *ngIf="items.length > 0; else aucunJure">
      <thead>
        <ng-container *ngTemplateOutlet="tableHeader; context: { sortProperty: 'item.nom', libelle: 'page.commissions.edit.modals.jures.table.header.nom' }"></ng-container>
        <th translate>page.commissions.edit.modals.jures.table.header.college</th>
        <th translate>page.commissions.edit.modals.jures.table.header.cahier</th>
        <ng-container *ngTemplateOutlet="tableHeader; context: { sortProperty: 'item.dateDerniereFormation', libelle: 'page.commissions.edit.modals.jures.table.header.dateDerniereFormation' }"></ng-container>
        <th translate>page.commissions.edit.modals.jures.table.header.operateurs_lies</th>
      </thead>
      
      <tbody>
        <tr *ngFor="let item of items | filter: 'item':searchString | orderBy: order.property:order.reverse:true" [ngClass]="{ 'selected': item.selected, 'disabled': item.disabled }" (click)="toggleSelectionItem(item)">
          <td>{{ item.item.nom }}</td>
          <td class="small">
            <span *ngFor="let college of item.item.colleges">
              {{ college.libelle }}<br>
            </span>
          </td>
          <td class="small">
            <span *ngFor="let cahier of item.item.cahiers">
              {{ cahier.libelle }} <br>
            </span>
          </td>
          <td>{{ item.item.dateDerniereFormation | date:'shortDate' }}</td>
          <td>
            <ul>
              <li *ngFor="let operateur of item.item.operateurs">
                {{ operateur.siret }} - {{ operateur.raisonSociale }}
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-light" (click)="cancel()" translate>label.annuler</button>
    <button type="submit" class="btn btn-primary" (click)="submit()" [disabled]="selectedItems.length === 0" [translateParams]="{nb:selectedItems.length}" translate>page.commissions.edit.modals.jures.valider</button>
  </div>
</div>

<ng-template #aucunJure>
  <div translate>page.commissions.edit.modals.jures.table.aucun_jure</div>
</ng-template>

<ng-template #tableHeader let-property="sortProperty" let-libelle="libelle">
  <th *ngIf="property" (click)="setOrder(property)" [class.sortable]="property">
    {{ libelle | translate }} <fa-icon *ngIf="property" [icon]="getIcon(property)"></fa-icon>
  </th>
  <th *ngIf="!property">{{ libelle | translate }}</th>
</ng-template>
