import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { Operateur } from '../../intervenant/models/operateur.model';
import { NatureControle } from './nature-controle.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import * as moment from 'moment';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { Animateur } from '../../commission/models/animateur.model';
import { Cahier } from '../../habilitation/models/cahier.model';
import { StatutControle } from './statut-controle.model';
import { Produit } from '../../habilitation/models/produit.model';
import { StatutManquement } from './statut-manquement.model';

@JsonObject
export class ManquementResultat {
    @JsonProperty('idManquement', Number)
    id: number;

    @JsonProperty('numeroManquement', String)
    numero: string;

    @JsonProperty('numeroControle', String)
    numeroControle: string;

    @JsonProperty('idOperateur', Number)
    idOperateur: number;

    @JsonProperty('idNature', Number)
    @CustomConverter(DataIdConverterFactory.build(NatureControle, 'naturesControle'))
    nature: NatureControle;

    @JsonProperty('dateConstat', String)
    @CustomConverter(DateConverter)
    dateConstat: moment.Moment;

    @JsonProperty('idAgent', Number)
    @CustomConverter(DataIdConverterFactory.build(Animateur, 'agents'))
    @Optional
    agent: Animateur;

    @JsonProperty('idCahier', Number)
    @CustomConverter(DataIdConverterFactory.build(Cahier, 'cahiers'))
    cahierDesCharges: Cahier;

    @JsonProperty('idStatut', Number)
    @CustomConverter(DataIdConverterFactory.build(StatutManquement, 'statutsManquement'))
    statut: StatutManquement;

    @JsonProperty('codeProduit', String)
    @Optional
    codeProduit: string;

    @JsonProperty('idControle', Number)
    @Optional
    idControle: number;

    operateur: Operateur;
    produit: Produit;
    cvi: string;
    raisonSociale: string;
}
