import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-connexion-operateur',
  templateUrl: './connexion-operateur.component.html',
  styleUrls: ['./connexion-operateur.component.scss']
})
export class ConnexionOperateurComponent implements OnInit {
  formGroup: FormGroup;

  get identifiant() { return this.formGroup.get('identifiant'); }
  get domaine() { return this.formGroup.get('domaine'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      identifiant: [undefined, Validators.required],
      domaine: [undefined, Validators.required]
    });
  }

  submit() {
    if (this.formGroup.valid) {
      const identifiantIntervenant = this.identifiant.value;
      const domaine = this.domaine.value;

      this.router.navigate(['../', 'redirection'], {
        relativeTo: this.route,
        queryParams: {
          identifiantIntervenant,
          domaine
        }
      });
    }
  }
}
