import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl} from '@angular/forms';
import {ProduitsService} from 'src/app/data/habilitation/services/produits/produits.service';
import {CahiersService} from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import {ActivitesService} from 'src/app/data/habilitation/services/activites/activites.service';
import {forkJoin, of} from 'rxjs';
import {Cahier} from 'src/app/data/habilitation/models/cahier.model';
import {Activite} from 'src/app/data/habilitation/models/activite.model';
import {AdvBootstrapLoaderService} from '@adv/bootstrap-loader';
import {Produit} from 'src/app/data/habilitation/models/produit.model';
import {SessionContext} from 'src/app/core/services/config/app.settings';
import {distinctUntilChanged, debounceTime, subscribeOn} from 'rxjs/operators';
import {ReferentielService} from 'src/app/data/declaration/services/referentiel/referentiel.service';
import {RefMouvement} from 'src/app/data/declaration/models/ref-mouvement.model';
import {ActivatedRoute, Router} from '@angular/router';
import {PressionControle} from 'src/app/data/declaration/models/pression-controle.model';
import {PressionsService} from 'src/app/data/declaration/services/pressions/pressions.service';
import {ObjetControle} from 'src/app/data/declaration/models/objet-controle.model';
import {NatureControle} from 'src/app/data/declaration/models/nature-controle.model';
import {PressionDeclencheur} from 'src/app/data/declaration/models/pression-declencheur.model';
import {Campagne} from 'src/app/data/declaration/models/campagne.model';
import {OrganismesService} from "../../../../../data/intervenant/services/organismes/organismes.service";

@Component({
  selector: 'app-pression-controle',
  templateUrl: './pression-controle.component.html',
  styleUrls: ['./pression-controle.component.scss']
})
export class PressionControleComponent implements OnInit {
  private idOrganisme: number;
  private idPression: number;
  private codeRefOrganisme: string;
  public refAnnees: Campagne[] = [];
  public refActivites: Activite[];
  public refCahiers: Cahier[];
  public refDeclencheurs: PressionDeclencheur[];
  public refMouvements: RefMouvement[];
  public refNatures: NatureControle[];
  public refObjets: ObjetControle[];
  public refProduits: Produit[];
  public formPression: FormGroup;
  public filteredRefDeclencheurs: PressionDeclencheur[] = [];

  get type() {
    return this.formPression.get('type');
  }

  get libelle() {
    return this.formPression.get('libelle');
  }

  get nature() {
    return this.formPression.get('nature');
  }

  get objet() {
    return this.formPression.get('objet');
  }

  get cdcs() {
    return this.formPression.get('cdcs');
  }

  get produits() {
    return this.formPression.get('produits');
  }

  get activites() {
    return this.formPression.get('activites');
  }

  get declencheur() {
    return this.formPression.get('declencheur');
  }

  get typesDeclaration() {
    return this.formPression.get('typesDeclaration') as FormArray;
  }

  get pression() {
    return this.formPression.get('pression');
  }

  get baseCampagne() {
    return this.formPression.get('baseCampagne');
  }

  get annees() {
    return this.formPression.get('annees') as FormArray;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly activitesService: ActivitesService,
    private readonly organismeService: OrganismesService,
    private readonly cahiersService: CahiersService,
    private readonly produitsService: ProduitsService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly pressionService: PressionsService,
    private readonly referentielService: ReferentielService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {
  }

  ngOnInit() {
    this.idOrganisme = SessionContext.get('idOrganisme');
    this.idPression = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.loadData();
  }

  /**
   * Charge la pression dans le cas d'une modification et charge toutes les données
   * nécessaires nécessaires au remplissage des listes déroulantes
   */
  private loadData(): void {
    forkJoin(
      this.idPression ? this.pressionService.getPressionControle(this.idPression) : of(new PressionControle()),
      this.cahiersService.getCahiers(),
      this.activitesService.getActivites(),
      this.referentielService.getReferentiel(),
      this.organismeService.getOrganisme(SessionContext.get('idOrganisme'))
    ).pipe(
      this.loaderService.operator()
    ).subscribe(([pression, cahiers, activites, referentiel, organisme]) => {
      this.refActivites = activites;
      this.refAnnees = referentiel.campagnes;
      this.refCahiers = cahiers;
      this.refDeclencheurs = referentiel.pressionDeclencheurs;
      const codesFiltres: string[] = ['REVENDICATION', 'TRANSACTION', 'CONDITIONNEMENT'];
      this.refMouvements = referentiel.refMouvements.filter(refMouvement => codesFiltres.includes(refMouvement.code));
      this.refNatures = referentiel.naturesControle;
      this.refObjets = referentiel.objetsControle;
      this.codeRefOrganisme = organisme.type.code;
      this.filteredRefDeclencheurs = this.refDeclencheurs.filter(d => d.code === 'DECLARATION');
      this.initForm((pression.id) ? pression : null);
    });
  }

  /** Initialise le formulaire de saisie/modification d'une pression de contrôle */
  private initForm(pression?: PressionControle): void {
    if (pression) {
      if (pression.declencheur) {
        console.log(pression.declencheur);
        this.updateDeclencheurOptions(pression.nature);
      }
    }
    this.formPression = this.fb.group({
      type: [{
        value: (pression && !pression.estInterne) ? 'EXTERNE' : this.findDefaultChecboxValue('TYPE_PRESSION'),
        disabled: true
      }, Validators.required],
      libelle: [(pression) ? pression.libelle : undefined, Validators.required],
      nature: [(pression) ? this.refNatures.find(nature => nature.id === pression.nature.id) : undefined, Validators.required],
      objet: [(pression) ? this.refObjets.find(objet => objet.id === pression.objet.id) : undefined, Validators.required],
      cdcs: [(pression) ? this.refCahiers.filter(cahier => pression.cdcs.find(_ => _.id === cahier.id)) : undefined, [Validators.required]],
      produits: [{value: undefined, disabled: !pression}], // Affecté après le chargement dans le cas du mode édition
      activites: [{value: (pression) ? pression.activites : undefined, disabled: true}],
      declencheur: [{
        value: (pression) ? pression.declencheur.id.toString() : this.findDefaultChecboxValue('DECLENCHEUR'),
        disabled: false
      }, Validators.required],
      typesDeclaration: [(pression) ? pression.typesMouvement : undefined, Validators.required],
      pression: [(pression) ? pression.pression : 0, [Validators.required, Validators.min(0), Validators.max(100)]],
      baseCampagne: [(pression && pression.estAnneeCivile) ? 'ANNEE_CIVILE' : 'ANNEE_RECOLTE', Validators.required],
      annees: this.fb.array([])
    });

    // Charger les produits et les campagnes en mode édition
    if (pression) {
      if (pression.cdcs && pression.cdcs.length > 0) {
        this.loadProductByCdcs(pression.cdcs, false, pression.produits);
      }
      if (pression.campagnes && pression.campagnes.length > 0) {
        pression.campagnes.forEach(campagne => this.onAddYear(campagne.annee));
      }
    }

    this.formPression.get('nature').valueChanges.subscribe(nature => {
      if (nature) {
        this.getSelectedNatureCode();
      }
    });
    this.createCdcsChangedListener();
  }

  getSelectedNatureCode(): void {
    const selectedNature = this.formPression.get('nature').value;
    if (selectedNature) {
      this.updateDeclencheurOptions(selectedNature);
    }
  }

  private updateDeclencheurOptions(selectedNature: any): void {
    switch (selectedNature.code) {
      case 'ANALYTIQUE' :
        this.filteredRefDeclencheurs = this.refDeclencheurs.filter(d => d.code === 'DECLARATION' || d.code === 'ECHANTILLON');
        break;
      case 'ORGANOLEPTIQUE':
        this.filteredRefDeclencheurs = this.refDeclencheurs.filter(d => d.code === 'DECLARATION'
          /*|| d.code === 'DECLARANT' || d.code === 'OPERATEUR'*/);
        break;
    }
  }

  /** Crée un écouteur qui charge les produits en fonction des CDCs sélectionnés */
  private createCdcsChangedListener(): void {
    // Ecouter sur la sélection de CDCs
    this.cdcs.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((cahiers: Cahier[]) => {
      this.loadProductByCdcs(cahiers, true);
    });
  }

  /**
   * Charge les produits en fonction d'une collection de cahiers des charges
   * @param cahiers La collection de cahiers des charges dont dépendent les produits
   */
  private loadProductByCdcs(cahiers: Cahier[], reset?: boolean, presets?: Produit[]): void {
    this.refProduits = [];
    this.produits.disable();

    // Réinitialiser la sélection des produits
    if (reset) {
      this.produits.reset();
    }

    // Récupérer les IDs des CDCs sélectionnés
    const idsCdcs: number[] = cahiers.map(cahier => cahier.id);
    if (idsCdcs && idsCdcs.length > 0) {
      // Récupérer les produits liés aux CDCs sélectionnés
      this.produitsService.getProduitsByCdcs(this.idOrganisme, idsCdcs).subscribe(produits => {
        this.refProduits = produits;
        this.produits.enable();

        // Pré-sélectionner les produits
        if (presets && presets.length > 0) {
          this.produits.setValue(this.refProduits.filter(produit =>
            presets.find(_ => _.id === produit.id)
          ));
        }
      });
    }
  }

  /**
   * Retourne la valeur par défaut d'une collection de radio boutons
   * @param type Le type pour lequel chercher la valeur par défaut
   * @returns Un entier sous forme de chaîne de caractère (id)
   */
  private findDefaultChecboxValue(type: 'TYPE_PRESSION' | 'DECLENCHEUR' | 'TYPE_MOUVEMENT'): string {
    let defaultValue = '0';

    switch (type) {
      case 'TYPE_PRESSION':
        defaultValue = this.codeRefOrganisme === 'ODG' ? 'INTERNE' : 'EXTERNE';
        break;
      case 'DECLENCHEUR':
        const declarationDeclencheur = this.filteredRefDeclencheurs.find(declencheur => declencheur.code === 'DECLARATION');
        defaultValue = (declarationDeclencheur) ? declarationDeclencheur.id.toString() : defaultValue;
        break;
      case 'TYPE_MOUVEMENT':
        const revendicationMouvement = this.refMouvements.find(mouvement => mouvement.code === 'REVENDICATION');
        defaultValue = (revendicationMouvement) ? revendicationMouvement.id.toString() : defaultValue;
        break;
    }

    return defaultValue;
  }

  /** Récupère les valeurs entrées par l'utilisateur et envoie les données au serveur */
  public onSubmit(): void {
    // Vérifier que la pression saisie est valide
    if (this.formPression.valid) {
      // Récupérer les campagnes de la pression
      const campagnes: Campagne[] = [];
      this.annees.controls.forEach(campagne => {
        const refCampagne = this.refAnnees.find(refAnnee => refAnnee.annee === campagne.get('annee').value);
        if (refCampagne) {
          campagnes.push(refCampagne);
        }
      });

      // Instancier la pression
      const pression = Object.assign(new PressionControle(), {
        estInterne: (this.type.value === 'INTERNE'),
        libelle: this.libelle.value,
        nature: this.nature.value,
        objet: this.objet.value,
        cdcs: this.cdcs.value,
        codesProduit: (this.produits.value) ? this.produits.value.map(produit => produit.code) : [],
        activites: this.activites.value,
        declencheur: this.filteredRefDeclencheurs.find(declencheur => declencheur.id === +this.declencheur.value),
        typesMouvement: this.typesDeclaration.value,
        pression: this.pression.value,
        estAnneeCivile: (this.baseCampagne.value === 'ANNEE_CIVILE'),
        campagnes
      });

      // Créer ou modifier la pression côté serveur
      if (this.idPression) {
        pression.id = this.idPression;
        this.pressionService.putPressionControle(pression).subscribe(() => this.onClose());
      } else {
        this.pressionService.postPressionControle(pression).subscribe(idPression => this.onClose());
      }
    }
  }

  /** Redirige vers la page d'accueil des contrôles */
  public onClose(): void {
    this.router.navigate(['.'], {
      relativeTo: this.route.parent
    });
  }

  /**
   * Ajoute une année dans le formulaire
   * @param annee L'annnée à ajouter
   */
  public onAddYear(annee: number | undefined): void {
    this.annees.push(this.fb.group({
      annee: [annee, [
        Validators.required,
        Validators.pattern(/^\d{4}/),
        this.yearIsCampaignValidator.bind(this)
      ]
      ]
    }));
  }

  /**
   * Retire une année du formulaire
   * @param index L'indice de l'année à retirer
   */
  public onDeleteYear(index: number): void {
    this.annees.removeAt(index);
  }

  /**
   * Retourne toutes les AbstractControl des années
   * @returns Une collection de AbstractControl
   * @see AbstractControl
   */
  public getYears(): AbstractControl[] {
    return this.annees.controls;
  }

  /**
   * Vérifie qu'une année saisie par l'utilisateur est gérée dans la liste
   * des campagnes du référentiel
   * @param control Le contrôle du formulaire à vérifier
   */
  private yearIsCampaignValidator(control: FormControl) {
    return (this.refAnnees.find(campagne => campagne.annee === control.value)) ?
      null : {invalid: true};
  }
}
