import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditGroupeVarietalComponent } from './edit-groupe-varietal/edit-groupe-varietal.component';
import { ListeGroupesVarietauxComponent } from './liste-groupes-varietaux/liste-groupes-varietaux.component';

@Component({
  selector: 'app-groupes-varietaux',
  templateUrl: './groupes-varietaux.component.html',
  styleUrls: ['./groupes-varietaux.component.scss']
})
export class GroupesVarietauxComponent implements OnInit {

  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"]
    };
  
    static routes: Routes = [
      { path: '', component: ListeGroupesVarietauxComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id', component: EditGroupeVarietalComponent, data: { roles: ['ORGANISME'] } },
      { path: 'new', component: EditGroupeVarietalComponent, data: { roles: ['ORGANISME'] } }
    ];

  constructor() { }

  ngOnInit() {
  }

}
