import { JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";
import { CodeEspece } from "../../declaration/models/enums/code-espece.enum";

@JsonObject
export class EstimationStock {
  @JsonProperty('espece', String)
  @Optional
  public espece: string;

  @JsonProperty('idGroupeVarietal', Number)
  public idGroupeVarietal: number;

  @JsonProperty('groupeVarietal', String)
  public groupeVarietal: string;  

  @JsonProperty('stock', Number)
  @Optional
  public stock: number;

  sorting(): string {
    var x =
        ''.concat(
            this.espece === CodeEspece.POMME ? '0' : (this.espece === CodeEspece.POIRE ? '1' : '2'),
            this.groupeVarietal.toUpperCase());
    return x;
}
}
