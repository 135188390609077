<form [formGroup]="formRecours" #frmRecours="ngForm" novalidate>
  <div class="cols">
    <div class="col">
      <div class="form-group">
        <label for="dateRecours" translate>page.controles.manquement.recours.form.dateRecours</label>
        <div class="input-group">
          <input ngbDatepicker #dateRecours="ngbDatepicker" formControlName="dateRecours" class="form-control"
            [ngClass]="{ 'is-invalid': formRecours.get('dateRecours').invalid }" />
          <div class="input-group-append">
            <button type="button" (click)="dateRecours.toggle()" class="btn btn-secondary">
              <fa-icon icon="calendar-alt"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="form-group" [hidden]="controle?.nature?.code !== 'ORGANOLEPTIQUE'">
        <label for="commissionRecours" class="form-check-label"
          translate>page.controles.manquement.recours.form.nouvelleExpertise</label>
        <select id="commissionRecours" formControlName="commissionRecours" class="form-control"
          [ngClass]="{ 'is-invalid': formRecours.get('commissionRecours').invalid }">
          <option [ngValue]=" null" selected hidden translate>page.controles.manquement.recours.form.nouvelleExpertise
          </option>
          <option *ngFor="let commissionRecours of commissionsRecours" [ngValue]="commissionRecours.id">Commission
            {{ commissionRecours.numero}} : {{commissionRecours.dateDebut | date:'dd/MM/yyyy'}}</option>
        </select>
      </div>
      <div class="form-group" [hidden]="controle?.nature?.code === 'ORGANOLEPTIQUE'">
        <label for="dateNouvelleExpertise" translate>page.controles.manquement.recours.form.nouvelleExpertise</label>
        <div class="input-group">
          <input ngbDatepicker #dateNouvelleExpertise="ngbDatepicker" formControlName="dateNouvelleExpertise"
            class="form-control" />
          <div class="input-group-append">
            <button type="button" (click)="dateNouvelleExpertise.toggle()" class="btn btn-secondary">
              <fa-icon icon="calendar-alt"></fa-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="form-group" [hidden]="controle?.nature?.code !== 'ORGANOLEPTIQUE'">
        <div class="form-check">
          <input type="checkbox" id="nouveauPrelevement" formControlName="nouveauPrelevement"
            class="form-check-input" />
          <label for="nouveauPrelevement" class="form-check-label"
            translate>page.controles.manquement.recours.form.nouveauPrelevement</label>
        </div>
      </div>
    </div>
  </div>
</form>