<div class="anpp">
  <div class="content">
    <div class="content-panel" *ngIf="groupe">
      <div class="title-bar">
        <h4>
          {{ title | translate }}
        </h4>
      </div>

      <form *ngIf="formGroup" [formGroup]="formGroup" #form="ngForm" (submit)="submit()" (keydown.enter)="$event.preventDefault()" novalidate>

        <div class="actions-bar" fxLayoutAlign="space-between">
          <button class="btn btn-primary" *ngIf="!creation" [routerLink]="'../..'" translate>label.retour</button>
          <button class="btn btn-primary" *ngIf="creation" [routerLink]="'../'" translate>label.retour</button>

          <button type="submit" class="btn btn-primary" translate>{{!creation
            ? 'label.suivant': 'label.valider'}}</button>
        </div>
        <div class="m-3">
          <div class="row">

            <div class="col-lg-4 form-group">
              <label for="code" translate>page.valeur_parametrable.form.label.code</label>

              <input type="text" id="code" formControlName="code"
                [ngClass]="{'is-invalid': form.submitted && code.invalid}" class="form-control" />

              <ng-container *ngIf="form.submitted">
                <div *ngIf="code.errors?.required" class="form-error" translate>label.obligatoire</div>
              </ng-container>
            </div>
            <div class="col-lg-8 form-group">
              <label for="libelle" translate>page.valeur_parametrable.form.label.libelle</label>

              <input type="text" id="libelle" formControlName="libelle"
                [ngClass]="{'is-invalid': form.submitted && libelle.invalid}" class="form-control" />
              <ng-container *ngIf="form.submitted">
                <div *ngIf="libelle.errors?.required" class="form-error" translate>label.obligatoire</div>
              </ng-container>
            </div>
          </div>
          <div *ngIf="!creation">
            <div class="row ">
              <div class="col col-lg-6" cdkDropList id="contacts-groupe" [cdkDropListConnectedTo]=""
                [cdkDropListEnterPredicate]="canDropCon" (cdkDropListDropped)="moveCon($event)"
                [cdkDropListData]="contact_filter.value">
                <h5><label for="contact" ><span translate>page.valeur_parametrable.form.label.contacts_groupe</span><span *ngIf="contact_groupe"> : {{contact_groupe.length}}</span></label></h5>
                <div class="tableScroll">
                <table class="table table-striped">
                  <thead class="sticky-top">
                    <th></th>
                    <th scope="col" style="position:relative">
                      <div translate>page.valeur_parametrable.table.nom </div>
                      <div>
                        <input type="text" [id]="nom" class="form-control bar1" [(ngModel)]="nomFilter_groupe"
                          [ngModelOptions]="{standalone: true}" (input)="filter_groupe()">
                      </div>
                    </th>
                    <th scope="col" style="position:relative">
                      <div translate>page.valeur_parametrable.table.raison_sociale </div>
                      <div>
                        <input type="text" [id]="raisonsociale" class="form-control bar1"
                          [ngModelOptions]="{standalone: true}" [(ngModel)]="raisonsocialeFilter_groupe"
                          (input)="filter_groupe()">
                      </div>
                    </th>
                    <th scope="col" style="position:relative">
                      <div translate>page.valeur_parametrable.table.fonction </div>
                      <div>
                        <select id="fonction" class="custom-select bar1" [(ngModel)]="fonctionFilter_groupe"
                          [ngModelOptions]="{standalone: true}" (change)="filter_groupe()">
                          <option></option>
                          <option *ngFor="let fonction of fonctions" [ngValue]="fonction">{{fonction.libelle}}
                          </option>
                        </select>
                      </div>
                    </th>
                    <th scope="col" style="position:relative">
                      <div translate>page.valeur_parametrable.table.refsoustype</div>
                      <div>
                        <select id="refsoustype" class="custom-select bar1" [(ngModel)]="refsoustypeFilter_groupe"
                          [ngModelOptions]="{standalone: true}" (change)="filter_groupe()">
                          <option></option>
                          <option *ngFor="let refsoustype of refSousTypes" [ngValue]="refsoustype">
                            {{refsoustype.libelle}}
                          </option>
                        </select>
                      </div>
                    </th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let con of contact_filter.value" cdkDrag class="jury-liste-item" [cdkDragData]="con">
                      <td>
                        <div class="listes-dad-placeholder" *cdkDragPlaceholder></div>
                        <fa-icon icon="trash" (click)="removeCon(index, con)" class="pointer jury-liste-delete">
                        </fa-icon>
                      </td>
                      <td>{{ con.nom }} {{ con.prenom }}</td>
                      <td>{{ con.raisonsociale }}</td>
                      <td>{{ con.fonction?.libelle }}</td>
                      <td>{{ con.refSousType?.libelle }}</td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </div>

              <div class="col col-lg-6" cdkDropList id="contacts-libre" [cdkDropListConnectedTo]=""
                [cdkDropListEnterPredicate]="canDropCon" (cdkDropListDropped)="moveConLibre($event)"
                [cdkDropListData]="contacts_libres_filter.value">
                <h5><label for="contact" translate>page.valeur_parametrable.form.label.contact_libre</label></h5>
                <div class="tableScroll">
                <table class="table table-striped">
                  <thead class="sticky-top">
                    <th></th>
                    <th scope="col" style="position:relative">
                      <div translate>page.valeur_parametrable.table.nom </div>
                      <div>
                        <input type="text" [id]="nom" class="form-control bar1" [(ngModel)]="nomFilter"
                          [ngModelOptions]="{standalone: true}" (input)="filter()">
                      </div>
                    </th>
                    <th scope="col" style="position:relative">
                      <div translate>page.valeur_parametrable.table.raison_sociale </div>
                      <div>
                        <input type="text" [id]="raisonsociale" class="form-control bar1"
                          [ngModelOptions]="{standalone: true}" [(ngModel)]="raisonsocialeFilter" (input)="filter()">
                      </div>
                    </th>
                    <th scope="col" style="position:relative">
                      <div translate>page.valeur_parametrable.table.fonction </div>
                      <div>
                        <select id="fonction" class="custom-select bar1" [(ngModel)]="fonctionFilter"
                          [ngModelOptions]="{standalone: true}" (change)="filter()">
                          <option></option>
                          <option *ngFor="let fonction of fonctions" [ngValue]="fonction">{{fonction.libelle}}
                          </option>
                        </select>
                      </div>
                    </th>
                    <th scope="col" style="position:relative">
                      <div translate>page.valeur_parametrable.table.refsoustype</div>
                      <div>
                        <select id="refsoustype" class="custom-select bar1" [(ngModel)]="refsoustypeFilter"
                          [ngModelOptions]="{standalone: true}" (change)="filter()">
                          <option></option>
                          <option *ngFor="let refsoustype of refSousTypes" [ngValue]="refsoustype">
                            {{refsoustype.libelle}}
                          </option>
                        </select>
                      </div>
                    </th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let con of contacts_libres_filter.value" cdkDrag class="jury-liste-item"
                      [cdkDragData]="con">
                      <td>
                        <div class="listes-dad-placeholder" *cdkDragPlaceholder></div>
                        <fa-icon cdkDragHandle icon="bars" class="grab" style="font-size:0.8em;"></fa-icon>
                      </td>
                      <td>{{ con.nom }} {{ con.prenom }}</td>
                      <td>{{ con.raisonsociale }}</td>
                      <td>{{ con.fonction?.libelle }}</td>
                      <td>{{ con.refSousType?.libelle }}</td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </div>

            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>