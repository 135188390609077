import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class Assemblage {
    @JsonProperty('annee', Number)
    annee: number = undefined;

    @JsonProperty('volume', Number)
    volume: number = undefined;
}
