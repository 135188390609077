<div *ngIf="contact" class="anpp">
  <div class="modal-header">
    <h4 class="modal-title" translate>page.mon-compte.contact.detail.title</h4>
  </div>
  <div class="modal-body">
    <div class="flex">
      <div class="section">
        <div><label><span translate>page.mon-compte.contact.detail.civilite</span><span> : </span></label> {{contact.civilite}}</div>
        <div><label><span translate>page.mon-compte.contact.detail.nom</span><span> : </span></label> {{contact.nom}}</div>
        <div><label><span translate>page.mon-compte.contact.detail.prenom</span><span> : </span></label> {{contact.prenom}}</div>
        <div><label><span translate>page.mon-compte.contact.detail.entreprise</span><span> : </span></label> {{contact.entreprise.raisonSociale}}</div>
        <div><label><span translate>page.mon-compte.contact.detail.referents</span><span> : </span></label> 
          <div *ngFor="let ref of contact.referents">
            <span class="badge badge-secondary badge-green" [ngbTooltip]="ref.libelle"> {{ref.code}} </span>
          </div>
        </div>
        <div><label><span translate>page.mon-compte.contact.detail.fonction</span><span> : </span></label> {{contact.fonction ? contact.fonction.libelle : ''}}</div>
      </div>
      <div class="section">
        <div><label><span translate>page.mon-compte.contact.detail.telephoneFixe</span><span> : </span></label> {{contact.telephonefixe}}</div>
        <div><label><span translate>page.mon-compte.contact.detail.telephoneMobile</span><span> : </span></label> {{contact.telephoneportable}}</div>
        <div><label><span translate>page.mon-compte.contact.detail.email</span><span> : </span></label> {{contact.mail}}</div>
        <div><label><span translate>page.mon-compte.contact.detail.adresse</span><span> : </span></label> {{contact.adresse}}</div>
        <div><label><span translate>page.mon-compte.contact.detail.codePostal</span><span> : </span></label> {{contact.codepostal}}</div>
        <div><label><span translate>page.mon-compte.contact.detail.ville</span><span> : </span></label> {{contact.commune}}</div>
      </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="cancel()" translate>label.fermer</button>
  </div>
</div>