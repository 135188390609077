import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { Salle } from '../../models/salle.model';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';
import { OrganismesService } from 'src/app/data/intervenant/services/organismes/organismes.service';

@Injectable({
  providedIn: 'root'
})
export class SallesService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly refService: ReferencesService,
    private readonly organismesService: OrganismesService
  ) { }

  getSalles(idOrganisme: number): Observable<Salle[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/declaration/private/organismes/${idOrganisme}/salles`),
      this.refService.getReferences(),
      this.organismesService.getOrganismes()
    ).pipe(
      map(([salles, ref, organismes]) => this.mapper.deserializeArray(salles, Salle, Object.assign({ organismes }, { pays: ref.pays }))),
    );
  }
}
