<div class="content">
  <div class="content-panel">
    <h2 translate>page.controles.echantillons.title</h2>

    <form [formGroup]="formSearchEchantillons" #form="ngForm" (ngSubmit)="onSubmit()">
     
      <div class="form-row">
        <div class="form-group col-md-5 row">
          <label for="statut" class="col-md-7 col-form-label"
            translate>page.controles.echantillons.search.statut</label>
          <div class="col-md-5">
            <select id="statut" formControlName="statut" class="form-control">
              <option [ngValue]="null" selected>{{ 'page.controles.echantillons.search.tous' | translate }}</option>
              <option *ngFor="let statut of statuts" [ngValue]="statut.code">{{ statut.libelle }}</option>
            </select>
          </div>
        </div>
        <div class="form-group col-md-4 row">
          <label for="agentPrevu" class="col-md-4 col-form-label"
            translate>page.controles.echantillons.search.agentPrevu</label>
          <div class="col-md-6">
            <select id="agentPrevu" formControlName="agentPrevu" class="form-control">
              <option [ngValue]="null" selected>{{ 'page.controles.echantillons.search.tous' | translate }}</option>
              <option *ngFor="let animateur of animateurs" [ngValue]="animateur.id">{{ animateur.prenom }}
                {{ animateur.nom }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-5 row">
          <label for="debutDateButoir" class="col-md-7 col-form-label"
            translate>page.controles.echantillons.search.dateButoirEntre</label>
          <div class="col-md-5 input-group">
            <input id="debutDateButoir" ngbDatepicker #debutDatepickerButoir="ngbDatepicker"
              formControlName="debutDateButoir" class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="debutDatepickerButoir.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group col-md-4 row">
          <label for="finDateButoir" class="col-md-4 col-form-label"
            translate>page.controles.echantillons.search.et</label>
          <div class="col-md-6 input-group">
            <input id="finDateButoir" ngbDatepicker #finDatepickerButoir="ngbDatepicker" formControlName="finDateButoir"
              class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="finDatepickerButoir.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-5 row">
          <label for="debutDatePrelevement" class="col-md-7 col-form-label"
            translate>page.controles.echantillons.search.datePrelevementEntre</label>
          <div class="col-md-5 input-group">
            <input id="debutDatePrelevement" ngbDatepicker #debutDatepickerPrelevement="ngbDatepicker"
              formControlName="debutDatePrelevement" class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="debutDatepickerPrelevement.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group col-md-4 row">
          <label for="finDatePrelevement" class="col-md-4 col-form-label"
            translate>page.controles.echantillons.search.et</label>
          <div class="col-md-6 input-group">
            <input id="finDatePrelevement" ngbDatepicker #finDatepickerPrelevement="ngbDatepicker"
              formControlName="finDatePrelevement" class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="finDatepickerPrelevement.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-5 row">
          <label for="debutDateCommission" class="col-md-7 col-form-label"
            translate>page.controles.echantillons.search.dateCommissionEntre</label>
          <div class="col-md-5 input-group">
            <input id="debutDateCommission" ngbDatepicker #debutDatepickerCommission="ngbDatepicker"
              formControlName="debutDateCommission" class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="debutDatepickerCommission.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group col-md-4 row">
          <label for="finDateCommission" class="col-md-4 col-form-label"
            translate>page.controles.echantillons.search.et</label>
          <div class="col-md-6 input-group">
            <input id="finDateCommission" ngbDatepicker #finDatepickerCommission="ngbDatepicker"
              formControlName="finDateCommission" class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="finDatepickerCommission.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-2 row">
          <button type="button" class="btn btn-primary rechercher" (click)="etiquettesPdf()" >Impression des étiquettes</button>
        </div>
        <div class="form-group col-md-8 row">
          <button type="submit" class="btn btn-primary rechercher">Rechercher</button>
        </div>
      </div>
    </form>
    <table class="table" *ngIf="echantillons?.length > 0; else aucunEchantillon">
      <thead>
        <th translate>page.controles.echantillons.table.cvi</th>
        <th translate>page.controles.echantillons.table.raisonSociale</th>
        <th translate>page.controles.echantillons.table.produit</th>
        <th translate>page.controles.echantillons.table.siteStockage</th>
        <th translate>page.controles.echantillons.table.statut</th>
        <th translate>page.controles.echantillons.table.numeroDossier</th>
        <th translate>page.controles.echantillons.table.dateButoir</th>
        <th translate>page.controles.echantillons.table.agent</th>
        <th translate>page.controles.echantillons.table.datePrelevement</th>
        <th translate>page.controles.echantillons.table.dateCommission</th>
        <th translate>page.controles.echantillons.table.numeroCommission</th>
        <th></th>
      </thead>

      <tbody>
        <tr *ngFor="let echantillon of echantillons">
          <td>{{ echantillon.cvi }}</td>
          <td>{{ echantillon.operateur?.raisonSociale }}</td>
          <td>{{ echantillon.libelleProduit }}</td>
          <td>{{ echantillon.adresseSite }}</td>
          <td>{{ echantillon.statut?.libelle }}</td>
          <td>{{ echantillon.numeroDossier }}</td>
          <td [ngClass]="{ 'dateButoirDepassee': echantillon.estPerime() && echantillon.statut?.code==='A_PRELEVER'}">
            {{ echantillon.dateButoir | date:'dd/MM/yyyy' }}</td>
          <td>{{ echantillon.animateur?.prenom }} {{ echantillon.animateur?.nom }}</td>
          <td>{{ echantillon.datePrelevement | date:'dd/MM/yyyy' }}</td>
          <td>{{ echantillon.dateCommission | date:'dd/MM/yyyy' }}</td>
          <td>{{ echantillon.numeroCommission }}</td>
          <td>
            <span>
              <button type="button" class="btn btn-outline-secondary btn-add"
                *ngIf="echantillon.codeStatut === 'A_PRELEVER'" (click)="onAddPrelevement(echantillon)">
                <fa-icon icon="plus" class="primary-icon" title="Saisir un prélèvement"></fa-icon>
              </button>
              <button style="margin-left: 1em;" type="button" class="btn btn-outline-secondary btn-add"
                (click)="telechargerDocument(echantillon)">
                <fa-icon icon="download" class="primary-icon" title="Télécharger le document"></fa-icon>
              </button>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <app-pagination-bar [pagination]="pagination"></app-pagination-bar>
  </div>
</div>

<ng-template #aucunEchantillon>
  <div class="alert alert-warning alert-block" translate>
    <fa-icon icon="exclamation"></fa-icon>
    page.controles.echantillons.search.aucunEchantillon
  </div>
</ng-template>