import { UtilisateurTypeCode } from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';

// intervenant

interface IIntervenant {
  utilisateurType: UtilisateurTypeCode;
  roles: string[];
  idIntervenant: number;
  idPortail: number;
  idOrganisme?: number;
  idRefOrganisme?: string;
  idDomaine?: number;
  codeDomaine?: string;
  mailIntervenant?: string;
  codePortail?: string;
}

const defaultIntervenant: IIntervenant = {
  roles: [],
  utilisateurType: UtilisateurTypeCode.NONE,
  idIntervenant: null,
  idPortail: null
};

export class SessionContext {
  static intervenant: IIntervenant = defaultIntervenant;

  static has<K extends keyof IIntervenant>(key: K): boolean {
    return SessionContext.intervenant[key] !== undefined;
  }

  static get<K extends keyof IIntervenant>(key: K): IIntervenant[K] {
    return SessionContext.intervenant[key];
  }

  static set(intervenant: IIntervenant) {
    return SessionContext.intervenant = Object.assign({}, intervenant);
  }

  static clear() {
    SessionContext.intervenant = defaultIntervenant;
  }
}

// navigation

interface IContext {
  idOperateur?: number;
}


const defaultContext: IContext = {};


export class NavigationContext {
  static context: IContext = defaultContext;

  static has<K extends keyof IContext>(key: K): boolean {
    return NavigationContext.context[key] !== undefined;
  }

  static get<K extends keyof IContext>(key: K): IContext[K] {
    return NavigationContext.context[key];
  }

  static set<K extends keyof IContext>(key: K, value: IContext[K]) {
    return NavigationContext.context[key] = value;
  }

  static setValues(values: Partial<IContext>) {
    for (const key in values) {
      NavigationContext.context[key] = values[key];
    }
  }

  static clear() {
    NavigationContext.context = defaultContext;
  }
}

// general context

export class Context {
  static clear() {
    SessionContext.clear();
    NavigationContext.clear();
  }
}
