import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class SyntheseInventaireUniteEdition {

@JsonProperty('referenceCadastrale', String)
  @Optional
  public referenceCadastrale: string = undefined;

  @JsonProperty('commune', String)
  @Optional
  public commune: string = undefined;

  @JsonProperty('lieuDit', String)
  @Optional
  public lieuDit: string = undefined;


  @JsonProperty('numBlocFruitier', String)
  @Optional
  public numBlocFruitier: string = undefined;

  @JsonProperty('numTracabilite', String)
  @Optional
  public numTracabilite: string = undefined;

  @JsonProperty('codeParcelle', String)
  @Optional
  public codeParcelle: string = undefined;

  @JsonProperty('variete', String)
  @Optional
  public variete: string = undefined;

  @JsonProperty('clone', String)
  @Optional
  public clone: string = undefined;

  @JsonProperty('anneePlantation', Number)
  @Optional
  anneePlantation: number;

  @JsonProperty('anneePremiereFeuille', Number)
  @Optional
  anneePremiereFeuille: number;

  @JsonProperty('anneeSurgreffage', Number)
  @Optional
  anneeSurgreffage: number;

  @JsonProperty('distanceIntraRang', Number)
  @Optional
  distanceIntraRang: number;

  @JsonProperty('distanceInterRang', Number)
  @Optional
  distanceInterRang: number;

  @JsonProperty('nbArbre', Number)
  @Optional
  nbArbre: number;

  @JsonProperty('surfaceCalculee', Number)
  @Optional
  surfaceCalculee: number;

  @JsonProperty('porteGreffe', String)
  @Optional
  public porteGreffe: string = undefined;

  @JsonProperty('refSystemeIrrigation', Boolean)
  @Optional
  public refSystemeIrrigation: boolean = undefined;

  @JsonProperty('refProtectionGel', Boolean)
  @Optional
  public refProtectionGel: boolean = undefined;

  @JsonProperty('refProtectionGrele', Boolean)
  @Optional
  public refProtectionGrele: boolean = undefined;

  @JsonProperty('refCultureBio', Boolean)
  @Optional
  public refCultureBio: boolean = undefined;

  @JsonProperty('forme', String)
  @Optional
  public forme: string = undefined;
}
