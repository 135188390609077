import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-choix-colonnes',
  templateUrl: './choix-colonnes.component.html',
  styleUrls: ['./choix-colonnes.component.scss']
})
export class ChoixColonnesComponent implements OnInit {

@Input() public headers ;

headerselect : [];

formGroup : FormGroup;
isAnpp: boolean;
get multis() { return this.formGroup.get('multis'); }

  constructor(
    public readonly fb: FormBuilder,
    public readonly modal: NgbActiveModal,
    public readonly authService: AuthService
    ) { }

  ngOnInit() {
    this.isAnpp = this.authService.isAnpp();
    
    if(this.headers)
    this.headerselect = this.headers.filter(header =>  header['checked'] );
   
    this.formGroup = this.fb.group({
      multis: [ this.headerselect]
    });

  }

  onSubmit(){

  this.headers.forEach(h =>{
      h['checked'] = (this.formGroup.value.multis.find(head=> head['name'] === h['name'] ) !== undefined)?true:false;
    });  
    
  this.modal.close( this.headers );

  }

  onClose(){
    this.modal.dismiss();
  }

}