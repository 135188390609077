import { Component, OnInit, Input } from '@angular/core';

interface Data {
  value: number;
  color: string;
  libelle: string;
}

interface Item {
  css: Css;
  libelle: string;
  display: boolean;
}

interface Css { [name: string]: string | number; }

@Component({
  selector: 'app-tank',
  templateUrl: './tank.component.html',
  styleUrls: ['./tank.component.scss']
})
export class TankComponent implements OnInit {
  list: Item[];

  @Input()
  set data(val: Data[]) {
    if (!Array.isArray(val)) {
      val = [val];
    }

    this.list = [];
    if (val) {
      let somme = 0;
      val.forEach(i => {
        this.list.push({
          css: this.getCssString(i, somme),
          libelle: i.libelle,
          display: i.value > 0
        });

        somme += i.value;
      });
    }
  }

  constructor() { }

  ngOnInit() {
  }

  getDataItem(index: number) {
    const item = this.data[index];
    if (item) {
      return Object.assign({}, item, { offset: this.data.slice(0, index).reduce((t, i) => t + i.value, 0) });
    }
  }

  getHeight(value: number) {
    return Math.min(100, Math.max(0, value)) / 100;
  }

  getRbga(hexString: string) {
    let hex = '00a0a0'.split('');
    try {
      hex = hexString.replace('#', '').split('');
    } catch (e) { }

    const r = parseInt(hex.length === 6 ? hex[0] + hex[1] : hex[0] + hex[0], 16);
    const g = parseInt(hex.length === 6 ? hex[2] + hex[3] : hex[1] + hex[1], 16);
    const b = parseInt(hex.length === 6 ? hex[4] + hex[5] : hex[2] + hex[2], 16);

    return `rgba(${r},${g},${b},0.7)`;
  }

  getCssString(data: Data, offset: number = 0): Css {
    if (data.value > 0) {
      const height = this.getHeight(data.value) * 0.83 + 0.17;
      const offsetHeight = Math.max(this.getHeight(offset) * 0.83 - 0.009, 0);
      return { height: `${100 * height}%`, bottom: `${100 * offsetHeight}%`, 'background-color': this.getRbga(data.color) };
    } else {
      return { display: 'none;' };
    }
  }

}
