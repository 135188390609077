import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { some, difference } from 'lodash';

import { UpdateHabilitationComponent } from './modals/update-habilitation/update-habilitation.component';
import { Cahier } from 'src/app/data/habilitation/models/cahier.model';
import { Habilitation } from 'src/app/data/habilitation/models/habilitation.model';
import { Activite } from 'src/app/data/habilitation/models/activite.model';
import { ActivitesService } from 'src/app/data/habilitation/services/activites/activites.service';
import { CahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import { HabilitationsService } from 'src/app/data/habilitation/services/habilitations/habilitations.service';
import { HabilitationsComponent } from '../../../habilitations/habilitations.component';
import { NavigationContext, SessionContext } from 'src/app/core/services/config/app.settings';

@Component({
  selector: 'app-operateur-habilitations',
  templateUrl: './operateur-habilitations.component.html',
  styleUrls: ['../../../habilitations/habilitations.component.scss']
})
export class OperateurHabilitationsComponent extends HabilitationsComponent {
  static routes = [
    {
      path: '',
      component: OperateurHabilitationsComponent,
      data: {
        role: 'HABILITATION_HABILITATION_LECTURE || HABILITATION_HABILITATION_ECRITURE',
        menu: {
          icon: 'user-check',
          libelle: 'menu.habilitation'
        }
      }
    }
  ];

  private get idOperateur(): number {
    if (NavigationContext.has('idOperateur')) {
      return NavigationContext.get('idOperateur');
    } else {
      return SessionContext.get('idIntervenant');
    }
  }

  constructor(
    readonly activitesService: ActivitesService,
    readonly cahiersService: CahiersService,
    readonly habilitationsService: HabilitationsService,
    readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalService: NgbModal
  ) {
    super(activitesService, cahiersService, habilitationsService, loaderService);
  }

  getHabilitations() {
    return this.habilitationsService.getOperateurHabilitations(this.idOperateur);
  }

  getUnusedCahiers() {
    return difference(this.cahiers, this.cahiersFiltered);
  }

  addCahier(cahier: Cahier) {
    this.cahiersFiltered.push(cahier);
  }

  cahierHasActivite(cahier: Cahier, activite: Activite) {
    return some(cahier.activites, (a: Activite) => a.id === activite.id);
  }

  createHabilitation(cahier: Cahier, activite: Activite) {
    const habilitation: Habilitation = new Habilitation();
    habilitation.cahier = cahier;
    habilitation.activite = activite;

    this.updateHabilitation(habilitation);
  }

  updateHabilitation(habilitation: Habilitation) {
    const modalRef = this.modalService.open(UpdateHabilitationComponent, { backdrop: 'static' });
    modalRef.componentInstance.habilitation = habilitation;
    modalRef.componentInstance.idOperateur = this.idOperateur;

    modalRef.result.then(() => {
      this.loadHabilitations();
    }, () => { });
  }
}
