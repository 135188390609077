import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { UtilisateurTypeCode } from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';
import { AnppProducteurComponent } from './pages/anpp-producteur/anpp-producteur.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  static routes: Routes = [
    {
      path: '',
      data: {
        role: 'ORGANISME || PI || PO || GP',
        domaines: ['VIN', 'ANPP'],
        menu: {
          icon: 'tachometer-alt',
          libelle: 'menu.home'
        }
      },
      component: HomeComponent
    },
    {
      path: ':idEntreprise', component: AnppProducteurComponent, children: [
        { path: '', component: AnppProducteurComponent }
      ],
    }
  ];

  isOrganismeAnpp: boolean = false;
  isProducteurAnpp: boolean = false;
  constructor() { }

  ngOnInit() {
    if (SessionContext.get('codeDomaine') === 'ANPP') {
      if (SessionContext.get('utilisateurType') === UtilisateurTypeCode.ORGANISME) {
        this.isOrganismeAnpp = true;
      } else if (SessionContext.get('utilisateurType') === UtilisateurTypeCode.PI ||
      SessionContext.get('utilisateurType') === UtilisateurTypeCode.PO ||
      SessionContext.get('utilisateurType') === UtilisateurTypeCode.GP ||
      SessionContext.get('utilisateurType') === UtilisateurTypeCode.CT ||
      SessionContext.get('utilisateurType') === UtilisateurTypeCode.OC)
        this.isProducteurAnpp = true;
    }
  }
}
