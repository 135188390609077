<div class="content">
  <div class="content-panel">
    <form *ngIf="formGroup" [formGroup]="formGroup" #form="ngForm" (submit)="submit()" novalidate t-id="habilitation-update-form">
      <!-- Formulaire sur le type -->
      <h5>Type d'organisme :</h5>
      <div class="flex-break"></div>
      <ng-container *ngTemplateOutlet="fieldSelect;context:{form:form,formControl:type,placeholder:'type',items:typesOrganismes,required:true}"></ng-container>
      <div class="flex-break"></div>
      <!-- Fin formulaire sur le type -->

      <!-- Formulaire identité -->
      <h5>Identité :</h5>
      <div class="flex-break"></div>
      <ng-container *ngTemplateOutlet="fieldUpload;context:{form:form,formControl:logo,placeholder:'logo',items:logo}"></ng-container>
      <div class="flex-break"></div>
      <ng-container *ngTemplateOutlet="fieldSelect;context:{form:form,formControl:structure,placeholder:'structure',items:structures}"></ng-container>
      <ng-container *ngTemplateOutlet="fieldText;context:{form:form,formControl:raisonSociale,placeholder:'raisonSociale',required:true}"></ng-container>
      <ng-container *ngTemplateOutlet="fieldText;context:{form:form,formControl:siret,placeholder:'siret'}"></ng-container>
      <div class="flex-break"></div>
      <ng-container *ngTemplateOutlet="fieldText;context:{form:form,formControl:adresse,placeholder:'adresse',isArea:true}"></ng-container>
      <ng-container *ngTemplateOutlet="fieldText;context:{form:form,formControl:codePostal,placeholder:'codePostal'}"></ng-container>
      <ng-container *ngTemplateOutlet="fieldText;context:{form:form,formControl:ville,placeholder:'ville'}"></ng-container>
      <ng-container *ngTemplateOutlet="fieldSelect;context:{form:form,formControl:pays,items:listePays,placeholder:'pays'}"></ng-container>
      <ng-container *ngTemplateOutlet="fieldText;context:{form:form,formControl:cedex,placeholder:'cedex'}"></ng-container>
      <div class="flex-break"></div>
      <ng-container *ngTemplateOutlet="fieldText;context:{form:form,formControl:telephone,placeholder:'telephone'}"></ng-container>
      <ng-container *ngTemplateOutlet="fieldText;context:{form:form,formControl:portable,placeholder:'portable'}"></ng-container>
      <ng-container *ngTemplateOutlet="fieldText;context:{form:form,formControl:fax,placeholder:'fax'}"></ng-container>
      <div class="flex-break"></div>
      <ng-container *ngTemplateOutlet="fieldText;context:{form:form,formControl:mail,placeholder:'mail'}"></ng-container>
      <div class="flex-break"></div>
      <!-- Fin formulaire identité -->

      <!-- Bouton d'enregistrement -->
      <div id="button-next-cnt">
        <button *hasRole="'INTERVENANT_ORGANISME_ABONNEMENT_LECTURE || INTERVENANT_ORGANISME_ABONNEMENT_ECRITURE';elseDisable"
          type="submit" class="btn btn-primary" translate>label.suivant
        </button>
      </div>
      <!-- Fin bouton d'enregistrement -->
    </form>
  </div>
</div>


<ng-template #fieldSelect let-form="form" let-formControl="formControl" let-label="label" let-items="items" let-placeholder="placeholder" let-required="required">
  <div class="form-group" *hasRole="'INTERVENANT_ORGANISME_MODIFICATION';elseDisable">
   <label [for]="formControl.name" *ngIf="label" >{{'page.admin.organismes.edit.label.'+label | translate}}</label>

    <select [id]="formControl.name" [formControl]="formControl" [required]="required" [ngClass]="{'is-invalid': form.submitted && formControl.invalid, 'select-placeholder' : (formControl.value === null && !formControl.dirty)}"
      class="form-control">
      <option [ngValue]="null" *ngIf="placeholder" hidden selected>{{'page.admin.organismes.edit.label.'+placeholder | translate}}</option>
      <option *ngFor="let item of items" [ngValue]="item">{{item.libelle}}</option>
    </select>

    <ng-container *ngIf="form.submitted && required">
      <div *ngIf="formControl.errors?.required" class="form-error" translate>label.obligatoire</div>
    </ng-container>
  </div>
</ng-template>

<ng-template #fieldText let-form="form" let-isArea="isArea" let-formControl="formControl" let-label="label" let-placeholder="placeholder" let-required="required">
  <div class="form-group" *hasRole="'INTERVENANT_ORGANISME_MODIFICATION';elseDisable">
   <label [for]="formControl.name" *ngIf="label">{{'page.admin.organismes.edit.label.'+label | translate}}</label>

    <input *ngIf="!isArea" type="text" [id]="formControl.name" [formControl]="formControl" [required]="required" [ngClass]="{'is-invalid': form.submitted && formControl.invalid}"
      class="form-control" placeholder="{{placeholder ? ('page.admin.organismes.edit.label.'+placeholder | translate) : ''}}"/>

    <textarea *ngIf="isArea" [id]="formControl.name" [formControl]="formControl" [required]="required" [ngClass]="{'is-invalid': form.submitted && formControl.invalid}"
      class="form-control" placeholder="{{placeholder ? ('page.admin.organismes.edit.label.'+placeholder | translate) : ''}}"></textarea>

    <ng-container *ngIf="form.submitted && required">
      <div *ngIf="formControl.errors?.required" class="form-error" translate>label.obligatoire</div>
    </ng-container>
  </div>
</ng-template>

<ng-template #fieldUpload let-form="form" let-isArea="isArea" let-formControl="formControl" let-label="label" let-placeholder="placeholder" let-required="required">

  <div class="form-group" (click)="uploadFile.click()">
    <div class="form-group" *hasRole="'INTERVENANT_ORGANISME_MODIFICATION';elseDisable">
      <div class="imagePreview" *ngIf="organisme.logo && organisme.logo !== ''">
        <img [src]="organisme.logo" style="max-width: 100px;max-height: 38px;margin-left: 10px;">
    </div>
      <label [for]="formControl.name" *ngIf="label">{{'page.admin.organismes.edit.label.'+label | translate}}</label>
      <input (change)="onFileSelected($event)" *ngIf="!isArea" type="file" #uploadFile [id]="formControl.name" [ngClass]="{'is-invalid': form.submitted && formControl.invalid}"
      class="form-control-file" placeholder="{{placeholder ? ('page.admin.organismes.edit.label.'+placeholder | translate) : ''}}"/>
    </div>
    <ng-container *ngIf="form.submitted && required">
      <div *ngIf="formControl.errors?.required" class="form-error" translate>label.obligatoire</div>
    </ng-container>
  </div>
</ng-template>
