import {CustomConverter, JsonObject, JsonProperty, Optional} from '@thorolf/json-ts-mapper';
import {DateConverter} from '../../../core/services/mapper/converters';

@JsonObject
export class InfosLastValid {

  @JsonProperty('idEntreprise', Number)
  idEntreprise: number;

  @JsonProperty('dateDerniereMajInventaire', String)
  @CustomConverter(DateConverter)
  dateDerniereMajInventaire: moment.Moment;

  @JsonProperty('idAuteur', Number)
  idAuteur: number;
}
