import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { ListFacturationComponent } from './pages/list-facturation/list-facturation.component';
import { EditFacturationComponent } from './pages/edit-facturation/edit-facturation.component';
import { GenererFacturesComponent } from './pages/generer-factures/generer-factures.component';
import { CanDeactivateGuard } from './pages/edit-facturation/changes-in-progress/can-deactivate-guard.service';

@Component({
  selector: 'app-facturation',
  templateUrl: './facturation.component.html',
  styleUrls: ['./facturation.component.scss']
})
export class FacturationComponent implements OnInit {
  static routes: Routes = [
    {
      path: '',
      data: {
        role: 'ORGANISME',
        domaines: ['VIN'],
        menu: { icon: 'euro-sign', libelle: 'menu.facturation' }
      },
      children: [{
        path: '',
        redirectTo: 'modeles-facturation',
        pathMatch: 'full'
      }, {
        path: 'modeles-facturation',
        component: ListFacturationComponent,
        data: { menu: { libelle: 'Modèles' } }
      },
      {
        path: 'modeles-facturation/:idModeleFacturation/edit', component: EditFacturationComponent, canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'generer-factures',
        component: GenererFacturesComponent,
        data: { menu: { libelle: 'Générer les factures' } }
      }
      ]
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
