import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Localisation} from '../../models/localisation.model';
import {map} from 'rxjs/operators';
import {JsonMapperService} from '../../../../core/services/mapper/mapper.service';

@Injectable({
  providedIn: 'root'
})
export class LocalisationService {

  private url = 'https://geo.api.gouv.fr/departements/';

  constructor(private readonly http: HttpClient,
              private readonly mapper: JsonMapperService,
  ) {
  }

  getLocalisationsEntreprise(idEntreprise: number): Observable<Localisation[]> {
    return this.http.get<Localisation[]>(`/api/declaration/private/entreprises/${idEntreprise}/localisations`);
  }

  creerLocalisationEntreprise(idEntreprise: number, localisation: Localisation): Observable<void> {
    return this.http.post<void>(`/api/declaration/private/entreprises/${idEntreprise}/localisation`, localisation);
  }

  modifierLocalisationEntreprise(idEntreprise: number, idLocalisation: number, localisation: Localisation): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/entreprises/${idEntreprise}/localisations/${idLocalisation}`, localisation);
  }

  supprimerLocalisationEntreprise(idEntreprise: number, idLocalisation: number): Observable<void> {
    return this.http.delete<void>(`/api/declaration/private/entreprises/${idEntreprise}/localisations/${idLocalisation}`);
  }


}
