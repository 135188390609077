
import { CustomConverter, JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { DateConverter } from 'src/app/core/services/mapper/converters';

@JsonObject
export class InteractionAccueil {
  @JsonProperty('auteurMiseAJour', String)
  @Optional
  public auteurMiseAJour: string = undefined;

  @JsonProperty('sujet', String)
  @Optional
  public sujet: string = undefined;

  @JsonProperty('dateInteraction', String)
  @CustomConverter(DateConverter)
  @Optional
  public dateInteraction: moment.Moment ;
}
