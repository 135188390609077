import { JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";
import { CodeEspece } from "./enums/code-espece.enum";

@JsonObject
export class GroupeVarietal {
    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('code', String)
    @Optional
    code: string;
    
    @JsonProperty('libelle', String)
    @Optional
    libelle: string;
    
    @JsonProperty('surfaceTotale', Number)
    @Optional
    surfaceTotale: number;
    
    @JsonProperty('surfaceBio', Number)
    @Optional
    surfaceBio: number;
    
    @JsonProperty('varietes', String)
    @Optional
    varietes: string;

    @JsonProperty('codeEspece', String)
    @Optional
    codeEspece: CodeEspece;

    toString(): string {
        return this.libelle;
    }
}