import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpResponse, HttpClient } from '@angular/common/http';
import { Observable, Subject, defer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FunctionalError } from '../../errors/functional.error';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';


export interface PaginateResponse<T> {
  pagination: {
    pages: Observable<PaginateResponse<T>>[];
    next: Observable<PaginateResponse<T>>;
    prev: Observable<PaginateResponse<T>>;
    first: Observable<PaginateResponse<T>>;
    last: Observable<PaginateResponse<T>>;
    length: number;
    start: number;
    end: number;
    begin: number;
    maxRange: number;
    currentPage: number;
  };
  response: T;
}

export interface PaginateResponseStream<T> {
  pagination: {
    loadPages: (() => {})[]
    loadPrev: () => {};
    loadNext: () => {};
    loadFirst: () => {};
    loadLast: () => {};
    length: number;
    start: number;
    end: number;
    begin: number;
    maxRange: number;
    currentPage: number;
  };
  response: T;
}

interface HttpOptions {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe: 'response';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  constructor(
    private readonly http: HttpClient,
    private readonly loader: AdvBootstrapLoaderService
  ) { }

  paginateGet<ResponseType>(url: string, start?: number, limit?: number, originalOptions?: Partial<HttpOptions>): Observable<PaginateResponse<ResponseType>> {
    const options: HttpOptions = Object.assign({}, originalOptions, { observe: 'response', responseType: 'json' });

    // copy params
    if (!originalOptions || !originalOptions.params) {
      options.params = new HttpParams();
    } else if (originalOptions.params instanceof HttpParams) {
      options.params = new HttpParams();
      for (const key of originalOptions.params.keys()) {
        options.params = options.params.set(key, originalOptions.params.get(key));
      }
    } else {
      options.params = new HttpParams({
        fromObject: originalOptions.params
      });
    }

    if (start !== undefined && limit !== undefined) {
      options.params = options.params.delete('range');
      options.params = options.params.set('range', `${start}-${start + limit - 1}`);
    }

    return this.http.get<ResponseType>(url, options).pipe(
      map((response: HttpResponse<ResponseType>) => {
        let range: string, start: number, end: number, length: number, maxRange: number, effectiveRange: number;
        try {
          range = response.headers.get('content-range');
          [start, end, length] = range.match(/\d+/g).map(i => +i);
          /*const parsedRange = range.match(/(?<start>-?\d+)-(?<end>-?\d+)\/(?<length>-?\d+)/);

          start = +parsedRange.groups['start'];
          end = +parsedRange.groups['end'];
          length = +parsedRange.groups['length'];*/
        } catch (e) {
          throw new FunctionalError('PAGINATION_RESPONSE_NOT_VALID', {}, '[PaginationService] server response doesnt containt valid "Content-range" header : ' + response.headers.get('Content-range'), e);
        }
        try {
          maxRange = +response.headers.get('accept-range');
          effectiveRange = Math.min(maxRange, limit || Infinity);
        } catch (e) {
          maxRange = length;
          effectiveRange = length;
        }


        const nbPages = Math.ceil(length / effectiveRange) ;
        const first = start === 0;
        const last = end >= length - 1;
        const full = first && last;

        const currentPage =  Math.ceil(start / effectiveRange) ;

        const begin = (Math.abs(nbPages-currentPage)<=1 && nbPages > 20)? currentPage - 20 :((nbPages<20)?0:currentPage) ;
        const endPage =   begin + 20 > nbPages ? nbPages : begin + 20 ;

        const lastPage = endPage === nbPages;

        const firstPage = begin === 0;

        const pages: Observable<PaginateResponse<ResponseType>>[] = [];
        for (let i = begin; i < endPage; i++) {
          pages.push(this.paginateGet(url, i * effectiveRange, effectiveRange, originalOptions));
        }


        const paginationResponse: PaginateResponse<ResponseType> = Object.assign({
          pagination: {
            length, start, end, maxRange,
            pages,
            currentPage,
            prev: full || firstPage || first ? null : this.paginateGet(url, Math.max(0, start - effectiveRange), effectiveRange, originalOptions),
            next: full || lastPage || last ? null : this.paginateGet(url, end + 1, effectiveRange, originalOptions),
            first: full || firstPage || first ? null : this.paginateGet(url, 0, effectiveRange, originalOptions),
            last: full || lastPage || last ? null : this.paginateGet(url, length, effectiveRange, originalOptions),
            begin: begin
          },
          response: response.body
        });

        return paginationResponse;
      })
    );
  }

  callObservableAndfeedSubject<T>(obs: Observable<T>, subject: Subject<T>, showLoader = true) {
    obs.pipe(
      showLoader ? this.loader.operator() : tap()
    ).subscribe(
      response => { subject.next(response); },
      error => { subject.error(error); }
    );
  }

  paginateGetAsStream<ResponseType>(url: string, start?: number, limit?: number, options?: Partial<HttpOptions>, showLoader = true): Observable<PaginateResponseStream<ResponseType>> {
    const subject = new Subject<PaginateResponse<ResponseType>>();
    const obs = subject.pipe(
      map(response => {

        const pagination = {
          loadPages: response.pagination.pages.map(p => {
            return () => { this.callObservableAndfeedSubject(p, subject, showLoader); };
          }),
          loadPrev: response.pagination.prev ? () => { this.callObservableAndfeedSubject(response.pagination.prev, subject, showLoader); } : null,
          loadNext: response.pagination.next ? () => { this.callObservableAndfeedSubject(response.pagination.next, subject, showLoader); } : null,
          loadFirst: response.pagination.first ? () => { this.callObservableAndfeedSubject(response.pagination.first, subject, showLoader); } : null,
          loadLast: response.pagination.last ? () => { this.callObservableAndfeedSubject(response.pagination.last, subject, showLoader); } : null,
          length: response.pagination.length,
          start: response.pagination.start,
          end: response.pagination.end,
          begin: response.pagination.begin,
          maxRange: response.pagination.maxRange,
          currentPage: response.pagination.currentPage,
        };

        return { pagination, response: response.response } as PaginateResponseStream<ResponseType>;
      }),
    );

    return defer(() => {
      setTimeout(() => {
        this.callObservableAndfeedSubject(this.paginateGet<ResponseType>(url, start, limit, options), subject, showLoader);
      });

      return obs;
    });
  }
}
