import { JsonObject, JsonProperty } from "@thorolf/json-ts-mapper";
import { CodeEspece } from "../../declaration/models/enums/code-espece.enum";

@JsonObject
export class EstimationEcoulement {
  @JsonProperty('codeEspece', String)
  public espece: string;

  @JsonProperty('idGroupeVarietal', Number)
  public idGroupeVarietal: number;


  @JsonProperty('groupeVarietal', String)
  public groupeVarietal: string;  

  @JsonProperty('estimation', Number)
  public estimation: number;

  // @JsonProperty('venteFrance', Number)
  // public venteFrance: number;

  // @JsonProperty('venteExport', Number)
  // public venteExport: number;

  // @JsonProperty('venteIndustrie', Number)
  // public venteIndustrie: number;

  // @JsonProperty('freinte', Number)
  // public freinte: number;      

  sorting(): string {
    var x =
        ''.concat(
            this.espece === CodeEspece.POMME ? '0' : (this.espece === CodeEspece.POIRE ? '1' : '2'),

            this.groupeVarietal.toUpperCase());
    return x;
  }
}