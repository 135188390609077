import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Defaut } from 'src/app/data/commission/models/defaut.model';
import { CahiersService } from 'src/app/data/commission/services/cahiers/cahiers.service';
import { EchantillonsService } from 'src/app/data/commission/services/echantillons/echantillons.service';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { Cahier } from 'src/app/data/habilitation/models/cahier.model';
import { Produit } from 'src/app/data/habilitation/models/produit.model';
import { ProduitsService } from 'src/app/data/habilitation/services/produits/produits.service';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';
import { TourneeEchantillon } from 'src/app/data/tournee/models/tournee-echantillon.models';
import { TourneesService } from 'src/app/data/tournee/services/tournees.service';
import { AbstractRechercheItemComponent } from 'src/app/main/pages/commissions/pages/edit-commission/modals/abstract-recherche-items.component';

@Component({
  selector: 'app-recherche-echantillons-tournee',
  templateUrl: './recherche-echantillons-tournee.component.html',
  styleUrls: ['./recherche-echantillons-tournee.component.scss']
})
export class RechercheEchantillonsTourneeComponent extends AbstractRechercheItemComponent<TourneeEchantillon> implements OnInit {
  activeTabId: string;
  @Input() idOrganisme: number;
  @Input() alreadySelectedEchantillonsIds: number[];
  produits: Produit[];
  cahiers: Cahier[];
  defauts: Defaut[];
  formGroupLeurre: FormGroup;
  
  constructor(
    public readonly modal: NgbActiveModal,
    private readonly cahiersService: CahiersService,
    private readonly refService: ReferencesService,
    private readonly refServiceDeclaration: ReferentielService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly tourneeService: TourneesService
  ) {
    super(modal);
  }

  ngOnInit() {

    forkJoin(
      this.cahiersService.getCahiers()
    ).pipe()
    .subscribe(([cahiers]) =>{
      this.cahiers = cahiers;
    });

    super.ngOnInit();
  }

  submit() {
    this.modal.close({ echantillons: this.selectedItems });
  }

  getData() {   
   
       return   forkJoin(
                this.tourneeService.getEchantillonsLibres(this.idOrganisme),      
                this.refService.getReferences()
              ).pipe(
                this.loaderService.operator(),
                map(([echantillons, ref]) => {
                  this.defauts = ref.defauts;

                  return echantillons;
                }),
                map(echantillons => {
                  return echantillons.filter(echantillon => this.alreadySelectedEchantillonsIds.indexOf(echantillon.id) === -1);
                }),
                catchError(error => {
                  this.modal.dismiss(error);
                  throw error;
                })
              );
 
    
    
   
  }

}
