import { Component, OnInit, Input } from '@angular/core';
import { EchantillonJury } from 'src/app/data/commission/models/echantillon-jury.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormArray, Validators, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-affectation-echantillons',
  templateUrl: './affectation-echantillons.component.html',
  styleUrls: ['./affectation-echantillons.component.scss']
})
export class AffectationEchantillonsComponent implements OnInit {

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly fb: FormBuilder
  ) { }
  @Input() echantillonsListe: EchantillonJury[];
  @Input() nbJurys: number;
  echantillons: EchantillonJury[];
  affectationsForm: FormGroup;
  echantillonsForms: { echantillon: EchantillonJury, form: FormGroup }[];

  // ###################################

  order = {
    property: 'id',
    reverse: false
  };

  ngOnInit() {
    this.echantillons = this.echantillonsListe.filter(e => e.echantillon);

    this.affectationsForm = this.fb.group({ liste: this.fb.array([]) });
    this.echantillonsForms = [];

    this.echantillons.forEach(echantillon => {
      const form = this.fb.group({
        echantillon: [echantillon.echantillon.id],
        jury: [echantillon.numeroJury, [Validators.required, this.juryValidator.bind(this)]]
      });

      this.echantillonsForms.push({ echantillon, form });
      (this.affectationsForm.get('liste') as FormArray).push(form);
    });
  }

  juryValidator(control: FormControl) {
    const v = control.value as number;
    if (v < 1 || v > this.nbJurys) {
      return { invalid: true };
    }
    return null;
  }

  cancel() {
    this.modal.dismiss();
  }

  submit() {
    if (this.affectationsForm.valid) {
      const result: { [name: number]: EchantillonJury[] } = {};

      this.echantillonsForms.forEach(f => {

        const jury = f.form.get('jury').value;
        const ech = f.echantillon;

        if (result[jury] === undefined) {
          result[jury] = [];
        }

        result[jury].push(ech);
      });

      this.modal.close(result);
    }
  }

  setOrder(property: string) {
    if (property) {
      if (property === this.order.property) {
        this.order.reverse = !this.order.reverse;
      } else {
        this.order = {
          property,
          reverse: false
        };
      }
    }
  }

  getIcon(property: string) {
    if (property === this.order.property) {
      return this.order.reverse ? 'sort-down' : 'sort-up';
    } else {
      return 'sort';
    }
  }
}
