import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { SyntheseVolumesAOC } from '../../models/synthese-volumes-aoc.model';
import { SyntheseVolumesIGP } from '../../models/synthese-volumes-igp.model';
import { ProduitsService } from 'src/app/data/habilitation/services/produits/produits.service';

@Injectable({
  providedIn: 'root'
})
export class SyntheseVolumesService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly produitsService: ProduitsService
  ) { }

  getSyntheseVolumesAOC(idOperateur: number, campagne: string): Observable<SyntheseVolumesAOC[]> {
    // return this.http.get<object[]>(`/assets/mocks/syntheses-aoc.json`).pipe(
    return this.http.get<object[]>(`/api/declaration/private/operateurs/${idOperateur}/synthese-volumes-aoc/${campagne}`).pipe(
      map(syntheses => this.mapper.deserializeArray(syntheses, SyntheseVolumesAOC)),
      this.produitsService.getProduitPipe()
    );
  }

  getSyntheseVolumesIGP(idOperatuer: number, campagne: string): Observable<SyntheseVolumesIGP[]> {
    // return this.http.get<object[]>(`/assets/mocks/syntheses-igp.json`).pipe(
    return this.http.get<object[]>(`/api/declaration/private/operateurs/${idOperatuer}/synthese-volumes-igp/${campagne}`).pipe(
      map(syntheses => this.mapper.deserializeArray(syntheses, SyntheseVolumesIGP)),
      this.produitsService.getProduitPipe()
    );
  }
}
