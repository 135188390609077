import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractRechercheItemComponent } from '../../../commissions/pages/edit-commission/modals/abstract-recherche-items.component';
import { Observable, pipe } from 'rxjs';
import { DetailsObjetFacture } from 'src/app/data/facturation/models/details-objet-facture.model';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ObjetFactureService } from 'src/app/data/facturation/services/objet-facture/objet-facture.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import * as moment from 'moment';

@Component({
  selector: 'app-donnees-facturation',
  templateUrl: './donnees-facturation.component.html',
  styleUrls: ['./donnees-facturation.component.scss']
})
export class DonneesFacturationComponent extends AbstractRechercheItemComponent<DetailsObjetFacture> implements OnInit {
  @Input() idsToSend: number[];
  /*
  detailsObjetFacture: DetailsObjetFacture[] = [{
    id: 1,
    prixMini: 12,
    prixUnitaire: 12,
    montantTva: 15,
    quantite: 45,
    totalHt: 122,
    tva: 10,
    libelle: 'bonjour',
    dateFacturation: null,
    idIntervenant: 4,
    idObjetFacture: 4,
    idRefQuantiteFacture: 1,
    mailOperateur: 'fff@ferf.fr',
    totalTtc: 504
  }];
  */
  detailsObjetFacture: DetailsObjetFacture[];

  getData(): Observable<any[]> {
    throw new Error('Method not implemented.');
  }

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly objetFactureService: ObjetFactureService
  ) {
    super(modal);
  }

  ngOnInit() {
    this.objetFactureService.getDetailsObjetFacture(SessionContext.get('idOrganisme'), this.idsToSend)
      .pipe(
        this.loaderService.operator()
      ).subscribe(details => {
        this.detailsObjetFacture = details;
      });
  }

}
