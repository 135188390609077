<div class="content">
  <div class="content-panel">
    <form *ngIf="formGroup" [formGroup]="formGroup" #form="ngForm" (ngSubmit)="submit()" novalidate>
      <div class="form-group">
        <input type="text" name="identifiant" [formControl]="identifiant" required [ngClass]="{'is-invalid': form.submitted && identifiant.invalid}"
          class="form-control" placeholder="Identifiant" />
      </div>

      <div class="form-group">
        <select class="form-control"  [formControl]="domaine" [ngClass]="{'is-invalid': form.submitted && domaine.invalid, 'select-placeholder' : formGroup.get('domaine').value === null}" >
          <option [ngValue]="null" hidden>Domaine</option>
          <option *ngFor="let domaine of codesDomaines" [ngValue]="domaine" [disabled]="domaine !== 'VIN'">{{domaine}}</option>
        </select>
        <!-- <input type="text" name="domaine"required [ngClass]="{'is-invalid': form.submitted && domaine.invalid}"
          class="form-control" placeholder="Domaine" /> -->
      </div>
      <hr/>
      <div class="dates">
        <div class="form-group">
          <div class="input-group">
            <input formControlName="dateDebut" placeholder="Date de début" ngbDatepicker #datePickerDebut="ngbDatepicker" [ngClass]="{'is-invalid': form.submitted && formGroup.get('dateDebut').invalid}" class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="datePickerDebut.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="input-group">
            <input formControlName="dateFin" placeholder="Date de fin" ngbDatepicker #datePickerFin="ngbDatepicker" [ngClass]="{'is-invalid': form.submitted && formGroup.get('dateFin').invalid}" class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="datePickerFin.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <div class="cols">
        <div class="form-group">
          <input type="text" formControlName="structureJuridique" [ngClass]="{'is-invalid':form.submitted && formGroup.get('structureJuridique').invalid}" class="form-control" placeholder="Structure juridique"/>
        </div>

        <div class="form-group">
          <input type="text" formControlName="raisonSociale" [ngClass]="{'is-invalid':form.submitted && formGroup.get('raisonSociale').invalid}" class="form-control" placeholder="Raison sociale"/>
        </div>

        <div class="form-group">
          <input type="text" formControlName="siret" [ngClass]="{'is-invalid':form.submitted && formGroup.get('siret').invalid}" class="form-control" placeholder="Siret"/>
        </div>

        <div class="form-group">
          <input type="text" formControlName="tvaIntra" [ngClass]="{'is-invalid':form.submitted && formGroup.get('tvaIntra').invalid}" class="form-control" placeholder="Tva intra"/>
        </div>
      </div>
      <hr/>
      <div class="cols">
        <div class="form-group">
          <input type="text" formControlName="adresse" [ngClass]="{'is-invalid':form.submitted && formGroup.get('adresse').invalid}" class="form-control" placeholder="Adresse"/>
        </div>

        <div class="form-group">
          <input type="text" formControlName="codePostal" [ngClass]="{'is-invalid':form.submitted && formGroup.get('codePostal').invalid}" class="form-control" placeholder="Code postal"/>
        </div>

        <div class="form-group">
          <input type="text" formControlName="ville" [ngClass]="{'is-invalid':form.submitted && formGroup.get('ville').invalid}" class="form-control" placeholder="Ville"/>
        </div>

        <div class="form-group">
          <input type="text" formControlName="pays" [ngClass]="{'is-invalid':form.submitted && formGroup.get('pays').invalid}" class="form-control" placeholder="Pays"/>
        </div>

        <div class="form-group">
          <input type="text" formControlName="cedex" [ngClass]="{'is-invalid':form.submitted && formGroup.get('cedex').invalid}" class="form-control" placeholder="Cedex"/>
        </div>
      </div>
      <hr/>
      <div class="cols">
        <div class="form-group">
          <input type="text" formControlName="telFixe" [ngClass]="{'is-invalid':form.submitted && formGroup.get('telFixe').invalid}" class="form-control" placeholder="Tel fixe"/>
        </div>

        <div class="form-group">
          <input type="text" formControlName="telPortable" [ngClass]="{'is-invalid':form.submitted && formGroup.get('telPortable').invalid}" class="form-control" placeholder="Tel portable"/>
        </div>

        <div class="form-group">
          <input type="text" formControlName="fax" [ngClass]="{'is-invalid':form.submitted && formGroup.get('fax').invalid}" class="form-control" placeholder="Fax"/>
        </div>
      </div>
      <hr/>
      <div class="cols" formGroupName="domaineSpec">
        <div class="form-group" *ngFor="let controlName of domaineSpecControlsNames">
          <input type="text" [formControlName]="controlName" [ngClass]="{'is-invalid':form.submitted && formGroup.get('domaineSpec.'+controlName).invalid}" class="form-control" placeholder="{{controlName | titlecase}}"/>
        </div>
      </div>
      <br/>

      <div class="actions-bar">
        <button type="submit" class="btn btn-primary">Inscription</button>
      </div>
    </form>
  </div>
</div>
