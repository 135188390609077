import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin, of } from 'rxjs';
import { Contact } from 'src/app/data/intervenant/models/contact.model';
import { ContactInteraction } from 'src/app/data/intervenant/models/contact-interaction.model';
import { EntrepriseBase } from 'src/app/data/intervenant/models/entreprise-base.model';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { Interaction } from 'src/app/data/intervenant/models/interaction.model';
import { RefTypeInteraction } from 'src/app/data/intervenant/models/ref-type-interaction.model';
import { ContactsService } from 'src/app/data/intervenant/services/contacts/contacts.service';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { RefTypeInteractionsService } from 'src/app/data/intervenant/services/ref-type-interaction/ref-type-interactions.service';
import { InteractionsService } from 'src/app/data/intervenant/services/interactions/interactions.service';
import { Interlocuteur } from 'src/app/data/intervenant/models/interlocuteur.model';
import { InterlocuteursService } from 'src/app/data/intervenant/services/interlocuteurs/interlocuteurs.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';


const heureRequiredValidator = function (control: FormControl) {
  if (!control.value || typeof control.value.hour === 'undefined' || typeof control.value.minute === 'undefined') {
    return { invalid: true };
  }

  return null;
};

@Component({
  selector: 'app-edit-interaction',
  templateUrl: './edit-interaction.component.html',
  styleUrls: ['./edit-interaction.component.scss']
})
export class EditInteractionComponent implements OnInit {

  formGroup: FormGroup;

  creation = false;

  title: string;

  interaction: Interaction;
  refTypeInteractions: RefTypeInteraction[];

  entrepriseBase: Entreprise;
  contactInteraction: ContactInteraction;
  contactAnpp: Interlocuteur;

  contacts: ContactInteraction[];
  contacts_anpp: Interlocuteur[];

  idEntreprise: number;
  idContact: number;

  get date_interaction() { return this.formGroup.get('date_interaction'); }
  get heure_interaction() { return this.formGroup.get('heure_interaction'); }
  get contact() { return this.formGroup.get('contact'); }
  get contact_anpp() { return this.formGroup.get('contact_anpp'); }
  get type_interaction() { return this.formGroup.get('type_interaction'); }
  get sujet() { return this.formGroup.get('sujet'); }
  get compte_rendu() { return this.formGroup.get('compte_rendu'); }
  get idOrganisme() { return SessionContext.get('idOrganisme'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly contactService: ContactsService,
    private readonly entrepriseService: EntreprisesService,
    private readonly refTypeInteractionService: RefTypeInteractionsService,
    private readonly interactionService: InteractionsService,
    private readonly interlocuteurService: InterlocuteursService
  ) { }

  ngOnInit() {
    const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    const idc = parseInt(this.route.snapshot.paramMap.get('idc'), 10);
    const ide = parseInt(this.route.snapshot.paramMap.get('ide'), 10);

    if (!id) {
      this.creation = true;
    }

    if (ide) {
      this.idEntreprise = ide;
    }

    if (idc) {
      this.idContact = idc;
    }

    this.loadData(id);
  }

  private loadData(id: number) {

    this.title = this.creation ? 'page.interactions.form.title.add' : 'page.interactions.form.title.edit';

    if (!this.creation) {
      forkJoin(
        this.refTypeInteractionService.getTypeInteractions(),
        this.interlocuteurService.getInterlocuteurs(this.idOrganisme),
        this.interactionService.getInteraction(id))
        .pipe(
          this.loaderService.operator()
        ).subscribe(([refType, interlocuteurs, interaction]) => {
          this.refTypeInteractions = refType;
          this.contacts_anpp = interlocuteurs;
          this.interaction = interaction;

          forkJoin(
            this.contactService.getContactInteractionsByEntreprise(interaction.contact.idEntreprise),
            this.entrepriseService.getEntrepriseBase(interaction.contact.idEntreprise)
          )
            .pipe(this.loaderService.operator()).subscribe(([contacts, entreprise]) => {
              this.contacts = contacts;
              this.entrepriseBase = entreprise;

              this.initForm(interaction);
            });

        });
    }
    else {


      forkJoin(

        this.refTypeInteractionService.getTypeInteractions(),
        this.interlocuteurService.getInterlocuteurs(this.idOrganisme))
        .pipe(
          this.loaderService.operator()
        ).subscribe(([refType, interlocuteurs]) => {

          this.refTypeInteractions = refType;
          this.contacts_anpp = interlocuteurs;
          this.contacts = [];
          this.interaction = new Interaction();

          if (this.idContact) {
            this.contactService.getContact(this.idContact).subscribe((contact) => {
              this.entrepriseService.getEntreprise(contact.entreprise.id).subscribe((entreprise) => {
                this.entrepriseBase = entreprise;
                this.contactService.getContactInteractionsByEntreprise(entreprise.id).pipe(this.loaderService.operator()).subscribe((contacts) => {
                  this.contacts = contacts;
                  this.interaction.contact = this.contacts.find(c => c.idContact === this.idContact);                 
                  this.initForm(this.interaction);
                });
              });
            });
          }

          if (this.idEntreprise) {
            this.entrepriseService.getEntreprise(this.idEntreprise).subscribe((entreprise) => {
              this.entrepriseBase = entreprise;
              this.contactService.getContactInteractionsByEntreprise(entreprise.id).pipe(this.loaderService.operator()).subscribe((contacts) => {
                this.contacts = contacts;     
                this.initForm(this.interaction);
              });
            });
          }

          if (!this.idContact && !this.idEntreprise) {
            this.entrepriseBase = new Entreprise();        
            this.initForm(this.interaction);
          }


        });
    }
  }

  private initForm(interaction?: Interaction): void {

    this.formGroup = this.fb.group({
      date_interaction: [{
        value: ((interaction && interaction.dateInteraction !== undefined) ? {
          year: interaction.dateInteraction.toDate().getFullYear(),
          month: interaction.dateInteraction.toDate().getMonth() + 1,
          day: interaction.dateInteraction.toDate().getDate()
        } : undefined), disabled: false
      }, Validators.required],
      heure_interaction: [{
        value: ((interaction && interaction.dateInteraction !== undefined) ? {
          hour: interaction.dateInteraction.toDate().getHours(),
          minute: interaction.dateInteraction.toDate().getMinutes()
        } : undefined), disabled: false
      },  [Validators.required,heureRequiredValidator]],
      contact: [{ value: ((interaction.contact) ? this.contacts.find(c => c.idContact === interaction.contact.idContact) : undefined), disabled: false }, Validators.required],
      contact_anpp: [{ value: ((interaction.contact_anpp) ? this.contacts_anpp.find(c => c.id === interaction.contact_anpp.id) : undefined), disabled: false }, Validators.required],
      sujet: [{ value: ((interaction.sujetInteraction) ? interaction.sujetInteraction : undefined), disabled: false }, Validators.required],
      compte_rendu: [{ value: ((interaction.compteRendu) ? interaction.compteRendu : undefined), disabled: false }],
      type_interaction: [{ value: ((interaction.refTypeInteraction) ? this.refTypeInteractions.find(c => c.id === interaction.refTypeInteraction.id) : undefined), disabled: false }, Validators.required],
    });
  }

  changeEntreprise(event): void {

    const entreprise = event.target.value;

    this.contactService.getContactInteractionsByEntreprise(entreprise.id).pipe(this.loaderService.operator()).subscribe((contacts) => {
      this.contacts = contacts;
      if(contacts.length > 0)
      this.formGroup.get(`contact`).setValue(contacts[0]);
      else this.formGroup.get(`contact`).setValue(null);
    });


  }

  selectItem(newItem: Entreprise) {
    this.entrepriseBase = newItem;
    this.contactService.getContactInteractionsByEntreprise(newItem.id).pipe(this.loaderService.operator()).subscribe((contacts) => {
      this.contacts = contacts;
      if(contacts.length > 0)
      this.formGroup.get(`contact`).setValue(contacts[0]);
      else this.formGroup.get(`contact`).setValue(null);
       
    });
  }

  onCancel() {

    if (this.idContact)
            this.router.navigate(['main/admin/params/contacts', this.idContact,'interactions']);

          if (this.idEntreprise)
            this.router.navigate(['main/admin/params/entreprises/fiche',this.idEntreprise]);

          if (!this.idContact && !this.idEntreprise)
            this.router.navigate(['main/admin/params/interactions']);
  }

  submit() {
    if (this.formGroup.valid) {

      const date = this.date_interaction.value;
      const heureDebut = this.heure_interaction.value;

      this.interaction.compteRendu = this.compte_rendu.value;
      this.interaction.contact = this.contact.value;
      this.interaction.contact_anpp = this.contact_anpp.value;
      this.interaction.dateInteraction = moment([date.year, date.month - 1, date.day, heureDebut.hour, heureDebut.minute, 0, 0]);;
      this.interaction.sujetInteraction = this.sujet.value;
      this.interaction.refTypeInteraction = this.type_interaction.value;

      if (this.interaction.id == null) {
        this.interactionService.creerInteraction(this.interaction).subscribe(() => {
          if (this.idContact)
            this.router.navigate(['main/admin/params/contacts']);

          if (this.idEntreprise)
            this.router.navigate(['main/admin/params/entreprises/fiche',this.idEntreprise]);

          if (!this.idContact && !this.idEntreprise)
            this.router.navigate(['main/admin/params/interactions']);
        });
      } else {
        this.interactionService.modifierInteraction(this.interaction).subscribe(() => {
          if (this.idContact)
            this.router.navigate(['main/admin/params/contacts']);

          if (this.idEntreprise)
            this.router.navigate(['main/admin/params/entreprises/fiche',this.idEntreprise]);

          if (!this.idContact && !this.idEntreprise)
            this.router.navigate(['main/admin/params/interactions']);
        });
      }

    }
  }

}
