import * as moment from 'moment';

import { JsonConverter, AbstractJsonConverter } from '@thorolf/json-ts-mapper';
import { UnexpectedArgument } from 'src/app/shared/errors/unexpected-argument.error';
import { VisibiliteEnum } from 'src/app/data/edition/models/enums/visibilite.enum';

/**
 * Datetime converter -{moment}<->{string}-
 * Default format is ISO9601
 */
@JsonConverter
export class DateTimeConverter implements AbstractJsonConverter<string, moment.Moment> {
  requiredContextKeys(): void | { serialize?: string[]; deserialize?: string[]; } { }

  deserialize(data: string): moment.Moment {
    // console.debug(data);
    const m = moment(data);
    // console.debug(data, m);
    if (m.isValid()) {
      return m;
    } else {
      throw new UnexpectedArgument('invalid date representation', data);
    }
  }

  serialize(data: moment.Moment): string {
    return data && data.format ? data.format('YYYY-MM-DDTHH:mm:ss.SSSZZ') : null;
  }
}

/**
 * Converter -{moment}<->{string}- date
 * Default format is YYYY-MM-DD
 */
@JsonConverter
export class DateConverter implements AbstractJsonConverter<string, moment.Moment> {
  requiredContextKeys(): void | { serialize?: string[]; deserialize?: string[]; } { }

  deserialize(data: string): moment.Moment {
    const m = moment(data);
    if (m.isValid()) {
      return m;
    } else {
      throw new UnexpectedArgument('invalid date representation', data);
    }
  }

  serialize(data: moment.Moment): string {
    return data && data.format ? data.format('YYYY-MM-DD') : null;
  }
}

export class VisibiliteEnumConverter implements AbstractJsonConverter<string, VisibiliteEnum> {
  requiredContextKeys(): void | { serialize?: string[]; deserialize?: string[]; } { }

  deserialize(obj: string): VisibiliteEnum {
    return obj as VisibiliteEnum;
  }

  serialize(obj: VisibiliteEnum): string {
    return VisibiliteEnum[obj].toString();
  }
}
