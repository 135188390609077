import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class ComplementPoPdfVer {
  @JsonProperty('idProducteurOrganise', Number)
  public idProducteurOrganise: number;

  @JsonProperty('idContactReferent', Number)
  @Optional
  public idContactReferent: number;

  @JsonProperty('structureJuridique', String)
  @Optional
  public structureJuridique: string;
}