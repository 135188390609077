<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
      page.declarations.synthese.modal.choix-stock.title
    </h4>
  </div>

  <div class="modal-body">
    <div class="liste-choix">
      <button type="button" *ngFor="let stock of stocks" (click)="select(stock)" class="btn btn-primary stock-button">{{'page.declarations.synthese.modal.choix-stock.'+stock | translate }}</button>
    </div>

    <div class="actions-bar">
        <button class="btn btn-light" (click)="dismiss()" translate>label.annuler</button>
    </div>
  </div>
</div>
