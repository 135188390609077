import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CahiersService } from 'src/app/data/commission/services/cahiers/cahiers.service';
import { UniteCulturale } from 'src/app/data/declaration/models/unite-culturale.model';
import { UniteCulturaleService } from 'src/app/data/declaration/services/parcellaire/unite-culturale.service';
import { ProduitsService } from 'src/app/data/declaration/services/produits/produits.service';
import { Cahier } from 'src/app/data/habilitation/models/cahier.model';

@Component({
  selector: 'app-valid-dpap',
  templateUrl: './valid-dpap.component.html',
  styleUrls: ['./valid-dpap.component.scss']
})
export class ValidDpapComponent implements OnInit {

  formGroup: FormGroup;
  isChecked = false;
  @Input() idEntreprise: number;
  @Input() ucs: UniteCulturale[];

  cahiers: Cahier[] = [];
  cahier: Cahier;

  constructor(  public readonly modal: NgbActiveModal,
                private readonly loaderService: AdvBootstrapLoaderService,
                private readonly uniteCulturaleService: UniteCulturaleService,
                private readonly modalsService: AdvBootstrapModalService,
                private readonly translate: TranslateService,
                private readonly produitService: ProduitsService,
                private readonly cahierService: CahiersService,
                private readonly fb: FormBuilder) { }

  ngOnInit() {

    let idProduit: number[] = [];
    if(this.ucs)
    this.ucs.forEach(uc =>{ 
        if(idProduit.indexOf(uc.uniteCulturaleVigne.idProduitDestination) === -1){
          idProduit.push(uc.uniteCulturaleVigne.idProduitDestination);
        };       
      });

      this.cahierService.getCahiersByProduits(idProduit).pipe(this.loaderService.operator()).subscribe((cahiers) => {
        this.cahiers = cahiers;
      });
  }

  cancel() {
    this.modal.dismiss();
  }

  
  validerDeclaration(){
    if (!this.cahier) {
      this.modalsService.error(
        this.translate.instant(`page.inventaire-verger.modals.valid-dpap.erreur`),
        this.translate.instant(`page.inventaire-verger.modals.valid-dpap.titleError`), {
          cancelText: this.translate.instant(`label.annuler`),
          windowClass: 'anpp'
        }
      );
    } else {
      this.uniteCulturaleService.validerDPAP(this.idEntreprise,this.cahier.id).pipe(this.loaderService.operator()).subscribe(() => {
        this.modalsService.alert(this.translate.instant(`page.inventaire-verger.modals.valid-inventaire-verger.msgOk`), null , {windowClass: 'anpp'}).then(() => {
          this.modal.dismiss();
        });
      });

    }
  }

}
