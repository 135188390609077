import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Cache, ClearCache, CacheKey } from 'src/app/core/services/cache/cache.service';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Observable, of } from 'rxjs';
import { ProduitsService } from 'src/app/data/habilitation/services/produits/produits.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import * as moment from 'moment';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { SuiviParcellairesViticoles } from '../../models/suivi-parcellaires-viticoles.model';
import { Produit } from 'src/app/data/habilitation/models/produit.model';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';


interface ObjectWithProduit { codeProduit: string; produit: Produit; }

@Injectable({
  providedIn: 'root'
})
export class SuiviParcellairesViticolesService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly produitsService: ProduitsService,
    private readonly entrepriseService: EntreprisesService
  ) { }

  @ClearCache
  clearCache() { }

  /**
   * Retourne la liste des mouvements non lus d'un organisme
   * @returns Une observable contenant une collection d'HistoriqueMouvement
   * @see SuiviParcellairesViticoles
   */
  getSuiviParcellaire(raisonSociale: string, annee: string, non_controlable: string, debutDate: moment.Moment, finDate: moment.Moment, suivi: string, cdcs: string[]): Observable<SuiviParcellairesViticoles[]> {
        
    const dateConverter = new DateConverter();
    let params = new HttpParams();

    params = (raisonSociale !== null && raisonSociale.length > 0) ? params.set('raisonSociale', raisonSociale) : params;
    params = (annee !== null ) ? params.set('annee', annee) : params;
    params = (suivi !== null ) ? params.set('suivi', suivi) : params;
    params = (non_controlable !== null ) ? params.set('non_controlable', non_controlable.toString()) : params;
    params = (debutDate) ? params.set('debutDate', dateConverter.serialize(debutDate)) : params;
    params = (finDate) ? params.set('finDate', dateConverter.serialize(finDate)) : params;
    params = (cdcs !== null ) ? params.set('cdcs', cdcs.join(',')) : params;
    
    return this.http.get<object[]>(
      `/api/declaration/private/suiviParcellaireFiltre`,
      {params}
      // '/assets/mocks/suivi-mouvements.json'
      
    ).pipe(
      map(historiques => this.mapper.deserializeArray(historiques, SuiviParcellairesViticoles)),
      this.entrepriseService.getEntreprisePipe()//,
      // tap(historiques => {
      //   historiques.forEach(historique =>
      //     this.operateurService.getInformationsDomaine(historique.idOperateur).subscribe(
      //       details => {
      //         const detail = details.find(detail => detail.code === 'VIN_CVI');
      //         historique.cvi = (detail) ? +detail.valeur : 0;
      //       }
      //     )
      //   );
      // })
    );
  }

  
  
}
