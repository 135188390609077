import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { remove } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { GroupeVarietalParam } from 'src/app/data/declaration/models/groupe-varietal-param.model';
import { Variete } from 'src/app/data/declaration/models/variete.model';
import { GroupeVarietalService } from 'src/app/data/declaration/services/groupe-varietal/groupe-varietal.service';
import { VarieteService } from 'src/app/data/declaration/services/parcellaire/variete.service';

@Component({
  selector: 'app-liste-varietes',
  templateUrl: './liste-varietes.component.html',
  styleUrls: ['./liste-varietes.component.scss']
})
export class ListeVarietesComponent implements OnInit {

  varietes : Variete[];

  groupesVarietaux : GroupeVarietalParam[];

  static routes: Routes = [
    { path: '', component: ListeVarietesComponent, data: { roles: ['ORGANISME'] } }
  ];

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalsService : AdvBootstrapModalService,
    private readonly translate: TranslateService,
    private readonly varieteService : VarieteService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly groupevarietalService : GroupeVarietalService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
 
    forkJoin(
      this.varieteService.getVarietes()
      )
    .pipe(this.loaderService.operator())
    .subscribe(([varietes]) => {
      this.varietes = varietes;     
    });

    

  }

  openConfirmationDialog(variete: Variete) { 

    this.modalsService.confirm(
      'Veuillez confirmer',
      'Voulez vous supprimer ' + this.translate.instant(`label.variete`)+ variete.libelle + ' ?', {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.deleteVariete(variete);
    }, () => { });

  }

  deleteVariete(variete: Variete) {

    forkJoin(
      this.groupevarietalService.getGroupeVarietaux()
      )
      .pipe(
        this.loaderService.operator()
      ).subscribe(([groupes]) => {
                
          if(groupes.filter( gv => gv.varietes.find(v => v.idVariete === variete.id)).length > 0){
            this.toastrService.error(this.translate.instant('page.valeur_parametrable.variete_presente'));
          }
          else {
            this.varieteService.desactiverVariete(variete).subscribe(() => {
                remove(this.varietes, variete);
              });
          }
      });          
  }

  editVariete(variete: Variete) {
    this.modalsService.confirm(
      'Veuillez confirmer',
      this.translate.instant(`page.valeur_parametrable.message_modification`), {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.router.navigate(['edit', variete.id], { relativeTo: this.route });
    }, () => { });
   
  }

}
