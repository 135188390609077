import { JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";

JsonObject
export class CommissionSyntheseEdition{

    @JsonProperty('nbEchantillons', String)
    @Optional
    nbEchantillons: string;

    @JsonProperty('volumeTotal', String)
    @Optional
    volumeTotal: string;

    @JsonProperty('volumeConforme', String)
    @Optional
    volumeConforme: string;

    @JsonProperty('volumeNonConforme', String)
    @Optional
    volumeNonConforme: string;

    @JsonProperty('nbOperateurs', String)
    @Optional
    nbOperateurs: string;

    @JsonProperty('nbNonConformes', String)
    @Optional
    nbNonConformes: string;

    @JsonProperty('nbConformes', String)
    @Optional
    nbConformes: string;

}
