import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { Terroir } from 'src/app/data/intervenant/models/terroir.model';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { TerroirsService } from 'src/app/data/intervenant/services/terroirs/terroirs.service';
import { Departement } from 'src/app/shared/models/departement.model';
import { DepartementService } from 'src/app/shared/services/dep-commune/departement.service';
import { FicheEntrepriseComponent } from '../../fiche-entreprise/fiche-entreprise/fiche-entreprise.component';

@Component({
  selector: 'app-liste-po',
  templateUrl: './liste-po.component.html',
  styleUrls: ['./liste-po.component.scss']
})
export class ListePoComponent implements OnInit {

  entreprises : Entreprise[];
  @Input() public estOnglet = false;

  @Input() @Output() id : number;

  terroirs: Terroir[];
  departements: Departement[];
  

  constructor(
    private readonly entrepriseService :EntreprisesService,
    private readonly route: ActivatedRoute,
    private readonly loaderService : AdvBootstrapLoaderService,
    private readonly router: Router,
    private readonly terroirService: TerroirsService,
    private readonly departementService: DepartementService
  ) { }

  ngOnInit() {
    
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    
    forkJoin(
      this.entrepriseService.getEntreprisesByIdEntrepriseLiee(this.id),      
      this.departementService.getLocalisationDepartements(),
      this.terroirService.getTerroirs()
    )
    .pipe(this.loaderService.operator()).subscribe(([entreprises,departements,terroirs]) => {
        this.entreprises = entreprises;
        this.departements = departements;
        this.terroirs = terroirs;
    });
  }

  ficheEntreprise(entreprise : Entreprise)
  {
    this.id = entreprise.id;

    this.router.navigate(['../',entreprise.id ], { relativeTo: this.route });
  }

  getTerroir(departement: string) {
    if (departement) {

      //const dept = this.departements.find(d => d.code === departement).code;

      const terroir_dept = this.terroirs.find(t => {
        if(t.departements)
          return t.departements.indexOf(departement) !== -1;         
      });

      if (terroir_dept)
        return terroir_dept.libelle;
      else return '';
    }
  }

}
