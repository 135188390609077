import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { Service } from '../../intervenant/models/service.model';
import { RefSoustypeUtilisateur } from './ref-soustype-utilisateur.model';
import { DroitFonctionnel } from './droit-fonctionnel.model';

@JsonObject
export class DroitProfil {
  @JsonProperty('id', Number)
  @Optional
  public id: number;

  @JsonProperty('libelle', String)
  public libelle: string;

  @JsonProperty('idRefSousType', Number)
  @CustomConverter(DataIdConverterFactory.build(RefSoustypeUtilisateur, 'soustypesUtilisateurs'))
  soustype: RefSoustypeUtilisateur;

  @JsonProperty('idService', Number)
  @CustomConverter(DataIdConverterFactory.build(Service, 'services'))
  service: Service;

  droitsFonctionnels: DroitFonctionnel[] = [];
}
