<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel"><b translate> page.inventaire-verger.modals.division.title</b></h5>
    <button (click)="cancel()" type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="formGroup" #form="ngForm" (ngSubmit)="submit()" novalidate>
      <h5 class="sub-popup-title"translate>page.inventaire-verger.infoBlocTitle</h5>
      <div class="row">
        <div class="col-lg-3">
          <span translate>page.inventaire-verger.numBloc</span>
          <span>: {{uniteCulturale?.numBlocFruitier}}</span>
        </div>
        <div class="col-lg-3">
          <span translate>page.inventaire-verger.lieuDit</span>
          <span>: {{uniteCulturale?.localisation.lieuDit}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <span translate>page.inventaire-verger.commune</span>
          <span>: {{uniteCulturale?.localisation.commune}}</span>
        </div>
      </div>
      <hr>
      <h5 class="sub-popup-title"translate>page.inventaire-verger.distancePlantationTitle</h5>
      <div class="row">
        <div class="col-lg-3">
          <span translate>page.inventaire-verger.produitIdentifie</span>
          <span>: {{uniteCulturale?.uniteCulturaleVigne.produitIdentifie}}</span>
        </div>
        <div class="col-lg-3">
          <span translate>page.inventaire-verger.surfacePlantee</span>
          <span>: {{uniteCulturale?.uniteCulturaleVigne.surfacePlantee}}</span>
        </div>
       
      <div class="row">
         <div class="col-lg-3">
          <span translate>page.inventaire-verger.distanceInterRang</span>
          <span>: {{uniteCulturale?.distanceInterRang}}m</span>
        </div>
        <div class="col-lg-3">
          <span translate>page.inventaire-verger.distanceIntraRang</span>
          <span>: {{uniteCulturale?.distanceIntraRang}}m</span>
        </div>
      </div>
      </div>
      <hr>
      <h5 class="sub-popup-title">Botanique</h5>
      <div class="row">
        <div class="col-lg-12 row">
          <div class="col-lg-3">
            <label translate>page.inventaire-verger.modals.division.nouvelleSurface</label>
          </div>
          <div class="col-lg-9">
            <input [ngClass]="{'is-invalid': form.submitted && nouvelleSurface.invalid}"  type="number" formControlName="nouvelleSurface" min="0" value="0" class="form-control">
            <ng-container *ngIf="form.submitted || form.touched">
              <div *ngIf="nouvelleSurface.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-lg-12 text-center">
          
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" style="background:#41905c;border:1px solid #41905c"
                data-dismiss="modal" aria-label="Close"translate>page.inventaire-verger.buttonEnregistrer
        </button>
      </div>
    </form>
  </div>
</div>
