import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { MonCompte } from '../../models/mon-compte.model';

@Injectable({
  providedIn: 'root'
})
export class MonCompteService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
    ) { }

    getMonCompte(idIntervenant: number): Observable<MonCompte> {
      return this.http.get<object>(`/api/intervenant/private/utilisateurs/${idIntervenant}/mon-compte`)
        .pipe(
          map(monCompte => this.mapper.deserializeObject(monCompte, MonCompte))
        );
    }

}