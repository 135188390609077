import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class RefTypeBalise {
    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('code', String)
    code: string;

    @JsonProperty('libelle', String)
    libelle: string;
}
