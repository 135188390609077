<div class="anpp content">
  <div class="content-panel" *ngIf="contact">
    <div class="title-bar">


      <h4>
        {{ title | translate:contact }}
      </h4>
    </div>

    <form *ngIf="formGroup" [formGroup]="formGroup" #form="ngForm" (submit)="submit()" novalidate>

      <div class="m-3">
        <div class="row">
          <div class="form-group col-lg-3">
            <label for="civilite" translate>page.contacts.form.label.civilite</label>
            <select id="civilite" formControlName="civilite" class="form-control"
              [ngClass]="{'is-invalid': form.submitted && civilite.invalid}">
              <option>Madame</option>
              <option>Monsieur</option>
            </select>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="civilite.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group col-lg-5">
            <label for="type" translate>page.contacts.form.label.nom</label>

            <input type="text" id="nom" formControlName="nom" [ngClass]="{'is-invalid': form.submitted && nom.invalid}"
              class="form-control" />

            <ng-container *ngIf="form.submitted">
              <div *ngIf="nom.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group col-lg-4">
            <label for="prenom" translate>page.contacts.form.label.prenom</label>

            <input type="text" id="prenom" formControlName="prenom"
              [ngClass]="{'is-invalid': form.submitted && prenom.invalid}" class="form-control" />

            <ng-container *ngIf="form.submitted">
              <div *ngIf="prenom.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-8">
            <label for="entreprise" translate>page.contacts.form.label.entreprise</label>
            <select id="entreprise" formControlName="entreprise" class="form-control"
              [ngClass]="{'is-invalid': form.submitted && civilite.invalid}">
              <option *ngFor="let entreprise of entreprises" [ngValue]="entreprise">{{entreprise.raisonSociale}}
              </option>
            </select>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="entreprise.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group col-lg-4">
            <label for="fonction" translate>page.contacts.form.label.fonction</label>
            <select id="fonction" formControlName="fonction" class="form-control">
              <option [ngValue]="undefined"></option>
              <option *ngFor="let fonction of fonctions" [ngValue]="fonction">{{fonction.libelle}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-6">
            <label for="telephonefixe" translate>page.contacts.form.label.telephonefixe</label>
            <input type="text" id="telephonefixe" formControlName="telephonefixe"
              [ngClass]="{'is-invalid': form.submitted && telephonefixe.invalid}" class="form-control" />
            <ng-container *ngIf="form.submitted">
              <div *ngIf="telephonefixe.errors?.required" class="form-error" translate>label.obligatoire</div>
              <div *ngIf="telephonefixe.errors?.pattern" class="form-error" translate>label.format-incorrect</div>
            </ng-container>
          </div>
          <div class="form-group col-lg-6">
            <label for="telephoneportable" translate>page.contacts.form.label.telephoneportable</label>
            <input type="text" id="telephoneportable" formControlName="telephoneportable"
              [ngClass]="{'is-invalid': form.submitted && telephoneportable.invalid}" class="form-control" />
            <ng-container *ngIf="form.submitted">
              <div *ngIf="telephoneportable.errors?.required" class="form-error" translate>label.obligatoire</div>
              <div *ngIf="telephoneportable.errors?.pattern" class="form-error" translate>label.format-incorrect</div>
            </ng-container>
          </div>
        </div>
        <div class="form-group">
          <label for="groupes" translate>page.contacts.form.label.groupe</label>
          <app-multiselect id="groupes" controlName="groupes" [items]="groupes" bindLabel="libelle" maxWidth="initial"
            [ngClass]="{ 'is-invalid': form.submitted && groupes.invalid }">
          </app-multiselect>
        </div>
        <div class="row">
          <div class="form-group col-lg-2">
            <label for="estintervenant" translate>page.contacts.form.label.estintervenant</label>
            <div class="form-group">
              <input type="radio" class="mr-1" id="oui" formControlName="estintervenant" [value]="true" />
              <label for="oui">Oui</label>
              <input type="radio" class="ml-5 mr-1" id="non" formControlName="estintervenant" [value]="false" />
              <label for="non">Non</label>
            </div>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="estintervenant.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group col-lg-3">
            <label for="mail" translate>page.contacts.form.label.dateConnexion</label>
            <div>
              {{contact.dateDerniereConnexion | date:'EEEE dd MMMM yyyy' }}
            </div>
          </div>
          <div class="form-group col-lg-7">
            <label for="referents" translate>page.contacts.form.label.referent</label>
            <app-multiselect id="referents" controlName="referents" [items]="referents" bindLabel="libelle"
              maxWidth="initial" [ngClass]="{ 'is-invalid': form.submitted && referents.invalid }">
            </app-multiselect>
          </div>
        </div>
        <div class="form-group">
          <label for="mail" translate>page.contacts.form.label.mail</label>
          <input type="text" id="mail" formControlName="mail" [ngClass]="{'is-invalid': form.submitted && mail.invalid}"
            class="form-control" />
          <ng-container *ngIf="form.submitted">
            <div *ngIf="mail.errors?.required" class="form-error" translate>label.obligatoire</div>
            <div *ngIf="mail.errors?.pattern" class="form-error" translate>label.format-incorrect</div>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="adresse" translate>page.contacts.form.label.adresse</label>
          <input type="text" id="adresse" formControlName="adresse"
            [ngClass]="{'is-invalid': form.submitted && adresse.invalid}" class="form-control" />
          <ng-container *ngIf="form.submitted">
            <div *ngIf="adresse.errors?.required" class="form-error" translate>label.obligatoire</div>
          </ng-container>
        </div>
        <div class="row">
          <div class="form-group col-lg-3">
            <label for="codepostal" translate>page.contacts.form.label.codepostal</label>
            <input (keyup)="changeValueCodePostal($event)" type="text" id="codepostal" formControlName="codepostal"
              [ngClass]="{'is-invalid': form.submitted && codepostal.invalid || (communes && communes.length === 0)}" class="form-control" />
            <ng-container *ngIf="form.submitted">
              <div *ngIf="codepostal.errors?.required" class="form-error" translate>label.obligatoire</div>
              
            </ng-container>
            <ng-container >
              <div *ngIf="communes && communes.length === 0" class="form-error" translate>Aucune commune avec ce code postal</div>
            </ng-container>
          </div>
          <div class="form-group col-lg-9">
            <label for="commune" translate>page.contacts.form.label.commune</label>
            <select formControlName="commune" class="form-control"
              [ngClass]="{'is-invalid': form.submitted && commune.invalid || (communes && communes.length === 0)}">
              <option *ngFor="let commune of communes" [ngValue]="commune">{{commune.nom}}</option>
            </select>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="commune.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="actions-bar" fxLayoutAlign="space-between">
        <button type="button" class="btn btn-primary btnprimary" (click)="onCancel()" translate>label.retour</button>
        <button type="submit" class="btn btn-primary btnprimary" translate>{{!creation
          ? 'label.suivant': 'label.valider'}}</button>
      </div>
    </form>




  </div>
</div>