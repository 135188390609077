import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CalendarDateFormatter, CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';

import * as moment from 'moment';
import { TourneeAgenda } from 'src/app/data/tournee/models/tournee-agenda.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, forkJoin } from 'rxjs';
import { TourneesService } from 'src/app/data/tournee/services/tournees.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { Animateur } from 'src/app/data/commission/models/animateur.model';
import { AnimateursService } from 'src/app/data/commission/services/animateurs/animateurs.service';
import { CustomDateFormatter } from '../../../commissions/pages/planning-commissions/CustomDateFormatter.provider';

const colors: any = {
  red: { primary: '#ad2121', secondary: '#FAE3E3' },
  blue: { primary: '#1e90ff', secondary: '#D1E8FF' },
  yellow: { primary: '#FFFB00', secondary: '#FFFEBF' },
  orange: { primary: '#FFA500', secondary: '#FFE5B5' }
};

moment.updateLocale('fr', {
  week: {
    dow: DAYS_OF_WEEK.MONDAY,
    doy: 0
  }
});

@Component({
  selector: 'app-planning-tournees',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './planning-tournees.component.html',
  styleUrls: ['./planning-tournees.component.scss'],
  providers: [{ provide: CalendarDateFormatter, useClass: CustomDateFormatter }]
})
export class PlanningTourneesComponent implements OnInit {
  maxEventsMonthView = 3;
  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  events: CalendarEvent<{ tournee: TourneeAgenda }>[] = [];
  activeDayIsOpen = false;
  dateDebut: moment.Moment = moment().startOf('week');
  dateFin: moment.Moment = moment().endOf('week');
  locale = 'fr_FR';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  actions: CalendarEventAction[] = [{
    label: 'Editer  ',
    onClick: ({ event }: { event: CalendarEvent }): void => {
      this.handleEvent('Edited', event);
    }
  }, {
    label: '  Supprimer',
    onClick: ({ event }: { event: CalendarEvent }): void => {
      this.handleEvent('Deleted', event);
    }
  }];
  techniciens: Animateur[];
  formFiltre: FormGroup;

  get listeEvents() {
    const filtreAnimateur = this.formFiltre.get('technicien').value;
    if (filtreAnimateur === null) {
      return this.events;
    } else {
      return this.events.filter(evt => evt.meta.tournee.technicien.id === filtreAnimateur.id);
    }
  }

  constructor(
    private readonly router: Router,
    private readonly tourneesService: TourneesService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly animateursService: AnimateursService,
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService
  ) { }

  ngOnInit() {
    this.formFiltre = this.fb.group({
      technicien: [undefined]
    });
    this.formFiltre.get('technicien').valueChanges.subscribe(technicien => {  this.refresh.next(); });

    this.loadData();
  }

  loadData() {
    this.events = [];
    forkJoin(
      this.tourneesService.getAgendaTournees(SessionContext.get('idOrganisme'), this.dateDebut, this.dateFin),
      this.animateursService.getAnimateurs(SessionContext.get('idOrganisme'))
    ).pipe(
      this.loaderService.operator()
    ).subscribe(([tournees, animateurs]) => {
      this.techniciens = animateurs;
      let titre = '';
      tournees.forEach((tournee: TourneeAgenda) => {
        titre = `<b>${this.translate.instant('page.tournees.planning.numero')} : ${tournee.numero}</b><br />`;
        titre += (this.view === CalendarView.Month) ? `${this.translate.instant('page.tournees.planning.horaire')} : ${tournee.debut.format('HH:mm')} - ${tournee.fin.format('HH:mm')}<br />` : '';
        titre += `- ${this.translate.instant('page.tournees.planning.technicien')} : ${tournee.technicien.prenom} ${tournee.technicien.nom}<br />
                    - ${this.translate.instant('page.tournees.planning.echantillons')} : ${tournee.nbEchantillons}, ${this.translate.instant('page.tournees.planning.operateurs')} : ${tournee.nbOperateurs}`;
        this.events.push({
          title: titre,
          start: tournee.debut.toDate(),
          end: tournee.fin.toDate(),
          resizable: { beforeStart: false, afterEnd: false },
          draggable: false,
          color: tournee.terminee ? colors.orange : colors.yellow,
          actions: this.actions,
          meta: { tournee }
        });
      });
      this.refresh.next();
    });
  }

  compareTechniciens(a: Animateur, b: Animateur) {
    return a && b ? a.id === b.id : a === b;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    const dateJour = moment([date.getFullYear(), date.getMonth(), date.getDate()]);
    const dateVue = moment([this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate()]);
    if (dateVue.isSame(dateJour, 'month')) {
      if (
        (dateVue.isSame(dateJour, 'day') && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    if (action === 'Edited') {
      this.router.navigate([`main/controles/tournees/${event.meta.tournee.id}/edit`]);
    } else if (action === 'Deleted') {
      if(event.meta.tournee.nbEchantillons > 0 || 
        event.meta.tournee.nbJuresTotal > 0 || 
        event.meta.tournee.nbJuresInscrits > 0 || 
        event.meta.tournee.nbJuresPresents > 0) {
          this.modalsService.alert(this.translate.instant('page.tournees.planning.removeDisable'));
      } else {
        this.modalsService.confirm(
          `${this.translate.instant('page.tournees.planning.confirm_remove.message')} ${event.meta.tournee.numero} ?`,
          this.translate.instant('page.tournees.planning.confirm_remove.title'),
          { cancelText: this.translate.instant(`label.annuler`), submitText: this.translate.instant(`label.valider`) }
        ).then(() => {
          this.tourneesService.deleteTournee(SessionContext.get('idOrganisme'), event.meta.tournee)
          .subscribe(() => {
            this.deleteEvent(event);
            this.refresh.next();
            this.toastr.success(
              this.translate.instant('page.tournees.edit.alert.suppression.message', { numero: event.meta.tournee.numero }),
              this.translate.instant('page.tournees.edit.alert.suppression.title'),
              { timeOut: 10 * 1000 }
            );
          });
        });
      }
    }
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
    this.refreshData();
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    this.refreshData();
  }

  refreshDatesIntervalle() {
    switch (this.view) {
      case CalendarView.Month:
        this.dateDebut = moment([this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate()]).startOf('month');
        this.dateFin = moment([this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate()]).endOf('month');
        break;
      case CalendarView.Week:
        this.dateDebut = moment([this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate()]).startOf('week');
        this.dateFin = moment([this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate()]).endOf('week');
        break;
      default:
        this.dateDebut = moment([this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate()]);
        this.dateFin = moment([this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate()]);
    }
  }

  refreshData() {
    this.refreshDatesIntervalle();
    this.loadData();
  }

}
