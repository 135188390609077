import { CustomConverter, JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { DateConverter, DateTimeConverter } from 'src/app/core/services/mapper/converters';
import { CodeEspece } from './enums/code-espece.enum';

@JsonObject
export class Recolte {

    @JsonProperty('idRecolte', Number)
    @Optional
    id: number = undefined;

    @JsonProperty('idEspece', Number)
    @Optional
    public idEspece: number = null;
    @JsonProperty('codeEspece', String)
    public codeEspece: string = null;

    @JsonProperty('idGroupeVarietal', Number)
    @Optional
    idGroupeVarietal: number = null;
    @JsonProperty('codeGroupeVarietal', String)
    @Optional
    codeGroupeVarietal: string;
    @JsonProperty('groupeVarietal', String)
    @Optional
    groupeVarietal: string = null;
    @JsonProperty('groupeVarietalVarietes', String)
    @Optional
    groupeVarietalVarietes: string;


    @JsonProperty('qteTotalePrecedente', Number)
    @Optional
    qteTotalePrecedente: number = null;
    @JsonProperty('qteBioPrecedente', Number)
    @Optional
    qteBioPrecedente: number = null;

    @JsonProperty('qteTotaleDernierePrevision', Number)
    @Optional
    qteTotaleDernierePrevision: number = null;
    @JsonProperty('qteBioDernierePrevision', Number)
    @Optional
    qteBioDernierePrevision: number = null;

    @JsonProperty('qteTotale', Number)
    @Optional
    qteTotale: number = null;
    @JsonProperty('qteBio', Number)
    @Optional
    qteBio: number = null;

    @JsonProperty('surfaceTotale', Number)
    @Optional
    surfaceTotale: number = null;

    @JsonProperty('surfaceBio', Number)
    @Optional
    surfaceBio: number = null;    
    
    @JsonProperty('datePrevisionInitiale', String)
    @CustomConverter(DateTimeConverter)
    @Optional
    datePrevisionInitiale: moment.Moment;

    @JsonProperty('datePrevisionActuelle', String)
    @CustomConverter(DateTimeConverter)
    @Optional
    datePrevisionActuelle: moment.Moment;

    @JsonProperty('dateSaisieRecolte', String)
    @CustomConverter(DateTimeConverter)
    @Optional
    dateSaisieRecolte: moment.Moment;

    public getRdtTotalPrecedent(): number {
        return this.surfaceTotale ? this.qteTotalePrecedente / this.surfaceTotale : undefined;
    }
    public getRdtBioPrecedent(): number {
        return this.surfaceBio ? this.qteBioPrecedente / this.surfaceBio : undefined;
    }

    @JsonProperty('previsionInitialeTotale', Number)
    @Optional
    previsionInitialeTotale: number = null;
    @JsonProperty('previsionInitialeBio', Number)
    @Optional
    previsionInitialeBio: number = null;

    getRdtInitialTotal(): number {
        return this.surfaceTotale ? this.previsionInitialeTotale / this.surfaceTotale : undefined;
    }

    public getRdtInitialBio(): number {
        return this.surfaceBio ? this.previsionInitialeBio / this.surfaceBio : undefined;
    }


    public getRdtTotalDernierePrevision(): number {
        return this.surfaceTotale ? this.qteTotaleDernierePrevision / this.surfaceTotale : undefined;
    }
    public getRdtBioDernierePrevision(): number {
        return this.surfaceBio ? this.qteBioDernierePrevision / this.surfaceBio : undefined;
    }

    public getRdtTotal(): number {
        return this.surfaceTotale ? this.qteTotale / this.surfaceTotale : undefined;
    }

    public getRdtTotalSuiviRecolte(declarationDefinitive: boolean): number {
        if(declarationDefinitive) {
            return this.surfaceTotale ? this.qteTotale / this.surfaceTotale : undefined;
        } else {
            return this.surfaceTotale ? this.qteTotaleDernierePrevision / this.surfaceTotale : undefined;
        }
    }

    public getQteTotaleSuiviRecolte(declarationDefinitive: boolean): number {
        return declarationDefinitive ? this.qteTotale : this.qteTotaleDernierePrevision;
    }


    public getRdtTotalSaisi(): number {
        return this.surfaceTotale ? this.qteTotale / this.surfaceTotale : undefined;
    }
    public getRdtBioSaisi(): number {
        return this.surfaceBio ? this.qteBio / this.surfaceBio : undefined;
    }

    public getNbJoursDepuisPrevision(): number {
      let nbJours: number;
      if (this.datePrevisionActuelle != null) {
        const dateNow = moment();
        nbJours = dateNow.diff(this.datePrevisionActuelle, 'days');
      }
      return nbJours;
    }

    isTotal: boolean = false;
    isAutre(): boolean {
        return ['autVarpomme', 'autVarpoir','autVarpoire'].indexOf(this.codeGroupeVarietal) > -1
    };

    sorting(): string {
        var x =
            ''.concat(
                this.codeEspece === CodeEspece.POMME ? '0' : (this.codeEspece === CodeEspece.POIRE ? '1' : '2'),
                this.isTotal ? '1' : '0',
                this.isAutre() ? '1' : '0',
                this.groupeVarietal.toUpperCase());
        return x;
    }
}