<div>
  <div class="modal-header">
    <h4 class="modal-title">Manquements</h4>
  </div>

  <!-- Corps de la fenêtre surgissante -->
  <div class="modal-body tab-container">
    <ng-container *ngIf="numerosManquements; else syntheseConstats">
      <span translate>page.controles.manquement.constat.modal.numeroManquement</span> :
      <ul>
        <li *ngFor="let numero of numerosManquements">{{ numero }}</li>
      </ul>
    </ng-container>
  </div>
  <!-- Fin corps de la fenêtre surgissante -->

  <div class="actions-bar" *ngIf="numerosManquements">
    <button type="button" class="btn btn-light" (click)="onClose(true)" translate>label.fermer</button>
  </div>
  <div class="actions-bar" *ngIf="!numerosManquements">
    <button type="button" class="btn btn-light" (click)="onClose()" translate>label.annuler</button>
    <button type="button" class="btn btn-primary ml-2" (click)="onAddSaisie()" translate>
      page.controles.manquement.constat.modal.form.ajouter
    </button>
    <button type="button" class="btn btn-primary ml-2" (click)="onSubmit()" translate>label.valider</button>
  </div>
</div>

<ng-template #syntheseConstats>
  <!-- Tableau des manquements -->
  <table class="table">
    <thead>
      <th translate>page.controles.manquement.constat.modal.form.cdc</th>
      <th translate>page.controles.manquement.constat.modal.form.produit</th>
      <th translate>page.controles.manquement.constat.modal.form.pointAMaitriser</th>
      <th translate>page.controles.manquement.constat.modal.form.manquementConstate</th>
      <th translate>page.controles.manquement.constat.modal.form.intensite</th>
      <th translate>page.controles.manquement.constat.modal.form.gravite</th>
      <th translate>page.controles.manquement.constat.modal.form.sanction</th>
      <th translate>page.controles.manquement.constat.modal.form.commentaire</th>
      <th></th>
    </thead>

    <tbody>
      <tr *ngFor="let manquement of manquements; let i = index">
        <td>{{ manquement.constat?.cahierDesCharges?.libelle }}</td>
        <td>{{ manquement.constat?.produit?.libelle }}</td>
        <td>{{ manquement.constat?.pointAMaitriser?.libelle }}</td>
        <td>{{ manquement.constat?.manquementConstate?.libelle }}</td>
        <td>{{ manquement.constat?.intensite }}</td>
        <td>{{ manquement.constat?.gravite }}</td>
        <td>{{ manquement.constat?.sanctionEncourue?.libelle }}</td>
        <td>{{ manquement.constat?.commentaire }}</td>
        <td>
          <fa-icon icon="edit" class="pointer" title="Modifier" (click)="onEdit(i)"></fa-icon>
          <fa-icon icon="trash" class="pointer ml-2" title="Supprimer" (click)="onDelete(i)" 
            [ngClass]="{ 'disabled': manquements.length < 2 }">
          </fa-icon>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- Fin tableau des manquements -->
</ng-template>