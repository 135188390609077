<div class="border-radius-onglet anpp">
  <div class="border-radius-onglet" *ngIf="!entrepriseId">
    <span translate><fa-icon icon="exclamation" class="mt-2 p-2"></fa-icon> page.declarations-stocks.no_adherent_selectionne</span>
  </div>
  <form [formGroup]="formGroup" #form="ngForm" *ngIf="entrepriseId">
    <div class="row tableau">
      <div class="col-lg-12">
        <div class="cards  border-radius-onglet text-center" style="background:#fff">
          <div class="row">
            <div class="col-lg-12 text-left pl-4 pt-4">

              <span style="display: inline-block; width: 260px;">
                <span *ngIf="!moisHorsLimites" (click)="ajouterGroupeVarietal()" class="pt-2 pl-2 mr-2 lienMain" type="button">
                  <fa-icon class="mr-1" icon='plus-square'></fa-icon>
                  <span style="text-decoration:underline" translate>page.declarations-stocks.ajoutGrpVarietal</span>
                </span>
              </span>

              <span>{{this.campagne.libelle}}</span>
              <span translate>page.declarations-stocks.labelStockAuPremier</span>
              <fa-icon (click)="reculerMois()" icon="chevron-left" class="lienMain" [ngClass]="{'chevronDisabled' : isFirstMonth()}"></fa-icon>
              <select formControlName="selectionMois" class="form-control" (change)="onMoisChange()"
                      style="display:inline; width:auto">
                <option [value]="null" hidden></option>
                <option *ngFor="let leMois of mois" [ngValue]="leMois" [selected]="leMois === numMois">
                  {{getLibelleMois(leMois)}}</option>
              </select>
              <fa-icon (click)="avancerMois()" icon="chevron-right" class="lienMain" [ngClass]="{'chevronDisabled' : isLastMonth()}"></fa-icon>

              <button *ngIf="numMoisCourant === numMois" type="button" (click)="stockAZero()" [disabled]="stocks.length === 0 || moisHorsLimites" style="background:#41905c;border:1px solid #41905c"
                      class="btn btn-sm btn-secondary  btnprimary" translate>page.declarations-stocks.stockAZeroBtn</button>
            </div>

          </div>
          <div class="tableScroll">
          <table class="table table-striped mt-2">
            <thead class="center sticky-top">
            <tr>
              <!--icone-->
              <th scope="col"> </th>
              <!--groupe varietal-->
              <th *ngIf="show('groupeVarietal');"scope="col" class="text-left bar1">
                <div ><span translate>page.declarations-stocks.table.header.groupeVarietal</span></div>
              </th>
              <th *ngIf="show('stockCampPrec');" scope="col" colspan="2" class="bar1"> <span
                translate>page.declarations-stocks.table.header.stockCampPrec</span></th>
              <th *ngIf="show('stockMoisPrec');" scope="col" colspan="2" class="bar1"> <span
                translate>page.declarations-stocks.table.header.stockMoisPrec</span></th>
              <th *ngIf="show('stockMoisCourant');" scope="col" colspan="2" class="bar1"> <span
                translate>page.declarations-stocks.table.header.stockMoisCourant</span></th>
              <th *ngIf="show('recolte');" scope="col" colspan="2" class="bar1"> <span
                translate>page.declarations-stocks.table.header.recolte</span></th>
              <th *ngIf="show('stockMoisSuiv');" scope="col" colspan="2" class="bar1"> <span
                translate>page.declarations-stocks.table.header.stockMoisSuiv</span></th>
              <th *ngIf="show('varRecolte');" scope="col" colspan="2" class="bar1"> <span
                translate>page.declarations-stocks.table.header.varRecolte</span></th>
              <th *ngIf="show('varMoisPrec');" scope="col" colspan="2" class="bar1"> <span
                translate>page.declarations-stocks.table.header.varMoisPrec</span></th>
              <th>
                <fa-icon class="clickable-icon" icon="ellipsis-h" (click)="afficherListe()"></fa-icon>
              </th>
            </tr>
            <tr>
              <!--icone-->
              <th scope="col" style="position:relative; width:60px;"></th>
              <!--groupe varietal-->
              <th scope="col" style="position:relative"></th>
              <!-- Stock campagne precedente -->
              <th *ngIf="show('stockCampPrec');"scope="col" class="center" ><small><strong translate>page.declarations-stocks.table.header.qteTotale</strong></small></th>
              <th *ngIf="show('stockCampPrec');" scope="col" class="center" ><small><strong translate>page.declarations-stocks.table.header.qteBio</strong></small></th>
              <!-- Stock mois precedent -->
              <th *ngIf="show('stockMoisPrec');" scope="col" class="center" ><small><strong translate>page.declarations-stocks.table.header.qteTotale</strong></small></th>
              <th *ngIf="show('stockMoisPrec');" scope="col" class="center" ><small><strong translate>page.declarations-stocks.table.header.qteBio</strong></small></th>
              <!-- Stock mois courant -->
              <th *ngIf="show('stockMoisCourant');" scope="col" class="center" ><small><strong translate>page.declarations-stocks.table.header.qteTotale</strong></small></th>
              <th *ngIf="show('stockMoisCourant');" scope="col" class="center" ><small><strong translate>page.declarations-stocks.table.header.qteBio</strong></small></th>
              <!-- Recolte -->
              <th *ngIf="show('recolte');" scope="col" class="center" ><small><strong translate>page.declarations-stocks.table.header.qteTotale</strong></small></th>
              <th *ngIf="show('recolte');" scope="col" class="center" ><small><strong translate>page.declarations-stocks.table.header.qteBio</strong></small></th>
              <!-- Stock mois suivant-->
              <th *ngIf="show('stockMoisSuiv');" scope="col" class="center" ><small><strong translate>page.declarations-stocks.table.header.qteTotale</strong></small></th>
              <th *ngIf="show('stockMoisSuiv');" scope="col" class="center" ><small><strong translate>page.declarations-stocks.table.header.qteBio</strong></small></th>
              <!-- Variation depuis la recolte-->
              <th *ngIf="show('varRecolte');" scope="col" class="center" ><small><strong translate>page.declarations-stocks.table.header.qteTotale</strong></small></th>
              <th *ngIf="show('varRecolte');" scope="col" class="center" ><small><strong translate>page.declarations-stocks.table.header.qteBio</strong></small></th>
              <!-- Variation depuis le mois precedent-->
              <th *ngIf="show('varMoisPrec');" scope="col" class="center" ><small><strong translate>page.declarations-stocks.table.header.qteTotale</strong></small></th>
              <th *ngIf="show('varMoisPrec');" scope="col" class="center" ><small><strong translate>page.declarations-stocks.table.header.qteBio</strong></small></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="stocks.length === 0">
              <td colspan="99" class="p-4">
                <fa-icon icon="exclamation-triangle"></fa-icon>&nbsp;
                <span translate>page.declarations-stocks.no_stock</span>
              </td>
            </tr>
            <tr *ngFor="let stock of stocks ; let i = index"
              [ngClass]="{'separation-pomme-poire' : stock.isTotal && stock.codeEspece === 'POMME', 'font-weight-bold' : stock.isTotal }">
              <!-- icone pomme/poire -->
              <td>
                  <span *ngIf="stock.codeEspece==='POMME'&& show('groupeVarietal')">
                    <fa-icon icon='apple-alt' style="color:black;"></fa-icon>
                    <fa-icon *ngIf="stock.isTotal" icon='apple-alt' style="color:black;"></fa-icon>
                  </span>
                <span *ngIf="stock.codeEspece==='POIRE' && show('groupeVarietal')" >
                    <img src="/assets/img/pear.png" width="16px" />
                    <img *ngIf="stock.isTotal" src="/assets/img/pear.png" width="16px" />
                  </span>
              </td>
              <td *ngIf="show('groupeVarietal');" scope="col" class="text-left">
                <span class="souligne" placement="top"><b>{{stock.groupeVarietal}}</b></span>
              </td>

              <!-- Stock campagne precedente -->
              <td *ngIf="show('stockCampPrec');" scope="col" class="center" style="width:90px; text-align: center !important;">
                {{stock.stockTotalCampPrec == null  ? '-' : stock.stockTotalCampPrec}}
              </td>
              <td *ngIf="show('stockCampPrec');" scope="col" class="center" style="width:90px; text-align: center !important;">
                {{stock.stockBioCampPrec == null  ? '-' : stock.stockBioCampPrec}}
              </td>

              <!-- Stock mois precedent -->
              <td *ngIf="show('stockMoisPrec');" scope="col" class="center" style="width:90px; text-align: center !important;">
                {{stock.stockTotalMoisPrec == null  ? '-' : stock.stockTotalMoisPrec}}
              </td>
              <td *ngIf="show('stockMoisPrec');" scope="col" class="center" style="width:90px; text-align: center !important;">
                {{stock.stockBioMoisPrec == null  ? '-' : stock.stockBioMoisPrec}}
              </td>

              <!-- Stock mois courant -->
              <td *ngIf="show('stockMoisCourant') && (numMoisCourant === numMois || estAnpp) && !stock.isTotal;" scope="col" class="center">
                <input [disabled]="moisHorsLimites" type="number" class="form-control" placeholder="{{ 'page.declarations-stocks.table.header.qteTotale' | translate }}" style="width:90px; text-align: center !important;"
                       [ngModelOptions]="{standalone: true}" [(ngModel)]="stock.stockTotalMoisCourant" min="0" max="10000" (keypress)="keyPressNumbers($event)"
                       name="stockTotalMoisCourant{{i}}" />
              </td>
              <td *ngIf="show('stockMoisCourant') && (numMoisCourant === numMois || estAnpp) && !stock.isTotal;" scope="col" class="center">
                <input [disabled]="moisHorsLimites" type="number" class="form-control" placeholder="{{ 'page.declarations-stocks.table.header.qteBio' | translate }}" style="width:90px; text-align: center !important;"
                       [ngModelOptions]="{standalone: true}" [(ngModel)]="stock.stockBioMoisCourant" min="0" max="10000" (keypress)="keyPressNumbers($event)"
                       name="stockBioMoisCourant{{i}}" />
              </td>
              <td *ngIf="show('stockMoisCourant') && (numMoisCourant === numMois && !estAnpp || stock.isTotal);" scope="col" class="center" style="width:90px; text-align: center !important;">
                {{stock.stockTotalMoisCourant == null  ? '-' : stock.stockTotalMoisCourant}}
              </td>
              <td *ngIf="show('stockMoisCourant') && (numMoisCourant === numMois && !estAnpp || stock.isTotal);" scope="col" class="center" style="width:90px; text-align: center !important;">
                {{stock.stockBioMoisCourant == null  ? '-' : stock.stockBioMoisCourant}}
              </td>
              <!-- Recolte -->
              <td *ngIf="show('recolte');" scope="col" class="center" style="width:90px; text-align: center !important;">
                {{stock.stockTotalRecolte == null  ? '-' : stock.stockTotalRecolte}}
              </td>
              <td *ngIf="show('recolte');" scope="col" class="center" style="width:90px; text-align: center !important;">
                {{stock.stockBioRecolte == null  ? '-' : stock.stockBioRecolte}}
              </td>
              <!-- Stock mois suivant-->
              <td *ngIf="show('stockMoisSuiv');" scope="col" class="center" style="width:90px; text-align: center !important;">
                {{stock.stockTotalMoisSuiv == null  ? '-' : stock.stockTotalMoisSuiv}}
              </td>
              <td *ngIf="show('stockMoisSuiv');" scope="col" class="center" style="width:90px; text-align: center !important;">
                {{stock.stockBioMoisSuiv == null  ? '-' : stock.stockBioMoisSuiv}}
              </td>
              <!-- Variation depuis la recolte-->
              <td *ngIf="show('varRecolte');" scope="col" class="center" style="width:90px; text-align: center !important;">
                {{stock.stockTotalMoisCourant-stock.stockTotalRecolte}}
              </td>
              <td *ngIf="show('varRecolte');" scope="col" class="center" style="width:90px; text-align: center !important;">
                {{stock.stockBioMoisCourant-stock.stockBioRecolte}}
              </td>
              <!-- Variation depuis le mois precedent-->
              <td *ngIf="show('varMoisPrec');" scope="col" class="center" style="width:90px; text-align: center !important;">
                {{stock.stockTotalMoisCourant-stock.stockTotalMoisPrec}}
              </td>
              <td *ngIf="show('varMoisPrec');" scope="col" class="center" style="width:90px; text-align: center !important;">
                {{stock.stockBioMoisCourant-stock.stockBioMoisPrec}}
              </td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
        </div>
      </div>
      <p>&nbsp;</p>
    </div>
    <div style="position:fixed; bottom:10px;right:25px">
      <button *ngIf="(numMoisCourant === numMois || estAnpp) && !moisHorsLimites" type="button" #submitButton (click)="onSave()" style="background:#41905c;border:1px solid #41905c"
              class="btn btn-lg btn-secondary  btnprimary">Enregistrer</button>
    </div>
  </form>
</div>
