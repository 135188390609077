import { Component, OnInit } from "@angular/core";
import { Routes } from "@angular/router";
import { FormulairePiComponent } from "./pages/formulaires/formulaire-pi/formulaire-pi.component";
import { FormulaireGpComponent } from "./pages/formulaires/formulaire-gp/formulaire-gp.component";
import { VergersEcoresponsablesComponent } from "./pages/vergers-ecoresponsables/vergers-ecoresponsables.component";
@Component({
  selector: "app-engagements",
  templateUrl: "./engagements.component.html",
  styleUrls: ["./engagements.component.scss"],
})
export class EngagementsComponent implements OnInit {
  static routes: Routes = [
    {
      path: "",
      component: EngagementsComponent,
      data: {
        domaines: ["ANPP"],
        role: "HABILITATION_ENGAGEMENT_VER_LECTURE",
        menu: {
          icon: "medal",
          libelle: "menu.engagements",
        },
      },
      children: [
        { path: "", redirectTo: "vergers-ecoresponsables", pathMatch: "full" },
        {
          path: "vergers-ecoresponsables",
          children: VergersEcoresponsablesComponent.routes,
        },
        { path: "formulaire-pi", component: FormulairePiComponent },
        {
          path: "formulaire-pi/:id",
          component: FormulairePiComponent,
          data: { roles: ["ORGANISME"] },
        },
        { path: "formulaire-gp", component: FormulaireGpComponent },
        {
          path: "formulaire-gp/:id",
          component: FormulaireGpComponent,
          data: { roles: ["ORGANISME"] },
        },
      ],
    },
  ];

  constructor() {}

  ngOnInit() {}
}
