import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { ClearCache, Cache } from 'src/app/core/services/cache/cache.service';
import { ReferencesDroits } from '../../models/references.model';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';

@Injectable({
  providedIn: 'root'
})
export class ReferencesDroitsService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly refIntervenant: ReferencesService,
  ) { }

  @ClearCache
  clearCache() { }

  @Cache({ maxAge: 3600 })
  getReferences(): Observable<ReferencesDroits> {
    return forkJoin(
      this.http.get<object>(`/api/intervenant/private/references-droits`),
      this.refIntervenant.getReferences()
    ).pipe(
      map(([ref, refIntervenant]) => this.mapper.deserializeObject(ref, ReferencesDroits, refIntervenant))
    );
  }
}
