import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';
import { DeclarationDr } from './declaration-dr.model';
import { DeclarationSV11 } from './declaration-sv11.model';
import { DeclarationSV12 } from './declaration-sv12.model';

@JsonObject
export class DeclarationRecolte {
  @JsonProperty('drs', [DeclarationDr])
  drs: DeclarationDr[] = [];

  @JsonProperty('sv11s', [DeclarationSV11])
  sv11s: DeclarationSV11[] = [];

  @JsonProperty('sv12s', [DeclarationSV12])
  sv12s: DeclarationSV12[] = [];
}
