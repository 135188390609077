import { JsonProperty, JsonObject, Optional, CustomConverter } from '@thorolf/json-ts-mapper';

import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { RefModeCalcul } from './ref-mode-calcul.model';
import { RefQuantiteFacture } from './ref-quantite-facture.model';
import { RefTva } from './ref-tva.model';
import { Cahier } from '../../habilitation/models/cahier.model';

@JsonObject
export class ModeleArticle {
    @JsonProperty('id', Number)
    @Optional
    id: number;

    @JsonProperty('libelle', String)
    libelle: string;

    @JsonProperty('idRefModeCalcul', Number)
    @CustomConverter(DataIdConverterFactory.build(RefModeCalcul, 'refModeCalculList'))
    refModeCalcul: RefModeCalcul;

    @JsonProperty('idRefQuantiteFacture', Number)
    @CustomConverter(DataIdConverterFactory.build(RefQuantiteFacture, 'refQuantiteFactureList'))
    refQuantiteFacture: RefQuantiteFacture;

    @JsonProperty('idRefTva', Number)
    @CustomConverter(DataIdConverterFactory.build(RefTva, 'refTvaList'))
    refTva: RefTva;

    @JsonProperty('idCahiers', [Number])
    @CustomConverter(DataIdConverterFactory.build(Cahier, 'cahiers'))
    cahiers: Cahier[] = undefined;

    @JsonProperty('prixUnitaire', Number)
    prixUnitaire: number;

    @JsonProperty('prixMinimumHT', Number)
    prixMinimumHT: number;
}
