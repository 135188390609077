import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class NotificationUpload {

  @JsonProperty('idEntreprise', Number)
  @Optional
  public idEntreprise: number = undefined;

  @JsonProperty('idIntervenant', Number)
  @Optional
  public idIntervenant: number = undefined;

  @JsonProperty('codeRefSousType', String)
  @Optional
  public codeRefSousType: string = undefined;

  @JsonProperty('nomDocument', String)
  @Optional
  public nomDocument: string = undefined;
}