import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs/internal/Observable';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, tap, catchError, merge } from 'rxjs/operators';
import { GroupeVarietal } from 'src/app/data/declaration/models/groupe-varietal.model';
import { Router, ActivatedRoute } from '@angular/router';
import { DomainesService } from 'src/app/data/intervenant/services/domaines/domaines.service';
import { of, Subject } from 'rxjs';
import { GroupeVarietalService } from 'src/app/data/declaration/services/groupe-varietal/groupe-varietal.service';
import { VarieteService } from 'src/app/data/declaration/services/parcellaire/variete.service';
import { GroupeVarietalParam } from 'src/app/data/declaration/models/groupe-varietal-param.model';
import { CodeEspece } from 'src/app/data/declaration/models/enums/code-espece.enum';


@Component({
  selector: 'app-ajout-groupe-varietal',
  templateUrl: './ajout-groupe-varietal.component.html',
  styleUrls: ['./ajout-groupe-varietal.component.scss']
})
export class AjoutGroupeVarietalComponent implements OnInit {


  groupes: GroupeVarietalParam[];
  groupeFilter: GroupeVarietalParam;
  groupe: GroupeVarietal;

  getData(): Observable<Document[]> {
    throw new Error('Method not implemented.');
  }

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly domainesService: DomainesService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly groupeVarietalService: GroupeVarietalService,
    private readonly varietalService: VarieteService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  selectOperateur() {
    if (this.groupeFilter != null) { 
      const groupe = this.groupeFilter as GroupeVarietalParam;

      this.groupe = new GroupeVarietal();

      this.groupe.id = groupe.id;
      this.groupe.libelle = groupe.libelle;

      if(groupe.varietes.length>0){
         this.varietalService.getVariete(groupe.varietes[0].idVariete).subscribe(variete => {
          this.groupe.codeEspece =  CodeEspece[variete.espece.code] ;
          this.modal.close(this.groupe);
        });
      }
      else this.modal.close(this.groupe);
    }
  }

  ngOnInit() { 

    this.groupeVarietalService.getGroupeVarietaux()
    .pipe(this.loaderService.operator())
    .subscribe(groupes => {
      this.groupes = groupes;
    });

  }

  save() {
    this.modal.close('definitive');
  }

  onClose() {
    this.modal.dismiss();
  }

    
}

