import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin, of } from 'rxjs';
import { RefCampagnesService } from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';
import { EngagementVergersEcoService } from 'src/app/data/habilitation/services/engagements/engagement-vergers-eco.service';
import { EntrepriseBase } from 'src/app/data/intervenant/models/entreprise-base.model';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { Structure } from 'src/app/data/intervenant/models/structure.model';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { RefSousTypesService } from 'src/app/data/intervenant/services/ref-sous-types/ref-sous-types.service';
import { Commune } from 'src/app/shared/models/commune.model';
import { Departement } from 'src/app/shared/models/departement.model';
import { CommuneService } from 'src/app/shared/services/dep-commune/commune.service';
import { DepartementService } from 'src/app/shared/services/dep-commune/departement.service';
import { RefSoustypeUtilisateur } from 'src/app/data/droits/models/ref-soustype-utilisateur.model';

@Component({
  selector: 'app-edit-entreprise',
  templateUrl: './edit-entreprise.component.html',
  styleUrls: ['./edit-entreprise.component.scss']
})
export class EditEntrepriseComponent implements OnInit {

  @Input() public estOnglet = true;
  @Input() public id: number;
  public formGroup: FormGroup;
  public creation = false;
  public codeComptableExiste = false;
  public EntrepriseLieeRequise = false;
  public title: string;
  public entreprise: Entreprise;
  public entreprises: EntrepriseBase[];
  public refSousTypes: RefSoustypeUtilisateur[];
  public refStructures: Structure[];
  public departement: Departement;
  public communes: Commune[];

  public get raisonsociale() { return this.formGroup.get('raisonsociale'); }
  public get estadherent() { return this.formGroup.get('estadherent'); }
  public get numadherent() { return this.formGroup.get('numadherent'); }
  public get cotisationAJour() { return this.formGroup.get('cotisationAJour'); }
  public get dateAdhesion() { return this.formGroup.get('dateAdhesion'); }
  public get dateDemission() { return this.formGroup.get('dateDemission'); }
  public get estMorale() { return this.formGroup.get('estMorale'); }
  public get siret() { return this.formGroup.get('siret'); }
  public get numeroTVA() { return this.formGroup.get('numeroTVA'); }
  public get adresse() { return this.formGroup.get('adresse'); }
  public get codePostal() { return this.formGroup.get('codePostal'); }
  public get commune() { return this.formGroup.get('commune'); }
  public get telephone() { return this.formGroup.get('telephone'); }
  public get mail() { return this.formGroup.get('mail'); }
  public get refSousType() { return this.formGroup.get('refSousType'); }
  public get refStructure() { return this.formGroup.get('refStructure'); }
  public get numeroReconnaissance() { return this.formGroup.get('numeroReconnaissance'); }
  public get entrepriseLiee() { return this.formGroup.get('entrepriseLiee'); }
  public get cultureBio() { return this.formGroup.get('cultureBio'); }
  public get codeComptable() { return this.formGroup.get('codeComptable'); }
  public get commentaire() { return this.formGroup.get('commentaire'); }
  public get ecoresponsable() { return this.formGroup.get('ecoresponsable'); }

  constructor(
    private fb: FormBuilder,
    private loaderService: AdvBootstrapLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private entrepriseService: EntreprisesService,
    private refSousTypeservice: RefSousTypesService,
    private communeService: CommuneService,
    private departementService: DepartementService,
    private readonly engagementVergersEcoService: EngagementVergersEcoService,
    private readonly refCampagnesService: RefCampagnesService
  ) { }

  public ngOnInit(): void {
    if (!this.id) {
      this.creation = true;
    }

    this.loadData(this.id);
  }

  public changeValueCodePostal(event): void {
      const codePostal = event.target.value;
    if (codePostal.length === 5) {
      this.communeService.getCommunes(codePostal).pipe(this.loaderService.operator()).subscribe((communes) => {
        this.communes = communes;

        if (communes) {
          this.departementService.getDepartement(communes[0].codeDepartement).pipe(this.loaderService.operator()).subscribe((departements) => {
            this.departement = departements[0];
          });
        }
      });
    }
    else {
      this.communes = [];
    }


    this.formGroup.patchValue({ 'commune': undefined });
  }

  public changeValueCodeComptable(event): void {
    const codeComptable = event.target.value;

    if (codeComptable === this.entreprise.codeComptable) {
      this.codeComptableExiste = false;
    } else {
      this.entrepriseService.codeComptableExiste(codeComptable)
        .pipe(this.loaderService.operator())
        .subscribe((codeComptableExiste: boolean) => this.codeComptableExiste = codeComptableExiste);
    }
  }

  public numadherentValidatorsRequired(): void {
    this.formGroup.get(`numadherent`).setValidators(
      this.estadherent.value === false 
        ? null 
        : [Validators.required]
    );

    this.formGroup.get(`numadherent`).updateValueAndValidity();
  }

  public numeroReconnaissanceValidatorsRequired(): void {
    if (this.refSousType.value !== undefined) {
      this.formGroup.get(`numeroReconnaissance`).setValidators(
        this.refSousType.value.code !== "GP_DEFAUT" 
          ? null 
          : [Validators.required]
      );
    }

    this.formGroup.get(`numeroReconnaissance`).updateValueAndValidity();
  }

  public entrepriseLieeValidatorsRequired(): void {
    this.EntrepriseLieeRequise = false;

    if (this.refSousType.value !== undefined) {
      this.formGroup.get(`entrepriseLiee`).clearValidators();

      if (this.refSousType.value.code === "PO_DEFAUT" || this.refSousType.value.code === "SC_DEFAUT") {
        this.formGroup.get(`entrepriseLiee`).setValidators(Validators.required);
        this.formGroup.get(`entrepriseLiee`).enable();
        if(!this.entrepriseLiee.value)this.EntrepriseLieeRequise = true;
      } else {
        this.formGroup.get(`entrepriseLiee`).disable();
        this.formGroup.get(`entrepriseLiee`).setValue(undefined);
      }
    } else {
      this.formGroup.get(`entrepriseLiee`).disable();
      this.formGroup.get(`entrepriseLiee`).setValue(undefined);
    }

    this.formGroup.get(`entrepriseLiee`).updateValueAndValidity();
  }

  public submit(): void {
    this.numadherentValidatorsRequired();

    if (this.formGroup.valid && !this.codeComptableExiste) {
      this.entreprise.raisonSociale = this.raisonsociale.value;
      this.entreprise.estAdherent = this.estadherent.value;
      this.entreprise.numeroAdherent = this.numadherent.value;
      this.entreprise.cotisationAJour = this.cotisationAJour.value;
      if (this.dateAdhesion.value) this.entreprise.dateAdhesion = this.getMomentDate(this.dateAdhesion.value);
      if (this.dateDemission.value) this.entreprise.dateDemission = this.getMomentDate(this.dateDemission.value);
      this.entreprise.estMorale = this.estMorale.value;
      this.entreprise.siret = this.siret.value;
      this.entreprise.numeroTVA = this.numeroTVA.value;
      this.entreprise.adresse = this.adresse.value;
      this.entreprise.codePostal = this.codePostal.value;
      this.entreprise.commune = this.commune.value.nom;
      this.entreprise.departement = this.departement.code;
      this.entreprise.telephone = this.telephone.value;
      this.entreprise.mail = this.mail.value;
      this.entreprise.refSousType = this.refSousType.value;
      this.entreprise.numeroReconnaissance = this.numeroReconnaissance.value;
      this.entreprise.entrepriseLiee = (this.entrepriseLiee.value) ? this.entrepriseLiee.value : null;
      this.entreprise.cultureBio = this.cultureBio.value;
      this.entreprise.codeComptable = this.codeComptable.value;
      this.entreprise.commentaire = this.commentaire.value;
      this.entreprise.structureJuridique = this.refStructure.value;

      if (this.entreprise.id == null) {
        this.entrepriseService.creerEntreprise(this.entreprise).subscribe((idEntreprise: number) => this.onClose());
      } else {
        this.entrepriseService.modifierEntreprise(this.entreprise).subscribe(() => this.onClose());
      }
    }
  }

  public onClose(): void {
    this.router.navigate(['.'], { relativeTo: this.route.parent });
  }

  private loadData(id: number): void {
    this.title = this.creation ? 'page.entreprises.form.title.add' : 'page.entreprises.form.title.edit';

    forkJoin([
      this.creation ? of(new Entreprise()) : this.entrepriseService.getEntreprise(+id),
      this.refSousTypeservice.getRefSousType(),
      this.entrepriseService.getStructureJuriques()
    ]).pipe(this.loaderService.operator())
      .subscribe((
        [entreprise, refSousTypes, refStructures]:
        [Entreprise, Array<RefSoustypeUtilisateur>, Array<Structure>]
      ) => {
        this.refSousTypes = refSousTypes;
        this.entreprise = entreprise;
        this.refStructures = refStructures;

        if (this.creation) {
          this.entrepriseService.getEntreprisesByIdRefSousType(this.refSousTypes.find(r => r.code === "GP_DEFAUT").id)
            .pipe(this.loaderService.operator())
            .subscribe((entreprises: Array<EntrepriseBase>) => {
              this.entreprises = entreprises;
              this.initForm((entreprise.id) ? entreprise : null);
            });
        } else {
          forkJoin([
            this.communeService.getCommunes(entreprise.codePostal),
            this.entrepriseService.getEntreprisesByIdRefSousType(this.refSousTypes.find(r => r.code === "GP_DEFAUT").id)
          ]).pipe(this.loaderService.operator())
            .subscribe((
              [communes, entreprises]: 
              [Array<Commune>, Array<EntrepriseBase>]
            ) => {  
              this.communes = communes;

              if (communes && communes.length >0) {
                this.departementService.getDepartement(communes[0].codeDepartement)
                  .pipe(this.loaderService.operator())
                  .subscribe((departements) => {
                    this.departement = departements[0];
                  });;
              }

              this.entreprises = entreprises;
              this.initForm((entreprise.id) ? entreprise : null);
            });
        }
      });
  }

  private estecoresponsable(): void {
    if(this.creation) {
      return;
    }
    this.refCampagnesService.getCampagneVerEnCours().subscribe(campagneVerEnCours => {
      this.engagementVergersEcoService.getEngagementEcoByEntrepriseAndCampagne(this.id, campagneVerEnCours.code).subscribe(engagement => {
        if (engagement != null && ( engagement.codeStatut==='AE' || engagement.codeStatut==='C' ) ) {
          this.ecoresponsable.setValue(true);
        }
      });
    });    
  }

  private initForm(entreprise?: Entreprise): void {
    this.formGroup = this.fb.group({
      raisonsociale: [{ value: ((entreprise) ? entreprise.raisonSociale : undefined), disabled: false }, Validators.required],
      estadherent: [{ value: ((entreprise && entreprise.estAdherent) ? true : false), disabled: false }, Validators.required],
      numadherent: [{ value: ((entreprise) ? entreprise.numeroAdherent : undefined), disabled: false }],
      cotisationAJour: [{ value: ((entreprise) ? entreprise.cotisationAJour : undefined), disabled: false }],
      dateAdhesion: [{ value: ((entreprise && entreprise.dateAdhesion) ? this.getCustomDateFromMoment(entreprise.dateAdhesion) : undefined), disabled: false }],
      dateDemission: [{ value: ((entreprise && entreprise.dateDemission) ? this.getCustomDateFromMoment(entreprise.dateDemission) : undefined), disabled: false }],
      estMorale: [{ value: ((entreprise) ? entreprise.estMorale : undefined), disabled: false }],
      siret: [{ value: ((entreprise) ? entreprise.siret : undefined), disabled: false }, Validators.pattern(/^[0-9]{14}$/)],
      numeroTVA: [{ value: ((entreprise) ? entreprise.numeroTVA : undefined), disabled: false }],
      adresse: [{ value: ((entreprise) ? entreprise.adresse : undefined), disabled: false }, Validators.required],
      codePostal: [{ value: ((entreprise) ? entreprise.codePostal : undefined), disabled: false }, Validators.required],
      commune: [{ value: ((entreprise) ? this.communes.find(c => c.nom === entreprise.commune) : undefined), disabled: false }, Validators.required],
      telephone: [{ value: ((entreprise) ? entreprise.telephone : undefined), disabled: false }, Validators.pattern(/^([+][0-9]{2}|[0-9]{1})[0-9]{9}$/)],
      mail: [{ value: ((entreprise) ? entreprise.mail : undefined), disabled: false }, Validators.email],
      refSousType: [{ value: ((entreprise) ? this.refSousTypes.find(ref => ref.code === entreprise.refSousType.code) : undefined), disabled: false }, Validators.required],
      numeroReconnaissance: [{ value: ((entreprise) ? entreprise.numeroReconnaissance : undefined), disabled: false }],
      entrepriseLiee: [{ value: ((entreprise && entreprise.entrepriseLiee) ? this.entreprises.find(ent => ent.id === entreprise.entrepriseLiee.id) : undefined), disabled: false }, Validators.required],
      cultureBio: [{ value: ((entreprise) ? entreprise.cultureBio : undefined), disabled: false }],
      codeComptable: [{ value: ((entreprise) ? entreprise.codeComptable : undefined), disabled: false }],
      commentaire: [{ value: ((entreprise) ? entreprise.commentaire : undefined), disabled: false }],
      refStructure: [{ value: ((entreprise) ? this.refStructures.find(ref => ref.code === entreprise.structureJuridique.code) : undefined), disabled: false }, Validators.required],
      ecoresponsable: [{ value: false, disabled: true }]
    });
    this.estecoresponsable();
    //this.numeroReconnaissanceValidatorsRequired();
    this.entrepriseLieeValidatorsRequired();
    this.numadherentValidatorsRequired();
  }

  private getMomentDate(date: {
    year: number,
    month: number,
    day: number
  }): moment.Moment {
    return (date) 
      ? moment([date.year, date.month - 1, date.day, 0, 0, 0]) 
      : null;
  }


  private getCustomDateFromMoment(date: moment.Moment): {
    year: number,
    month: number,
    day: number
  } {
    return (date) 
      ? {
        year: date.year(),
        month: date.month() + 1,
        day: date.date()
      } 
      : undefined;
    }

}
