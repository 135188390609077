import { JsonProperty, JsonObject, Optional, CustomConverter } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { Salle } from './salle.model';
import { Animateur } from './animateur.model';
import { DateTimeConverter } from 'src/app/core/services/mapper/converters';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';

@JsonObject
export class CommissionAgenda {
  @JsonProperty('id', Number)
  @Optional
  id: number;

  @JsonProperty('terminee', Boolean)
  terminee: boolean;

  @JsonProperty('dateDebut', String)
  @CustomConverter(DateTimeConverter)
  debut: moment.Moment;

  @JsonProperty('dateFin', String)
  @CustomConverter(DateTimeConverter)
  fin: moment.Moment;

  @JsonProperty('numeroCommission', String)
  @Optional
  numero: string;

  @JsonProperty('idSalle', Number)
  @CustomConverter(DataIdConverterFactory.build(Salle, 'salles'))
  salle: Salle;

  @JsonProperty('idAnimateur', Number)
  @CustomConverter(DataIdConverterFactory.build(Animateur, 'animateurs'))
  animateur: Animateur;

  @JsonProperty('nbJuresInscrits', Number)
  nbJuresInscrits: number;

  @JsonProperty('nbJuresPresents', Number)
  nbJuresPresents: number;

  @JsonProperty('nbJuresTotal', Number)
  nbJuresTotal: number;

  @JsonProperty('nbEchantillons', Number)
  nbEchantillons: number;
}
