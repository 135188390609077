<div class="modal-content anpp">
  <!-- header fenêtre surgissante -->
  <div class="modal-header">
    <h4 class="modal-title" translate>{{ titleResource }}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- body fenêtre surgissante -->
  <div class="modal-body">
    <form [formGroup]="formDocument" #form="ngForm" (submit)="onSubmit()" novalidate>
      <div class="form-group">
        <label for="nom">Nom *</label>
        <input type="text" id="nom" class="form-control" formControlName="nom" [ngClass]="{ 'is-invalid': form.submitted && nom.invalid }" />
      </div>
      <div class="form-group">
        <label for="typeFichier">Type de fichier *</label>
        <select class="form-control" formControlName="typeFichier" [ngClass]="{ 'is-invalid': form.submitted && typeFichier.invalid }">
          <option [ngValue]="null" hidden>Type de fichier</option>
          <option *ngFor="let refTypeFichier of refTypesFichier" [ngValue]="refTypeFichier">
            {{ refTypeFichier.libelle }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="description">Description</label>
        <textarea id="description" class="form-control" formControlName="description"></textarea>
      </div>
      <ng-container *ngIf="!estEdition">
        <div class="form-group">
          <label>Visibilité *</label>
          <div class="form-check">
            <input type="radio" id="tousUtilisateurs" class="form-check-input" formControlName="visibilite" [value]="visibiliteEnum.PUBLIC_UTILISATEURS" />
            <label class="form-check-label" for="tousUtilisateurs">Public pour tous les utilisateurs</label>
          </div>
          <div class="form-check">
            <input type="radio" id="tousAdherents" class="form-check-input" formControlName="visibilite" [value]="visibiliteEnum.PUBLIC_ADHERENTS" />
            <label class="form-check-label" for="tousAdherents">Public pour tous les adhérents</label>
          </div>
          <div class="form-check">
            <input type="radio" id="prive" class="form-check-input" formControlName="visibilite" [value]="visibiliteEnum.PRIVE" />
            <label class="form-check-label" for="prive">Privé</label>
          </div>
        </div>
        <div class="form-group">
          <label for="fichier">Fichier *</label><br />
          <input 
            type="file" 
            id="fichier"
            formControlName="fichier" 
            (change)="onFileChange($event)" 
            [ngClass]="{ 'is-invalid': form.submitted && fichier.invalid }" 
          />
          <span *ngIf="uploadEnCours" class="spinner-border" role="status"></span>
        </div>
        <div class="form-group">
          <label>Destinataire</label>
          <ng-container *ngIf="estAnpp">
            <select class="form-control" formControlName="entreprise" [ngClass]="{ 'is-invalid': form.submitted && entreprise.invalid }">
              <option [ngValue]="null">Adhérent</option>
              <option *ngFor="let adherent of entreprises$ | async" [ngValue]="adherent.id">
                {{ adherent.raisonSociale }}
              </option>
            </select>
            <select class="form-control" formControlName="refSousType" [ngClass]="{ 'is-invalid': form.submitted && refSousType.invalid }">
              <option [ngValue]="null">Type d'entreprise</option>
              <option *ngFor="let typeEntreprise of refSousTypes | async" [ngValue]="typeEntreprise.code">
                {{ typeEntreprise.libelle }}
              </option>
            </select> 
          </ng-container>

          <select *ngIf="!estAnpp" class="form-control" formControlName="intervenant" [ngClass]="{ 'is-invalid': form.submitted && intervenant.invalid }">
            <option [ngValue]="null">Utilisateur</option>
            <option *ngFor="let operateur of operateurs$ | async" [ngValue]="operateur.id">
              {{ operateur.prenom }} {{ operateur.nom }}
            </option>
          </select>
        </div>
      </ng-container>

      <div class="actions-bar">
        <button type="button" (click)="onClose()" class="btn btn-light mr-2" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary" translate [disabled]="form.invalid || uploadEnCours">Valider</button>
      </div>
    </form>
  </div>
</div>
