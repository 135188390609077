import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditTerroirComponent } from './edit-terroir/edit-terroir.component';
import { ListeTerroirsComponent } from './liste-terroirs/liste-terroirs.component';

@Component({
  selector: 'app-terroirs',
  templateUrl: './terroirs.component.html',
  styleUrls: ['./terroirs.component.scss']
})
export class TerroirsComponent implements OnInit {

  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"]
    };
  
    static routes: Routes = [
      { path: '', component: ListeTerroirsComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id', component: EditTerroirComponent, data: { roles: ['ORGANISME'] } },
      { path: 'new', component: EditTerroirComponent, data: { roles: ['ORGANISME'] } }
    ];

  constructor() { }

  ngOnInit() {
  }

}
