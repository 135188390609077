<div class="modal-content anpp">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel"><img src="/assets/img/arbre.png" width="25px"/> <b translate> page.inventaire-verger.modals.arrachage.arrachageTitle</b></h5>
    <button (click)="cancel()" type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="formGroup" #form="ngForm" (ngSubmit)="submit()" novalidate>
      <h5 class="sub-popup-title"translate>page.inventaire-verger.infoBlocTitle</h5>
      <div class="row">
        <div class="col-lg-3">
          <span translate>page.inventaire-verger.numBloc</span>
          <span>: {{uniteCulturale.numBlocFruitier}}</span>
        </div>
        <div class="col-lg-3">
          <span translate>page.inventaire-verger.numDeTracabilite</span>
          <span>: {{uniteCulturale.numTracabilite}}</span>
        </div>
        <div class="col-lg-3">
          <span translate>page.inventaire-verger.codeParcelle</span>
          <span>: {{uniteCulturale.codeParcelle}}</span>
        </div>
        <div class="col-lg-3">
          <span translate>page.inventaire-verger.lieuDit</span>
          <span>: {{uniteCulturale.localisation.lieuDit}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <span translate>page.inventaire-verger.commune</span>
          <span>: {{uniteCulturale.localisation.commune}}</span>
        </div>
      </div>
      <hr>
      <h5 class="sub-popup-title"translate>page.inventaire-verger.distancePlantationTitle</h5>
      <div class="row">
        <div class="col-lg-6">
          <span translate>page.inventaire-verger.distanceInterRang</span>
          <span>: {{uniteCulturale.distanceInterRang}}m</span>
        </div>
        <div class="col-lg-6">
          <span translate>page.inventaire-verger.distanceIntraRang</span>
          <span>: {{uniteCulturale.distanceIntraRang}}m</span>
        </div>
      </div>
      <hr>
      <h5 class="sub-popup-title">Botanique</h5>
      <div class="row">
        <div class="col-lg-12 row">
          <div class="col-lg-3">
            <label translate>page.inventaire-verger.modals.arrachage.nbArbreArraches</label>
          </div>
          <div class="col-lg-9">
            <input [ngClass]="{'is-invalid': form.submitted && nbArbre.invalid}" (keyup)="calculeSurface()" (keyup)="controleNbArbreMaxMin()" (keypress)="keyPressNumbers($event)" type="number" formControlName="nbArbre" max="{{this.uniteCulturale.nbArbre}}" min="0" value="0" class="form-control">
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="  pt-2 m-2"
               style="width:90%;display:inline-block;border-radius:15px;line-height:30px;border:1px solid  rgba(131,187,38,1); background:rgba(131,187,38,0.15);">
            <b style="font-size:25px">Surface : <span style="font-size:25px;color:rgba(131,187,38,1)">{{this.surface}} Ha</span></b><br/>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" style="background:#41905c;border:1px solid #41905c"
                data-dismiss="modal" aria-label="Close"translate>page.inventaire-verger.buttonEnregistrer
        </button>
      </div>
    </form>
  </div>
</div>
