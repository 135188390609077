import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { map, catchError, tap } from 'rxjs/operators';
import { Observable, throwError, of, Subject, EMPTY } from 'rxjs';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { DeclarationRecolte } from '../../models/declaration-recolte.model';
import { ProduitsService } from 'src/app/data/habilitation/services/produits/produits.service';
import { DREVSaisie } from '../../models/drevsaisie.model';
import { Declarations } from '../../models/declarations.model';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { DeclarationIgp } from '../../models/declaration-igp.model';
import { DeclarationExport } from '../../models/declaration-export.model';

@Injectable({
  providedIn: 'root'
})
export class DeclarationsService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly produitsService: ProduitsService
  ) { }

  activeTabId: string;
  entrepriseSelectionnee: Entreprise;
  setActiveTabId(id: string): void {
    this.activeTabId = id;
  }

  getActiveTabId(): string {
    return this.activeTabId;
  }

  getDeclarationsRecolte(idIntervenant: number): Observable<DeclarationRecolte> {
    // return this.http.get<object[]>(`/assets/mocks/declarations-dr.json`).pipe(
    return this.http.get<object>(`/api/declaration/private/intervenant/${idIntervenant}/dr`).pipe(
      catchError(err => {
        if (err.error.code) {
          err.error.context = { annee: moment().get('year').toString() };
        }
        return throwError(err);
      }),
      map(response => this.mapper.deserializeObject(response, DeclarationRecolte)),
      tap(declaration => {
        of([].concat(declaration.drs, declaration.sv11s, declaration.sv12s)).pipe(
          this.produitsService.getProduitPipe()
        ).subscribe();
      })
    );
  }

  getDeclarationsRevendication(idIntervenant: number): Observable<Declarations> {
    // return this.http.get<object[]>(`/assets/mocks/declarations-drev.json`).pipe(
    return this.http.get<object[]>(`/api/declaration/private/intervenant/${idIntervenant}/drev`).pipe(
      map(response => this.mapper.deserializeObject(response, Declarations)),
      tap(response => {
        // Récupérer les produits liés aux déclarations AOC
        response.aocs.forEach(aoc => {
          this.produitsService.getProduitByCode(aoc.codeProduit)
            .subscribe(produit => aoc.produit = produit);
        });
        // Récupérer les produits liés aux déclarations IGP
        response.igps.forEach(igp => {
          this.produitsService.getProduitByCode(igp.codeProduit)
            .subscribe(produit => igp.produit = produit);
        });
      })
    );
  }

  getConsultationDREV(idIntervenant: number, annee: string): Observable<Declarations> {
    // return this.http.get<object[]>(`/assets/mocks/declarations-drev.json`).pipe(
    return this.http.get<object[]>(`/api/declaration/private/intervenant/${idIntervenant}/drev/annee/${annee}`).pipe(
      map(response => this.mapper.deserializeObject(response, Declarations)),
      tap(response => {
        // Récupérer les produits liés aux déclarations AOC
        response.aocs.forEach(aoc => {
          this.produitsService.getProduitByCode(aoc.codeProduit)
            .subscribe(produit => aoc.produit = produit);
        });
        // Récupérer les produits liés aux déclarations IGP
        response.igps.forEach(igp => {
          this.produitsService.getProduitByCode(igp.codeProduit)
            .subscribe(produit => igp.produit = produit);
        });
      })
    );
  }

  saisieStockDeclarationsRevendications(saisies: DREVSaisie[], idIntervenant: number): Observable<void> {
    saisies = saisies.filter(saisie => saisie.vciComplement || saisie.vciRemplacement || saisie.vciSubstitution);
    return this.http.patch(`/api/declaration/private/intervenant/${idIntervenant}/drev`, this.mapper.serialize(saisies)).pipe(
      map(() => { })
    );
  }

  getInventaireVergerDefinitiveStatus(idEntreprise: number, idCampagne: number): Observable<boolean> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(`/api/declaration/private/entreprises/${idEntreprise}/campagnes/${idCampagne}/inventaireVergerDefinitiveStatut`);
  }

  getDrevIgp(idIntervenant: number, annee: string, idProduit: number): Observable<DeclarationIgp[]> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<DeclarationIgp[]>(`/api/declaration/private/intervenant/${idIntervenant}/produits/${idProduit}/drevigp/annee/${annee}`).pipe(
      map(response => this.mapper.deserializeArray(response, DeclarationIgp)),
      tap(response => {
        // Récupérer les produits liés aux déclarations IGP
        response.forEach(igp => {
          this.produitsService.getProduitByCode(igp.codeProduit)
            .subscribe(produit => igp.produit = produit);
        });
      })
    );
  }

  updateDrevIgp(declarationsIgp: DeclarationIgp[], idIntervenant: number): Observable<void> {
    return this.http.patch(`/api/declaration/private/intervenant/${idIntervenant}/drevigp`, this.mapper.serialize(declarationsIgp)).pipe(
      map(() => { })
    );
  }

  getDeclarationExport(idParam: (number[] | string | null)[]): Observable<DeclarationExport[]> {
    return this.http.post<DeclarationExport[]>(`/api/declaration/private/declaration-export`, idParam).pipe(
      map(response => this.mapper.deserializeArray(response, DeclarationExport))
    );
  }

}
