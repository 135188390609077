import { Pipe, PipeTransform } from '@angular/core';
import { some } from 'lodash';


function getValues<T, K extends keyof T>(obj: T, properties: '*' | K | K[], maxDepth: number = 3) {
  if (maxDepth === 0) {
    return null;
  }

  if (Array.isArray(obj)) {
    return obj.map(item => getValues(item, '*', maxDepth))
      .reduce((array, item) => array.concat(item), [])
      .filter(val => val !== null && val !== undefined && val != '');
  }

  let values: any[];
  if (!obj) {
    values = [];
  } else if (properties === '*') {
    values = Object.values(obj);
  } else {
    values = [];
    properties = [].concat(properties);
    for (const key in obj) {
      if (some(properties, key)) {
        values.push(obj[key]);
      }
    }

    for (const key of properties) {
      values.push(obj[key]);
    }
  }


  return values.map(item => {
    if (typeof item === 'object') {
      return getValues(item, '*', maxDepth - 1);
    }
    return item;
  }).reduce((array, item) => array.concat(item), [])
    .filter(val => val !== null && val !== undefined && val != '');
}

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform<T, K extends keyof T>(items: T[], fields: '*' | K | K[], valuesString: string, maxDepth: number = 3): any[] {
    if (!items) {
      return [];
    }
    if (!fields || !valuesString) {
      return items;
    }

    const values = valuesString.split(' ');

    return items.filter(item => {
      for (const value of values) {
        if (!getValues(item, fields, maxDepth).join('|').toLowerCase().includes(value.toLowerCase())) {
          return false;
        }
      }
      return true;
    });
  }
}
