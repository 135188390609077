import { JsonProperty, JsonObject, Optional, CustomConverter } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { DateTimeConverter } from 'src/app/core/services/mapper/converters';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { Animateur } from '../../commission/models/animateur.model';

@JsonObject
export class TourneeAgenda {
  @JsonProperty('id', Number)
  @Optional
  id: number;

  @JsonProperty('terminee', Boolean)
  terminee: boolean;

  @JsonProperty('dateDebut', String)
  @CustomConverter(DateTimeConverter)
  debut: moment.Moment;

  @JsonProperty('dateFin', String)
  @CustomConverter(DateTimeConverter)
  fin: moment.Moment;

  @JsonProperty('numeroCommission', String)
  @Optional
  numero: string;


  @JsonProperty('idTechnicien', Number)
  @CustomConverter(DataIdConverterFactory.build(Animateur, 'techniciens'))
  technicien: Animateur;

  @JsonProperty('nbOperateurs', Number)
  nbOperateurs: number;

  @JsonProperty('nbEchantillons', Number)
  nbEchantillons: number;
}
