import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Observable } from 'rxjs';
import { Laboratoire } from '../../models/laboratoire.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LaboratoiresService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService) { }

    getLaboratoire(idOrganisme: number): Observable<Laboratoire[]> {
      return this.http.get<object[]>(`/api/declaration/private/organismes/${idOrganisme}/laboratoires`).pipe(
        // return this.http.get<object>(`/assets/mocks/referentiel.json`).pipe(
          map(laboratoires => this.mapper.deserializeArray(laboratoires, Laboratoire))
      );
    }
}
