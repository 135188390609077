import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { UtilisateurTypeCode } from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';
import { MonCompte } from 'src/app/data/intervenant/models/mon-compte.model';
import { MonCompteService } from 'src/app/data/intervenant/services/mon-compte/mon-compte.service';
import { UtilisateursService } from 'src/app/data/intervenant/services/utilisateurs/utilisateurs.service';
import { EditMdpComponent } from '../edit-mdp/edit-mdp.component';
import { DetailContactComponent } from './detail-contact/detail-contact.component';

@Component({
  selector: 'app-mon-compte',
  templateUrl: './mon-compte.component.html',
  styleUrls: ['./mon-compte.component.scss']
})
export class MonCompteComponent implements OnInit {
  static routes = [
    { path: '', redirectTo: 'or', pathMatch: 'full' }
  ];
  monCompte: MonCompte;
  showNotificationDocumentaire: boolean = false;
  constructor(private readonly modalService: NgbModal,
    private readonly monCompteService: MonCompteService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly utilisateursService: UtilisateursService) { }

  ngOnInit() {
    this.monCompteService.getMonCompte(SessionContext.get('idIntervenant')).pipe(this.loaderService.operator()).subscribe(monCompte => {
      this.monCompte = monCompte;
    });
    if (SessionContext.get('utilisateurType') === UtilisateurTypeCode.PI ||
      SessionContext.get('utilisateurType') === UtilisateurTypeCode.GP ||
      SessionContext.get('utilisateurType') === UtilisateurTypeCode.PO ||
      SessionContext.get('utilisateurType') === UtilisateurTypeCode.CT ||
      SessionContext.get('utilisateurType') === UtilisateurTypeCode.OC) {
      this.showNotificationDocumentaire = true;
    }
  }

  openModal() {
    this.modalService.open(EditMdpComponent, { size: 'lg', backdrop: 'static' });
  }

  openModalContact(contact) {
    const modal = this.modalService.open(DetailContactComponent, { size: 'lg', backdrop: 'static' });
    modal.componentInstance.contact = contact;
  }

  changeNotificationDocumentaire() {
    this.monCompte.notificationDocumentaire = !this.monCompte.notificationDocumentaire;
    this.utilisateursService.updateNotificationDocumentaire(SessionContext.get('idIntervenant'), this.monCompte.notificationDocumentaire).pipe(this.loaderService.operator()).subscribe();
  }

}
