import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';

import { DateConverter } from 'src/app/core/services/mapper/converters';

@JsonObject
export class Inscription {
  @JsonProperty('email', String)
  email: string;

  @JsonProperty('dateDebut', String)
  @CustomConverter(DateConverter)
  dateDebut: moment.Moment;

  @JsonProperty('dateFin', String)
  @CustomConverter(DateConverter)
  @Optional
  dateFin: moment.Moment;

  @JsonProperty('structureJuridique', String)
  @Optional
  structureJuridique: string;

  @JsonProperty('raisonSociale', String)
  raisonSociale: string;

  @JsonProperty('siret', String)
  siret: string;

  @JsonProperty('tvaIntra', String)
  @Optional
  tvaIntra: string;

  @JsonProperty('adresse', String)
  adresse: string;

  @JsonProperty('codePostal', String)
  codePostal: string;

  @JsonProperty('ville', String)
  ville: string;

  @JsonProperty('pays', String)
  pays: string;

  @JsonProperty('cedex', String)
  @Optional
  cedex: string;

  @JsonProperty('telFixe', String)
  @Optional
  telFixe: string;

  @JsonProperty('telPortable', String)
  @Optional
  telPortable: string;

  @JsonProperty('fax', String)
  @Optional
  fax: string;

}
