import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditAbonnementsComponent } from '../../../organismes/pages/edit-abonnements/edit-abonnements.component';
import { EditTypeInteractionComponent } from './edit-type-interaction/edit-type-interaction.component';
import { ListeTypeInteractionsComponent } from './liste-type-interactions/liste-type-interactions.component';

@Component({
  selector: 'app-type-interactions',
  templateUrl: './type-interactions.component.html',
  styleUrls: ['./type-interactions.component.scss']
})
export class TypeInteractionsComponent implements OnInit {

  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"]
    };
  
    static routes: Routes = [
      { path: '', component: ListeTypeInteractionsComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id', component: EditTypeInteractionComponent, data: { roles: ['ORGANISME'] } },
      { path: 'new', component: EditTypeInteractionComponent, data: { roles: ['ORGANISME'] } }
    ];

    constructor() { }

  ngOnInit() {
  }

}
