import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { remove } from 'lodash';
import { forkJoin, of } from 'rxjs';
import { RefSoustypeUtilisateur } from 'src/app/data/droits/models/ref-soustype-utilisateur.model';
import { ContactGroupe } from 'src/app/data/intervenant/models/contact-groupe.model';
import { Fonction } from 'src/app/data/intervenant/models/fonction.model';
import { Groupe } from 'src/app/data/intervenant/models/groupe.models';
import { ContactsService } from 'src/app/data/intervenant/services/contacts/contacts.service';
import { GroupesDiffusionService } from 'src/app/data/intervenant/services/groupes-diffusion/groupes-diffusion.service';
import { RefSousTypesService } from 'src/app/data/intervenant/services/ref-sous-types/ref-sous-types.service';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';

@Component({
  selector: 'app-edit-groupe-diffusion',
  templateUrl: './edit-groupe-diffusion.component.html',
  styleUrls: ['./edit-groupe-diffusion.component.scss']
})
export class EditGroupeDiffusionComponent implements OnInit {

  formGroup: FormGroup;
  creation = false;
  title: string;
  groupe: Groupe;
  groupes: Groupe[];

  contacts: ContactGroupe[];
  contact_groupe: ContactGroupe[];
  contact_groupe_filter: ContactGroupe[];
  contact_libre: ContactGroupe[];
  contact_libre_filter: ContactGroupe[];

  fonctions: Fonction[];
  refSousTypes: RefSoustypeUtilisateur[];

  get code() { return this.formGroup.get('code'); }
  get libelle() { return this.formGroup.get('libelle'); }
  get contact_filter() { return this.formGroup.get('contact'); }
  get contacts_libres_filter() { return this.formGroup.get('contacts_libres_filter'); }

  nomFilter: string;
  raisonsocialeFilter: string;
  fonctionFilter: Fonction;
  refsoustypeFilter: RefSoustypeUtilisateur;

  nomFilter_groupe: string;
  raisonsocialeFilter_groupe: string;
  fonctionFilter_groupe: Fonction;
  refsoustypeFilter_groupe: RefSoustypeUtilisateur;

  constructor(
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly groupeService: GroupesDiffusionService,
    private readonly contactService: ContactsService, 
    private readonly referenceService: ReferencesService,
    private readonly refSousTypeService: RefSousTypesService

    ) { }

  ngOnInit() {
    const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    if (!id) {
      this.creation = true;
    }
      this.loadData(id);
  }

  loadData(id: number) {

    this.title = this.creation ? 'page.valeur_parametrable.form.title.add_groupediffusion': 'page.valeur_parametrable.form.title.edit_groupediffusion';

    forkJoin(
    this.groupeService.getGroupes(),
    this.contactService.getContactsGroupe(),
    this.referenceService.getReferences(),
    this.refSousTypeService.getRefSousType()
    )
    .pipe(
      this.loaderService.operator()
    ).subscribe(([groupes,contacts, references, sousTypes]) => {
     
      this.groupe = !this.creation ? groupes.find(g => g.id === id) : (new Groupe());
      
      this.contacts = contacts; 

      this.fonctions = references["fonctions"];
      this.refSousTypes = sousTypes;

      this.contact_groupe = this.contacts.filter(c =>  c.groupes.find(g => g.id === id));
      this.contact_libre = this.contacts.filter(c =>  !c.groupes.find(g => g.id === id));
      this.contact_libre_filter = this.contact_libre;
      this.contact_groupe_filter = this.contact_groupe;
      this.initForm((this.groupe.id) ? this.groupe : null);
     
    });
  }

  private initForm(groupe?: Groupe): void {
 
    this.formGroup = this.fb.group({
      contact: [{ value: ((groupe) ? this.contact_groupe_filter: undefined), disabled: false }],      
      contacts_libres_filter: [{ value: ((groupe) ? this.contact_libre_filter: undefined), disabled: false }],
      libelle: [{ value: ((groupe) ? groupe.libelle : undefined), disabled: false }, Validators.required],
      code: [{ value: ((groupe) ? groupe.code : undefined), disabled: false }, Validators.required]
    });
  }

  removeCon(indexDep: number,con: ContactGroupe) {

    remove(this.contact_filter.value, con);
    this.contact_libre.push(con);

    this.udpadevalue();
    
    this.supprimerContact(con.idContact);

  }

  canDropCon(drag: CdkDrag<ContactGroupe>, drop: CdkDropList<ContactGroupe[]>) {
    return drop.data.findIndex(contact => contact.idContact === drag.data.idContact) === -1;
  }

  moveCon(event: CdkDragDrop<ContactGroupe[]>) {
    remove(this.contact_filter.value, event.item.data);

    this.contact_libre.push(event.item.data);
    
    this.udpadevalue();
    
    this.supprimerContact(event.item.data.idContact);
  }

  moveConLibre(event: CdkDragDrop<ContactGroupe[]>) {
    
    remove(this.contact_libre, event.item.data);
    remove(this.contacts_libres_filter.value, event.item.data);

    this.contact_groupe.push(event.item.data);

    this.udpadevalue();

    this.creerContact(event.item.data.idContact);

  }

  udpadevalue(){
    this.formGroup.get(`contact`).updateValueAndValidity();
    this.formGroup.get('contacts_libres_filter').updateValueAndValidity();
  }

  creerContact(id: number){
       
      this.groupeService.creerContactGroupe(id, this.groupe.id).subscribe( () => {} );
  }

  supprimerContact(id: number){    
    this.groupeService.supprimerContactGroupe(id, this.groupe.id).subscribe( () => {} );
  }

  filter() {
    if (this.contact_libre && this.contact_libre.length) {
      this.contact_libre_filter = this.contact_libre.filter(item => {
        let nomPrenom = `${item.nom} ${item.prenom}`;
        if (this.nomFilter && (nomPrenom.toLowerCase().indexOf(this.nomFilter.toLowerCase()) === -1)) {
          return false;
        }
        if (this.raisonsocialeFilter && item.raisonsociale.toLowerCase().indexOf(this.raisonsocialeFilter.toLowerCase()) === -1) {
          return false;
        }
        if (this.refsoustypeFilter && (item.refSousType && item.refSousType.id !== this.refsoustypeFilter.id)) {
          return false;
        }
        
        if (this.fonctionFilter && (item.fonction === undefined || (item.fonction && item.fonction.id !== this.fonctionFilter.id))) {
          return false;
        }
        return true;
      })
      
      this.formGroup.patchValue({'contacts_libres_filter':this.contact_libre_filter});
    }
  }

  filter_groupe() {
    if (this.contact_groupe && this.contact_groupe.length) {
      this.contact_groupe_filter = this.contact_groupe.filter(item => {
        let nomPrenom = `${item.nom} ${item.prenom}`;
        if (this.nomFilter_groupe && (nomPrenom.toLowerCase().indexOf(this.nomFilter_groupe.toLowerCase()) === -1)) {
          return false;
        }
        if (this.raisonsocialeFilter_groupe && item.raisonsociale.toLowerCase().indexOf(this.raisonsocialeFilter_groupe.toLowerCase()) === -1) {
          return false;
        }
        if (this.refsoustypeFilter_groupe && !(item.refSousType.id === this.refsoustypeFilter_groupe.id)) {
          return false;
        }
        
        if (this.fonctionFilter_groupe && (item.fonction === undefined || (item.fonction && item.fonction.id !== this.fonctionFilter_groupe.id))) {
          return false;
        }
        return true;
      })
      
      this.formGroup.patchValue({'contact':this.contact_groupe_filter});
    }
  }

  submit() {
    if (this.formGroup.valid) {

      this.groupe.libelle = this.libelle.value;
      this.groupe.code = this.code.value;
     
      if (this.groupe.id == null) {
        this.groupeService.creerGroupe(this.groupe).subscribe(idGroupe => { 
          this.groupe.id = idGroupe;
          this.creation = false;
          this.initForm(this.groupe);
        });
      } else {            
        this.groupeService.modifierGroupe(this.groupe).subscribe( () => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
         } );
      }
     
    }
  }

}
