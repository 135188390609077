<form [formGroup]="formGroup" #form="ngForm" (submit)="submit()" novalidate t-id="habilitation-update-form">
  <div class="modal-header">
    <h4 class="modal-title" t-id="habilitation-update-form-title">
      {{(habilitation.statut ? 'page.operateurs.habilitations.modal-update.title.modification' :
      'page.operateurs.habilitations.modal-update.title.creation') | translate }}
      <small> - {{habilitation.activite.libelle}}</small>
    </h4>
  </div>

  <div class="modal-body">
    <h4>{{habilitation.cahier.libelle}}</h4>

    <div class="form-group">
      <label for="statut" translate>page.operateurs.habilitations.modal-update.label.statut</label>

      <select id="statut" formControlName="statut" required [ngClass]="{'is-invalid': form.submitted && statut.invalid}"
        class="form-control">
        <option *ngFor="let statut of statuts" [ngValue]="statut">{{statut.libelle}}</option>
      </select>

      <ng-container *ngIf="form.submitted">
        <div *ngIf="statut.errors?.required" class="form-error" translate>label.obligatoire</div>
      </ng-container>
    </div>

    <div *ngIf="isEncours()" class="form-group">
      <label for="dateReception" translate>page.operateurs.habilitations.modal-update.label.dateReception</label>

      <div class="input-group">
        <input id="dateReception" formControlName="dateReception" ngbDatepicker #dateReceptionPicker="ngbDatepicker"
          [ngClass]="{'is-invalid': form.submitted && dateReception.invalid}" class="form-control" />
        <div class="input-group-append">
          <button type="button" (click)="dateReceptionPicker.toggle()" class="btn btn-secondary">
            <fa-icon icon="calendar-alt"></fa-icon>
          </button>
        </div>
      </div>

      <ng-container *ngIf="form.submitted">
        <div *ngIf="dateReception.errors?.required" class="form-error" translate>label.obligatoire</div>
      </ng-container>
    </div>

    <div class="form-group">
      <label for="dateDebut" translate>page.operateurs.habilitations.modal-update.label.dateDebut</label>

      <div class="input-group">
        <input id="dateDebut" formControlName="dateDebut" ngbDatepicker #dateDebutPicker="ngbDatepicker" [ngClass]="{'is-invalid': form.submitted && dateDebut.invalid}"
          class="form-control" />
        <div class="input-group-append">
          <button type="button" (click)="dateDebutPicker.toggle()" class="btn btn-secondary">
            <fa-icon icon="calendar-alt"></fa-icon>
          </button>
        </div>
      </div>

      <ng-container *ngIf="form.submitted">
        <div *ngIf="dateDebut.errors?.required" class="form-error" translate>label.obligatoire</div>
      </ng-container>
    </div>

    <div class="form-group">
      <label for="commentaire" translate>page.operateurs.habilitations.modal-update.label.commentaire</label>
      <textarea id="commentaire" formControlName="commentaire" [ngClass]="{'is-invalid': form.submitted && commentaire.invalid}"
        class="form-control"></textarea>
    </div>

    <div *ngIf="habilitation.historique.length > 0">
      <h5 class="primary" translate>page.operateurs.habilitations.modal-update.historique</h5>

      <app-historique-item [statut]="habilitation.historique[0]"></app-historique-item>

      <div *ngIf="habilitation.historique.length > 1">
        <button type="button" *ngIf="!showAllHistorique" (click)="showAllHistorique = true" class="btn btn-link pl-0"
          translate>page.operateurs.habilitations.modal-update.afficher-historique</button>

        <div *ngIf="showAllHistorique">
          <app-historique-item *ngFor="let statut of habilitation.historique | slice:1" [statut]="statut"></app-historique-item>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" (click)="cancel()" class="btn btn-light" translate>label.annuler</button>
    <button type="submit" class="btn btn-primary" translate>label.valider</button>
  </div>
</form>