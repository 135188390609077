import { JsonProperty, JsonObject, Optional, CustomConverter } from '@thorolf/json-ts-mapper';

import * as moment from 'moment';

import { DateTimeConverter } from 'src/app/core/services/mapper/converters';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { Animateur } from '../../commission/models/animateur.model';
import { TourneeEchantillon } from './tournee-echantillon.models';

@JsonObject
export class Tournee {

  @JsonProperty('id', Number)
  @Optional
  id: number;

  @JsonProperty('dateDebut', String)
  @CustomConverter(DateTimeConverter)
  dateDebut: moment.Moment;

  @JsonProperty('dateFin', String)
  @CustomConverter(DateTimeConverter)
  dateFin: moment.Moment;

  @JsonProperty('numero', String)
  @Optional
  numero: string;

  @JsonProperty('echantillons', [TourneeEchantillon])
  echantillons: TourneeEchantillon[];

  @JsonProperty('idTechnicien', Number)
  @CustomConverter(DataIdConverterFactory.build(Animateur, 'techniciens'))
  technicien: Animateur;

  @JsonProperty('terminee', Boolean)
  terminee: boolean;
}
