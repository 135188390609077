import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, forkJoin} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {JsonMapperService} from 'src/app/core/services/mapper/mapper.service';
import {Echantillon} from '../../models/echantillon.model';
import {ProduitsService} from 'src/app/data/habilitation/services/produits/produits.service';
import {OperateursService} from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import {CacheKey, Cache, ClearCache} from 'src/app/core/services/cache/cache.service';
import {ReferentielService} from 'src/app/data/declaration/services/referentiel/referentiel.service';
import {OrganismesService} from 'src/app/data/intervenant/services/organismes/organismes.service';

@Injectable({
  providedIn: 'root'
})
export class EchantillonsService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly refService: ReferentielService,
    private readonly produitsService: ProduitsService,
    private readonly operateursService: OperateursService,
    private readonly organismesService: OrganismesService
  ) {
  }

  @ClearCache
  clearCache() {
  }

  getEchantillonsByCdcs(idOrganisme: number, idsCdcs: number[], aPreleve: boolean): Observable<Echantillon[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/declaration/private/organismes/${idOrganisme}/echantillons?cdc=${idsCdcs.join(',')}&aPreleve=${aPreleve}`),
      this.refService.getReferentiel(),
      this.organismesService.getOrganismes()
    ).pipe(
      map(([echantillons, ref, organismes]) => {
        return this.mapper.deserializeArray(echantillons, Echantillon, Object.assign({organismes}, ref));
      }),
      this.produitsService.getProduitPipe(),
      this.operateursService.getOperateurPipe<Echantillon>(),
      tap(echantillons => {
        echantillons.forEach(item => {
          this.operateursService.getInformationsDomaine(item.idOperateur)
            .subscribe(infos => item.infosOperateur = infos);
        });
      })
    );
  }
}
