import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {OperateursService} from '../../../../../../../data/intervenant/services/operateurs/operateurs.service';
import {Pays} from '../../../../../../../data/intervenant/models/pays.model';
import {Operateur} from '../../../../../../../data/intervenant/models/operateur.model';

@Component({
  selector: 'app-edit-informations-mineures',
  templateUrl: './edit-informations-mineures.component.html',
  styleUrls: ['./edit-informations-mineures.component.scss']
})
export class EditInformationsMineuresComponent implements OnInit {

  @Input() idOperateur: number;
  @Input() informationsMineures: any;
  @Input() operateur: Operateur;
  @Input() infosDomaine: any[];
  @ViewChild('form') form: NgForm;
  formInformationsMineures: FormGroup;
  refPays: Pays[];

  get email() {
    return this.formInformationsMineures.get('email');
  }

  constructor(private readonly fb: FormBuilder,
              private readonly modal: NgbActiveModal,
              private operateurService: OperateursService) {
  }

  ngOnInit() {
    this.loadPays();
    
      if(this.operateur){
      this.formInformationsMineures = this.fb.group({
      nom: [ this.operateur.nom || ''],
      prenom: [this.operateur.prenom || ''],
      adresse: [this.operateur!.adresse || ''],
      codePostal: [this.operateur!.codePostal || ''],
      ville: [this.operateur!.ville || ''],
      pays: [this.operateur!.idPays ? this.operateur!.idPays.id : null],
      telFixe: [this.operateur!.telephoneFixe || ''],
      telMobile: [this.operateur!.telephoneMobile || ''],
      fax: [this.operateur!.fax || ''],
      cedex: [this.operateur!.cedex || ''],
      email: [this.operateur!.email || '', [Validators.required, this.singleEmailValidator]]
    });
      }
      else{
        this.formInformationsMineures = this.fb.group({
          nom: [ ''],
          prenom: [''],
          adresse: [''],
          codePostal: [''],
          ville: [''],
          pays: [null],
          telFixe: [''],
          telMobile: [''],
          fax: [''],
          cedex: [''],
          email: ['', [Validators.required, this.singleEmailValidator]]
        });
      }
    // Formater les numéros de téléphones et de fax
    this.formatPhoneNumber('telFixe');
    this.formatPhoneNumber('telMobile');
    this.formatPhoneNumber('fax');
  }

  // Récupère la liste de tous les pays (même s'il n'y en a qu'un dans la BDD..)
  loadPays() {
    this.operateurService.getPays().subscribe(
      (pays: Pays[]) => {
        this.refPays = pays;
      },
      error => {
        console.error('Erreur détaillée lors du chargement des pays:', error);
      }
    );
  }

  onClose() {
    this.modal.dismiss();
  }
  // Formate le numéro de téléphone pour qu'il soit plus lisible
  formatPhoneNumber(controlName: string) {
    const control = this.formInformationsMineures.get(controlName);
    if (control) {
      const value = control.value;
      const formattedValue = this.formatNumber(value);
      control.setValue(formattedValue, {emitEvent: false});
    }
  }

  // Méthode pour formater les numéros de téléphones et de fax pour plus de lisibilité (XX XX XX XX XX)
  private formatNumber(value: string): string {
    if (!value) {
      return value;
    }
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 2) {
      return phoneNumber;
    }
    if (phoneNumberLength < 4) {
      return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2)}`;
    }
    if (phoneNumberLength < 6) {
      return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 4)} ${phoneNumber.slice(4)}`;
    }
    // tslint:disable-next-line:max-line-length
    if (phoneNumberLength < 8) {
      return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 4)} ${phoneNumber.slice(4, 6)} ${phoneNumber.slice(6)}`;
    }
    // tslint:disable-next-line:max-line-length
    return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 4)} ${phoneNumber.slice(4, 6)} ${phoneNumber.slice(6, 8)} ${phoneNumber.slice(8, 10)}`;
  }

  // Fonction de validation pour une seule adresse email
  singleEmailValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return null;
    }

    // Vérifiez s'il y a plusieurs adresses e-mail en utilisant une regex simple pour détecter les séparateurs courants
    const emails = value.split(/[\s,;]+/);
    if (emails.length > 1) {
      return {multipleEmails: true};
    }

    // Vérifiez si l'adresse e-mail est valide
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      return {invalidEmail: true};
    }

    return null;
  }

  // Permet de vérifier à la volée si l'email est correct et s'il n'y en a qu'un
  onEmailBlur() {
    this.email.markAsTouched();
  }
  onEmailInput() {
    this.email.updateValueAndValidity();
  }
  // Formate le numéro de téléphone avant de le soumettre pour qu'il n'y ait pas d'espace
  private formatPhoneNumberBeforeSubmit(phoneNumber: string): string {
    return phoneNumber.replace(/\D/g, '');
  }

  onSubmit() {
    if (this.formInformationsMineures.valid) {
      const formData = this.formInformationsMineures.value;
      formData.telMobile = this.formatPhoneNumberBeforeSubmit(formData.telMobile);
      formData.fax = this.formatPhoneNumberBeforeSubmit(formData.fax);
      formData.telFixe = this.formatPhoneNumberBeforeSubmit(formData.telFixe);
      // Create an object that matches the OperateurDTO structure
      const dataToSend: Partial<Operateur> = {
        id: this.idOperateur,
        nom: formData.nom,
        prenom: formData.prenom,
        email: formData.email,
        adresse: formData.adresse,
        codePostal: formData.codePostal,
        ville: formData.ville,
        idPays: formData.pays, // Assuming formData.pays contains the ID of the selected country
        cedex: formData.cedex,
        telephoneFixe: formData.telFixe,
        telephoneMobile: formData.telMobile,
        fax: formData.fax,
      };

      this.operateurService.updateInformationsMineures(this.idOperateur, dataToSend).subscribe(
        result => {
          this.modal.close('informations_mineures_updated');
        },
        error => {
          console.error('Erreur lors de la mise à jour des informations mineures', error);
        }
      );
    }
  }
}
