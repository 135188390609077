import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {JsonMapperService} from '../../../../core/services/mapper/mapper.service';
import {Observable} from 'rxjs';
import {DeclarationStock} from '../../models/declaration-stock.model';
import {map} from 'rxjs/operators';
import {Stock} from '../../models/stock.model';
import { SuiviStockModel } from 'src/app/data/suivis/models/suivi-stock.model';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { UtilisateursService } from 'src/app/data/intervenant/services/utilisateurs/utilisateurs.service';
import { EstimationStock } from 'src/app/data/suivis/models/estimation-stock.model';


@Injectable({
  providedIn: 'root'
})
export class StocksService {

  constructor(protected readonly http: HttpClient,
              protected readonly mapper: JsonMapperService,
              protected readonly utilisateurService: UtilisateursService,
              protected readonly entrepriseService: EntreprisesService,

) { }

  /**
   * appel back récupération des stocks d'une entreprise
   * @param idEntreprise
   * @param idCampagne
   * @param numMois
   * @returns
   */
  getStocks(idEntreprise: number, idCampagne: number, numMois: number): Observable<DeclarationStock> {
    return this.http.get<object[]>(`/api/declaration/private/stock?idEntreprise=${idEntreprise}&idCampagne=${idCampagne}&numMois=${numMois}`
    ).pipe(
      map(response => this.mapper.deserializeObject(response, DeclarationStock)),
    );
  }

  /**
   * appel back sauvegarde des stocks d'une entreprise
   * @param entrepriseId
   * @param campagneId
   * @param numMois
   * @param stock
   * @returns
   */
  public saveStocks(entrepriseId: number, campagneId: number, numMois: number, stocks: Stock[]): Observable<void> {
    const declaration: DeclarationStock = new DeclarationStock();
    declaration.numMois = numMois;
    declaration.idEntreprise = entrepriseId;
    declaration.idRefCampagne = campagneId;
    declaration.stocks = stocks;

    return this.http.post<void>(`/api/declaration/private/stock`, declaration);
  }

  /**
   * appel back stock à zero pour mois courant et suivant
   * @param entrepriseId
   * @param campagneId
   * @param ecoulements
   * @returns
   */
  public stocksAZero(entrepriseId: number, campagneId: number, numMois: number): Observable<void> {

    return this.http.post<void>(`/api/declaration/private/entreprises/${entrepriseId}/campagnes/${campagneId}/mois/${numMois}/stockAZero`, null);
  }

  /**
   * appel back suivi de stocks
   * @param idCampagne 
   * @param numMois 
   * @returns 
   */

  public getSuivisStocks(idCampagne: number, numMois: any): Observable<SuiviStockModel[]> {
    return this. http.get<SuiviStockModel[]>(`/api/declaration/private/suivi-stock?idCampagne=${idCampagne}&numMois=${numMois}`)
  }

  /**
   * 
   * @returns appel back estimation stock mois en cours
   */
  public getEstimationStocks(): Observable<EstimationStock[]> {
    return this. http.get<EstimationStock[]>(`/api/declaration/private/estimations-stocks`)
    .pipe(
      map(estimation => this.mapper.deserializeArray(estimation, EstimationStock)))
  }


  /**
   * appel back raz stock à zero pour mois courant et suivant
   * @param idEntreprise 
   * @param idCampagne 
   * @param numMois 
   * @returns 
   */
  public razStockAZero(idEntreprise: Number, idCampagne: number, numMois: number): Observable<void> {
    return this.http.post<void>(`/api/declaration/private/entreprises/${idEntreprise}/campagnes/${idCampagne}/mois/${numMois}/razStockAZero`, null);
  }
}
