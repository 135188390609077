export enum ReferenceQuantiteEnum {
    VOL_REVENDICATION = 'VOL_REVENDICATION',
    VOL_DREV = 'VOL_DREV',
    VOL_CHANGMT_PRODUIT = 'VOL_CHANGMT_PRODUIT',
    VOL_CONDITIONNEMENT = 'VOL_CONDITIONNEMENT',
    VOL_DR = 'VOL_DR',
    SURFACE_DR = 'SURFACE_DR',
    FORFAIT_CONTROLE = 'FORFAIT_CONTROLE',
    FORFAIT_REVENDICATION = 'FORFAIT_REVENDICATION',
    FORFAIT_DREV = 'FORFAIT_DREV',
    FORFAIT_CHANGMT_PRODUIT = 'FORFAIT_CHANGMT_PRODUIT',
    FORFAIT_CONDITIONNEMENT = 'FORFAIT_CONDITIONNEMENT',
    FORFAIT_DR = 'FORFAIT_DR',
    FORFAIT_SPLIT = 'FORFAIT'
}
