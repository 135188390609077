import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Animateur } from '../../models/animateur.model';
import { HttpClient } from '@angular/common/http';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { map, tap } from 'rxjs/operators';

interface ObjectWithAnimateur { idAnimateur: number; animateur: Animateur; }

@Injectable({
  providedIn: 'root'
})
export class AnimateursService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
  ) { }

  getAnimateurs(idOrganisme: number): Observable<Animateur[]> {
    return this.http.get<object[]>(`/api/intervenant/private/organismes/${idOrganisme}/animateurs`).pipe(
      map(animateurs => this.mapper.deserializeArray(animateurs, Animateur)),
      tap(animateurs => {
        animateurs.forEach(animateur => animateur.nomComplet = `${animateur.prenom} ${animateur.nom}`);
      })
    );
  }

  getAnimateurPipe<T extends ObjectWithAnimateur | ObjectWithAnimateur[]>(idOrganisme: number) {
    return tap((list: T) => {
      const liste: ObjectWithAnimateur[] = [].concat(list);
      this.getAnimateurs(idOrganisme).subscribe(animateurs => {
        liste.forEach(item => {
          item.animateur = animateurs.find(animateur => animateur.id === item.idAnimateur);
        });
      });
    });
  }
}
