import { JsonProperty, JsonObject } from '@thorolf/json-ts-mapper';
import { Produit } from '../../habilitation/models/produit.model';

@JsonObject
export class SyntheseVolumesAOC {
  @JsonProperty('codeProduit', String)
  codeProduit: string = undefined;

  produit: Produit;

  @JsonProperty('surface', Number)
  surface = 0;

  @JsonProperty('volumesAchats', Number)
  volumesAchats = 0;

  @JsonProperty('volumesNetsRevendiques', Number)
  volumesNetsRevendiques = 0;

  @JsonProperty('volumesStockVrac', Number)
  volumesStockVrac = 0;

  @JsonProperty('variationsChangementsDeno', Number)
  variationsChangementsDeno = 0;
}
