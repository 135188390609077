<div class="anpp">
  <div class="content">
    <div class="content-panel">
      <div class="cards  border-radius-onglet text-center" style="background:#fff">
        <div class="row">
          <div class="col-lg text-left pl-4 pt-4">
            <a href="#" class="pl-4 link-secondary" [routerLink]="['./contacts/new']">
              <span style="text-decoration:underline"><fa-icon icon="plus-square"></fa-icon>Ajouter un contact</span></a>
          </div>
        </div>
        <table class="table table-striped" *ngIf="contacts?.length > 0; else aucunContact">
          <thead>
            <tr>
              <th scope="col">Opérations</th>
              <th scope="col" style="position:relative">
                <div translate>page.contacts.table.civilite </div>
              </th>
              <th scope="col" style="position:relative">
                <div translate>page.contacts.table.nom </div>
              </th>
              <th scope="col" style="position:relative">
                <div translate>page.contacts.table.prenom</div>
              </th>
              <th scope="col" style="position:relative">
                <div translate>page.contacts.table.fonction</div>
              </th>
              <th scope="col" style="position:relative">
                <div translate>page.contacts.table.telephone</div>
              </th>
              <th scope="col" style="position:relative">
                <div translate>page.contacts.table.portable</div>
              </th>
              <th scope="col" style="position:relative">
                <div translate>page.contacts.table.mail</div>
              </th>
              <th scope="col" style="position:relative">
                <div translate>page.contacts.table.referent</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let contact of contacts ">
              <td>
                <div class="btn-group dropright  ">
                  <div ngbDropdown placement="right-start">
                    <button class="btn btn-secondary dropdown-toggle btnprimary btn-sm " id="dropdownConfig"
                      ngbDropdownToggle>
                      <fa-icon icon="cog"></fa-icon>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                      <button (click)="editContact(contact)" ngbDropdownItem class="dropdown-item">
                        <fa-icon icon="id-card"></fa-icon>
                        Fiche du contact
                      </button>
                      <button (click)="openConfirmationDialog(contact)" ngbDropdownItem class="dropdown-item">
                        <fa-icon icon="trash"></fa-icon>
                        Supprimer le contact
                      </button>
                    </div>
                  </div>
                </div>
              </td>
              <td>{{ contact.civilite }}</td>
              <td>{{ contact.nom }}</td>
              <td>{{ contact.prenom }}</td>
              <td>{{ contact.fonction?.libelle }}</td>
              <td>{{ contact.telephonefixe }}</td>
              <td>{{ contact.telephoneportable }}</td>
              <td>{{ contact.mail }}</td>
              <td>
                <span class="badge badge-secondary badge-green" *ngFor="let ref of contact.referents">{{ref.code}} </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #aucunContact>
  <div translate>page.contacts.table.aucun_contact</div>
</ng-template>