import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Profil } from '../../models/profil.model';

@Injectable({
  providedIn: 'root'
})
export class ProfilsService {

  static profils: Profil[];

  constructor(
    private readonly http: HttpClient,
    private readonly mapperService: JsonMapperService
  ) { }

  static getProfils(): Profil[] {
    const profils = ProfilsService.profils;
    if (profils === undefined) {
      throw new Error('service-profils.static-data.unavailable');
    }
    return profils;
  }

  getProfils(idUtilisateur): Observable<Profil[]> {
    return this.http.get<object[]>(`/api/intervenant/private/intervenant/${idUtilisateur}/profils`)
      .pipe(
        map(profils => this.mapperService.deserializeArray(profils, Profil)),
        tap(profils => {
          ProfilsService.profils = profils;
        })
      );
  }
}
