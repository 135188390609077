<div class="content">
  <div class="content-panel">


    <ngb-tabset #t="ngbTabset" activeId="tab-0" justify="fill">
      <ngb-tab [id]="'tab-'+index" *ngFor="let type of typesUtilisateurs; let index = index">
        <ng-template ngbTabTitle>{{type.libelle}}</ng-template>
        <ng-template ngbTabContent>
          <app-search-bar [(value)]="searchStrings[index]"></app-search-bar>
          <table table class="table table-striped liste">
            <thead>
              <tr>
                <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'libelle', libelle:'page.admin.profils.table.header.profil'}"></ng-container>
                <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'service', libelle:'page.admin.profils.table.header.service'}"></ng-container>
                <!-- <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'soustype.libelleType', libelle:'page.admin.profils.table.header.type'}"></ng-container> -->
                <ng-container *ngIf="type.nbSoustype > 1">
                  <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'soustype.libelle', libelle:'page.admin.profils.table.header.soustype'}"></ng-container>
                </ng-container>
                <th translate>page.admin.profils.table.header.droits-fonctionnels</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let profil of getProfilsByType(type.code) | filter : '*': searchStrings[index] | orderBy:order.property:order.reverse:true">
                  <td>{{profil.libelle}}</td>
                  <td [title]="profil.service.code">{{profil.service.libelleCourt}}</td>
                  <!-- <td [title]="profil.soustype.codeType">{{profil.soustype.libelleType}}</td> -->
                  <td *ngIf="type.nbSoustype > 1" [title]="profil.soustype.code">{{profil.soustype.libelle}}</td>
                  <td>
                    <button type="button" class="btn btn-light btn-show-droits" (click)="profil.__show_droits = !profil.__show_droits"><fa-icon [icon]="profil.__show_droits ? 'minus':'plus'"></fa-icon>{{(profil.__show_droits ? 'label.masquer':'label.afficher') | translate}}</button>
                    <div *ngIf="profil.__show_droits">
                      <table class="liste-droits">
                        <tr *ngFor="let droitFonctionnel of profil.droitsFonctionnels">
                          <td [title]="droitFonctionnel.code">{{droitFonctionnel.libelle}}</td>
                          <td class="actions-droits">
                            <fa-icon icon="trash" (click)="supprimerDroit(profil, droitFonctionnel)"></fa-icon>
                            <fa-icon icon="search" (click)="voirRoles(roleModal, droitFonctionnel)"></fa-icon>
                          </td>
                        </tr>
                      </table>
                      <div class="form-group ajout-droit">
                        <label translate>page.admin.profils.table.ajouter-droits</label>
                        <select class="form-control" [(ngModel)]="profil.__null" (ngModelChange)="ajouterDroit(profil, $event)">
                          <option [value]="null"></option>
                          <option *ngFor="let droit of getDroitsFonctionnelsAjoutables(profil)" [ngValue]="droit">{{droit.libelle}}</option>
                        </select>
                      </div>
                    </div>
                  </td>
                </tr>
            </tbody>
          </table>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
</div>


<ng-template #tableHeader let-property="sortProperty" let-libelle="libelle">
  <th *ngIf="property" (click)="setOrder(property)" [class.sortable]="property">
    {{libelle | translate}}
    <fa-icon *ngIf="property" [icon]="getIcon(property)"></fa-icon>
  </th>
  <th *ngIf="!property">{{libelle | translate}}</th>
</ng-template>

<ng-template #roleModal let-modal>
  <div class="modal-header">
    <div class="modal-title">
      <h3>Roles : </h3>
      <h4>
        {{modalContext.title}}
      </h4>
    </div>
  </div>
  <div class="modal-body">
    <table class="table table-striped">
      <tr *ngFor="let role of modalContext.roles">
        <td>{{role.libelle}}</td>
        <td>({{role.code}})</td>
      </tr>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()" translate>label.valider</button>
  </div>
</ng-template>
