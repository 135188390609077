<div class="modal-content anpp">
  <div class="modal-header">
    <h4 class="modal-title text-uppercase bold" translate>
      page.ecoulement.modalEnregistrer.titre
    </h4>
  </div>			
  <div class="modal-body">
      <div class="tab-content" id="nav-tabContent">
        <div class="row justify-content-md-center">
          <div class="col col-lg-12 text-center">
            <h4></h4>
            <p>&nbsp;</p>			
            <table class="table table-striped mt-2">

              <thead class="center">
                <tr>
                  <!--icone-->
                  <th scope="col"> </th>
                  <!--groupe varietal-->
                  <th scope="col" class="text-left bar1">
                    <div ><span translate>page.ecoulement.table.header.groupeVarietal</span></div>
                  </th>
                  <!-- France-->
                  <th scope="col" colspan="2" class="bar1"> <span
                      translate>page.ecoulement.table.header.france</span></th>
                  <!-- Export-->
                  <th scope="col" colspan="2" class="bar1"> <span
                      translate>page.ecoulement.table.header.export</span></th>
                  <!-- Industrie -->
                  <th scope="col" colspan="2" class="bar1"> <span
                    translate>page.ecoulement.table.header.industrie</span></th>
                  <!-- Freinte -->
                  <th scope="col" colspan="2" class="bar1"> <span
                    translate>page.ecoulement.table.header.freinte</span></th>
                  <!-- totaux -->
                  <th scope="col" colspan="2" class="bar1"> <span></span></th>                  
                </tr>
                <tr>
                  <!--icone-->
                  <th scope="col" style="position:relative; width:60px;"></th>
                  <!--groupe varietal-->
                  <th scope="col" style="position:relative"></th>
                  <!-- France-->
                  <th scope="col" class="center" ><small><strong translate>page.ecoulement.table.header.qteTotale</strong></small></th>
                  <th scope="col" class="center" ><small><strong translate>page.ecoulement.table.header.qteBio</strong></small></th>
                  <!-- Export-->
                  <th scope="col" class="center" ><small><strong translate>page.ecoulement.table.header.qteTotale</strong></small></th>
                  <th scope="col" class="center" ><small><strong translate>page.ecoulement.table.header.qteBio</strong></small></th>
                  <!-- Industrie -->
                  <th scope="col" class="center" ><small><strong translate>page.ecoulement.table.header.qteTotale</strong></small></th>
                  <th scope="col" class="center" ><small><strong translate>page.ecoulement.table.header.qteBio</strong></small></th>
                  <!-- Freinte -->
                  <th scope="col" class="center" ><small><strong translate>page.ecoulement.table.header.qteTotale</strong></small></th>
                  <th scope="col" class="center" ><small><strong translate>page.ecoulement.table.header.qteBio</strong></small></th>
                  <!-- totaux -->
                  <th scope="col" class="center bg-td" ><small><strong translate>page.ecoulement.table.header.qteTotale</strong></small></th>
                  <th scope="col" class="center bg-td" ><small><strong translate>page.ecoulement.table.header.qteBio</strong></small></th>
                </tr> 
              </thead>
              <tbody>

                <tr *ngFor="let ecoulement of ecoulements;let i = index" [ngClass]="font-weight-bold">
                  <td>
                    <span *ngIf="ecoulement.codeEspece==='POMME'"> 
                      <fa-icon icon='apple-alt' style="color:black;"></fa-icon>
                      <fa-icon icon='apple-alt' style="color:black;"></fa-icon>
                    </span>                    
                    <span *ngIf="ecoulement.codeEspece==='POIRE'"> 
                      <img src="/assets/img/pear.png" width="16px" />
                      <img src="/assets/img/pear.png" width="16px" />
                    </span>
                  </td>                  
                  <td scope="col" class="text-left">
                    <span [innerHtml]="'page.ecoulement.table.header.total' | translate"></span>
                    &nbsp;{{ecoulement.codeEspece}}
                  </td>

                  <!-- france -->
                  <td scope="col" class="center bold">{{ecoulement.qteTotaleFrance | saisieRecolteFormat}}<br/>{{ecoulement.qteTotaleFrancePC() | percent}}</td>
                  <td scope="col" class="center">{{ecoulement.qteBioFrance | saisieRecolteFormat}}<br/>{{ecoulement.qteBioFrancePC() | percent}}</td>

                  <!-- export -->
                  <td scope="col" class="center bold">{{ecoulement.qteTotaleExport | saisieRecolteFormat}}<br/>{{ecoulement.qteTotaleExportPC() | percent}}</td>
                  <td scope="col" class="center">{{ecoulement.qteBioExport | saisieRecolteFormat}}<br/>{{ecoulement.qteBioExportPC() | percent}}</td>

                  <!-- industrie -->
                  <td scope="col" class="center bold">{{ecoulement.qteTotaleIndustrie | saisieRecolteFormat}}<br/>{{ecoulement.qteTotaleIndustriePC() | percent}}</td>
                  <td scope="col" class="center">{{ecoulement.qteBioIndustrie | saisieRecolteFormat}}<br/>{{ecoulement.qteBioIndustriePC() | percent}}</td>
                  <!-- freinte -->
                  <td scope="col" class="center bold">{{ecoulement.qteTotaleFreinte | saisieRecolteFormat}}<br/>{{ecoulement.qteTotaleFreintePC() | percent}}</td>
                  <td scope="col" class="center">{{ecoulement.qteBioFreinte | saisieRecolteFormat}}<br/>{{ecoulement.qteBioFreintePC() | percent}}</td>

                  <td scope="col" class="center bold">{{ecoulement.qteTotale() | saisieRecolteFormat}}</td>
                  <td scope="col" class="center bold">{{ecoulement.qteBio() | saisieRecolteFormat}}</td>   										
                </tr>


              </tbody>
            </table>

          </div>

        </div>
      </div>
  </div>

  <div class="actions-bar">
    <button type="button" (click)="cancel()" class="btn btn-light mr-2" translate>label.annuler</button>
    <button type="submit" (click)="save()" class="btn btn-secondary  btnprimary" style="background:#41905c;border:1px solid #41905c"
      translate>label.valider</button>
    </div>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
</div>
