<div class="content">
  <div class="content-panel">
    <form [formGroup]="formGroup" #form="ngForm" (submit)="submit()" novalidate>
      <div class="m-3">
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <select id="spec" formControlName="spec" required class="form-control">
                <option *ngFor="let spec of specs" [ngValue]="spec">{{spec.libelle}}</option>
              </select>
            </div>
            <input type="text" id="search" formControlName="search"
                   [ngClass]="{'is-invalid': form.submitted && search.invalid}"
                   class="form-control"/>
          </div>

          <ng-container *ngIf="form.submitted">
            <div *ngIf="search.errors?.required" class="form-error" translate>label.obligatoire</div>
          </ng-container>
        </div>
      </div>

      <div class="actions-bar">
        <button type="submit" class="btn btn-primary" translate>label.valider</button>
      </div>
    </form>
  </div>

  <div *ngIf="noResult" class="content-panel p-3">
    <p>
      La recherche ne correspond à aucun opérateur connu.<br/>
    </p>

    <button type="button" class="btn btn-primary" (click)="addOperateur()" translate>page.operateurs.recherche-ensemble.redirection-agranet
    </button>

  </div>

  <button type="button" routerLink="../recherche-affilie" class="btn btn-primary mt-3">Nouvelle recherche</button>
</div>
