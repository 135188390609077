import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { Constat } from './constat.model';
import { Notification } from './notification.model';
import { StatutManquement } from './statut-manquement.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { Decision } from './decision.model';
import { Recours } from './recours.model';

@JsonObject
export class Manquement {
    @JsonProperty('id', Number)
    @Optional
    id: number;

    @JsonProperty('numeroManquement', String)
    @Optional
    numero: string;

    @JsonProperty('idStatut', Number)
    @CustomConverter(DataIdConverterFactory.build(StatutManquement, 'statutsManquement'))
    @Optional
    statut: StatutManquement;

    @JsonProperty('constat', Constat)
    @Optional
    constat: Constat;

    @JsonProperty('notification', Notification)
    @Optional
    notification: Notification;

    @JsonProperty('decision', Decision)
    @Optional
    decision: Decision;

    @JsonProperty('recours', Recours)
    @Optional
    recours: Recours;
}
