import { JsonProperty, JsonObject } from '@thorolf/json-ts-mapper';
import { College } from './college.model';
import { StatutJure } from './statut-jure.model';
import { Defaut } from './defaut.model';
import { TypeLeurre } from './type-leurre.model';

@JsonObject
export class References {
  @JsonProperty('colleges', [College])
  colleges: College[];

  @JsonProperty('statutsJure', [StatutJure])
  statutsJure: StatutJure[];

  @JsonProperty('defauts', [Defaut])
  defauts: Defaut[];

  @JsonProperty('typesLeurres', [TypeLeurre])
  typesLeurres: TypeLeurre[];
}
