import { JsonObject, JsonProperty, Optional, CustomConverter } from '@thorolf/json-ts-mapper';
import { Operateur } from '../../intervenant/models/operateur.model';
import { StatutHabilitation } from '../../habilitation/models/statut-habilitation.model';
import { RefStatut } from '../../habilitation/models/statut.ref.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { Activite } from '../../habilitation/models/activite.model';
import { Cahier } from '../../habilitation/models/cahier.model';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import * as moment from 'moment';
import { OperateurExport } from './operateur-export.model';


@JsonObject
export class HabilitationExport {
    @JsonProperty('id', Number)
    @Optional
    public id: number = undefined;

    @JsonProperty('idCahier', Number)
    @CustomConverter(DataIdConverterFactory.build(Cahier, 'cahiers'))
    public cahier: Cahier = undefined;

    @JsonProperty('idActivite', Number)
    @CustomConverter(DataIdConverterFactory.build(Activite, 'activites'))
    public activite: Activite = undefined;

    @JsonProperty('idStatut', Number)
    @CustomConverter(DataIdConverterFactory.build(RefStatut, 'statutsHabilitation'))
    @Optional
    public statut: RefStatut = undefined;

    @JsonProperty('historiquesStatut', [StatutHabilitation])
    @Optional
    public historique: StatutHabilitation[] = [];

    // ajouté pour les exports
    @JsonProperty('idOperateur', Number)
    @Optional
    public idOperateur: number;

    @JsonProperty('operateur', OperateurExport)
    @Optional
    public operateur: OperateurExport;

    @JsonProperty('datePremiereHabilitation', String)
    @CustomConverter(DateConverter)
    @Optional
    datePremiereHabilitation: moment.Moment;

}
