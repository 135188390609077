import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RefModeConduite } from "../../models/ref-mode-conduite.model";

@Injectable({
    providedIn: 'root'
  })
  export class RefModeConduiteService {
    constructor( private readonly http: HttpClient) { }

    getRefModeConduite(): Observable<RefModeConduite[]> {
        return this.http.get<RefModeConduite[]>(`/api/declaration/private/referentielModeConduite`);
    }
  }