import { CustomConverter, JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";
import * as moment from "moment";
import { DateConverter, VisibiliteEnumConverter } from "src/app/core/services/mapper/converters";
import { VisibiliteEnum } from "./enums/visibilite.enum";
import { RefTypeFichier } from "./ref-type-fichier.model";
import { VersionFichier } from "./version-fichier.model";

@JsonObject
export class GedFichier {
  
  @JsonProperty('id', Number)
  public id: number;

  @JsonProperty('libelle', String)
  public libelle: String;

  @JsonProperty('uuid', String)
  public uuid: String;

  @JsonProperty('typeFichier', RefTypeFichier)
  public typeFichier: RefTypeFichier;

  @JsonProperty('description', String)
  @Optional
  public description: String;

  @JsonProperty('dateUpload', String)
  @CustomConverter(DateConverter)
  public dateUpload: moment.Moment;

  @JsonProperty('estPublic', Boolean)
  public estPublic: boolean;

  @JsonProperty('visibilite', String)
  @Optional
  @CustomConverter(VisibiliteEnumConverter)
  public visibilite: VisibiliteEnum;

  @JsonProperty('estArchive', Boolean)
  public estArchive: boolean;

  @JsonProperty('versions', [VersionFichier])
  public versions: Array<VersionFichier>;

  @JsonProperty('intervenants', [Number])
  @Optional
  public intervenants: Array<number>;

  @JsonProperty('entreprises', [Number])
  @Optional
  public entreprises: Array<number>;

  @JsonProperty('codeSousTypes', [String])
  @Optional
  public codeSousTypes: Array<string>;

  public currentVersion: VersionFichier;

  public getVersion(): VersionFichier | null {
    let version: VersionFichier = null;
    if (this.versions != null ) {
      version = this.versions.sort((version1, version2) => 
        version2.dateCreation.diff(version1.dateCreation)
      )[0];
    }
    return version;
  }

}
