import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { DateTimeConverter } from 'src/app/core/services/mapper/converters';
import { CommissionSyntheseEdition } from './commission-synthese-edition.model';
import { CommissionEchantillonEdition } from './commission-echantillon-edition.model';

@JsonObject
export class CommissionCahierEdition {
    @JsonProperty('id', Number)
    @Optional
    id: number;

    @JsonProperty('libelle', String)
    @Optional
    libelle: string;
    
    @JsonProperty('synthese', CommissionSyntheseEdition)
    @Optional
    synthese: CommissionSyntheseEdition;

    @JsonProperty('echantillons', [CommissionEchantillonEdition])
    @Optional
    echantillons: CommissionEchantillonEdition[];
}
