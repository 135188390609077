<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
      page.commissions.edit.modals.select-jures.title
    </h4>
  </div>

  <div class="modal-body">
    <form [formGroup]="formGroup" #form="ngForm" (submit)="onSubmit()">

      <div class="modal-body" *ngFor="let jure of jures; let i=index;">
        <input type="checkbox" formControlName="jure" [(ngModel)]="jures[i].checked"
        (change)="isJuresCheck()"
          [ngClass]="{ 'is-invalid': form.submitted && formGroup.get('jure').invalid }">
        {{jure.nom}}
      </div>
      <div *ngIf="noJures" style="color: red;"> Veuillez sélectionner au moins un juré.</div>
      <div class="actions-bar">
        <button type="button" (click)="onClose()" class="btn btn-light mr-2" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary"
          translate>page.commissions.edit.modals.select-jures.envoyer-mail</button>
      </div>
    </form>
  </div>
</div>