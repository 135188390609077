import {JsonObject, JsonProperty, Optional} from '@thorolf/json-ts-mapper';
import {Espece} from './espece.model';

@JsonObject
export class Variete {
  @JsonProperty('id', Number)
  id: number;

  @JsonProperty('code', String)
  code: string;

  @JsonProperty('libelle', String)
  libelle: string;

  @JsonProperty('espece', Espece)
  @Optional
  public espece: Espece = undefined;

  @JsonProperty('varieteClone', Variete)
  @Optional
  public varieteClone: Variete = undefined;
}
