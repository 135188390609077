<div class="container-fluid">
    <div class="row justify-content-md-center">
        <div class="col col-6 height-100 text-center text-white bg-blue-dark" style="height:100vh">
            <div class="border-sidebar"></div>
            <div>
                <img src="/assets/img/logo-innov.png" class="logo" /><br />
            </div>
            
            <h5>Solution logicielle dédiée à la gestion numérique <br />des signes de qualité à destination des ODG, OC
                et OI​</h5>
        </div>
        <div class="col col-lg-6 text-center" style="background-color: white;">
            <div style="margin-top:20%">
                <fa-icon icon="user-circle" style="font-size:60px;color:#261d56;"></fa-icon>
                <h1>Connexion</h1>
                <div class="row justify-content-md-center">
                    <div class="col col-6">
                        <app-login (onLoginSuccess)="loginSuccess($event)" *ngIf="state === 'login'"></app-login>
                        <app-choix-domaine (onSelectDomaine)="selectDomaine($event)" *ngIf="state === 'domaine'">
                        </app-choix-domaine>
                        <app-choix-organisme [idDomaine]="idDomaine" (onSelectOrganisme)="selectOrganisme($event)"
                            *ngIf="state === 'organisme'"></app-choix-organisme>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>