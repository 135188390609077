<div class="content">
  <div class="content-panel">
    <h1 translate>page.edition.gestion-templates.title</h1>

    <div class="row">
      <div class="col-4">
        <label translate>page.edition.gestion-templates.categorie</label>
        <select [(ngModel)]="selectedCategorie" class="form-control" (change)="changeCategorie()" *ngIf="loaded">
          <option [ngValue]="null" class="placeholder" selected translate>
            page.edition.gestion-templates.choix-categorie</option>
          <option *ngFor="let categorie of categories" [ngValue]="categorie">
            {{categorie.libelle}}
          </option>
        </select>
      </div>
      <div class="col-4">
        <label translate>page.edition.gestion-templates.document</label>
        <select [(ngModel)]="selectedDocument" [disabled]="!selectedCategorie" class="form-control" *ngIf="loaded"
          (change)="changeDocument()">
          <option [ngValue]="null" class="placeholder" selected translate>
            page.edition.gestion-templates.choix-document</option>
          <option *ngFor="let document of documents" [ngValue]="document">
            {{document.libelle}}
          </option>
        </select>
      </div>
      <div style="margin-top: auto;" *ngIf="selectedCategorie && selectedCategorie.code !== 'COMMUN'">
        <button type="button" [disabled]="!selectedCategorie" (click)="addNewDocument()" class="btn btn-primary">
          <fa-icon icon="plus" class="primary-icon"></fa-icon>
        </button>
      </div>
      <div style="margin-top: auto;margin-left:20px" (mouseover)="showHelp=true" (mouseout)="showHelp=false">
        <h4>
          <fa-icon icon="info-circle"></fa-icon>
          Aide
        </h4>
      </div>
    </div>


    <div *ngIf="showHelp" style="margin-left: 20%; z-index: 8000; position: absolute;">
      <table class="helpPopin">
        <tbody>
          <tr>
            <td><b>Balises</b><br>
              Pour ajouter une balise au template, cliquez sur l'une des balises dans la liste à gauche</td>
          </tr>
          <tr>
            <td><b>Listes</b><br>
              Pour ajouter une liste, cliquez sur la balise "début liste" correspondant à l'élément que vous voulez
              lister,<br>
              puis sur les balises que vous voulez voir dans votre liste, et enfin, sur la balise "fin liste".</td>
          </tr>
          <tr>
            <td><b>Tableaux</b><br>
              Pour ajouter un tableau, cliquez sur l'icone des tableaux dans la bare du haut, et créez un tableau de 2
              lignes.<br>
              Vous pouvez mettre le nombre de colonne que vous voulez. Dans la première ligne, placez les libéllés que
              vous voulez.<br>
              Dans la 2eme ligne, placez dans la première colonne une balise "Début Tableau" (tout en bas de la liste
              des balises)<br>
              correspondant a l'objet pour lequel vous voulez un tableau, puis remplissez les colonnes avec les balises
              de l'objet.<br>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="selectedDocument" class="editeur">
      <div class="row">
        <div class="col-2" *ngIf="!disableEditor">
          <div *ngIf="selectedCategorie.code !== 'COMMUN'">
            <input type="checkbox" [(ngModel)]="selectedDocument.isEntete">
            En-tête et pied de page
          </div>
          <div *ngIf="selectedCategorie.code === 'COMMISSION'">
            <input type="checkbox" [(ngModel)]="selectedDocument.isIndividuel">
            Document individuel
          </div>
          <div>
            <input type="checkbox" [(ngModel)]="selectedDocument.isPaysage">
            Document en paysage (portrait par défaut)
          </div>
          <tree-root #tree [nodes]="nodes" [focused]="true" [options]="options" (activate)="onActivate($event)">
          </tree-root>
        </div>
        <div class="col-10">
          <div *ngIf="firstLoad">
            Chargement du template...
          </div>
          <editor apiKey="27fvxo9nzn4j1cjyt9c21rm0zy167pcziey9m35w7vwbpx1w" [(ngModel)]="dataModel"
            [init]="tinyMceConfig" [disabled]="disableEditor"></editor>
        </div>
      </div>
    </div>

    <div *ngIf="selectedDocument" class="buttons">
      <button class="btn btn-primary" *ngIf="selectedCategorie.code !== 'COMMUN'" (click)="addModelePersonnalise()" style="margin-right: 5px;"
        translate>page.edition.gestion-templates.creerModelePersonnalise</button>
      <button class="btn btn-primary" [disabled]="disableEditor" (click)="save()" style="margin-right: 5px;"
        translate>label.enregistrer</button>
    </div>
  </div>