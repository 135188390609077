import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { ReferencesEditionService } from '../references-edition/references-edition.service';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Balise } from '../../models/balise.model';


@Injectable({
  providedIn: 'root'
})
export class BaliseService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly refEditionService: ReferencesEditionService
  ) {

  }

  getBalisesByCategorie(idCategorie: number, idOrganisme: number): Observable<Balise[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/edition/private/organismes/${idOrganisme}/categories/${idCategorie}/balises`),
      // this.http.get<object[]>(`/assets/mocks/balises.json`),
      this.refEditionService.getReferences()
    ).pipe(
      map(([balises, ref]) => this.mapper.deserializeArray(balises,
        Balise, Object.assign({}, ref)))
    );
  }

  getBalises(): Observable<Balise[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/edition/private/balises`),
      // this.http.get<object[]>(`/assets/mocks/balises.json`),
      this.refEditionService.getReferences()
    ).pipe(
      map(([balises, ref]) => this.mapper.deserializeArray(balises,
        Balise, Object.assign({}, ref)))
    );
  }
}
