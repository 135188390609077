import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

import { RefStatut } from './statut.ref.model';
import { HabilitationStatutWorkflow } from './habilitation-statut-workflow';

@JsonObject
export class References {
  @JsonProperty('statutHabilitationReferences', [RefStatut])
  public statutsHabilitation: RefStatut[] = [];

  @JsonProperty('statutsHabilitationWorkflows', [HabilitationStatutWorkflow])
  public statutsHabilitationWorkflow: HabilitationStatutWorkflow[] = [];
}
