import { Injectable } from '@angular/core';
import {
  CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot,
  RouterStateSnapshot, UrlTree, Router
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const role = next.data.role;

    if ((role != null) && !this.authService.hasRole(role)) {
      console.warn(`[Guard] permission refused for route ${state.url}`, { requiredRoles: role });
      const parentUrl = state.url.slice(0, state.url.lastIndexOf('/') - 1);
      this.router.navigate([parentUrl]);

      return false;
    }

    return true;
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const role = next.data.role;

    if ((role != null) && !this.authService.hasRole(role)) {
      console.warn(`[Guard] permission refused for route ${state.url}`, { requiredRoles: role });
      const parentUrl = state.url.slice(0, state.url.lastIndexOf('/') - 1);
      this.router.navigate([parentUrl]);

      return false;
    }

    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const role = route.data.role;
    if ((role != null) && !this.authService.hasRole(role)) {
      this.router.navigate(['/']);

      return false;
    }

    return true;
  }
}
