import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Activite } from '../../models/activite.model';
import { Cache, NoCache, ClearCache } from 'src/app/core/services/cache/cache.service';
import { Context } from 'src/app/core/services/config/app.settings';

@Injectable({
  providedIn: 'root'
})
export class ActivitesService {

  constructor(
    protected readonly http: HttpClient,
    protected readonly mapper: JsonMapperService
  ) { }

  static activites: Activite[];

  static getActivites(): Activite[] {
    const activites = ActivitesService.activites;
    if (activites === undefined) {
      throw new Error('service-activites.static-data.unavailable');
    }
    return activites;
  }

  @ClearCache
  clearCache() { }

  @Cache()
  public getActivites(@NoCache noCache?: boolean): Observable<Activite[]> {
    return this.http.get(`/api/habilitation/private/activites`).pipe(
      map((response: object[]) => this.mapper.deserializeArray(response, Activite)),
      tap(activites => {
        ActivitesService.activites = activites;
      })
    );
  }
}
