import { Component, OnInit, Type } from '@angular/core';
import { ParcellairesComponent } from '../declarations/pages/parcellaires/parcellaires.component';
import { HistoriqueParcellaireComponent } from './pages/historique-parcellaire/historique-parcellaire.component';
import { Routes } from '@angular/router';

export interface Tab {
    title: string;
    component: Type<any>;
  }
  
@Component({
  selector: 'app-parcellaire-viticole',
  templateUrl: './parcellaire-viticole.component.html',
  styleUrls: ['./parcellaire-viticole.component.scss']
})
export class ParcellaireViticoleComponent implements OnInit {  
  
  tabs: Tab[] = [
    { title: 'Parcellaire', component: ParcellairesComponent },
    { title: 'Historique des DPAPs', component: HistoriqueParcellaireComponent }
  ];

  constructor() { }

  ngOnInit() {
  }

}
