<div id="accordion" class="accordion-overflow">
  <img *ngIf="isAnpp" class="logo-anpp" src="/assets/img/logo-anpp.png" width="155px"/>
  <ng-container *ngFor="let item of items; index as i;">
    <ng-container *ngIf="!item.hide">
      <ng-container *hasDomaine="item.domaines">
        <div class="card card-separation" *hasRole="item.roles">
          <div class="card-header card-header-background" [attr.id]="'heading'+ i">
            <h5 class="mb-0">
              <a [routerLink]="item.path" routerLinkActive="active" *ngIf="item.children.length === 0"
                (click)='closeAll()'>
                <fa-icon [icon]="item.icon"></fa-icon>
                <span>{{ item.libelle.replace('menu.', menu) | translate}}</span>
              </a>
              <a class="accordion-button" type="button" (click)='openCloseAccordion(i)' data-bs-toggle="collapse"
                [attr.data-target]="'#collapse' + i" aria-expanded="true" [attr.aria-controls]="'collapse' + i"
                *ngIf="item.children.length > 0">
                <fa-icon [icon]="item.icon"></fa-icon>
                <span>{{item.libelle.replace('menu.', menu) | translate}}</span>
              </a>
            </h5>
          </div>
          <div [attr.id]="'collapse' + i" class="accordion-collapse collapse" [class.show]='openAccordion[i]'
            [attr.aria-labelledby]="'heading' + i" data-bs-parent="#accordionExample">
            <div class="card-body text-white">
              <ng-container *ngFor="let children of item.children">
                <ng-container *hasDomaine="children.domaines">
                  <a [routerLink]="children.path" routerLinkActive="active" *hasRole="children.roles">
                    <span>{{children.libelle.replace('menu.', menu) | translate}}</span>
                  </a><br *hasRole="children.roles"/>
                </ng-container>
                
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>