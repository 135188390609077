<div class="content">
  <div class="content-panel p-3">
    <div class="form-group">
      <label for="typeahead-http">Rechercher un opérateur : </label>

      <input 
      #someInput
      id="typeahead-http" 
      [(ngModel)]="userSearch" 
      type="search" 
      [ngbTypeahead]="search" 
      (selectItem)="selectOperateur($event)"
      (focus)="focus$.next($event.target.value)"
      placeholder="{{'page.operateurs.recherche-affilie.placeholder' | translate}}" 
      [editable]="false"
      class="form-control"
      />

      <div class="mt-1">
        <span *ngIf="searching" translate>label.chargement</span>
        <span *ngIf="noResult" translate>label.aucunResultat</span>
      </div>
    </div>

    <div fxLayout="row" style="justify-content: flex-end;">
      <button type="button" routerLink="../ajout-operateur" class="btn btn-primary">Ajouter un opérateur à
        l'organisme</button>
    </div>
  </div>
</div>