import { JsonObject, JsonProperty, Optional, CustomConverter } from '@thorolf/json-ts-mapper';
import { Cahier } from '../../habilitation/models/cahier.model';
import { Produit } from '../../habilitation/models/produit.model';
import { Activite } from '../../habilitation/models/activite.model';
import { RefMouvement } from './ref-mouvement.model';
import { NatureControle } from './nature-controle.model';
import { ObjetControle } from './objet-controle.model';
import { PressionDeclencheur } from './pression-declencheur.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { Campagne } from './campagne.model';

@JsonObject
export class PressionControle {
    @JsonProperty('id', Number)
    @Optional
    id: number = undefined;

    @JsonProperty('estInterne', Boolean)
    estInterne: boolean = undefined;

    @JsonProperty('libelle', String)
    libelle: string = undefined;

    @JsonProperty('controleNature', Number)
    @CustomConverter(DataIdConverterFactory.build(NatureControle, 'naturesControle'))
    nature: NatureControle;

    @JsonProperty('controleObjet', Number)
    @CustomConverter(DataIdConverterFactory.build(ObjetControle, 'objetsControle'))
    objet: ObjetControle;

    @JsonProperty('cahiers', [Number])
    @CustomConverter(DataIdConverterFactory.build(Cahier, 'cahiers'))
    cdcs: Cahier[] = undefined;

    @JsonProperty('produits', [String])
    @Optional
    codesProduit: string[] = undefined;

    @JsonProperty('activites', [Number])
    @CustomConverter(DataIdConverterFactory.build(Activite, 'activites'))
    @Optional
    activites: Activite[];

    @JsonProperty('declencheur', Number)
    @CustomConverter(DataIdConverterFactory.build(PressionDeclencheur, 'pressionDeclencheurs'))
    declencheur: PressionDeclencheur;

    @JsonProperty('typesMouvement', [Number])
    @CustomConverter(DataIdConverterFactory.build(RefMouvement, 'refMouvements'))
    typesMouvement: RefMouvement[];

    @JsonProperty('pression', Number)
    pression: number = undefined;

    @JsonProperty('estAnneeCivile', Boolean)
    estAnneeCivile: boolean = undefined;

    @JsonProperty('campagnes', [Number])
    @CustomConverter(DataIdConverterFactory.build(Campagne, 'campagnes'))
    @Optional
    campagnes: Campagne[];

    produits: Produit[] = [];
}
