import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { LaboratoireAnpp } from '../../models/engagements/laboratoire-anpp.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LaboratoireAnppService {

  constructor(  private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
) { }

public getLaboratoires(): Observable<LaboratoireAnpp[]> {
  return this.http.get<Object[]>(`/api/habilitation/private/laboratoiresAnpp`)
  .pipe(
    map(laboratoires => this.mapper.deserializeArray(laboratoires, LaboratoireAnpp))
  );
}

public getLaboratoiresResidus(): Observable<LaboratoireAnpp[]> {
  return this.http.get<Object[]>(`/api/habilitation/private/laboratoiresAnpp/residus`)
  .pipe(
    map(laboratoires => this.mapper.deserializeArray(laboratoires, LaboratoireAnpp))
  );
}

public getLaboratoiresSols(): Observable<LaboratoireAnpp[]> {
  return this.http.get<Object[]>(`/api/habilitation/private/laboratoiresAnpp/residus`)
  .pipe(
    map(laboratoires => this.mapper.deserializeArray(laboratoires, LaboratoireAnpp))
  );
}


}
