import { Injectable } from "@angular/core";
import { EMPTY, Observable } from "rxjs";
import { distinctUntilChanged, map, skipWhile } from "rxjs/operators";
import { Campagne } from "../../models/campagne.model";
import { RefCampagnesService } from "../../services/ref-campagnes/ref-campagnes.service";

/**
 * Façade qui gère des observables sur les campagnes de référence à partager entre les composants
 * @author Guillaume Alabarbe <guillaume.alabarbe.ext@adventiel.fr>
 */
@Injectable({ providedIn: 'root' })
export class RefCampagnesFacade {

  /**
   * Observable qui émet toutes les {@link Campagne} sans tri préalable
   */
  public refCampagnes$: Observable<Array<Campagne>> = this.refCampagneService.getCampagneAll().pipe(
    skipWhile((refCampagnes: Array<Campagne>) => refCampagnes == null),
    distinctUntilChanged(),
    map((refCampagnes: Array<Campagne>) => refCampagnes)
  );

  /**
   * Observable qui émet toutes les {@link Campagne} par dates croissantes
   */
  public refCampagnesOrderByYearsASC$: Observable<Array<Campagne>> = this.refCampagnes$.pipe(
    skipWhile((refCampagnes: Array<Campagne>) => refCampagnes == null),
    distinctUntilChanged(),
    map((refCampagnes: Array<Campagne>) => refCampagnes.sort((a, b) => this.sortByDate(a, b)))
  );

  /**
   * Observable qui émet toutes les {@link Campagne} par dates décroissantes
   */
  public refCampagnesOrderByYearsDESC$: Observable<Array<Campagne>> = this.refCampagnes$.pipe(
    skipWhile((refCampagnes: Array<Campagne>) => refCampagnes == null),
    distinctUntilChanged(),
    map((refCampagnes: Array<Campagne>) => refCampagnes.sort((a, b) => this.sortByDate(a, b, false)))
  );

  /**
   * Observable qui émet toutes les {@link Campagne} par dates décroissantes avec une date début ANPP
   */
  public refCampagnesWithDateDebutOrderByYearsDESC$: Observable<Array<Campagne>> = this.refCampagnesOrderByYearsDESC$.pipe(
    skipWhile((refCampagnes: Array<Campagne>) => refCampagnes == null),
    distinctUntilChanged(),
    map((refCampagnes: Array<Campagne>) => refCampagnes
      .filter((refCampagne: Campagne) => refCampagne.dateDebutAnpp)
      .sort((a, b) => this.sortByDate(a, b, false))
    )
  );

  constructor(private refCampagneService: RefCampagnesService) {}

  /**
   * Appelle le service ref-campagnes pour charger les données de toutes les {@link Campagne}
   */
  public loadRefCampagnes(): void {
    this.refCampagnes$.subscribe();
  }

  /**
   * Compare les années de deux objets de type {@link Campagne} pour les classer par ordre ascendant ou descendant
   * @param a Première campagne à comparer
   * @param b Seconde campagne à comparer
   * @param asc Détermine si le tri est ASC ou DESC (Optionnel - true par défaut)
   * @returns 0 si les années sont égales, 
   */
  private sortByDate(a: Campagne, b: Campagne, asc: boolean = true): number {
    let result = 0;
    if (a && b && a.annee > b.annee) result = asc ? 1 : -1;
    if (a && b && a.annee < b.annee) result = asc ? -1 : 1;
    return result;
  }
}
