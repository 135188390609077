import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class Scope {
  @JsonProperty('idOrganisme', Number)
  @Optional
  idOrganisme: number = undefined;

  @JsonProperty('idDomaine', Number)
  @Optional
  idDomaine: number = undefined;
}
