import { JsonProperty, JsonObject } from '@thorolf/json-ts-mapper';

@JsonObject
export class Interlocuteur {
  @JsonProperty('id', Number)
  id: number;

  @JsonProperty('nom', String)
  nom: string;

  @JsonProperty('prenom', String)
  prenom: string;

  nomComplet: string;
}
