<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
      page.declarations.drev.modal.vci.title
    </h4>
  </div>

  <div class="modal-body">
    <div class="flex flex-row">
      <!-- tanks sidebar -->
      <div class="tanks-sidebar">
        <h3 [translateParams]="{annee:campagne}" translate>page.declarations.drev.modal.vci.declaration-recolte</h3>
        <div class="flex flex-row tanks">
          <div>
            <app-tank [data]="{value:100, color:'#875ebf'}"></app-tank>
            <div>
              {{drev.vinsEnCours | number:'1.0-2'}} hL <span>(L15 - L8)</span>
            </div>
          </div>

          <strong>+</strong>

          <div>
            <app-tank [data]="{value:getTankValue('vciEnCours'), color:'#AD92D3'}"></app-tank>
            <div>
              {{drev.vciEnCours | number:'1.0-2'}} hL <span>({{'page.declarations.drev.modal.vci.stock-vci-L19' | translate}})</span>
            </div>
          </div>

          <div class="separateur"></div>

          <div>
            <app-tank [data]="[{value:getTankValue('stockVci'), color:'#f68235'}]"></app-tank>
            <div>
              {{getFieldValue('stock') | number:'1.0-2' }} hL <span>({{'page.declarations.drev.modal.vci.stock-vci-n-1' | translate:{'annee':campagne-1} }})</span>
            </div>
          </div>
        </div>

        <hr/>

        <h3 [translateParams]="{annee:campagne}" translate>page.declarations.drev.modal.vci.declaration-revendication</h3>
        <div class="flex flex-row tanks">
          <div>
            <app-tank [data]="getDrevTankData()"></app-tank>
            <div>
              {{drev.vinsEnCours - getFieldValue('remplacement') + getFieldValue('complement') | number:'1.0-2' }} hL <span>(DREV {{campagne}})</span>
            </div>
          </div>

          <div>
            <app-tank [data]="getVciTankData()"></app-tank>
            <div>
              {{ getFieldValue('remplacement') + drev.vciEnCours | number:'1.0-2' }} hL <span>(VCI {{campagne}})</span>
            </div>
          </div>

          <div style="width:25%;"></div>
        </div>
      </div>
      <!-- end tanks sidebar -->
      <div class="separateur" style="height:auto;margin: 0 0.5em;"></div>
      <!-- form -->
      <form id="form" [formGroup]="formGroup" #form="ngForm" (ngSubmit)="submit()" novalidate  *ngIf="loaded">
        <h3 [translateParams]="{annee:campagne-1}" translate>page.declarations.drev.modal.vci.utilisation-stock-vci</h3>

        <div class="form-group">
          <label for="stock" translate>page.declarations.drev.modal.vci.stock</label>
          <div style="width:100%">
            <input type="number" lang="en" formControlName="stock" class="form-control" [ngClass]="{'is-invalid': form.submitted && getField('stock').invalid}" />
            <div *ngIf="form.submitted && form.submitted && getField('stock').invalid && getField('stock').errors.somme_incorrecte" class="msg_erreur" translate>page.declarations.drev.modal.vci.somme_incorrecte</div>
          </div>
        </div>

        <hr/>

        <div class="form-group">
          <label for="remplacement" translate>page.declarations.drev.modal.vci.remplacement</label>
          <input type="number" lang="en" formControlName="remplacement" class="form-control" [ngClass]="{'is-invalid': form.submitted && getField('remplacement').invalid}" />
        </div>

        <div class="form-group">
          <label for="substitution" translate>page.declarations.drev.modal.vci.substitution</label>
          <input type="number" lang="en" formControlName="substitution" class="form-control" [ngClass]="{'is-invalid': form.submitted && getField('substitution').invalid}" />
        </div>

        <div class="form-group">
          <label for="complement" translate>page.declarations.drev.modal.vci.complement</label>
          <input type="number" lang="en" formControlName="complement" class="form-control" [ngClass]="{'is-invalid': form.submitted && getField('complement').invalid}" />
        </div>

        <div class="form-group">
          <label for="detruit" translate>page.declarations.drev.modal.vci.detruit</label>
          <input type="number" lang="en" formControlName="detruit" class="form-control" [ngClass]="{'is-invalid': form.submitted && getField('detruit').invalid}" />
        </div>
      </form>
      <!-- end form -->
    </div>

    <div class="actions-bar">
      <button class="btn btn-light" (click)="dismiss()" translate>label.annuler</button>
      <button type="submit" class="btn btn-primary" form="form" translate>label.valider</button>
    </div>
  </div>
</div>
