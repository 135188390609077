import { Component, OnInit } from '@angular/core';
import {Routes} from '@angular/router';
import {ListeJuresComponent} from './pages/liste-jures/liste-jures.component';
import {UpdateJureComponent} from './pages/update-jure/update-jure.component';
import {AddEditQualificationComponent} from './modal/add-edit-qualification/add-edit-qualification.component';

@Component({
  selector: 'app-jures',
  templateUrl: './jures.component.html',
  styleUrls: ['./jures.component.scss']
})
export class JuresComponent implements OnInit {
  static routeData = {
    role: 'DECLARATION_TOURNEE_CONSULTER && ORGANISME',
    domaines: ["VIN"],
    menu: {
      icon: 'users',
      libelle: 'menu.administration-jures'
    }
  };
  static routes: Routes = [
    { path: '', component: ListeJuresComponent },
    { path: 'jure/addJure', component: UpdateJureComponent },
    { path: 'jure/updateJure/:id', component: UpdateJureComponent },
    { path: 'add', redirectTo: 'new/edit' },
    { path: 'qualification/addQualification', component: AddEditQualificationComponent },
    { path: 'qualification/updateQualification/:id', component: AddEditQualificationComponent },
  ];

  constructor() { }

  ngOnInit() {
  }

}
