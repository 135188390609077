import { Component, OnInit, Input, Output } from '@angular/core';
import { Onglet } from 'src/app/data/intervenant/interfaces/onglet.interface';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Manquement } from 'src/app/data/declaration/models/manquement.model';
import { Decision } from 'src/app/data/declaration/models/decision.model';
import * as moment from 'moment';
import { ManquementsService } from 'src/app/data/declaration/services/manquements/manquements.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { Controle } from 'src/app/data/declaration/models/controle.model';

@Component({
  selector: 'app-saisir-decision',
  templateUrl: './saisir-decision.component.html',
  styleUrls: ['./saisir-decision.component.scss']
})
export class SaisirDecisionComponent implements OnInit, Onglet {
  @Input() public controle: Controle;
  @Input() public estOnglet = false;
  @Input() @Output() public manquement: Manquement;
  private idOrganisme: number;
  public formDecision: FormGroup;
  get dateTransmission() { return this.formDecision.get('dateTransmission'); }
  get dateDecision() { return this.formDecision.get('dateDecision'); }
  get auteur() { return this.formDecision.get('auteur'); }
  get applicationSanction() { return this.formDecision.get('applicationSanction'); }
  get sanction() { return this.formDecision.get('sanction'); }
  get commentaire() { return this.formDecision.get('commentaire'); }

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly fb: FormBuilder,
    private readonly manquementsService: ManquementsService
  ) { }

  ngOnInit() {
    this.idOrganisme = SessionContext.get('idOrganisme');

    this.formDecision = this.fb.group({
      dateTransmission: this.getCustomDateFromMoment(this.manquement.decision.dateTransmission),
      dateDecision: this.getCustomDateFromMoment(this.manquement.decision.dateDecision),
      auteur: this.manquement.decision.auteur,
      applicationSanction: this.manquement.decision.applicationSanction,
      sanction: [{value: this.manquement.decision.idSanction, disabled: !this.manquement.decision.applicationSanction}],
      commentaire: this.manquement.decision.commentaire
    });
  }

  public async onEdit(): Promise<boolean> {
    return new Promise(resolve => {
      const decision = Object.assign(new Decision(), {
        dateTransmission: this.getMomentDate(this.dateTransmission.value),
        dateDecision: this.getMomentDate(this.dateDecision.value),
        auteur: this.auteur.value,
        applicationSanction: !!this.applicationSanction.value,
        idSanction: this.sanction.value,
        commentaire: this.commentaire.value
      });

      this.manquement.decision = decision;
      this.manquementsService.putDecision(this.idOrganisme, this.controle.id, this.manquement).subscribe(() => {
        this.formDecision.markAsUntouched();
        resolve(true);
      });
    });
  }

  private getMomentDate(date: {
    year: number,
    month: number,
    day: number
  }): moment.Moment {
    return (date) ? moment([date.year, date.month - 1, date.day, 0, 0, 0]) : null;
  }

  private getCustomDateFromMoment(date: moment.Moment) {
    return (date) ? {
      year: date.year(),
      month: date.month() + 1,
      day: date.date()
    } : undefined;
  }

  public onSanctionCheckedChange() {
    if (this.applicationSanction.value) {
      this.sanction.enable();
    } else {
      this.sanction.disable();
      this.sanction.setValue(null);
    }
  }
}
