import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Innov';

  private readonly DEFAULT_LANG = 'fr';
  AVAILABLE_LANGS = ['fr', 'en'];

  constructor(public translate: TranslateService) {
    this.setLanguages();
  }

  setLanguages() {
    registerLocaleData(localeFr, 'fr');

    this.translate.addLangs(this.AVAILABLE_LANGS);
    this.translate.setDefaultLang(this.DEFAULT_LANG);

    const browserLang = this.translate.getBrowserLang();
    const availableLangsRegexp = new RegExp(this.AVAILABLE_LANGS.join('|'));
    this.translate.use(browserLang.match(availableLangsRegexp) ? browserLang : this.DEFAULT_LANG);
  }
}
