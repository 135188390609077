export abstract class FileDownloadTools {

    public static b64 =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    public static b64re: RegExp = /^(?:[A-Za-z\d+\/]{4})*?(?:[A-Za-z\d+\/]{2}(?:==)?|[A-Za-z\d+\/]{3}=?)?$/;
    public static b64ToBlob(
        b64Data: string,
        contentType: string,
        sliceSize: number = 512
    ): Blob {
        const byteCharacters: string = this.advAtoB(b64Data);
        const byteArrays: Uint8Array[] = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice: string = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers: number[] = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray: Uint8Array = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob: Blob = new Blob(byteArrays, {
            type: contentType
        });
        return blob;
    }

    public static advAtoB(str: string): string {
        str = str.replace(/[\t\n\f\r ]+/g, '');
        //if (!this.b64re.test(str)) {
        //    throw new TypeError(
        //        `Failed to execute 'atob' on 'Window': The string to be decoded is not correctly encoded.`
        //    );
        //}
        str += '=='.slice(2 - (str.length & 3));
        let bitmap: number,
            result = '',
            r1: number,
            r2: number,
            i = 0;
        for (; i < str.length;) {
            bitmap =
                (this.b64.indexOf(str.charAt(i++)) << 18) |
                (this.b64.indexOf(str.charAt(i++)) << 12) |
                ((r1 = this.b64.indexOf(str.charAt(i++))) << 6) |
                (r2 = this.b64.indexOf(str.charAt(i++)));

            result +=
                r1 === 64
                    ? String.fromCharCode((bitmap >> 16) & 255)
                    : r2 === 64
                        ? String.fromCharCode((bitmap >> 16) & 255, (bitmap >> 8) & 255)
                        : String.fromCharCode(
                            (bitmap >> 16) & 255,
                            (bitmap >> 8) & 255,
                            bitmap & 255
                        );
        }
        return result;
    }

    public static advBtoA(str: string): string {
        let bitmap: number,
            a: number,
            b: number,
            c: number,
            result = '',
            i = 0;
        const rest: number = str.length % 3;

        for (; i < str.length;) {
            if (
                (a = str.charCodeAt(i++)) > 255 ||
                (b = str.charCodeAt(i++)) > 255 ||
                (c = str.charCodeAt(i++)) > 255
            ) {
                throw new TypeError(
                    `Failed to execute 'btoa' on 'Window': The string to be encoded contains characters outside of the Latin1 range.`
                );
            }

            bitmap = (a << 16) | (b << 8) | c;
            result +=
                this.b64.charAt((bitmap >> 18) & 63) +
                this.b64.charAt((bitmap >> 12) & 63) +
                this.b64.charAt((bitmap >> 6) & 63) +
                this.b64.charAt(bitmap & 63);
        }

        return rest ? result.slice(0, rest - 3) + '==='.substring(rest) : result;
    }
}
