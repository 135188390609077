import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { Jwt } from './jwt.model';

@JsonObject
export class Cookie {

  @JsonProperty('jwt', Jwt)
  jwt: Jwt = undefined;

  @JsonProperty('idOrganisme', Number)
  @Optional
  idOrganisme: number = undefined;

  @JsonProperty('idDomaine', Number)
  @Optional
  idDomaine: number = undefined;
}
