import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class RefUnite {
    @JsonProperty('idUnite', Number)
    id: number = undefined;

    @JsonProperty('codeUnite', String)
    code: string = undefined;

    @JsonProperty('libelleUnite', String)
    libelle: string = undefined;
}
