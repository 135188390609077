<div class="modal-content">
  <!-- header fenêtre surgissante -->
  <div class="modal-header">
    <h5 class="modal-title" translate><fa-icon icon="file-alt"></fa-icon> page.suivi.suivi-ecoulements.estimations-modal.title</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- body fenêtre surgissante -->
  <div class="modal-body">
    <table class="table table-striped">
      <thead>
        <tr>
          <th translate>page.suivi.suivi-ecoulements.estimations-modal.espece</th>
          <th translate>page.suivi.suivi-ecoulements.estimations-modal.groupe-varietal</th>
          <th translate>page.suivi.suivi-ecoulements.estimations-modal.estimation</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let estimation of estimations; let index = index" [ngClass]="{ 'pointilles': index == indexEspecesDifferentes }">
          <td>
            <fa-icon *ngIf="estimation.espece === 'POMME'" icon="apple-alt"></fa-icon>
            <img *ngIf="estimation.espece === 'POIRE'" class="pear-img" src="/assets/img/pear.png" alt="Poire" />
          </td>
          <td>{{ estimation.groupeVarietal }}</td>
          <td>{{ estimation.estimation | saisieRecolteFormat }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
