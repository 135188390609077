import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-recapitulatif',
  templateUrl: './recapitulatif.component.html',
  styleUrls: ['./recapitulatif.component.scss']
})
export class RecapitulatifComponent implements OnInit {
  @Input() public ecoulements;
  

  constructor(
    public readonly modal: NgbActiveModal,    
  ) {
    //super(modal);
  }


  ngOnInit() {


  }
  save() {
    this.modal.close(true);
  }

  cancel() {
    this.modal.close();
  }
}
