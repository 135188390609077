import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RefCultureBio} from '../../models/ref-culture-bio.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RefCultureBioService {

  constructor( private readonly http: HttpClient) { }

  getRefCultureBio(): Observable<RefCultureBio[]> {
    return this.http.get<RefCultureBio[]>(`/api/declaration/private/referentielCultureBio`);
  }

  public creerRefCultureBio(cultureBio: RefCultureBio): Observable<number> {
    return this.http.post(`/api/declaration/private/referentielCultureBio`, cultureBio, { observe: 'response' }).pipe(
      map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
    );
  }  
  
  public modifierRefCultureBio(cultureBio: RefCultureBio ): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/referentielCultureBio/${cultureBio.id}`, cultureBio );
  }
  
  public desactiverRefCultureBio(cultureBio: RefCultureBio): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/referentielCultureBio/${cultureBio.id}?desactivation=${true}`, cultureBio );
  }

}
