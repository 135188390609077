<div class="row" [ngClass]="{'collapse-margin':isMaskedMenu, 'anpp': isAnpp}">
  <div class="col-lg-12 fixed-top pl-0 mt-2 ">
    <div class="row">
      <div class="col-lg-2 pt-2">
        <button id="sidebarCollapse"  [ngClass]="{
          'collapse-background': isMaskedMenu && !isAnpp,
        'background-secondary': isMaskedMenu && isAnpp 
      }" type="button" (click)="showMenu()">
          <fa-icon class="mr-2 text-white" icon="bars"></fa-icon> 
          <img *ngIf="!isAnpp" src="/assets/img/logo-innov.png" width="145px" />
          <img *ngIf="isAnpp" src="/assets/img/logo-innov.png" width="145px" />
        </button>
      </div>
      <div class="col-lg-10 text-right">
        <a *ngIf="!isAdmin() && canChangeScope" class="vertical-menu link-secondary" (click)="changeScope()" translate>label.changeScope</a>
        <a *ngIf="canModifyPwd && utilisateur" class="vertical-menu link-secondary" (click)="goToMonCompte()">
          <fa-icon icon="user" class="pr-1"></fa-icon> <span class="pr-4">{{utilisateur.prenom}} {{utilisateur.nom |
            uppercase}}</span>
        </a>
        <a (click)="logOut()" class="link-secondary" style="margin-right: 10px;" title="Déconnexion">
          <fa-icon icon="sign-in-alt"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</div>
<div fxFlexFill fxLayout="column" [ngClass]="{'anpp': isAnpp}">
  <!-- <div style='position: absolute;right: 0'>
    <a *ngIf="!isAdmin() && canChangeScope" (click)="changeScope()" translate>label.changeScope</a>
    <a *ngIf="canModifyPwd" (click)="editPwd()"><fa-icon icon="user"></fa-icon>Mathieu SUTTY</a>
    <a (click)="logOut()"><fa-icon icon="sign-in-alt"></fa-icon> </a>
  </div> -->
  <div fxFlex fxLayout="row">
    <div class="vertical-nav bg-nav background-secondary" id="sidebar" [ngClass]="{maskmenu: isMaskedMenu}">

      <div class="border-sidebar"></div>
      <app-sidebar [isAnpp]="isAnpp" style="max-width: 194px;"></app-sidebar>


    </div>

    <div fxFlex fxLayout="column" *ngIf="displayPage" id="page-content" class="page-content"
      [ngClass]="{'maskmenu':isMaskedMenu}">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>