import { Component, OnInit } from '@angular/core';
import { SessionContext } from 'src/app/core/services/config/app.settings';

@Component({
  selector: 'app-no-roles',
  templateUrl: './no-roles.component.html',
  styleUrls: ['./no-roles.component.scss']
})
export class NoRolesComponent implements OnInit {

  public typeUtilisateur: string;

  constructor() { }

  ngOnInit() {
    this.typeUtilisateur = SessionContext.get('utilisateurType');
  }

}
