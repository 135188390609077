import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { Contact } from './contact.model';
import { Entreprise } from './entreprise.model';

@JsonObject
export class MonCompte {

  @JsonProperty('adresse', String)
  @Optional
  adresse: string = undefined;

  @JsonProperty('codePostal', String)
  @Optional
  codePostal: string = undefined;

  @JsonProperty('email', String)
  email: string = undefined;

  @JsonProperty('emailEntreprise', String)
  @Optional
  emailEntreprise: string = undefined;

  @JsonProperty('fax', String)
  @Optional
  @Optional
  fax: string = undefined;

  @JsonProperty('nom', String)
  @Optional
  nom: string = undefined;

  @JsonProperty('prenom', String)
  @Optional
  prenom: string = undefined;

  @JsonProperty('raisonSociale', String)
  @Optional
  raisonSociale: string = undefined;

  @JsonProperty('siret', String)
  @Optional
  siret: string = undefined;

  @JsonProperty('structureJuridique', String)
  @Optional
  structureJuridique: string = undefined;

  @JsonProperty('telephoneFixe', String)
  @Optional
  telephoneFixe: string = undefined;

  @JsonProperty('telephoneMobile', String)
  @Optional
  telephoneMobile: string = undefined;

  @JsonProperty('ville', String)
  @Optional
  ville: string = undefined;

  @JsonProperty('notificationDocumentaire', Boolean)
  @Optional
  notificationDocumentaire: boolean = undefined;

  @JsonProperty('contacts', [Contact])
  @Optional
  contacts: Contact[] = undefined;

  @JsonProperty('groupementProducteurs', Entreprise)
  @Optional
  groupementProducteurs: Entreprise = undefined;

}
