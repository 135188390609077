import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class EngagementGraph {
  @JsonProperty('libelleRefStatut', String)
  @Optional
  public libelleRefStatut: string = undefined;

  @JsonProperty('codeRefStatut', String)
  @Optional
  public codeRefStatut: string = undefined;

  @JsonProperty('value', Number)
  @Optional
  public value: number = undefined;


}
