import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { RefCampagnesService } from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';
import { ContactEngagement } from 'src/app/data/habilitation/models/engagements/contact-engagement';
import { EngagementBase } from 'src/app/data/habilitation/models/engagements/engagement-base';
import { EntrepriseEngagement } from 'src/app/data/habilitation/models/engagements/entreprise-engagement';
import { IntervenantEngagement } from 'src/app/data/habilitation/models/engagements/intervenant-engagement';
import { LaboratoireAnpp } from 'src/app/data/habilitation/models/engagements/laboratoire-anpp.model';
import { RefCampagne } from 'src/app/data/habilitation/models/engagements/ref.campagne.model';
import { EngagementVergersEcoService } from 'src/app/data/habilitation/services/engagements/engagement-vergers-eco.service';
import { LaboratoireAnppService } from 'src/app/data/habilitation/services/laboratoires/laboratoire-anpp.service';
import { Contact } from 'src/app/data/intervenant/models/contact.model';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { Utilisateur } from 'src/app/data/intervenant/models/utilisateur.model';
import { ContactsService } from 'src/app/data/intervenant/services/contacts/contacts.service';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { UtilisateursService } from 'src/app/data/intervenant/services/utilisateurs/utilisateurs.service';

@Component({
  selector: 'app-formulaire-pi',
  templateUrl: './formulaire-pi.component.html',
  styleUrls: ['./formulaire-pi.component.scss']
})
export class FormulairePiComponent implements OnInit {
  static routes: Routes = [
    {
      path: '', component: FormulairePiComponent, data: {
        domaines: ['ANPP'],
        menu: {
          libelle: 'formulaire'
        }
      }
    }
  ];
  @Input() public idEngagement: number;
  public formGroup: FormGroup;
  public engagement: EngagementBase;
  public entreprise: Entreprise;
  public contacts: Contact[];
  public contactsReferents: Contact[];
  public conseillers: Utilisateur[];
  public refCampagne: RefCampagne;
  public organismesCertification: EntrepriseEngagement[];
  public creation: Boolean = false;
  public isAnpp: Boolean;
  public readOnly: Boolean = false;
  public laboratoireResidusList: LaboratoireAnpp[];
  public laboratoireSolsList: LaboratoireAnpp[];

  public get raisonsociale() { return this.formGroup.get('raisonsociale'); }
  public get responsable() { return this.formGroup.get('responsable'); }
  public get activiteProduction() { return this.formGroup.get('activiteProduction'); }
  public get globalGap() { return this.formGroup.get('globalGap'); }
  public get engagementHve() { return this.formGroup.get('engagementHve'); }
  public get typeEngagementHve() { return this.formGroup.get('typeEngagementHve'); }
  public get activiteConditionnement() { return this.formGroup.get('activiteConditionnement'); }
  public get brc() { return this.formGroup.get('brc'); }
  public get ifs() { return this.formGroup.get('ifs'); }
  public get utilisationLogo() { return this.formGroup.get('utilisationLogo'); }
  public get contactReferentEco() { return this.formGroup.get('contactReferentEco'); }
  public get conseillerTechnique() { return this.formGroup.get('conseillerTechnique'); }
  public get organismeCertification() { return this.formGroup.get('organismeCertification'); }
  public get laboratoireResidus() { return this.formGroup.get('laboratoireResidus'); }
  public get laboratoireSols() { return this.formGroup.get('laboratoireSols'); }

  constructor(private fb: FormBuilder,
    private loaderService: AdvBootstrapLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private readonly utilisateursService: UtilisateursService,
    private readonly contactService: ContactsService,
    private readonly translate: TranslateService,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly engagementVergersEcoService: EngagementVergersEcoService,
    private readonly refCampagnesService: RefCampagnesService,
    private readonly authService: AuthService,
    private readonly entrepriseService: EntreprisesService,
    private readonly laboratoireAnppService: LaboratoireAnppService) { }

  ngOnInit() {
    this.isAnpp = this.authService.isAnpp();

    //Vérification si il n'a pas d'id ou si on est pas sur la fiche entreprise dans l'url auquel cas on active le mode création
    let id;
    if (this.idEngagement) {
      id = this.idEngagement;
    } else {
      id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    }
    if (!id) {
      this.initForm();
      this.creation = true;
      this.utilisateursService.getUtilisateur(SessionContext.get('idIntervenant')).subscribe(utilisateur => {
        this.loadData(utilisateur.entreprise);
      });

    } else {
      //Si on est en mode modification et que l'utilisateur n'est pas anpp alors on vérouille le formulaire en lecture seule
      if (this.isAnpp == false || this.idEngagement) {
        this.readOnly = true;
      }
      this.initForm();
      //Si l'id est pésent dans l'url, on récupère l'engagement en base de données
      this.engagementVergersEcoService.getEngagementEcoById(id).pipe(this.loaderService.operator()).subscribe(engagement => {
        this.engagement = engagement;
        //this.typeEngagement.setValue("renouvellement");
        //remplissage du formulaire
        this.loadData(engagement.entreprise);
        //this.loadData();
      });
    }
  }

  initForm() {
    this.formGroup = this.fb.group({
      raisonsociale: [{ value: undefined, disabled: true }, Validators.required],
      responsable: [{ value: undefined, disabled: this.readOnly }, Validators.required],
      activiteProduction: [{ value: undefined, disabled: this.readOnly }, Validators.required],
      globalGap: [{ value: undefined, disabled: this.readOnly }],
      engagementHve: [{ value: undefined, disabled: this.readOnly }],
      typeEngagementHve: [{ value: undefined, disabled: this.readOnly }],
      activiteConditionnement: [{ value: undefined, disabled: this.readOnly }, Validators.required],
      brc: [{ value: undefined, disabled: this.readOnly }],
      ifs: [{ value: undefined, disabled: this.readOnly }],
      utilisationLogo: [{ value: undefined, disabled: this.readOnly }],
      contactReferentEco: [{ value: undefined, disabled: this.readOnly }, Validators.required],
      conseillerTechnique: [{ value: undefined, disabled: this.readOnly }, Validators.required],
      organismeCertification: [{ value: undefined, disabled: this.readOnly }, Validators.required],
      laboratoireResidus: [{ value: undefined, disabled: this.readOnly }],
      laboratoireSols: [{ value: undefined, disabled: this.readOnly }],
    });
  }

  loadData(entreprise: Entreprise) {
    const date = new Date();
    forkJoin([this.entrepriseService.getEntreprisesBySousRefType(["OC_DEFAUT"]),
    this.refCampagnesService.getCampagneAnnee(date.getFullYear()),
    this.laboratoireAnppService.getLaboratoires()])
      .pipe(this.loaderService.operator()).subscribe(([organismesCertificateurs, campagne, laboratoires]) => {
        this.refCampagne = <RefCampagne>{
          id: campagne.id,
          code: campagne.code,
          annee: campagne.annee
        };
        this.organismesCertification = organismesCertificateurs;
        this.entreprise = entreprise;
        this.laboratoireResidusList = laboratoires.filter(l => l.typeCode === 'RESIDUS');
        this.laboratoireSolsList = laboratoires.filter(l => l.typeCode === 'SOLS');
        forkJoin([this.contactService.getContactsByEntreprise(entreprise.id),
        this.utilisateursService.getConseillersTechniques(entreprise.id)]).subscribe(([contacts, conseillersTechniques]) => {
          this.contacts = contacts;
          this.contactsReferents = contacts.filter(c => c.referents.find(r => r.code === 'VER'));
          this.conseillers = conseillersTechniques.sort((a, b) => (a.nom < b.nom) ? -1 : 1);
          if (this.creation) {
            this.refCampagnesService.getCampagneVerPrecedente().subscribe(campagnePrecedente => {
              this.engagementVergersEcoService.getEngagementEcoByEntrepriseAndCampagne(entreprise.id, campagnePrecedente.code).subscribe(dernierEngagement => {
                if (dernierEngagement != null) {
                  this.engagement = dernierEngagement;
                  this.engagement.refCampagne = this.refCampagne;
                  this.typeEngagementHve.setValue("renouvellement");
                  this.loadForm();
                } else {
                  this.typeEngagementHve.setValue("auditInitial");
                  this.initEngagement();
                }
              });
            });
          } else {
            this.loadForm();
          }
        });
        this.raisonsociale.setValue(entreprise.raisonSociale);
      }, err => { console.log(err); });
  }

  initEngagement() {
    this.engagement = <EngagementBase>{
      refCampagne: this.refCampagne,
      entreprise: {
        id: this.entreprise.id,
        raisonSociale: this.entreprise.raisonSociale,
        adresse: this.entreprise.adresse,
        codePostal: this.entreprise.codePostal,
        commune: this.entreprise.commune,
        departement: this.entreprise.departement,
        siret: this.entreprise.siret
      },
      responsable: null,
      activiteProduction: false,
      activiteConditionnement: false,
      utilisationLogo: false,
      referent: null,
      conseillerTechnique: null,
      organismeCertification: null,
      dateAudit: null,
      engagementAnimationTechnique: false,
      listEngagementDetail: [
        {
          id: null,
          activiteConditionnement: false,
          activiteProduction: false,
          certificationBrc: false,
          certificationIfs: false,
          engagementGlobalGap: false,
          engagementHve: false,
          engagementVergersEcoresponsables: false,
          entreprise: {
            id: this.entreprise.id,
            raisonSociale: this.entreprise.raisonSociale
          },
          typeEngagementHve: null
        }
      ],
      listEngagementSc: null
    }
  }

  loadForm() {
    this.responsable.setValue(this.engagement.responsable.id);
    this.activiteProduction.setValue(this.engagement.activiteProduction);
    this.globalGap.setValue(this.engagement.listEngagementDetail[0].engagementGlobalGap);
    this.engagementHve.setValue(this.engagement.listEngagementDetail[0].engagementHve);
    this.activiteConditionnement.setValue(this.engagement.activiteConditionnement)
    this.brc.setValue(this.engagement.listEngagementDetail[0].certificationBrc);
    this.ifs.setValue(this.engagement.listEngagementDetail[0].certificationIfs);
    this.utilisationLogo.setValue(this.engagement.utilisationLogo);
    this.contactReferentEco.setValue(this.engagement.referent.id);
    this.conseillerTechnique.setValue(this.engagement.conseillerTechnique.id);
    const org = this.organismesCertification.find(organisme => organisme.id === this.engagement.organismeCertification.id);
    this.organismeCertification.setValue(org);
    this.typeEngagementHve.setValue(this.engagement.listEngagementDetail[0].typeEngagementHve);
    if(this.engagement.laboratoireResidus != null) {
      const laboResidus = this.laboratoireResidusList.find(laboResidus => laboResidus.id === this.engagement.laboratoireResidus.id);
      this.laboratoireResidus.setValue(laboResidus);
    }
    if(this.engagement.laboratoireSols != null) {
      const laboSols = this.laboratoireSolsList.find(laboSols => laboSols.id === this.engagement.laboratoireSols.id);
      this.laboratoireSols.setValue(laboSols);
    }
  }

  submit() {
    if (this.formGroup.valid) {
      let modalConfirmText: string = "";
      if (!this.isAnpp && this.creation) {
        modalConfirmText = this.translate.instant('page.engagements.modals.confirm-creation');
      } else if (this.isAnpp) {
        modalConfirmText = this.translate.instant('page.engagements.modals.confirm-modification');
      }

      this.modalsService.confirm(
        this.translate.instant(modalConfirmText),
        this.translate.instant('page.engagements.modals.title'), {
        cancelText: this.translate.instant('page.engagements.modals.cancel-button'),
        submitText: this.translate.instant('page.engagements.modals.valid-button')
      }
      ).then(() => {
        this.saveEngagement();
      }, () => { });
    }
  }

  saveEngagement() {
    this.engagement.responsable = <ContactEngagement>{ id: this.responsable.value };
    this.engagement.activiteProduction = this.activiteProduction.value;
    this.engagement.activiteConditionnement = this.activiteConditionnement.value;
    this.engagement.listEngagementDetail[0].activiteProduction = this.activiteProduction.value;
    this.engagement.listEngagementDetail[0].activiteConditionnement = this.activiteConditionnement.value;
    if (this.globalGap.value !== undefined) {
      this.engagement.listEngagementDetail[0].engagementGlobalGap = this.globalGap.value;
    }
    if (this.engagementHve.value !== undefined) {
      this.engagement.listEngagementDetail[0].engagementHve = this.engagementHve.value;
      if (this.engagementHve.value === true) {
        if (this.typeEngagementHve.value !== undefined) {
          this.engagement.listEngagementDetail[0].typeEngagementHve = this.typeEngagementHve.value;
        }
      } else if(this.engagementHve.value === false) {
        this.engagement.listEngagementDetail[0].typeEngagementHve = null;
      }
    }
   
    this.engagement.listEngagementDetail[0].engagementVergersEcoresponsables = true;
    if (this.engagement.activiteConditionnement && this.brc.value !== undefined) {
      this.engagement.listEngagementDetail[0].certificationBrc = this.brc.value;
    } else {
      this.engagement.listEngagementDetail[0].certificationBrc = false;
    }
    if (this.engagement.activiteConditionnement && this.ifs.value !== undefined) {
      this.engagement.listEngagementDetail[0].certificationIfs = this.ifs.value;
    } else {
      this.engagement.listEngagementDetail[0].certificationIfs = false;
    }
    if (this.engagement.activiteConditionnement && this.utilisationLogo.value !== undefined) {
      this.engagement.utilisationLogo = this.utilisationLogo.value;
    } else {
      this.engagement.utilisationLogo = false;
    }
    this.engagement.referent = <ContactEngagement>{ id: this.contactReferentEco.value };
    this.engagement.conseillerTechnique = <IntervenantEngagement>{ id: this.conseillerTechnique.value };
    let organismeCertificateur: EntrepriseEngagement = this.organismeCertification.value;
    this.engagement.organismeCertification = <EntrepriseEngagement>{
      id: organismeCertificateur.id,
      raisonSociale: organismeCertificateur.raisonSociale
    };

    if (this.typeEngagementHve.value === undefined) {
      this.engagement.listEngagementDetail[0].typeEngagementHve = this.typeEngagementHve.value;
    }
    if (this.engagement.activiteConditionnement && this.laboratoireResidus.value !== undefined && this.laboratoireResidus.value !== '') {
      let laboratoireResidus: LaboratoireAnpp = this.laboratoireResidus.value;
      this.engagement.laboratoireResidus = laboratoireResidus;
    } else {
      this.engagement.laboratoireResidus = null;
    }
    if (this.laboratoireSols.value !== undefined  && this.laboratoireSols.value !== '') {
      let laboratoireSols: LaboratoireAnpp = this.laboratoireSols.value;
      this.engagement.laboratoireSols = laboratoireSols;
    } else {
      this.engagement.laboratoireSols = null;
    }
    if (this.creation) {
      this.engagementVergersEcoService.creerEngagementVergersEco(this.engagement, 'PI').subscribe((idEngagenemnt: number) => this.retour());
    } else {
      this.engagementVergersEcoService.modifierEngagementVergersEco(this.engagement).subscribe(retour => this.retour());
    }
  }

  retour() {
    this.router.navigate(['main/engagements']);
  }

}
