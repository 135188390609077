import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JureCdc } from 'src/app/data/commission/models/jure-cdc.model';
import { JureSynthese } from 'src/app/data/commission/models/jure-synthese.model';
import { JuresService } from 'src/app/data/commission/services/jures/jures.service';

@Component({
  selector: 'app-detail-synthese',
  templateUrl: './detail-synthese.component.html',
  styleUrls: ['./detail-synthese.component.scss']
})
export class DetailSyntheseComponent implements OnInit {

  @Input() detailJures: JureCdc[];
  @Input() jure:JureSynthese;

  constructor(
    public readonly modal: NgbActiveModal,
  ) {
   
  }

  ngOnInit() {
    //console.log(this.detailJures);
  }

  /** Ferme la fenêtre surgissante */
  public onClose(): void { this.modal.dismiss(); }

}
