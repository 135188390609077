import { JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";
import { GroupeVarietalVariete } from "./groupe-varietal-variete.model";

@JsonObject
export class GroupeVarietalParam {

    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('code', String)
    code: string;

    @JsonProperty('libelle', String)
    libelle: string;

    @JsonProperty('varietes', [GroupeVarietalVariete])
    @Optional
    varietes: GroupeVarietalVariete[] = [];

}