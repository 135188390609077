import { Directive, Renderer2, Input, TemplateRef, ViewContainerRef, ɵstringify as stringify } from '@angular/core';

import { SessionContext, NavigationContext } from 'src/app/core/services/config/app.settings';
import { UtilisateurTypeCode } from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';
import { HasHabilitationService } from '../../services/has-habilitation/has-habilitation.service';


@Directive({
  selector: '[hasHabilitation]'
})
export class HasHabilitationDirective {

  private isVisible: boolean;
  private elseAction = 'hide';
  private elseTemplateRef: TemplateRef<any>;

  idOperateur: number;
  idCahier: number;
  idsActivitesConditionString: string;

  private warningTimeout = null;

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly renderer: Renderer2,
    private readonly hasHabilitationService: HasHabilitationService
  ) { }


  @Input()
  set hasHabilitation(activite: number | string) {
    // console.debug('hasHabilitation', activite);
    this.clear();
    this.setOperateur(); // init

    if (typeof activite === 'number') {
      this.idsActivitesConditionString = activite.toString();
    }
    if (typeof activite === 'string') {
      this.idsActivitesConditionString = activite.trim();
    }
  }

  @Input()
  set hasHabilitationOperateur(operateur: number | 'CURRENT') {
    // console.debug('hasHabilitationOperateur', operateur);

    this.setOperateur(operateur);
    this.calculateView();
  }

  private setOperateur(operateur: number | 'CURRENT' = 'CURRENT') {
    if (SessionContext.get('utilisateurType') === UtilisateurTypeCode.OPERATEUR) {
      if (operateur !== 'CURRENT' && operateur !== SessionContext.get('idIntervenant')) {
        throw new Error('Un utilisateur OPERATEUR ne peut pas verifier les habilitation d\'un autre utilisateur que lui meme');
      }

      this.idOperateur = SessionContext.get('idIntervenant');
    } else {
      if (operateur === 'CURRENT') {
        this.idOperateur = NavigationContext.has('idOperateur') ? NavigationContext.get('idOperateur') : null;
      } else {
        this.idOperateur = operateur;
      }
    }
  }

  @Input()
  set hasHabilitationCahier(cahier: number) {
    // console.debug('hasHabilitationCahier', cahier);
    this.idCahier = cahier;
    this.calculateView();
  }

  @Input()
  set hasHabilitationElseDisable(voidd: void) {
    // console.debug('set else = disable')
    this.elseAction = 'disabled';
    this.calculateView();
  }

  @Input()
  set hasHabilitationElse(templateRef: TemplateRef<any>) {
    // console.debug('set else = template')
    assertTemplate('ngIfElse', templateRef);
    this.elseAction = 'else';
    this.elseTemplateRef = templateRef;
    this.calculateView();
  }

  calculateView() {
    clearTimeout(this.warningTimeout);
    if (this.idOperateur === undefined || this.idCahier === undefined || this.idsActivitesConditionString === undefined) {
      // wait for data
      return;
    }
    // console.debug(`calculate view habilitation : ${this.elseAction} : operateur[${this.idOperateur}], activite[${this.idsActivitesConditionString}], cahier[${this.idCahier}]`);
    if (this.idOperateur && this.idCahier && this.idsActivitesConditionString) {
      this.hasHabilitationService.hasHabilitation(this.idsActivitesConditionString, this.idCahier, this.idOperateur).subscribe(
        hasHabilitation => {
          this.isVisible = hasHabilitation;
          this.updateView();
        }
      );
    } else {
      this.warningTimeout = setTimeout(() => { // eviter de spammer la console avec des warnings inutiles
        console.warn(`calculate view habilitation : missing data : operateur[${this.idOperateur}], activite[${this.idsActivitesConditionString}], cahier[${this.idCahier}]`);
      }, 1000);
    }
  }

  clear() {
    this.viewContainerRef.clear();
  }

  private updateView() {
    // console.debug(`update view habilitation : ${this.isVisible} : ${this.elseAction} : operateur[${this.idOperateur}], activite[${this.idsActivitesConditionString}], cahier[${this.idCahier}]`);

    this.clear();
    if (this.isVisible) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else if (this.elseAction === 'disabled') {
      const view = this.viewContainerRef.createEmbeddedView(this.templateRef);
      const rootElem = view.rootNodes[0];
      if (rootElem) {
        rootElem.disabled = true;
        this.renderer.addClass(rootElem, 'disabled'); // disable element
        rootElem.style.pointerEvents = 'none'; // disable standar elements
        this.renderer.setProperty(rootElem, 'disabled', true); // add class
      }
    } else if (this.elseAction === 'else') {
      this.viewContainerRef.createEmbeddedView(this.elseTemplateRef);
    }
  }


}

export class HasHabilitationContext {
  public $implicit: any = null;
  public event: any = null;
}

function assertTemplate(property: string, templateRef: TemplateRef<any> | null): void {
  const isTemplateRefOrNull = !!(!templateRef || templateRef.createEmbeddedView);
  if (!isTemplateRefOrNull) {
    throw new Error(`${property} must be a TemplateRef, but received '${stringify(templateRef)}'.`);
  }
}
