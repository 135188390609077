import { JsonProperty, JsonObject } from '@thorolf/json-ts-mapper';
import { RefStatutJureCode } from './enums/ref-statut-jure.enum';

@JsonObject
export class StatutJure {
  @JsonProperty('id', Number)
  id: number;

  @JsonProperty('code', String)
  code: RefStatutJureCode;

  @JsonProperty('libelle', String)
  libelle: string;
}
