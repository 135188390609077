import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { UtilisateurDroit } from '../../models/utilisateur-droit.model';

@Injectable({
  providedIn: 'root'
})
export class DroitsService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
  ) { }


  getDroits(): Observable<UtilisateurDroit[]> {
    return this.http.get<object[]>(`/api/intervenant/private/intervenants-droits`)
      .pipe(
        map(domaines => this.mapper.deserializeArray(domaines, UtilisateurDroit))
      );
  }
}
