import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { TranslateService } from '@ngx-translate/core';
import { find } from 'lodash';
import { mergeMap, map } from 'rxjs/operators';

import { ApiCredentials } from '../../models/api-credentials.model';
import { AuthentificationService } from '../../services/authentification/authentification.service';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';
import { Domaine } from 'src/app/data/intervenant/models/domaine.model';

import * as jwt_decode from 'jwt-decode';
import { CacheService } from 'src/app/core/services/cache/cache.service';
import { Context } from 'src/app/core/services/config/app.settings';

@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.scss']
})
export class RedirectionComponent implements OnInit {
  constructor(
    private readonly authentificationService: AuthentificationService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalToolsService: AdvBootstrapModalService,
    private readonly referencesService: ReferencesService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService
  ) { }

  ngOnInit() {

    CacheService.clearAll();
    Context.clear();
    this.authentificationService.deleteCookie();

    const tokenAgriMaker = this.route.snapshot.queryParamMap.get('token');
    let identifiantIntervenant;
    let codeDomaine;
    let identifiantParent;
    let portail;
    if(tokenAgriMaker){
      const decoded = jwt_decode(tokenAgriMaker);
      identifiantIntervenant = decoded.identifiantIntervenant;
      codeDomaine = decoded.domaine;
      identifiantParent = decoded.identifiantParent;
      portail = decoded.portail;
    } else {
      identifiantIntervenant = this.route.snapshot.queryParamMap.get('identifiantIntervenant');
      codeDomaine = this.route.snapshot.queryParamMap.get('domaine');
      identifiantParent = this.route.snapshot.queryParamMap.get('identifiantParent');
      portail = this.route.snapshot.queryParamMap.get('portail');
    }

    this.authentificationService.getOperateurToken(identifiantIntervenant, codeDomaine, identifiantParent, tokenAgriMaker, portail).pipe(
      this.loaderService.operator(),
      mergeMap(jwt => {
        const credentials = Object.assign(new ApiCredentials(), {
          token: jwt.token,
          utilisateurType: jwt.type,
          roles: jwt.roles,
          idIntervenant: jwt.idIntervenant,
          mailIntervenant: jwt.mailIntervenant,
          idPortail: jwt.idPortail,
          codePortail: jwt.codePortail,
        });
        this.authentificationService.onNewApiCredentials(credentials);

        return this.referencesService.getReferences().pipe(
          map(references => {
            const domaine = find(references.domaines, {
              code: codeDomaine
            }) as Domaine;

            if (domaine == null) {
              this.modalToolsService.error(
                this.translate.instant('page.auth.redirection.domaine-introuvable'),
                this.translate.instant('label.erreur')
              ).then(
                () => {
                  // TODO redirect to portail
                }
              );

              throw new Error('domaine introuvable');
            } else {
              credentials.idDomaine = domaine.id;
              this.authentificationService.onNewApiCredentials(credentials);

              this.authentificationService.saveCookie(jwt, domaine.id, null);
            }

            return credentials;
          })
        );
      })
    ).subscribe(
      credentials => {
        if (credentials.roles.length === 0) {
          this.router.navigate(['/auth/no-roles']);
        } else {

          this.router.navigate(['/']);
        }
      }
    );
  }
}
