<div
  class="row mt-4 pt-4 gloabl-font-size dashboard-main-div"
  *ngIf="accueilAnppProducteur"
>
  <button
    class="btn-changer-entrprise"
    (click)="goToOrganismeHome()"
    *ngIf="isAnpp"
    translate
  >
    page.accueil.changer-producteur
  </button>
  <div class="col-lg-12 spacing">
    <div class="row">
      <div class="col-lg-4 margin-bottom">
        <ul class="list-group border-radius text-left shadow">
          <li class="list-group-item text-center">
            <h5>
              <b translate>page.accueil.anpp-producteur.adherent.title</b>
            </h5>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <b translate
              >page.accueil.anpp-producteur.adherent.raison-sociale</b
            >
            <span *ngIf="accueilAnppProducteur.raisonSociale">{{
              accueilAnppProducteur.raisonSociale
            }}</span>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <b translate>page.accueil.anpp-producteur.adherent.email</b>
            <span *ngIf="accueilAnppProducteur.email">{{
              accueilAnppProducteur.email
            }}</span>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <b translate>page.accueil.anpp-producteur.adherent.telephone</b>
            <span *ngIf="accueilAnppProducteur.telephone">{{
              accueilAnppProducteur.telephone
            }}</span>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="isPo"
          >
            <b translate>Mon groupement</b>
            <span *ngIf="accueilAnppProducteur.telephone">{{
              accueilAnppProducteur.groupement
            }}</span>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <a
              class="vertical-menu link-secondary pointer"
              (click)="goToMonCompte()"
            >
              <fa-icon icon="user" class="pr-1"></fa-icon>
              <span class="pr-4" translate
                >page.accueil.anpp-producteur.adherent.detail</span
              >
            </a>
          </li>
        </ul>
      </div>
      <div class="col-lg-8 margin-bottom" *ngIf="!isPo">
        <div class="list-group list-group-item border-radius text-left shadow">
          <div class="d-flex justify-content-between align-items-center">
            <fa-icon
              (click)="getPrev('slideThree')"
              icon="chevron-left"
              class="mt-2 p-2 pointer"
              style="font-size: 20px"
            ></fa-icon>
            <fa-icon
              (click)="moveNext()"
              icon="chevron-right"
              class="mt-2 p-2 pointer"
              style="font-size: 20px"
            >
            </fa-icon>
          </div>
          <ngb-carousel
            #carousel
            id="carousel"
            class="no-indicators text-center"
            [interval]="false"
            [showNavigationArrows]="false"
          >
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <small translate
                  >page.accueil.anpp-producteur.histogramme.titles.rendement-variete</small
                >
                <div
                  *ngIf="rendementVarietesPrincipales"
                  id="chart1"
                  class="echarts-height echarts-margin"
                  echarts
                  [options]="rendementVarietesPrincipales"
                ></div>
              </div>
              <h5 *ngIf="!rendementVarietesPrincipales">
                <span class="badge badge-green badge-pill" translate
                  >page.accueil.pas-donnee</span
                >
              </h5>
            </ng-template>
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <small translate
                  >page.accueil.anpp-producteur.histogramme.titles.recolte-prevision</small
                >
                <div
                  *ngIf="recoltesPrevision"
                  id="chart2"
                  class="echarts-height echarts-margin"
                  echarts
                  [options]="recoltesPrevision"
                ></div>
              </div>
              <h5 *ngIf="!recoltesPrevision">
                <span class="badge badge-green badge-pill" translate
                  >page.accueil.pas-donnee</span
                >
              </h5>
            </ng-template>
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <small translate
                  >page.accueil.anpp-producteur.histogramme.titles.declaration-stock</small
                >
                <div
                  *ngIf="stocks"
                  id="chart3"
                  class="echarts-height echarts-margin"
                  echarts
                  [options]="stocks"
                ></div>
              </div>
              <h5 *ngIf="!stocks">
                <span class="badge badge-green badge-pill" translate
                  >page.accueil.pas-donnee</span
                >
              </h5>
            </ng-template>
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <small translate
                  >page.accueil.anpp-producteur.histogramme.titles.declaration-ecoulement</small
                >
                <div
                  *ngIf="ecoulements"
                  id="chart4"
                  class="echarts-height echarts-margin"
                  echarts
                  [options]="ecoulements"
                ></div>
              </div>
              <h5 *ngIf="!ecoulements">
                <span class="badge badge-green badge-pill" translate
                  >page.accueil.pas-donnee</span
                >
              </h5>
            </ng-template>
          </ngb-carousel>
        </div>
      </div>
      <div class="col-lg-4 margin-bottom" *ngIf="dashboardGed">
        <ul class="list-group border-radius text-left shadow">
          <li class="list-group-item text-center">
            <h5>
              <b translate>page.accueil.anpp-producteur.actualite.title</b>
            </h5>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
            *ngFor="let document of dashboardGed.newsletters"
          >
            <b
              >{{ document.dateUpload | date : "dd/MM/yyyy" }} :
              {{ document.libelle }}</b
            >
            <fa-icon
              icon="download"
              (click)="onDownloadFile(document.uuid)"
              title="Télécharger"
            ></fa-icon>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="dashboardGed && !dashboardGed.newsletters"
          >
            <span translate
              >page.accueil.anpp-producteur.actualite.pas-document</span
            >
          </li>
        </ul>
      </div>
      <div class="col-lg-4 margin-bottom" *ngIf="dashboardGed">
        <ul class="list-group border-radius text-left shadow">
          <li class="list-group-item text-center">
            <h5>
              <b translate
                >page.accueil.anpp-producteur.dernier-document.title</b
              >
            </h5>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
            *ngFor="let document of dashboardGed.documents"
          >
            <b
              >{{ document.dateUpload | date : "dd/MM/yyyy" }} :
              {{ document.libelle }}</b
            >
            <fa-icon
              icon="download"
              (click)="onDownloadFile(document.uuid)"
              title="Télécharger"
            ></fa-icon>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="dashboardGed && !dashboardGed.documents"
          >
            <span translate
              >page.accueil.anpp-producteur.dernier-document.pas-document</span
            >
          </li>
        </ul>
      </div>
      <div class="col-lg-4 margin-bottom" *ngIf="!isPo">
        <ul class="list-group border-radius text-left shadow">
          <li class="list-group-item text-center">
            <h5>
              <b translate>page.accueil.anpp-producteur.declarations.title</b>
            </h5>
            <small translate
              >page.accueil.anpp-producteur.declarations.subtitle</small
            >
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <b translate>page.accueil.anpp-producteur.declarations.recolte</b>
            <div *ngIf="derniereDeclarationRecolte">
              <h5>
                <span class="badge badge-green badge-pill">{{
                  derniereDeclarationRecolte.dateDeclaration
                    | date : "dd/MM/yyyy"
                }}</span>
              </h5>
              <a
                class="vertical-menu link-secondary pointer"
                (click)="
                  gotToDeclaration(derniereDeclarationRecolte.codeMouvement)
                "
              >
                <span class="pr-4" translate
                  >page.accueil.anpp-producteur.declarations.voir</span
                >
              </a>
            </div>
            <div *ngIf="!derniereDeclarationRecolte">
              <small translate
                >page.accueil.anpp-producteur.pas-declaration</small
              >
            </div>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <b translate>page.accueil.anpp-producteur.declarations.stock</b>
            <div *ngIf="derniereDeclarationStock">
              <h5>
                <span class="badge badge-green badge-pill">{{
                  derniereDeclarationStock.dateDeclaration | date : "dd/MM/yyyy"
                }}</span>
              </h5>
              <a
                class="vertical-menu link-secondary pointer"
                (click)="
                  gotToDeclaration(derniereDeclarationStock.codeMouvement)
                "
              >
                <span class="pr-4" translate
                  >page.accueil.anpp-producteur.declarations.voir</span
                >
              </a>
            </div>
            <div *ngIf="!derniereDeclarationStock">
              <small translate
                >page.accueil.anpp-producteur.pas-declaration</small
              >
            </div>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <b translate
              >page.accueil.anpp-producteur.declarations.ecoulement</b
            >
            <div *ngIf="derniereDeclarationEcoulement">
              <h5>
                <span class="badge badge-green badge-pill">{{
                  derniereDeclarationEcoulement.dateDeclaration
                    | date : "dd/MM/yyyy"
                }}</span>
              </h5>
              <a
                class="vertical-menu link-secondary pointer"
                (click)="
                  gotToDeclaration(derniereDeclarationEcoulement.codeMouvement)
                "
              >
                <span class="pr-4" translate
                  >page.accueil.anpp-producteur.declarations.voir</span
                >
              </a>
            </div>
            <div *ngIf="!derniereDeclarationEcoulement">
              <small translate
                >page.accueil.anpp-producteur.pas-declaration</small
              >
            </div>
          </li>
        </ul>
      </div>
      <div class="col-lg-4 margin-bottom">
        <ul class="list-group border-radius text-left shadow">
          <li class="list-group-item text-center">
            <h5>
              <b translate>page.accueil.anpp-producteur.inventaires.title</b>
            </h5>
          </li>

          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <b translate
              >page.accueil.anpp-producteur.inventaires.date-modification</b
            >
            <h5 class="margin-bottom-none">
              <span
                class="badge badge-green badge-pill"
                *ngIf="derniereDateMajInventaireVerger"
                >{{
                  derniereDateMajInventaireVerger | date : "dd/MM/yyyy"
                }}</span
              >
            </h5>
            <div *ngIf="!derniereDateMajInventaireVerger">
              <small translate
                >page.accueil.anpp-producteur.pas-declaration</small
              >
            </div>
            <div class="col-lg-4 text-right pt-2 pr-4 export-button">
              <fa-icon
                (click)="exportInventaire()"
                icon="upload"
                class="clickable-icon"
                style="font-size: 25px"
              >
              </fa-icon>
            </div>
          </li>
          <li
            class="list-group-item d-flex justify-content-center"
            *ngIf="miseAJour"
          >
            <b class="red" translate
              >page.accueil.anpp-producteur.inventaires.mise-a-jour</b
            >
          </li>
        </ul>
      </div>

      <div class="col-lg-4 margin-bottom" *ngIf="!isPo">
        <ul class="list-group border-radius text-left shadow">
          <li class="list-group-item text-center">
            <h5>
              <b translate>page.accueil.anpp-producteur.verger-eco.title</b>
            </h5>
          </li>

          <li
            class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="engagement"
          >
            <b translate>page.accueil.anpp-producteur.verger-eco.engagement</b
            ><b>{{ engagement.refCampagne.annee }}</b>
            <h5 class="margin-bottom-none">
              <span class="badge badge-green badge-pill">{{
                engagement.libelleStatut
              }}</span>
            </h5>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="engagementPrecedant"
          >
            <b translate>page.accueil.anpp-producteur.verger-eco.attestations</b
            ><b>{{ engagementPrecedant.refCampagne.annee }}</b>
            <h5 class="margin-bottom-none">
              <span class="badge badge-green badge-pill">{{
                engagementPrecedant.libelleStatut
              }}</span>
            </h5>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
