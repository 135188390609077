import { JsonProperty, JsonObject, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { DateConverter } from 'src/app/core/services/mapper/converters';

@JsonObject
export class DashBoardInventaire {
  @JsonProperty('dateDeclaration', String)
  @CustomConverter(DateConverter)
  @Optional
  dateDeclaration: moment.Moment;

  @JsonProperty('raisonSocialeEntreprise', String)
  raisonSocialeEntreprise: string;

}
