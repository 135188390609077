import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { Commission } from '../../models/commission.model';
import { CommissionAgenda } from '../../models/commission-agenda.model';
import { SallesService } from '../salles/salles.service';
import { AnimateursService } from '../animateurs/animateurs.service';
import { ReferencesService } from '../references/references.service';
import { CahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import { JuresService } from '../jures/jures.service';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { OrganismesService } from 'src/app/data/intervenant/services/organismes/organismes.service';
import { CommissionRecours } from '../../models/commissionRecours';

@Injectable({
  providedIn: 'root'
})
export class CommissionsService {

  private dateMapper: DateConverter;

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly refService: ReferencesService,
    private readonly sallesService: SallesService,
    private readonly animateursService: AnimateursService,
    private readonly cahiersService: CahiersService,
    private readonly juresService: JuresService,
    private readonly referentielService: ReferentielService,
    private readonly organismeService: OrganismesService,

  ) {
    this.dateMapper = new DateConverter();
  }

  getAgendaCommissions(idOrganisme: number, dateDebut: moment.Moment, dateFin: moment.Moment): Observable<CommissionAgenda[]> {
    const debut = this.dateMapper.serialize(dateDebut);
    const fin = this.dateMapper.serialize(dateFin);

    return forkJoin(
      this.http.get<object[]>(`/api/declaration/private/organismes/${idOrganisme}/commissions-agenda?debut=${debut}&fin=${fin}`),
      this.refService.getReferences(),
      this.animateursService.getAnimateurs(idOrganisme),
      this.sallesService.getSalles(idOrganisme)
    ).pipe(
      map(([commissions, ref, animateurs, salles]) => this.mapper.deserializeArray(commissions, CommissionAgenda, Object.assign({ salles, animateurs }, ref)))
    );
  }

  getCommissions(idOrganisme: number, idCommission: number): Observable<any> {
    /*return this.http.get<object>(`/api/declaration/private/organismes/${idOrganisme}/commissions/${idCommission}`).pipe(
      map(commission => {
        return commission;
      })
    );*/
    return forkJoin(
      this.http.get<object>(`/api/declaration/private/organismes/${idOrganisme}/commissions/${idCommission}`),
      this.refService.getReferences(),
      this.referentielService.getReferentiel(),
      this.animateursService.getAnimateurs(idOrganisme),
      this.sallesService.getSalles(idOrganisme),
      this.cahiersService.getCahiers(),
      this.juresService.getJures(idOrganisme),
      this.organismeService.getOrganismes()
    ).pipe(
      map(([commission, ref, referentiel, animateurs, salles, cahiers, jures, organismes]) => this.mapper.deserializeObject(commission,
        Commission, Object.assign({ animateurs, salles, cahiers, jures, organismes }, ref, referentiel))),
    );
  }

  getCommissionsRecours(idOrganisme: number): Observable<CommissionRecours[]> {
    return forkJoin(
      this.http.get<CommissionRecours[]>(`/api/declaration/private/organismes/${idOrganisme}/commissions-recours`)
    ).pipe(
      map(([commissions]) => this.mapper.deserializeArray(commissions, CommissionRecours))
    );
  }

  createCommission(idOrganisme: number, commission: Commission): Observable<{ id: number, numero: string }> {
    return this.http.post(`/api/declaration/private/organismes/${idOrganisme}/commissions`, this.mapper.serialize(commission), { observe: 'response', responseType: 'text' }).pipe(
      map(response => {
        return {
          id: parseInt(response.headers.get('location').split('/').pop(), 10),
          numero: response.body
        };
      })
    );
  }

  modifyCommission(idOrganisme: number, commission: Commission): Observable<{ id: number, numero: string }> {
    return this.http.put(`/api/declaration/private/organismes/${idOrganisme}/commissions/${commission.id}`, this.mapper.serialize(commission), { observe: 'response', responseType: 'text' }).pipe(
      map(response => {
        return {
          id: parseInt(response.headers.get('location').split('/').pop(), 10),
          numero: response.body
        };
      })
    );
  }

  deleteCommission(idOrganisme: number, commission: Commission): Observable<void> {
    return this.http.delete(`/api/declaration/private/organismes/${idOrganisme}/commissions/${commission.id}`).pipe(
      map(() => { })
    );
  }
}
