import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'saisieRecolteFormat'
})
export class SaisieRecolteFormatPipe implements PipeTransform {
  transform(number, decimal) {
    return number ? number.toLocaleString(undefined, {maximumFractionDigits: decimal ? decimal : 0, minimumFractionDigits: decimal ? decimal : 0}) : '-';
  }
}
