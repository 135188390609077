
        <div class="modal-header">
            <h4 class="modal-title">Modifications en cours</h4>
        </div>
        <div class="modal-body">

            <p class="text-muted" >{{confMessage}}</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="this.activeModal.close(true)">Quitter la page</button>
            <button type="button" class="btn btn-secondary" (click)="this.activeModal.close(null)">Retour</button>
        </div>
    