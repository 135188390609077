import { CustomConverter, JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";
import { DateTimeConverter } from "src/app/core/services/mapper/converters";
import { ContactInteraction } from "./contact-interaction.model";
import { Interlocuteur } from "./interlocuteur.model";
import { RefTypeInteraction } from "./ref-type-interaction.model";

@JsonObject
export class Interaction {

    @JsonProperty('id', Number)
    @Optional
    public id: number = undefined;

    @JsonProperty('dateInteraction', String)
    @CustomConverter(DateTimeConverter)
    @Optional
    dateInteraction: moment.Moment ;

    @JsonProperty('contact', ContactInteraction)
    @Optional
    public contact: ContactInteraction = undefined;

    @JsonProperty('contact_interlocuteur', Interlocuteur)
    @Optional
    public contact_anpp: Interlocuteur = undefined;

    @JsonProperty('refTypeInteraction', RefTypeInteraction)
    @Optional
    public refTypeInteraction: RefTypeInteraction = undefined;

    @JsonProperty('sujet', String)
    @Optional
    public sujetInteraction: string = undefined;

    @JsonProperty('compteRendu', String)
    @Optional
    public compteRendu: string = undefined;
}
