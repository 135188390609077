import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class DomaineSpec {
  @JsonProperty('id', Number)
  public id: number = undefined;

  @JsonProperty('code', String)
  public code: string = undefined;

  @JsonProperty('libelle', String)
  public libelle: string = undefined;
}
