import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { GroupeVarietal } from '../../models/groupe-varietal.model';
import { map } from 'rxjs/operators';
import { GroupeVarietalParam } from '../../models/groupe-varietal-param.model';
import { GroupeVarietalVariete } from '../../models/groupe-varietal-variete.model';
import { GroupeVarietalRecolte } from '../../models/groupe-varietal-recolte.model';

@Injectable({
  providedIn: 'root'
})
export class GroupeVarietalService {

  constructor(protected readonly http: HttpClient,
    protected readonly mapper: JsonMapperService

  ) { }
  
  public searchGroupeVarietal(idRefCampagne:number, nameBeginWith: string): Observable<GroupeVarietal[]> {
    return this.http.get<object[]>(`/api/declaration/private/groupe_varietal/search?beginWith=${nameBeginWith}&idRefCampagne=${idRefCampagne}`)
    .pipe(
      map(groupes => this.mapper.deserializeArray(groupes, GroupeVarietal))
    );
  }

  public getGroupeVarietaux(): Observable<GroupeVarietalParam[]> {
    return this.http.get<object[]>(`/api/declaration/private/groupe_varietal_param`)
    .pipe(
      map(groupes => this.mapper.deserializeArray(groupes, GroupeVarietalParam))
    );
  }

  public getGroupeVarietauxRecolte(): Observable<GroupeVarietalRecolte[]> {
    return this.http.get<object[]>(`/api/declaration/private/groupe_varietal_recolte`)
    .pipe(
      map(groupes => this.mapper.deserializeArray(groupes, GroupeVarietalRecolte))
    );
  }

  public creerGroupeVarietal(groupeVarietal: GroupeVarietalParam): Observable<number> {
    return this.http.post(`/api/declaration/private/groupe_varietal_param`, groupeVarietal, { observe: 'response' }).pipe(
      map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
    );
  }  
  
  public modifierGroupeVarietal(groupeVarietal: GroupeVarietalParam ): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/groupe_varietal_param/${groupeVarietal.id}`, groupeVarietal );
  }
  
  public desactiverGroupeVarietal(groupeVarietal: GroupeVarietalParam): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/groupe_varietal_param/${groupeVarietal.id}?desactivation=${true}`, groupeVarietal );
  }

  public creerGroupeVarietalVariete(groupeVarietalVariete: GroupeVarietalVariete): Observable<number> {
    return this.http.post(`/api/declaration/private/groupe_varietal_variete`, groupeVarietalVariete, { observe: 'response' }).pipe(
      map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
    );
  }  
  
  public supprimerGroupeVarietalVariete(groupeVarietalVariete: GroupeVarietalVariete ): Observable<void> {
    return this.http.delete<void>(`/api/declaration/private/groupe_varietal_variete/${groupeVarietalVariete.id}` );
  }

}
