import { Component, OnInit } from '@angular/core';
import { some } from 'lodash';
import { DeclarationsService } from 'src/app/data/declaration/services/declarations/declarations.service';
import { DeclarationDr } from 'src/app/data/declaration/models/declaration-dr.model';
import { DeclarationSV12 } from 'src/app/data/declaration/models/declaration-sv12.model';
import { DeclarationSV11 } from 'src/app/data/declaration/models/declaration-sv11.model';
import { SessionContext, NavigationContext } from 'src/app/core/services/config/app.settings';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { Campagne } from 'src/app/data/declaration/models/campagne.model';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-affichage-dr',
  templateUrl: './affichage-dr.component.html',
  styleUrls: ['./affichage-dr.component.scss']
})
export class AffichageDrComponent implements OnInit {
  public declarations: DeclarationDr[];
  public declarationsSv11: DeclarationSV11[];
  public declarationsSv12: DeclarationSV12[];
  public annee: number;
  public hasErrors = false;
  public refCampagnes: Campagne[];

  constructor(
    private readonly declarationsService: DeclarationsService,
    private readonly referentielService: ReferentielService
  ) { }

  ngOnInit() {
    setTimeout(() => this.loadData());
  }

  get idIntervenant(): number {
    if (NavigationContext.has('idOperateur')) {
      return NavigationContext.get('idOperateur');
    } else {
      return SessionContext.get('idIntervenant');
    }
  }

  private loadData() {
    const annees: number[] = [];
    // @ts-ignore
    forkJoin(
      this.referentielService.getReferentiel(),
      this.declarationsService.getDeclarationsRecolte(this.idIntervenant)
    ).subscribe(
      ([campagnes, declarations]) => {

        campagnes.campagnes.forEach(c => {
          annees.push(c.annee);
        });

        this.annee = annees.reduce((a, b) => Math.max(a, b));

        this.declarations = declarations.drs;
        this.declarationsSv11 = declarations.sv11s;
        this.declarationsSv12 = declarations.sv12s;

        // tslint:disable-next-line:max-line-length
        const list = [].concat(this.declarations, this.declarationsSv11, this.declarationsSv12) as [DeclarationDr | DeclarationSV11 | DeclarationSV12];

        if (some(list, declaration => declaration && declaration.erreurs && declaration.erreurs.length > 0)) {
          this.hasErrors = true;
        }
      },
      error => {
        try {
          if (error.error.code === 'BACCHUS_BUSINESS_NO_DR') {
            this.declarations = [];
            this.declarationsSv11 = [];
            this.declarationsSv12 = [];
            return;
          }
        } catch (e) { }
        throw error;
      }
    );
  }
}
