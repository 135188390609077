<div class="content">
  <div class="content-panel">
    <div class="searchBar">
      <label for="searchBar-input" translate>page.admin.organismes.liste.table.filtrer</label>
      <input type="text" id="searchBar-input" (ngModelChange)="searchStringUpdate.next($event)" [(ngModel)]="searchStringInput"
        placeholder="{{'label.filtre'|translate}}">
    </div>
    <br />
    <div id="liste-operateur-organismes">
      <table table class="table table-striped">
        <thead>
          <tr>
            <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'raisonSociale',libelle:'page.common.organismes.liste.table.header.raisonSociale'}"></ng-container>
            <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'type',libelle:'page.common.organismes.liste.table.header.type'}"></ng-container>
            <ng-container *ngTemplateOutlet="tableHeader;context:{libelle:'page.common.organismes.liste.table.header.adresse'}"></ng-container>
            <ng-container *ngTemplateOutlet="tableHeader;context:{libelle:'page.common.organismes.liste.table.header.telephone'}"></ng-container>
            <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'mail',libelle:'page.common.organismes.liste.table.header.mail'}"></ng-container>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let organisme of organismes | filter : '*': searchString | orderBy:order.property:order.reverse:true">
            <td>{{organisme.raisonSociale}}</td>
            <td>{{organisme.type.libelle}}</td>
            <td>
              <ng-container *ngTemplateOutlet="adresseTemplate;context:{organisme:organisme}"></ng-container>
            </td>
            <td>{{formatPhoneNumber(organisme.telephone)}}</td>
            <td>{{organisme.mail}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


<ng-template #tableHeader let-property="sortProperty" let-libelle="libelle">
  <th *ngIf="property" (click)="setOrder(property)" [class.sortable]="property">
    {{libelle | translate}}
    <fa-icon *ngIf="property" [icon]="getIcon(property)"></fa-icon>
  </th>
  <th *ngIf="!property">{{libelle | translate}}</th>
</ng-template>

<ng-template #adresseTemplate let-organisme="organisme">
  <div class="multilines-value">{{organisme.adresse}}</div>
  <div>{{organisme.codePostal}} {{organisme.ville}}</div>
  <div *ngIf="organisme.cedex">{{organisme.cedex}}</div>
  <div *ngIf="organisme.pays">{{organisme.pays.libelle}}</div>
</ng-template>
