import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { DeclarationDrBase } from './declaration-dr.base.model';

@JsonObject
export class DeclarationDr extends DeclarationDrBase {

  @JsonProperty('l4', Number)
  l4: number;

  @JsonProperty('l5', Number)
  l5: number;

  @JsonProperty('l8', Number)
  l8: number;

  @JsonProperty('l15', Number)
  l15: number;

  @JsonProperty('l16', Number)
  l16: number;

  @JsonProperty('l18', Number)
  l18: number;

  @JsonProperty('l19', Number)
  l19: number;

  @JsonProperty('list', [DeclarationDr])
  @Optional
  details: DeclarationDr[] = [];
}
