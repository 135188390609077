import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UniteCulturale } from 'src/app/data/declaration/models/unite-culturale.model';
import { UniteCulturaleService } from 'src/app/data/declaration/services/parcellaire/unite-culturale.service';
import { SelectFieldsColumnComponent } from '../../../declarations/pages/parcellaires/modals/select-fields-column/select-fields-column.component';
import { from } from 'rxjs';
import { ProduitsService } from 'src/app/data/declaration/services/produits/produits.service';
import { VisuRecapUcComponent } from '../visu-recap-uc/visu-recap-uc.component';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';

@Component({
  selector: 'app-visu-dpap',
  templateUrl: './visu-dpap.component.html',
  styleUrls: ['./visu-dpap.component.scss']
})
export class VisuDpapComponent implements OnInit {

  @Input() idDeclaration: number;  
  @Input() idCahier: number;

  headers = [];

  unitesCulturales: UniteCulturale[];

  constructor(
    public readonly modal: NgbActiveModal,
    public readonly modalSelectField: NgbModal,
    public readonly produitService: ProduitsService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly uniteCulturaleService: UniteCulturaleService,
    private readonly translate: TranslateService,
    private readonly entrepriseService: EntreprisesService
    ) { 

  }

  ngOnInit() {
    this.loadHeader();
    this.getUnitesCulturales();
  } 

 
  getUnitesCulturales() {
    this.uniteCulturaleService.getUniteCulturaleByDpap(this.idDeclaration,this.idCahier).pipe(this.loaderService.operator()).subscribe((unitesCulturales) => {

      this.unitesCulturales = unitesCulturales.sort((a, b) => a.numBlocFruitier > b.numBlocFruitier ? 1 : -1);
      
        let idProduit: number[] = [];
        this.unitesCulturales.forEach(uc =>{ 
            if(idProduit.indexOf(uc.uniteCulturaleVigne.idProduitDestination) === -1){
              idProduit.push(uc.uniteCulturaleVigne.idProduitDestination);
            };
            if(idProduit.indexOf(uc.uniteCulturaleVigne.idProduitIdentifie) === -1){
              idProduit.push(uc.uniteCulturaleVigne.idProduitIdentifie);
            };
            if(uc.uniteCulturaleVigne.idEntrepriseDestination){
              this.entrepriseService.getEntreprise(uc.uniteCulturaleVigne.idEntrepriseDestination).subscribe((entreprise) => {
                 uc.uniteCulturaleVigne.entreprise = entreprise;
              });
            }
            
          });

          this.produitService.getProduitsById(idProduit).pipe(this.loaderService.operator()).subscribe((produits) => {
            this.unitesCulturales.forEach(uc =>{ 
              uc.uniteCulturaleVigne.produitIdentifie = produits.find(p => p.id === uc.uniteCulturaleVigne.idProduitIdentifie )!.libelle;
              uc.uniteCulturaleVigne.produitDestination = produits.find(p => p.id === uc.uniteCulturaleVigne.idProduitDestination )!.libelle;
            });
          });



      
      
      //this.getRecoltesInfos();
    });
  }

  visuUC(uniteCulturale){
    const modalRef = this.modalSelectField.open(VisuRecapUcComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.uniteCulturale = uniteCulturale;
  }

  cancel() {
    this.modal.close();
  }

  showList() {
    const modalRef = this.modalSelectField.open(SelectFieldsColumnComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.headers = this.headers;

    from(modalRef.result).subscribe(headers => {
      this.headers = headers;
    });
  }

  show(obj) {
    return (this.headers.find(h => h.name === obj)).checked;
  }

  

  private loadHeader() {
   
    this.headers = [
      {name: 'numBloc', label: this.translate.instant('page.inventaire-verger.numBloc'), checked: true},
      {name: 'infosTracabilite', label: this.translate.instant('page.inventaire-verger.infosTracabilite'), checked: true},
      {name: 'codeParcelle', label: this.translate.instant('page.inventaire-verger.codeParcelle'), checked: false},
      {name: 'refCadastrale', label: this.translate.instant('page.inventaire-verger.refCadastrale'), checked: false},
      {name: 'numTracabilite', label: this.translate.instant('page.inventaire-verger.numDeTracabilite'), checked: false},
      {name: 'lieuDit', label: this.translate.instant('page.inventaire-verger.lieuDit'), checked: false},
      {name: 'commune', label: this.translate.instant('page.inventaire-verger.commune'), checked: true},
      {name: 'produitIdentifie', label: this.translate.instant('page.inventaire-verger.produitIdentifie'), checked: true},
      {name: 'produitDestination', label: this.translate.instant('page.inventaire-verger.produitDestination'), checked: true},
      {name: 'variete', label: this.translate.instant('page.inventaire-verger.variete'), checked: true},
      {name: 'surfacePlantee', label: this.translate.instant('page.inventaire-verger.surfacePlantee'), checked: true},
      {
        name: 'annee',
        label: this.translate.instant('page.inventaire-verger.annee'),
        checked: true
      },

      {name: 'cultureBio', label: this.translate.instant('page.inventaire-verger.cultureBio'), checked: false},
      {name: 'departement', label: this.translate.instant('page.inventaire-verger.departement'), checked: false},
      {
        name: 'distanceInterRang',
        label: this.translate.instant('page.inventaire-verger.distanceInterRang'),
        checked: true
      },
      {
        name: 'distanceIntraRang',
        label: this.translate.instant('page.inventaire-verger.distanceIntraRang'),
        checked: true
      },
      {
        name: 'systemeIrrigation',
        label: this.translate.instant('page.inventaire-verger.systemeIrrigation'),
        checked: false
      }
    ];
  

  }

  

}
