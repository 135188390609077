import { JsonObject, JsonProperty, Any, Optional } from '@thorolf/json-ts-mapper';
import { RefTypeBalise } from './ref-type-balise.model';
import { RefCategorie } from './ref-categorie.model';
import { RefTypeFichier } from './ref-type-fichier.model';

@JsonObject
export class ReferencesEdition {
    @JsonProperty('refCategorieList', [RefCategorie])
    refCategorieList: RefCategorie[];

    @JsonProperty('refTypeBaliseList', [RefTypeBalise])
    refTypeBaliseList: RefTypeBalise[];

    @JsonProperty('refTypeFichierList', [RefTypeFichier])
    refTypeFichierList: RefTypeFichier[];

    getRefCategorieList() {
        return this.refCategorieList;
    }

    getRefTypeBaliseList() {
        return this.refTypeBaliseList;
    }
}
