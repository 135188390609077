import { Injectable, ErrorHandler } from '@angular/core';

import { ErrorModalService } from './error-modal.service';
import { ErrorLoggerService } from './error-logger.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  constructor(
    private readonly modalService: ErrorModalService,
    private readonly loggerService: ErrorLoggerService
  ) { }

  handleError(error: any) {
    try { this.loggerService.handleError(error); } catch (e) { }
    try { this.modalService.handleError(error); } catch (e) { }
  }
}
