import { JsonConverter, AbstractJsonConverter } from '@thorolf/json-ts-mapper';
import { UnexpectedArgument } from 'src/app/shared/errors/unexpected-argument.error';
import { find } from 'lodash';

const mapper = {
  deserialize: <T, K extends keyof T>(field: K, data: T[K], items: T[]): T => {
    const found = find(items, item => item[field] === data);
    if (found !== undefined) {
      return found;
    } else {
      throw new UnexpectedArgument(`no item found with ${field}`, data);
    }
  },

  serialize: <T, K extends keyof T>(field: K, data: T): T[K] => {
    if (data === null || data === undefined) {
      return data as any;
    } else if (data.hasOwnProperty(field)) {
      return data[field];
    } else {
      throw new UnexpectedArgument(`object does not contain ${field} property`, data);
    }
  }
};

@JsonConverter
export abstract class DataPropertyConverter<T, K extends keyof T> implements AbstractJsonConverter<any, T> {
  protected mapper = mapper;

  constructor(
    protected field: K,
    protected deserializeContextKey?: string
  ) { }

  requiredContextKeys(): void | { serialize?: string[]; deserialize?: string[]; } {
    if (this.deserializeContextKey) {
      return { deserialize: [this.deserializeContextKey] };
    }
  }

  deserialize(data: T[K], context: {}): T {
    if (!context) {
      throw Error(`DataPropertyConverter : context key '${this.deserializeContextKey}' is required, context is missing`);
    } else if (!context[this.deserializeContextKey]) {
      throw Error(`DataPropertyConverter : context key '${this.deserializeContextKey}' is required, available context keys : [${Object.keys(context).join(',')}]`);
    }
    return this.mapper.deserialize(this.field, data, context[this.deserializeContextKey]);
  }

  serialize(data: T): T[K] {
    return this.mapper.serialize(this.field, data);
  }
}
