<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
      page.facturation.generer-factures.details-objet.title
    </h4>
  </div>

  <div class="modal-body">
    <table class="table table-striped">
      <thead>
        <tr>
          <th translate>page.facturation.generer-factures.details-objet.table.header.article</th>
          <th translate>page.facturation.generer-factures.details-objet.table.header.quantite</th>
          <th translate>page.facturation.generer-factures.details-objet.table.header.prix-unitaire</th>
          <th translate>page.facturation.generer-factures.details-objet.table.header.prix-minimum</th>
          <th translate>page.facturation.generer-factures.details-objet.table.header.total-ht</th>
          <th translate>page.facturation.generer-factures.details-objet.table.header.tva</th>
          <th translate>page.facturation.generer-factures.details-objet.table.header.montant-tva</th>
          <th translate>page.facturation.generer-factures.details-objet.table.header.total-ttc</th>

        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let dof of detailsObjetFacture; let i = index">
          <td>{{dof.libelleModeleArticle}}</td>
          <td>{{dof.quantite}}</td>
          <td>{{dof.prixUnitaire}}</td>
          <td>{{dof.prixMini}}</td>
          <td>{{dof.totalHt}}</td>
          <td>{{dof.tva}}</td>
          <td>{{dof.montantTva}}</td>
          <td>{{dof.totalTtc}}</td>
        </tr>
      </tbody>
    </table>

    <div class="actions-bar">
      <button class="btn btn-light" (click)="modal.dismiss()" translate>label.fermer</button>
    </div>
  </div>
</div>