import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';
import { DomaineCode } from './enums/domaine-code.enum';

@JsonObject
export class Domaine {
  @JsonProperty('id', Number)
  public id: number;

  @JsonProperty('code', String)
  public code: DomaineCode;

  @JsonProperty('libelle', String)
  public libelle: string;
}
