import { JsonObject, JsonProperty, Optional, CustomConverter } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { OrganismeOptionalConverter } from './converters/organisme.converter';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { Organisme } from './organisme.model';
import { UtilisateurType } from './utilisateur-type.model';
import { Entreprise } from './entreprise.model';

@JsonObject
export class UtilisateurBase {
  @JsonProperty('id', Number)
  @Optional
  public id: number = undefined;

  @JsonProperty('email', String)
  email: string = undefined;

  @JsonProperty('nom', String)
  @Optional
  nom: string = undefined;

  @JsonProperty('prenom', String)
  @Optional
  prenom: string = undefined;

  @JsonProperty('idType', Number)
  @CustomConverter(DataIdConverterFactory.build(UtilisateurType, 'typesUtilisateurs'))
  public type: UtilisateurType = undefined;

  @JsonProperty('entreprise', Entreprise)
  @Optional
  public entreprise: Entreprise = undefined;

  @JsonProperty('utilisateurAnpp', Boolean)
  @Optional
  public utilisateurAnpp: boolean = undefined;
  
  @JsonProperty('estConseillerTechnique', Boolean)
  @Optional
  public estConseillerTechnique: boolean = undefined;
}
