import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RecoltesFacade } from 'src/app/data/declaration/facades/recoltes/recoltes.facade';
import { Estimation } from 'src/app/data/declaration/models/estimation.model';

@Component({
  selector: 'app-estimations-recoltes',
  templateUrl: './estimations-recoltes.component.html',
  styleUrls: ['./estimations-recoltes.component.scss']
})
export class EstimationsRecoltesComponent implements OnInit {

  public estimations$: Observable<Array<Estimation>> = this.recoltesFacade.estimationsRecoltes$.pipe(
    map((estimations: Array<Estimation>) => {
      this.nbEstimations = estimations.length;
      let found = false;
      let cpt = 0;
      while (!found && cpt <= this.nbEstimations - 1) {
        const current = estimations[cpt];
        if (current && cpt < this.nbEstimations - 1) {
          const next = estimations[cpt + 1];
          if (current.espece != next.espece) {
            found = true;
            this.indexEspecesDifferentes = cpt;
          }
        }
        cpt++;
      }
      return estimations;
    })
  );
  public indexEspecesDifferentes: number;
  public nbEstimations = 0;

  constructor(private activeModal: NgbActiveModal, private recoltesFacade: RecoltesFacade) { }

  public ngOnInit(): void {
    this.recoltesFacade.loadEstimations();
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }

}
