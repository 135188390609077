import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbTabChangeEvent, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { RefCampagnesService } from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';
import { EngagementVergersEcoService } from 'src/app/data/habilitation/services/engagements/engagement-vergers-eco.service';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { EditEntrepriseComponent } from '../../edit-entreprise/edit-entreprise.component';
import { ListePoComponent } from '../../liste-po/liste-po/liste-po.component';

@Component({
  selector: 'app-fiche-entreprise',
  templateUrl: './fiche-entreprise.component.html',
  styleUrls: ['./fiche-entreprise.component.scss']
})
export class FicheEntrepriseComponent implements OnInit {

  entreprise : Entreprise;
  @Input() id : number;

  private ongletSelectionne = 'edit';
  private switchEnCours = false;
  public ongletVergerEco : Boolean = false;
  public idEngagement : Number;
  
  @ViewChild('edit') edit: EditEntrepriseComponent;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly entrepriseService : EntreprisesService,
    private readonly translate: TranslateService,
    private readonly alertService: AdvBootstrapModalService,
    private readonly router: Router,
    private readonly engagementVergersEcoService: EngagementVergersEcoService,
    private readonly refCampagnesService: RefCampagnesService
  ) { }

  ngOnInit() {


    const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    if(id)this.id=id;
    this.loadData(this.id);
  }
   
  private loadData(id: number): void {
    // Charger l'entreprise
    forkJoin(
      this.entrepriseService.getEntreprise(id)      
    ).subscribe(([entreprise]) => {
      this.entreprise = entreprise; 
      //TODO vérifier la présence d'un engagement pour géréer l'affichage de l'onglet
      this.refCampagnesService.getCampagneVerEnCours().subscribe(campagneVerEnCours => {
        this.engagementVergersEcoService.getEngagementEcoByEntrepriseAndCampagne(entreprise.id, campagneVerEnCours.code).subscribe(engagement => {
          if (engagement != null) {
            this.ongletVergerEco = true;
            this.idEngagement = engagement.id; 
            
          }
        });
      });
          
    });
  }

  /**
   * Retourne le formulaire affiché dans l'onglet courant
   * @returns FormGroup
   */
   private getCurrentViewChildForm(): FormGroup {
    switch (this.ongletSelectionne) {
      case 'edit': return this.edit.formGroup; break;  
      default: return null;
    }
  }

   /**
   * Détecte si le formulaire de l'onglet courant a subit des modifications et affiche
   * un message de prévention à l'utilisateur
   * @param $event L'évènement de changement d'onglet
   * @param tabset La directive Bootstrap d'onglets
   */
    public onTabChange($event: NgbTabChangeEvent, tabset: NgbTabset): void {
      const currentForm = this.getCurrentViewChildForm();
      // Vérifier si le changement d'onglet est provoqué par le tabset.select()
      // Ou s'il y a eut des modifications dans l'onglet courant
      if (this.switchEnCours) {
        // Laisser le changement d'onglet se faire
        this.switchEnCours = !this.switchEnCours;
      } else if (currentForm && currentForm.touched) {
        // Bloque le changement d'onglet
        $event.preventDefault();
  
        // Afficher une fenêtre surgissante de confirmation pour changer d'onglet
        this.alertService.confirm(
          this.translate.instant(`page.controles.manquement.edit.avertissementModif`),
          this.translate.instant(`page.controles.manquement.edit.avertissementTitre`), {
          cancelText: this.translate.instant(`page.controles.manquement.edit.avertissementCancel`),
          submitText: this.translate.instant(`page.controles.manquement.edit.avertissementConfirm`)
        }
        ).then(() => {
          // Prévenir le cas du onTabChange provoqué par le tabset.select()
          this.switchEnCours = !this.switchEnCours;
          // Aller sur l'onglet si l'utilisateur a confirmé
          tabset.select($event.nextId);
          // Changer le nom de l'onglet courant
          this.ongletSelectionne = $event.nextId;
        }, () => { $event.preventDefault(); });
      } else {
        // Gérer le cas du changement d'onglet standard
        this.ongletSelectionne = $event.nextId;
      }
    }

    public toGroupement(): void {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;

      this.router.navigate(['../',this.entreprise.entrepriseLiee.id ], { relativeTo: this.route });

    }

}
