import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NavigationContext, SessionContext } from 'src/app/core/services/config/app.settings';
import { HistoriqueDpap } from 'src/app/data/declaration/models/historique-dpap.model';
import { UniteCulturaleService } from 'src/app/data/declaration/services/parcellaire/unite-culturale.service';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { VisuDpapComponent } from '../../modals/visu-dpap/visu-dpap.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { from } from 'rxjs';

@Component({
  selector: 'app-historique-parcellaire',
  templateUrl: './historique-parcellaire.component.html',
  styleUrls: ['./historique-parcellaire.component.scss']
})
export class HistoriqueParcellaireComponent implements OnInit {

  constructor(
    public readonly modal: NgbModal,
    private readonly loaderService: AdvBootstrapLoaderService,
    //private readonly fb: FormBuilder,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly uniteCulturaleService: UniteCulturaleService,
    private readonly entrepriseService: EntreprisesService
   // private readonly translate: TranslateService
    ) { }

historiques: HistoriqueDpap[] = [];
entreprise: Entreprise;

    private get idOperateur(): number {
      if (NavigationContext.has('idOperateur')) {
        return NavigationContext.get('idOperateur');
      } else {
        return SessionContext.get('idIntervenant');
      }
    }

  ngOnInit() {

      this.entrepriseService.getEntrepriseByIdOperateur(this.idOperateur).subscribe( entreprise =>{
        this.entreprise = entreprise;
      this.uniteCulturaleService.getDPAPList(entreprise.id).subscribe((histos) => {
        this.historiques = histos;
      });
    });    
  }


  visuDpap(historique){
      
      const modalRef = this.modal.open(VisuDpapComponent, {backdrop: 'static', windowClass: 'app-modal-1000px'});
      modalRef.componentInstance.idDeclaration = historique.idDeclaration;
      modalRef.componentInstance.idCahier = historique.idCahier;
  }


}
