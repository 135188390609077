import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { RefForme } from 'src/app/data/declaration/models/ref-forme.model';
import { RefCultureBioService } from 'src/app/data/declaration/services/parcellaire/ref-culture-bio.service';
import { RefFormeService } from 'src/app/data/declaration/services/parcellaire/ref-forme.service';

@Component({
  selector: 'app-edit-forme',
  templateUrl: './edit-forme.component.html',
  styleUrls: ['./edit-forme.component.scss']
})
export class EditFormeComponent implements OnInit {

  formGroup: FormGroup;
  creation = false;
  title: string;
  forme: RefForme;

  get code() { return this.formGroup.get('code'); }
  get libelle() { return this.formGroup.get('libelle'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly formeService: RefFormeService,) { }

  ngOnInit() {

    const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    if (!id) {
      this.creation = true;
    }
      this.loadData(id);
    

  }

  loadData(id: number) {

    this.title = this.creation ? 'page.valeur_parametrable.form.title.add_forme': 'page.valeur_parametrable.form.title.edit_forme';
    
    forkJoin(
    !this.creation ? this.formeService.getRefForme() : of(new RefForme())
    )
    .pipe(
      this.loaderService.operator()
    ).subscribe(([gels]) => {
     this.forme = !this.creation ? gels.find(g => g.id === id) : gels ; 

    this.initForm((this.forme.id) ? this.forme : null);
     
    });
  }

  private initForm(forme?: RefForme): void {
 
    this.formGroup = this.fb.group({
      code: [{ value: ((forme) ? forme.code: undefined), disabled: false }, Validators.required],
      libelle: [{ value: ((forme) ? forme.libelle : undefined), disabled: false }, Validators.required]
    });
  }

  submit() {
    if (this.formGroup.valid) {
      this.forme.code = this.code.value;
      this.forme.libelle = this.libelle.value;
     
      if (this.forme.id == null) {
        this.formeService.creerRefForme(this.forme).subscribe(idCultureBio => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
        });
      } else {        
        this.formeService.modifierRefForme(this.forme).subscribe( () => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
         } );
      }
     
    }
  }

}
