import { JsonProperty, JsonObject, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';

import { College } from './college.model';
import { Cahier } from '../../habilitation/models/cahier.model';
import { Operateur } from '../../intervenant/models/operateur.model';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';

@JsonObject
export class Jure {
  @JsonProperty('id', Number)
  id: number;

  @JsonProperty('nom', String)
  nom: string;

  @JsonProperty('idCollege', Number)
  @CustomConverter(DataIdConverterFactory.build(College, 'colleges'))
  college: College;

  @JsonProperty('idCahier', Number)
  @CustomConverter(DataIdConverterFactory.build(Cahier, 'cahiers'))
  cahier: Cahier;

  @JsonProperty('libelleCahier', String)
  libelleCahier: string;

  @JsonProperty('idIntervenantList', [Number])
  idsOperateurs: number[];

  @JsonProperty('dateDerniereFormation', String)
  @CustomConverter(DateConverter)
  @Optional
  dateDerniereFormation: moment.Moment;

  @JsonProperty('mail', String)
  @Optional
  mail: string;

  operateurs: Operateur[];

  checked?: boolean;
}
