import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class Fichier {

    @JsonProperty('base64', String)
    base64: string;

    @JsonProperty('nom', String)
    nom: string;
}
