import { AdvBootstrapLoaderService } from "@adv/bootstrap-loader";
import { Component, Input, OnInit } from "@angular/core";
import { Utilisateur } from "src/app/data/intervenant/models/utilisateur.model";
import { UtilisateursService } from "src/app/data/intervenant/services/utilisateurs/utilisateurs.service";

@Component({
  selector: "app-list-conseiller",
  templateUrl: "./list-conseiller.component.html",
  styleUrls: ["./list-conseiller.component.scss"],
})
export class ListConseillerComponent implements OnInit {
  @Input() id: number;

  conseillers: Utilisateur[];
  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly utilisateursService: UtilisateursService
  ) {}

  ngOnInit() {
    this.utilisateursService
      .getConseillersTechniquesGroupe(this.id)
      .pipe(this.loaderService.operator())
      .subscribe((utilisateurs) => {
        this.conseillers = utilisateurs;
      });
  }
}
