import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
  HttpUserEvent,
  HttpResponse,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent
} from '@angular/common/http';

import { Observable, throwError, BehaviorSubject } from 'rxjs';

import { catchError, take, switchMap, finalize, filter } from 'rxjs/operators';


export abstract class AuthorizationInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() {
    // console.debug('interceptor constructor');
  }

  abstract buildHeaders(token: string): { [name: string]: string | string[] };
  abstract getToken(): string;
  abstract refreshToken(): Observable<string>;
  abstract logOut(): void;
  /**
   * if return true : add headers
   * @param  req [description]
   * @return     [description]
   */
  abstract applyHeadersFilter(req: HttpRequest<any>, ): boolean;

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: this.buildHeaders(token) });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent |
    HttpResponse<any> | HttpUserEvent<any>> {
    if (this.applyHeadersFilter(req)) {
      return next.handle(this.addToken(req, this.getToken())).pipe(
        catchError(error => {
          // console.debug('error http : ', error);
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              // case 400:
              //   return this.handle400Error(error);
              case 401:
                return this.handle401Error(req, next);
            }
          }
          return throwError(error);

        })
      );
    } else {
      return next.handle(req);
    }
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    // console.debug('error 401', req.headers.has('Authorization') ? req.headers.get('Authorization').slice(-5) : '');
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      // console.debug('refresh token');
      return this.refreshToken()
        .pipe(
          switchMap((newToken: string) => {
            if (newToken) {
              // console.debug('get new token', newToken.slice(-5));
              this.tokenSubject.next(newToken);
              return next.handle(this.addToken(req, newToken));
            }

            // console.debug('get new token token KO : loagout');
            // If we don't get a new token, we are in trouble so logout.
            return this.logoutUser();
          }),
          catchError(error => {
            // console.debug('get new token token error : loagout');
            // console.debug(error);
            // If there is an exception calling 'refreshToken', bad news so logout.
            if (error instanceof HttpErrorResponse && error.status === 401) {
              return this.logoutUser();
            } else {
              return throwError(error);
            }
          }),
          finalize(() => {
            // console.debug('end refresh token');
            this.isRefreshingToken = false;
          })
        );
    } else {
      // console.debug('refresh already called : wait for new token');
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(newToken => {
          // console.debug('refresh already called : get new token', newToken.slice(-5));
          return next.handle(this.addToken(req, newToken));
        })
      );
    }
  }

  logoutUser() {
    this.logOut();
    return throwError('');
  }

}
