import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StocksService } from 'src/app/data/declaration/services/stocks/stocks.service';
import { EstimationStock } from 'src/app/data/suivis/models/estimation-stock.model';

@Component({
  selector: 'app-estimation-stock',
  templateUrl: './estimation-stock.component.html',
  styleUrls: ['./estimation-stock.component.scss']
})
export class EstimationStockComponent implements OnInit {
  estimations: EstimationStock[];

  constructor(
    private activeModal: NgbActiveModal, 
    private readonly stocksService: StocksService,
    
  ) { }

  ngOnInit() {
    this.stocksService.getEstimationStocks().subscribe(r=>{
      this.estimations =       
          r.sort((a, b) => {
            return a.sorting().localeCompare(b.sorting());
          });
    });
  }


  public onClose(): void {
    this.activeModal.dismiss();
  }
}
