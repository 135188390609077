<div class="anpp" [ngClass]="{ content: !idEngagement }">
  <div [ngClass]="{ 'content-panel': !idEngagement }" *ngIf="engagement">
    <div class="title-bar" *ngIf="!idEngagement">
      <h4 translate>page.engagements.title</h4>
    </div>
    <form [formGroup]="formGroup" #form="ngForm" (submit)="submit()" novalidate>
      <div class="m-3">
        <div class="row">
          <div class="form-group col-lg-5">
            <label for="raisonsociale" translate
              >page.engagements.form.labels.raison-sociale</label
            >
            <input
              type="text"
              formControlName="raisonsociale"
              [ngClass]="{
                'is-invalid': form.submitted && raisonsociale.invalid
              }"
              class="form-control"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-5">
            <label for="responsable" translate
              >page.engagements.form.labels.responsable</label
            >
            <select
              id="responsable"
              formControlName="responsable"
              class="form-control"
              [ngClass]="{
                'is-invalid': form.submitted && responsable.invalid
              }"
            >
              <option
                *ngFor="let responsable of contacts"
                [ngValue]="responsable.id"
              >
                {{ responsable.nom }} {{ responsable.prenom }}
              </option>
            </select>
            <ng-container *ngIf="form.submitted">
              <div
                *ngIf="responsable.errors?.required"
                class="form-error"
                translate
              >
                label.obligatoire
              </div>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-5">
            <label for="activiteProduction" translate
              >page.engagements.form.labels.activite-production</label
            >
            <div>
              <input
                type="radio"
                class="mr-1"
                id="oui"
                formControlName="activiteProduction"
                [value]="true"
              />
              <label for="oui" translate>page.engagements.form.choix.oui</label>
              <input
                type="radio"
                class="ml-5 mr-1"
                id="non"
                formControlName="activiteProduction"
                [value]="false"
              />
              <label for="non" translate>page.engagements.form.choix.non</label>
            </div>
            <ng-container *ngIf="form.submitted">
              <div
                *ngIf="activiteProduction.errors?.required"
                class="form-error"
                translate
              >
                label.obligatoire
              </div>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-5">
            <label for="activiteConditionnement" translate
              >page.engagements.form.labels.activite-conditionnement</label
            >
            <div>
              <input
                type="radio"
                class="mr-1"
                id="oui"
                formControlName="activiteConditionnement"
                [value]="true"
              />
              <label for="oui" translate>page.engagements.form.choix.oui</label>
              <input
                type="radio"
                class="ml-5 mr-1"
                id="non"
                formControlName="activiteConditionnement"
                [value]="false"
              />
              <label for="non" translate>page.engagements.form.choix.non</label>
            </div>
            <ng-container *ngIf="form.submitted">
              <div
                *ngIf="activiteConditionnement.errors?.required"
                class="form-error"
                translate
              >
                label.obligatoire
              </div>
            </ng-container>
          </div>
          <div
            class="form-group col-lg-5"
            *ngIf="activiteConditionnement.value"
          >
            <label for="utilisationLogo" translate
              >page.engagements.form.labels.utilisation-logo</label
            >
            <div>
              <input
                type="radio"
                class="mr-1"
                id="oui"
                formControlName="utilisationLogo"
                [value]="true"
              />
              <label for="oui" translate>page.engagements.form.choix.oui</label>
              <input
                type="radio"
                class="ml-5 mr-1"
                id="non"
                formControlName="utilisationLogo"
                [value]="false"
              />
              <label for="non" translate>page.engagements.form.choix.non</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-5">
            <label for="contactReferentEco" translate
              >page.engagements.form.labels.contact-referent</label
            >
            <select
              id="contactReferentEco"
              formControlName="contactReferentEco"
              class="form-control"
              [ngClass]="{
                'is-invalid': form.submitted && contactReferentEco.invalid
              }"
            >
              <option
                *ngFor="let contactReferentEco of contactsReferents"
                [ngValue]="contactReferentEco.id"
              >
                {{ contactReferentEco.nom }} {{ contactReferentEco.prenom }}
              </option>
            </select>
            <ng-container *ngIf="form.submitted">
              <div
                *ngIf="contactReferentEco.errors?.required"
                class="form-error"
                translate
              >
                label.obligatoire
              </div>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-5">
            <label for="engagementAnimationTechnique" translate
              >page.engagements.form.labels.engagement-animation</label
            >
            <div>
              <input
                type="radio"
                class="mr-1"
                id="oui"
                formControlName="engagementAnimationTechnique"
                [value]="true"
              />
              <label for="oui" translate>page.engagements.form.choix.oui</label>
              <input
                type="radio"
                class="ml-5 mr-1"
                id="non"
                formControlName="engagementAnimationTechnique"
                [value]="false"
              />
              <label for="non" translate>page.engagements.form.choix.non</label>
            </div>
          </div>
        </div>
        <!-- Conseillers technique groupe -->
        <div
          class="row"
          *ngIf="conseillersGroupe && conseillersGroupe.length > 0"
        >
          <div class="form-group col-lg-5">
            <label for="conseillerTechnique" translate
              >page.engagements.form.labels.conseiller-technique-gp</label
            >
            <select
              id="conseillerTechnique"
              formControlName="conseillerTechnique"
              class="form-control"
              [ngClass]="{
                'is-invalid': form.submitted && conseillerTechnique.invalid
              }"
            >
              <option
                *ngFor="let conseillerTechnique of conseillersGroupe"
                [ngValue]="conseillerTechnique.id"
              >
                {{ conseillerTechnique.nom }} {{ conseillerTechnique.prenom }},
                {{ conseillerTechnique.entreprise.raisonSociale }}
              </option>
            </select>
            <ng-container *ngIf="form.submitted">
              <div
                *ngIf="conseillerTechnique.errors?.required"
                class="form-error"
                translate
              >
                label.obligatoire
              </div>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-5">
            <label
              *ngIf="conseillersGroupe && conseillersGroupe.length > 0"
              for="conseillerTechnique2"
              translate
              >page.engagements.form.labels.conseiller-technique2-gp</label
            >
            <label
              *ngIf="!conseillersGroupe || conseillersGroupe.length == 0"
              for="conseillerTechnique2"
              translate
              >page.engagements.form.labels.conseiller-technique</label
            >
            <select
              id="conseillerTechnique2"
              formControlName="conseillerTechnique2"
              class="form-control"
              [ngClass]="{
                'is-invalid': form.submitted && conseillerTechnique2.invalid
              }"
            >
              <option></option>
              <option
                *ngFor="let conseillerTechnique2 of conseillers"
                [ngValue]="conseillerTechnique2.id"
              >
                {{ conseillerTechnique2.nom }}
                {{ conseillerTechnique2.prenom }},
                {{ conseillerTechnique2.entreprise.raisonSociale }}
              </option>
            </select>
            <ng-container *ngIf="form.submitted">
              <div
                *ngIf="conseillerTechnique.errors?.required"
                class="form-error"
                translate
              >
                label.obligatoire
              </div>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-4">
            <label for="organismeCertification" translate
              >page.engagements.form.labels.organisme-certification</label
            >
            <select
              id="organismeCertification"
              formControlName="organismeCertification"
              class="form-control"
              [ngClass]="{
                'is-invalid': form.submitted && organismeCertification.invalid
              }"
            >
              <option
                *ngFor="let organismeCertification of organismesCertification"
                [ngValue]="organismeCertification"
              >
                {{ organismeCertification.raisonSociale }}
              </option>
            </select>
          </div>
          <ng-container *ngIf="form.submitted">
            <div
              *ngIf="organismeCertification.errors?.required"
              class="form-error"
              translate
            >
              label.obligatoire
            </div>
          </ng-container>
        </div>
        <!--LABORATOIRE RESIDUS -->
        <div class="row">
          <div class="form-group col-lg-4">
            <label for="laboratoireResidus" translate
              >page.engagements.form.labels.laboratoire-residus</label
            >
            <select
              id="laboratoireResidus"
              formControlName="laboratoireResidus"
              class="form-control"
              [ngClass]="{
                'is-invalid': form.submitted && laboratoireResidus.invalid
              }"
            >
              <option></option>
              <option
                *ngFor="let laboratoireResidus of laboratoireResidusList"
                [ngValue]="laboratoireResidus"
              >
                {{ laboratoireResidus.libelle }}
              </option>
            </select>
          </div>
        </div>
        <!--LABORATOIRE SOLS -->
        <div class="row">
          <div class="form-group col-lg-4">
            <label for="laboratoireSols" translate
              >page.engagements.form.labels.laboratoire-sols</label
            >
            <select
              id="laboratoireSols"
              formControlName="laboratoireSols"
              class="form-control"
              [ngClass]="{
                'is-invalid': form.submitted && laboratoireSols.invalid
              }"
            >
              <option></option>
              <option
                *ngFor="let laboratoireSols of laboratoireSolsList"
                [ngValue]="laboratoireSols"
              >
                {{ laboratoireSols.libelle }}
              </option>
            </select>
          </div>
        </div>
        <div class="margin-top">
          <h4 translate>page.engagements.form.grid-po.title</h4>
          <div class="dropdown-divider"></div>
          <table
            *ngIf="engagement.listEngagementDetail"
            table
            class="table table-striped"
          >
            <thead>
              <tr>
                <th translate>page.engagements.form.grid-po.raison-sociale</th>
                <th translate>page.engagements.form.grid-po.production</th>
                <th translate>page.engagements.form.grid-po.conditionnement</th>
                <th translate>page.engagements.form.grid-po.ver</th>
                <th translate>page.engagements.form.grid-po.gap</th>
                <th translate>page.engagements.form.grid-po.hve</th>
                <th translate>page.engagements.form.grid-po.ifs</th>
                <th translate>page.engagements.form.grid-po.brc</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let po of engagement.listEngagementDetail">
                <td>{{ po.entreprise.raisonSociale }}</td>
                <td>
                  <input
                    type="checkbox"
                    class="mr-1"
                    name="prod_{{ po.entreprise.id }}"
                    [(ngModel)]="po.activiteProduction"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="readOnly"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    class="mr-1"
                    name="condi_{{ po.entreprise.id }}"
                    [(ngModel)]="po.activiteConditionnement"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="readOnly"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    class="mr-1"
                    name="gap_{{ po.entreprise.id }}"
                    [(ngModel)]="po.engagementVergersEcoresponsables"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="readOnly"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    class="mr-1"
                    name="eco_{{ po.entreprise.id }}"
                    [(ngModel)]="po.engagementGlobalGap"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="readOnly"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    class="mr-1"
                    name="hev_{{ po.entreprise.id }}"
                    [(ngModel)]="po.engagementHve"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="changeEngagementHve(po)"
                    [disabled]="readOnly"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    class="mr-1"
                    name="ifs_{{ po.entreprise.id }}"
                    [(ngModel)]="po.certificationIfs"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="readOnly"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    class="mr-1"
                    name="brc_{{ po.entreprise.id }}"
                    [(ngModel)]="po.certificationBrc"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="readOnly"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="margin-top">
          <h4 translate>page.engagements.form.grid-sc.title</h4>
          <div class="dropdown-divider"></div>
          <table
            *ngIf="engagement.listEngagementSc"
            table
            class="table table-striped"
          >
            <thead>
              <tr>
                <th translate>page.engagements.form.grid-sc.raison-sociale</th>
                <th translate>page.engagements.form.grid-sc.logo</th>
                <th translate>page.engagements.form.grid-sc.ifs</th>
                <th translate>page.engagements.form.grid-sc.brc</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sc of engagement.listEngagementSc">
                <td>{{ sc.entreprise.raisonSociale }}</td>
                <td>
                  <input
                    type="checkbox"
                    class="mr-1"
                    id="oui"
                    [value]="true"
                    name="logo_{{ sc.entreprise.id }}"
                    [(ngModel)]="sc.utilisationLogo"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="readOnly"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    class="mr-1"
                    id="oui"
                    [value]="true"
                    name="sc_ifs_{{ sc.entreprise.id }}"
                    [(ngModel)]="sc.certificationIfs"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="readOnly"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    class="mr-1"
                    id="oui"
                    [value]="true"
                    name="sc_brc_{{ sc.entreprise.id }}"
                    [(ngModel)]="sc.certificationBrc"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="readOnly"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="actions-bar"
        fxLayoutAlign="space-between"
        *ngIf="!idEngagement"
      >
        <button
          type="button"
          class="btn btn-primary btnprimary"
          (click)="retour()"
          translate
        >
          page.engagements.form.bouton.retour
        </button>
        <button
          type="submit"
          class="btn btn-primary btnprimary"
          *ngIf="!readOnly"
          translate
        >
          page.engagements.form.bouton.enregistrer
        </button>
      </div>
    </form>
  </div>
</div>
