import { JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";
import { CommissionEchantillonEdition } from "./commission-echantillon-edition.model";
import { CommissionJureEdition } from './commission-jure-edition.model';

JsonObject
export class CommissionJuryEdition{

    @JsonProperty('id',Number)
    id: number

    @JsonProperty('numero',Number)
    numero: number

    @JsonProperty('cahiers',[String])
    @Optional
    cahiers: string[]

    @JsonProperty('jures', [CommissionJureEdition])
    @Optional
    jures: CommissionJureEdition[];

    @JsonProperty('echantillons', [CommissionEchantillonEdition])
    @Optional
    echantillons: CommissionEchantillonEdition[];
}

/*
 private Long id;
    private String numero;
    private List<String> cahiers = new ArrayList<>();
    List<CommissionEchantillonEditionDTO> echantillons  = new ArrayList<>();
    List<CommissionJureEditionDTO> jures = new ArrayList<>();
*/