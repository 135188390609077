import { Component, OnInit } from '@angular/core';
import { Routes, Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { RefCampagnesService } from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';
import { ParametreCampagne } from 'src/app/data/intervenant/models/parametre-campagne.model';
import { Campagne } from 'src/app/data/declaration/models/campagne.model';
import { forkJoin } from 'rxjs';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { remove } from 'lodash';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';
import { RefSoustypeUtilisateur } from 'src/app/data/droits/models/ref-soustype-utilisateur.model';
import { EntrepriseDroit } from 'src/app/data/intervenant/models/entreprise-droit.model';
import { Terroir } from 'src/app/data/intervenant/models/terroir.model';
import { EntrepriseReferent } from 'src/app/data/intervenant/models/entreprise-referent.model';
import { TranslateService } from '@ngx-translate/core';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { ToastrService } from 'ngx-toastr';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';

@Component({
  selector: 'app-campagnes',
  templateUrl: './campagnes.component.html',
  styleUrls: ['./campagnes.component.scss']
})
export class CampagnesComponent implements OnInit {

  static routes: Routes = [
    {
      path: '', component: CampagnesComponent, data: {
        role: 'ORGANISME',
        domaines: ["ANPP"],
        menu: {
          libelle: 'menu.administration-campagnes',
          icon: 'key'
        }
      }
    }
  ];

  public showFilter: Boolean = false;
  public campagneEncours: Campagne;
  public parametreCampagneList: ParametreCampagne[];
  public entrepriseList: EntrepriseReferent[];
  public entreprisesSelectionnees: EntrepriseReferent[] = [];
  public entreprisesFitrees: EntrepriseReferent[];
  public raisonSocialeFilter: string;
  public recolteFilter: boolean;
  public stockFilter: boolean;
  public ecoulementFilter: boolean;
  public referentFilter: string;
  public typeFilter: RefSoustypeUtilisateur;
  public terroirFilter: Terroir;
  public sousTypeList: RefSoustypeUtilisateur[];
  public terroirList: Terroir[];
  public entrepriseDroitModifie: EntrepriseReferent[] = [];

  constructor(
    private refCampagnesService: RefCampagnesService,
    private entreprisesService: EntreprisesService,
    private referencesService: ReferencesService,
    private router: Router,
    public config: NgbDropdownConfig,
    private modalsService: AdvBootstrapModalService,
    private translate: TranslateService,
    private toastrService: ToastrService,
    private readonly loader: AdvBootstrapLoaderService
  ) {
    config.placement = 'right-start';
    config.autoClose = true;
  }

  ngOnInit() {
    this.loadData();
  }

  private loadData(): void {
    forkJoin(
      this.refCampagnesService.getCampagneEnCours(),
      this.entreprisesService.getEntreprisesReferentsPourParamCampagne(),
      this.referencesService.getReferences()
    ).pipe(
      this.loader.operator()
    ).subscribe(([campagne, entreprises, references]) => {
      this.campagneEncours = campagne;
      this.entrepriseList = entreprises;
      this.entreprisesFitrees = this.entrepriseList;
      this.sousTypeList = references.sousTypesUtilisateurs;
      this.terroirList = references.terroirs;
      this.refCampagnesService.getParametreCampagne(this.campagneEncours.id).pipe(
        this.loader.operator()
      ).subscribe((parametres: Array<ParametreCampagne>) => {
        //const para : ParametreCampagne[] = [{id: 1, idEntreprise: 1, idCampagne: 1, droitRecolte: true, droitStock: false, droitEcoulement: true},
        //  {id: 2, idEntreprise: 2, idCampagne: 1, droitRecolte: false, droitStock: false, droitEcoulement: false}];
        this.parametreCampagneList = parametres;
        this.entrepriseList.forEach(entreprise => {
          entreprise.terroir = references.terroirs.find((terroir: Terroir) =>
            (terroir.departements != null && terroir.departements.indexOf(entreprise.departement) !== -1)
          );
          entreprise.parametreCampagne = this.parametreCampagneList.find((param: ParametreCampagne) =>
            param.idEntreprise === entreprise.id
          );
        });
      })
    })
  };

  public save(): void {
    this.refCampagnesService
      .save(this.parametreCampagneList, this.campagneEncours.id)
      .subscribe(() => {
        this.toastrService.success(this.translate.instant('page.campagnes.enregistrement-succes'));
        this.enregistrerDroits();
      });
  }

  public onNouvelleCampagne(): void {
    this.modalsService.confirm(
      this.translate.instant(`page.campagnes.modalConfirmText`),
      this.translate.instant(`page.campagnes.modalConfirmTitle`), {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.valider`)
    }
    ).then(() => {
      this.refCampagnesService.ouvrirCampagne().pipe(
        this.loader.operator()
      ).subscribe(() =>
        this.loadData()
      );
    }, () => { });
  }

  public selectionnerEntreprise(entreprise: EntrepriseReferent): void {
    if (this.entreprisesSelectionnees.includes(entreprise)) {
      remove(this.entreprisesSelectionnees, entreprise);
    } else {
      this.entreprisesSelectionnees.push(entreprise);
    }
  }

  public modifierDR(valeur: boolean): void {
    this.entreprisesSelectionnees.forEach((entreprise: EntrepriseReferent) => {
      entreprise.parametreCampagne.droitRecolte = valeur
      this.addDroitsModifieList(entreprise);
    }
    );
  }

  public modifierStock(valeur: boolean): void {
    this.entreprisesSelectionnees.forEach((entreprise: EntrepriseReferent) => {
      entreprise.parametreCampagne.droitStock = valeur
      this.addDroitsModifieList(entreprise);
    }
    );
  }

  public modifierEcoulement(valeur: boolean): void {
    this.entreprisesSelectionnees.forEach((entreprise: EntrepriseReferent) => {
      entreprise.parametreCampagne.droitEcoulement = valeur;
      this.addDroitsModifieList(entreprise);
    }
    );
  }

  public modifierReferent(entreprise: Entreprise): void {
    this.router.navigate(['/main/admin/params/entreprises/fiche/' + entreprise.id]);
  }

  public selectAllEntreprises(event: any): void {
    this.entreprisesSelectionnees = [];
    if (event.target.checked) {
      this.entreprisesFitrees.forEach((entreprise: EntrepriseReferent) =>
        this.entreprisesSelectionnees.push(entreprise)
      )
    }
  }

  public filter(): void {
    this.entreprisesFitrees = this.entrepriseList.filter((item: EntrepriseReferent) => {
      if (this.raisonSocialeFilter && item.raisonSociale.toLowerCase().indexOf(this.raisonSocialeFilter.toLowerCase()) === -1) {
        return false;
      }
      if (this.recolteFilter !== undefined && this.recolteFilter !== null && item.parametreCampagne.droitRecolte !== this.recolteFilter) {
        return false;
      }
      if (this.stockFilter !== undefined && this.stockFilter !== null && item.parametreCampagne.droitStock !== this.stockFilter) {
        return false;
      }
      if (this.ecoulementFilter !== undefined && this.ecoulementFilter !== null && item.parametreCampagne.droitEcoulement !== this.ecoulementFilter) {
        return false;
      }
      if (
        this.referentFilter && (
          (this.referentFilter === "pasReferentRecolte" && item.referentRecolteList.length > 0) ||
          (this.referentFilter === "pasReferentStock" && item.referentStockList.length > 0) ||
          (this.referentFilter === "pasReferentEcoulement" && item.referentEcoulementList.length > 0)
        )
      ) {
        return false;
      }
      if (this.typeFilter !== undefined && this.typeFilter !== null && item.sousType.id !== this.typeFilter.id) {
        return false;
      }
      if (this.terroirFilter !== undefined && this.terroirFilter !== null && this.terroirFilter.departements.indexOf(item.departement) === -1) {
        return false;
      }

      return true;
    })
  }

  public addDroitsModifieList(entreprise: EntrepriseReferent): void {
    if (!this.entrepriseDroitModifie.find(entr => entr.id == entreprise.id)) {
      this.entrepriseDroitModifie.push(entreprise);
    }
  }

  private enregistrerDroits(): void {
    const entrepriseDroitList: EntrepriseDroit[] = [];

    this.entrepriseDroitModifie.forEach((entreprise: EntrepriseReferent) => {
      let entrepriseDroit: EntrepriseDroit = new EntrepriseDroit();
      entrepriseDroit.id = entreprise.id;
      entrepriseDroit.droitRecolte = entreprise.parametreCampagne.droitRecolte;
      entrepriseDroit.droitStock = entreprise.parametreCampagne.droitStock;
      entrepriseDroit.droitEcoulement = entreprise.parametreCampagne.droitEcoulement;

      entrepriseDroitList.push(entrepriseDroit);
    });

    this.entreprisesService.enregistrerDroits(entrepriseDroitList).subscribe(() =>
      this.entrepriseDroitModifie = []
    );
  }
}
