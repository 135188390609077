import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditPorteGreffeComponent } from './edit-porte-greffe/edit-porte-greffe.component';
import { ListesPortesGreffesComponent } from './listes-portes-greffes/listes-portes-greffes.component';

@Component({
  selector: 'app-portes-greffes',
  templateUrl: './portes-greffes.component.html',
  styleUrls: ['./portes-greffes.component.scss']
})
export class PortesGreffesComponent implements OnInit {

  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"]
    };
  
    static routes: Routes = [
      { path: '', component: ListesPortesGreffesComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id', component: EditPorteGreffeComponent, data: { roles: ['ORGANISME'] } },
      { path: 'new', component: EditPorteGreffeComponent, data: { roles: ['ORGANISME'] } }
    ];

  constructor() { }

  ngOnInit() {
  }

}
