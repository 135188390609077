import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Routes } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { Referentiel } from 'src/app/data/declaration/models/referentiel.model';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { SuiviParcellairesViticoles } from 'src/app/data/suivis/models/suivi-parcellaires-viticoles.model';
import { SuiviParcellairesViticolesService } from 'src/app/data/suivis/services/suivi-parcellaires-viticoles/suivi-parcellaires-viticoles.service';
import * as moment from 'moment';
import { RefCampagne } from 'src/app/data/habilitation/models/engagements/ref.campagne.model';
import { RefCampagnesService } from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';
import { Campagne } from 'src/app/data/declaration/models/campagne.model';
import { Cahier } from 'src/app/data/habilitation/models/cahier.model';
import { CahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { ExportToCsv } from 'export-to-csv';
import { VisuRecapUcComponent } from '../parcellaire-viticole/modals/visu-recap-uc/visu-recap-uc.component';
import { UniteCulturaleService } from 'src/app/data/declaration/services/parcellaire/unite-culturale.service';
import { UniteCulturale } from 'src/app/data/declaration/models/unite-culturale.model';

@Component({
  selector: 'app-suivi-parcellaires-viticoles',
  templateUrl: './suivi-parcellaires-viticoles.component.html',
  styleUrls: ['./suivi-parcellaires-viticoles.component.scss']
})
export class SuiviParcellairesViticolesComponent implements OnInit {
  static routes: Routes = [{
    path: '',
    redirectTo: 'liste',
    pathMatch: 'full',
    data: {
      role: 'ORGANISME',
      menu: { icon: 'wine-bottle', libelle: 'menu.suivi-parcellaires-viticoles', hide: true }
    }
  }, {
    path: 'liste',
    component: SuiviParcellairesViticolesComponent
  }];
  public historiques: SuiviParcellairesViticoles[];
  public referentiel: Referentiel;
  public searchForm: FormGroup;
  public estAffecte: Boolean = false;
  public campagneEnCours: Campagne;  
  public refCahiers: Cahier[];

  
  get debutDate() { return this.searchForm.get('debutDate'); }
  get finDate() { return this.searchForm.get('finDate'); }
  get annee() { return this.searchForm.get('annee'); }
  get non_controlable() { return this.searchForm.get('non_controlable'); }
  get raisonSociale() { return this.searchForm.get('raisonSociale'); }
  get suivi() { return this.searchForm.get('suivi'); }
  get cdcs() {
    return this.searchForm.get('cdcs');
  }

  
  constructor(
    private readonly loader: AdvBootstrapLoaderService,
    private readonly suiviParcellaireService: SuiviParcellairesViticolesService,
    private readonly uniteCulturaleService: UniteCulturaleService,
    private readonly fb: FormBuilder,
    private readonly referentielService: ReferentielService,
    private readonly modalService: NgbModal,
    private readonly refCampagneService: RefCampagnesService,
    private readonly cahiersService: CahiersService,
  ) { }
  
  ngOnInit() {

    this.refCampagneService.getCampagneEnCours().subscribe((campagne)=>{
      this.campagneEnCours = campagne;

   
      // Instancier le formulaire de recherche
      this.searchForm = this.fb.group({
        raisonSociale: undefined,
        annee: this.campagneEnCours.annee.toString(),
        non_controlable: undefined,
        debutDate: undefined,
        finDate: undefined,
        suivi: false,
        cdcs: undefined
      });

        this.loadData(); 
    }
    )
  }

  private loadData() {
    forkJoin(
      this.suiviParcellaireService.getSuiviParcellaire('', this.campagneEnCours.annee.toString(),'',null,null,'false',null ),
      this.referentielService.getReferentiel(),
      this.cahiersService.getCahiers()
    ).pipe(this.loader.operator()).subscribe(
      ([historiques, referentiel,cahiers]) => {
        this.historiques = historiques;
        this.referentiel = referentiel;
        this.refCahiers = cahiers;
      });
  }

  search() {

    if (this.searchForm.valid) {
      
      const annee = this.estAffecte ? this.searchForm.get('annee').value as string : this.campagneEnCours.annee.toString();
      const raisonSociale = this.searchForm.get('raisonSociale').value as string;
      const non_controlable = this.searchForm.get('non_controlable').value as string;
      const suivi = this.searchForm.get('suivi').value as string;
      const finDate = this.finDate.value;
      const debutDate = this.debutDate.value;
      const expCdc = [];

      if (this.cdcs.value != undefined) {
        this.cdcs.value.forEach((element) => {
          expCdc.push(element.id);
        });
      } else {
        this.refCahiers.forEach((element) => {
          expCdc.push(element.id);
        });
      }

      forkJoin(
        this.suiviParcellaireService.getSuiviParcellaire(
          raisonSociale,
          annee,
          non_controlable,
          this.getMomentDate(debutDate),
          this.getMomentDate(finDate),
          suivi,
          expCdc
      )
      ).pipe(this.loader.operator()).subscribe(
        ([historiques]) => {
          this.historiques = historiques;
        });        
      
    }
  }

    /**
   * Transforme une date provenant d'un Datepicker en date momentJS
   * @param date La date provenant d'un Datepicker
   * @see moment.Moment
   */
    private getMomentDate(date: {
      year: number,
      month: number,
      day: number
    }): moment.Moment {
      return (date) ? moment([date.year, date.month - 1, date.day, 0, 0, 0]) : null;
    }


    public onEdit(mouvement: SuiviParcellairesViticoles): void {

      this.uniteCulturaleService.getUniteCulturale(mouvement.idUniteCulturale).subscribe((uc)=>{
        const modal = this.modalService.open(VisuRecapUcComponent, { backdrop: 'static', windowClass: 'app-modal-1000px' });
        modal.componentInstance.uniteCulturale = uc;
    
        modal.result.then(() => { }, () => {});

      });

       
    }

    changeType() {
    
      if (this.suivi.value === 'true') {
        this.estAffecte = true;
      } else if (this.suivi.value === 'false') {
        this.estAffecte = false;
      } 
    }

    exporterParcellaire(){
      const data = [];
    this.historiques.forEach(exp => {

      data.push({
        raisonSociale: exp.entreprise.raisonSociale,
        commune: exp.commune,
        referenceCadastrale: exp.referenceCadastrale.replace(';', ','),
        produit: exp.libelleProduit,
        anneePlantation: exp.anneePlantation,
        variete: exp.variete,
        surfacePlantee: exp.surfacePlantee.toFixed(2),
        surfaceEngagee: exp.surfaceEngagee.toFixed(2)
      });
    });


    const options = {
      filename: 'EXPORT_PARCELLAIRE'.concat('_').concat(moment().format('YYYYMMDD')),
      fieldSeparator: ';',
      quoteStrings: '',
      decimalSeparator: ',',
      showLabels: true,
      showTitle: false,
      title: 'EXPORT_DECLARATION'.concat('_').concat(moment().format('YYYYMMDD')),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        'Raison Sociale',
        'Commune',
        'Réf. Cadastrale',
        'Produit',
        'Année Plantation',
        'Cépage',
        'Surface Plantée (ha)',
        'Surface Engagée (ha)'
      ]
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);

    }

}
