import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { OrganismeOptionalConverter } from './converters/organisme.converter';
import { Domaine } from './domaine.model';
import { Organisme } from './organisme.model';
import { Profil } from './profil.model';
import { Service } from './service.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';

@JsonObject
export class UtilisateurProfil {
  @JsonProperty('id', Number)
  @Optional
  public id: number = undefined;

  @JsonProperty('idDomaine', Number)
  @Optional
  @CustomConverter(DataIdConverterFactory.build(Domaine, 'domaines'))
  domaine: Domaine = undefined;

  @JsonProperty('idOrganisme', Number)
  @Optional
  @CustomConverter(OrganismeOptionalConverter)
  organisme: Organisme = undefined;

  @JsonProperty('idService', Number)
  @Optional
  @CustomConverter(DataIdConverterFactory.build(Service, 'services'))
  service: Service = undefined;

  @JsonProperty('idProfil', Number)
  @Optional
  @CustomConverter(DataIdConverterFactory.build(Profil, 'profils'))
  profil: Profil = undefined;
}
