import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { Pays } from '../../intervenant/models/pays.model';
import { Operateur } from '../../intervenant/models/operateur.model';

@JsonObject
export class OperateurExport extends Operateur {
    @JsonProperty('cvi', String)
    @Optional
    public cvi: string = undefined;
}
