import { Injectable } from '@angular/core';
import { ActivitesService } from 'src/app/data/habilitation/services/activites/activites.service';
import { HabilitationsService } from 'src/app/data/habilitation/services/habilitations/habilitations.service';
import { Observable, forkJoin, of } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { UtilisateurTypeCode } from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';
import { ClearCache, Cache, CacheKey } from 'src/app/core/services/cache/cache.service';
import { RefStatutCode } from 'src/app/data/habilitation/models/enums/ref-statut-code.enum';

@Injectable({
  providedIn: 'root'
})
export class HasHabilitationService {

  constructor(
    private readonly habilitationService: HabilitationsService,
    private readonly activitesService: ActivitesService
  ) { }

  private getIdActiviteByCode(code: string): Observable<number> {
    return this.activitesService.getActivites().pipe(
      map(codes => {
        const aa = codes.find(act => act.code === code);
        if (aa) {
          return aa.id;
        }

        return null;
      })
    );
  }

  @ClearCache
  clearCache() { }

  @Cache({ maxAge: 60 })
  hasHabilitation(@CacheKey activiteConditionString: string, @CacheKey idCahier: number, @CacheKey idOperateur: number): Observable<boolean> {
    const request = SessionContext.get('utilisateurType') === UtilisateurTypeCode.OPERATEUR ?
      this.habilitationService.getHabilitations() :
      this.habilitationService.getOperateurHabilitations(idOperateur);

    return request.pipe(
      map(habilitations => {
        habilitations = habilitations.filter(hab => hab.cahier.id === idCahier && hab.statut.code === RefStatutCode.Habilite);

        const parts = activiteConditionString.match(/([A-Z0-9_-]+|\d+|[&|!\(\)]+|\s+)/g);
        const transcriptedParts = parts.map(a => {
          if (/^\d+$/.test(a)) {
            const id = a;
            return !!habilitations.find(hab => hab.activite.id.toString() === id) ? '1' : '0';
          } else if (/^[A-Z0-9_-]+$/.test(a)) {
            const code = a;
            return !!habilitations.find(hab => hab.activite.code === code) ? '1' : '0';
          } else {
            return a;
          }
        });
        const conditionStr = transcriptedParts.join('');
        // console.debug('###', activiteConditionString, '=>', conditionStr);

        // on check qu'il ne reste plus rien d'autre qu'un expression logique dans la chaine
        if (/[^01&|!() ]/i.test(conditionStr)) {
          throw Error(`activites expression invalid : "${activiteConditionString}"`);
        }
        // la chaine est propre, on peut se permettre un 'eval'
        // tslint:disable-next-line:no-eval
        return !!eval(conditionStr);
      })
    );
  }

}
