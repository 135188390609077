import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class CommissionJureEdition {

    @JsonProperty('id', Number)
    @Optional
    id: number;

    @JsonProperty('jury', Number)
    @Optional
    jury: number;

    @JsonProperty('nom', String)
    @Optional
    nom: string;

    @JsonProperty('college', String)
    @Optional
    college: string;

    @JsonProperty('adresse', String)
    @Optional
    adresse: string;

    @JsonProperty('mail', String)
    @Optional
    mail: string;

    @JsonProperty('telephone', String)
    @Optional
    telephone: string;

    @JsonProperty('portable', String)
    @Optional
    portable: string;

    @JsonProperty('statut', String)
    @Optional
    statut: string;
}
