import {AdvBootstrapLoaderService} from '@adv/bootstrap-loader';
import {Component, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {forkJoin, from, Subject} from 'rxjs';
import {Contact} from 'src/app/data/intervenant/models/contact.model';
import {ContactsService} from 'src/app/data/intervenant/services/contacts/contacts.service';
import {remove} from 'lodash';
import * as moment from 'moment';
import {ExportToCsv} from 'export-to-csv';
import {Fonction} from 'src/app/data/intervenant/models/fonction.model';
import {Referent} from 'src/app/data/intervenant/models/referent.model';
import {Groupe} from 'src/app/data/intervenant/models/groupe.models';
import {ReferencesService} from 'src/app/data/intervenant/services/references/references.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SelectContactColonneComponent} from './modals/select-contact-colonne/select-contact-colonne.component';
import {EntreprisesService} from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import {Entreprise} from 'src/app/data/intervenant/models/entreprise.model';
import {AdvBootstrapModalService} from '@adv/bootstrap-modal';
import {TranslateService} from '@ngx-translate/core';
import {RefSoustypeUtilisateur} from 'src/app/data/droits/models/ref-soustype-utilisateur.model';
import {RefSousTypesService} from 'src/app/data/intervenant/services/ref-sous-types/ref-sous-types.service';


@Component({
  selector: 'app-liste-contacts',
  templateUrl: './liste-contacts.component.html',
  styleUrls: ['./liste-contacts.component.scss']
})
export class ListeContactsComponent implements OnInit {

  filtrer: boolean = true;

  contacts: Contact[];
  contactFilter: Contact[];

  fonctions: Fonction[];
  referents: Referent[];
  groupes: Groupe[];
  typeRefs: RefSoustypeUtilisateur[];
  entreprises: Entreprise[];

  nomFilter: string;
  prenomFilter: string;
  mailFilter: string;
  adresseFilter: string;
  codepostalFilter: string;
  communeFilter: string;
  fonctionFilter: Fonction;
  referentFilter: Referent;
  groupeFilter: Groupe;
  estintervenantFilter: boolean;
  entrepriseFilter: string;
  civiliteFilter: string;
  telephoneFilter: string;
  portableFilter: string;
  estadherentFilter: boolean;
  typerefFilter: RefSoustypeUtilisateur;

  exportData: {};

  headers = [
    {name: 'civilite', libelle: "Civilité", checked: false},
    {name: 'nom', libelle: "Nom", checked: true},
    {name: 'prenom', libelle: "Prénom", checked: true},
    {name: 'entreprise', libelle: "Entreprise", checked: true},
    {name: 'telephone', libelle: "Téléphone fixe", checked: true},
    {name: 'mail', libelle: "Email", checked: true},
    {name: 'adresse', libelle: "Adresse postale", checked: true},
    {name: 'codepostal', libelle: "Code Postal", checked: true},
    {name: 'commune', libelle: "Commune", checked: true},
    {name: 'fonction', libelle: "Fonction", checked: true},
    {name: 'estintervenant', libelle: "Utilisateur du portail", checked: true},
    {name: 'portable', libelle: "Téléphone portable", checked: false},
    {name: 'referents', libelle: "Référent", checked: false},
    {name: 'typeRefs', libelle: "Type d'entreprise", checked: false},
    {name: 'estadherent', libelle: "Adhérent", checked: false},
    {name: 'groupes', libelle: "Groupe de diffusion", checked: false}
  ];

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly contactsService: ContactsService,
    private readonly entreprisesService: EntreprisesService,
    private readonly modalService: NgbModal,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly translate: TranslateService,
    private readonly refSousTypeService: RefSousTypesService
  ) {
  }

  ngOnInit() {
    this.getContacts();
  }

  getContacts() {
    forkJoin(
      this.contactsService.getContacts(),
      this.contactsService.getReferents(),
      this.contactsService.getGroupes(),
      this.entreprisesService.getEntreprises(),
      this.refSousTypeService.getRefSousType()
    )
      .pipe(this.loaderService.operator()).subscribe(([contacts, referents, groupes, entreprises, typeRefs]) => {
      this.fonctions = ReferencesService.getReference('fonctions');
      this.contacts = contacts;
      this.contactFilter = contacts;
      this.referents = referents;
      this.groupes = groupes;
      this.entreprises = entreprises;
      this.typeRefs = typeRefs;
    });

  }

  editContact(contact: Contact) {
    this.router.navigate(['edit', contact.id], {relativeTo: this.route});
  }

  interactionContact(contact: Contact) {
    this.router.navigate([contact.id, 'interactions'], {relativeTo: this.route});
  }

  openConfirmationDialog(contact: Contact) {

    this.modalsService.confirm(
      'Veuillez confirmer',
      'Voulez vous supprimer le contact ' + contact.prenom + ' ' + contact.nom + ' ?', {
        cancelText: this.translate.instant(`label.annuler`),
        submitText: this.translate.instant(`label.confirmer`),
        windowClass: 'anpp'
      }
    ).then(() => {
      this.deleteContact(contact);
    }, () => {
    });

  }

  deleteContact(contact: Contact) {
    this.contactsService.supprimerContact(contact.id).subscribe(() => {
      remove(this.contacts, contact);
    });

  }

  afficheFiltre() {
    this.filtrer = !this.filtrer;
  }

  afficherListe() {

    const modalRef = this.modalService.open(SelectContactColonneComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.headers = this.headers;

    from(modalRef.result).subscribe(headers => {
      this.headers = headers;
    });

  }

  show(obj) {
    return (this.headers.find(h => h['name'] === obj)).checked;
  }

  filter() {
    if (this.contacts && this.contacts.length) {
      this.contactFilter = this.contacts.filter(item => {
        if (this.civiliteFilter && item.civilite.toLowerCase().indexOf(this.civiliteFilter.toLowerCase()) === -1) {
          return false;
        }
        if (this.nomFilter && item.nom.toLowerCase().indexOf(this.nomFilter.toLowerCase()) === -1) {
          return false;
        }
        if (this.prenomFilter && item.prenom.toLowerCase().indexOf(this.prenomFilter.toLowerCase()) === -1) {
          return false;
        }
        if (this.portableFilter && ((item.telephoneportable && item.telephoneportable.toLowerCase().indexOf(this.portableFilter.toLowerCase()) === -1) || item.telephoneportable === undefined || item.telephoneportable === '')) {
          return false;
        }
        if (this.telephoneFilter && ((item.telephonefixe && item.telephonefixe.toLowerCase().indexOf(this.telephoneFilter.toLowerCase()) === -1) || item.telephonefixe === undefined || item.telephonefixe === '')) {
          return false;
        }
        if (this.mailFilter && item.mail.toLowerCase().indexOf(this.mailFilter.toLowerCase()) === -1) {
          return false;
        }
        if (this.adresseFilter && item.adresse.toLowerCase().indexOf(this.adresseFilter.toLowerCase()) === -1) {
          return false;
        }
        if (this.codepostalFilter && item.codepostal.toLowerCase().indexOf(this.codepostalFilter.toLowerCase()) === -1) {
          return false;
        }
        if (this.communeFilter && item.commune.toLowerCase().indexOf(this.communeFilter.toLowerCase()) === -1) {
          return false;
        }
        if (this.fonctionFilter && (item.fonction == null || item.fonction.id !== this.fonctionFilter.id)) {
          return false;
        }
        if (this.referentFilter && !item.referents.find(ref => ref.id === this.referentFilter.id)) {
          return false;
        }
        if (this.groupeFilter && !item.groupes.find(ref => ref.id === this.groupeFilter.id)) {
          return false;
        }
        if (this.estintervenantFilter !== undefined && item.estintervenant !== this.estintervenantFilter) {
          return false;
        }
        if (this.entrepriseFilter && item.entreprise.raisonSociale.toLowerCase().indexOf(this.entrepriseFilter.toLowerCase()) === -1) {
          return false;
        }
        if (this.typerefFilter && item.entreprise.refSousType.id !== this.typerefFilter.id) {
          return false;
        }
        if (this.estadherentFilter !== undefined && !(item.entreprise.estAdherent === this.estadherentFilter)) {
          return false;
        }

        return true;
      })
    }
  }

  //Export CSV
  exporterContacts(): void {
    const data = [];
    const headersCSV = [];

    this.headers.filter(head => head.checked).forEach(header => {
        headersCSV.push(header['libelle'])
      }
    );

    this.contactFilter.forEach(contact => {

      this.exportData = {};

      if (this.show('civilite')) this.exportData["civilite"] = contact.civilite;
      if (this.show('nom')) this.exportData["nom"] = contact.nom;
      if (this.show('prenom')) this.exportData["prenom"] = contact.prenom;
      if (this.show('entreprise')) this.exportData["entreprise"] = contact.entreprise.raisonSociale;
      if (this.show('telephone')) this.exportData["telephone"] = contact.telephonefixe === undefined ? "" : contact.telephonefixe;
      if (this.show('mail')) this.exportData["mail"] = contact.mail;
      if (this.show('adresse')) this.exportData["adresse"] = contact.adresse;
      if (this.show('codepostal')) this.exportData["codepostal"] = contact.codepostal;
      if (this.show('commune')) this.exportData["commune"] = contact.commune;
      if (this.show('fonction')) this.exportData["fonction"] = contact.fonction.libelle;
      if (this.show('estintervenant')) this.exportData["estintervenant"] = (contact.estintervenant) ? "Oui" : "Non";
      if (this.show('portable')) this.exportData["portable"] = contact.telephoneportable !== undefined ? contact.telephoneportable : "";
      if (this.show('referents')) this.exportData["referents"] = this.afficherCodeReferents(contact.referents);
      if (this.show('typeRefs')) this.exportData["typeRefs"] = contact.entreprise.refSousType.libelle;
      if (this.show('estadherent')) this.exportData["estadherent"] = (contact.entreprise.estAdherent) ? "Oui" : "Non";
      if (this.show('groupes')) this.exportData["groupes"] = this.afficherCodeGroupes(contact.groupes);

      data.push(this.exportData
      );

    });


    const options = {
      filename: 'EXPORT_CONTACT'.concat('_').concat(moment().format('YYYYMMDD')),
      fieldSeparator: ';',
      quoteStrings: '',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'EXPORT_CONTACT'.concat('_').concat(moment().format('YYYYMMDD')),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: headersCSV
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);

  }

  afficherCodeReferents(referents: Referent[]) {

    const codes = [];
    referents.forEach(r => codes.push(r.code));
    return codes.join(", ");

  }

  afficherCodeGroupes(groupes: Groupe[]) {

    const codes = [];
    groupes.forEach(g => codes.push(g.code));
    return codes.join(", ");

  }

}
