import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class Profil {

  @JsonProperty('id', Number)
  public id: number = undefined;

  @JsonProperty('idOrganisme', Number)
  @Optional
  public idOrganisme: number = undefined;

  @JsonProperty('idService', Number)
  public idService: number = undefined;

  @JsonProperty('libelle', String)
  public libelle: string = undefined;
}
