import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Terroir } from '../../models/terroir.model';

@Injectable({
  providedIn: 'root'
})
export class TerroirsService {

  constructor(  
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
    ) { }

    getTerroir(idTerroir : number): Observable<Terroir> {
      return  this.http.get<Terroir>(`/api/intervenant/private/terroirs/${idTerroir}`).pipe(
        map(terroir => this.mapper.deserializeObject(terroir, Terroir))
      );        
    }

    getTerroirs(): Observable<Terroir[]> {
      return  this.http.get<Terroir[]>(`/api/intervenant/private/terroirs`).pipe(
        map(terroir => this.mapper.deserializeArray(terroir, Terroir))
      );        
    }

    public creerTerroir(terroir: Terroir): Observable<number> {
      return this.http.post(`/api/intervenant/private/terroirs`, terroir, { observe: 'response' }).pipe(
        map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
      );
    }  
    
    public modifierTerroir(terroir: Terroir): Observable<void> {
      return this.http.put<void>(`/api/intervenant/private/terroirs/${terroir.id}`, terroir);
    }

    public supprimerTerroir(idTerroir: number): Observable<void> {
      return this.http.delete<void>(`/api/intervenant/private/terroirs/${idTerroir}`);
    }

}
