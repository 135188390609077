<div class="anpp">
  <div class="content">
    <div class="content-panel" *ngIf="terroir">
      <div class="title-bar">
        <h4>
          {{ title | translate }}
        </h4>
      </div>

      <form *ngIf="formGroup" [formGroup]="formGroup" #form="ngForm" (submit)="submit()" novalidate>

        <div class="m-3">

          <div class="form-group">
            <label for="libelle" translate>page.valeur_parametrable.form.label.libelle</label>

            <input type="text" id="libelle" formControlName="libelle"
              [ngClass]="{'is-invalid': form.submitted && libelle.invalid}" class="form-control" />

            <ng-container *ngIf="form.submitted">
              <div *ngIf="libelle.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>



          <div class="form-group" *ngIf="!creation">

            <!-- <input type="text" id="departement" formControlName="departement"
              [ngClass]="{'is-invalid': form.submitted && departement.invalid}" class="form-control" />

            <ng-container *ngIf="form.submitted">
              <div *ngIf="departement.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container> -->
            <div class="container">
            <div class="row justify-content-md-center" >


              <div class="col col-lg-5" cdkDropList id="depts-terroir" [cdkDropListConnectedTo]="" [cdkDropListEnterPredicate]="canDropCdc"
                (cdkDropListDropped)="moveDep($event)" [cdkDropListData]="departement.value">
            <label for="departement" translate>page.valeur_parametrable.form.label.departement_terroir</label>
                <div *ngFor="let dep of departement.value" cdkDrag class=" jury-liste-item" [cdkDragData]="dep">
                  <div class="listes-dad-placeholder" *cdkDragPlaceholder></div>
                  <fa-icon cdkDragHandle icon="bars" class="grab" style="font-size:0.8em;"></fa-icon>
                  {{dep.code}} - {{dep.nom}}
                  <span class="flex-expand"></span>
                  <fa-icon icon="trash" (click)="removeDep(index, dep)" class="pointer jury-liste-delete"></fa-icon>
                </div>
              </div>
              <div class="col-md-auto">
              </div>



              <div class="col col-lg-5" cdkDropList id="depts-libre" [cdkDropListConnectedTo]="" [cdkDropListEnterPredicate]="canDropCdc"
                (cdkDropListDropped)="moveDepLibre($event)" [cdkDropListData]="departements_libres.value">
                
            <label for="departement" translate>page.valeur_parametrable.form.label.departement_libre</label>
                <div *ngFor="let dep of departements_libres.value" cdkDrag class="jury-liste-item" [cdkDragData]="dep">
                  <div class="listes-dad-placeholder" *cdkDragPlaceholder></div>
                  <fa-icon cdkDragHandle icon="bars" class="grab" style="font-size:0.8em;"></fa-icon>
                  {{dep.code}} - {{dep.nom}}
                  <!-- <span class="flex-expand"></span>
            <fa-icon icon="trash" (click)="removeDep(index, dep)" class="pointer jury-liste-delete"></fa-icon> -->
                </div>
              </div>

            </div>
            </div>
          </div>
        </div>

        <div class="actions-bar" fxLayoutAlign="space-between">
          <button class="btn btn-primary" *ngIf="!creation" [routerLink]="'../..'" translate>label.retour</button>
          <button class="btn btn-primary" *ngIf="creation" [routerLink]="'../'" translate>label.retour</button>

          <button type="submit" class="btn btn-primary" translate>{{!creation
            ? 'label.suivant': 'label.valider'}}</button>
        </div>
      </form>
    </div>
  </div>
</div>