import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, find, filter } from 'lodash';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { Habilitation } from 'src/app/data/habilitation/models/habilitation.model';
import { RefStatut } from 'src/app/data/habilitation/models/statut.ref.model';
import { ReferencesService } from 'src/app/data/habilitation/services/references/references.service';
import { HabilitationsService } from 'src/app/data/habilitation/services/habilitations/habilitations.service';
import { RefStatutCode } from 'src/app/data/habilitation/models/enums/ref-statut-code.enum';
import { StatutHabilitation } from 'src/app/data/habilitation/models/statut-habilitation.model';
import { HabilitationStatutWorkflow } from 'src/app/data/habilitation/models/habilitation-statut-workflow';

@Component({
  selector: 'app-update-habilitation',
  templateUrl: './update-habilitation.component.html',
  styleUrls: ['./update-habilitation.component.scss']
})
export class UpdateHabilitationComponent implements OnInit {
  habilitation: Habilitation;
  private isCreation: boolean;
  idOperateur: number;

  showAllHistorique = false;

  allStatuts: RefStatut[] = ReferencesService.getReference('statutsHabilitation');
  statuts: RefStatut[] = [];
  private workflow = ReferencesService.getReference('statutsHabilitationWorkflow');

  formGroup: FormGroup;

  get statut() { return this.formGroup.get('statut'); }
  get dateReception() { return this.formGroup.get('dateReception'); }
  get dateDebut() { return this.formGroup.get('dateDebut'); }
  get commentaire() { return this.formGroup.get('commentaire'); }

  constructor(
    private readonly fb: FormBuilder,
    public readonly modal: NgbActiveModal,
    private readonly habilitationService: HabilitationsService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService
  ) { }

  ngOnInit() {
    let currentStatut: RefStatut;
    if (this.habilitation.statut != null) {
      this.statuts = filter(this.workflow, { idStatut: this.habilitation.statut.id })
        .map((item: HabilitationStatutWorkflow) => find(this.allStatuts, { id: item.idNextStatut }));
    } else {
      currentStatut = find(this.allStatuts, {
        code: RefStatutCode.EnCours
      });

      this.statuts = [currentStatut];
    }

    this.formGroup = this.fb.group({
      statut: [currentStatut, Validators.required],
      dateReception: [undefined, Validators.required],
      dateDebut: [undefined, Validators.required],
      commentaire: ''
    });
    this.statut.valueChanges.subscribe(statut => {
      this.dateReception.setValidators(statut === RefStatutCode.EnCours ? [Validators.required] : null);
      this.dateReception.updateValueAndValidity();
    });

    this.isCreation = this.habilitation.id === undefined;
  }

  isEncours() {
    return (this.statut.value && this.statut.value.code === RefStatutCode.EnCours);
  }

  submit() {
    if (this.formGroup.valid) {
      const newStatut = new StatutHabilitation();

      newStatut.statut = this.statut.value;
      if (this.isEncours() && this.dateReception.value != null) {
        newStatut.dateReception = moment([
          this.dateReception.value.year,
          this.dateReception.value.month - 1,
          this.dateReception.value.day
        ]);
      }
      if (this.dateDebut.value != null) {
        newStatut.dateDebut = moment([
          this.dateDebut.value.year,
          this.dateDebut.value.month - 1,
          this.dateDebut.value.day
        ]);
      }
      newStatut.commentaire = this.commentaire.value;

      if (this.isCreation) {
        const habilitation = cloneDeep(this.habilitation);

        habilitation.historique.push(newStatut);
        this.habilitationService.createHabilitation(this.idOperateur, habilitation).subscribe(newId => {
          this.translate.get('page.operateurs.habilitations.modal-update.alertes.creation-ok').subscribe(msg => {
            this.toastr.success('', msg);
          });
          this.modal.close(newId);
        });
      } else {
        this.habilitationService.changeStatut(this.idOperateur, this.habilitation.id, newStatut).subscribe(() => {
          this.translate.get('page.operateurs.habilitations.modal-update.alertes.modification-ok').subscribe(msg => {
            this.toastr.success('', msg);
          });
          this.modal.close();
        });
      }
    }
  }

  cancel() {
    this.modal.dismiss();
  }
}
