import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { IdCodeLibelle } from '../../id-code-libelle.abstract.model';

@JsonObject
export class RefSoustypeUtilisateur extends IdCodeLibelle {
  @JsonProperty('idType', Number)
  @Optional
  public idType: number;

  @JsonProperty('codeType', String)
  @Optional
  public codeType: string;

  @JsonProperty('libelleType', String)
  @Optional
  public libelleType: string;
}
