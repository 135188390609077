import { Injectable } from '@angular/core';

import 'reflect-metadata';
import * as moment from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { tap, share, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  static classesGuid: { constructor: any, guid: string }[] = [];

  static cache: { [key: string]: { value: any, expireAt: moment.Moment } } = {};
  static DEFAULT_MAX_AGE = 300;

  static clear(key: string) {
    // console.debug(`[CacheService] clear cache ${key}`);
    delete CacheService.cache[key];
  }

  static clearAll() {
    // console.debug(`[CacheService] clear all cache`);
    CacheService.cache = {};
  }

  static put(key: string, value: any, maxAge?: number) {
    // console.debug(`[CacheService] set cache ${key}`);
    CacheService.cache[key] = {
      value,
      expireAt: maxAge === -1 ? null : moment().add(maxAge || CacheService.DEFAULT_MAX_AGE, 'seconds')
    };
  }

  static get(key: string, defaultValue?: any): any {
    const cache = CacheService.cache[key];

    if (cache && (cache.expireAt === null || moment().isBefore(cache.expireAt))) {
      // console.debug(`[CacheService] get cache ${key}`);
      return cache.value;
    }

    // console.debug(`[CacheService] cache ${key} ` + (cache ? 'expired' : 'undefined'));
    return defaultValue;
  }
}

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + '-' + s4();
}



function storeCachesKey(target: any, key: string) {
  const metadataKey = `cache:keys`;
  const cacheKeys = Reflect.getMetadata(metadataKey, target) || [];
  cacheKeys.push(key);
  Reflect.defineMetadata(metadataKey, cacheKeys, target);
}

export function ClearCache(target: any, methodKey: string, descriptor: PropertyDescriptor) {

  const originalMethod = descriptor.value;
  descriptor.value = () => {
    const metadataKey = `cache:keys`;
    const cacheKeys: string[] = Reflect.getMetadata(metadataKey, target) || [];

    for (const key of cacheKeys) {
      CacheService.clear(key);
    }

    return originalMethod();
  };

  return descriptor;
}

function getMetadata(target: any, key: string, defaultValue: any): any {
  if (Reflect.hasMetadata(key, target)) {
    return Reflect.getMetadata(key, target);
  } else {
    return defaultValue;
  }
}

interface Options {
  key?: string;
  maxAge?: number;
}
export function Cache(options?: Options) {
  options = options || {};
  return (target: any, methodKey: string, descriptor: PropertyDescriptor) => {
    const cachedParameters: number[] = getMetadata(target, `cache:${methodKey}:parameters`, []);
    const noCacheArgIndex: number = getMetadata(target, `cache:${methodKey}:nocache`, null);

    const originalMethod = descriptor.value;
    descriptor.value = function() {
      const context = this;
      const args = arguments;

      let classGuid: string;
      try {
        classGuid = CacheService.classesGuid.find(cg => cg.constructor === target.constructor).guid;
      } catch (e) {
        classGuid = getMetadata(target, 'cache:classGuid', undefined);
        if (!classGuid) {
          // console.debug(`[CacheDecorator] generate new Guid for class ${target.constructor.name}`);
          classGuid = guid();
          Reflect.defineMetadata('cache:classGuid', classGuid, target);
          CacheService.classesGuid.push({ constructor: target.constructor, guid: classGuid });
        }
      }

      const cacheKey = (options.key ? [options.key] : [classGuid, target.constructor.name, methodKey])
        .concat(cachedParameters.map(i => args[i])).join(':');

      storeCachesKey(target, cacheKey);

      if (noCacheArgIndex !== null && args[noCacheArgIndex] === true) {
        // console.debug(`[CacheDecorator] called with 'noCache'`);
        CacheService.clear(cacheKey);
      }

      // console.debug(`[CacheDecorator] get cached value [${cacheKey}]`);
      const cache = CacheService.get(cacheKey);
      if (cache) {
        if (cache.value !== undefined) {
          // console.debug(`[CacheDecorator] return cached value [${cacheKey}]`);
          return of(cache.value);
        } else if (cache.observable) {
          // console.debug(`[CacheDecorator] return cached observable [${cacheKey}]`);
          return cache.observable;
        }
      }

      const observable = (originalMethod.apply(context, args) as Observable<any>).pipe(
        tap((data: any) => {
          // console.debug(`[CacheDecorator] cache value [${cacheKey}]`);
          CacheService.put(cacheKey, { value: data }, options.maxAge);
        }),
        catchError((error) => {
          CacheService.clear(cacheKey);
          return throwError(error);
        }),
        share() // avoid multiple subscriptions to make multiple calls
      );

      // console.debug(`[CacheDecorator] cache observable [${cacheKey}]`);
      CacheService.put(cacheKey, { observable });


      return observable;
    };

    return descriptor;
  };
}

export function CacheKey(target: any, methodKey: string, index: number) {
  const metadataKey = `cache:${methodKey}:parameters`;

  const cachedParameters = Reflect.getMetadata(metadataKey, target) || [];
  cachedParameters.push(index);
  Reflect.defineMetadata(metadataKey, cachedParameters, target);
}

export function NoCache(target: any, methodKey: string, index: number) {
  Reflect.defineMetadata(`cache:${methodKey}:nocache`, index, target);
}
