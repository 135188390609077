import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Campagne } from "../../models/campagne.model";
import { HttpClient } from "@angular/common/http";
import { ParametreCampagne } from "src/app/data/intervenant/models/parametre-campagne.model";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class RefCampagnesService {
  constructor(private readonly http: HttpClient) {}

  public getCampagneEnCours(): Observable<Campagne> {
    return this.http.get<Campagne>(
      `/api/declaration/private/campagnes/encours`
    );
  }

  public getCampagneLastThreeYears(): Observable<Campagne[]> {
    return this.http.get<Campagne[]>(
      `/api/declaration/private/campagnes/lastThreeYears`
    );
  }

  public getParametreCampagne(
    idCampagne: Number
  ): Observable<ParametreCampagne[]> {
    return this.http.get<ParametreCampagne[]>(
      `/api/declaration/private/campagnes/${idCampagne}/parametres-campagne`
    );
  }

  public getParametreCampagneByEntreprise(
    idCampagne: Number,
    idEntreprise: Number
  ): Observable<ParametreCampagne> {
    return this.http.get<ParametreCampagne>(
      `/api/declaration/private/campagnes/${idCampagne}/entreprise/${idEntreprise}/parametres-campagne`
    );
  }

  public save(
    parametreCampagneList: ParametreCampagne[],
    idCampagne: Number
  ): Observable<string> {
    return this.http.put<string>(
      `/api/declaration/private/campagnes/${idCampagne}/parametres-campagne`,
      parametreCampagneList
    );
  }

  public ouvrirCampagne(): Observable<void> {
    return this.http.post<void>(
      `/api/declaration/private/campagnes/ouvrir`,
      null
    );
  }

  public getCampagneAnnee(annee): Observable<Campagne> {
    return this.http.get<Campagne>(
      `/api/declaration/private/campagnes/annee?annee=${annee}`
    );
  }

  public getCampagneAll(): Observable<Array<Campagne>> {
    return this.http.get<[Campagne]>(`/api/declaration/private/campagnes`);
  }

  /**
   * récupération des campagnes anpp
   * @returns
   */
  getCampagneAnpp(): Observable<Array<Campagne>> {
    return this.http.get<[Campagne]>(`/api/declaration/private/campagnes`);
  }

  public getIdEntrepriseDroitStockFalse(
    idCampagne: Number
  ): Observable<Number[]> {
    return this.http.get<Number[]>(
      `/api/declaration/private/campagnes/${idCampagne}/no-droits-stocks`
    );
  }

  public getIdEntrepriseDroitEcoulementFalse(
    idCampagne: Number
  ): Observable<Number[]> {
    return this.http.get<Number[]>(
      `/api/declaration/private/campagnes/${idCampagne}/no-droits-ecoulements`
    );
  }

  public getIdEntrepriseDroitRecolteFalse(
    idCampagne: Number
  ): Observable<Number[]> {
    return this.http.get<Number[]>(
      `/api/declaration/private/campagnes/${idCampagne}/no-droits-recoltes`
    );
  }

  /**
   * Permet de récupérer la référence de campagne pour les engagements VER (fonctionne du 15/02/N au 15/02/N+1)
   * @returns
   */
  public getCampagneVerEnCours(): Observable<Campagne> {
    return this.http.get<Campagne>(
      `/api/habilitation/private/campagnes/ver/encours`
    );
  }

  /**
   * Permet de récupérer la référence de campagne précédente pour les engagements VER
   * @returns
   */
  public getCampagneVerPrecedente(): Observable<Campagne> {
    return this.http.get<Campagne>(
      `/api/habilitation/private/campagnes/ver/precedente`
    );
  }

  /**
   * Permet de récupérer les références de campagne pour les engagements VER (avec la notion début/fin aux 15/02)
   * @returns
   */
  public getCampagneVerAll(): Observable<Array<Campagne>> {
    return this.http.get<[Campagne]>(`/api/habilitation/private/campagnes/ver`);
  }

  public getCampagneVerAnnee(annee): Observable<Campagne> {
    return this.http.get<Campagne>(
      `/api/habilitation/private/campagnes/ver/annee?annee=${annee}`
    );
  }

  public getCampagneAnppByDateDebutCampagneVer(
    debutVer: moment.Moment
  ): Observable<Campagne> {
    return this.http.get<Campagne>(
      `/api/declaration/private/campagnes/debutver?debutVer=${debutVer}`
    );
  }
}
