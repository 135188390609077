import {JsonObject, JsonProperty, Optional} from '@thorolf/json-ts-mapper';
import {CodeEspece} from './enums/code-espece.enum';

@JsonObject
export class Stock {

  @JsonProperty('idGroupeVarietal', Number)
  idGroupeVarietal: number = null;

  @JsonProperty('stockTotalMoisCourant', Number)
  @Optional
  stockTotalMoisCourant: number = null;

  @JsonProperty('stockBioMoisCourant', Number)
  @Optional
  stockBioMoisCourant: number = null;

  @JsonProperty('stockTotalCampPrec', Number)
  @Optional
  stockTotalCampPrec: number = null;

  @JsonProperty('stockBioCampPrec', Number)
  @Optional
  stockBioCampPrec: number = null;

  @JsonProperty('stockTotalMoisPrec', Number)
  @Optional
  stockTotalMoisPrec: number = null;

  @JsonProperty('stockBioMoisPrec', Number)
  @Optional
  stockBioMoisPrec: number = null;

  @JsonProperty('stockTotalRecolte', Number)
  @Optional
  stockTotalRecolte: number = null;

  @JsonProperty('stockBioRecolte', Number)
  @Optional
  stockBioRecolte: number = null;

  @JsonProperty('stockTotalMoisSuiv', Number)
  @Optional
  stockTotalMoisSuiv: number = null;
  @JsonProperty('stockBioMoisSuiv', Number)
  @Optional
  stockBioMoisSuiv: number = null;

  @JsonProperty('idEspece', Number)
  @Optional
  idEspece: number = null;
  @JsonProperty('codeEspece', String)
  codeEspece: string = null;

  @JsonProperty('codeGroupeVarietal', String)
  codeGroupeVarietal: string;
  @JsonProperty('groupeVarietal', String)
  groupeVarietal: string = null;

  isAutre(): boolean {
    return ['autVarpomme', 'autVarpoir', 'autVarpoire'].indexOf(this.codeGroupeVarietal) > -1;
  }

  isTotal: boolean = false;

  isDernierPommePoire : boolean = false;

  sorting(): string {
    const x =
      ''.concat(
        this.codeEspece === CodeEspece.POMME ? '0' : '1',
        this.isTotal ? '1' : '0',
        this.isAutre() ? '1' : '0',
        this.groupeVarietal.toUpperCase());
    return x;
  }
}
