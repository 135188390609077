import { JsonProperty, JsonObject, Optional, CustomConverter } from '@thorolf/json-ts-mapper';
import { JureJury } from './jure-jury.model';
import { Cahier } from '../../habilitation/models/cahier.model';
import { EchantillonJury } from './echantillon-jury.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';

@JsonObject
export class Jury {
  @JsonProperty('id', Number)
  @Optional
  id: number;

  @JsonProperty('ordre', Number)
  ordre: number;

  @JsonProperty('jures', [JureJury])
  jures: JureJury[];

  @JsonProperty('idCahiers', [Number])
  @CustomConverter(DataIdConverterFactory.build(Cahier, 'cahiers'))
  cdcs: Cahier[];

  @JsonProperty('echantillons', [EchantillonJury])
  echantillons: EchantillonJury[];
}
