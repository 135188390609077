import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { remove } from 'lodash';
import { forkJoin } from 'rxjs';
import { Contact } from 'src/app/data/intervenant/models/contact.model';
import { Referent } from 'src/app/data/intervenant/models/referent.model';
import { ContactsService } from 'src/app/data/intervenant/services/contacts/contacts.service';

@Component({
  selector: 'app-liste-contact-entreprise',
  templateUrl: './liste-contact-entreprise.component.html',
  styleUrls: ['./liste-contact-entreprise.component.scss']
})
export class ListeContactEntrepriseComponent implements OnInit {

  @Input() id: number;

  contacts: Contact[];
  referents: Referent[];

  constructor(
    private readonly contactService: ContactsService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly translate: TranslateService
  ) { }

  ngOnInit() {
    forkJoin(
      this.contactService.getContactsByEntreprise(this.id),
      this.contactService.getReferents()
    ).pipe(this.loaderService.operator()).
      subscribe(([contacts, referents]) => {
        this.contacts = contacts;
        this.referents = referents;
      })
  }

  editContact(contact: Contact) {    
    this.router.navigate(['contacts','edit', contact.id], { relativeTo: this.route });
  }

  deleteContact(contact: Contact) {
    this.contactService.supprimerContact(contact.id).subscribe(() => {
      remove(this.contacts, contact);
    });
  }

  openConfirmationDialog(contact: Contact) { 

    this.modalsService.confirm(
      'Veuillez confirmer',
      'Voulez vous supprimer le contact '+ contact.prenom + ' ' + contact.nom + ' ?', {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`)
    }
    ).then(() => {
      this.deleteContact(contact);
    }, () => { });

  }

}
