import { Injectable } from '@angular/core';
import { CahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import { ReferencesFacturationService } from '../references-facturation/references-facturation.service';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { ObjetFacture } from '../../models/objet-facture.model';
import { map, tap, flatMap } from 'rxjs/operators';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { ReferencesFacturation } from '../../models/references-facturation.model';
import { PaginateResponseStream } from 'src/app/shared/services/pagination/pagination.service';
import { DetailsObjetFacture } from '../../models/details-objet-facture.model';

@Injectable({
  providedIn: 'root'
})
export class ObjetFactureService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly refFacturationService: ReferencesFacturationService,
    private readonly cahiersService: CahiersService,
    private readonly operateursService: OperateursService
  ) { }


  getObjetsFacture(idOrganisme: number, idModeleFacturation: number, annee: number, estFacture: string): Observable<ObjetFacture[]> {
    const params = new HttpParams()
      .set('estFacture', estFacture);
    return forkJoin(
      this.http.get<object[]>(`/api/facturation/private/organismes/${idOrganisme}/modelesfacturation/${idModeleFacturation}/objetsfacture/${annee}`, { params }),
      this.cahiersService.getCahiers(),
      this.refFacturationService.getReferences()
    ).pipe(
      map(([objetsFacture, cahiers, ref]) => this.mapper.deserializeArray(objetsFacture,
        ObjetFacture, Object.assign({ cahiers }, ref)))
    );
  }

  getDetailsObjetFacture(idOrganisme: number, idsToSend: number[]): Observable<DetailsObjetFacture[]> {
    return this.http.post<object[]>(`/api/facturation/private/organismes/${idOrganisme}/detailsFactures`, idsToSend)
      .pipe(
        map(detailsObjetFacture => this.mapper.deserializeArray(detailsObjetFacture, DetailsObjetFacture))
      );
  }
}
