import {JsonObject, JsonProperty, Optional} from '@thorolf/json-ts-mapper';
import {CaracteristiqueParcelle} from './caracteristique-parcelle.model';
import { UniteCulturaleVigne } from './unite-culturale-vigne.model';


@JsonObject
export class UniteCulturalePost {
  @JsonProperty('id', Number)
  @Optional
  public id: number = undefined;

  @JsonProperty('localisation', Number)
  @Optional
  public localisation: number = undefined;

  @JsonProperty('caracteristiqueParcelle', CaracteristiqueParcelle)
  @Optional
  public caracteristiqueParcelle: CaracteristiqueParcelle = undefined;

  @JsonProperty('uniteCulturaleVigne', UniteCulturaleVigne)
  @Optional
  public uniteCulturaleVigne: UniteCulturaleVigne = undefined;

  @JsonProperty('numBlocFruitier', String)
  @Optional
  public numBlocFruitier: string = undefined;

  @JsonProperty('numTracabilite', String)
  @Optional
  public numTracabilite: string = undefined;

  @JsonProperty('codeParcelle', String)
  @Optional
  public codeParcelle: string = undefined;

  @JsonProperty('espece', Number)
  @Optional
  public espece: number = undefined;

  @JsonProperty('variete', Number)
  @Optional
  public variete: number = undefined;

  @JsonProperty('anneePlantation', Number)
  anneePlantation: number;

  @JsonProperty('anneePremiereFeuille', Number)
  @Optional
  anneePremiereFeuille: number;

  @JsonProperty('anneeSurgreffage', Number)
  @Optional
  anneeSurgreffage: number;

  @JsonProperty('distanceIntraRang', Number)
  distanceIntraRang: number;

  @JsonProperty('distanceInterRang', Number)
  distanceInterRang: number;

  @JsonProperty('nbArbre', Number)
  @Optional
  nbArbre: number;

  @JsonProperty('confusion', Boolean)
  @Optional
  confusion: boolean;

  @JsonProperty('porteGreffe', Number)
  @Optional
  public porteGreffe: number = undefined;
}
