<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
      page.declarations.synthese.modal.recherche-produit.title
    </h4>
  </div>

  <div class="modal-body" *ngIf="loaded">
    <div *ngIf="produits.length; else aucunProduit">
    <div class="searchBar">
      <label for="searchBar-input" translate>label.filtrer</label>
      <input type="text" id="searchBar-input" (ngModelChange)="searchStringUpdate.next($event)" [(ngModel)]="searchStringInput"
        placeholder="{{'label.filtre'|translate}}">
    </div>
    <table class="table" >
      <thead>
        <tr>
          <th translate>page.declarations.synthese.modal.recherche-produit.table.produit</th>
          <th translate>page.declarations.synthese.modal.recherche-produit.table.cahier</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let produit of produits | filter : '*': searchString" >
          <td>{{produit.libelle}}</td>
          <td>{{produit.libelleCahier}}</td>
          <td><button class="btn btn-primary close-btn" (click)="close(produit)"><fa-icon icon="plus"></fa-icon><span translate>label.ajouter</span></button></td>
        </tr>
      </tbody>
    </table>
    </div>
    <div class="actions-bar">
        <button class="btn btn-light" (click)="dismiss()" translate>label.annuler</button>
      </div>
  </div>
</div>

<ng-template #aucunProduit>
  <div translate>
    page.declarations.synthese.modal.recherche-produit.aucun-produit
  </div>
</ng-template>
