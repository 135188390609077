<div class="content">
  <div class="content-panel">
    <h2 translate [translateParams]="{annee:annee}">page.declarations.dr.title</h2>
    <div id="liste-dr"
         *ngIf="(declarations?.length > 0 || declarationsSv11?.length > 0 || declarationsSv12?.length > 0); else aucunProduit">

      <!-- Tableau des déclarations -->
      <table table class="table table-striped" *ngIf="declarations.length > 0">
        <thead>
        <tr>
          <th colspan="2"></th>
          <th class="liste-col-header" translate>page.declarations.dr.table.header.l4</th>
          <th class="liste-col-header" translate>page.declarations.dr.table.header.l5</th>
          <th class="liste-col-header" translate>page.declarations.dr.table.header.l15</th>
          <th class="liste-col-header" translate>page.declarations.dr.table.header.l16</th>
          <th class="liste-col-header" translate>page.declarations.dr.table.header.l18</th>
          <th class="liste-col-header" translate>page.declarations.dr.table.header.l19</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let declaration of declarations">
          <tr>
            <td>
              <fa-icon *ngIf="declaration.details.length && !declaration.showDetails" style="font-size:1.3em;"
                       icon="plus" (click)="declaration.showDetails = true" class="pointer"></fa-icon>
              <fa-icon *ngIf="declaration.details.length && declaration.showDetails" style="font-size:1.3em;"
                       icon="minus" (click)="declaration.showDetails = false" class="pointer"></fa-icon>
            </td>
            <td>{{declaration.produit?.libelle}}<span *ngIf="declaration.mention"> - {{declaration.mention}}</span>
            </td>
            <td>{{declaration.l4 | number:'1.4-4' }}</td>
            <td>{{declaration.l5 | number:'1.2-2' }}</td>
            <td>{{declaration.l15 | number:'1.2-2' }}</td>
            <td>{{declaration.l16 | number:'1.2-2' }}</td>
            <td>{{declaration.l18 | number:'1.2-2' }}</td>
            <td>{{declaration.l19 | number:'1.2-2' }}</td>
            <td class="align-center">
              <button *ngIf="declaration.erreurs && declaration.erreurs.length; else declarationOk" type="button"
                      class="btn btn-danger" translate>
                <fa-icon style="margin-right:0.7em;" icon="bell"></fa-icon>
                page.declarations.dr.table.modifier_dr
              </button>
              <ng-template #declarationOk>
                  <span class="btn btn-success pointer-none">
                    <fa-icon style="font-size:1.2em;" icon="check"></fa-icon>
                  </span>
              </ng-template>
            </td>
          </tr>
          <ng-container *ngIf="declaration.showDetails">
            <ng-container *ngFor="let detail of declaration.details">
              <tr>
                <td style="background-color: #ccc"></td>
                <td>{{declaration.produit?.libelle}} - {{declaration.mention}}</td>
                <td>{{detail.l4 | number:'1.4-4' }}</td>
                <td>{{detail.l5 | number:'1.2-2' }}</td>
                <td>{{detail.l15 | number:'1.2-2' }}</td>
                <td>{{detail.l16 | number:'1.2-2' }}</td>
                <td>{{detail.l18 | number:'1.2-2' }}</td>
                <td>{{detail.l19 | number:'1.2-2' }}</td>
                <td>
                  <ng-container *ngIf="declaration.erreurs.length > 0">
                    <!-- Afficher toutes les erreurs de la liste -->
                    <span *ngFor="let erreur of declaration.erreurs; let last = last">
                      {{ erreur }}<span *ngIf="!last">, </span>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="!declaration.erreurs.length">
                    <!-- Ici, vous pourriez afficher quelque chose si aucune erreur n'est présente ou simplement rien -->
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
        </tbody>
      </table>
      <!-- Fin tableau des déclarations -->

      <!-- Tableau des déclarations SV11 -->
      <div *ngIf="declarationsSv11?.length > 0">
        <h2 translate>page.declarations.dr.table_sv11.title</h2>
        <table table class="table table-striped">
          <thead>
          <tr>
            <th colspan="2"></th>
            <th class="liste-col-header" translate>page.declarations.dr.table_sv11.header.superficie</th>
            <th class="liste-col-header" translate>page.declarations.dr.table_sv11.header.volume_apporte</th>
            <th class="liste-col-header" translate>page.declarations.dr.table_sv11.header.volume_cree</th>
            <th class="liste-col-header" translate>page.declarations.dr.table_sv11.header.volume_distillation</th>
            <th class="liste-col-header" translate>page.declarations.dr.table_sv11.header.vsi</th>
            <th class="liste-col-header" translate>page.declarations.dr.table_sv11.header.vci</th>
            <th></th>
          </tr>
          </thead>

          <tbody>
          <ng-container *ngFor="let declarationSv11 of declarationsSv11">
            <tr>
              <td>
                <fa-icon *ngIf="declarationSv11.details.length && !declarationSv11.showDetails"
                         style="font-size:1.3em;" icon="plus" (click)="declarationSv11.showDetails = true"
                         class="pointer">
                </fa-icon>
                <fa-icon *ngIf="declarationSv11.details.length && declarationSv11.showDetails"
                         style="font-size:1.3em;" icon="minus" (click)="declarationSv11.showDetails = false"
                         class="pointer">
                </fa-icon>
              </td>
              <td>{{ declarationSv11.produit?.libelle }}<span *ngIf="declarationSv11.mention"> -
                {{ declarationSv11.mention }}</span></td>
              <td>{{ declarationSv11.sup_rec | number:'1.4-4' }}</td>
              <td>{{ declarationSv11.vol_app | number:'1.2-2' }}</td>
              <td>{{ declarationSv11.vol_cree | number:'1.2-2' }}</td>
              <td>{{ declarationSv11.vol_dplc | number:'1.2-2' }}</td>
              <td>{{ declarationSv11.vol_vsi | number:'1.2-2' }}</td>
              <td>{{ declarationSv11.vol_vci | number:'1.2-2' }}</td>
              <td></td>
            </tr>
            <ng-container *ngIf="declarationSv11.showDetails">
              <ng-container *ngFor="let detail of declarationSv11.details">
                <tr>
                  <td style="background-color: #ccc"></td>
                  <td>{{ declarationSv11.produit?.libelle }} - {{ declarationSv11.mention }}</td>
                  <td>{{ detail.sup_rec | number:'1.4-4' }}</td>
                  <td>{{ detail.vol_app | number:'1.2-2' }}</td>
                  <td>{{ detail.vol_cree | number:'1.2-2' }}</td>
                  <td>{{ detail.vol_dplc | number:'1.2-2' }}</td>
                  <td>{{ detail.vol_vsi | number:'1.2-2' }}</td>
                  <td>{{ detail.vol_vci | number:'1.2-2' }}</td>
                  <td>{{ detail.erreurs }}</td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>
          </tbody>
        </table>
      </div>
      <!-- Fin tableau des déclarations SV11 -->

      <!-- Tableau des déclarations SV12 -->
      <div *ngIf="declarationsSv12?.length > 0">
        <h2 translate>page.declarations.dr.table_sv12.title</h2>
        <table table class="table table-striped">
          <thead>
          <tr>
            <th colspan="2"></th>
            <th class="liste-col-header" translate>page.declarations.dr.table_sv12.header.superficie</th>
            <th class="liste-col-header" translate>page.declarations.dr.table_sv12.header.quantite_achat</th>
            <th class="liste-col-header" translate>page.declarations.dr.table_sv12.header.volume_achat</th>
            <th class="liste-col-header" translate>page.declarations.dr.table_sv12.header.volume_vf</th>
            <th class="liste-col-header" translate>page.declarations.dr.table_sv12.header.volume_mouts</th>
            <th></th>
          </tr>
          </thead>

          <tbody>
          <ng-container *ngFor="let declarationSv12 of declarationsSv12">
            <tr>
              <td>
                <fa-icon *ngIf="declarationSv12.details.length && !declarationSv12.showDetails"
                         style="font-size:1.3em;" icon="plus" (click)="declarationSv12.showDetails = true"
                         class="pointer">
                </fa-icon>
                <fa-icon *ngIf="declarationSv12.details.length && declarationSv12.showDetails"
                         style="font-size:1.3em;" icon="minus" (click)="declarationSv12.showDetails = false"
                         class="pointer">
                </fa-icon>
              </td>
              <td>{{ declarationSv12.produit?.libelle }}<span *ngIf="declarationSv12.mention"> -
                {{ declarationSv12.mention }}</span></td>
              <td>{{ declarationSv12.s_recolte | number:'1.4-4' }}</td>
              <td>{{ declarationSv12.qte_achat_vf | number:'1.2-2' }}</td>
              <td>{{ declarationSv12.vol_achat_m | number:'1.2-2' }}</td>
              <td>{{ declarationSv12.vol_issu_vf | number:'1.2-2' }}</td>
              <td>{{ declarationSv12.vol_issu_m | number:'1.2-2' }}</td>
              <td></td>
            </tr>
            <ng-container *ngIf="declarationSv12.showDetails">
              <ng-container *ngFor="let detail of declarationSv12.details">
                <tr>
                  <td style="background-color: #ccc"></td>
                  <td>{{ declarationSv12.produit?.libelle }} - {{ declarationSv12.mention }}</td>
                  <td>{{ detail.s_recolte | number:'1.4-4' }}</td>
                  <td>{{ detail.qte_achat_vf | number:'1.2-2' }}</td>
                  <td>{{ detail.vol_achat_m | number:'1.2-2' }}</td>
                  <td>{{ detail.vol_issu_vf | number:'1.2-2' }}</td>
                  <td>{{ detail.vol_issu_m | number:'1.2-2' }}</td>
                  <td>{{ detail.erreurs }}</td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>
          </tbody>
        </table>
      </div>
      <!-- Fin tableau des déclarations SV12 -->
    </div>
    <div class="actions-bar"
         *ngIf="(declarations && declarations.length) || (declarationsSv11 && declarationsSv11.length) || (declarationsSv12 && declarationsSv12.length)">
      <button type="button" [routerLink]="['../drev']" class="btn btn-primary" [disabled]="hasErrors"
              translate>label.suivant
      </button>
    </div>
  </div>
</div>

<ng-template #aucunProduit>
  <div class="alert alert-warning alert-block" translate [translateParams]="{annee:annee}">
    <fa-icon icon="exclamation"></fa-icon>
    page.declarations.dr.table.aucunProduit
  </div>
</ng-template>
