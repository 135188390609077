import { Directive, Renderer2, Input, TemplateRef, ViewContainerRef, ɵstringify as stringify } from '@angular/core';

import { AuthService } from 'src/app/core/services/auth/auth.service';

const selector = '[hasRole]';
@Directive({
  selector
})
export class HasRoleDirective {

  private isVisible: boolean;
  private elseAction = 'hide';
  private elseTemplateRef: TemplateRef<any>;

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly renderer: Renderer2,
    private readonly authService: AuthService) {
  }

  @Input()
  set hasRole(roles: string | string[]) {
    this.authService.getRoles().subscribe(
      () => {
        this.isVisible = this.authService.hasRole(roles);
        this.updateView();
      }
    );
  }

  @Input()
  set hasRoleElseDisable(voidd: void) {
    this.elseAction = 'disabled';
    this.updateView();
  }

  @Input()
  set hasRoleElse(templateRef: TemplateRef<any>) {
    assertTemplate('ngIfElse', templateRef);
    this.elseAction = 'else';
    this.elseTemplateRef = templateRef;
    this.updateView();
  }

  private updateView() {
    this.viewContainerRef.clear();
    if (this.isVisible) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else if (this.elseAction === 'disabled') {
      const view = this.viewContainerRef.createEmbeddedView(this.templateRef);
      const rootElem = view.rootNodes[0];
      if (rootElem) {
        rootElem.disabled = true;
        this.renderer.addClass(rootElem, 'disabled'); // disable element
        rootElem.style.pointerEvents = 'none'; // disable standar elements
        this.renderer.setProperty(rootElem, 'disabled', true); // add class
      }
    } else if (this.elseAction === 'else') {
      this.viewContainerRef.createEmbeddedView(this.elseTemplateRef);
    }
  }


}

export class HasRoleContext {
  public $implicit: any = null;
  public event: any = null;
}

function assertTemplate(property: string, templateRef: TemplateRef<any> | null): void {
  const isTemplateRefOrNull = !!(!templateRef || templateRef.createEmbeddedView);
  if (!isTemplateRefOrNull) {
    throw new Error(`${property} must be a TemplateRef, but received '${stringify(templateRef)}'.`);
  }
}
