import { JsonProperty, JsonObject, Optional, CustomConverter } from '@thorolf/json-ts-mapper';

import { RefAnneeType } from './ref-annee-type.model';
import { RefModeCalcul } from './ref-mode-calcul.model';
import { RefTva } from './ref-tva.model';
import { RefObjetFacture } from './ref-objet-facture.model';
import { RefQuantiteFacture } from './ref-quantite-facture.model';

@JsonObject
export class ReferencesFacturation {
    @JsonProperty('refAnneeTypeList', [RefAnneeType])
    refAnneeTypeList: RefAnneeType[];

    @JsonProperty('refModeCalculList', [RefModeCalcul])
    refModeCalculList: RefModeCalcul[];

    @Optional
    @JsonProperty('refObjetFactureList', [RefObjetFacture])
    refObjetFactureList: RefObjetFacture[];

    @JsonProperty('refTvaList', [RefTva])
    refTvaList: RefTva[];

    @Optional
    @JsonProperty('refQuantiteFactureList', [RefQuantiteFacture])
    refQuantiteFactureList: RefQuantiteFacture[];

}
