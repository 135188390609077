import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Echantillon } from '../../models/echantillon.model';
import * as moment from 'moment';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { ReferentielService } from '../referentiel/referentiel.service';
import { StatutEchantillon } from '../../models/statut-echantillon.model';
import { DateConverter, DateTimeConverter } from 'src/app/core/services/mapper/converters';
import { PaginationService, PaginateResponseStream } from 'src/app/shared/services/pagination/pagination.service';
import { AnimateursService } from 'src/app/data/commission/services/animateurs/animateurs.service';
import { Fichier } from 'src/app/data/edition/models/fichier.model';
import { Etiquette } from '../../models/etiquette.model';

interface ObjectWithStatut { codeStatut: string; statut: StatutEchantillon; }

@Injectable({
  providedIn: 'root'
})
export class EchantillonsService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly operateursService: OperateursService,
    private readonly referentielService: ReferentielService,
    private readonly paginationService: PaginationService,
    private readonly animateursService: AnimateursService
  ) { }

  /**
   * Recherche une collection d'échantillons selon des critères de recherche
   * @param idOrganisme L'ID organisme des échantillons
   * @param statuts La liste des statuts des échantillons
   * @param agentsPrevus La liste des agents prévus des échantillons
   * @param debutDateButoir La date de début d'intervalle de date butoir
   * @param finDateButoir La date de fin d'intervalle de date butoir
   * @param debutDatePrelevement La date de but d'intervalle de date de prélèvement
   * @param finDatePrelevement La date de fin d'intervalle de date de prélèvement
   * @param debutDateCommission La date de début d'intervalle de date de commission
   * @param finDateCommission La date de fin d'intervalle de date de commission
   * @see Echantillon
   */
  getFilteredEchantillons(
    idOrganisme: number,
    statuts: string[],
    agentsPrevus: string[],
    debutDateButoir: moment.Moment,
    finDateButoir: moment.Moment,
    debutDatePrelevement: moment.Moment,
    finDatePrelevement: moment.Moment,
    debutDateCommission: moment.Moment,
    finDateCommission: moment.Moment
  ): Observable<PaginateResponseStream<Echantillon[]>> {
    const dateConverter = new DateConverter();
    let params = new HttpParams();

    params = (statuts.length > 0) ? params.set('codeStatutList', statuts.join(',')) : params;
    params = (agentsPrevus.length > 0) ? params.set('idAgentList', agentsPrevus.join(',')) : params;
    params = (debutDateButoir) ? params.set('dateButoirMin', dateConverter.serialize(debutDateButoir)) : params;
    params = (finDateButoir) ? params.set('dateButoirMax', dateConverter.serialize(finDateButoir)) : params;
    params = (debutDatePrelevement) ? params.set('datePrelevementMin', dateConverter.serialize(debutDatePrelevement)) : params;
    params = (finDatePrelevement) ? params.set('datePrelevementMax', dateConverter.serialize(finDatePrelevement)) : params;
    params = (debutDateCommission) ? params.set('dateCommissionMin', dateConverter.serialize(debutDateCommission)) : params;
    params = (finDateCommission) ? params.set('dateCommissionMax', dateConverter.serialize(finDateCommission)) : params;

    return this.paginationService.paginateGetAsStream<object[]>(
      `/api/declaration/private/organismes/${idOrganisme}/recherche-echantillons`,
      undefined,
      undefined,
      { params }
    ).pipe(
      map((result: PaginateResponseStream<object[]>) => {
        result.response = this.mapper.deserializeArray(result.response, Echantillon);
        return result as PaginateResponseStream<Echantillon[]>;
      }),
      tap(result => {
        of(result.response).pipe(
          this.operateursService.getCviPipe(),
          this.operateursService.getOperateurPipe(),
          this.getStatutEchantillonPipe(),
          this.animateursService.getAnimateurPipe(idOrganisme)
        ).subscribe(() => { });
      })
    );
  }

  /** Retourne le statut de l'échantillon depuis le référentiel */
  getStatutEchantillonPipe<T extends ObjectWithStatut | ObjectWithStatut[]>() {
    return tap((list: T) => {
      this.referentielService.getReferentiel().subscribe(referentiel => {
        const liste: ObjectWithStatut[] = [].concat(list);

        liste.forEach(item =>
          item.statut = referentiel.echantillonStatuts.find(_ => _.code === item.codeStatut)
        );
      });
    });
  }

  public countEchantillonsEnCoursEtAPrelever(): Observable<number[]> {
    return this.http.get<number[]>(`/api/declaration/private/echantillons/count`);
  }

  public getEtiquette(idMouvements:number[]): Observable<Etiquette[]> {
    return this.http.post<Etiquette[]>(`/api/declaration/private/etiquette`,idMouvements);
  }

  etiquettesPdf(idOrganisme: number, etiquettes: Etiquette[]): any {
    return this.http.post<Fichier[]>(`/api/edition/private/organismes/${idOrganisme}/generer/etiquette-igp`, etiquettes);
  }

}
