import { JsonProperty, JsonObject, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class ControleEchantillonEdition {

    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('numero', String)
    @Optional
    numero: string;

    @JsonProperty('cahier', String)
    @Optional
    cahier: string;

    @JsonProperty('numeroPassage', Number)
    @Optional
    numeroPassage: number;

    @JsonProperty('millesime', String)
    @Optional
    millesime: string;

    @JsonProperty('produit', String)
    @Optional
    produit: string;

    @JsonProperty('numeroLot', String)
    @Optional
    numeroLot: string;

    @JsonProperty('cepage', String)
    @Optional
    cepage: string;

    @JsonProperty('volume', String)
    @Optional
    volume: string;

    @JsonProperty('type', String)
    @Optional
    type: string;

    @JsonProperty('logement', String)
    @Optional
    logement: string;

    @JsonProperty('contenant', String)
    @Optional
    contenant: string;
    
    @JsonProperty('typeConditionnement', String)
    @Optional
    typeConditionnement: string;
    
    @JsonProperty('degre', String)
    @Optional
    degre: string;

    @JsonProperty('numeroDossier', String)
    @Optional
    numeroDossier: string;

    @JsonProperty('date', String)
    @Optional
    date: string;

}
