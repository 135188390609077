import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators, NgModel } from '@angular/forms';
import { ReferencesFacturationService } from 'src/app/data/facturation/services/references-facturation/references-facturation.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ModeleFacturationService } from 'src/app/data/facturation/services/modele-facturation/modele-facturation.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { ModeleFacturationLight } from 'src/app/data/facturation/models/modele-facturation-light.model';
import { ObjetFacture } from 'src/app/data/facturation/models/objet-facture.model';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DonneesFacturationComponent } from '../../modals/donnees-facturation/donnees-facturation.component';
import { ObjetFactureService } from 'src/app/data/facturation/services/objet-facture/objet-facture.service';
import * as _ from 'lodash';
import { ObjetFactureTriNormal } from 'src/app/data/facturation/models/objet-facture-tri-normal.model';
import { from, forkJoin } from 'rxjs';
import { groupBy } from 'lodash';
import { mergeMap } from 'rxjs/operators';
import { QuantiteInfos } from 'src/app/data/facturation/models/quantite-infos.models';
import { ObjetFactureTriOperateur } from 'src/app/data/facturation/models/objet-facture-tri-operateur.model';
import { ReferenceQuantiteEnum } from 'src/app/data/facturation/models/enums/references-quantite.enum';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { FacturationService } from 'src/app/data/facturation/services/facturation/facturation.service';

@Component({
  selector: 'app-generer-factures',
  templateUrl: './generer-factures.component.html',
  styleUrls: ['./generer-factures.component.scss']
})

export class GenererFacturesComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  @ViewChild('valChbx') valChbx: NgModel;
  formGroup: FormGroup;
  modelesFacturation: ModeleFacturationLight[];
  objetsFacture: ObjetFacture[] = [];
  objetsFactureTriNormal: ObjetFactureTriNormal[];
  objetsFactureTriOperateur: ObjetFactureTriOperateur[];
  modeRecherche: boolean;
  currentModeleFacturation: ModeleFacturationLight;
  loaded = false;
  submitted = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly modalService: NgbModal,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modeleFacturationService: ModeleFacturationService,
    private readonly objetFactureService: ObjetFactureService,
    private readonly operateursService: OperateursService,
    private readonly facturationService: FacturationService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      modeleFacturation: [undefined, Validators.required],
      annee: [undefined, [Validators.required, Validators.pattern(/^\d{4}$/)]],
      modeRecherche: [false, Validators.required]
    });

    setTimeout(() => { this.loadData(); });
  }

  loadData() {
    this.modeleFacturationService.getModelesFacturationLight(SessionContext.get('idOrganisme'))
      .pipe(
        this.loaderService.operator()
      ).subscribe(modelesFacturation => {
        this.modelesFacturation = modelesFacturation;
      });
  }

  submit() {
    this.objetsFactureTriNormal = [];
    this.objetsFactureTriOperateur = [];
    this.modeRecherche = this.formGroup.get('modeRecherche').value;
  
    if (this.formGroup.valid) {
      this.submitted = true;
      this.modeRecherche = this.formGroup.get('modeRecherche').value;
      const index: number = this.modelesFacturation.indexOf(this.formGroup.get('modeleFacturation').value);
      this.currentModeleFacturation = this.modelesFacturation[index];

      this.objetFactureService.getObjetsFacture(
        SessionContext.get('idOrganisme'),
        this.currentModeleFacturation.id,
        this.formGroup.get('annee').value,
        String(this.modeRecherche)
      )
        .pipe(
          this.loaderService.operator()
        ).subscribe(objetsFacture => {
          this.objetsFacture = objetsFacture;

          this.objetsFacture.forEach(obj => {
            if (!this.currentModeleFacturation.isGroupeOperateur) {
              const existingIndexTriNormal: number = this.objetsFactureTriNormal.length > 0 ? this.objetsFactureTriNormal.findIndex(t => t.idObjet === obj.idObjet) : -1;

              if (existingIndexTriNormal != -1) {
                this.objetsFactureTriNormal[existingIndexTriNormal].quantiteInfos.push(new QuantiteInfos(obj.quantite, obj.refQuantiteFacture));
                this.objetsFactureTriNormal[existingIndexTriNormal].idsToSend.push(obj.id);
              } else {
                const objF = new ObjetFactureTriNormal();
                objF.idsToSend = objF.idsToSend ? objF.idsToSend : [];
                objF.idsToSend.push(obj.id);
                objF.id = obj.id;
                objF.idObjet = obj.idObjet;
                objF.raisonSocialeOperateur = obj.raisonSocialeOperateur != undefined ? obj.raisonSocialeOperateur : 'Non renseigné';
                objF.cahier = obj.cahier;
                objF.annee = obj.annee;
                objF.fonctionnelId = obj.fonctionnelId;
                objF.estFacture = obj.estFacture;
                objF.quantiteInfos = objF.quantiteInfos ? objF.quantiteInfos : [];
                objF.quantiteInfos.push(new QuantiteInfos(obj.quantite, obj.refQuantiteFacture));
                this.objetsFactureTriNormal.push(objF);
              }
            } else {
              const existingIndexTriOperateur: number = this.objetsFactureTriOperateur.length > 0 ? this.objetsFactureTriOperateur.findIndex(t => t.idOperateur === obj.idOperateur) : -1;

              if (existingIndexTriOperateur != -1) {
                this.objetsFactureTriOperateur[existingIndexTriOperateur].quantiteInfos.push(new QuantiteInfos(obj.quantite, obj.refQuantiteFacture));
                if(this.objetsFactureTriOperateur[existingIndexTriOperateur].fonctionnelsId.indexOf(obj.fonctionnelId) == -1){
                this.objetsFactureTriOperateur[existingIndexTriOperateur].fonctionnelsId.push(obj.fonctionnelId);}
                this.objetsFactureTriOperateur[existingIndexTriOperateur].idsToSend.push(obj.id);
                this.objetsFactureTriOperateur[existingIndexTriOperateur].idsObjet.push(obj.idObjet);
              } else {
                const objF = new ObjetFactureTriOperateur();
                objF.id = obj.id;
                objF.idsToSend = objF.idsToSend ? objF.idsToSend : [];
                objF.idsToSend.push(obj.id);
                objF.idsObjet = objF.idsObjet ? objF.idsObjet : [];
                objF.idsObjet.push(obj.idObjet);
                objF.idOperateur = obj.idOperateur;
                objF.raisonSocialeOperateur = obj.raisonSocialeOperateur != undefined ? obj.raisonSocialeOperateur : 'Non renseigné';
                objF.cahier = obj.cahier;
                objF.annee = obj.annee;
                objF.fonctionnelsId = objF.fonctionnelsId ? objF.fonctionnelsId : [];
                objF.fonctionnelsId.push(obj.fonctionnelId);
                objF.estFacture = obj.estFacture;
                objF.quantiteInfos = objF.quantiteInfos ? objF.quantiteInfos : [];
                objF.quantiteInfos.push(new QuantiteInfos(obj.quantite, obj.refQuantiteFacture));
                this.objetsFactureTriOperateur.push(objF);
              }
            }

          });

          if (this.objetsFactureTriOperateur) {
            this.getQuantitesTriOperateur();
          }
          this.loaded = true;
        }
        );
    } else {
    }
  }

  private getQuantitesTriOperateur(): ObjetFactureTriOperateur[] {
    this.objetsFactureTriOperateur.forEach(triOperateur => {
      triOperateur.quantiteInfos.forEach(retourQteInfos => {

        const index = triOperateur.quantiteInfosOperateurGroupe ? triOperateur.quantiteInfosOperateurGroupe.findIndex(qio => qio.refQuantiteFacture.id == retourQteInfos.refQuantiteFacture.id) : -1;
        if (index != -1) {
          triOperateur.quantiteInfosOperateurGroupe[index].quantite += retourQteInfos.quantite;
        } else {
          triOperateur.quantiteInfosOperateurGroupe = triOperateur.quantiteInfosOperateurGroupe ? triOperateur.quantiteInfosOperateurGroupe : [];
          triOperateur.quantiteInfosOperateurGroupe.push(new QuantiteInfos(retourQteInfos.quantite, retourQteInfos.refQuantiteFacture, retourQteInfos.libelle));
        }
      });
    });
    return this.objetsFactureTriOperateur;
  }

  getDonneesFacturation(idsToSend: number[]) {
    const modalRef = this.modalService.open(DonneesFacturationComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.idsToSend = idsToSend;

    modalRef.result.then(() => {
      this.loadData();
    }, () => { });
  }

  facturer() {
    const ids: number[] = this.getIdsToFacture(this.currentModeleFacturation.isGroupeOperateur);
    this.facturationService.postFacturation(SessionContext.get('idOrganisme'), this.currentModeleFacturation.id, ids)
      .subscribe(reponse => {
        // this.router.navigate(['../facturation/generer-factures'], { relativeTo: this.route });
        // this.loadData();
        this.submit();
      });
  }

  private getIdsToFacture(isGroupeOperateur: boolean) {
    const ids: number[] = [];
    // let objs: any[] = !isGroupeOperateur ? this.objetsFactureTriNormal : this.objetsFactureTriOperateur;

    if (!isGroupeOperateur) {
      this.objetsFactureTriNormal.forEach(o => {
        if (o.checked) {
          ids.push(o.idObjet);
        }
      });
    } else {
      this.objetsFactureTriOperateur.forEach(o => {
        if (o.checked) {
          o.idsObjet.forEach(id => ids.push(id));
        }
      });
    }

    return ids;
  }
}
