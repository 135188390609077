export enum UtilisateurTypeCode {
  NONE = '',
  ADMIN = 'ADMIN',
  ORGANISME = 'ORGANISME',
  OPERATEUR = 'OPERATEUR',
  PI = 'PI',
  PO = 'PO',
  GP = 'GP',
  CT= 'CT',
  OC= 'OC'
}
