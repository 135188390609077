import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Commune} from '../../models/commune.model';
@Injectable({
  providedIn: 'root'
})
export class CommuneService {

  private url = 'https://geo.api.gouv.fr/communes?codePostal=';
  constructor(private readonly http: HttpClient) {
  }
  getCommunes(codePostal: string): Observable<Commune[]> {
    return this.http.get<Commune[]>(this.url + codePostal);
  }
}
