import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RefPorteGreffe} from '../../models/ref-porte-greffe.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RefPorteGreffeService {

  constructor( private readonly http: HttpClient) { }

  getRefPorteGreffe(): Observable<RefPorteGreffe[]> {
    return this.http.get<RefPorteGreffe[]>(`/api/declaration/private/referentielPorteGreffe`);
  }

  getRefPorteGreffeById(idRefPorteGreffe: number): Observable<RefPorteGreffe> {
    return this.http.get<RefPorteGreffe>(`/api/declaration/private/referentielPorteGreffe/${idRefPorteGreffe}`);
  }

  getRefPorteGreffeByIdEspece(idEspece: number): Observable<RefPorteGreffe> {
    return this.http.get<RefPorteGreffe>(`/api/declaration/private/especes/${idEspece}/referentielPorteGreffe`);
  }

  public creerRefPorteGreffe(portegreffe: RefPorteGreffe): Observable<number> {
    return this.http.post(`/api/declaration/private/referentielPorteGreffe`, portegreffe, { observe: 'response' }).pipe(
      map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
    );
  }  
  
  public modifierRefPorteGreffe(portegreffe: RefPorteGreffe ): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/referentielPorteGreffe/${portegreffe.id}`, portegreffe );
  }
  
  public desactiverRefPorteGreffe(portegreffe: RefPorteGreffe): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/referentielPorteGreffe/${portegreffe.id}?desactivation=${true}`, portegreffe );
  }
}
