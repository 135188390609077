import { JsonProperty, JsonObject, Optional, CustomConverter } from '@thorolf/json-ts-mapper';

import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { RefAnneeType } from './ref-annee-type.model';
import { RefObjetFacture } from './ref-objet-facture.model';
import { ModeleArticle } from './modele-article.model';

@JsonObject
export class ModeleFacturation {
    @JsonProperty('id', Number)
    @Optional
    id: number;

    @JsonProperty('libelle', String)
    libelle: string;

    @JsonProperty('idRefObjetFacture', Number)
    @CustomConverter(DataIdConverterFactory.build(RefObjetFacture, 'refObjetFactureList'))
    refObjetFacture: RefObjetFacture;

    @JsonProperty('idRefAnneeType', Number)
    @CustomConverter(DataIdConverterFactory.build(RefAnneeType, 'refAnneeTypeList'))
    refAnneeType: RefAnneeType;

    @JsonProperty('idOrganisme', Number)
    // @CustomConverter(DataIdConverterFactory.build(RefAnneeType, 'refAnneesTypes'))
    idOrganisme: number;

    @JsonProperty('idDomaine', Number)
    // @CustomConverter(DataIdConverterFactory.build(RefAnneeType, 'refAnneesTypes'))
    idDomaine: number;

    @JsonProperty('isSupprime', Boolean)
    isSupprime: boolean;

    @JsonProperty('isGroupeOperateur', Boolean)
    isGroupeOperateur: boolean;

    @JsonProperty('isUsite', Boolean)
    isUsite: boolean;

    @JsonProperty('isFacturationDirecte', Boolean)
    isFacturationDirecte: boolean;

    @JsonProperty('modeleArticleList', [ModeleArticle])
    modeleArticleList: ModeleArticle[];
}
