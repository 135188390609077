import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { remove } from 'lodash';
import { forkJoin } from 'rxjs';
import { Structure } from 'src/app/data/intervenant/models/structure.model';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';
import { StructuresService } from 'src/app/data/intervenant/services/structures/structures.service';

@Component({
  selector: 'app-liste-structures',
  templateUrl: './liste-structures.component.html',
  styleUrls: ['./liste-structures.component.scss']
})
export class ListeStructuresComponent implements OnInit {

  structures : Structure[];

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalsService : AdvBootstrapModalService,
    private readonly translate: TranslateService,
    private readonly structureService : StructuresService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {

    forkJoin(
      this.structureService.getStructures()
      )
    .pipe(this.loaderService.operator())
    .subscribe(([structures]) => {
      this.structures = structures;     
    });

  }

  openConfirmationDialog(structure: Structure) { 

    this.modalsService.confirm(
      'Veuillez confirmer',
      'Voulez vous supprimer la structure juridique '+ structure.libelle + ' ?', {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.deleteStructure(structure);
    }, () => { });

  }

  deleteStructure(structure: Structure) {
    this.structureService.supprimerStructure(structure.id).subscribe(() => {
      remove(this.structures, structure);
    });
  }

  editStructure(structure: Structure) {this.modalsService.confirm(
      'Veuillez confirmer',
      this.translate.instant(`page.valeur_parametrable.message_modification`), {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.router.navigate(['edit', structure.id], { relativeTo: this.route });
    }, () => { });
  }

}
