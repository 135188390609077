<div>
  <div class="modal-header" *ngIf="!estOnglet">
    <h4 class="modal-title" translate>page.controles.manquement.title</h4>
  </div>

  <!-- Div de contenu de la fenêtre surgissante -->
  <div class="modal-body">
    <!-- Formulaire de saisie d'un constat -->
    <form [formGroup]="formConstat" #frmConstat="ngForm" (submit)="onSubmit(frmConstat)" novalidate>
      <div class="cols">
        <div class="col">
          <!-- A afficher seulement en mode onglets -->
          <ng-container *ngIf="estOnglet">
            <div class="form-group">
              <label for="date" translate>page.controles.manquement.constat.modal.form.date</label>
              <div class="input-group">
                <input ngbDatepicker #datePicker="ngbDatepicker" formControlName="date" class="form-control" [ngClass]="{ 'is-invalid': (estOnglet || frmConstat.submitted) && formConstat.get('date').invalid }" />
                <div class="input-group-append">
                  <button type="button" (click)="datePicker.toggle()" class="btn btn-secondary">
                    <fa-icon icon="calendar-alt"></fa-icon>
                  </button>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="auteur" translate>page.controles.manquement.constat.modal.form.auteur</label>
              <select id="auteur" formControlName="auteur" class="form-control" [ngClass]="{ 'is-invalid': (estOnglet || frmConstat.submitted) && formConstat.get('auteur').invalid }">
                <option *ngFor="let auteur of auteurs" [ngValue]="auteur">{{ auteur.nomComplet }}</option>
              </select>
            </div>
          </ng-container>

          <!-- A afficher seulement lorsque pas en mode onglet -->
          <ng-container *ngIf="!estOnglet">
            <div class="form-group">
              <label for="cdc" selected translate>page.controles.manquement.constat.modal.form.cdc</label>
              <select id="cdc" formControlName="cdc" class="form-control" [ngClass]="{ 'is-invalid': frmConstat.submitted && formConstat.get('cdc').invalid }">
                <option [ngValue]="null" class="placeholder" selected hidden translate>page.controles.manquement.constat.modal.form.cdc</option>
                <option *ngFor="let cdc of controle.cahiers" [ngValue]="cdc">{{ cdc.libelle }}</option>
              </select>
            </div>

            <div class="form-group">
              <label for="produit" selected translate>page.controles.manquement.constat.modal.form.produit</label>
              <select id="produit" formControlName="produit" class="form-control" [ngClass]="{ 'is-invalid': frmConstat.submitted && formConstat.get('produit').invalid }">
                <option [ngValue]="null" class="placeholder" selected translate>page.controles.manquement.constat.modal.form.produit</option>
                <option *ngFor="let produit of produits" [ngValue]="produit">{{ produit.libelle }}</option>
              </select>
            </div>
          </ng-container>

          <div class="form-group">
            <label for="pointAMaitriser" translate>page.controles.manquement.constat.modal.form.pointAMaitriser</label>
            <select id="pointAMaitriser" formControlName="pointAMaitriser" class="form-control" [ngClass]="{ 'is-invalid': (estOnglet || frmConstat.submitted) && formConstat.get('pointAMaitriser').invalid }">
              <option [ngValue]="null" class="placeholder" selected translate>page.controles.manquement.constat.modal.form.pointAMaitriser</option>
              <option *ngFor="let point of points" [ngValue]="point">{{ point.libelle }}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="manquementConstate" translate>page.controles.manquement.constat.modal.form.manquementConstate</label>
            <select id="manquementConstate" formControlName="manquementConstate" class="form-control" [ngClass]="{ 'is-invalid': (estOnglet || frmConstat.submitted) && formConstat.get('manquementConstate').invalid }">
              <option [ngValue]="null" class="placeholder" selected translate>page.controles.manquement.constat.modal.form.manquementConstate</option>
              <option *ngFor="let manquement of manquementsConstates" [ngValue]="manquement">{{ manquement.libelle }}</option>
            </select>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="commentaire" translate>page.controles.manquement.constat.modal.form.commentaire</label>
            <textarea id="commentaire" formControlName="commentaire" class="form-control"></textarea>
          </div>

          <div class="form-group" [hidden]="controle?.nature?.code !== 'ORGANOLEPTIQUE'">
            <label for="intensite" translate>page.controles.manquement.constat.modal.form.intensite</label><br />
            <ng-container *ngFor="let intensite of intensites; let i = index">
              <input type="radio" id="intensite-{{ i }}" formControlName="intensite" value="{{ intensite }}" class="ml-5 mr-1" /> {{ intensite }}
            </ng-container>
          </div>

          <div class="form-group">
            <label for="gravite" translate>page.controles.manquement.constat.modal.form.gravite</label><br />
            <ng-container *ngFor="let gravite of gravites; let i = index">
              <input type="radio" id="gravite-{{ i }}" formControlName="gravite" value="{{ gravite }}" class="ml-5 mr-1" /> {{ gravite }}
            </ng-container>
          </div>

          <div class="form-group">
            <label for="sanction" translate>page.controles.manquement.constat.modal.form.sanction</label>
            <select id="sanction" formControlName="sanction" class="form-control" [ngClass]="{ 'is-invalid': (estOnglet || frmConstat.submitted) && formConstat.get('sanction').invalid }">
              <option [ngValue]="null" class="placeholder" selected translate>page.controles.manquement.constat.modal.form.sanction</option>
              <option *ngFor="let sanction of sanctions" [ngValue]="sanction">{{ sanction.libelle }}</option>
            </select>
          </div>
        </div>
      </div>

      <!-- Boutons d'action -->
      <div class="actions-bar" *ngIf="!estOnglet">
        <button type="button" class="btn btn-light" (click)="onClose()" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary ml-2" *ngIf="!estOnglet" translate>page.controles.manquement.constat.modal.form.ajouter</button>
        <button type="submit" class="btn btn-primary ml-2"
          (click)="onTerminer()"  
          [disabled]="!formConstat.valid"
          translate>
          label.terminer
        </button>
      </div>
      <!-- Fin boutons d'action -->
    </form>
    <!-- Fin formulaire de saisie d'un constat -->
  </div>
  <!-- Fin div de contenu de la fenêtre surgissante -->
</div>