import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditInteractionComponent } from './pages/edit-interaction/edit-interaction.component';
import { ListeInteractionsComponent } from './pages/liste-interactions/liste-interactions.component';

@Component({
  selector: 'app-interactions',
  templateUrl: './interactions.component.html',
  styleUrls: ['./interactions.component.scss']
})
export class InteractionsComponent implements OnInit {

  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"],
    menu: {
      libelle: 'menu.administration-interactions',
      icon: 'key'
      }
    };
  
    static routes: Routes = [
      { path: '', component: ListeInteractionsComponent },
      { path: 'new', component: EditInteractionComponent, data: { roles: ['ORGANISME'] } },
      { path: 'new/contact/:idc', component: EditInteractionComponent, data: { roles: ['ORGANISME'] } },
      { path: 'new/entreprise/:ide', component: EditInteractionComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id', component: EditInteractionComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id/contact/:idc', component: EditInteractionComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id/entreprise/:ide', component: EditInteractionComponent, data: { roles: ['ORGANISME'] } }
    ];

  constructor() { }

  ngOnInit() {
  }

}
