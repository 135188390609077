<div class="modal-content anpp">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel"><fa-icon icon="leaf"></fa-icon> <b translate> page.inventaire-verger.modals.cultureBio.cultureBioTitle</b></h5>
    <button (click)="cancel()" type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="formGroup" #form="ngForm" (ngSubmit)="submit()" novalidate>
      <div class="form-group">
        <ng-container *ngFor="let culture of refCultureBios">
          <div class="row">
            <div class="col-lg-12 sub-popup-title">
          <input [ngClass]="{'is-invalid': form.submitted && cultureBio.invalid }" type="radio" class="mr-2 style-radio-buttton" id="{{'cultureBio'+culture.id}}" formControlName="cultureBio" value="{{ culture.id }}" />
          <label for="{{'cultureBio'+culture.id}}" class="mr-5" translate>{{ culture.libelle }}</label>
          </div>
          </div>
        </ng-container>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" style="background:#41905c;border:1px solid #41905c"
                data-dismiss="modal" aria-label="Close"translate>page.inventaire-verger.buttonEnregistrer
        </button>
      </div>
    </form>
  </div>
</div>
