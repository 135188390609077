import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';

import { find, some } from 'lodash';
import { forkJoin } from 'rxjs';

import { Cahier } from 'src/app/data/habilitation/models/cahier.model';
import { Habilitation } from 'src/app/data/habilitation/models/habilitation.model';
import { Activite } from 'src/app/data/habilitation/models/activite.model';
import { ActivitesService } from 'src/app/data/habilitation/services/activites/activites.service';
import { CahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import { HabilitationsService } from 'src/app/data/habilitation/services/habilitations/habilitations.service';

@Component({
  selector: 'app-habilitations',
  templateUrl: './habilitations.component.html',
  styleUrls: ['./habilitations.component.scss']
})
export class HabilitationsComponent implements OnInit {
  static routeData = {
    // role: '',
    menu: {
      icon: 'certificate',
      libelle: 'menu.habilitation'
    }
  };
  static routes: Routes = [
    {
      path: '',
      redirectTo: 'liste',
      pathMatch: 'full',
      data: {
        role: 'OPERATEUR && HABILITATION_HABILITATION_LECTURE',
        menu: {
          icon: 'user-check',
          libelle: 'menu.habilitation'
        }
      }
    },
    { path: 'liste', component: HabilitationsComponent }
  ];

  protected cahiers: Cahier[];
  protected habilitations: Habilitation[];
  activites: Activite[];
  cahiersFiltered: Cahier[];

  constructor(
    protected readonly activitesService: ActivitesService,
    protected readonly cahiersService: CahiersService,
    protected readonly habilitationsService: HabilitationsService,
    protected readonly loaderService: AdvBootstrapLoaderService,
  ) { }

  ngOnInit() {
    setTimeout(() => { this.loadHabilitations(); });
  }

  loadHabilitations() {
    forkJoin(
      this.activitesService.getActivites(),
      this.cahiersService.getCahiers(),
      this.getHabilitations()
    )
      .pipe(this.loaderService.operator())
      .subscribe(
        result => {
          [this.activites, this.cahiers, this.habilitations] = result;

          this.filterCahiers();
        }
      );
  }

  getHabilitations() {
    return this.habilitationsService.getHabilitations();
  }

  getHabilitation(cahier: Cahier, activite: Activite): Habilitation {
    return find(this.habilitations, (habilitation: Habilitation) => {
      return (habilitation.cahier.id === cahier.id) && (habilitation.activite.id === activite.id);
    });
  }

  filterCahiers() {
    this.cahiersFiltered = this.cahiers.filter(cahier => {
      return some(this.habilitations, (habilitation: Habilitation) => {
        return (habilitation.cahier.id === cahier.id);
      });
    });
  }
}
