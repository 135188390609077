import { CustomConverter, JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";
import { JureCdc } from "./jure-cdc.model";

@JsonObject
export class JureSynthese extends JureCdc {   

    @JsonProperty('nbConvocation', Number)
    nbConvocation: number;

    @JsonProperty('nbPresent', Number)
    nbPresent: number;

    @JsonProperty('nbExcuse', Number)
    nbExcuse: number;

    @JsonProperty('nbAbsent', Number)
    nbAbsent: number;

    @JsonProperty('nbDesinscrit', Number)
    nbDesinscrit: number;

    @JsonProperty('nbConvoque', Number)
    nbConvoque: number;

    @JsonProperty('nbAConvoque', Number)
    nbAConvoque: number;

}