import { Component, OnInit, Input } from '@angular/core';
import { OperateurDrevManquante } from 'src/app/data/intervenant/interfaces/operateur-drev-manquante.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-operateur',
  templateUrl: './operateur.component.html',
  styleUrls: ['./operateur.component.scss']
})
export class OperateurComponent implements OnInit {
  @Input() public operateur: OperateurDrevManquante;

  constructor(private readonly modal: NgbActiveModal) { }

  ngOnInit() { }

  public close(): void {
    this.modal.dismiss();
  }
}
