import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class Terroir {

  @JsonProperty('id', Number)
  public id: number = undefined;

  @JsonProperty('libelle', String)
  public libelle: string = undefined;

  @JsonProperty('departements', String)
  @Optional
  public departements: string = undefined;

}
