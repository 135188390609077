<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>page.edition.gestion-templates.modals.add-document.title</h4>
  </div>

  <div class="modal-body">
    <form [formGroup]="formGroup" #form="ngForm" (ngSubmit)="submit()">
      <div class="form-section">
        <div class="form-group">
          <label for="libelle" translate>page.edition.gestion-templates.modals.add-document.nom-document</label>

          <input type="text" id="nomDocument" formControlName="nomDocument"
            [ngClass]="{'is-invalid': form.submitted && formGroup.get('nomDocument').invalid}" class="form-control" />
        </div>
      </div>


      <div class="actionsButtons">
        <button style="position:relative;margin:1em;" type="submit" class="btn btn-primary" (click)="save()"
          translate>label.enregistrer</button>
        <button type="button" class="btn btn-light" (click)="cancel()" translate>label.annuler</button>
      </div>
    </form>
  </div>
</div>
<!--
<ng-template #aucunCdc>
  <div translate>page.commissions.edit.modals.cdcs.table.aucun_cdc</div>
</ng-template>

<ng-template #tableHeader let-property="sortProperty" let-libelle="libelle">
  <th *ngIf="property" (click)="setOrder(property)" [class.sortable]="property">
    {{ libelle | translate }} <fa-icon *ngIf="property" [icon]="getIcon(property)"></fa-icon>
  </th>
  <th *ngIf="!property">{{ libelle | translate }}</th>
</ng-template>
-->