import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AbstractRechercheItemComponent } from 'src/app/main/pages/commissions/pages/edit-commission/modals/abstract-recherche-items.component';
import { Observable, forkJoin } from 'rxjs';
import { Cahier } from 'src/app/data/habilitation/models/cahier.model';
import { FormBuilder, NgForm, FormGroup, Validators } from '@angular/forms';
import { ModeleArticle } from 'src/app/data/facturation/models/modele-article.model';
import { ModeleArticleService } from 'src/app/data/facturation/services/modele-article/modele-article.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { Router, ActivatedRoute } from '@angular/router';
import { RefModeCalcul } from 'src/app/data/facturation/models/ref-mode-calcul.model';
import { RefQuantiteFacture } from 'src/app/data/facturation/models/ref-quantite-facture.model';
import { RefTva } from 'src/app/data/facturation/models/ref-tva.model';
import { ReferencesFacturationService } from 'src/app/data/facturation/services/references-facturation/references-facturation.service';
import { RefObjetFacture } from 'src/app/data/facturation/models/ref-objet-facture.model';
import { find } from 'lodash';
import { CahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import { CacheService } from 'src/app/core/services/cache/cache.service';
import { ModeleFacturation } from 'src/app/data/facturation/models/modele-facturation.model';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { ReferenceQuantiteEnum } from 'src/app/data/facturation/models/enums/references-quantite.enum';

@Component({
  selector: 'app-edit-article',
  templateUrl: './edit-article.component.html',
  styleUrls: ['./edit-article.component.scss']
})
export class EditArticleComponent extends AbstractRechercheItemComponent<ModeleArticle> implements OnInit {
  @ViewChild('form') form: NgForm;
  formGroup: FormGroup;
  @Input() idModeleFacturation: number;
  @Input() idOrganisme: number;
  @Input() modeleArticle: ModeleArticle;
  @Input() edition: boolean;
  @Input() selectedObjetFacture: any;
  @Input() modeleFacturation: ModeleFacturation;
  refQuantitesFacture: RefQuantiteFacture[];
  refQuantitesFactureFilter: RefQuantiteFacture[];

  refModeCalcul: RefModeCalcul[];
  refObjetsFactures: RefObjetFacture[];
  refTva: RefTva[];
  cahiers: Cahier[];
  loaded = false;

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly fb: FormBuilder,
    private readonly modeleArticleService: ModeleArticleService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly refFacturationService: ReferencesFacturationService,
    private readonly cahiersService: CahiersService,
    private readonly modalsService: AdvBootstrapModalService
  ) {
    super(modal);
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    forkJoin(
      this.cahiersService.getCahiers(),
      this.refFacturationService.getReferences()
    ).pipe(
      this.loaderService.operator()
    ).subscribe(
      ([cahiers, ref]) => {
        this.cahiers = cahiers;
        this.refModeCalcul = ref.refModeCalculList;
        this.refObjetsFactures = ref.refObjetFactureList;
        this.refQuantitesFacture = ref.refQuantiteFactureList;
        this.refTva = ref.refTvaList;
        this.refQuantitesFactureFilter = this.refQuantitesFacture.filter(qte => qte.refObjetFacture.id === this.selectedObjetFacture.id);
        this.createForm(this.modeleArticle);
      }
    );
  }


  createForm(modeleArticle: ModeleArticle) {
    let selectedModeCalcul;
    let selectedTva;
    let selectedQuantiteFacture;
    if (modeleArticle.id) {
      selectedModeCalcul = find(this.refModeCalcul, refModeCalcul => refModeCalcul.id === modeleArticle.refModeCalcul.id);
      selectedTva = find(this.refTva, refTva => refTva.id === modeleArticle.refTva.id);
      selectedQuantiteFacture = find(this.refQuantitesFacture, refQuantiteFacture => refQuantiteFacture.id === modeleArticle.refQuantiteFacture.id);
      this.formGroup = this.fb.group({
        libelle: [this.modeleArticle.libelle, Validators.required],
        prixUnitaireHT: [this.modeleArticle.prixUnitaire, Validators.required],
        prixMinimumHT: [this.modeleArticle.prixMinimumHT, Validators.required],
        modeCalcul: [selectedModeCalcul, Validators.required],
        typeQteFacture: [selectedQuantiteFacture, Validators.required],
        cdc: [this.modeleArticle.cahiers, Validators.required],
        tauxTVA: [selectedTva, Validators.required]
      });
    } else {
      this.formGroup = this.fb.group({

        libelle: [this.modeleArticle.libelle ? this.modeleArticle.libelle : undefined, Validators.required],
        prixUnitaireHT: [this.modeleArticle.prixUnitaire ? this.modeleArticle.prixUnitaire : undefined, Validators.required],
        prixMinimumHT: [this.modeleArticle.prixMinimumHT ? this.modeleArticle.prixMinimumHT : undefined, Validators.required],
        modeCalcul: [this.modeleArticle.refModeCalcul ? find(this.refModeCalcul, refModeCalcul => refModeCalcul.id === modeleArticle.refModeCalcul.id) : undefined, Validators.required],
        typeQteFacture: [this.modeleArticle.refQuantiteFacture ? find(this.refQuantitesFacture, refQuantiteFacture => refQuantiteFacture.id === modeleArticle.refQuantiteFacture.id) : undefined, Validators.required],
        cdc: [this.modeleArticle.cahiers, Validators.required],
        tauxTVA: [this.modeleArticle.refTva ? find(this.refTva, refTva => refTva.id === modeleArticle.refTva.id) : this.refTva[0], Validators.required]
      });
    }
    this.loaded = true;
  }

  submit() {
    if (this.formGroup.valid) {
      const modeleArticle = new ModeleArticle();
      const libelle = this.getField('libelle').value;
      const prixUnitaireHT = this.getField('prixUnitaireHT').value;
      const prixMinimumHT = this.getField('prixMinimumHT').value;
      const modeCalcul = this.getField('modeCalcul').value;
      const typeQteFacture = this.getField('typeQteFacture').value;
      const cdc = this.getField('cdc').value;
      const tauxTVA = this.getField('tauxTVA').value;

      modeleArticle.libelle = libelle;
      modeleArticle.refModeCalcul = modeCalcul;
      modeleArticle.refQuantiteFacture = typeQteFacture;
      modeleArticle.refTva = tauxTVA;
      modeleArticle.cahiers = cdc;
      modeleArticle.prixUnitaire = parseFloat(prixUnitaireHT);
      modeleArticle.prixMinimumHT = parseFloat(prixMinimumHT);

      // si l'article existe donc édition
      if (this.modeleArticle.id) {
        modeleArticle.id = this.modeleArticle.id;
        this.modeleArticleService.modifyModeleArticle(SessionContext.get('idOrganisme'), modeleArticle, this.idModeleFacturation).subscribe(
          response => {
            this.toastr.success(
              this.translate.instant('page.facturation.edit.alert.modele_article.modification.message', { libelle: response.libelle }),
              this.translate.instant('page.facturation.edit.alert.modele_article.modification.title'),
              { timeOut: 10 * 1000 }
            );
            this.modal.close(modeleArticle);
            this.openModalInformationsUtilisateurs();
          }
        );
      } else { // sinon, création
        if (this.edition) {
          this.modeleArticleService.createModeleArticle(SessionContext.get('idOrganisme'), modeleArticle, this.idModeleFacturation).subscribe(
            response => {
              this.toastr.success(
                this.translate.instant('page.facturation.edit.alert.modele_article.creation.message', { libelle: response.libelle }),
                this.translate.instant('page.facturation.edit.alert.modele_article.creation.title'),
                { timeOut: 10 * 1000 }
              );
              this.modal.close(modeleArticle);
              this.openModalInformationsUtilisateurs();
            }
          );
        } else {
          this.modal.close(modeleArticle);
        }
      }
    }
  }

  private openModalInformationsUtilisateurs() {
    if (this.modeleFacturation.isUsite) {
      this.modalsService.alert(`Les facturations déjà génèrées ne seront pas modifiées.`);
    }
  }

  changeModeCalcul() {
    if (this.getField('modeCalcul').value.code === 'FORFAITAIRE') {
      this.formGroup.controls.prixMinimumHT.setValue('0');
      this.formGroup.controls.prixMinimumHT.disable();
      this.refQuantitesFactureFilter = this.refQuantitesFacture.filter(qte => qte.refObjetFacture.id === this.selectedObjetFacture.id
        && qte.code.split('_')[0] === ReferenceQuantiteEnum.FORFAIT_SPLIT);
      this.formGroup.controls.typeQteFacture.setValue(this.refQuantitesFactureFilter[0]);
      this.formGroup.controls.typeQteFacture.updateValueAndValidity();
    } else {
      this.formGroup.controls.prixMinimumHT.enable();
      this.refQuantitesFactureFilter = this.refQuantitesFacture.filter(qte => qte.refObjetFacture.id === this.selectedObjetFacture.id
        && qte.code.split('_')[0] != ReferenceQuantiteEnum.FORFAIT_SPLIT);
      this.formGroup.controls.typeQteFacture.setValue(this.refQuantitesFactureFilter[0]);
      this.formGroup.controls.typeQteFacture.updateValueAndValidity();
    }
  }

  getData(): Observable<any[]> {
    throw new Error('Method not implemented.');
  }

  getField(name: string) {
    return this.formGroup.get(name);
  }

}
