import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class LaboratoireAnpp {
  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('libelle', String)
  libelle: string = undefined;

  @JsonProperty('typeLibelle', String)
  typeLibelle: string = undefined;
  
  @JsonProperty('typeCode', String)
  typeCode: string = undefined;

}
