import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { SyntheseInventaireUniteEdition } from './synthese-inventaire-unite-edition';

@JsonObject
export class SyntheseInventaireEspeceEdition {

    @JsonProperty('libelleEspece', String)
    @Optional
    libelleEspece: String

    @JsonProperty('uniteCulturaleEditionList', [SyntheseInventaireUniteEdition])
    @Optional
    uniteCulturaleEditionList: SyntheseInventaireUniteEdition[]

    @JsonProperty('surface', Number)
    @Optional
    surface: number

    @JsonProperty('surfaceIrriguee', Number)
    @Optional
    surfaceIrriguee: number

    @JsonProperty('surfaceGrele', Number)
    @Optional
    surfaceGrele: number

    @JsonProperty('surfaceGel', Number)
    @Optional
    surfaceGel: number

    @JsonProperty('surfaceBio', Number)
    @Optional
    surfaceBio: number


}
