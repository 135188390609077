import { JsonProperty, JsonObject, Optional, CustomConverter } from '@thorolf/json-ts-mapper';
import { RefQuantiteFacture } from './ref-quantite-facture.model';

@JsonObject
export class QuantiteInfos {
    quantite?: number;
    refQuantiteFacture?: RefQuantiteFacture;
    libelle?: string;

    constructor(
        quantite?: number,
        refQuantiteFacture?: RefQuantiteFacture,
        libelle?: string
    ) {
        this.quantite = quantite;
        this.refQuantiteFacture = refQuantiteFacture;
        this.libelle = libelle;
    }
}
