import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EchantillonJury } from 'src/app/data/commission/models/echantillon-jury.model';
import { Echantillon } from 'src/app/data/commission/models/echantillon.model';
import { AbstractRechercheItemComponent } from '../abstract-recherche-items.component';

@Component({
  selector: 'app-filtre-echantillons-igp',
  templateUrl: './filtre-echantillons-igp.component.html',
  styleUrls: ['./filtre-echantillons-igp.component.scss']
})
export class FiltreEchantillonsIgpComponent implements OnInit {

  @ViewChild('form') form: NgForm;
  formGroup: FormGroup;

  @Input() echantillons: EchantillonJury[];
  
  sortField1: string = '';
  sortField2: string = '';  

  constructor(
    private readonly fb: FormBuilder,
    public readonly modal: NgbActiveModal
    ) {     }

  ngOnInit() {

    if(this.echantillons)
    this.echantillons.forEach(ech => {
      if(ech.echantillon){
        ech.echantillon.cahier = ech.echantillon.produit.libelleCahier;
        ech.echantillon.couleur = this.getCouleur(ech.echantillon.codeProduit);
      }else if(ech.leurre){        
        ech.leurre.cahier = ech.leurre.produit.libelleCahier;
        ech.leurre.couleur = this.getCouleur(ech.leurre.codeProduit);
      }
    });

    this.formGroup = this.fb.group({
      //jure: [undefined, Validators.required],
    });
  }

  // Helper function to sort by multiple fields
  multiSort = (fields: string[]) => (a: any, b: any) => {
    for (let field of fields) {
      let ech1;
      let ech2;

      ech1 = (a.echantillon)?a.echantillon : a.leurre;
      ech2 = (b.echantillon)?b.echantillon : b.leurre;

      if (ech1[field] < ech2[field]) return -1;
      if (ech1[field] > ech2[field]) return 1;
    }
    return 0;
  };  

  onSortChange1(event: any) {
    this.sortField1 = event.target.value;
    this.echantillons.sort(this.multiSort([this.sortField1, this.sortField2]));
  }

  onSortChange2(event: any) {
    this.sortField2 = event.target.value;
    this.echantillons.sort(this.multiSort([this.sortField1, this.sortField2]));
  }


  getCVI(echantillon: Echantillon): string {
    try {
      return echantillon.infosOperateur.find(infos => !!~infos.code.indexOf('CVI')).valeur;
    } catch (e) {
      return '';
    }
  }

  getCouleur(codeProduit: String): string {
    try {

      switch   (codeProduit.substring(1,2))
      {
          case "R" :
            return 'Rouge';
          case "S" : 
            return 'Rosé';
          case "B" :
            return "Blanc";
          default:
            return codeProduit.substring(1,2);
      }
      
    } catch (e) {
      return '';
    }
  }



  getRaisonSociale(echantillon: Echantillon): string {
    try {
      return echantillon.operateur.raisonSociale;
    } catch (e) {
      return '';
    }
  }

  public onClose(): void { this.modal.dismiss(); }




}
