<!-- <div class="anpp">
  <div class="content">
    <div class="content-panel">
      <div >
        <div class="title-bar">
          <h4 translate>
            
          </h4>
        </div> -->



<div class="row mt-4 pt-4">

  <div class="col-lg-12">


    <div class="cards  shadow pt-2  pb-4 border-radius text-center echarts-card">
      <fa-icon icon="clipboard-list" class="mt-2 p-2" style="font-size:20px"></fa-icon>
      <h5><b translate>page.valeur_parametrable.listes_parametrables</b></h5>


    </div>



    <div class="row mt-4">


      <div class="col-lg-7">
        <div class="cards  border-radius p-2 pb-4 text-center">
          <fa-icon icon="user" class="mt-2 p-2" style="font-size:20px"></fa-icon><strong translate>
            page.valeur_parametrable.ref_contact</strong>
          <div class="row">
            <div class="col-lg-4">
              <div class=" border-radius p-2 pointer" (click)="goToListes('fonctions')">
                <h4>
                  <div class=" badge  badge-green-white">
                    Fonctions
                  </div>
                </h4>
              </div>
            </div>
            <div class="col-lg-4">
              <div class=" border-radius p-2 pointer" (click)="goToListes('groupediffusion')">
                <h4>
                  <div class=" badge  badge-green-white">
                    Groupes de diffusion
                  </div>
                </h4>
              </div>
            </div>
            <div class="col-lg-4">
              <div class=" border-radius p-2 pointer" (click)="goToListes('typeInteraction')">
                <h4>
                  <div class=" badge  badge-green-white">
                    Types d'interaction
                  </div>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="col-lg-5">
        <div class="cards  border-radius  p-2 pb-4 text-center">
          <fa-icon icon="clipboard-check" class="mt-2 p-2" style="font-size:20px"></fa-icon><strong translate>
            page.valeur_parametrable.ref_entreprise</strong>
          <div class="row">
            <div class="col-lg-12">
              <div class=" border-radius p-2 pointer" (click)="goToListes('structures')">
                <h4>
                  <span class=" badge  badge-green-white">
                    Structures juridiques
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-12">
        <div class="cards  border-radius  p-2 pb-2 text-center">
          <fa-icon icon="clipboard-check" class="mt-2 p-2" style="font-size:20px"></fa-icon><strong>
            <span translate> page.valeur_parametrable.ref_verger</span></strong>
          <div class="row">
            <div class="col-lg-2">
              <div class=" border-radius p-2 pointer" (click)="goToListes('varietes')">
                <h4><span class="badge badge-green-white" translate>Variétés</span></h4>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="border-radius p-2 pointer" (click)="goToListes('groupes')">
                <h4><div class="badge  badge-green-white">Groupes variétaux</div></h4>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="border-radius p-2 pointer" (click)="goToListes('portegreffes')">
                <h4><div class="badge  badge-green-white">Porte-greffes</div></h4>
              </div>
            </div>
            <div class="col-lg-2">
              <div class=" border-radius p-2 pointer" (click)="goToListes('clones')">
                <h4><div class="badge  badge-green-white">Clones</div></h4>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="border-radius p-2 pointer" (click)="goToListes('cultureBio')">
                <h4><div class="badge  badge-green-white">Culture Bio</div></h4>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="border-radius p-2 pointer" (click)="goToListes('formes')">
                <h4><div class="badge  badge-green-white">Formes</div></h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-2">
              <div class=" border-radius p-2 pointer" (click)="goToListes('situations')">
                <h4><span class="badge badge-green-white" translate>Situations</span></h4>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="border-radius p-2 pointer" (click)="goToListes('protectionGrele')">
                <h4><div class="badge  badge-green-white">Protections Grêle</div></h4>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="border-radius p-2 pointer" (click)="goToListes('protectionGel')">
                <h4><div class="badge  badge-green-white">Protections Gel</div></h4>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="border-radius p-2 pointer" (click)="goToListes('irrigations')">
                <h4><div class="badge  badge-green-white">Irrigations</div></h4>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="border-radius p-2 pointer" (click)="goToListes('terroirs')">
                <h4><div class="badge  badge-green-white">Terroirs</div></h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>