import { JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";

@JsonObject
export class ManquementMouvementEdition{
    @JsonProperty('millesime', String)
    millesime: string;

    @JsonProperty('cahier', String)
    cahier: string;

    @JsonProperty('produit', String)
    produit: string;

    @JsonProperty('produitOrigine', String)
    @Optional
    produitOrigine: string;

    @JsonProperty('numeroLot', String)
    @Optional
    numeroLot: string;

    @JsonProperty('cepage', String)
    @Optional
    cepage: string;

    @JsonProperty('volume', String)
    volume: string;

    @JsonProperty('type', String)
    @Optional
    type: string;

    @JsonProperty('logement', String)
    @Optional
    logement: string;

    @JsonProperty('contenant', String)
    @Optional
    contenant: string;

    @JsonProperty('date', String)
    @Optional
    date: string;

    @JsonProperty('dateRetiraison', String)
    @Optional
    dateRetiraison: string;

    @JsonProperty('typeConditionnement', String)
    @Optional
    typeConditionnement: string;

    @JsonProperty('degre', String)
    @Optional
    degre: string;

    @JsonProperty('numeroDossier', String)
    @Optional
    numeroDossier: string;

    @JsonProperty('commentaire', String)
    @Optional
    commentaire: string;
}