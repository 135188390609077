import { CustomConverter, JsonObject, JsonProperty } from "@thorolf/json-ts-mapper";
import * as moment from "moment";
import { DateConverter } from "src/app/core/services/mapper/converters";

@JsonObject
export class VersionFichier {

  @JsonProperty('id', Number)
  public id: number;

  @JsonProperty('dateCreation', String)
  @CustomConverter(DateConverter)
  public dateCreation: moment.Moment;

  @JsonProperty('numero', String)
  public numero: String;

  @JsonProperty('idAuteur', Number)
  public idAuteur: number;

}
