import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { forkJoin, Observable, throwError } from 'rxjs';
import { map, flatMap, catchError } from 'rxjs/operators';

import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';

import { Utilisateur } from '../../models/utilisateur.model';
import { OrganismesService } from '../organismes/organismes.service';
import { ReferencesService } from '../references/references.service';
import { UtilisateurTypeCode } from '../../models/enums/type-utilisateur.enum';
import { UtilisateurBase } from '../../models/utilisateur-base.model';
import { EngagementCtOc } from 'src/app/data/habilitation/models/engagements/engagement-ct-oc';
import { NotificationDocument } from '../../models/notification-document.model';
import { NotificationUpload } from '../../models/notification-upload.model';

@Injectable({
  providedIn: 'root'
})
export class UtilisateursService {
  constructor(
    protected readonly http: HttpClient,
    protected readonly mapper: JsonMapperService,
    private readonly organismesService: OrganismesService,
    private readonly referencesService: ReferencesService
  ) { }

  public getUtilisateurs(): Observable<Utilisateur[]> {
    return forkJoin([
      this.http.get<object[]>(`/api/intervenant/private/utilisateurs`),
      this.organismesService.getOrganismes(),
      this.referencesService.getReferences()
    ])
      .pipe(
        map(([utilisateurs, organismes, ref]) => this.mapper.deserializeArray(utilisateurs, Utilisateur, Object.assign({ organismes }, ref)))
      );
  }

  public getUtilisateur(id: number): Observable<Utilisateur> {
    return forkJoin([
      this.http.get<object>(`/api/intervenant/private/utilisateurs/${id}`),
      this.organismesService.getOrganismes(),
      this.referencesService.getReferences()
    ]).pipe(
      map(([utilisateur, organismes, ref]) => this.mapper.deserializeObject(utilisateur, Utilisateur, Object.assign({ organismes }, ref)
      ))
    );
  }

  public createUtilisateur(utilisateur: UtilisateurBase): Observable<number> {
    return this.http.post(`/api/intervenant/private/utilisateurs`, this.mapper.serialize(utilisateur), { observe: 'response' }).pipe(
      map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
    );
  }

  public updateUtilisateur(utilisateur: Utilisateur): Observable<void> {
    return this.http.put(`/api/intervenant/private/utilisateurs/${utilisateur.id}`, this.mapper.serialize(utilisateur)).pipe(
      map(() => { })
    );
  }

  public updateUtilisateurStatut(utilisateur: Utilisateur, newActif: boolean): Observable<void> {
    return this.http.patch(`/api/intervenant/private/utilisateurs/${utilisateur.id}`, {
      actif: newActif
    }).pipe(
      map(() => { })
    );
  }

  public getUtilisateursByEmail(email: string, typeCode: UtilisateurTypeCode): Observable<Utilisateur> {
    return this.http.get(`/api/intervenant/private/utilisateurs/recherche?email=${email}&type=${typeCode}`, { responseType: 'text' }).pipe(
      catchError(e => {
        e.error = JSON.parse(e.error);
        return throwError(e);
      }),
      flatMap((id: string) => this.getUtilisateur(+id))
    );
  }


  public updateNotificationDocumentaire(idUtilisateur: number, notification: boolean): Observable<void> {
    return this.http.patch(`/api/intervenant/private/utilisateurs/${idUtilisateur}/notification-documentaire/${notification}`, {
    }).pipe(
      map(() => { })
    );
  }

  public getConseillersTechniques(idEntreprise: number): Observable<Utilisateur[]> {
    return forkJoin([
      this.http.get<object[]>(`/api/intervenant/private/entreprises/${idEntreprise}/conseillersTechniques`),
      this.organismesService.getOrganismes(),
      this.referencesService.getReferences()
    ]).pipe(
      map(([utilisateurs, organismes, ref]) => this.mapper.deserializeArray(utilisateurs, Utilisateur, Object.assign({ organismes }, ref)
      ))
    );
  }

  public getConseillersTechniquesGroupe(idEntreprise: number): Observable<Utilisateur[]> {
    return forkJoin([
      this.http.get<object[]>(`/api/intervenant/private/entreprises/${idEntreprise}/conseillersTechniquesGroupe`),
      this.organismesService.getOrganismes(),
      this.referencesService.getReferences()
    ]).pipe(
      map(([utilisateurs, organismes, ref]) => this.mapper.deserializeArray(utilisateurs, Utilisateur, Object.assign({ organismes }, ref)
      ))
    );
  }

  public getIntervenantsOrganismeCertificateur(idEntreprise: number): Observable<Utilisateur[]> {
    return forkJoin([
      this.http.get<object[]>(`/api/intervenant/private/entreprises/${idEntreprise}/intervenants-organisme-certificateur`),
      this.organismesService.getOrganismes(),
      this.referencesService.getReferences()
    ]).pipe(
      map(([utilisateurs, organismes, ref]) => this.mapper.deserializeArray(utilisateurs, Utilisateur, Object.assign({ organismes }, ref)
      ))
    );
  }


  public getAuteurs(listCtOc: EngagementCtOc[]): Observable<EngagementCtOc[]> {
    return this.http.post<object[]>(`/api/intervenant/private/auteurs`, this.mapper.serialize(listCtOc)).pipe(
      map(response => this.mapper.deserializeArray(response, EngagementCtOc))
    );
  }

  public sendNotificationDocumentaire(notificationDocument: NotificationDocument): Observable<void> {
    return this.http.post(`/api/intervenant/private/utilisateurs/send-notification-document`, notificationDocument, { observe: 'response' }).pipe(
      map(() => { })
    );
  }

  public sendNotificationsUpload(notificationUpload: NotificationUpload): Observable<void> {
    return this.http.post(`/api/intervenant/private/utilisateurs/send-upload-notifications`, notificationUpload, { observe: 'response' }).pipe(
      map(() => { })
    );
  }

}
