import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class MockToken {
  @JsonProperty('authHeader', String)
  public authHeader: string = undefined;

  @JsonProperty('authValue', String)
  public authValue: string = undefined;

  @JsonProperty('portailCode', String)
  public portailCode: string = undefined;

  @JsonProperty('portailHeader', String)
  public portailHeader: string = undefined;
}
