import { HttpResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { Routes } from "@angular/router";
import { forkJoin, Observable } from "rxjs";
import { ImportInventaireVergersResultat } from "src/app/data/declaration/models/import-inventaire-vergers-resultat.model";
import { ImportInventaireVergersService } from "src/app/data/declaration/services/parcellaire/import-inventaire-vergers.service";
import { Entreprise } from "src/app/data/intervenant/models/entreprise.model";
import { EntreprisesService } from "src/app/data/intervenant/services/entreprises/entreprises.service";
import { SessionContext } from "src/app/core/services/config/app.settings";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { RefSousTypesService } from "src/app/data/intervenant/services/ref-sous-types/ref-sous-types.service";
import { AdvBootstrapLoaderService } from "@adv/bootstrap-loader";
import { UtilisateursService } from "src/app/data/intervenant/services/utilisateurs/utilisateurs.service";
import { AuthService } from "src/app/core/services/auth/auth.service";
import { JsonMapperService } from "src/app/core/services/mapper/mapper.service";

@Component({
  selector: "app-import-inventaire-vergers",
  templateUrl: "./import-inventaire-vergers.component.html",
  styleUrls: ["./import-inventaire-vergers.component.scss"],
})
export class ImportInventaireVergersComponent implements OnInit {
  private estGPonly = true;
  private refSousTypes: any;
  public formGroup: FormGroup;
  public fileToUpload: File;
  public isSaving: boolean;
  public groupement: Entreprise;
  public entreprises: Entreprise[];
  public retourImport: ImportInventaireVergersResultat;

  public get groupementSel(): AbstractControl {
    return this.formGroup.get("selectionGroupement");
  }
  public get inputFile(): AbstractControl {
    return this.formGroup.get("myInputFile");
  }

  public static routes: Routes = [
    {
      path: "",
      component: ImportInventaireVergersComponent,
      data: {
        role: "ORGANISME || GP",
        domaines: ["ANPP"],
        menu: {
          libelle: "menu.import-inventaire-vergers",
          icon: "key",
        },
      },
    },
  ];

  constructor(
    private fb: FormBuilder,
    private readonly utilisateursService: UtilisateursService,
    private readonly entreprisesService: EntreprisesService,
    private readonly importInventaireVergersService: ImportInventaireVergersService,
    private readonly toastr: ToastrService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly translate: TranslateService,
    private readonly refSousTypeservice: RefSousTypesService,
    private readonly authService: AuthService,
    protected readonly mapper: JsonMapperService
  ) {}

  public ngOnInit(): void {
    this.isSaving = false;
    this.estGPonly = !this.authService.isAnpp();

    this.formGroup = this.fb.group({
      selectionGroupement: [
        { value: this.groupement, disabled: this.estGPonly },
      ],
      myInputFile: [undefined],
    });

    this.loadData();
  }

  private loadData(): void {
    forkJoin([
      this.refSousTypeservice.getRefSousType(),
      this.utilisateursService.getUtilisateur(
        SessionContext.get("idIntervenant")
      ),
    ])
      .pipe(this.loaderService.operator())
      .subscribe(([refSousTypes, utilisateur]) => {
        this.refSousTypes = refSousTypes;
        if (
          utilisateur &&
          utilisateur.entreprise &&
          utilisateur.entreprise.refSousType.code === "GP_DEFAUT"
        ) {
          this.groupement = utilisateur.entreprise;
          this.groupementSel.setValue(utilisateur.entreprise);
        }
        forkJoin([
          this.entreprisesService.getEntreprisesByIdRefSousType(
            this.refSousTypes.find((r) => r.code === "GP_DEFAUT").id
          ),
        ])
          .pipe(this.loaderService.operator())
          .subscribe(([compagnies]) => {
            if (utilisateur.entreprise) {
              this.entreprises = compagnies
                .filter((e: Entreprise) => e.id == utilisateur.entreprise.id)
                .sort((a: Entreprise, b: Entreprise) =>
                  a.raisonSociale.localeCompare(b.raisonSociale)
                );
            } else {
              this.entreprises = compagnies.sort(
                (a: Entreprise, b: Entreprise) =>
                  a.raisonSociale.localeCompare(b.raisonSociale)
              );
            }
          });
      });
  }

  /**
   *  Handle file input
   */
  public handleFileInput(files: FileList): void {
    this.fileToUpload = files[0];
    this.isSaving = false;
  }

  /**
   * click bouton Envoyer
   */
  public uploadInventaire(): void {
    this.isSaving = true;
    this.retourImport = undefined;
    this.subscribeToImportInventaire(
      this.importInventaireVergersService.uploadInventaire(
        this.groupement.id,
        this.fileToUpload
      )
    );
  }

  /**
   * souscription pour l'envoi des données
   */
  protected subscribeToImportInventaire(
    result: Observable<HttpResponse<ImportInventaireVergersResultat>>
  ): void {
    result.pipe(this.loaderService.operator()).subscribe(
      (o) => {
        this.retourImport = o.body;
        this.fileToUpload = undefined;
        this.inputFile.setValue(undefined);
      },
      () => this.onSaveError()
    );
  }

  /**
   * On save error
   */
  protected onSaveError(): void {
    this.isSaving = false;
    this.toastr.error(
      this.translate.instant("Erreur lors de l'import du fichier"),
      this.translate.instant("Import inventaire vergers"),
      { timeOut: 10000 }
    );
  }

  public onValueGroupementChange(): void {
    this.groupement = this.formGroup.get("selectionGroupement")
      .value as Entreprise;
    if (this.groupement) {
      this.formGroup.get("myInputFile").enable();
    } else {
      this.formGroup.get("myInputFile").disable();
    }
  }

  // Reset fichier à uploader
  public reset(): void {
    this.fileToUpload = undefined;
    this.inputFile.setValue(undefined);
  }

  public getNbProducteursEnErreur(): number {
    let reponse = this.retourImport as ImportInventaireVergersResultat;
    return (
      (reponse.producteursEnErreur
        ? reponse.producteursEnErreur
            .map((item) => item.raisonSociale)
            .filter((value, index, self) => self.indexOf(value) === index)
            .length
        : 0) +
      (reponse.producteursEnErreurInconnus
        ? reponse.producteursEnErreurInconnus
            .map((item) => item.raisonSociale)
            .filter((value, index, self) => self.indexOf(value) === index)
            .length
        : 0) +
      (reponse.producteursEnErreurAbsentsGP
        ? reponse.producteursEnErreurAbsentsGP
            .map((item) => item.raisonSociale)
            .filter((value, index, self) => self.indexOf(value) === index)
            .length
        : 0)
    );
  }
}
