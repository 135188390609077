import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, from, of } from 'rxjs';
import { remove, find } from 'lodash';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { Animateur } from 'src/app/data/commission/models/animateur.model';
import { AnimateursService } from 'src/app/data/commission/services/animateurs/animateurs.service';
import { ProduitsService } from 'src/app/data/habilitation/services/produits/produits.service';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { Tournee } from 'src/app/data/tournee/models/tournee.model';
import { TourneesService } from 'src/app/data/tournee/services/tournees.service';
import { RechercheEchantillonsTourneeComponent } from './modals/recherche-echantillons-tournee/recherche-echantillons-tournee.component';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as moment from 'moment';
import { SaisirPrelevementComponent } from '../../../controles/pages/list-echantillons/modals/saisir-prelevement/saisir-prelevement.component';
import { TourneeEchantillon } from 'src/app/data/tournee/models/tournee-echantillon.models';
import { TelechargerDocumentComponent } from '../../../main/components/telecharger-document/telecharger-document.component';
import { CategorieEnum } from 'src/app/data/edition/models/enums/categorie.enum.model';

const heureRequiredValidator = function(control: FormControl) {
  if (!control.value || typeof control.value.hour === 'undefined' || typeof control.value.minute === 'undefined') {
    return { invalid: true };
  }

  return null;
};

@Component({
  selector: 'app-edit-tournee',
  templateUrl: './edit-tournee.component.html',
  styleUrls: ['./edit-tournee.component.scss']
})
export class EditTourneeComponent implements OnInit {

  tournee: Tournee;
  techniciens: Animateur[];
  indexLastEchantillon: number;
  edition: boolean;
  tourneeIsOver = false;

  echantillons: TourneeEchantillon[] = [];

  indexNouveauxEchantillons: number[] = [];

  formGroup: FormGroup;

  get nbEchantillons(): number {    
    return this.echantillons.length;
  }

  get nbOperateurs(): number {    

    let opDistinct: number[] = [];

     this.echantillons.forEach(element => {
      if(opDistinct.indexOf(element.idOperateur) === -1){
        opDistinct.push(element.idOperateur);
      }
    });;

    return opDistinct.length;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly animateursService: AnimateursService,
    private readonly tourneesService: TourneesService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly modalService: NgbModal,
    private readonly loaderService: AdvBootstrapLoaderService
  ) { }

  ngOnInit() {
    let idTournee = null;
    if (this.route.snapshot.paramMap.has('idTournee')) {
      idTournee = parseInt(this.route.snapshot.paramMap.get('idTournee'), 10);
      isNaN(idTournee) ? this.edition = false : this.edition = true;
    }

    this.loadData(idTournee);
  }

  
  loadData(idTournee: number) {
    forkJoin(
      isNaN(idTournee) ? of(new Tournee()) : this.tourneesService.getTournees(SessionContext.get('idOrganisme'), idTournee), // TODO if idCommission is set, get from server
      this.animateursService.getAnimateurs(SessionContext.get('idOrganisme'))
    ).pipe(
      this.loaderService.operator()
    ).subscribe(
      ([tournee,  techniciens]) => {
        this.tournee = tournee;
        this.techniciens = techniciens;

        this.tourneeIsOver = this.tournee.terminee;

        this.createForm(tournee);

        this.indexLastEchantillon = this.nbEchantillons;
      }
    );

  }

  public telechargerDocument() {
    const modalRef = this.modalService.open(TelechargerDocumentComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.tournee = this.tournee;
    modalRef.componentInstance.categorie = CategorieEnum.TOURNEE;
    modalRef.result.then(
      () => { },
      error => { throw error; }
    );
  }

  getField(name: string) {
    return this.formGroup.get(name);
  }

  submit() {
    if (this.formGroup.valid) {
      const tournee = new Tournee();

      const date = this.getField('date').value;
      const heureDebut = this.getField('heureDebut').value;
      const heureFin = this.getField('heureFin').value;

      tournee.dateDebut = moment([date.year, date.month - 1, date.day, heureDebut.hour, heureDebut.minute, 0, 0]);
      tournee.dateFin = moment([date.year, date.month - 1, date.day, heureFin.hour, heureFin.minute, 0, 0]);

      tournee.technicien = this.getField('technicien').value;

      if (this.tourneeIsOver) {
        tournee.terminee = true;
      } else {
        tournee.terminee = false;
      }

      tournee.echantillons = this.echantillons;

         
      if (this.edition) {
        tournee.id = this.tournee.id;
        this.tourneesService.modifyTournee(SessionContext.get('idOrganisme'), tournee).subscribe(
          response => {
            this.toastr.success(
              this.translate.instant('page.tournees.edit.alert.modification.message', { numero: response.numero }),
              this.translate.instant('page.tournees.edit.alert.modification.title'),
              { timeOut: 10 * 1000 }
            );
            this.router.navigate(['../../planning'], { relativeTo: this.route });
          }
        );
      } else {
        this.tourneesService.createTournee(SessionContext.get('idOrganisme'), tournee).subscribe(
          response => {
            this.toastr.success(
              this.translate.instant('page.tournees.edit.alert.creation.message', { numero: response.numero }),
              this.translate.instant('page.tournees.edit.alert.creation.title'),
              { timeOut: 10 * 1000 }
            );
            this.router.navigate(['../../planning'], { relativeTo: this.route });
          }
        );
      }

    } else {
      console.warn(this.formGroup);
    }
  }
  
  enregistrer() {
    if (this.formGroup.valid) {
      const tournee = new Tournee();

      const date = this.getField('date').value;
      const heureDebut = this.getField('heureDebut').value;
      const heureFin = this.getField('heureFin').value;

      tournee.dateDebut = moment([date.year, date.month - 1, date.day, heureDebut.hour, heureDebut.minute, 0, 0]);
      tournee.dateFin = moment([date.year, date.month - 1, date.day, heureFin.hour, heureFin.minute, 0, 0]);

      tournee.technicien = this.getField('technicien').value;

      if (this.tourneeIsOver) {
        tournee.terminee = true;
      } else {
        tournee.terminee = false;
      }

      tournee.echantillons = this.echantillons;

      if (this.edition) {

        tournee.id = this.tournee.id;
        this.tourneesService.modifyTournee(SessionContext.get('idOrganisme'), tournee).subscribe(
          response => {
            this.toastr.success(
              this.translate.instant('page.tournees.edit.alert.modification.message', { numero: response.numero }),
              this.translate.instant('page.tournees.edit.alert.modification.title'),
              { timeOut: 10 * 1000 }
            );
            
            this.router.navigate(['main/controles/tournees/' + response.id + '/edit'])
            .then(() => {
              window.location.reload();
            });
          }
        );
      } else {
        this.tourneesService.createTournee(SessionContext.get('idOrganisme'), tournee).subscribe(
          response => {
            this.toastr.success(
              this.translate.instant('page.tournees.edit.alert.creation.message', { numero: response.numero }),
              this.translate.instant('page.tournees.edit.alert.creation.title'),
              { timeOut: 10 * 1000 }
            );
            this.router.navigate(['main/controles/tournees/' + response.id + '/edit'])
            .then(() => {
              window.location.reload();
            });
          }
        );
      }

    } else {
      console.warn(this.formGroup);
    }
  }

  createForm(tournee: Tournee) {

    if (this.tournee.id) {
      const selectedTechnicien = find(this.techniciens, anim => anim.id === tournee.technicien.id);
      this.formGroup = this.fb.group({
        date: [{
          year: tournee.dateDebut.toDate().getFullYear(),
          month: tournee.dateDebut.toDate().getMonth() + 1,
          day: tournee.dateDebut.toDate().getDate()
        }, Validators.required],
        heureDebut: [{ hour: tournee.dateDebut.toDate().getHours(), minute: tournee.dateDebut.toDate().getMinutes() }, heureRequiredValidator],
        heureFin: [{ hour: tournee.dateFin.toDate().getHours(), minute: tournee.dateFin.toDate().getMinutes() }, heureRequiredValidator],
        technicien: [selectedTechnicien, Validators.required],
      });

      this.echantillons = this.tournee.echantillons;

    } else {
      this.formGroup = this.fb.group({
        date: [undefined, Validators.required],
        heureDebut: [{ hour: 8, minute: 0 }, heureRequiredValidator],
        heureFin: [{ hour: 18, minute: 0 }, heureRequiredValidator],
        technicien: ['undefined', Validators.required],
      });


    }
  }


  addEchantillons() {
    
    const modalRef = this.modalService.open(RechercheEchantillonsTourneeComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.idOrganisme = SessionContext.get('idOrganisme');

    const alreadySelectedEchantillonsIds = this.echantillons.reduce((ids, ech) => {      
      return ids.concat(ech.id, ([] as number[]));
    }, ([] as number[]));

    modalRef.componentInstance.alreadySelectedEchantillonsIds = alreadySelectedEchantillonsIds;

    from(modalRef.result).subscribe((res: { echantillons?: TourneeEchantillon[] }) => {
      if (res.echantillons) {
        res.echantillons.forEach( ech => {
          ech.codeStatut = 'A_PRELEVER';
          this.indexNouveauxEchantillons.push(ech.id);
          this.echantillons.push(ech);
        });
       
      }    

    });
  }

    /**
   * Permet d'ouvrir la fenêtre surgissante d'ajout d'un prélèvement à un échantillon
   * @param echantillon L'échantillon auquel on veut ajouter un prélèvement
   * @see Echantillon
   */
    public onAddPrelevement(echantillon: TourneeEchantillon): void {
      const modal = this.modalService.open(SaisirPrelevementComponent, { backdrop: 'static' });
      modal.componentInstance.idEchantillon = echantillon.id;
      modal.componentInstance.dateTournee = this.tournee.dateDebut;
  
      modal.result.then(([]) => this.echantillons.find(ec =>{ return ec.id === echantillon.id}).codeStatut = 'PRELEVE'); 
       
    }

  removeEchantillon(ech: TourneeEchantillon) {
    const index = this.echantillons.indexOf(ech);
    this.echantillons.splice(index, 1);

    if(this.indexNouveauxEchantillons.indexOf(ech.id) === -1)
    this.tourneesService.deleteEchantillonTournee(SessionContext.get('idOrganisme'),ech).subscribe();

  }

  moveEchantillon(event: CdkDragDrop<TourneeEchantillon[]>): void {
    moveItemInArray(this.echantillons, event.previousIndex, event.currentIndex);
  } 
 

  generateIdsList(prefix: string, nb: number) {
    return Array.from(Array(nb).keys()).map(i => prefix + '-' + i);
  }

  getCVI(echantillon: TourneeEchantillon): string {
    try {
      return echantillon.infosOperateur.find(infos => !!~infos.code.indexOf('CVI')).valeur;
    } catch (e) {
      return '';
    }
  }

  getRaisonSociale(echantillon: TourneeEchantillon): string {
    try {
      return echantillon.operateur.raisonSociale;
    } catch (e) {
      return '';
    }
  }



}
