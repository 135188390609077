import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { EntrepriseBase } from './entreprise-base.model';
import { Fonction } from './fonction.model';
import { Groupe } from './groupe.models';
import { Referent } from './referent.model';

@JsonObject
export class Contact {

  @JsonProperty('id', Number)
  @Optional
  public id: number = undefined;

  @JsonProperty('mail', String)
  @Optional
  public mail: string = undefined;

  @JsonProperty('nom', String)
  @Optional
  public nom: string = undefined;

  @JsonProperty('prenom', String)
  @Optional
  public prenom: string = undefined;

  @JsonProperty('civilite', String)
  @Optional
  public civilite: string = undefined;

  @JsonProperty('entreprise', EntrepriseBase)
  @Optional
  public entreprise: EntrepriseBase = undefined;

  @JsonProperty('fonction', Fonction)
  @Optional
  public fonction : Fonction = undefined;

  @JsonProperty('telephonefixe', String)
  @Optional
  public telephonefixe: string = undefined;

  @JsonProperty('telephoneportable', String)
  @Optional
  public telephoneportable: string = undefined;

  @JsonProperty('adresse', String)
  @Optional
  public adresse: string = undefined;

  @JsonProperty('codepostal', String)
  @Optional
  public codepostal: string = undefined;

  @JsonProperty('commune', String)
  @Optional
  public commune: string = undefined;

  @JsonProperty('estintervenant', Boolean)
  @Optional
  public estintervenant: boolean = undefined;

  @JsonProperty('referents', [Referent])
  @Optional
  public referents: [Referent] = undefined;

  @JsonProperty('groupes', [Groupe])
  @Optional
  public groupes: [Groupe] = undefined;

  @JsonProperty('dateDerniereConnexion', String)
  @CustomConverter(DateConverter)
  @Optional
  dateDerniereConnexion: moment.Moment ;
}