import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, forkJoin, OperatorFunction, of, throwError} from 'rxjs';
import {map, tap, flatMap, toArray, catchError} from 'rxjs/operators';
import {JsonMapperService} from 'src/app/core/services/mapper/mapper.service';
import {Operateur} from '../../models/operateur.model';
import {DomaineSpec} from '../../models/domaine-spec.model';
import {OperateurInformationsDomaine} from '../../models/operateur-informations-domaine.model';
import {ReferencesService} from '../references/references.service';
import {OperateurDrevManquante} from '../../interfaces/operateur-drev-manquante.interface';
import {ResponseDrevManquantes} from '../../interfaces/response-drev-manquantes.interface';
import {OperateurExport} from 'src/app/data/edition/models/operateur-export.model';
import {IntervenantCategorie} from '../../models/intervenant-categorie.model';
import {Pays} from '../../models/pays.model';

interface ObjectWithOperateur {
  idOperateur: number;
  operateur: Operateur;
}

interface ObjectWithCvi {
  idOperateur;
  cvi: string;
}

class Omit<T, U> {
}


@Injectable({
  providedIn: 'root'
})
export class OperateursService {
  constructor(
    protected readonly http: HttpClient,
    protected readonly mapper: JsonMapperService,
    private readonly referencesService: ReferencesService
  ) {
  }

  public getOperateursAffilies(search: string): Observable<Operateur[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/intervenant/private/operateurs?search=${search}`),
      this.referencesService.getReferences(),
    ).pipe(
      map(([operateurs, ref]) => this.mapper.deserializeArray(operateurs, Operateur, ref))
    );
  }

  public getNombreOperateursAffilies(): Observable<number> {

    return this.http.get<number>(`/api/intervenant/private/operateurs/count`);
  }

  public getAutresOperateurs(spec: DomaineSpec, search: string): Observable<Operateur[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/intervenant/private/operateurs?spec=${spec.code}&search=${search}`),
      this.referencesService.getReferences()
    ).pipe(
      map(([operateur, ref]) => this.mapper.deserializeArray(operateur, Operateur, ref))
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side error
      if (error.status === 400 && error.error) {
        errorMessage = `Error: ${error.error.message}`;
      }
    }
    alert(errorMessage); // or use a more sophisticated notification system
    return throwError(errorMessage);
  }

  /**
   * Méthode pour créer un opérateur (afin de ne plus passer par AgriMaker)
   */
  createOperateur(operateur: any): Observable<any> {
    return this.http.post<any>('/api/intervenant/private/operateur', operateur)
      .pipe(
        catchError(this.handleError)
      );
  }

  public getOperateur(idOperateur: number): Observable<Operateur> {
    return forkJoin(
      this.http.get<object>(`/api/intervenant/private/details-operateurs/${idOperateur}`),
      this.referencesService.getReferences()
    ).pipe(
      map(([operateur, ref]) => this.mapper.deserializeObject(operateur, Operateur, ref))
    );
  }

  public getOperateursByOrganisme(idOrganisme: number): Observable<OperateurExport[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/intervenant/private/organismes/${idOrganisme}/operateurs`),
      this.referencesService.getReferences()
    ).pipe(
      map(([operateur, ref]) => this.mapper.deserializeArray(operateur, OperateurExport, ref))
    );
  }

  public getOperateurPipe<T extends ObjectWithOperateur | ObjectWithOperateur[]>() {
    return tap((list: T[]) => {
      const liste: ObjectWithOperateur[] = [].concat(list);

      liste.forEach(item => {
        this.getOperateur(item.idOperateur)
          .subscribe(operateur => item.operateur = operateur);
      });
    });
  }

  /**
   * Récupère toutes les informations Domaines pour le formulaire de création d'un opérateur
   */
  public getAllInformationsDomaine(): Observable<DomaineSpec[]> {
    return this.http.get<object[]>(`/api/intervenant/private/domaines_specs`).pipe(
      map(infos => {
        return this.mapper.deserializeArray(infos, DomaineSpec);
      })
    );
  }

  /**
   * Récupère tous les pays pour le formulaire de modifications et de création d'un opérateur
   */
  public getPays(): Observable<Pays[]> {
    return this.http.get<object[]>(`/api/intervenant/private/pays`).pipe(
      map(infos => {
        const result = this.mapper.deserializeArray(infos, Pays);
        return result;
      })
    );
  }

  /**
   * Récupère les informations domaine d'un opérateur
   * @param idOperateur l'Id de l'opérateur
   */
  public getInformationsDomaine(idOperateur: number): Observable<OperateurInformationsDomaine[]> {
    return this.http.get<object[]>(`/api/intervenant/private/details-operateurs/${idOperateur}/informations-domaine`).pipe(
      map(infos => this.mapper.deserializeArray(infos, OperateurInformationsDomaine))
    );
  }

  /**
   * Mets à jours les informations majeures d'un opérateur via le EditInformationsMajeures.component
   * Les informations sont visibles dans informations-operateur.component
   */
  public updateInformationsMajeures(idOperateur: number, data: any): Observable<any> {
    return this.http.put(`/api/intervenant/private/details-operateurs/${idOperateur}/informations-majeures`, data)
      .pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Mets à jours les informations mineures d'un opérateur via le EditInformationMineures.component
   * Les informations sont visibles dans informations-operateur.component
   */
  public updateInformationsMineures(idOperateur: number, data: any): Observable<any> {
    return this.http.put(`/api/intervenant/private/details-operateurs/${idOperateur}/informations-mineures`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /** Retourne le CVI d'un objet qui contient un ID d'opérateur */
  public getCviPipe<T extends ObjectWithCvi | ObjectWithCvi[]>() {
    return tap((list: T[]) => {
      const liste: ObjectWithCvi[] = [].concat(list);

      liste.forEach(item => {
        this.getInformationsDomaine(item.idOperateur).subscribe(infos => {
          const cvi = infos.find(info => info.code === 'VIN_CVI');
          item.cvi = (cvi) ? cvi.valeur : '';
        });
      });
    });
  }

  public getOperateursDREVManquantes(idOrganisme: number): Observable<OperateurDrevManquante[]> {
    // return this.http.get<ResponseDrevManquantes>(`/assets/mocks/operateurs-drev-manquantes.json`).pipe(
    return this.http.get<ResponseDrevManquantes>(`/api/declaration/private/drev/${idOrganisme}/manquantes`).pipe(
      flatMap(result => {
        return forkJoin(result.intervenantIds.map(id => {
          return forkJoin(
            this.getOperateur(id),
            this.getInformationsDomaine(id)
          ).pipe(
            map(result => ({operateur: result[0], informations: result[1]}))
          );
        }));
      })
    );
  }

  public countOperateursDREVManquantes(idOrganisme: number): Observable<number> {
    return this.http.get<number>(`/api/declaration/private/drev/${idOrganisme}/manquantes/count`);
  }

  public getOperateursFacturePanel(idOperateur: number, idOrganisme: number): Observable<string> {
    return this.http.get<string>(`/api/facturation/private/organismes/${idOrganisme}/operateurs/${idOperateur}/panel`,
      {responseType: 'text' as 'json'});
  }

  public getIntervenantCategorie(idIntervenant: number, idOrganisme: number): Observable<IntervenantCategorie> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<IntervenantCategorie>(`/api/intervenant/private/details-operateur/categorie/${idIntervenant}/organisme/${idOrganisme}`)
      .pipe(
        map(categ => this.mapper.deserializeObject(categ, IntervenantCategorie))
        //   catchError(error => {
        //     if (error.status === 404) {
        //       return of(null); // Cas où il n'y a pas de données pour cet ID
        //     }
        //     return throwError(() => new Error('Erreur lors de la récupération des données'));
        //   })
      );
  }

  public addIntervenantCategorie(intervenantCategorie: IntervenantCategorie): Observable<IntervenantCategorie> {
    return this.http.post<IntervenantCategorie>(`/api/intervenant/private/details-operateur/categorie`, intervenantCategorie);
  }

  public updateIntervenantCategorie(idIntervenantCategorie: number, data: IntervenantCategorie): Observable<IntervenantCategorie> {
    return this.http.put<IntervenantCategorie>(`/api/intervenant/private/details-operateur/categorie/${idIntervenantCategorie}`, data);
  }
}
