import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { RefCategorie } from './ref-categorie.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { RefTypeBalise } from './ref-type-balise.model';

@JsonObject
export class Balise {
    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('code', String)
    code: string;

    @JsonProperty('libelle', String)
    libelle: string;

    @JsonProperty('html', String)
    @Optional
    html: string;

    @JsonProperty('contenu', String)
    @Optional
    contenu: string;

    @JsonProperty('idRefCategorie', Number)
    @CustomConverter(DataIdConverterFactory.build(RefCategorie, 'refCategorieList'))
    refCategorie: RefCategorie;

    @JsonProperty('idBaliseParent', Number)
    @Optional
    idBaliseParent: number;
    baliseParent: Balise;

    @JsonProperty('idRefTypeBalise', Number)
    @CustomConverter(DataIdConverterFactory.build(RefTypeBalise, 'refTypeBaliseList'))
    refTypeBalise: RefTypeBalise;

    childrens?: Balise[];

}
