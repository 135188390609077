<div class="content">
  <div class="content-panel">
    <div class="searchBar">
      <label for="searchBar-input" translate>page.admin.utilisateur.liste.table.filtrer</label>
      <input type="text" id="searchBar-input" (ngModelChange)="searchStringUpdate.next($event)" [(ngModel)]="searchStringInput"
        placeholder="{{'label.filtre'|translate}}">
    </div>

    <div class="actions-bar" *hasRole="'INTERVENANT_UTILISATEUR_ECRITURE'">
      <button type="button" (click)="addUtilisateur()" class="btn btn-primary">Ajouter</button>
    </div>
    <div class="tableScroll">
    <table *ngIf="utilisateurs" table class="table table-striped">
      <thead class="sticky-top">
        <tr>
          <th scope="col" class="actif-column"></th>
          <th><ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'email', libelle:'page.admin.utilisateur.liste.table.header.email'}"></ng-container></th>
          <th><ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'nom', libelle:'page.admin.utilisateur.liste.table.header.nom'}"></ng-container></th>
          <th><ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'prenom', libelle:'page.admin.utilisateur.liste.table.header.prenom'}"></ng-container></th>
          <th *ngIf="!isAnpp()">
            <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'type.libelle', libelle:'page.admin.utilisateur.liste.table.header.type'}"></ng-container>
          </th>
          <th *ngIf="!isAnpp()">
            <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'dateCreation', libelle:'page.admin.utilisateur.liste.table.header.dateCreation'}"></ng-container>
          </th>
          <th *ngIf="isAnpp()"> 
            <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'entreprise.raisonSociale', libelle:'page.admin.utilisateur.liste.table.header.entreprise'}"></ng-container>
          </th>
          <th *hasRole="'INTERVENANT_UTILISATEUR_ECRITURE'"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let utilisateur of utilisateurs | filter: ['email','nom','prenom','entreprise']:searchString | orderBy:order.property:order.reverse:true">
          <td>
            <app-utilisateur-statut [utilisateur]="utilisateur"></app-utilisateur-statut>
          </td>
          <td>{{utilisateur.email}}</td>
          <td>{{utilisateur.nom}}</td>
          <td>{{utilisateur.prenom}}</td>
          <td *ngIf="!isAnpp()">{{utilisateur.type?.libelle}}</td>
          <td *ngIf="!isAnpp()">{{utilisateur.dateCreation | date:'shortDate'}}</td>
          <td *ngIf="isAnpp()">{{ utilisateur.entreprise != null ? utilisateur.entreprise.raisonSociale : '' }}</td>
          <td *hasRole="'INTERVENANT_UTILISATEUR_ECRITURE'" class="actions">
            <span *hasRole="'INTERVENANT_UTILISATEUR_LECTURE || INTERVENANT_UTILISATEUR_ECRITURE'">
              <fa-icon class="clickable-icon" icon="user-tag" (click)="editUtilisateurDroits(utilisateur)" *ngIf="!isAdmin(utilisateur) && !isAnpp() && !utilisateur.utilisateurAnpp"></fa-icon>
            </span>
            <span><fa-icon class="clickable-icon" icon="edit" (click)="editUtilisateur(utilisateur)"></fa-icon></span>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
</div>

<ng-template #tableHeader let-property="sortProperty" let-libelle="libelle">
  <th *ngIf="property" (click)="setOrder(property)" [class.sortable]="property">
    {{libelle | translate}}
    <fa-icon *ngIf="property" [icon]="getIcon(property)"></fa-icon>
  </th>
  <th *ngIf="!property">{{libelle | translate}}</th>
</ng-template>
