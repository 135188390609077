import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AdvBootstrapLoaderService} from '@adv/bootstrap-loader';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UniteCulturale} from '../../../../../../../data/declaration/models/unite-culturale.model';
import {
  UniteCulturaleService
} from '../../../../../../../data/declaration/services/parcellaire/unite-culturale.service';
import {AdvBootstrapModalService} from '@adv/bootstrap-modal';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-valid-inventaire',
  templateUrl: './valid-inventaire.component.html',
  styleUrls: ['./valid-inventaire.component.scss']
})
export class ValidInventaireComponent implements OnInit {

  formGroup: FormGroup;
  isChecked = false;
  @Input() idEntreprise: number;

  constructor(  public readonly modal: NgbActiveModal,
                private readonly loaderService: AdvBootstrapLoaderService,
                private readonly uniteCulturaleService: UniteCulturaleService,
                private readonly modalsService: AdvBootstrapModalService,
                private readonly translate: TranslateService,
                private readonly fb: FormBuilder) { }

  ngOnInit() {
  }

  validerDeclaration() {
    if (this.isChecked === false) {
      this.modalsService.error(
        this.translate.instant(`page.inventaire-verger.modals.valid-inventaire-verger.erreur`),
        this.translate.instant(`page.inventaire-verger.modals.valid-inventaire-verger.titleError`), {
          cancelText: this.translate.instant(`label.annuler`),
          windowClass: 'anpp'
        }
      );
    } else {
      this.uniteCulturaleService.validerInventaireVerger(this.idEntreprise).pipe(this.loaderService.operator()).subscribe(() => {
        this.modalsService.alert(this.translate.instant(`page.inventaire-verger.modals.valid-inventaire-verger.msgOk`), null , {windowClass: 'anpp'}).then(() => {
          this.modal.dismiss();
        });
      });

    }
  }

  cancel() {
    this.modal.dismiss();
  }

  checkValue(event: any) {
    this.isChecked = event;
  }

}
