import { JsonProperty, JsonObject, Optional, CustomConverter } from '@thorolf/json-ts-mapper';
import { Pays } from '../../intervenant/models/pays.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { Organisme } from '../../intervenant/models/organisme.model';

@JsonObject
export class Salle {
  @JsonProperty('id', Number)
  @Optional
  id: number;

  @JsonProperty('nom', String)
  @Optional
  nom: string;

  @JsonProperty('adresse', String)
  adresse: string;

  @JsonProperty('codePostal', String)
  codePostal: string;

  @JsonProperty('ville', String)
  ville: string;

  @JsonProperty('idPays', Number)
  @CustomConverter(DataIdConverterFactory.build(Pays, 'pays'))
  pays: Pays;

  @JsonProperty('cedex', String)
  @Optional
  cedex: string;

  @JsonProperty('idOrganisme', Number)
  @CustomConverter(DataIdConverterFactory.build(Organisme, 'organismes'))
  organisme: Organisme;
}
