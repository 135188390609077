import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { DroitsService } from 'src/app/data/intervenant/services/droits/droits.service';
import { UtilisateurDroit } from 'src/app/data/intervenant/models/utilisateur-droit.model';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-droits',
  templateUrl: './droits.component.html',
  styleUrls: ['./droits.component.scss']
})
export class DroitsComponent implements OnInit {



  constructor(
    private readonly droitsServices: DroitsService
  ) { }

  static routes: Routes = [
    {
      path: '',
      component: DroitsComponent,
      data: {
        role: 'ADMIN',
        menu: {
          libelle: 'menu.administration-droits',
          icon: 'users'
        }
      }
    }
  ];
  droits: UtilisateurDroit[];

  searchStringInput: string;
  searchString: string;
  searchStringUpdate = new Subject<string>();


  order: { property: string | string[], reverse: boolean } = {
    property: '__defaultOrder',
    reverse: false
  };

  ngOnInit() {
    this.searchStringUpdate.pipe(
      debounceTime(200),
      distinctUntilChanged())
      .subscribe(value => {
        this.searchString = value;
      });

    this.droitsServices.getDroits().subscribe(droits => {

      droits.forEach(d => {
        Object.defineProperty(d, '__defaultOrder', { configurable: true, enumerable: false, get() { return (this.profil.intervenant ? this.profil.intervenant.mail : 'zzz') + this.profil.domaine.libelle; } });
      });

      this.droits = droits;
    });
  }

  setOrder(property: string | string[]) {
    if (property) {
      if (property === this.order.property) {
        this.order.reverse = !this.order.reverse;
      } else {
        this.order = {
          property,
          reverse: false
        };
      }
    }
  }

  getIcon(property: string | string[]) {
    if ([].concat(property).sort().join(',') === [].concat(this.order.property).sort().join(',')) {
      return this.order.reverse ? 'sort-down' : 'sort-up';
    } else {
      return 'sort';
    }
  }


}
