import { JsonObject, JsonProperty, Any, Optional } from '@thorolf/json-ts-mapper';
import { RefUnite } from './ref-unite.model';
import { RefContenant } from './ref-contenant.model';
import { Conditionnement } from './conditionnement.model';
import { Cepage } from './cepage.model';
import { MouvementDestination } from './mouvement-destination.model';
import { RefMouvement } from './ref-mouvement.model';
import { NatureControle } from './nature-controle.model';
import { ObjetControle } from './objet-controle.model';
import { PressionDeclencheur } from './pression-declencheur.model';
import { EchantillonStatut } from './echantillon-statut.model';
import { Campagne } from './campagne.model';
import { StatutEchantillon } from './statut-echantillon.model';
import { TypeControle } from './type-controle.model';
import { StatutControle } from './statut-controle.model';
import { StatutManquement } from './statut-manquement.model';

@JsonObject
export class Referentiel {
  @JsonProperty('refUniteList', [RefUnite])
  refUniteList: RefUnite[];

  @JsonProperty('refContenantList', [RefContenant])
  refContenantList: RefContenant[];

  @JsonProperty('refMvtConditionnementList', [Conditionnement])
  conditionnements: Conditionnement[];

  @JsonProperty('refCepageList', [Cepage])
  cepages: Cepage[];

  @JsonProperty('refMvtDestinationList', [MouvementDestination])
  mouvementsDestinations: MouvementDestination[];

  @JsonProperty('refMouvementList', [RefMouvement])
  @Optional // TODO supprimer ca !
  refMouvements: RefMouvement[];

  @JsonProperty('refControleNatureList', [NatureControle])
  naturesControle: NatureControle[];

  @JsonProperty('refControleObjetList', [ObjetControle])
  objetsControle: ObjetControle[];

  @JsonProperty('refControleStatutList', [StatutControle])
  statutsControle: StatutControle[];

  @JsonProperty('refControleTypeList', [TypeControle])
  typesControle: TypeControle[];

  @JsonProperty('refPressionDeclencheurList', [PressionDeclencheur])
  pressionDeclencheurs: PressionDeclencheur[];

  @JsonProperty('refEchantillonStatutList', [EchantillonStatut])
  echantillonStatuts: EchantillonStatut[];

  @JsonProperty('refCampagneList', [Campagne])
  campagnes: Campagne[];

  @JsonProperty('refManquementStatutList', [StatutManquement])
  statutsManquement: StatutManquement[];
}
