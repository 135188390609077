import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Structure } from '../../models/structure.model';
import { RefSoustypeUtilisateur } from 'src/app/data/droits/models/ref-soustype-utilisateur.model';

@Injectable({
  providedIn: 'root'
})
export class RefSousTypesService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
    ) { }


    getRefSousType(): Observable<RefSoustypeUtilisateur[]> {
      return this.http.get<object[]>(`/api/intervenant/private/references/refsousTypes`)
        .pipe(
          map(refSousTypes => this.mapper.deserializeArray(refSousTypes, RefSoustypeUtilisateur))
        );
    }
}
