<div class="anpp">
  <div class="content">
    <div class="border-radius">
      <div class=" border-radius text-center" style="background:#fff">
        <div class="row">
          <div class="col-lg text-left pl-4 pt-4">
            <div class="row">
              <div class="col-lg-2 pl-4 pointer" [routerLink]="['./new']">
                <span style="text-decoration:underline">
                  <fa-icon icon="plus-square"></fa-icon> Ajouter une variété
                </span>
              </div>
              <div class="col-lg-2 pl-4 pointer" [routerLink]="['/main/admin/params/listes']">
                <span style="text-decoration:underline">
                  <fa-icon icon="clipboard-list"></fa-icon> <span translate>
                    page.valeur_parametrable.retour_liste</span>
                </span>
              </div>
            </div>
<div style="margin-bottom:40px" class="tableScroll tableScroll-param-height">
            <table class="table table-striped" *ngIf="varietes?.length > 0;"  >
              <thead class="sticky-top">
                <tr class="d-flex">
                  <th class="col-2" scope="col">Opérations</th>
                  <th class="col-3" scope="col">Code</th>
                  <th class="col-5" scope="col">Libellé</th>
                  <th class="col-2" scope="col">Espèce</th>
                </tr>
              </thead>
              <tbody>
                <tr class="d-flex" *ngFor="let variete of varietes ">
                  <td class="col-2" scope="row">
                    <div class="btn-group dropright  ">
                      <div ngbDropdown placement="right-start">
                        <button class="btn btn-secondary dropdown-toggle btnprimary btn-sm " id="dropdownConfig"
                          ngbDropdownToggle>
                          <fa-icon icon="cog"></fa-icon>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                          <button (click)="editVariete(variete)" ngbDropdownItem class="dropdown-item" translate>
                            <fa-icon icon="id-card"></fa-icon>
                            label.modifier
                          </button>
                          <button (click)="openConfirmationDialog(variete)" ngbDropdownItem class="dropdown-item">
                            <fa-icon icon="trash"></fa-icon>
                            Supprimer
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="col-3" scope="col">{{ variete.code }}</td>
                  <td class="col-5" scope="col">{{ variete.libelle }}</td>
                  <td class="col-2" scope="col">{{ variete.espece.libelle }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>