import { Component, OnInit } from "@angular/core";
import { Router, Routes } from "@angular/router";
import { NgbTabChangeEvent, NgbTabset } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/core/services/auth/auth.service";
import { SuivisService } from "src/app/data/suivis/services/suivis.service";
import { VergersEcoresponsablesComponent } from "../engagements/pages/vergers-ecoresponsables/vergers-ecoresponsables.component";
import { SuiviDeclarationsRecolteComponent } from "./pages/suivi-declarations-recolte/suivi-declarations-recolte.component";
import { SuiviEcoulementsComponent } from "./pages/suivi-ecoulements/suivi-ecoulements.component";
import { SuiviInventairesVergersComponent } from "./pages/suivi-inventaires-vergers/suivi-inventaires-vergers.component";
import { SuiviStocksComponent } from "./pages/suivi-stocks/suivi-stocks.component";

@Component({
  selector: 'app-suivis',
  templateUrl: './suivis.component.html',
  styleUrls: ['./suivis.component.scss']
})
export class SuivisComponent implements OnInit {

  public static routesAnpp: Routes = [
    {
      path: '',
      	component: SuivisComponent,
        data: {
        role: 'ORGANISME || GP',
        domaines: ["ANPP"],
        menu: {
          icon: 'chart-line',
          libelle: 'menu.suivis'
        }
      }, children: [
        
        { path: 'suivi-inventaires-vergers', children: SuiviInventairesVergersComponent.routes },
        { path: 'suivi-recoltes', children: SuiviDeclarationsRecolteComponent.routes },
        { path: 'suivi-engagements-vergers-ecoresponsables', children: VergersEcoresponsablesComponent.routes },
        { path: 'suivi-stocks', children: SuiviStocksComponent.routes },
        { path: 'suivi-ecoulements', children: SuiviEcoulementsComponent.routes }
      ]
    }
  ];

  activeIdString: string;

  constructor(private readonly router: Router,
    //private readonly route: ActivatedRoute,
    public readonly suivisService: SuivisService,
    private readonly authService: AuthService,
    private readonly translate: TranslateService,) {
  }

  ngOnInit() {
    if(!this.isAnpp()){
      //this.declarationService.entrepriseSelectionnee=null;
    }

  }


  public onTabChange($event: NgbTabChangeEvent, tabset: NgbTabset): void {

    let entrepriseUrl = "";
    // if (this.declarationService.entrepriseSelectionnee) {
    //   entrepriseUrl = "/" + this.declarationService.entrepriseSelectionnee.id;
    // }
    switch($event.nextId){
      case "vergers":
        this.router.navigate(['/main/suivi/suivi-inventaires-vergers']);
        break;
      case "recoltes":
        this.router.navigate(['/main/suivi/suivi-recoltes']);
        break;
      case "stocks":
        this.router.navigate(['/main/suivi/suivi-stocks']);
        break;
      case "ecoulements":
        this.router.navigate(['/main/suivi/suivi-ecoulements']);
        break;
      case "vergersEcoresponsables":
        this.router.navigate(['/main/suivi/suivi-engagements-vergers-ecoresponsables']);
        break;
    }

  }

  isAnpp() {
    return this.authService.isAnpp();
  }

}
