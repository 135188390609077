<div class="anpp content">
  <div class="content-panel border-radius">
    <div class="border-radius text-center" style="background:#fff">
      <div class="row">
        <div class="col-lg text-left pl-4 pt-4">
          <a class="pl-4" [routerLink]="['./new']">
            <span style="text-decoration:underline">
              <fa-icon icon="plus-square"></fa-icon> Ajouter une interaction
            </span></a>
          <a>
            <span id="bar1" class="pl-4" (click)="afficheFiltre()" style="text-decoration:underline">
              <fa-icon icon="filter"></fa-icon> Filtrer
            </span></a>
        </div>
        <div class="col-lg text-right pt-2 pr-4">
          <fa-icon icon="upload" class="clickable-icon" style="font-size:25px" (click)="exporterInteractions()"></fa-icon>

        </div>

        
      </div>
      <div class="tableScroll">
      <table class="table table-striped" *ngIf="interactions?.length > 0; else aucuneInteraction">
        <thead class="sticky-top">
          <tr>
            <th scope="col">Opérations</th>
            <th scope="col" style="position:relative">
              <div translate>page.interactions.table.date </div>
              <div *ngIf="!filtrer;" class="row">
                 <div class="col-6">
                  <div class="input-group">
                    <input id="dateDebutFilter" ngbDatepicker #dateDebutpicker="ngbDatepicker"
                      [(ngModel)]="dateDebutFilter" (ngModelChange)="filter()" (click)="dateDebutpicker.toggle()"
                      placeholder="Date de début" />
                    <div class="input-group-append">
                      <button type="button" (click)="dateDebutpicker.toggle()" class="btn btn-outline-secondary">
                        <fa-icon icon="calendar-alt"></fa-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group">
                    <input id="dateFinFilter" ngbDatepicker #dateFinpicker="ngbDatepicker" [(ngModel)]="dateFinFilter"
                      (ngModelChange)="filter()" (click)="dateFinpicker.toggle()" placeholder="Date de fin" />
                    <div class="input-group-append">
                      <button type="button" (click)="dateFinpicker.toggle()" class="btn btn-outline-secondary">
                        <fa-icon icon="calendar-alt"></fa-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </th>             
            <th scope="col" style="position:relative">
              <div translate>page.interactions.table.contact </div>
              <div *ngIf="!filtrer;">
                <input type="text" [id]="nomPrenom" class="form-control bar1" [(ngModel)]="nomPrenomFilter"
                  (keyup)="filter()">
              </div>
            </th>
            <th scope="col" style="position:relative">
              <div translate>page.interactions.table.raisonSociale </div>
              <div *ngIf="!filtrer;">
                <input type="text" [id]="raisonSociale" class="form-control bar1" [(ngModel)]="raisonSocialeFilter"
                  (keyup)="filter()">
              </div>
            </th>
            <th scope="col" style="position:relative">
              <div translate>page.interactions.table.contactAnpp </div>
              <div *ngIf="!filtrer;">
                <input type="text" [id]="nomPrenomAnpp" class="form-control bar1" [(ngModel)]="interlocuteurFilter"
                  (keyup)="filter()">
              </div>
            </th>
            <th scope="col" style="position:relative">
              <div translate>page.interactions.table.typeInteraction </div>
              <div *ngIf="!filtrer;">
                <select id="refTypeInteractions" class="custom-select bar1" [(ngModel)]="typeInteractionFilter" (change)="filter()">
                  <option></option>
                  <option *ngFor="let typeInteraction of refTypeInteractions" [ngValue]="typeInteraction">{{typeInteraction.libelle}}</option>
                </select>
                
              </div>
            </th>
            <th scope="col" style="position:relative">
              <div translate>page.interactions.table.sujet </div>
              <div *ngIf="!filtrer;">
                <input type="text" [id]="sujet" class="form-control bar1" [(ngModel)]="sujetFilter" (keyup)="filter()">
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let interaction of interactionFilter ">
            <td>
              <div class="btn-group dropright  ">
                <div ngbDropdown placement="right-start">
                  <button class="btn btn-secondary dropdown-toggle btnprimary btn-sm " id="dropdownConfig"
                    ngbDropdownToggle>
                    <fa-icon icon="cog"></fa-icon>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                    <button (click)="editInteraction(interaction)" ngbDropdownItem class="dropdown-item">
                      <fa-icon icon="id-card"></fa-icon>
                      Consulter l'interaction
                    </button>
                    <button (click)="openConfirmationDialog(interaction)" ngbDropdownItem class="dropdown-item">
                      <fa-icon icon="trash"></fa-icon>
                      Supprimer l'interaction
                    </button>
                  </div>
                </div>
              </div>
            </td>
            <td>{{ interaction.dateInteraction | date : 'medium' }}</td>
            <td>{{ interaction.contact?.nom }} {{ interaction.contact?.prenom }}</td>
            <td>{{ interaction.contact?.raisonsociale }}</td>
            <td>{{ interaction.contact_anpp?.nom }} {{ interaction.contact_anpp?.prenom }}</td>
            <td>{{ interaction.refTypeInteraction?.libelle }}</td>
            <td>{{ interaction.sujetInteraction }}</td>

          </tr>
        </tbody>
      </table>
      </div>
      <ng-template #aucuneInteraction>
        <div translate>page.interactions.aucune_interaction</div>
      </ng-template>

    </div>
  </div>
</div>