import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map, skipWhile, tap } from "rxjs/operators";
import { SessionContext } from "src/app/core/services/config/app.settings";
import { GedOngletEnum } from "../models/enums/ged-onglet.enum";
import { GedFichier } from "../models/ged-fichier.model";
import { RefTypeFichier } from "../models/ref-type-fichier.model";
import { EditionService } from "../services/edition.service";

@Injectable({ providedIn: 'root' })
export class GedFacade {

  private idIntervenant: number;
  private idEntreprise: number;
  private codeRefSousType: string;

  private fichiers$ = new BehaviorSubject<Array<GedFichier>>([]);

  public fichiersPubliques$: Observable<Array<GedFichier>> = this.fichiers$.asObservable().pipe(
    skipWhile((fichiers: Array<GedFichier>) => fichiers == null),
    distinctUntilChanged(),
    map((fichiers: Array<GedFichier>) => {
      const fichiersPubliques = fichiers.filter((fichier: GedFichier) => fichier.estPublic && !fichier.estArchive);
      return fichiersPubliques == null ? [] : fichiersPubliques;
    })
  );

  public fichiersPrives$: Observable<Array<GedFichier>> = this.fichiers$.asObservable().pipe(
    skipWhile((fichiers: Array<GedFichier>) => fichiers == null),
    distinctUntilChanged(),
    map((fichiers: Array<GedFichier>) => {
      const fichiersPrives = fichiers.filter((fichier: GedFichier) => !fichier.estPublic && !fichier.estArchive);
      return fichiersPrives == null ? [] : fichiersPrives;
    }),
  );

  public fichiersArchives$: Observable<Array<GedFichier>> = this.fichiers$.asObservable().pipe(
    skipWhile((fichiers: Array<GedFichier>) => fichiers == null),
    distinctUntilChanged(),
    map((fichiers: Array<GedFichier>) => {
      const fichiersArchives = fichiers.filter((fichier: GedFichier) => fichier.estArchive);
      return fichiersArchives == null ? [] : fichiersArchives;
    })
  );

  public refTypesFichier$ = new BehaviorSubject<Array<RefTypeFichier>>([]);

  public selectedTab$ = new BehaviorSubject<GedOngletEnum>(GedOngletEnum.PUBLIC);

  constructor(private editionService: EditionService) {}

  public loadFiles(): void {
    this.fichiers$.next([]);
    this.editionService.getDocuments(this.idIntervenant, this.idEntreprise, this.codeRefSousType)
      .pipe(
        tap((fichiers: Array<GedFichier>) => fichiers.forEach((fichier: GedFichier) => fichier.currentVersion = fichier.getVersion())),
        map((fichiers: Array<GedFichier>) => this.fichiers$.next(fichiers))
      )
      .subscribe();
  }

  public loadRefTypesFichier(): void {
    this.editionService.getRefTypesFichier(this.idIntervenant).subscribe(
      (refTypesFichier: Array<RefTypeFichier>) => this.refTypesFichier$.next(refTypesFichier)
    );
  }

  public postFile(fichierDto: GedFichier): Observable<void> {
    return this.editionService.postFile(this.idIntervenant, fichierDto);
  }

  public patchFile(fichierDto: GedFichier): Observable<void> {
    return this.editionService.patchFile(this.idIntervenant, fichierDto);
  }

  public putFile(fichierDto: GedFichier): Observable<void> {
    return this.editionService.putFile(this.idIntervenant, fichierDto);
  }

  public postUploadFile(file: File): Observable<string> {
    return this.editionService.postUploadFile(this.idIntervenant, file);
  }

  public putUploadFile(file: File, uuid: string): Observable<void> {
    return this.editionService.putUploadFile(this.idIntervenant, file, uuid);
  }

  public downloadFile(uuid: string): void {
    this.editionService.downloadFile(this.idIntervenant, uuid);
  }

  public archiveFile(id: number): void {
    this.editionService.archiveFile(this.idIntervenant, id).subscribe(() => this.loadFiles());
  }

  public changeSelectedTab(gedOngletTab: GedOngletEnum): void {
    this.selectedTab$.next(gedOngletTab);
  }

  public setIdIntervenant(idIntervenant: number): void {
    this.idIntervenant = idIntervenant;
  }

  public setIdEntreprise(idEntreprise: number): void {
    this.idEntreprise = idEntreprise;
  }

  public setCodeRefSousType(codeRefSousType: string): void {
    this.codeRefSousType = codeRefSousType;
  }

}
