import {CustomConverter, JsonObject, JsonProperty, Optional} from '@thorolf/json-ts-mapper';
import {DateConverter} from '../../../core/services/mapper/converters';
import * as moment from 'moment/moment';
import {DataIdConverterFactory} from '../../../core/services/mapper/data-id.converter';
import {Operateur} from '../../intervenant/models/operateur.model';
import {College} from './college.model';

@JsonObject
export class ComQualif {
  @JsonProperty('id', Number)
  @Optional
  id: number;

  @JsonProperty('idJure', Number)
  idJure: number;

  @JsonProperty('dateFormationDer', String)
  @CustomConverter(DateConverter)
  @Optional
  dateFormationDer: moment.Moment;

  @JsonProperty('dateFormationInit', String)
  @CustomConverter(DateConverter)
  @Optional
  dateFormationInit: moment.Moment;

  @JsonProperty('dateFin', String)
  @CustomConverter(DateConverter)
  @Optional
  dateFin: moment.Moment;

  @JsonProperty('idCahier', Number)
  idCahier: number;

  @JsonProperty('idIntervenants', [Number])
  @Optional
  idsOperateurs: number[];

  @JsonProperty('idRefColleges', [Number])
  @CustomConverter(DataIdConverterFactory.build(College, 'colleges'))
  @Optional
  colleges: College[] = [];

  @Optional
  operateurs: Operateur[] = [];
}
