import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeclarationEcoulement } from '../../models/declaration-ecoulement.model';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { HttpClient } from '@angular/common/http';
import { Ecoulement } from '../../models/ecoulement.model';
import { SaisieEcoulement } from '../../models/saisie-ecoulement.model';
import { SuiviEcoulement } from 'src/app/data/suivis/models/suivi-ecoulement.model';
import { EstimationEcoulement } from 'src/app/data/suivis/models/estimation-ecoulement.model';

@Injectable({
  providedIn: 'root'
})
export class EcoulementsService {

  constructor(protected readonly http: HttpClient,
    protected readonly mapper: JsonMapperService) { }


    /**
   * appel back récupération des écoulements d'une entreprise
   * @param idEntreprise 
   * @param idCampagne 
   * @returns 
   */
     getEcoulements(idEntreprise: number, idCampagne: number, numMois: number): Observable<DeclarationEcoulement> {
      return this.http.get<object[]>(`/api/declaration/private/ecoulement?idEntreprise=${idEntreprise}&idCampagne=${idCampagne}&numMois=${numMois}`
      ).pipe(
        map(response => this.mapper.deserializeObject(response, DeclarationEcoulement)),
      );
    }


      /**
   * appel back sauvegarde des écoulements d'une entreprise
   * @param entrepriseId 
   * @param campagneId 
   * @param ecoulements
   * @returns 
   */
  public saveEcoulements(entrepriseId: number, campagneId: number, numMois: number, ecoulements: Ecoulement[]): Observable<void> {
    let declaration :DeclarationEcoulement = new DeclarationEcoulement();
    declaration.numMois = numMois;
    declaration.idEntreprise = entrepriseId;
    declaration.idRefCampagne = campagneId;
    declaration.ecoulements = ecoulements.filter(saisie => !saisie.isTotal && (saisie.qteTotaleExport || saisie.qteTotaleFrance || saisie.qteTotaleFreinte || saisie.qteTotaleIndustrie
          || saisie.qteBioExport || saisie.qteBioFrance || saisie.qteBioFreinte || saisie.qteBioIndustrie ));

    return this.http.post<void>(`/api/declaration/private/ecoulement`, declaration);
  }

  /**
   * 
   * @param id 
   * @param numMois 
   */
  public getSuivisEcoulements(idCampagne: number, numMois: any): Observable<SuiviEcoulement[]> {
    return this. http.get<SuiviEcoulement[]>(`/api/declaration/private/suivi-ecoulement?idCampagne=${idCampagne}&numMois=${numMois}`)
  }

  public getEstimationEcoulements(): Observable<EstimationEcoulement[]> {
    return this. http.get<EstimationEcoulement[]>(`/api/declaration/private/estimation-ecoulement`)
    .pipe(
      map(estimation => this.mapper.deserializeArray(estimation, EstimationEcoulement)))
  }
}
