import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EChartOption } from 'echarts';
import { forkJoin } from 'rxjs';
import { DashBoardInventaire } from 'src/app/data/declaration/models/dashboard-inventaire.model';
import { DashboardService } from 'src/app/data/declaration/services/dashboard/dashboard.service';
import { RefCampagnesService } from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';
import { EngagementGraph } from 'src/app/data/habilitation/models/engagements/engagement-graph';
import { EngagementStats } from 'src/app/data/habilitation/models/engagements/engagement-stats';
import { EngagementVergersEcoService } from 'src/app/data/habilitation/services/engagements/engagement-vergers-eco.service';
import { InteractionAccueil } from 'src/app/data/intervenant/models/accueil-anpp/interaction-accueil.model';
import { NombreAdherents } from 'src/app/data/intervenant/models/accueil-anpp/nombre-adherents';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { DashboardIntervenantService } from 'src/app/data/intervenant/services/dashboard/dashboard.service';


@Component({
  selector: 'app-anpp-organisme',
  templateUrl: './anpp-organisme.component.html',
  styleUrls: ['./anpp-organisme.component.scss']
})
export class AnppOrganismeComponent implements OnInit {

  chartVerger: EChartOption;
  nombreAdherents: NombreAdherents;
  nombretotalAdherents: number;
  surfacePomme: number;
  surfacePoire: number;
  inventairesVerger: DashBoardInventaire[];
  engagementVerStats: EngagementStats;
  graphValues: EngagementGraph[];
  pourcentageCotisation: number;
  interactions: InteractionAccueil[];

  constructor(private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly dashboardIntervenantService: DashboardIntervenantService,
    private readonly dashboardDeclarationService: DashboardService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly engagementVergersEcoService: EngagementVergersEcoService,
    private readonly refCampagnesService: RefCampagnesService) { }

  ngOnInit() {
    this.dashboardIntervenantService.getNombreAdherents().pipe(this.loaderService.operator()).subscribe(result => {
      this.nombreAdherents = result;
      this.nombretotalAdherents = this.nombreAdherents.nombreProducteursIndependants + this.nombreAdherents.nombreProducteursOrganises;
    });

    this.dashboardIntervenantService.getDernieresInteractions().pipe(this.loaderService.operator()).subscribe(interactions => {
      this.interactions = interactions;
    });
    const date = new Date();
    forkJoin([this.refCampagnesService.getCampagneVerEnCours(),
    this.dashboardIntervenantService.getPourcentageCotisationsAJour()])
    .pipe(this.loaderService.operator()).subscribe(([campagneEngagementEnCours, pourcentageCotisation]) => {
      this.pourcentageCotisation = pourcentageCotisation;
      forkJoin([this.engagementVergersEcoService.getEngagementVerStats(campagneEngagementEnCours.code),
      this.engagementVergersEcoService.getEngagementGraphValues(campagneEngagementEnCours.code)
      ]).pipe(this.loaderService.operator()).subscribe(([stats, graphValues]) => {
        this.engagementVerStats = stats;
        this.graphValues = graphValues;
        if(graphValues && graphValues.length > 0){
          this.loadChartVerger();
        }
      });
    });


    this.dashboardDeclarationService.getSurfaces().subscribe(async surfaces => {
      const pomme = surfaces.find(dec => dec.codeEspece === 'POMME');
      const poire = surfaces.find(dec => dec.codeEspece === 'POIRE');

      if (pomme) {
        this.surfacePomme = pomme.surface
      }
      if (poire) {
        this.surfacePoire = poire.surface;
      }

    });

    this.dashboardDeclarationService.getDerniersInventaireVerger().pipe(this.loaderService.operator()).subscribe(inventaires => {
      this.inventairesVerger = inventaires;
    });

  }
  loadChartVerger() {
    this.chartVerger = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: "2%",
        orient: "vertical",
        right: "10px" 
      },
      series: [
        {
          name: 'VER',
          type: 'pie',
          radius: ['30%', '60%'],
          center: ['30%', '40%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: this.getGraphData()
        }
      ]
    };
  }
  getGraphData() {
    let data: any[] = [];
    this.graphValues.forEach(graphValue => {
      if (graphValue.value !== 0) {
        data.push({ value: graphValue.value, name: graphValue.libelleRefStatut, itemStyle: { color: this.getColorValue(graphValue) } })
      }

    });

    return data;
  }

  searchEntreprise(entreprise: Entreprise) {
    this.router.navigate([`${entreprise.id}`], { relativeTo: this.route });
  }

  goToEngagementVer(): void {
    this.router.navigate(['main/engagements/vergers-ecoresponsables']);
  }

  getColorValue(graphValue: EngagementGraph): String {
    let color: string = '';
    switch (graphValue.codeRefStatut) {
      case 'DP':
        color = '#FCF3CF';
        break;
      case 'DEOC':
        color = '#FAD7A0';
        break;
      case 'AR':
        color = '#ABEBC6';
        break;
      case 'C':
        color = '#2ECC71';
        break;
      case 'NC':
        color = '#C0392B';
        break;
      case 'LNC':
        color = '#C39BD3';
        break;
      case 'AE':
        color = '#186A3B ';
        break;
      case 'ABE':
        color = '#7B7D7D';
        break;
    }
    return color;
  }

}
