import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Referentiel } from '../../models/referentiel.model';
import { RefUnite } from '../../models/ref-unite.model';
import { ClearCache, Cache } from 'src/app/core/services/cache/cache.service';
import { StatutEchantillon } from '../../models/statut-echantillon.model';

@Injectable({
  providedIn: 'root'
})
export class ReferentielService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
  ) { }

  @ClearCache
  clearCache() { }

  @Cache({
    maxAge: 3600
  })
  getReferentiel(): Observable<Referentiel> {
    return this.http.get<object>(`/api/declaration/private/referentiel`).pipe(
      // return this.http.get<object>(`/assets/mocks/referentiel.json`).pipe(
      map((referentiel: any) => {
        const refUniteList = this.mapper.deserializeArray(referentiel.refUniteList, RefUnite);

        return this.mapper.deserializeObject(referentiel, Referentiel, { refUnite: refUniteList });
      })
    );
  }
}
