import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormGroup } from '@angular/forms';
import { EditionService } from 'src/app/data/edition/services/edition.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { HabilitationsService } from 'src/app/data/habilitation/services/habilitations/habilitations.service';
import { Habilitation } from 'src/app/data/habilitation/models/habilitation.model';
import { Activite } from 'src/app/data/habilitation/models/activite.model';
import { Cahier } from 'src/app/data/habilitation/models/cahier.model';
import { forkJoin } from 'rxjs';
import { ActivitesService } from 'src/app/data/habilitation/services/activites/activites.service';
import { CahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Operateur } from 'src/app/data/intervenant/models/operateur.model';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { OperateurExport } from 'src/app/data/edition/models/operateur-export.model';
import * as moment from 'moment';
import { OrganismesService } from 'src/app/data/intervenant/services/organismes/organismes.service';
import { Organisme } from 'src/app/data/intervenant/models/organisme.model';
import { ExportToCsv } from 'export-to-csv';
import { HabilitationExport } from 'src/app/data/edition/models/habilitation-export.model';
import { StatutHabilitation } from 'src/app/data/habilitation/models/statut-habilitation.model';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-generation-csv',
  templateUrl: './generation-csv.component.html',
  styleUrls: ['./generation-csv.component.scss']
})
export class GenerationHabilitationsComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  formGroup: FormGroup;
  habilitations: HabilitationExport[];
  activites: Activite[];
  cahiers: Cahier[];
  operateurs: OperateurExport[];
  organisme: Organisme;

  constructor(
    private readonly habilitationService: HabilitationsService,
    private readonly activitesService: ActivitesService,
    private readonly cahiersService: CahiersService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly operateursService: OperateursService,
    private readonly organismeService: OrganismesService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    forkJoin(
      this.activitesService.getActivites(),
      this.cahiersService.getCahiers(),
      this.habilitationService.getOrganismeHabilitations(SessionContext.get('idOrganisme')),
      this.operateursService.getOperateursByOrganisme(SessionContext.get('idOrganisme')),
      this.organismeService.getOrganisme(SessionContext.get('idOrganisme'))
    )
      .pipe(this.loaderService.operator())
      .subscribe(
        result => {
          [this.activites, this.cahiers, this.habilitations, this.operateurs, this.organisme] = result;
          this.habilitations.forEach(h => {
            h.operateur = this.operateurs.find(o => o.id == h.idOperateur);
            h.operateur.nom ? h.operateur.nom : undefined;
            h.operateur.telephoneMobile ? h.operateur.telephoneMobile : undefined;
            h.operateur.telephoneFixe ? h.operateur.telephoneFixe : undefined;
          });

          this.exporterHabilitations();
          this.router.navigate(['../../home'], { relativeTo: this.route });
        }
      );
  }

  getDateDebut(habilitation: HabilitationExport) {
    let statutHabilitation: StatutHabilitation;
    let retour: string;
    if (habilitation.historique) {
      statutHabilitation = habilitation.historique.find(histo => histo.statut.id === habilitation.statut.id);

      if (statutHabilitation) {
        if (statutHabilitation.dateDebut) {
          retour = statutHabilitation.dateDebut.format('DD/MM/YYYY');
        } else { retour = ''; }
      } else { retour = ''; }
    }
    return retour;
  }

  getCommentaire(habilitation: HabilitationExport) {
    let statutHabilitation: StatutHabilitation;
    let retour: string;
    if (habilitation.historique) {
      statutHabilitation = habilitation.historique.find(histo => histo.statut.id === habilitation.statut.id);

      if (statutHabilitation) {
        if (statutHabilitation.commentaire) {
          retour = statutHabilitation.commentaire;
        } else { retour = ''; }
      } else { retour = ''; }
    }
    return retour;
  }

  exporterHabilitations(): void {
    const data = [];
    this.habilitations.forEach(habilitation => {

      this.habilitations.forEach(h => {
        h.operateur = this.operateurs.find(operateur => operateur.id === h.idOperateur);
      });

      habilitation.operateur = this.operateurs.find(o => o.id == habilitation.idOperateur);


      data.push({
        refOCPC: '',
        refOCODG: this.organisme.raisonSociale ? this.organisme.raisonSociale : '' + ' - ' + habilitation.cahier.libelle ? habilitation.cahier.libelle : '',
        identifiantEmetteur: '',
        dateExtraction: moment().format('DD/MM/YYYY'),
        codeClientInterne: '',
        nomEntreprise: habilitation.operateur.raisonSociale ? habilitation.operateur.raisonSociale : '',
        raisonSocialeEtablissement: habilitation.operateur.raisonSociale ? habilitation.operateur.raisonSociale : '',
        codeSubstitution: '',
        numeroSiret: habilitation.operateur.siret ? habilitation.operateur.siret : '',
        numeroEvv: habilitation.operateur.cvi ? habilitation.operateur.cvi : '',
        // nomResponsable: habilitation.operateur.nom && habilitation.operateur.prenom ? habilitation.operateur.nom : '' + ' ' + habilitation.operateur.prenom ? habilitation.operateur.prenom : '',
        nomResponsable: !habilitation.operateur.nom && !habilitation.operateur.prenom ? '' : habilitation.operateur.nom && !habilitation.operateur.prenom ? habilitation.operateur.nom : !habilitation.operateur.nom && habilitation.operateur.prenom ? habilitation.operateur.prenom : habilitation.operateur.nom + ' ' + habilitation.operateur.prenom,
        qualiteResponsable: '',
        localisation: '',
        numeroLibelleVoie: habilitation.operateur.adresse ? habilitation.operateur.adresse : '',
        bpCommune: '',
        codePostal: habilitation.operateur.ville ? habilitation.operateur.ville : '',
        commune: habilitation.operateur.codePostal ? habilitation.operateur.codePostal : '',
        numeroTelephone: habilitation.operateur.telephoneMobile ?
          habilitation.operateur.telephoneMobile :
          habilitation.operateur.telephoneFixe ? habilitation.operateur.telephoneFixe : '',
        numeroFax: habilitation.operateur.fax ? habilitation.operateur.fax : '',
        email: habilitation.operateur.email,
        libelleActiviteOC: habilitation.activite.libelle ? habilitation.activite.libelle : '',
        codeActivite: '',
        datePremiereHabilitation: habilitation.datePremiereHabilitation ? habilitation.datePremiereHabilitation.format('DD/MM/YYYY') : '',
        etatHabilitation: habilitation.statut ? habilitation.statut.libelle : '',
        dateDecision: this.getDateDebut(habilitation),
        observationEventuelle: this.getCommentaire(habilitation)
      });
    });


    const options = {
      filename: 'EDI_INAO_HAB'.concat('_').concat(moment().format('YYYYMMDD')),
      fieldSeparator: ';',
      quoteStrings: '',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'EDI_INAO_HAB'.concat('_').concat(moment().format('YYYYMMDD')),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        'Référence OC du PC',
        'Références OC ODG + cahiers des charges (associés au PC)',
        'Identifiant de l\'OC émetteur',
        'Date d’extraction',
        'Code "client" interne',
        'Nom entreprise',
        'Raison sociale établissement',
        'Code substitution',
        'N° SIRET',
        'N° EVV',
        'Nom du responsable',
        'Qualité du responsable',
        'Localisation',
        'N° et libellé de la voie',
        'BP et commune si différente',
        'Code postal',
        'Commune',
        'N° de téléphone',
        'N° de fax',
        'E-mail',
        'Libellé OC de l\'activité',
        'Code activité',
        'Date de 1ère habilitation ou de DI',
        'État d\'habilitation',
        'Date de décision (suspension / retrait /...)',
        'Observation éventuelle'
      ]
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);

  }
}
