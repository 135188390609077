<div class="content">
  <div class="content-panel">
    <h2>{{pageTitle}}</h2>

    <form [formGroup]="formUpdateJure" (ngSubmit)="modifierJure()">

      <!-- Colonne de gauche -->
      <div class="col-md-12 row">
        <div class="form-group col-md-6 row">
          <label for="nom" class="col-md-4" translate>page.admin.jure.update.nom</label>
          <input type="text" class="form-control col-md-6" id="nom" formControlName="nom"/>
        </div>
        <div class="form-group col-md-6 row">
          <label for="mail" class="col-md-4" translate>page.admin.jure.update.mail</label>
          <input type="email" class="form-control col-md-6" id="mail" formControlName="mail"/>
        </div>


      </div>
      <!-- Fin colonne de gauche -->

      <!-- Colonne de droite -->
      <div class="col-md-12 row">
        <div class="form-group col-md-6 row">
          <label for="telFixe" class="col-md-4" translate>page.admin.jure.update.telFix</label>
          <input type="text" class="form-control col-md-6" id="telFixe" formControlName="telFixe"/>
        </div>
        <div class="form-group col-md-6 row">
          <label for="telPortable" class="col-md-4" translate>page.admin.jure.update.telPortable</label>
          <input type="text" class="form-control col-md-6" id="telPortable" formControlName="telPortable"/>
        </div>
      </div>
      <!-- Fin colonne de droite -->


      <!-- Boutons d'action formulaire -->
      <div class="actions-bar">
        <button type="button" class="btn btn-light" (click)="onClose()" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary ml-2" translate>label.valider</button>
      </div>
      <!-- Fin boutons d'action formulaire -->
    </form>
    <div class="actions-bar">
      <button type="button" (click)="addQualification()" class="btn btn-primary">Ajouter une qualification</button>
    </div>
    <table class="table tableQualifications" *ngIf="qualifications?.length > 0; else aucuneQualification">
      <caption>Qualifications actives</caption>
      <thead>
      <th class="cdc-col" translate>page.admin.jure.update.tableQualification.cdcs</th>
      <th translate>page.admin.jure.update.tableQualification.dateFormationInitiale</th>
      <th translate>page.admin.jure.update.tableQualification.dateDerniereFormation</th>
      <th translate>page.admin.jure.update.tableQualification.college</th>
      <th translate>page.admin.jure.update.tableQualification.operateur</th>
      <th class="actions" translate>page.admin.jure.update.tableQualification.actions</th>
      </thead>

      <tbody>
      <tr *ngFor="let qualification of qualifications">
        <td class="cdc-col" >{{ libelles[qualification.idCahier] }}</td>
        <td>{{ qualification.dateFormationInit | date:'dd/MM/yyyy' }}</td>
        <td>{{ qualification.dateFormationDer ? (qualification.dateFormationDer | date: 'dd/MM/yyyy') : '' }}</td>
        <td>{{ getCollegesLibelle(qualification) }}</td>
        <td>{{ getRaisonSocialeOperateurs(qualification) }}</td>
        <td class="actions">
            <span>
              <button type="button" class="btn btn-outline-secondary btn-add"
                      (click)="edit(qualification)">
                <fa-icon icon="edit" class="primary-icon" title="Modifier la qualification"></fa-icon>
              </button>
              <button style="margin-left: 1em;" type="button" class="btn btn-outline-secondary btn-add"
                      (click)="delete(qualification.idJure, qualification)">
                <fa-icon icon="trash" class="primary-icon" title="Supprimer la qualification"></fa-icon>
              </button>
            </span>
        </td>
      </tr>
      </tbody>
    </table>

    <table class="table" *ngIf="qualificationsInactives?.length > 0; else aucuneQualificationInactive">
      <caption>Qualifications inactives</caption>
      <thead>
      <th class="cdc-col"  translate>page.admin.jure.update.tableQualificationsInactives.cdcs</th>
      <th translate>page.admin.jure.update.tableQualificationsInactives.dateFin</th>
      <th translate>page.admin.jure.update.tableQualificationsInactives.college</th>
      <th class="actions" translate>page.admin.jure.update.tableQualificationsInactives.actions</th>
      </thead>

      <tbody>
      <tr *ngFor="let qualificationInactive of qualificationsInactives">
        <td class="cdc-col" >{{ libelles[qualificationInactive.idCahier] }}</td>
        <td>{{ qualificationInactive.dateFin ? (qualificationInactive.dateFin | date: 'dd/MM/yyyy') : '' }}</td>
        <td>{{ getCollegesLibelle(qualificationInactive) }}</td>
        <td class="actions">
            <span>
              <button type="button" class="btn btn-outline-secondary btn-add"
                      (click)="reactivate(qualificationInactive)">
                <fa-icon icon="undo" class="primary-icon" title="Réactiver la qualification"></fa-icon>
              </button>
            </span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #aucuneQualification>
  <div class="alert alert-warning alert-block" translate>
    <fa-icon icon="exclamation"></fa-icon>
    page.admin.jure.update.tableQualification.aucuneQualification
  </div>
</ng-template>

<ng-template #aucuneQualificationInactive>
  <div class="alert alert-warning alert-block" translate>
    <fa-icon icon="exclamation"></fa-icon>
    page.admin.jure.update.tableQualificationsInactives.aucuneQualificationInactive
  </div>
</ng-template>
