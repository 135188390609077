<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
      {{pageTitle}}
    </h4>
  </div>

  <div class="modal-body">
    <form [formGroup]="formQualification" *ngIf="formQualification" #form="ngForm" (submit)="onSubmit()">
      <div class="form-group">
        <label for="cdc" class="col-form-label" translate>page.admin.jure.qualifications.cdc</label>
        <select id="cdc" formControlName="cdc" class="form-control">
          <option [value]="null" hidden></option>
          <option *ngFor="let cdc of cdcs" [ngValue]="cdc">{{cdc.libelle}}</option>
        </select>

      </div>
      <div class="form-group">
        <label for="dateFormationIni" translate>page.admin.jure.qualifications.dateFormationInitiale</label>
        <div class="input-group">
          <input id="dateFormationIni" ngbDatepicker #dateFormationInit="ngbDatepicker" formControlName="dateFormationIni" class="form-control"/>
          <div class="input-group-append">
            <button type="button" (click)="dateFormationInit.toggle()" class="btn btn-secondary">
              <fa-icon icon="calendar-alt"></fa-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="dateFormationDer" translate>page.admin.jure.qualifications.dateDerniereFormation</label>
        <div class="input-group">
          <input id="dateFormationDer" ngbDatepicker #dateFormationDer="ngbDatepicker" formControlName="dateFormationDer" class="form-control"/>
          <div class="input-group-append">
            <button type="button" (click)="dateFormationDer.toggle()" class="btn btn-secondary">
              <fa-icon icon="calendar-alt"></fa-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="form-group" [ngClass]="{ 'is-invalid': form.submitted && college.invalid }">
        <label for="college" class="col-form-label" translate>page.admin.jure.qualifications.college</label>
          <select id="college" formControlName="college" class="form-control">
            <option [value]="null" hidden></option>
            <option *ngFor="let college of refColleges" [ngValue]="college">{{college.libelle}}</option>
          </select>

      </div>

      <div class="form-group">
        <label for="operateur" translate>page.admin.jure.qualifications.operateur</label>
        <!-- <select  formControlName="operateur" class="form-control">
          <option [ngValue]="null">Sélectionnez un opérateur (optionnel)</option>
          <option *ngFor="let operateur of operateurs" [ngValue]="operateur">{{operateur.getCustomLabel()}}</option>
        </select> -->
        <app-search-intervenant-organisme-bar id="operateur" [operateur]="this.operateurSearch" (newItemEvent)="selectOperateur($event)" ></app-search-intervenant-organisme-bar>
      </div>

      <div class="actions-bar">
        <button type="button" (click)="onClose()" class="btn btn-light" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary" translate>label.valider</button>
      </div>
    </form>
  </div>
</div>
