import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter  } from '@angular/core';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap, catchError, merge } from 'rxjs/operators';
import { EntrepriseBase } from 'src/app/data/intervenant/models/entreprise-base.model';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-search-adherent-actif-bar',
  templateUrl: './search-adherent-actif-bar.component.html',
  styleUrls: ['./search-adherent-actif-bar.component.scss']
})
export class SearchAdherentActifBarComponent implements OnInit {

  placeholder: string;
  minLength = 2;

  searching = false;
  noResult = false;
  userSearch: string;

  @Output() newItemEvent = new EventEmitter<Entreprise>();


  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly entrepriseService: EntreprisesService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) { }

  focus$ = new Subject<string>();
  @ViewChild('someInput') searchElement: ElementRef;
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus$),
      tap(() => {
        this.searching = true;
        this.noResult = false;
      }),
      switchMap(term => {
        //if ((term != null) && (term.length >= this.minLength)) {
          return this.entrepriseService.getEntreprisesAdherentesActivesSearch(term).pipe(
            tap(entreprises => {
              this.noResult = !entreprises || (entreprises.length === 0);
            }),
            catchError(() => {
              return of([]);
            }));
        //} else {
         // return of([]);
        //}
      }),
      tap(() => this.searching = false),
    )

  formatter = (entreprise: EntrepriseBase) => `${entreprise.raisonSociale}`;
  
  selectOperateur(event) {
    if (event.item != null) {
      const entreprise = event.item as Entreprise;
      this.newItemEvent.emit(entreprise);
    }
  }

  ngOnInit() { 
    this.route.queryParams.subscribe(params => {
      this.userSearch = params.search;
      setTimeout(()=>{
       // this.searchElement.nativeElement.focus();
        },100)
    });
  }
}
