import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { remove } from 'lodash';
import { forkJoin } from 'rxjs';
import { RefTypeInteraction } from 'src/app/data/intervenant/models/ref-type-interaction.model';
import { RefTypeInteractionsService } from 'src/app/data/intervenant/services/ref-type-interaction/ref-type-interactions.service';

@Component({
  selector: 'app-liste-type-interactions',
  templateUrl: './liste-type-interactions.component.html',
  styleUrls: ['./liste-type-interactions.component.scss']
})
export class ListeTypeInteractionsComponent implements OnInit {

  typesInteractions : RefTypeInteraction[];

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalsService : AdvBootstrapModalService,
    private readonly translate: TranslateService,
    private readonly refTypeInteractionService : RefTypeInteractionsService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
 
    forkJoin(
      this.refTypeInteractionService.getTypeInteractions()
      )
    .pipe(this.loaderService.operator())
    .subscribe(([interactions]) => {
      this.typesInteractions = interactions;     
    });

    

  }

  openConfirmationDialog(interaction: RefTypeInteraction) { 

    this.modalsService.confirm(
      'Veuillez confirmer',
      'Voulez vous supprimer le type d\'interaction '+ interaction.libelle + ' ?', {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.deleteInteraction(interaction);
    }, () => { });

  }

  deleteInteraction(interaction: RefTypeInteraction) {
    this.refTypeInteractionService.supprimerTypeInteraction(interaction.id).subscribe(() => {
      remove(this.typesInteractions, interaction);
    });
  }

  editInteraction(interaction: RefTypeInteraction) {this.modalsService.confirm(
      'Veuillez confirmer',
      this.translate.instant(`page.valeur_parametrable.message_modification`), {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.router.navigate(['edit', interaction.id], { relativeTo: this.route });
    }, () => { });
  }


}
