import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, forkJoin } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';

import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { AcheteurExport } from '../../models/acheteur-export.model';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { Operateur } from 'src/app/data/intervenant/models/operateur.model';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';

@Injectable({
  providedIn: 'root'
})
export class AcheteursService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly operateursService: OperateursService,
    private readonly refService: ReferencesService
  ) { }

  getAcheteursExport(idOperateur: number): Observable<AcheteurExport[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/declaration/private/operateurs/${idOperateur}/acheteurs-export`),
      this.refService.getReferences()
    ).pipe(
      map(([acheteurs, ref]) => this.mapper.deserializeArray(acheteurs, AcheteurExport, { pays: ref.pays }))
    );
  }

  getAcheteursFrance(idOperateur: number): Observable<Operateur[]> {
    return this.http.get<number[]>(`/api/declaration/private/operateurs/${idOperateur}/acheteurs-france`).pipe(

      flatMap(idsAcheteurs => {
        return forkJoin(idsAcheteurs.map(id => this.operateursService.getOperateur(id)));
      })
    );
  }

  creerAcheteur(idOperateur: number, acheteur: AcheteurExport): Observable<number> {
    return this.http.post(`/api/declaration/private/operateurs/${idOperateur}/acheteurs-export`, this.mapper.serialize(acheteur), { observe: 'response' }).pipe(
      map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
    );
  }
}
