import { JsonProperty, JsonObject, Optional, CustomConverter } from '@thorolf/json-ts-mapper';

import * as moment from 'moment';

import { DateTimeConverter } from 'src/app/core/services/mapper/converters';
import { Jury } from './jury.model';
import { Salle } from './salle.model';
import { Animateur } from './animateur.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';

@JsonObject
export class CommissionRecours {
  @JsonProperty('id', Number)
  @Optional
  id: number;

  @JsonProperty('dateDebut', String)
  @CustomConverter(DateTimeConverter)
  @Optional
  dateDebut: moment.Moment;

  @JsonProperty('numero', String)
  @Optional
  numero: string;
}
