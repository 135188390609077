import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { DeclarationDrBase } from './declaration-dr.base.model';

@JsonObject
export class DeclarationSV11 extends DeclarationDrBase {
  @JsonProperty('sup_rec', Number)
  sup_rec: number;

  @JsonProperty('vol_app', Number)
  vol_app: number;

  @JsonProperty('vol_cree', Number)
  vol_cree: number;

  @JsonProperty('vol_dplc', Number)
  vol_dplc: number;

  @JsonProperty('vol_vsi', Number)
  vol_vsi: number;

  @JsonProperty('vol_vci', Number)
  vol_vci: number;

  @JsonProperty('list', [DeclarationSV11])
  @Optional
  details: DeclarationSV11[] = [];
}
