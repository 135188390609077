import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { some, find, remove } from 'lodash';
import { Abonnement } from 'src/app/data/intervenant/models/abonnement.model';
import { Service } from 'src/app/data/intervenant/models/service.model';
import { Domaine } from 'src/app/data/intervenant/models/domaine.model';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';
import { AbonnementsService } from 'src/app/data/intervenant/services/abonnements/abonnements.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';

@Component({
  selector: 'app-edit-abonnements',
  templateUrl: './edit-abonnements.component.html',
  styleUrls: ['./edit-abonnements.component.scss']
})
export class EditAbonnementsComponent implements OnInit {
  idOrganisme: number;
  abonnements: Abonnement[];
  services: Service[];
  domaines: Domaine[];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly referenceService: ReferencesService,
    private readonly abonnementsService: AbonnementsService,
    private readonly loaderService: AdvBootstrapLoaderService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.idOrganisme = parseInt(params.get('id'), 10);

      this.loadData(this.idOrganisme);
    });
  }

  loadData(idOrganisme: number) {
    forkJoin(
      this.abonnementsService.getAbonnements(idOrganisme),
      this.referenceService.getReferences()
    ).pipe(
      this.loaderService.operator()
    ).subscribe(([abonnements, references]) => {
      this.abonnements = abonnements;

      this.services = references.services;
      this.domaines = references.domaines;
    });
  }

  getAbonnement(domaine: Domaine, service: Service): Abonnement {
    return find(this.abonnements, (abonnement: Abonnement) =>
      abonnement.domaine.id === domaine.id && abonnement.service.id === service.id
    );
  }

  createAbonnement(domaine: Domaine, service: Service) {
    const abonnement = Object.assign(new Abonnement(), {
      service,
      domaine,
      idOrganisme: this.idOrganisme
    });

    this.abonnementsService.createAbonnement(this.idOrganisme, abonnement).subscribe((id) => {
      abonnement.id = id;
      this.abonnements.push(abonnement);
    });
  }

  deleteAbonnement(domaine: Domaine, service: Service) {
    const abonnement = this.getAbonnement(domaine, service);

    this.abonnementsService.deleteAbonnement(this.idOrganisme, abonnement).subscribe(() => {
      remove(this.abonnements, abonnement);
    });
  }
}
