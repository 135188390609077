import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { clone, differenceBy, filter, find, get, head, some, uniqWith } from 'lodash';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { Abonnement } from 'src/app/data/intervenant/models/abonnement.model';
import { Domaine } from 'src/app/data/intervenant/models/domaine.model';
import { Organisme } from 'src/app/data/intervenant/models/organisme.model';
import { Profil } from 'src/app/data/intervenant/models/profil.model';
import { Service } from 'src/app/data/intervenant/models/service.model';
import { UtilisateurProfil } from 'src/app/data/intervenant/models/utilisateur-profil.model';
import { Utilisateur } from 'src/app/data/intervenant/models/utilisateur.model';
import { AbonnementsService } from 'src/app/data/intervenant/services/abonnements/abonnements.service';
import { OrganismesService } from 'src/app/data/intervenant/services/organismes/organismes.service';
import { ProfilsService } from 'src/app/data/intervenant/services/profils/profils.service';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';
import { UtilisateurProfilsService } from 'src/app/data/intervenant/services/utilisateur-profils/utilisateur-profils.service';
import { UtilisateursService } from 'src/app/data/intervenant/services/utilisateurs/utilisateurs.service';
import { UtilisateurTypeCode } from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';



@Component({
  selector: 'app-utilisateur-droits',
  templateUrl: './utilisateur-droits.component.html',
  styleUrls: ['./utilisateur-droits.component.scss']
})
export class UtilisateurDroitsComponent implements OnInit {
  formGroup: FormGroup;

  utilisateur: Utilisateur;

  services: Service[];
  organismes: Organisme[];
  abonnements: Abonnement[];
  profils: Profil[];

  utilisateurProfils: UtilisateurProfil[];

  filteredOrganismes: Organisme[];

  otherOrganismes: Organisme[];

  organismeSelected: Organisme;
  domaineSelected: Domaine;

  constructor(
    private readonly abonnementsService: AbonnementsService,
    private readonly authService: AuthService,
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly organismesService: OrganismesService,
    private readonly profilsService: ProfilsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly utilisateurProfilsService: UtilisateurProfilsService,
    private readonly utilisateursService: UtilisateursService,
    private readonly refService: ReferencesService
  ) { }

  ngOnInit() {
    const idUtilisateur = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    this.formGroup = this.fb.group({});

    forkJoin(
      this.utilisateursService.getUtilisateur(idUtilisateur),
      this.organismesService.getOrganismes(),
      this.profilsService.getProfils(idUtilisateur),
      this.abonnementsService.getAllAbonnements(),
      this.utilisateurProfilsService.getUtilisateurProfils(idUtilisateur),
      this.refService.getReferences()
    ).pipe(
      this.loaderService.operator()
    ).subscribe(([utilisateur, organismes, profils, abonnements, utilisateurProfils, ref]) => {
      this.utilisateur = utilisateur;
      this.organismes = organismes;
      this.services = ref.services;
      this.abonnements = abonnements;
      this.profils = profils;
      this.utilisateurProfils = utilisateurProfils;

      this.getOrganismesFromProfils();

      if (this.authService.hasRole('ADMIN')) {
        this.getOtherOrganismes();
      }

      this.buildForm();
    });

  }

  hasAbonnement(organisme, domaine, service) {
    return find(this.abonnements, (abonnement: Abonnement) => {
      return (abonnement.idOrganisme === organisme.id)
        && (abonnement.domaine.id === domaine.id)
        && (abonnement.service.id === service.id);
    }) != null;
  }

  getUtilisateurProfil(organisme, domaine, service): UtilisateurProfil {
    return this.utilisateurProfils.find(up => {
      return (up.organisme.id === organisme.id) &&
        (up.domaine.id === domaine.id) &&
        (up.service.id === service.id);
    });
  }

  getOrganismesFromProfils() {
    this.filteredOrganismes = [];

    if (SessionContext.get('utilisateurType') === UtilisateurTypeCode.ADMIN) {
      uniqWith(this.utilisateurProfils, (up1, up2) => {
        return (up1.organisme.id === up2.organisme.id) && (up1.domaine.id === up2.domaine.id);
      }).forEach(utilisateurProfil => {
        let organisme = find(this.filteredOrganismes, {
          id: utilisateurProfil.organisme ? utilisateurProfil.organisme.id : null
        });
        if (organisme == null) {
          organisme = clone(utilisateurProfil.organisme);
          organisme.domaines = [];
          this.filteredOrganismes.push(organisme);
        }

        const domaine = find(organisme.domaines, {
          id: utilisateurProfil.domaine.id
        });
        if (domaine == null) {
          organisme.domaines.push(utilisateurProfil.domaine);
        }
      });
    } else {
      const organisme = clone(find(this.organismes, {
        id: SessionContext.get('idOrganisme')
      }));

      const domaine: Domaine = find(ReferencesService.getReference('domaines'), {
        id: SessionContext.get('idDomaine')
      });

      organisme.domaines = [domaine];

      this.filteredOrganismes.push(organisme);
    }
  }

  hasProfils(organisme: Organisme, service: Service): boolean {
    return some(this.profils, {
      idOrganisme: organisme.id,
      idService: service.id
    });
  }

  getProfils(organisme: Organisme, service: Service): Profil[] {
    return filter(this.profils, {
      idOrganisme: organisme.id,
      idService: service.id
    }).sort((a, b) => a.libelle.toLowerCase().localeCompare(b.libelle.toLowerCase()));
  }

  buildForm(): void {
    this.filteredOrganismes.forEach(organisme => {
      const organismeFormGroup = this.fb.group({});

      organisme.domaines.forEach(domaine => {
        const domaineFormGroup = this.fb.group({});

        this.services.forEach((service: Service) => {
          const utilisateurProfil = this.getUtilisateurProfil(organisme, domaine, service);

          domaineFormGroup.addControl(
            String(service.id),
            this.fb.control(utilisateurProfil ? utilisateurProfil.profil : undefined)
          );
        });

        organismeFormGroup.addControl(String(domaine.id), domaineFormGroup);
      });

      this.formGroup.addControl(String(organisme.id), organismeFormGroup);
    });
  }

  getOtherOrganismes(): void {
    this.otherOrganismes = [];

    this.organismes.forEach(organisme => {
      const utilisateurOrganisme = find(this.filteredOrganismes, {
        id: organisme.id
      });

      if (utilisateurOrganisme == null) {
        this.otherOrganismes.push(clone(organisme));
      } else if (organisme.domaines.length > utilisateurOrganisme.domaines.length) {
        const otherOrganisme = clone(organisme);
        otherOrganisme.domaines = differenceBy(organisme.domaines, utilisateurOrganisme.domaines, 'id');

        this.otherOrganismes.push(otherOrganisme);
      }
    });

    this.organismeSelected = head(this.otherOrganismes);
    if (this.organismeSelected != null) {
      this.domaineSelected = head(this.organismeSelected.domaines);
    } else {
      this.domaineSelected = null;
    }
  }

  selectOrganisme(): void {
    if (this.organismeSelected != null) {
      this.domaineSelected = head(this.organismeSelected.domaines);
    }
  }

  addProfil(): void {
    if (this.organismeSelected && this.domaineSelected) {
      let utilisateurOrganisme = find(this.filteredOrganismes, {
        id: this.organismeSelected.id
      });

      if (utilisateurOrganisme == null) {
        utilisateurOrganisme = clone(this.organismeSelected);
        utilisateurOrganisme.domaines = [this.domaineSelected];

        this.filteredOrganismes.push(utilisateurOrganisme);
      } else {
        utilisateurOrganisme.domaines.push(this.domaineSelected);
      }

      this.addProfilForm();

      this.getOtherOrganismes();
    }
  }

  addProfilForm(): void {
    let organismeFormGroup = this.formGroup.get(String(this.organismeSelected.id)) as FormGroup;
    if (organismeFormGroup == null) {
      organismeFormGroup = this.fb.group({});
      this.formGroup.addControl(String(this.organismeSelected.id), organismeFormGroup);
    }

    let domaineFormGroup = organismeFormGroup.get(String(this.domaineSelected.id)) as FormGroup;
    if (domaineFormGroup == null) {
      domaineFormGroup = this.fb.group({});
      organismeFormGroup.addControl(String(this.domaineSelected.id), domaineFormGroup);
    }

    this.services.forEach((service: Service) => {
      domaineFormGroup.addControl(String(service.id), this.fb.control(undefined));
    });
  }

  compareProfils(p1: Profil, p2: Profil): boolean {
    return (p1 != null) && (p2 != null) && (p1.id === p2.id);
  }

  submit() {
    if (this.formGroup.valid) {
      const utilisateurProfilsUpdated = [];

      this.filteredOrganismes.forEach(organisme => {
        organisme.domaines.forEach(domaine => {
          this.services.forEach((service: Service) => {
            const profil = get(this.formGroup.value, [organisme.id, domaine.id, service.id]);

            let utilisateurProfil = this.getUtilisateurProfil(organisme, domaine, service);
            if (utilisateurProfil == null) {
              if (profil != null) {
                utilisateurProfil = new UtilisateurProfil();
                utilisateurProfil.organisme = organisme;
                utilisateurProfil.domaine = domaine;
                utilisateurProfil.service = service;
                utilisateurProfil.profil = profil;

                utilisateurProfilsUpdated.push(utilisateurProfil);
              }
            } else {
              utilisateurProfil = clone(utilisateurProfil);
              utilisateurProfil.profil = profil;

              utilisateurProfilsUpdated.push(utilisateurProfil);
            }
          });
        });
      });

      this.utilisateurProfilsService.updateUtilisateurProfils(this.utilisateur.id, utilisateurProfilsUpdated).pipe(
        this.loaderService.operator()
      ).subscribe(() => {
        this.router.navigate(['main/admin/utilisateurs']);
      });
    }
  }
}
