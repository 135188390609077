import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agrimaker',
  templateUrl: './agrimaker.component.html',
  styleUrls: ['./agrimaker.component.scss']
})
export class AgrimakerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
