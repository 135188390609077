import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { add, remove } from 'lodash';
import { forkJoin, of } from 'rxjs';
import { Terroir } from 'src/app/data/intervenant/models/terroir.model';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';
import { TerroirsService } from 'src/app/data/intervenant/services/terroirs/terroirs.service';
import { Departement } from 'src/app/shared/models/departement.model';
import { DepartementService } from 'src/app/shared/services/dep-commune/departement.service';

@Component({
  selector: 'app-edit-terroir',
  templateUrl: './edit-terroir.component.html',
  styleUrls: ['./edit-terroir.component.scss']
})
export class EditTerroirComponent implements OnInit {

  formGroup: FormGroup;
  creation = false;
  title: string;
  terroir: Terroir;
  terroirs: Terroir[];

  departements: Departement[];
  departement_terroir: Departement[];
  departement_libre: Departement[];

  dept_terroir_string: string[];

  get libelle() { return this.formGroup.get('libelle'); }
  get departement() { return this.formGroup.get('departement'); }
  get departements_libres() { return this.formGroup.get('departements_libres'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly terroirService: TerroirsService,
    private readonly referenceService: ReferencesService,
    private readonly departementService: DepartementService
    ) { }

  ngOnInit() {
    const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    if (!id) {
      this.creation = true;
    }
      this.loadData(id);
  }

  loadData(id: number) {

    this.title = this.creation ? 'page.valeur_parametrable.form.title.add_terroir': 'page.valeur_parametrable.form.title.edit_terroir';

    forkJoin(
    !this.creation ? this.terroirService.getTerroir(+id) : of(new Terroir()),
    this.departementService.getLocalisationDepartements(),
    this.terroirService.getTerroirs()
    )
    .pipe(
      this.loaderService.operator()
    ).subscribe(([terroir,depts, terroirs]) => {

      this.terroir = terroir;
      this.terroirs = terroirs;    
      this.departements = depts;

      this.dept_terroir_string= [];
      this.dept_terroir_string = terroirs.map(t =>{ return t.departements; }).join(",").split(",");
      this.departement_libre = this.departements.filter(dept =>  this.dept_terroir_string.indexOf(dept.code) === -1 );

      if(this.terroir.departements){
      this.departement_terroir = this.departements.filter(dept =>  this.terroir.departements.split(",").indexOf(dept.code) !== -1 );
      }
      else this.departement_terroir = [];

      this.initForm((terroir.id) ? terroir : null);
     
    });
  }

  private initForm(terroir?: Terroir): void {
 
    this.formGroup = this.fb.group({
      departement: [{ value: ((terroir) ? this.departement_terroir: undefined), disabled: false }],
      departements_libres: [{ value: ((terroir) ? this.departement_libre: undefined), disabled: false }],
      libelle: [{ value: ((terroir) ? terroir.libelle : undefined), disabled: false }, Validators.required]
    });
  }

  removeDep(indexDep: number,dep: Departement) {

    remove(this.departement.value, dep);
    this.departement_libre.push(dep);

    this.formGroup.get(`departement`).updateValueAndValidity();
    this.formGroup.get('departements_libres').updateValueAndValidity();

    
    this.modifierTerroir();

  }

  canDropDep(drag: CdkDrag<Departement>, drop: CdkDropList<Departement[]>) {
    return drop.data.findIndex(departement => departement.code === drag.data.code) === -1;
  }

  moveDep(event: CdkDragDrop<Departement[]>) {
    remove(this.departement.value, event.item.data);

    this.departement_libre.push(event.item.data);
    
    this.formGroup.get('departement').updateValueAndValidity();
    this.formGroup.get('departements_libres').updateValueAndValidity();
    
    this.modifierTerroir();
  }

  moveDepLibre(event: CdkDragDrop<Departement[]>) {
    remove(this.departements_libres.value, event.item.data);

    this.departement_terroir.push(event.item.data);

    this.formGroup.get('departement').updateValueAndValidity();
    this.formGroup.get('departements_libres').updateValueAndValidity();

    this.modifierTerroir();

  }

  modifierTerroir(){
    this.terroir.departements = this.departement.value.map(t =>{ return t.code; }).join(",");
    
    this.terroirService.modifierTerroir(this.terroir).subscribe( () => {} );;
  }

  submit() {
    if (this.formGroup.valid) {

      this.terroir.libelle = this.libelle.value;
     
      if (this.terroir.id == null) {
        this.terroirService.creerTerroir(this.terroir).subscribe(idterroir => { 
          this.terroir.id = idterroir;
          this.creation = false;
          this.initForm(this.terroir);
        });
      } else {            
        this.terroir.departements = this.departement.value.map(t =>{ return t.code; }).join(",");

        this.terroirService.modifierTerroir(this.terroir).subscribe( () => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
         } );
      }
     
    }
  }

}
