import { JsonObject, JsonProperty, Optional, CustomConverter } from '@thorolf/json-ts-mapper';

import * as moment from 'moment';

import { DateConverter } from 'src/app/core/services/mapper/converters';
import { RefStatut } from './statut.ref.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';

@JsonObject
export class StatutHabilitation {
  @JsonProperty('id', Number)
  @Optional
  public id: number = undefined;

  @JsonProperty('idHabilitation', Number)
  @Optional
  public idHabilitation: number = undefined;

  @JsonProperty('idStatut', Number)
  @CustomConverter(DataIdConverterFactory.build(RefStatut, 'statutsHabilitation'))
  public statut: RefStatut = undefined;

  @JsonProperty('dateReception', String)
  @Optional
  @CustomConverter(DateConverter)
  public dateReception: moment.Moment = undefined;

  @JsonProperty('dateDebut', String)
  @CustomConverter(DateConverter)
  public dateDebut: moment.Moment = undefined;

  @JsonProperty('commentaire', String)
  @Optional
  public commentaire: string = undefined;
}
