import {Component, OnInit, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {forkJoin} from 'rxjs';
import * as moment from 'moment';
import {AdvBootstrapLoaderService} from '@adv/bootstrap-loader';
import {OperateurSitesService} from 'src/app/data/declaration/services/operateur-sites/operateur-sites.service';
import {SessionContext, NavigationContext} from 'src/app/core/services/config/app.settings';
import {Site} from 'src/app/data/declaration/models/site.model';
import {Produit} from 'src/app/data/habilitation/models/produit.model';
import {UtilisateurTypeCode} from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';
import {Cepage} from 'src/app/data/declaration/models/cepage.model';
import {MouvementDestination} from 'src/app/data/declaration/models/mouvement-destination.model';
import {SaisieRevendication} from 'src/app/data/declaration/models/saisie-revendication.model';
import {ReferentielService} from 'src/app/data/declaration/services/referentiel/referentiel.service';
import {LotSaisiesRevendications} from 'src/app/data/declaration/models/lot-saisies-revendications.model';
import {ProduitsService} from 'src/app/data/habilitation/services/produits/produits.service';
import {CahiersService} from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import {Cahier} from 'src/app/data/habilitation/models/cahier.model';
import {ActiviteCode} from 'src/app/data/habilitation/models/enums/activites-code.enum';
import {RefStatutCode} from 'src/app/data/habilitation/models/enums/ref-statut-code.enum';

@Component({
  selector: 'app-saisir-revendication',
  templateUrl: './saisir-revendication.component.html',
  styleUrls: ['./saisir-revendication.component.scss']
})
export class SaisirRevendicationComponent implements OnInit {
  @Input() campagne: string;
  @Input() @Output() lotRevendications: LotSaisiesRevendications;
  produits: Produit[];
  cepages: Cepage[];
  destinations: MouvementDestination[];
  idOperateur: number;
  idOrganisme: number;
  indexModification: number;
  sites: Site[];
  // Formulaire de saisie
  public formGroup: FormGroup;

  get form_produits() {
    return this.formGroup.get('produits');
  }

  get form_cepages() {
    return this.formGroup.get('cepages');
  }

  get form_date() {
    return this.formGroup.get('date');
  }

  get form_logement() {
    return this.formGroup.get('logement');
  }

  get form_lot() {
    return this.formGroup.get('lot');
  }

  get form_degre() {
    return this.formGroup.get('degre');
  }

  get form_observation() {
    return this.formGroup.get('observation');
  }

  get form_volume() {
    return this.formGroup.get('volume');
  }

  get form_entreposage() {
    return this.formGroup.get('entreposage');
  }

  get form_destinations() {
    return this.formGroup.get('destinations');
  }

  get form_cahier() {
    return this.formGroup.get('cahier');
  }

  cahiers: Cahier[];

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly fb: FormBuilder,
    private readonly operateurSitesService: OperateurSitesService,
    private readonly referentielService: ReferentielService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly produitsService: ProduitsService,
    private readonly cahiersService: CahiersService
  ) {
  }

  ngOnInit() {
    // Récupère l'identifiant de l'opérateur courant
    this.idOperateur = SessionContext.get('utilisateurType') === UtilisateurTypeCode.OPERATEUR ?
      SessionContext.get('idIntervenant') : NavigationContext.get('idOperateur');

    this.idOrganisme = SessionContext.get('idOrganisme');

    // Instancie le formulaire de saisie d'une revendication
    this.formGroup = this.fb.group({
      cahier: [undefined, Validators.required],
      produits: [undefined, Validators.required],
      cepages: [undefined],
      date: [undefined, Validators.required],
      logement: [undefined, Validators.required],
      lot: [undefined, Validators.required],
      degre: [undefined, this.positifOrNullValidator],
      observation: [undefined],
      volume: [undefined, [
        Validators.pattern(/(^\d+$)|(^\d+\.\d{1,2}$)|(^\d+\,\d{1,2}$)/),
        this.positifStrictValidator,
        Validators.required
      ]],
      entreposage: [undefined, Validators.required],
      destinations: [undefined, Validators.required]
    });

    this.loadData();
  }

  /**
   * Charge les données nécessaires au formulaire et le pré-remplit dans le cas
   * d'un ajout ou d'une modification depuis un autre composant
   */
  loadData() {
    // Charger les stocks, les contenants et les adresses
    forkJoin(
      this.referentielService.getReferentiel(),
      this.operateurSitesService.getSitesOperateur(this.idOperateur),
      // tslint:disable-next-line:max-line-length
      this.cahiersService.getCahiersByHabilitation(this.idOperateur, this.idOrganisme, RefStatutCode.Habilite, ActiviteCode.VIN_PROD_VIGNIFICATION)
    )
      .pipe(this.loaderService.operator())
      .subscribe(([ref, sites, cahiers]) => {
        this.cepages = ref.cepages.sort((a, b) => {
          return a.libelle.localeCompare(b.libelle);
        });
        this.destinations = ref.mouvementsDestinations;
        this.sites = sites;
        this.cahiers = cahiers;
        // Vérifier si un lot a été passé depuis le composant précédent pour modification
        if (
          this.lotRevendications &&
          this.lotRevendications.revendications &&
          this.lotRevendications.revendications.length > 0
        ) {
          this.preRemplirForm();
        }
      });
  }

  /**
   * Vérifie qu'un contrôle de formulaire est bien positif
   * @param control Le contrôle à vérifier
   */
  positifStrictValidator(control: FormControl) {
    return (control.value <= 0) ? {invalid: true} : null;
  }

  /**
   * Vérifie qu'un contrôle de formulaire est positif ou null
   * @param control Le contrôle à vérifier
   */
  positifOrNullValidator(control: FormControl) {
    return (control.value && control.value <= 0) ? {invalid: true} : null;
  }

  /** Ferme la modal */
  onClose() {
    this.modal.dismiss();
  }

  /**
   * Récupère la revendication saisie et l'ajoute au lot avant de
   * réinitialiser le formulaire
   * @param formDirective Le formulaire à réinitialiser
   */
  onSubmit(formDirective: FormGroupDirective = null) {
    if (this.formGroup.valid) {
      // Instancier la revendication saisie
      const saisie = this.instanciateSaisie();

      // Instancier le lot si c'est la première saisie
      if (!this.lotRevendications) {
        this.lotRevendications = new LotSaisiesRevendications(
          this.form_cahier.value.id,
          this.form_cahier.value.libelle,
          saisie.date,
          saisie.entreposage,
          this.campagne,
          this.idOperateur
        );
      }

      if (this.indexModification > -1) {
        // Mettre à jour la revendication dans le lot
        this.lotRevendications.revendications[this.indexModification] = saisie;
      } else {
        // Ajouter la revendication saisie au lot
        this.lotRevendications.revendications.push(saisie);
      }

      // Réinitialiser le formulaire pour la saisie suivante
      if (formDirective) {
        this.resetForm(formDirective, saisie);
      }
    }
  }

  onPrevious() {
    this.indexModification = (this.indexModification) ?
      (this.indexModification - 1) : (this.lotRevendications.revendications.length - 1);
    this.preRemplirForm();
  }

  /** Récupère la saisie et clot la modal en retournant le lot de revendications */
  onTerminer() {
    // Récupérer la saisie du formulaire courant
    this.onSubmit();
    // Fermer la modale en retournant le lot de saisies
    this.modal.close(this.lotRevendications);
  }

  /**
   * Réinitialise le formulaire pour une nouvelle saisie avec entête pré-remplie et
   * non modifiable
   * @param form Le formulaire à réinitialiser
   * @param saisie La saisie contenant l'entête à afficher
   * @see SaisieRevendication
   */
  resetForm(form: FormGroupDirective, saisie: SaisieRevendication) {
    let igpSelect: Cahier = this.form_cahier.value;
    // Réinitialiser le formulaire et ses validators
    form.resetForm({
      cahier: igpSelect,
      produits: undefined,
      date: {
        year: saisie.date.year(),
        month: saisie.date.month() + 1,
        day: saisie.date.date()
      },
      entreposage: saisie.entreposage,
      cepages: undefined,
      logement: undefined,
      lot: undefined,
      degre: undefined,
      volume: undefined,
      destinations: undefined,
      observation: undefined
    });

    // Désactiver les champs cahier, date et entreposage après la première saisie
    this.form_date.disable();
    this.form_entreposage.disable();
    this.form_cahier.disable();
    // Réinitialiser l'index de modification
    this.indexModification = undefined;
  }

  /**
   * Retourne un objet de la classe SaisieRevendication en fonction de la saisie
   * effectuée par l'utilisateur
   * @see SaisieRevendication
   */
  instanciateSaisie(): SaisieRevendication {
    return Object.assign(new SaisieRevendication(), {
      codeProduit: this.form_produits.value.code,
      libelleProduit: this.form_produits.value.libelle,
      cepages: this.form_cepages.value,
      destinations: this.form_destinations.value,
      degre: this.form_degre.value,
      date: moment([
        this.form_date.value.year,
        this.form_date.value.month - 1,
        this.form_date.value.day, 0, 0, 0, 0
      ]),
      logement: this.form_logement.value,
      numeroLot: this.form_lot.value,
      volume: this.form_volume.value,
      entreposage: this.form_entreposage.value,
      observation: this.form_observation.value
    });
  }

  /**
   * Pré-remplit le formulaire avec les données de la revendication
   * à modifier ou avec les données d'entête dans le cas d'un ajout
   * a posteriori
   */
  preRemplirForm() {
    let saisie: SaisieRevendication;
    // Vérifier si l'on est en modification ou en ajout d'une revendication
    let cahier: Cahier = this.cahiers.find(
      cahier => cahier.id === this.lotRevendications.idCahier
    );
    if (this.indexModification > -1) {
      // Affecter les valeurs de la revendication à modifier
      saisie = this.lotRevendications.revendications[this.indexModification];
      this.loadProductsByCdc([cahier.id]);
      this.formGroup.patchValue({
        cahier: cahier,
        produits: this.produits.find(
          prd => prd.code === saisie.codeProduit
        ),
        date: {
          year: saisie.date.year(),
          month: saisie.date.month() + 1,
          day: saisie.date.date()
        },
        entreposage: this.sites.find(
          site => site.id === saisie.entreposage.id
        ),
        cepages: (saisie.cepages && this.cepages) ?
          this.cepages.filter(cepage =>
            saisie.cepages.find(_ => _.id === cepage.id)) : [],
        logement: saisie.logement,
        lot: saisie.numeroLot,
        degre: saisie.degre,
        volume: saisie.volume,
        destinations: (saisie.destinations && this.destinations) ?
          this.destinations.filter(destination =>
            saisie.destinations.find(_ => _.id === destination.id)) : [],
        observation: saisie.observation
      });
    } else {
      // Affecter les valeurs par défaut de l'entête du lot
      saisie = this.lotRevendications.revendications[0];
      this.loadProductsByCdc([cahier.id]);
      this.formGroup.patchValue({
        cahier: cahier,
        date: {
          year: saisie.date.year(),
          month: saisie.date.month() + 1,
          day: saisie.date.date()
        },
        entreposage: this.sites.find(
          site => site.id === saisie.entreposage.id
        )
      });
    }

    // Désactiver les champs de l'entête
    this.form_date.disable();
    this.form_entreposage.disable();
    this.form_cahier.disable();
  }


  loadProducts() {
    // Récupérer l'ID du CDCs sélectionné
    let idsCdcs: number[] = [this.form_cahier.value.id];
    this.loadProductsByCdc(idsCdcs);

  }

  /**
   * Charge les produits en fonction du cahier des charges selectionné
   */
  loadProductsByCdc(idsCdcs: number[]) {
    this.produits = [];
    this.form_produits.disable();

    // Réinitialiser la sélection des produits
    this.form_produits.reset();

    // Récupérer les produits liés au CDC sélectionné
    this.produitsService.getProduitsByCdcs(this.idOrganisme, idsCdcs).subscribe(produits => {
      this.produits = produits;
      this.form_produits.enable();
    });
  }


}
