import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AdvBootstrapLoaderService} from '@adv/bootstrap-loader';
import {AdvBootstrapModalService} from '@adv/bootstrap-modal';
import {
  UniteCulturaleService
} from '../../../../../../../data/declaration/services/parcellaire/unite-culturale.service';
import {TranslateService} from '@ngx-translate/core';
import {RefCultureBio} from '../../../../../../../data/declaration/models/ref-culture-bio.model';
import {UniteCulturale} from '../../../../../../../data/declaration/models/unite-culturale.model';

@Component({
  selector: 'app-culture-bio',
  templateUrl: './culture-bio.component.html',
  styleUrls: ['./culture-bio.component.scss']
})
export class CultureBioComponent implements OnInit {
  formGroup: FormGroup;
  @Input() refCultureBios: RefCultureBio[];
  @Input() uniteCulturalesSelectionnees: UniteCulturale[];

  constructor(public readonly modal: NgbActiveModal,
              private readonly loaderService: AdvBootstrapLoaderService,
              private readonly fb: FormBuilder,
              private readonly modalsService: AdvBootstrapModalService,
              private readonly uniteCulturaleService: UniteCulturaleService,
              private readonly translate: TranslateService) {
  }
  get cultureBio() {
    return this.formGroup.get('cultureBio');
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      cultureBio: [undefined, Validators.required]
    });
  }

  cancel() {
    this.modal.dismiss();
  }

  submit() {
    if (this.formGroup.valid) {
      this.uniteCulturaleService.cultureBioMasseUniteCulturale(this.uniteCulturalesSelectionnees, this.cultureBio.value).subscribe(() => {
        this.modal.close();
      });
    } else {
      this.modalsService.error(this.translate.instant(`page.inventaire-verger.modals.cultureBio.cultureBioError`), null , {windowClass: 'anpp'}).then(() => {
      });
    }
  }
}
