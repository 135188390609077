import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { ClonesComponent } from './pages/clones/clones.component';
import { CulturesBioComponent } from './pages/cultures-bio/cultures-bio.component';
import { FonctionsComponent } from './pages/fonctions/fonctions.component';
import { FormesComponent } from './pages/formes/formes.component';
import { GroupesDiffusionComponent } from './pages/groupes-diffusion/groupes-diffusion.component';
import { GroupesVarietauxComponent } from './pages/groupes-varietaux/groupes-varietaux.component';
import { ListeValeursParametrablesComponent } from './pages/liste-valeurs-parametrables/liste-valeurs-parametrables.component';
import { PortesGreffesComponent } from './pages/portes-greffes/portes-greffes.component';
import { ProtectionsGelComponent } from './pages/protections-gel/protections-gel.component';
import { ProtectionsGreleComponent } from './pages/protections-grele/protections-grele.component';
import { SituationsComponent } from './pages/situations/situations.component';
import { StructuresComponent } from './pages/structures/structures.component';
import { SystemesIrrigationsComponent } from './pages/systemes-irrigations/systemes-irrigations.component';
import { TerroirsComponent } from './pages/terroirs/terroirs.component';
import { TypeInteractionsComponent } from './pages/type-interactions/type-interactions.component';
import { VarietesComponent } from './pages/varietes/varietes.component';

@Component({
  selector: 'app-valeurs-parametrables',
  templateUrl: './valeurs-parametrables.component.html',
  styleUrls: ['./valeurs-parametrables.component.scss']
})
export class ValeursParametrablesComponent implements OnInit {


  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"],
    menu: {
      libelle: 'menu.administration-listes',
      icon: 'key'
      }
    };
  
    static routes: Routes = [
      { path: '', component: ListeValeursParametrablesComponent },
      { path: 'varietes', data: VarietesComponent.routeData, children : VarietesComponent.routes },
      { path: 'groupes', data: GroupesVarietauxComponent.routeData, children : GroupesVarietauxComponent.routes },
      { path: 'clones', data: ClonesComponent.routeData, children : ClonesComponent.routes },
      { path: 'fonctions', data: FonctionsComponent.routeData, children : FonctionsComponent.routes },
      { path: 'terroirs', data: TerroirsComponent.routeData, children : TerroirsComponent.routes },
      { path: 'structures', data: StructuresComponent.routeData, children : StructuresComponent.routes },
      { path: 'protectionGel', data: ProtectionsGelComponent.routeData, children : ProtectionsGelComponent.routes },
      { path: 'protectionGrele', data: ProtectionsGreleComponent.routeData, children : ProtectionsGreleComponent.routes },
      { path: 'portegreffes', data: PortesGreffesComponent.routeData, children : PortesGreffesComponent.routes },
      { path: 'cultureBio', data: CulturesBioComponent.routeData, children : CulturesBioComponent.routes },
      { path: 'formes', data: FormesComponent.routeData, children : FormesComponent.routes },
      { path: 'irrigations', data: SystemesIrrigationsComponent.routeData, children : SystemesIrrigationsComponent.routes },
      { path: 'situations', data: SituationsComponent.routeData, children : SituationsComponent.routes },
      { path: 'groupediffusion', data: GroupesDiffusionComponent.routeData, children : GroupesDiffusionComponent.routes },
      { path: 'typeInteraction', data: TypeInteractionsComponent.routeData, children : TypeInteractionsComponent.routes }
    ];

    
  constructor() { }

  ngOnInit() {
  }

}
