import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RefSituation} from '../../models/ref-situation.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RefSituationService {

  constructor( private readonly http: HttpClient) { }

  getRefSituation(): Observable<RefSituation[]> {
    return this.http.get<RefSituation[]>(`/api/declaration/private/referentielSituation`);
  }

  public creerRefSituation(situation: RefSituation): Observable<number> {
    return this.http.post(`/api/declaration/private/referentielSituation`, situation, { observe: 'response' }).pipe(
      map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
    );
  }  
  
  public modifierRefSituation(situation: RefSituation ): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/referentielSituation/${situation.id}`, situation );
  }
  
  public desactiverRefSituation(situation: RefSituation): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/referentielSituation/${situation.id}?desactivation=${true}`, situation );
  }
}
