<div class="anpp">
  <div class="content">
    <div class="content-panel">
      <div class="card">
        <div *ngIf="entreprise" class="row title">
          <div class="col">
            <p class="h4 m-3">
              {{ entreprise.raisonSociale }}
            </p>
          </div>
          <div class="col-md-auto"></div>
          <div class="col col-lg-4">
            <button
              type="button"
              *ngIf="entreprise.refSousType.code === 'PO_DEFAUT'"
              (click)="toGroupement()"
              class="btn btn-secondary btnprimary m-3"
            >
              vers Groupement de producteurs
            </button>
          </div>
        </div>

        <ngb-tabset
          class="mt-2"
          #tabSet="ngbTabset"
          class="border-radius-onglet"
          *ngIf="entreprise"
          (tabChange)="onTabChange($event, tabSet)"
        >
          <ngb-tab id="edit" title="Identification et coordonnées">
            <ng-template ngbTabContent>
              <app-edit-entreprise [id]="id" [estOnglet]="true" #edit>
              </app-edit-entreprise>
            </ng-template>
          </ngb-tab>

          <ngb-tab
            id="listepo"
            title="Producteurs organisés"
            *ngIf="entreprise && entreprise.refSousType.code === 'GP_DEFAUT'"
          >
            <ng-template ngbTabContent>
              <app-liste-po [id]="id" [estOnglet]="true" #listepo>
              </app-liste-po>
            </ng-template>
          </ngb-tab>

          <ngb-tab id="listecontact" title="Contacts" *ngIf="entreprise">
            <ng-template ngbTabContent>
              <app-liste-contact-entreprise [id]="id" #listecontact>
              </app-liste-contact-entreprise>
            </ng-template>
          </ngb-tab>

          <ngb-tab
            id="listeConseillert"
            title="Conseillers"
            *ngIf="entreprise && entreprise.refSousType.code === 'GP_DEFAUT'"
          >
            <ng-template ngbTabContent>
              <app-list-conseiller [id]="id" #listeConseiller>
              </app-list-conseiller>
            </ng-template>
          </ngb-tab>

          <ngb-tab id="verger" title="Verger" *ngIf="entreprise">
            <ng-template ngbTabContent>
              <app-verger-entreprise [id]="id" #verger> </app-verger-entreprise>
            </ng-template>
          </ngb-tab>

          <ngb-tab
            id="VAR"
            title="Vergers écoresponsables"
            *ngIf="
              entreprise &&
              entreprise.refSousType.code === 'PI_DEFAUT' &&
              ongletVergerEco
            "
          >
            <ng-template ngbTabContent>
              <app-formulaire-pi [idEngagement]="idEngagement" #vergerEco>
              </app-formulaire-pi>
            </ng-template>
          </ngb-tab>

          <ngb-tab
            id="VAR"
            title="Vergers écoresponsables"
            *ngIf="
              entreprise &&
              entreprise.refSousType.code === 'GP_DEFAUT' &&
              ongletVergerEco
            "
          >
            <ng-template ngbTabContent>
              <app-formulaire-gp [idEngagement]="idEngagement" #vergerEco>
              </app-formulaire-gp>
            </ng-template>
          </ngb-tab>
          <ngb-tab id="interaction" title="Interactions" *ngIf="entreprise">
            <ng-template ngbTabContent>
              <app-interactions-entreprise [id]="id" #interaction>
              </app-interactions-entreprise>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
</div>
