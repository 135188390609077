import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { PasswordService } from 'src/app/data/intervenant/services/password/password.service';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { AuthentificationService } from 'src/app/authentification/services/authentification/authentification.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-mdp',
  templateUrl: './edit-mdp.component.html',
  styleUrls: ['./edit-mdp.component.scss']
})
export class EditMdpComponent implements OnInit {
  static routes = [
    { path: '', redirectTo: 'or', pathMatch: 'full' }
  ];

  formGroup: FormGroup;
  submitted = false;
  pwdPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&_])[A-Za-z\d$@$!%*?&].{11,}';
  tokenPwd: string;
  email: string;
  isAnpp: boolean;

  get f() { return this.formGroup.controls; }
  get oldPwd() { return this.formGroup.get('oldPassword'); }
  get newPwd() { return this.formGroup.get('newPassword'); }
  get confirmNewPwd() { return this.formGroup.get('confirmPassword'); }



  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly passwordService: PasswordService,
    private readonly authentificationService: AuthentificationService,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    public readonly modal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.isAnpp = SessionContext.get('codeDomaine') === 'ANPP';
    this.formGroup = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(12),
        Validators.pattern(this.pwdPattern)
      ]
      ],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this.validatorConfirmPwd('newPassword', 'confirmPassword')
    });
  }

  private get idIntervenant(): number {
    return SessionContext.get('idIntervenant');
  }

  onSubmit() {
    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    } else {
      this.passwordService.editPwd(this.oldPwd.value, this.newPwd.value, this.confirmNewPwd.value, this.idIntervenant).subscribe(() => {
        this.router.navigate(['../home'], { relativeTo: this.activatedRoute });
        this.translate.get('page.auth.modifierMdp.messageConfirm').subscribe(msg => {
          this.toastr.success('', msg);
          this.modal.close();
        });
      });
    }
  }

  // Permet de valider si les mots de passe sont identiques ou non
  validatorConfirmPwd(pwd: string, matchPwd: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[pwd];
      const matchingControl = formGroup.controls[matchPwd];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmPwd: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  cancel() {
    this.modal.dismiss();
  }

}
