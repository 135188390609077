import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';
import { Produit } from '../../habilitation/models/produit.model';
import { TypeChangementProduitCode } from './enums/type-changement-produit-code.enum';

@JsonObject
export class ProduitDestination {
  @JsonProperty('idProduit', Number)
  idProduit: number;

  @JsonProperty('codeProduit', String)
  codeProduit: string;

  @JsonProperty('libelleTypeChangement', String)
  typeChangement: string;

  @JsonProperty('codeTypeChangement', String)
  codeTypeChangement: TypeChangementProduitCode;

  produit: Produit = new Produit();
}
