import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditContactComponent } from './pages/edit-contact/edit-contact.component';
import { InteractionsContactComponent } from './pages/interactions-contact/interactions-contact.component';
import { ListeContactsComponent } from './pages/liste-contacts/liste-contacts.component';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit { 

  static routeData = {
  role: 'ORGANISME',
  domaines: ["ANPP"],
  menu: {
    libelle: 'menu.administration-contacts',
    icon: 'key'
    }
  };

  static routes: Routes = [
    { path: '', component: ListeContactsComponent },
    { path: 'new', component: EditContactComponent, data: { roles: ['ORGANISME'] } },
    { path: ':id/interactions', component: InteractionsContactComponent, data: { roles: ['ORGANISME'] } },
    { path: 'edit/:idc', component: EditContactComponent, data: { roles: ['ORGANISME'] } }
  ];

  constructor() { }

  ngOnInit() {
  }

}
