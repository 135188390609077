<form novalidate class="anpp">
  <div class="modal-header">
    <h5 class="modal-title" translate>Document préparatoire pour CT</h5>
  </div>

  <div class="modal-body">
    <div [formGroup]="formGroup" class="form-group">
      <h6 class="modal-title"> Pour quel Conseiller Technique souhaitez vous uploader le document ?</h6>
      <select formControlName="conseillerTechnique">
        <option *ngFor="let conseillerTech of conseillerTechniqueList" [ngValue]="conseillerTech">{{conseillerTech.nom+' '+conseillerTech.prenom}}</option>
      </select>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" (click)="cancel()" class="btn btn-light" translate>label.annuler</button>
    <button type="submit" (click)="buildDocumentAudit()" class="btn btn-primary" translate>label.valider</button>
  </div>
</form>
