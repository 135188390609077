import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Structure } from '../../models/structure.model';

@Injectable({
  providedIn: 'root'
})
export class StructuresService {

  constructor(  
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
    ) { }

    getStructures(): Observable<Structure[]> {
      return this.http.get<Structure[]>(`/api/intervenant/private/structures`).pipe(
        map(structure => this.mapper.deserializeArray(structure, Structure))
      );        
    }

    getStructure(idStructure : number): Observable<Structure> {
      return  this.http.get<Structure>(`/api/intervenant/private/structures/${idStructure}`).pipe(
        map(structure => this.mapper.deserializeObject(structure, Structure))
      );        
    }

    public creerStructure(structure: Structure): Observable<number> {
      return this.http.post(`/api/intervenant/private/structures`, structure, { observe: 'response' }).pipe(
        map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
      );
    }  
    
    public modifierStructure(structure: Structure): Observable<void> {
      return this.http.put<void>(`/api/intervenant/private/structures/${structure.id}`, structure);
    }

    public supprimerStructure(idStructure: number): Observable<void> {
      return this.http.delete<void>(`/api/intervenant/private/structures/${idStructure}`);
    }
}
