import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordService } from 'src/app/data/intervenant/services/password/password.service';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-creer-mdp',
  templateUrl: './creer-mdp.component.html',
  styleUrls: ['./creer-mdp.component.scss']
})
export class CreerMdpComponent implements OnInit {


  formGroup: FormGroup;
  submitted = false;
  pwdPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&_])[A-Za-z\d$@$!%*?&].{11,}';

  tokenPwd: string;
  email: string;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly passwordService: PasswordService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.tokenPwd = params.tokenPassword;
      this.email = params.mail;
    });
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      mail: [this.email],
      password: ['', [
        Validators.required,
        Validators.minLength(12),
        Validators.pattern(this.pwdPattern)
      ]
      ],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this.validatorConfirmPwd('password', 'confirmPassword')
    });
  }

  get f() { return this.formGroup.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    } else {
      this.passwordService.createPwd(this.email, this.tokenPwd, this.f.password.value).subscribe(() => {
        this.router.navigate(['../login'], { relativeTo: this.activatedRoute });
        this.translate.get('page.auth.creerMdp.messageConfirmCreationMdp').subscribe(msg => {
          this.toastr.success('', msg);
        });
      });
    }
  }

  // Permet de valider si les mots de passe sont identiques ou non
  validatorConfirmPwd(pwd: string, matchPwd: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[pwd];
      const matchingControl = formGroup.controls[matchPwd];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmPwd: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

}
