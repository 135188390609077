import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { References } from '../../models/references.model';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ClearCache, Cache } from 'src/app/core/services/cache/cache.service';

@Injectable({
  providedIn: 'root'
})
export class ReferencesService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
  ) { }

  @ClearCache
  clearcache() { }

  @Cache({
    maxAge: 3600,
    key: 'refences-commissions'
  })
  getReferences(): Observable<References> {
    return this.http.get<object>(`/api/declaration/private/commissions/references`).pipe(
      map((ref: any) => {
        ref.statutsJure.forEach((st: any) => st.code = st.code.replace(' ', '_'));
        return ref;
      }), // TODO remove
      map(ref => this.mapper.deserializeObject(ref, References))
    );
  }
}
