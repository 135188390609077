import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { UtilisateurProfil } from '../../models/utilisateur-profil.model';
import { OrganismesService } from '../organismes/organismes.service';
import { ProfilsService } from '../profils/profils.service';
import { ReferencesService } from '../references/references.service';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurProfilsService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapperService: JsonMapperService,
    private readonly organismesService: OrganismesService,
    private readonly profilsService: ProfilsService,
    private readonly referencesService: ReferencesService
  ) { }

  getUtilisateurProfils(idUtilisateur: number): Observable<UtilisateurProfil[]> {
    const url = `/api/intervenant/private/utilisateurs/${idUtilisateur}/utilisateur-profils`;

    return forkJoin(
      this.http.get<object[]>(url),
      this.referencesService.getReferences(),
      this.organismesService.getOrganismes(),
      this.profilsService.getProfils(idUtilisateur)
    ).pipe(
      map(([utilisateurProfils, ref, organismes, profils]) => this.mapperService.deserializeArray(utilisateurProfils, UtilisateurProfil, Object.assign({ organismes, profils }, ref)))
    );
  }

  updateUtilisateurProfils(idUtilisateur: number, utilisateurProfils: UtilisateurProfil[]): Observable<void> {
    const url = `/api/intervenant/private/utilisateurs/${idUtilisateur}/utilisateur-profils`;

    return this.http.put(url, this.mapperService.serialize(utilisateurProfils)).pipe(
      map(() => { })
    );
  }
}
