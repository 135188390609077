import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Cache, NoCache } from 'src/app/core/services/cache/cache.service';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { References } from '../../models/references.model';

@Injectable({
  providedIn: 'root'
})
export class ReferencesService {

  static references: References;

  constructor(
    private readonly http: HttpClient,
    private readonly mapperService: JsonMapperService
  ) { }

  static getReferences(): References {
    const references = ReferencesService.references;
    if (references === undefined) {
      throw new Error('service-references-intervenant.static-data.unavailable');
    }
    return references;
  }

  static getReference<K extends keyof References>(key: K): References[K] {
    const references = ReferencesService.getReferences();

    return references[key];
  }

  @Cache({
    key: 'references intervenant',
    maxAge: 3600 // 1h
  })
  getReferences(@NoCache noCache?: boolean): Observable<References> {
    return this.http.get<object>(`/api/intervenant/private/references`)
      .pipe(
        map(references => this.mapperService.deserializeObject(references, References)),
        tap(references => { ReferencesService.references = references; })
      );
  }
}
