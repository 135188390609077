import { JsonProperty, JsonObject, Optional } from '@thorolf/json-ts-mapper';
import { Inscription } from './inscription.model';

@JsonObject
export class InscriptionVin extends Inscription {
  @JsonProperty('cvi', String)
  cvi: string;

  @JsonProperty('ppm', String)
  @Optional
  ppm: string;

  @JsonProperty('accise', String)
  @Optional
  accise: string;
}
