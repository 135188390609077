import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { info } from 'console';
import { EChartOption } from 'echarts';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { Campagne } from 'src/app/data/declaration/models/campagne.model';
import { DashBoardDeclaration } from 'src/app/data/declaration/models/dashboard-declaration.model';
import { HistogrammeEcoulement } from 'src/app/data/declaration/models/histogramme-ecoulement';
import { HistogrammeStock } from 'src/app/data/declaration/models/histogramme-stock';
import { DashboardService } from 'src/app/data/declaration/services/dashboard/dashboard.service';
import { DeclarationsService } from 'src/app/data/declaration/services/declarations/declarations.service';
import { UniteCulturaleService } from 'src/app/data/declaration/services/parcellaire/unite-culturale.service';
import { RefCampagnesService } from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';
import { GedFacade } from 'src/app/data/edition/facades/ged.facade';
import { DashboardGed } from 'src/app/data/edition/models/dashboard-ged.model';
import { EditionService } from 'src/app/data/edition/services/edition.service';
import { EngagementBase } from 'src/app/data/habilitation/models/engagements/engagement-base';
import { EngagementVergersEcoService } from 'src/app/data/habilitation/services/engagements/engagement-vergers-eco.service';
import { AccueilAnppProducteur } from 'src/app/data/intervenant/models/accueil-anpp/accueil-anpp-producteur';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { UtilisateursService } from 'src/app/data/intervenant/services/utilisateurs/utilisateurs.service';

@Component({
  selector: 'app-anpp-producteur',
  templateUrl: './anpp-producteur.component.html',
  styleUrls: ['./anpp-producteur.component.scss']
})
export class AnppProducteurComponent implements OnInit {
  accueilAnppProducteur: AccueilAnppProducteur = new AccueilAnppProducteur;
  isAnpp: boolean;
  idEntreprise: number;
  rendementVarietesPrincipales: EChartOption;
  recoltesPrevision: EChartOption;
  miseAJour: boolean = false;
  campagneEnCours: Campagne;
  campagneVerEnCours: Campagne;
  derniereDateMajInventaireVerger: Date;
  idEntrepriseDerniereModifInv: number;
  derniereDeclarationRecolte: DashBoardDeclaration;
  derniereDeclarationStock: DashBoardDeclaration;
  derniereDeclarationEcoulement: DashBoardDeclaration;
  entreprise: Entreprise;
  engagement: EngagementBase;
  engagementPrecedant: EngagementBase;
  isPo: boolean;
  dashboardGed: DashboardGed;

  stocks: EChartOption;
  ecoulements: EChartOption;
  @ViewChild('carousel') carousel: NgbCarousel

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly entreprisesService: EntreprisesService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly utilisateursService: UtilisateursService,
    private readonly dashboardDeclarationService: DashboardService,
    private readonly refCampagnesService: RefCampagnesService,
    private readonly declarationsService: DeclarationsService,
    private readonly uniteCulturaleService: UniteCulturaleService,
    private readonly translate: TranslateService,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly engagementVergersEco: EngagementVergersEcoService,
    private readonly editionService: EditionService,
    private gedFacade: GedFacade) { }

  ngOnInit() {
    forkJoin([
      this.refCampagnesService.getCampagneEnCours(),
      this.refCampagnesService.getCampagneVerEnCours()
    ]).pipe(this.loaderService.operator()).subscribe(([campagneEnCours, campagneVerEnCours]) => {
      this.campagneEnCours = campagneEnCours;
      this.campagneVerEnCours = campagneVerEnCours;
      let today = new Date();
      this.refCampagnesService.getCampagneAnnee(today.getFullYear()).pipe(this.loaderService.operator()).subscribe(campagne => {
        if (campagne && today < new Date(today.getFullYear(), 5, 15)) {
          this.miseAJour = true;
        }
      });
      this.isAnpp = this.authService.isAnpp();
      if (this.isAnpp) {
        this.idEntreprise = parseInt(this.route.snapshot.paramMap.get('idEntreprise'), 10);
        this.loadData(false);
      } else {
        this.utilisateursService.getUtilisateur(SessionContext.get('idIntervenant')).pipe(this.loaderService.operator()).subscribe(utilisateur => {
          this.idEntreprise = utilisateur.entreprise.id;
          this.isPo = (utilisateur.type.code === 'PO');
          this.loadData(this.isPo);
        });
      }
    });
  }

  loadData(isPo: boolean): void {
    this.loadEntreprise();
    if (!isPo) {
      this.loadHistogrammeRecolte();
      this.loadHistogrammeRendement();
      this.loadDeclarations();
      this.loadStocks();
      this.loadEcoulements();
      this.loadVer();
    }
  }

  loadVer(): void {
    forkJoin([
      this.engagementVergersEco.getEngagementEcoByEntrepriseAndCampagne(this.idEntreprise, this.campagneVerEnCours.code),
      this.refCampagnesService.getCampagneVerPrecedente()
    ]).pipe(this.loaderService.operator()).subscribe(([engagement, campagnePrecedente]) => {
      if (engagement) {
        this.engagement = engagement;
      }
      this.engagementVergersEco.getEngagementEcoByEntrepriseAndCampagne(this.idEntreprise, campagnePrecedente.code).pipe(this.loaderService.operator()).subscribe(engagementPrecedant => {
        if (engagementPrecedant) {
          this.engagementPrecedant = engagementPrecedant;
        }
      });
    });
  }

  loadEntreprise(): void {
    this.entreprisesService.getEntreprise(this.idEntreprise).pipe(this.loaderService.operator()).subscribe(entreprise => {
      this.entreprise = entreprise;
      this.accueilAnppProducteur.idEntreprise = entreprise.id;
      this.accueilAnppProducteur.raisonSociale = entreprise.raisonSociale;
      this.accueilAnppProducteur.email = entreprise.mail;
      this.accueilAnppProducteur.telephone = entreprise.telephone;
      if (entreprise.entrepriseLiee != null) {
        this.accueilAnppProducteur.groupement = entreprise.entrepriseLiee.raisonSociale;
      }
      this.loadDateDerniereMajInventaireVerger();
      //this.loadDashboardGed(entreprise.id, entreprise.refSousType.code);
    });
  }

  loadHistogrammeRecolte(): void {
    this.dashboardDeclarationService.getHistogrammeRecolte(this.idEntreprise).pipe(this.loaderService.operator()).subscribe(histogrammeRecolte => {
      if (histogrammeRecolte) {
        this.recoltesPrevision = {
          legend: {},
          tooltip: {},
          dataset: {
            source: [
              ['prévison', 'initial', 'dernière', 'récolte'],
              ['', histogrammeRecolte.quantitePrevisionInitiale, histogrammeRecolte.quantiteDernierePrevision, histogrammeRecolte.quantiteRecolte]
            ]
          },
          xAxis: { type: 'category' },
          yAxis: {},
          series: [
            { type: 'bar', center: ['50%', '40%'] }, { type: 'bar' }, { type: 'bar' }]
        };
      }
    });
  }

  loadHistogrammeRendement(): void {
    this.dashboardDeclarationService.getHistogrammeRendement(this.idEntreprise).pipe(this.loaderService.operator()).subscribe(histogrammeRendement => {
      if (histogrammeRendement.length > 0) {
        let source = [['variete', 'mon entreprise', 'département', 'national']];
        for (var i = 0; i < histogrammeRendement.length; i++) {
          const variete = histogrammeRendement[i];
          source.push([variete.libelleGroupeVarietal, variete.rendementEntreprise, variete.rendementDepartemental, variete.rendementNational])
        }
        this.rendementVarietesPrincipales = {
          legend: {},
          tooltip: { position: [10, '50%'] },
          dataset: {
            source: source
          },
          xAxis: {
            type: 'category',
            axisLabel: {
              fontSize: 10,
              formatter: function (params) {
                var newParamsName = "";// The final concatenated string
                var pramsSplitted = params.split(" ");
                for (var i = 0; i < pramsSplitted.length; i++) {
                  newParamsName += pramsSplitted[i] + "\n";
                }
                return newParamsName;
              }
            }
          },
          yAxis: {},
          // Declare several bar series, each will be mapped
          // to a column of dataset.source by default.
          series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
        };
      }
    });
  }

  loadDateDerniereMajInventaireVerger(): void {
    if (this.entreprise.refSousType.code === 'GP_DEFAUT') {
      this.uniteCulturaleService.getDateLastModifInventaireVergerEntreprisesLiees(this.idEntreprise).pipe(this.loaderService.operator()).subscribe((infos) => {
        if (infos) {
          this.derniereDateMajInventaireVerger = infos.dateDerniereMajInventaire;
          this.idEntrepriseDerniereModifInv = infos.idEntreprise;
        }
      });
    } else {
      this.uniteCulturaleService.getDateLastModifInventaireVerger(this.idEntreprise).pipe(this.loaderService.operator()).subscribe((infos) => {
        if (infos) {
          this.derniereDateMajInventaireVerger = infos.dateDerniereMajInventaire;
          this.idEntrepriseDerniereModifInv = infos.idEntreprise;
        }
      });
    }
  }

  loadDeclarations(): void {
    this.dashboardDeclarationService.getDernieresDeclarations(this.idEntreprise).pipe(this.loaderService.operator()).subscribe(declarations => {
      this.derniereDeclarationRecolte = declarations.find(dec => dec.codeMouvement === 'RECOLTE');
      this.derniereDeclarationStock = declarations.find(dec => dec.codeMouvement === 'STOCK');
      this.derniereDeclarationEcoulement = declarations.find(dec => dec.codeMouvement === 'ECOULEMENT');
    });
  }

  loadStocks(): void {
    this.dashboardDeclarationService.getHistogrammeStocks(this.idEntreprise).pipe(this.loaderService.operator()).subscribe(histo => {
      if (histo.length > 0) {
        this.stocks = {
          legend: {},
          tooltip: {},
          xAxis: { type: 'category', data: ['nov', 'dec', 'jan', 'fev', 'mar', 'avr', 'mai', 'juin', 'juil', 'aou'], },
          yAxis: { type: 'value' },
          series: [
            {
              type: 'bar',
              data: histo.map((h: HistogrammeStock) => h.quantite)
            }
          ]
        };
      }
    });
  }
  loadEcoulements(): void {
    this.dashboardDeclarationService.getHistogrammeEcoulements(this.idEntreprise).pipe(this.loaderService.operator()).subscribe(histo => {
      if (histo.length > 0) {
        this.ecoulements = {
          legend: {},
          tooltip: {},
          xAxis: { type: 'category', data: ['nov', 'dec', 'jan', 'fev', 'mar', 'avr', 'mai', 'juin', 'juil', 'aou'], },
          yAxis: { type: 'value', max: 'dataMax' },
          series: [
            {
              type: 'bar',
              name: "Total",
              data: histo.map((h: HistogrammeEcoulement) => h.quantite)
            },
            {
              type: 'bar',
              name: "France",
              data: histo.map((h: HistogrammeEcoulement) => h.quantiteFrance)
            },
            {
              type: 'bar',
              name: "Export",
              data: histo.map((h: HistogrammeEcoulement) => h.quantiteExport)
            },
            {
              type: 'bar',
              name: "Indust.",
              data: histo.map((h: HistogrammeEcoulement) => h.quantiteIndustrie)
            },
            {
              type: 'bar',
              name: "Freinte",
              data: histo.map((h: HistogrammeEcoulement) => h.quantiteFreinte)
            }
          ]
        };
      }
    });
  }

  loadDashboardGed(idEntreprise: number, codeSousType: string) {
    this.editionService.getDashboardDocuments(idEntreprise, codeSousType, 2).subscribe(result => {
      this.dashboardGed = result;
    });
  }

  moveNext() {
    this.carousel.next();
  }

  getPrev() {
    this.carousel.prev();
  }

  goToOrganismeHome(): void {
    this.router.navigate(['main/home']);
  }

  goToMonCompte(): void {
    if (this.isAnpp) {
      this.router.navigate(['/main/admin/params/entreprises/fiche/' + this.accueilAnppProducteur.idEntreprise]);
    } else {
      this.router.navigate(['main/mon-compte']);
    }
  }

  goToEngagementVer(): void {
    // Spec imprécise : parle de pouvoir modifier un engagement alors que ce n'est pas possible pour un PI / GP
    /*if (this.entreprise.refSousType.code == "GP_DEFAUT") {
      this.router.navigate(['main/engagements/formulaire-gp/' + this.engagement.id]);
    } else {
      this.router.navigate(['main/engagements/formulaire-pi/' + this.engagement.id]);
    }*/
    //Choix de diriger vers la grille du suivi
    this.router.navigate(['main/engagements/vergers-ecoresponsables']);
  }

  gotToDeclaration(code: string): void {
    let route = '/main/declarations-pp/';
    switch (code) {
      case "RECOLTE":
        route = route + 'recoltes/';
        break;
      case "STOCK":
        route = route + 'stocks/';
        break;
      case "ECOULEMENT":
        route = route + 'ecoulements/';
        break;
    }
    if (this.isAnpp) {
      this.declarationsService.entrepriseSelectionnee = <Entreprise>{
        id: this.entreprise.id,
        raisonSociale: this.entreprise.raisonSociale,
        refSousType: this.entreprise.refSousType
      };
      route = route + this.idEntreprise;
    }
    this.router.navigate([route]);
  }

  exportInventaire() {
    const entreprises = [];
    entreprises.push(this.idEntrepriseDerniereModifInv);
    this.uniteCulturaleService.exporterInventaireVerger(entreprises, this.campagneEnCours.id, true).subscribe((unites) => {
      const uniteCulturales = unites.sort((a, b) => a.numBlocFruitier > b.numBlocFruitier ? 1 : -1);
      if (uniteCulturales.length > 0) {
        this.uniteCulturaleService.exporterInventaireVergerCsv(uniteCulturales);
      }
      else {
        this.modalsService.error(this.translate.instant(`page.inventaire-verger.modals.exporterInventaire.exportError`)).then(() => {
        });
      }
    });
  }

  public onDownloadFile(uuid: string): void {
    this.gedFacade.downloadFile(uuid);
  }
}
