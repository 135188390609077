<div class="modal-content anpp">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel"> <fa-icon icon="file-alt"></fa-icon> <b translate> page.inventaire-verger.modals.valid-inventaire-verger.titleModal</b></h5>
    <button (click)="cancel()" type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="tab-content" id="nav-tabContent">
      <p>&nbsp;</p>
      <div class="row justify-content-md-center">
        <div class="col col-lg-10 text-center"><h4>Les modifications que vous avez apportées à vos blocs sont déjà enregistrées. En revanche, la validation définitive de l'inventaire vergers est nécessaire pour l'engagement 'Vergers écoresponsables'.</h4>
          <p>&nbsp;</p></div>
        <div class="col col-lg-5 text-center">
          <div class="form-group">
            <p>
              <label>
                <input class="with-gap" name="checkDeclaration" type="checkbox" (change)="checkValue(isChecked? true:false)" [(ngModel)]="isChecked">
                <span style="font-size:25px"translate> page.inventaire-verger.modals.valid-inventaire-verger.checkboxSendDeclaration</span>
              </label>
            </p>
          </div>
          <div class="align-center">
            <button type="button" (click)="cancel()" style="background:#41905c;border:10px solid #41905c" class="btn btn-primary mr-2" translate>label.annuler</button>
            <button (click)="validerDeclaration()" style="background:#41905c;border:10px solid #41905c" type="button" class="btn btn-primary"
                    translate>label.valider</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
