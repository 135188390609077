import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AuthentificationService } from '../../services/authentification/authentification.service';
import { Jwt } from '../../models/jwt.model';
import { LoginCredentials } from '../../models/login-credentials.model';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { HttpCustomErrorResponse } from 'src/app/shared/models/http-custom-error-response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { TranslateService } from '@ngx-translate/core';
import { PasswordService } from 'src/app/data/intervenant/services/password/password.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formGroup: FormGroup;
  @Output()
  onLoginSuccess = new EventEmitter<Jwt>();

  get login() { return this.formGroup.get('login'); }
  get password() { return this.formGroup.get('password'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly authService: AuthentificationService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly router: Router,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly translate: TranslateService,
    private readonly passwordService: PasswordService,
    private readonly toastr: ToastrService
  ) { }

  ngOnInit() {

    this.formGroup = this.fb.group({
      login: [undefined, Validators.required],
      password: [undefined, Validators.required]
    });
  }

  submit() {
    if (this.formGroup.valid) {
      const credentials = new LoginCredentials();
      credentials.login = this.login.value;
      credentials.password = this.password.value; // TODO bcrypt ?

      this.authService.getToken(credentials).pipe(
        this.loaderService.operator()
      ).subscribe(jwt => {
        this.onLoginSuccess.emit(jwt);
      },
        (error) => {
          console.log('login error', error);
          if (error instanceof HttpErrorResponse && error.error.code === 'INTERVENANT_BACCHUS_BUSINESS_USER_DISABLED') {
            this.router.navigate(['/auth/disabled']);
          } else {
            throw error;
          }
        }
      );
    }
  }

  forgotPasswordModal() {
    if (!this.validateEmail(this.login.value)) {
      this.modalsService.error(
        this.translate.instant(`page.auth.mdpOublie.modalErrorText`),
        this.translate.instant(`page.auth.mdpOublie.modalErrorTitle`), {
        cancelText: this.translate.instant(`label.annuler`)
      }
      ).then();
    } else {
      const modalConfirmText: string = this.translate.instant(`page.auth.mdpOublie.modalConfirmText`);
      const reg = /pMail/gi;
      this.modalsService.confirm(
        this.translate.instant(modalConfirmText.replace(reg, this.login.value)),
        this.translate.instant(`page.auth.mdpOublie.modalConfirmTitle`), {
        cancelText: this.translate.instant(`label.annuler`),
        submitText: this.translate.instant(`label.confirmer`)
      }
      ).then(() => {
        const mailForgotPwd: string = this.translate.instant(`page.auth.mdpOublie.mailForgotPwd`);
        this.passwordService.forgotPwd(this.login.value).subscribe(() => {
          this.toastr.success(mailForgotPwd.replace(reg, this.login.value));
        });
      }, () => { });
    }

  }

  validateEmail(email) {
    const patternLogin = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return patternLogin.test(String(email).toLowerCase());
  }

}
