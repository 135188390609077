import { JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";
import { TourneeSiteEdition } from "./tournee-site-edition.model";
import { OrganismeEdition } from "./organisme-edition.model";

@JsonObject
export class TourneeEdition {

    @JsonProperty('date', String)
    @Optional
    date: string;  

    @JsonProperty('numero', String)
    @Optional
    numero: string;

    @JsonProperty('responsable', String)
    @Optional
    responsable: string;

    @JsonProperty('idResponsable', Number)
    @Optional
    idResponsable: number;

    @JsonProperty('sites', [TourneeSiteEdition])
    @Optional
    sites: TourneeSiteEdition[];

    @JsonProperty('organisme', [OrganismeEdition])
    @Optional
    organisme: OrganismeEdition;

}