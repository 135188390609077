import { JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";

@JsonObject
export class EntrepriseEngagement {
  @JsonProperty("id", Number)
  @Optional
  public id: number = undefined;

  @JsonProperty("raisonSociale", String)
  public raisonSociale: string = undefined;

  @JsonProperty("adresse", String)
  @Optional
  public adresse: string = undefined;

  @JsonProperty("codePostal", String)
  @Optional
  public codePostal: string = undefined;

  @JsonProperty("commune", String)
  @Optional
  public commune: string = undefined;

  @JsonProperty("departement", String)
  @Optional
  public departement: string = undefined;

  @JsonProperty("siret", String)
  @Optional
  public siret: string = undefined;

  @JsonProperty("telephone", String)
  @Optional
  public telephone: string = undefined;

  @JsonProperty("mail", String)
  @Optional
  public mail: string = undefined;

  @JsonProperty("sousType", String)
  @Optional
  public sousType: string = undefined;
}
