<div>
  <div class="content">
    <div class="row">
      <div class="col-lg-4">
        <h4><b>Suivi</b></h4>
      </div>
    </div>
    <ngb-tabset class="mt-2" #tabSet="ngbTabset" (tabChange)="onTabChange($event, tabSet)"  [(activeId)]="suivisService.activeTabId" style="min-height: unset">

      <ngb-tab id="vergers" title="{{'page.suivi.titre-inventaires-vergers' | translate}}">
      </ngb-tab>

      <ngb-tab id="recoltes" title="{{'page.suivi.titre-recoltes' | translate}}" *hasRole="'ORGANISME'" >
      </ngb-tab>
      
      <ngb-tab id="vergersEcoresponsables" title="{{'page.suivi.titre-engagements-vergers-eco' | translate}}">
      </ngb-tab>
      
      <ngb-tab id="stocks" title="{{'page.suivi.titre-stocks' | translate}}"  *hasRole="'ORGANISME'">
      </ngb-tab>

      <ngb-tab id="ecoulements" title="{{'page.suivi.titre-ecoulements' | translate}}" *hasRole="'ORGANISME'">
      </ngb-tab>

    </ngb-tabset>
    <router-outlet></router-outlet>
  </div>
</div>
