<div class="content">
  <div class="content-panel">
    <h2 translate>page.controles.pression-controle.title</h2>

    <form [formGroup]="formPression" #form="ngForm" (ngSubmit)="onSubmit()" *ngIf="formPression">
      <!-- Colonnes centrales -->
      <div class="cols">
        <!-- Colonne de gauche -->
        <div class="col">
          <div class="form-group">
            <input type="radio" class="mr-1" id="interne" formControlName="type" value="INTERNE" />
            <label class="form-check-label" for="interne" translate>page.controles.pression-controle.form.interne</label>

            <input type="radio" class="ml-5 mr-1" id="externe" formControlName="type" value="EXTERNE" />
            <label class="form-check-label" for="externe" translate>page.controles.pression-controle.form.externe</label>
          </div>

          <div class="form-group">
            <label for="libelle" translate>page.controles.pression-controle.form.libelle</label>
            <input type="text" class="form-control" id="libelle" formControlName="libelle" [ngClass]="{ 'is-invalid': form.submitted && libelle.invalid }" />
          </div>

          <div class="form-group">
            <label for="nature" translate>page.controles.pression-controle.form.nature</label>
            <select class="form-control" id="nature" formControlName="nature" [ngClass]="{ 'is-invalid': form.submitted && nature.invalid }">
              <option [ngValue]="null" hidden selected>{{ 'page.controles.pression-controle.form.nature' | translate }}</option>
              <option *ngFor="let nature of refNatures" [ngValue]="nature">{{ nature.libelle }}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="objet" translate>page.controles.pression-controle.form.objet</label>
            <select class="form-control" id="objet" formControlName="objet">
              <ng-container *ngFor="let objet of refObjets">
                <option *ngIf="objet.idNature === nature.value?.id" [ngValue]="objet">{{ objet.libelle }}</option>
              </ng-container>
            </select>

          </div>

          <div class="form-group">
            <label for="cdcs" translate>page.controles.pression-controle.form.cdcs</label>
            <app-multiselect id="cdcs"
              controlName="cdcs"
              [items]="refCahiers"
              bindLabel="libelle"
              maxWidth="initial"
              [ngClass]="{ 'is-invalid': form.submitted && cdcs.invalid }">
            </app-multiselect>
          </div>

          <div class="form-group">
            <label for="produits" translate>page.controles.pression-controle.form.produits</label>
            <app-multiselect id="produits"
              controlName="produits"
              [items]="refProduits"
              bindLabel="libelle"
              maxWidth="initial"
              [ngClass]="{ 'is-invalid': form.submitted && produits.invalid }">
            </app-multiselect>
          </div>

          <div class="form-group">
            <label for="activites" translate>page.controles.pression-controle.form.activites</label>
            <app-multiselect id="activites"
              controlName="activites"
              [items]="refActivites"
              bindLabel="libelle"
              maxWidth="initial"
              [ngClass]="{ 'is-invalid': form.submitted && activites.invalid }">
            </app-multiselect>
          </div>
        </div>
        <!-- Fin colonne de gauche -->

        <!-- Colonne de droite -->
        <div class="col">
          <div class="form-group">
            <div class="form-group">
              <label for="declencheur" translate>page.controles.pression-controle.form.declencheur</label><br />

              <ng-container *ngFor="let declencheur of filteredRefDeclencheurs">
                <input type="radio" class="mr-1" id="declarants" formControlName="declencheur" value="{{ declencheur.id }}" />
                <label for="declarants" class="mr-5" translate>{{ declencheur.libelle }}</label>
              </ng-container>
              <ng-container *ngIf="form.submitted">
                <div *ngIf="declencheur.untouched " class="form-error" translate>label.obligatoire</div>
              </ng-container>
            </div>

            <div class="form-group">
              <label for="typesDeclaration" translate>page.controles.pression-controle.form.typesDeclaration</label>
              <app-multiselect id="typesDeclaration"
                controlName="typesDeclaration"
                [items]="refMouvements"
                bindLabel="libelle"
                maxWidth="initial"
                [ngClass]="{ 'is-invalid': form.submitted && typesDeclaration.invalid }">
              </app-multiselect>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="pression" translate>page.controles.pression-controle.form.pressionControle</label>
              </div>
              <div class="form-group col-md-3">
                <input type="number" lang="en" class="form-control small-length" id="pression" formControlName="pression" [ngClass]="{ 'is-invalid': form.submitted && pression.invalid }" />
              </div>
            </div>

            <div class="form-group">
              <label translate>page.controles.pression-controle.form.baseCampagne</label><br />
              <input type="radio" class="mr-1" id="anneeRecolte" formControlName="baseCampagne" value="ANNEE_RECOLTE" />
              <label for="anneeRecolte" translate>page.controles.pression-controle.form.anneeRecolte</label>
              <input type="radio" class="ml-5 mr-1" id="anneeCivile" formControlName="baseCampagne" value="ANNEE_CIVILE" />
              <label for="anneeCivile" translate>page.controles.pression-controle.form.anneeCivile</label>
            </div>
          </div>

          <div class="form-group">
            <label translate>
              page.controles.pression-controle.form.annees
              <fa-icon icon="plus" class="clickable-icon icon-add" title="Ajouter" (click)="onAddYear()"></fa-icon>
            </label>
            <ul formArrayName="annees">
              <li *ngFor="let annee of getYears(); let i = index" [formGroupName]="i">
                <div class="form-row">
                  <div class="form-group col-md-3">
                    <input type="number" class="form-control small-length" lang="en" formControlName="annee" [ngClass]="{ 'is-invalid': form.submitted && formPression.get('annees.'+i+'.annee').invalid }" />
                  </div>
                  <div class="form-group col-md-1">
                    <fa-icon icon="trash" class="clickable-icon" title="Supprimer" (click)="onDeleteYear(i)"></fa-icon>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- Fin colonne de droite -->
      </div>
      <!-- Fin colonne centrales -->

      <!-- Boutons d'action formulaire -->
      <div class="actions-bar">
        <button type="button" class="btn btn-light" (click)="onClose()" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary ml-2" translate>label.valider</button>
      </div>
      <!-- Fin boutons d'action formulaire -->
    </form>
  </div>
</div>
