import { Component, OnInit, Input } from '@angular/core';
import { DeclarationRevendication } from 'src/app/data/declaration/models/declaration-revendication.model';
import { DREVSaisie } from 'src/app/data/declaration/models/drevsaisie.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { merge } from 'rxjs';

@Component({
  selector: 'app-saisie-vci',
  templateUrl: './saisie-vci.component.html',
  styleUrls: ['./saisie-vci.component.scss']
})
export class SaisieVciComponent implements OnInit {

  @Input()
  campagne: number;

  @Input()
  drev: DeclarationRevendication;

  @Input()
  saisie: DREVSaisie;

  formGroup: FormGroup;
  loaded = false;
  twoDigits: RegExp = /(^\d+$)|(^\d+\.\d{1,2}$)|(^\d+\,\d{1,2}$)/;

  COLORS = [
    '#875EBF',
    '#AD92D3',
    '#F68235'
  ];

  getTankValue(tank: 'vciEnCours' | 'stockVci') {
    const vciEnCours = this.drev.vciEnCours;
    const stockVci = this.getFieldValue('stock');
    const max = Math.max(vciEnCours, stockVci);

    const v = tank === 'vciEnCours' ? vciEnCours : stockVci;

    return Math.round(v / max * 30);
  }

  get somme() {
    return this.formGroup ? this.getFieldValue('remplacement') + this.getFieldValue('substitution') + this.getFieldValue('complement') + this.getFieldValue('detruit') : 0;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly modal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      stock: [this.saisie.stockVci || this.drev.vciPasse, [Validators.required, Validators.pattern(this.twoDigits), this.positifValidator, this.totalValidator.bind(this)]],
      remplacement: [this.saisie.vciRemplacement, [Validators.required, Validators.pattern(this.twoDigits), this.positifValidator]],
      substitution: [this.saisie.vciSubstitution, [Validators.required, Validators.pattern(this.twoDigits), this.positifValidator]],
      complement: [this.saisie.vciComplement, [Validators.required, Validators.pattern(this.twoDigits), this.positifValidator]],
      detruit: [this.saisie.vciDetruit, [Validators.required, Validators.pattern(this.twoDigits), this.positifValidator]],
    });

    merge(
      this.getField('remplacement').valueChanges,
      this.getField('substitution').valueChanges,
      this.getField('complement').valueChanges,
      this.getField('detruit').valueChanges
    ).subscribe(() => {
      this.getField('stock').updateValueAndValidity();
    });


    this.loaded = true;
  }

  getField(key: string) {
    return this.formGroup.get(key);
  }

  getFieldValue(key: string) {
    return this.getField(key).value as number || 0;
  }

  positifValidator(control: FormControl) {
    if (!isNaN(control.value)) {
      if (control.value < 0) {
        return { invalid: true };
      }
    }

    return null;
  }

  totalValidator(control: FormControl) {
    const value = control.value;
    if (!isNaN(value) && !isNaN(this.somme)) {
      const PRECISION_DECIMAL = 2;

      if (this.somme.toFixed(PRECISION_DECIMAL) != (value as number).toFixed(PRECISION_DECIMAL)) {
        return { invalid: true, somme_incorrecte: true };
      }
    } else {
      return { invalid: true };
    }

    return null;
  }

  submit() {
    if (this.formGroup.valid) {
      this.saisie.vciComplement = this.getFieldValue('complement');
      this.saisie.vciRemplacement = this.getFieldValue('remplacement');
      this.saisie.vciSubstitution = this.getFieldValue('substitution');
      this.saisie.vciDetruit = this.getFieldValue('detruit');
      this.saisie.stockVci = this.getFieldValue('stock');

      this.modal.close(this.saisie);
    }
  }

  dismiss() {
    this.modal.dismiss();
  }

  getDrevTankData() {
    const v1 = this.drev.vinsEnCours - this.getFieldValue('remplacement') - this.getFieldValue('substitution');
    const v2 = this.getFieldValue('substitution') + this.getFieldValue('complement');
    const total = v1 + v2;
    let tankv1 = v1 / total * 100;
    let tankv2 = v2 / total * 100;

    if (tankv2 < 5 && tankv2 > 0) {
      tankv1 = 95;
      tankv2 = 5;
    }

    return [
      { value: tankv1, color: this.COLORS[0], libelle: Math.round(v1 * 100) / 100 + ' hL' },
      { value: tankv2, color: this.COLORS[2], libelle: Math.round(v2 * 100) / 100 + ' hL' },
    ];
  }

  getVciTankData() {
    const drevValue = this.drev.vinsEnCours - this.getFieldValue('remplacement') - this.getFieldValue('substitution') +
      this.getFieldValue('substitution') + this.getFieldValue('complement');

    const v1 = this.getFieldValue('remplacement');
    const v2 = this.drev.vciEnCours;

    const total = v1 + v2;
    let max = drevValue;
    if (total < drevValue / 4) {
      max = 4 * total;
    }
    if (total > drevValue) {
      max = total;
    }

    const tankv1 = v1 > 0 ? Math.max(v1 / max * 100, 5) : 0;
    const tankv2 = v2 > 0 ? Math.max(v2 / max * 100, 5) : 0;

    return [
      { value: tankv2, color: this.COLORS[1], libelle: Math.round(v2 * 100) / 100 + ' hL' },
      { value: tankv1, color: this.COLORS[0], libelle: Math.round(v1 * 100) / 100 + ' hL' }
    ];
  }
}
