<div class="page-content anpp" id="content">
 

  <div class="row tableau">

    <div class="col-lg-12">
      <div class="cards  border-radius-onglet text-center" style="background:#fff; display: inline-table; min-width: 100%;">
        <div class="row" *ngIf="isAnpp()">
          <div class="col-lg-4">

          </div>
          <div class="col-lg-1 text-left mt-4 lienMain"><label>Groupement : </label></div>
          <div class="col-lg-3 text-left mt-4 lienMain">
            <select id="groupement" formControlName="groupement" class="form-control" (change)="selectGroupement($event.target.value)">
              <option [value]="" ></option>
              <option *ngFor="let groupement of listGroupement" [value]="groupement.id">{{groupement.raisonSociale}}
              </option>
            </select>
          </div>
          <div class="col-lg-4">

          </div>
         
        </div>
        <div class="row">

          <!-- Lien filtrer -->
          <div class="col-lg-3 text-left mt-4 lienMain">
            <a class="pl-4" (click)="showFilter()" translate><fa-icon icon="filter"></fa-icon> label.filtrer</a>
          </div>          
          <div class="col-lg-3 text-center">
            <div class="pt-2 m-2 pourcentage-cadre">
                <a href="" placement="bottom" [ngbTooltip]="'page.suivi.suivi-inventaire.pourcent-mis-a-jour-aide' | translate">
                  <span class="pourcentage"><b>{{pourcentMisAJour|number: '1.0-0'}} %</b><br />
                    <small translate class="pourcentage-label">page.suivi.suivi-inventaire.pourcent-mis-a-jour&nbsp;<fa-icon icon="info-circle"></fa-icon></small>
                  </span>
                </a>
            </div>
          </div>

          <div class="col-lg-3 text-center">
            <div class="pt-2 m-2 pourcentage-cadre">
              <a href="" placement="bottom" [ngbTooltip]="'page.suivi.suivi-inventaire.pourcent-valides-aide' | translate">
                <span class="pourcentage"><b>{{pourcentValides|number: '1.0-0'}} %</b><br />
                  <small translate class="pourcentage-label">page.suivi.suivi-inventaire.valides&nbsp;<fa-icon icon="info-circle"></fa-icon>
                  </small>                  
                </span>
              </a>
            </div>
          </div>

          <div class="col-lg text-right pt-2 pr-4">
            <fa-icon icon="upload" class="clickable-icon" style="font-size:25px" (click)="exporterSuivis()"></fa-icon>  
          </div>

        </div>

        <div>
          <span class="badge badge-secondary">{{'page.suivi.suivi-inventaire.total-nb-entreprise'|translate}} : {{totalNbEntreprises}}</span>
          &nbsp;
          <span class="badge badge-secondary">{{'page.suivi.suivi-inventaire.total-surf-pomme'|translate}} : {{totalSurfacePomme | number: '1.3-3'}}</span>
          &nbsp;
          <span class="badge badge-secondary">{{'page.suivi.suivi-inventaire.total-surf-poire'|translate}} : {{totalSurfacePoire | number: '1.3-3'}}</span>
        </div>
        <div class="tableScroll">
        <table class="table table-striped">
          <thead class="sticky-top">
            <tr>
              <th scope="col">
                <div class="custom-control custom-checkbox">
                  <input 
                    type="checkbox" 
                    class="custom-control-input" 
                    id="relance" 
                    name="relance"
                    (change)="onCheckAllDeclarations($event)"
                  />
                  <label class="custom-control-label" for="relance"></label>
                </div>
              </th>
              <th scope="col" href="#" translate>page.suivi.suivi-inventaire.headers.operations</th>
              <th scope="col"><div translate>page.suivi.suivi-inventaire.headers.raison-sociale</div>
                <div *ngIf="!filtrer;">
                  <input type="text" [id]="raisonSociale" class="form-control bar1" [(ngModel)]="raisonSocialeFilter" (keyup)="filter()">
                </div>  
              </th>
              <th scope="col" sortable="surfacePomme" (sort)="onSort($event)"><div translate>page.suivi.suivi-inventaire.headers.surf-pommes</div>
                <div *ngIf="!filtrer;">
                  <input type="number" [id]="surfacePomme" class="form-control bar1" [(ngModel)]="surfacePommeFilter" (change)="filter()">
                </div>  
              </th>
              <th scope="col" sortable="surfacePoire" (sort)="onSort($event)"><div translate>page.suivi.suivi-inventaire.headers.surf-poires</div>
                <div *ngIf="!filtrer;">
                  <input type="number" [id]="surfacePoire" class="form-control bar1" [(ngModel)]="surfacePoireFilter" (change)="filter()">
                </div>  
              </th>
              <th scope="col" ><div translate>page.suivi.suivi-inventaire.headers.type</div>
                <div *ngIf="!filtrer;">
                    <select id="sousType" [(ngModel)]="sousTypeFilter" (change)="filter()" class="custom-select bar1">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let type of sousTypesEntreprise"
                              [ngValue]="type">{{type.libelle}}</option>
                    </select>
                  </div>
              </th>
    
  

              <th scope="col" style="position:relative">
                <fa-icon icon="calendar"></fa-icon>&nbsp;<span translate>page.suivi.suivi-inventaire.headers.maj</span>
                <div *ngIf="!filtrer;">
                  <input type="date" [id]="dateMaj" class="form-control bar1" [(ngModel)]="dateMajFilter" (change)="filter()">
                </div>
              </th>
              <th scope="col">
                <fa-icon icon="calendar"></fa-icon>&nbsp;<span translate>page.suivi.suivi-inventaire.headers.valider</span>
                <div *ngIf="!filtrer;">
                  <input type="date" [id]="dateValidation" class="form-control bar1" [(ngModel)]="dateValidationFilter" (change)="filter()">
                </div>
              </th>
              <th scope="col" translate>page.suivi.suivi-inventaire.headers.acteur
                <div *ngIf="!filtrer;">
                  <input type="text" [id]="acteur" class="form-control bar1" [(ngModel)]="acteurFilter" (change)="filter()">
                </div>                
              </th>


              <th *ngIf="show('anpp');" scope="col" translate>page.suivi.suivi-inventaire.headers.adherent
                <select *ngIf="!filtrer;" [id]="estadherent" class="custom-select bar1" [(ngModel)]="estadherentFilter"
                  (change)="filter()">
                  <option [ngValue]="undefined"></option>
                  <option [ngValue]="true" translate>label.oui</option>
                  <option [ngValue]="false" translate>label.non</option>
                </select>
              </th>
              <th *ngIf="show('terroir');" scope="col" translate>page.suivi.suivi-inventaire.headers.terroir
                <!-- filtre terroir -->
                <select *ngIf="!filtrer;" class="custom-select bar1" (change)="filter()"  [(ngModel)]="terroirFilter">
                  <option [ngValue]="undefined"></option>
                  <option *ngFor="let terroir of references.terroirs" [ngValue]="terroir">
                    {{ terroir.libelle }}
                  </option>
                </select>          
              </th>
              <th *ngIf="show('departement');" scope="col" translate>page.suivi.suivi-inventaire.headers.departement
                <div *ngIf="!filtrer;">
                  <input type="text" [id]="departement" class="form-control bar1" [(ngModel)]="departementFilter" (change)="filter()">
                </div>                
              </th>
              <th *ngIf="show('ville');" scope="col" translate>page.suivi.suivi-inventaire.headers.ville
                <div *ngIf="!filtrer;">
                  <input type="text" [id]="ville" class="form-control bar1" [(ngModel)]="villeFilter" (change)="filter()">
                </div>                
              </th>
              <th *ngIf="show('valide');" scope="col" translate>page.suivi.suivi-inventaire.headers.inventaire-valide
                <select *ngIf="!filtrer;" [id]="estValide" class="custom-select bar1" [(ngModel)]="estValideFilter"
                  (change)="filter()">
                  <option [ngValue]="undefined"></option>
                  <option [ngValue]="true" translate>label.oui</option>
                  <option [ngValue]="false" translate>label.non</option>
                </select>                
              </th>
              <th *ngIf="show('ct');" scope="col" translate>page.suivi.suivi-inventaire.headers.ct
                <div *ngIf="!filtrer;">
                  <select *ngIf="!filtrer;" [id]="ct" class="custom-select bar1" [(ngModel)]="ctFilter"
                  (change)="filter()">
                  <option [ngValue]="undefined"></option>
                  <option *ngFor="let user of acteurs" [ngValue]="user">{{user?.nom}} {{user?.prenom}}</option>
                </select>                    
                </div>                
              </th>              
              <th>
                <fa-icon class="clickable-icon" icon="ellipsis-h" (click)="afficherListe()"></fa-icon>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="suivisFilter.length === 0">
              <td colspan="99" class="p-4"><fa-icon icon="exclamation"></fa-icon>&nbsp;
                <span translate>page.suivi.suivi-inventaire.no-data</span>
              </td>
            </tr>            
            <tr *ngFor="let suivi of suivisFilter; let i = index"
              [ngClass]="{'suivi-valide' : suivi.dateDerniereValidation}">
              <td>
                <div class="custom-control custom-checkbox" style = "z-index:0">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="relance-{{ i }}"
                    name="relance-{{ i }}"
                    [checked]="suivi.estCochee"
                    (change)="onCheckDeclaration(suivi)"
                  />
                  <label class="custom-control-label" for="relance-{{ i }}"></label>
                </div>
              </td>
              <td>
                <div class="btn-group dropright  ">
                  <div ngbDropdown placement="right-start">
                    <button class="btn btn-secondary dropdown-toggle btnprimary btn-sm "
                            id="dropdownConfig" ngbDropdownToggle>
                      <fa-icon icon="cog"></fa-icon>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                      <button (click)="getEmails(suivi)" ngbDropdownItem translate
                              class="dropdown-item">
                        <fa-icon icon="user"></fa-icon>
                        page.suivi.suivi-inventaire.action-exporter-email
                      </button>
                      <div class="dropdown-divider"></div>
                      <button (click)="ficheEntreprise(suivi.idEntreprise)" ngbDropdownItem translate
                              class="dropdown-item">
                        <fa-icon icon="id-card"></fa-icon>
                        page.suivi.suivi-inventaire.action-fiche-entreprise
                      </button>
                      <div class="dropdown-divider"></div>
                      <button (click)="inventaireVerger(suivi)" ngbDropdownItem translate
                              class="dropdown-item">
                        <fa-icon icon="file-alt"></fa-icon>
                        page.suivi.suivi-inventaire.action-inventaire-verger
                      </button>                      
                    </div>
                  </div>
                </div>
              </td>
              <td>{{suivi.entreprise ? suivi.entreprise.raisonSociale : '-'}}</td>
              <td>{{suivi.surfacePomme | number: '1.3-3'}}</td>
              <td>{{suivi.surfacePoire | number: '1.3-3'}}</td>
              <td>{{suivi.entreprise && suivi.entreprise.refSousType ? suivi.entreprise.refSousType.libelle : '-'}}</td>              
              <td>{{suivi.dateDerniereMaj | date:'dd/MM/yyyy' }}</td>
              <td>{{suivi.dateDerniereValidation | date:'dd/MM/yyyy' }} </td>
              <td>{{suivi.auteurModification?.prenom}} {{suivi.auteurModification?.nom}}</td>
              <!-- optionnel-->
              <td *ngIf="show('anpp');"><input type="checkbox" [checked]="suivi.entreprise?.estAdherent" [disabled]="true" /></td>
              <td *ngIf="show('terroir');">{{getTerroir(suivi)}}</td>
              <td *ngIf="show('departement');">{{suivi.entreprise ? suivi.entreprise.departement : '-'}}</td>
              <td *ngIf="show('ville');">{{suivi.entreprise ? suivi.entreprise.commune : '-'}}</td>
              <td *ngIf="show('valide');"><input type="checkbox" [checked]="suivi.dateDerniereValidation" [disabled]="true" /></td>
              <td *ngIf="show('ct');" >{{suivi.ct}}</td>
              <td></td>

            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>

  </div>

  

  <div  class="btn-relance">
    <div class="row">
      <div class="col-lg-8 pt-2 pb-2">
        <div class="btn-group dropdown">
          <div ngbDropdown placement="top-start">
            <button class="btn  btn-secondary dropdown-toggle btnprimary" type="button" id="dropdownMenuButton" translate
              data-toggle="dropdown" ngbDropdownToggle aria-haspopup="true" aria-expanded="true">
              <fa-icon icon="cogs"></fa-icon>
              page.suivi.suivi-inventaire.operations-groupes
            </button>
            
            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
              <button type="button" class="dropdown-item" ngbDropdownItem (click)="relancerInventaires()" translate *ngIf="!estGP && !estCT">
                <fa-icon icon="envelope"></fa-icon> {{ 'page.suivi.suivi-inventaire.action-relancer' | translate }}
              </button>
              <div *ngIf="!estGP && !estCT" class="dropdown-divider"></div>

              <button (click)="exporterInventaires()" ngbDropdownItem translate
                      class="dropdown-item">
                <fa-icon icon="upload" style="margin-right: 5px;"></fa-icon>page.suivi.suivi-inventaire.action-exporter-csv
              </button>
              <div class="dropdown-divider"></div>
              <button (click)="exporterSynthesePdf()" ngbDropdownItem translate
                      class="dropdown-item">
                <fa-icon icon="upload" style="margin-right: 5px;"></fa-icon>page.suivi.suivi-inventaire.action-exporter-pdf
              </button>
              <div class="dropdown-divider"></div>              

              <button (click)="validerInventaires()" ngbDropdownItem translate
                      class="dropdown-item">
                <fa-icon icon="check" style="margin-right: 5px;"></fa-icon>page.suivi.suivi-inventaire.action-valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
</div>
