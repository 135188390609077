import { Component, OnInit } from '@angular/core';
import { Routes, Router, ActivatedRoute } from '@angular/router';

import { LoginComponent } from '../login/login.component';
import { Jwt } from '../../models/jwt.model';
import { AuthentificationService } from '../../services/authentification/authentification.service';
import { ApiCredentials } from '../../models/api-credentials.model';
import { Domaine } from 'src/app/data/intervenant/models/domaine.model';
import { Organisme } from 'src/app/data/intervenant/models/organisme.model';

interface FlowEvent {
  jwt: Jwt;
  domaine?: Domaine;
  organisme?: Organisme;
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  static routes: Routes = [
    { path: '', component: LoginComponent }
  ];

  private redirectPath: string;

  state: string;
  jwt: Jwt;
  idDomaine: number;
  idOrganisme: number;
  codeDomaine: string;

  private changeScope: boolean;


  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authentificationService: AuthentificationService
  ) { }

  ngOnInit() {
    this.redirectPath = this.route.snapshot.queryParamMap.get('redirect-path');
    this.changeScope = this.route.snapshot.queryParamMap.get('change-scope') === 'true';
    // console.debug('[Authentification] try to load api credential from cookie');
    this.authentificationService.getApiCredentialsFromCookie().subscribe(
      (credentials) => {
        // console.debug('[Authentification] get credentials');
        if (this.changeScope) {
          this.setFormState('domaine');
        } else {
          this.authentificationService.onNewApiCredentials(credentials);
          this.setFormState('end');
          this.redirectToApp(credentials.roles);
        }
      },
      () => {
        // console.debug('[Authentification] no credentials found, display login form');
        this.setFormState('login');
      }
    );

  }

  setFormState(state: string) {
    // console.debug('[Authentification] [formState] : ' + state);
    this.state = state;
  }

  loginSuccess(jwt: Jwt) {
    this.jwt = jwt;
    this.saveApiCredentials();

    if (jwt.type === 'ADMIN') {
      this.setFormState('end');
      this.onFlowSuccess();
    } else {
      this.setFormState('domaine');
    }
  }

  selectDomaine(event: FlowEvent) {
    this.idDomaine = event.domaine.id;
    this.codeDomaine = event.domaine.code;
    this.jwt = event.jwt;

    this.saveApiCredentials();
    this.setFormState('organisme');
  }

  selectOrganisme(event: FlowEvent) {
    this.idOrganisme = event.organisme.id;
    this.jwt = event.jwt;

    this.saveApiCredentials();
    this.setFormState('end');
    this.onFlowSuccess();
  }

  private onFlowSuccess() {
    this.authentificationService.saveCookie(this.jwt, this.idDomaine, this.idOrganisme);
    this.redirectToApp(this.jwt.roles);
  }

  private saveApiCredentials() {
    // console.debug('[Authentification] save api credentials');
    const credentials = Object.assign(new ApiCredentials(), {
      token: this.jwt.token,
      idDomaine: this.idDomaine,
      codeDomaine: this.codeDomaine,
      idOrganisme: this.idOrganisme,
      utilisateurType: this.jwt.type,
      roles: this.jwt.roles,
      idIntervenant: this.jwt.idIntervenant,
      mailIntervenant: this.jwt.mailIntervenant,
      idPortail: this.jwt.idPortail,
      codePortail: this.jwt.codePortail,
    });
    this.authentificationService.onNewApiCredentials(credentials);
  }

  private redirectToApp(roles: string[] = []) {
    // console.debug('[Authentification] [redirect] : ' + this.redirectPath);
    // setTimeout(() => this.router.navigateByUrl(this.redirectPath || '/'), 1000);
    if (roles.length === 0) {
      this.router.navigate(['/auth/no-roles']);
    } else {
      this.router.navigateByUrl(this.redirectPath || '/');
    }
  }

}
