import { JsonProperty, JsonObject, CustomConverter } from '@thorolf/json-ts-mapper';
import { StatutJure } from './statut-jure.model';
import { College } from './college.model';
import { Jure } from './jure.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';

@JsonObject
export class JureJury {
  @JsonProperty('idJure', Number)
  @CustomConverter(DataIdConverterFactory.build(Jure, 'jures'))
  jure: Jure;

  @JsonProperty('idStatut', Number)
  @CustomConverter(DataIdConverterFactory.build(StatutJure, 'statutsJure'))
  statut: StatutJure;

  @JsonProperty('idCollege', Number)
  @CustomConverter(DataIdConverterFactory.build(College, 'colleges'))
  college: College;
}
