import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { ReferencesDroitsService } from 'src/app/data/droits/services/references/references-droits.service';
import { forkJoin, Observable, of } from 'rxjs';
import { ProfilsService } from 'src/app/data/droits/services/profils/profils.service';
import { DroitProfil } from 'src/app/data/droits/models/droit-profil.model';
import { DroitFonctionnel } from 'src/app/data/droits/models/droit-fonctionnel.model';
import { flatMap, map, mergeMap } from 'rxjs/operators';
import { RolesService } from 'src/app/data/droits/services/roles/roles.service';
import { remove, uniq, groupBy, uniqBy } from 'lodash';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IdCodeLibelle } from 'src/app/data/id-code-libelle.abstract.model';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';

interface TypeUtilisateur { id: number; code: string; libelle: string; nbSoustype: number; }

@Component({
  selector: 'app-profils',
  templateUrl: './profils.component.html',
  styleUrls: ['./profils.component.scss']
})
export class ProfilsComponent implements OnInit {

  constructor(
    private readonly profilsService: ProfilsService,
    private readonly refService: ReferencesDroitsService,
    private readonly rolesServices: RolesService,
    private readonly modal: NgbModal,
    private readonly loader: AdvBootstrapLoaderService
  ) { }

  static routes: Routes = [
    {
      path: '', component: ProfilsComponent, data: {
        role: 'ADMIN',
        menu: {
          libelle: 'menu.administration-profils',
          icon: 'key'
        }
      }
    }
  ];
  droitsFonctionnels: DroitFonctionnel[];
  profils: DroitProfil[];
  typesUtilisateurs: TypeUtilisateur[];

  searchStrings: string[] = [];
  modalContext: any;

  // tri


  order: { property: string | string[], reverse: boolean } = {
    property: 'libelle',
    reverse: false
  };

  ngOnInit() {

    setTimeout(() => this.loadData());
  }

  loadData() {
    forkJoin(
      this.profilsService.getProfils().pipe(
        this.getPropertyOperator('droitsFonctionnels', profil => this.profilsService.getDroitFonctionnnel(profil.id))
      ),
      this.refService.getReferences().pipe(
        mergeMap(ref => {
          return forkJoin(
            of(ref.soustypesUtilisateurs),
            of(ref.droitsFonctionnels).pipe(
              this.getPropertyOperator('roles', droit => this.rolesServices.getRoles(droit.id))
            )
          );
        }),

      )
    ).pipe(
      this.loader.operator()
    ).subscribe(([profils, [sousTypesUtilisateur, droitsFonctionnels]]) => {
      this.profils = profils;
      this.droitsFonctionnels = droitsFonctionnels;

      // on extrait les types et on compte le nbre de sous types associés
      this.typesUtilisateurs = sousTypesUtilisateur.map(st => ({ id: st.idType, code: st.codeType, libelle: st.libelleType, nbSoustype: 0 } as TypeUtilisateur));
      this.typesUtilisateurs = uniqBy(this.typesUtilisateurs, 'code');
      this.typesUtilisateurs.forEach((t: TypeUtilisateur) => {
        t.nbSoustype = sousTypesUtilisateur.filter(st => st.idType === t.id).length;
      });
    });
  }

  getProfilsByType(codeType: string) {
    return this.profils.filter(p => p.soustype.codeType === codeType);
  }

  getPropertyOperator<T, K extends keyof T>(propertyName: K, getPropertyObservable: (item: T) => Observable<T[K]>) {
    return mergeMap((list: T[]) => {
      return forkJoin(list.map(item => {
        return getPropertyObservable(item).pipe(
          map(value => {
            item[propertyName] = value;
            return item;
          })
        );
      }));
    });
  }

  getDroitsFonctionnelsAjoutables(profil: DroitProfil) {
    return this.droitsFonctionnels
      .filter(d => d.service.id === profil.service.id)
      .filter(d => !~profil.droitsFonctionnels.findIndex(dd => dd.id === d.id));
  }

  voirRoles(modelTemplate: any, droit: DroitFonctionnel) {
    this.modalContext = { title: droit.libelle, roles: droit.roles };
    this.modal.open(modelTemplate, { size: 'lg' });
  }

  ajouterDroit(profil: DroitProfil, droit: DroitFonctionnel) {
    this.profilsService.ajouterDroitFonctionnnel(profil.id, droit).subscribe(() => {
      (profil as any).__null = null;
      profil.droitsFonctionnels.push(droit);
    });
  }

  supprimerDroit(profil: DroitProfil, droit: DroitFonctionnel) {
    this.profilsService.supprimerDroitFonctionnnel(profil.id, droit).subscribe(() => {
      remove(profil.droitsFonctionnels, droit);
    });
  }

  setOrder(property: string | string[]) {
    if (property) {
      if (property === this.order.property) {
        this.order.reverse = !this.order.reverse;
      } else {
        this.order = {
          property,
          reverse: false
        };
      }
    }
  }

  getIcon(property: string | string[]) {
    if ([].concat(property).sort().join(',') === [].concat(this.order.property).sort().join(',')) {
      return this.order.reverse ? 'sort-down' : 'sort-up';
    } else {
      return 'sort';
    }
  }

}
