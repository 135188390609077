import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { References } from '../../models/references.model';
import { Cache, ClearCache } from 'src/app/core/services/cache/cache.service';

@Injectable({
  providedIn: 'root'
})
export class ReferencesService {

  static references: References;

  constructor(
    protected readonly http: HttpClient,
    protected readonly mapper: JsonMapperService
  ) { }



  static getReferences(): References {
    const references = ReferencesService.references;
    if (references === undefined) {
      throw new Error('service-references.static-data.unavailable');
    }
    return references;
  }

  static getReference<K extends keyof References>(key: K): References[K] {
    const references = ReferencesService.getReferences();

    return references[key];
  }

  @ClearCache
  clearcache() { }

  @Cache({
    maxAge: 3600,
    key: 'references-habilitations'
  })
  public getReferences(): Observable<References> {
    return this.http.get<object>(`/api/habilitation/private/references`).pipe(
      map(response => this.mapper.deserializeObject(response, References)),
      tap(references => {
        ReferencesService.references = references;
      })
    );
  }
}
