import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-liste-valeurs-parametrables',
  templateUrl: './liste-valeurs-parametrables.component.html',
  styleUrls: ['./liste-valeurs-parametrables.component.scss']
})
export class ListeValeursParametrablesComponent implements OnInit {

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) { }

  ngOnInit() {
  }

  goToListes(liste: String){
    this.router.navigate(['.',liste], { relativeTo: this.route.parent });
  }




}
