import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { remove } from 'lodash';
import { forkJoin } from 'rxjs';
import { RefCultureBio } from 'src/app/data/declaration/models/ref-culture-bio.model';
import { RefCultureBioService } from 'src/app/data/declaration/services/parcellaire/ref-culture-bio.service';

@Component({
  selector: 'app-liste-cultures-bio',
  templateUrl: './liste-cultures-bio.component.html',
  styleUrls: ['./liste-cultures-bio.component.scss']
})
export class ListeCulturesBioComponent implements OnInit {

  culturesBio : RefCultureBio[];

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalsService : AdvBootstrapModalService,
    private readonly translate: TranslateService,
    private readonly culturesBioService : RefCultureBioService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
 
    forkJoin(
      this.culturesBioService.getRefCultureBio()
      )
    .pipe(this.loaderService.operator())
    .subscribe(([culturesBio]) => {
      this.culturesBio = culturesBio;     
    });

    

  }

  openConfirmationDialog(cultureBio: RefCultureBio) { 

    this.modalsService.confirm(
      'Veuillez confirmer',
      'Voulez vous supprimer la référence culture Bio '+ cultureBio.libelle + ' ?', {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.deleteCultureBio(cultureBio);
    }, () => { });

  }

  deleteCultureBio(cultureBio: RefCultureBio) {
    this.culturesBioService.desactiverRefCultureBio(cultureBio).subscribe(() => {
      remove(this.culturesBio, cultureBio);
    });
  }

  editCultureBio(cultureBio: RefCultureBio) {
    this.modalsService.confirm(
      'Veuillez confirmer',
      this.translate.instant(`page.valeur_parametrable.message_modification`), {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.router.navigate(['edit', cultureBio.id], { relativeTo: this.route });
    }, () => { });
  }

}
