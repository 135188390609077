import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { MockToken } from '../../models/mock-token.model';
import { DomaineCode } from 'src/app/data/intervenant/models/enums/domaine-code.enum';
import { Inscription } from '../../models/inscription.model';
import { InscriptionVin } from '../../models/inscription-vin.model';

@Injectable({
  providedIn: 'root'
})
export class PortailService {
  constructor(
    protected readonly http: HttpClient,
    private readonly mapper: JsonMapperService
  ) { }

  getMockToken(): Observable<MockToken> {
    return this.http.get<object>(`/api/intervenant/public/mock/portail/token`)
      .pipe(
        map(token => this.mapper.deserializeObject(token, MockToken))
      );
  }

  private inscription<T extends Inscription>(inscription: T, url: string): Observable<void> {
    return this.getMockToken().pipe(
      flatMap(mockToken => {
        const httpHeaders = {};
        httpHeaders[mockToken.authHeader] = mockToken.authValue;
        httpHeaders[mockToken.portailHeader] = mockToken.portailCode;

        return this.http.post<void>(url, this.mapper.serialize(inscription), { headers: new HttpHeaders(httpHeaders) });
      })
    );
  }

  inscriptionVin(inscription: InscriptionVin) {
    return this.inscription(inscription, '/api/intervenant/portal/portail/inscriptionVin');
  }
}
