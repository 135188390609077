import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { ClearCache, Cache, CacheKey } from 'src/app/core/services/cache/cache.service';
import { DroitRole } from '../../models/droit-role.model';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
  ) { }

  @ClearCache
  clearCache() { }

  @Cache()
  getRoles(@CacheKey idDroitFonctionnel: number): Observable<DroitRole[]> {
    return this.http.get<object[]>(`/api/intervenant/private/droits-fonctionnels/${idDroitFonctionnel}/roles`).pipe(
      map(roles => this.mapper.deserializeArray(roles, DroitRole))
    );
  }

}
