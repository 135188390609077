import {
  CustomConverter,
  JsonObject,
  JsonProperty,
  Optional,
} from "@thorolf/json-ts-mapper";
import {
  DateConverter,
  DateTimeConverter,
} from "src/app/core/services/mapper/converters";
import { Contact } from "src/app/data/intervenant/models/contact.model";
import { ContactEngagement } from "./contact-engagement";
import { EngagementDetail } from "./engagement-detail";
import { EngagementSc } from "./engagement-sc";
import { EntrepriseEngagement } from "./entreprise-engagement";
import { IntervenantEngagement } from "./intervenant-engagement";
import { RefCampagne } from "./ref.campagne.model";
import { LaboratoireAnpp } from "./laboratoire-anpp.model";

@JsonObject
export class EngagementBase {
  @JsonProperty("id", Number)
  @Optional
  public id: number = undefined;

  @JsonProperty("refCampagne", RefCampagne)
  public refCampagne: RefCampagne;

  @JsonProperty("entreprise", EntrepriseEngagement)
  public entreprise: EntrepriseEngagement = undefined;

  @JsonProperty("utilisationLogo", Boolean)
  public utilisationLogo: boolean = undefined;

  @JsonProperty("activiteProduction", Boolean)
  public activiteProduction: boolean = undefined;

  @JsonProperty("activiteConditionnement", Boolean)
  public activiteConditionnement: boolean = undefined;

  @JsonProperty("engagementAnimationTechnique", Boolean)
  public engagementAnimationTechnique: boolean = undefined;

  @JsonProperty("organismeCertification", EntrepriseEngagement)
  public organismeCertification: EntrepriseEngagement = undefined;

  @JsonProperty("responsable", ContactEngagement)
  public responsable: ContactEngagement = undefined;

  @JsonProperty("referent", ContactEngagement)
  public referent: ContactEngagement = undefined;

  @JsonProperty("conseillerTechnique", IntervenantEngagement)
  @Optional
  public conseillerTechnique: IntervenantEngagement = undefined;

  @JsonProperty("conseillerTechnique2", IntervenantEngagement)
  @Optional
  public conseillerTechnique2: IntervenantEngagement = undefined;

  @JsonProperty("listEngagementDetail", [EngagementDetail])
  @Optional
  public listEngagementDetail: EngagementDetail[] = undefined;

  @JsonProperty("listEngagementSc", [EngagementSc])
  @Optional
  public listEngagementSc: EngagementSc[] = undefined;

  @JsonProperty("dateAudit", String)
  @CustomConverter(DateConverter)
  @Optional
  public dateAudit: moment.Moment;

  @JsonProperty("idStatut", Number)
  @Optional
  public idStatut: number = undefined;

  @JsonProperty("libelleStatut", String)
  @Optional
  public libelleStatut: string = undefined;

  @JsonProperty("codeStatut", String)
  @Optional
  public codeStatut: string = undefined;

  @JsonProperty("libelleStatut", String)
  @Optional
  public libelleStatutPrecedent: string = undefined;

  @JsonProperty("codeStatut", String)
  @Optional
  public codeStatutPrecedent: string = undefined;

  @JsonProperty("dateEngagement", String)
  @CustomConverter(DateTimeConverter)
  @Optional
  public dateEngagement: moment.Moment = undefined;

  @JsonProperty("laboratoireResidus", LaboratoireAnpp)
  @Optional
  public laboratoireResidus: LaboratoireAnpp = undefined;

  @JsonProperty("laboratoireSols", LaboratoireAnpp)
  @Optional
  public laboratoireSols: LaboratoireAnpp = undefined;
}
