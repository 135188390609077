<div class="border-radius-onglet anpp">

  <form [formGroup]="formGroup" #form="ngForm" >
    <div class="row tableau">
      <div class="col-lg-12">
        <div class="cards  border-radius-onglet text-center" style="background:#fff; min-width: 1100px;">
          <div *ngIf="!entrepriseId">
            <tr translate><fa-icon icon="exclamation" class="mt-2 p-2"></fa-icon> page.recoltesPp.no_adherent_selectionne</tr>
          </div>
          <div *ngIf="entrepriseId">
            <div class="row" >
              <div class="col-lg-8 text-left pl-4 pt-4">
                <!--<a href="#" class="pt-2 pl-2 mr-2 link-secondary" type="button" data-toggle="modal" data-target="#fiche">
                  <fa-icon class="mr-1" icon='id-card'></fa-icon>
                  <span style="text-decoration:underline" translate>page.recoltesPp.nomProducteur</span>
                </a>-->
                <span (click)="ajouterGroupeVarietal()" class="pt-2 pl-2 mr-2 lienMain" type="button">
                  <fa-icon class="mr-1" icon='plus-square' ></fa-icon>
                  <span style="text-decoration:underline" translate>page.recoltesPp.ajoutGrpVarietal</span>
                </span>
                <span (click)="ajouterCommentaire()" class="pt-2 pl-2 mr-2 lienMain" type="button">
                  <fa-icon class="mr-1" icon='comment-dots' ></fa-icon>
                  <span style="text-decoration:underline" translate>page.recoltesPp.commentaire</span>
                </span>
                <select formControlName="selectionCampagne" class="form-control" (change)="onCampagneChange()"
                  style="display:inline; width:auto">
                  <option [value]="null" hidden></option>
                  <option *ngFor="let camp of campagnes" [ngValue]="camp" [selected]="camp.annee === campagne?.annee">
                    {{camp.libelle}}</option>
                </select>

              </div>


              <div class="col-lg-4 text-right">
              </div>
            </div>
<div class="tableScroll">
            <table class="table table-striped mt-2">
              <thead class="center sticky-top">
                <tr>
                  <th scope="col"> </th>
                  <th scope="col" class="text-left bar1">
                    <div ><span translate>page.recoltesPp.table.header.groupeVarietal</span></div>
                  </th>
                  <th class="" scope="col" colspan="2">
                    <div ><span>Rappel {{campagnePrecedente?.libelle}}</span></div>
                  </th>

                  <th class="" scope="col" colspan="2" *ngIf="!estDefinitive"><span
                      translate>page.recoltesPp.table.header.dernierePrevision</span></th>
                  <th scope="col" colspan="2" class="bg-td "> <span> {{(estDefinitive ?
                      'page.recoltesPp.table.header.recolteDefinitive' : 'page.recoltesPp.table.header.nouvelleSaisie') |
                      translate}}</span></th>
                  <th class="" *ngIf="show('surfTotale') || show('surfBio');" scope="col"
                    [colSpan]="show('surfTotale') && show('surfBio') ? '2' : '1'"> <span
                      translate>page.recoltesPp.table.header.surfDeclaree</span>
                  </th>
                  <th *ngIf="show('rdtTotalPrec') || show('rdtBioPrec');" scope="col"
                    [colSpan]="show('rdtTotalPrec') && show('rdtBioPrec') ? '2' : '1'" class=""> <span
                      translate>page.recoltesPp.table.header.rendementCampagnePrecedente</span>
                  </th>
                  <th *ngIf="show('prevInitialeTotale');" scope="col" colspan="1" class=""> <span
                      translate>page.recoltesPp.table.header.previsionInitiale</span></th>
                  <th *ngIf="show('rdtInitialTotal');" scope="col" colspan="1" class=""> <span
                      translate>page.recoltesPp.table.header.rendementInitial</span></th>
                  <th *ngIf="show('prevInitialeBio');" scope="col" colspan="1" class=""> <span
                      translate>page.recoltesPp.table.header.previsionInitiale</span></th>
                  <th *ngIf="show('rdtInitialBio');" scope="col" colspan="1" class=""> <span
                      translate>page.recoltesPp.table.header.rendementInitial</span></th>
                  <th *ngIf="show('rdtTotalDernierePrev') || show('rdtBioDernierePrev') || estDefinitive;" scope="col"
                    [colSpan]="((show('rdtTotalDernierePrev') && show('rdtBioDernierePrev')) || estDefinitive ) ? '2' :'1'" class="">
                    <span>{{(estDefinitive ? 'page.recoltesPp.table.header.rendementRecolteDefinitive' : 'page.recoltesPp.table.header.rendementDernierePrev') | translate}}</span>
                  </th>
                  <th>
                    <fa-icon class="clickable-icon" icon="ellipsis-h" (click)="afficherListe()"></fa-icon>
                  </th>

                </tr>
                <tr>
                  <!--icone-->
                  <th scope="col" style="position:relative; width:60px;"></th>
                  <!--groupe varietal-->
                  <th scope="col" style="position:relative"></th>
                  <!--campagne-->
                  <th scope="col" class="center"><small><strong translate>page.recoltesPp.table.header.qteTotale</strong></small></th>
                  <th scope="col" class="center"><small><strong translate>page.recoltesPp.table.header.dontBioT</strong></small></th>
                  <!-- dernière prévision-->
                  <th scope="col" class="center" *ngIf="!estDefinitive" ><small><strong translate>page.recoltesPp.table.header.qteTotale</strong></small>
                  </th>
                  <th scope="col" class="center" *ngIf="!estDefinitive" ><small><strong translate>page.recoltesPp.table.header.dontBioT</strong></small>
                  </th>
                  <!--nouvelle saisie-->
                  <th scope="col" class="center bg-td" ><small><strong translate>page.recoltesPp.table.header.qteTotale</strong></small></th>
                  <th scope="col" class="center bg-td" ><small><strong translate>page.recoltesPp.table.header.dontBioT</strong></small></th>
                  <!--surface-->
                  <th *ngIf="show('surfTotale');" scope="col" class="center" ><small><strong translate>page.recoltesPp.table.header.totaleHa</strong></small>
                  </th>
                  <th *ngIf="show('surfBio');" scope="col" class="center" ><small><strong translate>page.recoltesPp.table.header.dontBioHa</strong></small>
                  </th>
                  <!--rendement-->
                  <th *ngIf="show('rdtTotalPrec');" scope="col" class="center" ><small><strong translate>
                    page.recoltesPp.table.header.total</strong></small></th>
                  <th *ngIf="show('rdtBioPrec');" scope="col" class="center" ><small><strong translate>page.recoltesPp.table.header.bio</strong></small>
                  </th>
                  <!--prévision initiale -->
                  <th *ngIf="show('prevInitialeTotale');" scope="col" class="center" ><small><strong translate>
                    page.recoltesPp.table.header.totaleT</strong></small></th>
                  <!--rendement initial -->
                  <th *ngIf="show('rdtInitialBio');" scope="col" class="center" ><small><strong translate>
                    page.recoltesPp.table.header.total</strong></small></th>
                  <!--prévision initiale bio -->
                  <th *ngIf="show('prevInitialeBio');" scope="col" class="center" ><small><strong translate>
                    page.recoltesPp.table.header.bioT</strong></small></th>
                  <!--rendement initial -->
                  <th *ngIf="show('rdtInitialBio');" scope="col" class="center" ><small><strong translate>page.recoltesPp.table.header.bio</strong></small>
                  </th>
                  <!--rendement -->
                  <th *ngIf="show('rdtTotalDernierePrev') || estDefinitive" scope="col" class="center" ><small><strong translate>
                    page.recoltesPp.table.header.totale</strong></small></th>
                  <!--rendement -->
                  <th *ngIf="show('rdtBioDernierePrev') || estDefinitive" scope="col" class="center" ><small><strong translate>
                    page.recoltesPp.table.header.bio</strong></small></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="recoltes && recoltes.length === 0">
                  <td colspan="99" class="p-4"><fa-icon icon="exclamation"></fa-icon>&nbsp;
                    <span translate>page.recoltesPp.table.no-data</span>
                  </td>
                </tr>
                <tr *ngFor="let recolte of recoltes ; let i = index"
                  [ngClass]="{'separation-pomme-poire' : recolte.isTotal && recolte.codeEspece === 'POMME', 'font-weight-bold' : recolte.isTotal }">
                  <td>
                    <span *ngIf="recolte.codeEspece==='POMME'">
                      <fa-icon icon='apple-alt' style="color:black;"></fa-icon>
                      <fa-icon *ngIf="recolte.isTotal" icon='apple-alt' style="color:black;"></fa-icon>
                    </span>
                    <span *ngIf="recolte.codeEspece==='POIRE'">
                      <img src="/assets/img/pear.png" width="16px" />
                      <img *ngIf="recolte.isTotal" src="/assets/img/pear.png" width="16px" />
                    </span>
                  </td>
                  <td scope="col" class="text-left">
                    <span class="souligne" placement="top" ngbTooltip="{{ recolte.groupeVarietalVarietes}}"><b>{{recolte.groupeVarietal}}</b></span>
                  </td>
                  <td scope="col" class="center">{{recolte.qteTotalePrecedente | saisieRecolteFormat}}</td>
                  <td scope="col" class="center">{{recolte.qteBioPrecedente | saisieRecolteFormat}}</td>
                  <td scope="col" class="center" *ngIf="!estDefinitive">{{recolte.qteTotaleDernierePrevision |
                    saisieRecolteFormat}}</td>
                  <td scope="col" class="center" *ngIf="!estDefinitive">{{recoltes[i].qteBioDernierePrevision |
                    saisieRecolteFormat}}</td>
                  <td scope="col" class="center bg-td">
                    <input *ngIf="!recolte.isTotal" type="number" class="form-control recolte-input" placeholder="Qté Totale" style="width:90px" (keypress)="keyPressNumbers($event)"
                      [ngModelOptions]="{standalone: true}" [(ngModel)]="recoltes[i].qteTotale" min="0" max="10000" 
                      name="qteTotale{{i}}" (change)="updateTotaux()" required />
                    <span *ngIf="recolte.isTotal">{{recolte.qteTotale}}</span>
                  </td>

                  <td scope="col" class="center bg-td">
                    <input *ngIf="!recolte.isTotal" type="number" class="form-control recolte-input" placeholder="Qté bio" style="width:90px; text-align: center !important;"
                      [ngModelOptions]="{standalone: true}" [(ngModel)]="recolte.qteBio" min="0" max="10000" 
                      name="qteBio{{i}}" (change)="updateTotaux()" (keypress)="keyPressNumbers($event)"/>
                    <span *ngIf="recolte.isTotal">{{recolte.qteBio}}</span>
                  </td>

                  <td *ngIf="show('surfTotale');">{{recolte.surfaceTotale | saisieRecolteFormat : '3'}}</td>
                  <td *ngIf="show('surfBio');">{{recolte.surfaceBio | saisieRecolteFormat : '3'}}</td>
                  <td *ngIf="show('rdtTotalPrec');">{{recolte.getRdtTotalPrecedent() | saisieRecolteFormat : '2'}}</td>
                  <td *ngIf="show('rdtBioPrec');">{{recolte.getRdtBioPrecedent() | saisieRecolteFormat : '2'}}</td>
                  <td *ngIf="show('prevInitialeTotale');">{{recolte.previsionInitialeTotale | saisieRecolteFormat}}</td>
                  <td *ngIf="show('rdtInitialTotal');">{{recolte.getRdtInitialTotal() | saisieRecolteFormat : '2'}}</td>
                  <td *ngIf="show('prevInitialeBio');">{{recolte.previsionInitialeBio | saisieRecolteFormat}}</td>
                  <td *ngIf="show('rdtInitialBio');">{{recolte.getRdtInitialBio() | saisieRecolteFormat : '2'}}</td>
                  <td *ngIf="show('rdtTotalDernierePrev') || estDefinitive;">{{(estDefinitive?recolte.getRdtTotal():recolte.getRdtTotalDernierePrevision()) |
                    saisieRecolteFormat : '2'}}</td>
                  <td *ngIf="show('rdtBioDernierePrev') || estDefinitive;">{{recolte.getRdtBioDernierePrevision() | saisieRecolteFormat : '2'}}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>       
        </div>
      </div>
      <p>&nbsp;</p>
    </div>
    <div style="position:fixed; bottom:10px;right:25px">
      <button type="button" #submitButton (click)="onSave()" style="background:#41905c;border:1px solid #41905c"
        class="btn btn-lg btn-secondary  btnprimary">Enregistrer</button>
    </div>
  </form>
</div>