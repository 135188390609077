import { JsonProperty, JsonObject } from '@thorolf/json-ts-mapper';

@JsonObject
export class OperateurInformationsDomaine {

  @JsonProperty('libelle', String)
  libelle: string = undefined;

  @JsonProperty('code', String)
  code: string = undefined;

  @JsonProperty('valeur', String)
  valeur: string = undefined;
}
