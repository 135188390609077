import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { Structure } from 'src/app/data/intervenant/models/structure.model';
import { StructuresService } from 'src/app/data/intervenant/services/structures/structures.service';

@Component({
  selector: 'app-edit-structure',
  templateUrl: './edit-structure.component.html',
  styleUrls: ['./edit-structure.component.scss']
})
export class EditStructureComponent implements OnInit {

  formGroup: FormGroup;
  creation = false;
  title: string;
  structure: Structure;

  get code() { return this.formGroup.get('code'); }
  get libelle() { return this.formGroup.get('libelle'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly structureService: StructuresService,) { }

  ngOnInit() {

    const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    if (!id) {
      this.creation = true;
    }
      this.loadData(id);
    

  }

  loadData(id: number) {

    this.title = this.creation ? 'page.valeur_parametrable.form.title.add_structure': 'page.valeur_parametrable.form.title.edit_structure';

    forkJoin(
    !this.creation ? this.structureService.getStructure(+id) : of(new Structure())
    )
    .pipe(
      this.loaderService.operator()
    ).subscribe(([structure]) => {

      this.structure = structure;    
        this.initForm((structure.id) ? structure : null);
     
    });
  }

  private initForm(structure?: Structure): void {
 
    this.formGroup = this.fb.group({
      code: [{ value: ((structure) ? structure.code: undefined), disabled: false }, Validators.required],
      libelle: [{ value: ((structure) ? structure.libelle : undefined), disabled: false }, Validators.required]
    });
  }

  submit() {
    if (this.formGroup.valid) {
      this.structure.code = this.code.value;
      this.structure.libelle = this.libelle.value;
     
      if (this.structure.id == null) {
        this.structureService.creerStructure(this.structure).subscribe(idFonction => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
        });
      } else {        
        this.structureService.modifierStructure(this.structure).subscribe( () => { 
          this.router.navigate(['.'], { relativeTo: this.route.parent });
         } );
      }
     
    }
  }

}
