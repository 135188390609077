import { JsonProperty, CustomConverter, JsonObject, Optional } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { Produit } from '../../habilitation/models/produit.model';
import { RefMouvement } from './ref-mouvement.model';
import { Operateur } from '../../intervenant/models/operateur.model';

@JsonObject
export class HistoriqueDpap {

    @JsonProperty('idOperateur', Number)
    idOperateur: number;

    @JsonProperty('idDeclaration', Number)
    idDeclaration: number;

    @JsonProperty('idCahier', Number)
    idCahier: number;

    @JsonProperty('libelleCahier', String)
    libelleCahier: string;

    @JsonProperty('date', String)
    @CustomConverter(DateConverter)
    date: moment.Moment;

    @JsonProperty('campagne', String)
    campagne: string;
   

    @JsonProperty('surfaceEngagee', Number)
    @Optional
    surfaceEngagee: number;

    @JsonProperty('surfacePlantee', Number)
    @Optional
    surfacePlantee: number;

   
}
