import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

export interface Version {
  name: string;
  version: string;
}


@Injectable({
  providedIn: 'root'
})
export class VersionsService {

  constructor(
    private readonly http: HttpClient
  ) { }

  getVersions(): Observable<Version[]> {
    return forkJoin(
      this.getServiceVersion('intervenant'),
      this.getServiceVersion('habilitation'),
      this.getServiceVersion('declaration')
    );
  }
  private getServiceVersion(serviceName: string): Observable<Version> {
    return this.http.get(`/api/${serviceName}/public/info/service/version`, { responseType: 'text' }).pipe(
      catchError(() => {
        return of('unavailable');
      }),
      map(version => {
        return { name: serviceName, version };
      })
    );
  }
}
