import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ConnexionOperateurComponent } from './pages/connexion-operateur/connexion-operateur.component';
import { InscriptionComponent } from './pages/inscription/inscription.component';
import { MainComponent } from './pages/main/main.component';
import { NoRolesComponent } from './pages/no-roles/no-roles.component';
import { RedirectionComponent } from './pages/redirection/redirection.component';
import { UserDisabledComponent } from './pages/user-disabled/user-disabled.component';
import { CreerMdpComponent } from './pages/password/creer-mdp/creer-mdp.component';
import { AgrimakerComponent } from './pages/agrimaker/agrimaker.component';



const routes: Routes = [
  {
    path: 'auth', data: { skipGuard: true }, children: [
      { path: 'login', component: MainComponent },
      { path: 'inscription', component: InscriptionComponent },
      { path: 'connexion', component: ConnexionOperateurComponent },
      { path: 'redirection', component: RedirectionComponent },
      { path: 'no-roles', component: NoRolesComponent },
      { path: 'disabled', component: UserDisabledComponent },
      { path: 'creer-mdp', component: CreerMdpComponent },
      { path: 'agrimaker', component: AgrimakerComponent }
    ],
    
  }   
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthentificationRoutingModule { }
