<div class="content">
  <div class="content-panel-overflow">
    <form [formGroup]="formGroup" #form="ngForm">
      <label translate>page.declarations.synthese.annee</label> :
      <input formControlName="campagne" maxlength="4" [ngClass]="{'is-invalid': campagne.dirty && campagne.invalid}"
        class="form-control campagne-input" />
    </form>

    <!-- Produits AOC -->
    <div *ngIf="synthesesVolumesAOC">
      <h2>Produits AOC</h2>
      <table class="table" *ngIf="synthesesVolumesAOC.length; else aucunProduit">
        <thead>
          <tr>
            <th translate>page.declarations.synthese.table.header.produit</th>
            <th translate>page.declarations.synthese.table.header.surface</th>
            <th translate>page.declarations.synthese.table.header.volumesNetsRevendiques</th>
            <th translate>page.declarations.synthese.table.header.volumesAchats</th>
            <th translate>page.declarations.synthese.table.header.variationsChangementsDeno</th>
            <th translate>page.declarations.synthese.table.header.volumesStockVrac</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let synthese of synthesesVolumesAOC">
            <td>{{synthese.produit?.libelle}}</td>
            <td>{{synthese.surface | number:'1.4-4' }}</td>
            <td>{{synthese.volumesNetsRevendiques | number:'1.2-2' }}</td>
            <td>{{synthese.volumesAchats | number:'1.2-2' }}</td>
            <td>
              {{synthese.variationsChangementsDeno>0 ? '+' : ''}}{{synthese.variationsChangementsDeno | number:'1.2-2' }}
            </td>
            <td>{{synthese.volumesStockVrac | number:'1.2-2' }}</td>
            <td>
              <fa-icon class="clickable-icon icon-with-background" icon="exchange-alt"
                (click)="changementDenomination(synthese)"
                title="{{'page.declarations.synthese.changementProduit' | translate}}"></fa-icon>
              <fa-icon *hasHabilitation="'VIN_PROD_CONDITIONNEMENT';cahier synthese.produit?.idCahier;elseDisable"
                class="clickable-icon icon-with-background" icon="wine-bottle" (click)="saisirConditionnement(synthese)"
                title="{{'page.declarations.synthese.saisirConditionnement' | translate}}"></fa-icon>
              <fa-icon
                *hasHabilitation="'VIN_PROD_CONDITIONNEMENT || VIN_PROD_VIGNIFICATION';cahier synthese.produit?.idCahier;elseDisable"
                class="clickable-icon icon-with-background" icon="euro-sign"
                (click)="transaction('AOC', synthese.produit)"
                title="{{'page.declarations.synthese.saisirTransaction' | translate}}"></fa-icon>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="add-produit-bar">
        <button type="button" class="btn btn-primary" (click)="changementDenominationHorsBacchus('AOC')">
          <fa-icon icon="exchange-alt"></fa-icon><span translate>page.declarations.synthese.autre-produit</span>
        </button>
      </div>
    </div>

    <!-- Produits IGP -->
    <div *ngIf="synthesesVolumesIGP">
      <h2>Produits IGP</h2>
      <table class="table" *ngIf="synthesesVolumesIGP.length; else aucunProduit">
        <thead>
          <tr>
            <th translate>page.declarations.synthese.table.header.produit</th>
            <th translate>page.declarations.synthese.table.header.surface</th>
            <th translate>page.declarations.synthese.table.header.volumesIssusDR</th>
            <th translate>page.declarations.synthese.table.header.volumesRevendiques</th>
            <th translate>page.declarations.synthese.table.header.variationChgtDeno</th>
            <th translate>page.declarations.synthese.table.header.variationDeclassement</th>
            <th translate>page.declarations.synthese.table.header.resteARevendiquer</th>
            <th translate>page.declarations.synthese.table.header.volumesConditionnements</th>
            <th translate>page.declarations.synthese.table.header.volumesTransactions</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let synthese of synthesesVolumesIGP">
            <td>{{synthese.produit?.libelle}}</td>
            <td>{{synthese.surface | number:'1.4-4' }}</td>
            <td>{{synthese.volIssusDr | number:'1.2-2' }}</td>
            <td>{{synthese.volRevendiques | number:'1.2-2' }}</td>
            <td>{{synthese.varRevendiqueChangementsDeno | number:'1.2-2' }}</td>
            <td>{{synthese.varRevendiqueDeclassement | number:'1.2-2' }}</td>
            <td [ngClass]="{'est-negatif': synthese.resteARevendiquer < 0}">
              {{synthese.resteARevendiquer | number:'1.2-2' }}</td>
            <td>{{synthese.volConditionnements | number:'1.2-2' }}</td>
            <td>{{synthese.volTransactions | number:'1.2-2' }}</td>
            <td>
              <fa-icon class="clickable-icon icon-with-background" icon="pen" (click)="modifierDr(synthese)"
                       *ngIf="SessionContext.get('utilisateurType') !== UtilisateurTypeCode.OPERATEUR"
              title="{{'page.declarations.synthese.modifierDr' | translate}}"></fa-icon>
              <fa-icon class="clickable-icon icon-with-background" icon="exchange-alt"
                (click)="changementDenominationIgp(synthese)"
                title="{{'page.declarations.synthese.changementProduit' | translate}}"></fa-icon>
              <fa-icon *hasHabilitation="'VIN_PROD_CONDITIONNEMENT';cahier synthese.produit?.idCahier;elseDisable"
                class="clickable-icon icon-with-background" icon="wine-bottle"
                (click)="saisirConditionnementIgp(synthese)"
                title="{{'page.declarations.synthese.saisirConditionnement' | translate}}"></fa-icon>
              <fa-icon
                *hasHabilitation="'VIN_PROD_CONDITIONNEMENT || VIN_PROD_VIGNIFICATION';cahier synthese.produit?.idCahier;elseDisable"
                class="clickable-icon icon-with-background" icon="euro-sign"
                (click)="transaction('IGP', synthese.produit)"
                title="{{'page.declarations.synthese.saisirTransaction' | translate}}"></fa-icon>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="add-produit-bar-bottom">
        <button type="button" class="btn btn-primary" (click)="SaisirRevendicationIgp()">
          <fa-icon icon="sign-in-alt"></fa-icon><span translate>page.declarations.synthese.saisirRevendication</span>
        </button>
        <button type="button" class="btn btn-primary" (click)="changementDenominationHorsBacchus('IGP')">
          <fa-icon icon="exchange-alt"></fa-icon><span translate>page.declarations.synthese.autre-produit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #aucunProduit>
  <div class="alert alert-warning alert-block" translate [translateParams]="{annee:annee}">
    <fa-icon icon="exclamation"></fa-icon>
    page.declarations.synthese.table.aucunProduit
  </div>
  <ng-template>
