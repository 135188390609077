import { JsonProperty, JsonObject, Optional, CustomConverter } from '@thorolf/json-ts-mapper';

import * as moment from 'moment';

import { DateTimeConverter } from 'src/app/core/services/mapper/converters';
import { Jury } from './jury.model';
import { Salle } from './salle.model';
import { Animateur } from './animateur.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';

@JsonObject
export class Commission {
  @JsonProperty('id', Number)
  @Optional
  id: number;

  @JsonProperty('dateDebut', String)
  @CustomConverter(DateTimeConverter)
  dateDebut: moment.Moment;

  @JsonProperty('dateFin', String)
  @CustomConverter(DateTimeConverter)
  dateFin: moment.Moment;

  @JsonProperty('numero', String)
  @Optional
  numero: string;

  @JsonProperty('idSalle', Number)
  @CustomConverter(DataIdConverterFactory.build(Salle, 'salles'))
  salle: Salle;

  @JsonProperty('jurys', [Jury])
  jurys: Jury[];

  @JsonProperty('idAnimateur', Number)
  @CustomConverter(DataIdConverterFactory.build(Animateur, 'animateurs'))
  animateur: Animateur;

  @JsonProperty('terminee', Boolean)
  terminee: boolean;
}
