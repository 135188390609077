import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { remove } from 'lodash';
import { forkJoin } from 'rxjs';
import { RefProtectionGel } from 'src/app/data/declaration/models/ref-protection-gel.model';
import { RefProtectionGelService } from 'src/app/data/declaration/services/parcellaire/ref-protection-gel.service';

@Component({
  selector: 'app-listes-protections-gel',
  templateUrl: './listes-protections-gel.component.html',
  styleUrls: ['./listes-protections-gel.component.scss']
})
export class ListesProtectionsGelComponent implements OnInit {

  protectionsGel : RefProtectionGel[];

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalsService : AdvBootstrapModalService,
    private readonly translate: TranslateService,
    private readonly protectionsGelService : RefProtectionGelService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
 
    forkJoin(
      this.protectionsGelService.getRefProtectionGel()
      )
    .pipe(this.loaderService.operator())
    .subscribe(([protectionsGel]) => {
      this.protectionsGel = protectionsGel;     
    });

    

  }

  openConfirmationDialog(protectionGel: RefProtectionGel) { 

    this.modalsService.confirm(
      'Veuillez confirmer',
      'Voulez vous désactiver la protection gel '+ protectionGel.libelle + ' ?', {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.deleteProtectionGel(protectionGel);
    }, () => { });

  }

  deleteProtectionGel(protectionGel: RefProtectionGel) {
    this.protectionsGelService.desactiverRefProtectionGel(protectionGel).subscribe(() => {
      remove(this.protectionsGel, protectionGel);
    });
  }

  editProtectionGel(protectionGel: RefProtectionGel) {
    this.modalsService.confirm(
      'Veuillez confirmer',
      this.translate.instant(`page.valeur_parametrable.message_modification`), {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.router.navigate(['edit', protectionGel.id], { relativeTo: this.route });
    }, () => { });
  }
}
