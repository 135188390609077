import { JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";

@JsonObject
export class RefTypeInteraction {
  @JsonProperty('id', Number)
  @Optional
  public id: number;

  @JsonProperty('code', String)
  @Optional
  public code: string;

  @JsonProperty('libelle', String)
  @Optional
  public libelle: string;
}