import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Jure } from 'src/app/data/commission/models/jure.model';
import { AbstractRechercheItemComponent } from 'src/app/main/pages/commissions/pages/edit-commission/modals/abstract-recherche-items.component';
import { FormBuilder, NgForm, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DocumentService } from 'src/app/data/edition/services/document/document.service';
import { ReferencesEditionService } from 'src/app/data/edition/services/references-edition/references-edition.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Observable } from 'rxjs';
import { Commission } from 'src/app/data/commission/models/commission.model';
import { EditionService } from 'src/app/data/edition/services/edition.service';
import { Document } from 'src/app/data/edition/models/document.model';
import { CommissionEdition } from 'src/app/data/edition/models/commission-edition.model';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { MapperEditionService } from 'src/app/data/edition/services/mapper-edition/mapper-edition.service';
import { FileDownloadTools } from 'src/app/shared/file-download-tools/file-download-tools';
import { CommissionEditionMail } from 'src/app/data/edition/models/commission-mail.model';
import { RefCategorie } from 'src/app/data/edition/models/ref-categorie.model';
import { Fichier } from 'src/app/data/edition/models/fichier.model';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { TranslateService } from '@ngx-translate/core';
import { OrganismeEdition } from 'src/app/data/edition/models/organisme-edition.model';

@Component({
  selector: 'app-select-jures',
  templateUrl: './select-jures.component.html',
  styleUrls: ['./select-jures.component.scss']
})
export class SelectJuresComponent extends AbstractRechercheItemComponent<Jure> implements OnInit {
  @ViewChild('form') form: NgForm;
  formGroup: FormGroup;
  @Input() commission: Commission;
  @Input() categorie: RefCategorie;
  @Input() documents: Document[];
  jures: Jure[] = [];
  noJures: boolean = false;
  getData(): Observable<Jure[]> {
    throw new Error('Method not implemented.');
  }

  constructor(
    private readonly fb: FormBuilder,
    public readonly modal: NgbActiveModal,
    private readonly editionService: EditionService,
    private readonly operateursService: OperateursService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService
  ) {
    super(modal);
  }

  ngOnInit() {
    this.jures = this.getJuresCommission(this.commission);

    this.formGroup = this.fb.group({
      jure: [undefined, Validators.required],
    });
  }

  public onClose(): void { this.modal.dismiss(); }


  private getJuresCommission(commission: Commission): Jure[] {
    const jures: Jure[] = [];
    commission.jurys.forEach(jury => {
      jury.jures.forEach(juryJure => jures.push(juryJure.jure));
    });

    return jures;
  }

  private getMailsJuresChecked(jures: Jure[]): string[] {
    const result: string[] = [];
    jures.forEach(j => {
      if (j.checked) {
        result.push(j.mail);
      }
    });
    return result;
  }

  private getDocumentsChecked(documents: Document[]): number[] {
    const result: number[] = [];
    documents.forEach(d => {
      if (d.checked) {
        result.push(d.id);
      }
    });
    return result;
  }

  private setJuresCheckedNull(): void {
    this.jures.forEach(jure => {
      jure.checked = false;
    });
  }

  isJuresCheck(): void {
    const mailsJures: string[] = this.getMailsJuresChecked(this.jures);
    if(mailsJures && mailsJures.length >0) {
      this.noJures = false;
    }
  }
  onSubmit() {
    const mailsJures: string[] = this.getMailsJuresChecked(this.jures);
    if(mailsJures && mailsJures.length >0) {
      this.onClose();
    
      const documents: number[] = this.getDocumentsChecked(this.documents);
  
      this.editionService.getCommissionEdition(SessionContext.get('idOrganisme'), this.commission.id)
        .subscribe(async commissionEdition => {
          if (commissionEdition.idResponsable) {
  
            const responsable = await this.operateursService.getOperateur(commissionEdition.idResponsable).toPromise();
            commissionEdition.responsable = responsable.nom || responsable.prenom ? responsable.prenom.concat(' ').concat(responsable.nom) : 'N/A';
            const organismeEdition: OrganismeEdition = await this.editionService.getOrganismeEdition(SessionContext.get("idOrganisme")).toPromise();
            commissionEdition.organisme = organismeEdition;
          } else {
            commissionEdition.responsable = 'N/A';
          }
          if (commissionEdition.echantillons.length > 0) {
  
            for (let i = 0; i < commissionEdition.echantillons.length; i++) {
              const operateur = await this.operateursService.getOperateur(commissionEdition.echantillons[i].operateur.id).toPromise();
              commissionEdition.echantillons[i].operateur.raisonSociale = operateur.raisonSociale ? operateur.raisonSociale : 'N/A';
              commissionEdition.echantillons[i].operateur.telephone = operateur.telephoneFixe ? operateur.telephoneFixe : 'N/A';
              commissionEdition.echantillons[i].operateur.portable = operateur.telephoneMobile ? operateur.telephoneMobile : 'N/A';
              commissionEdition.echantillons[i].operateur.fax = operateur.fax ? operateur.fax : 'N/A';
              commissionEdition.echantillons[i].operateur.ville = operateur.ville ? operateur.ville : 'N/A';
              commissionEdition.echantillons[i].operateur.codePostal = operateur.codePostal ? operateur.codePostal : 'N/A';
            }
  
            const commissionMail = new CommissionEditionMail(commissionEdition, mailsJures);
            this.editionService.envoyerMailCommission(SessionContext.get('idOrganisme'), commissionMail, documents)
              .subscribe((fichiers: Fichier[]): void => {
                if (fichiers) {
                  fichiers.forEach(fichier => {
                    const fileURL = URL.createObjectURL(FileDownloadTools.b64ToBlob(fichier.base64, 'application/pdf'));
                    window.open(fileURL);
                  });
                }
                this.setJuresCheckedNull();
              }), () => { },
              () => { };
          } else {
  
            commissionEdition.echantillons.forEach(e => {
              e.operateur.raisonSociale = 'N/A';
              e.operateur.telephone = 'N/A';
              e.operateur.portable = 'N/A';
              e.operateur.fax = 'N/A';
              e.operateur.ville = 'N/A';
              e.operateur.codePostal = 'N/A';
            });
  
            const commissionMail = new CommissionEditionMail(commissionEdition, mailsJures);
            this.editionService.envoyerMailCommission(SessionContext.get('idOrganisme'), commissionMail, documents)
              .subscribe((fichiers: Fichier[]): void => {
                if (fichiers) {
                  fichiers.forEach(fichier => {
                    const fileURL = URL.createObjectURL(FileDownloadTools.b64ToBlob(fichier.base64, 'application/pdf'));
                    window.open(fileURL);
                  });
                }
                this.setJuresCheckedNull();
              }), () => { },
              () => { };
          }
        });
    } else {
      this.noJures = true;
    }

  }

}
