import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';
import { RefStatutCode } from './enums/ref-statut-code.enum';

@JsonObject
export class RefStatut {
  @JsonProperty('id', Number)
  public id: number = undefined;

  @JsonProperty('code', String)
  public code: RefStatutCode = undefined;

  @JsonProperty('libelle', String)
  public libelle: string = undefined;
}
