import { Injectable } from '@angular/core';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClearCache, Cache } from 'src/app/core/services/cache/cache.service';
import { ReferencesEdition } from '../../models/references-edition.model';

@Injectable({
  providedIn: 'root'
})
export class ReferencesEditionService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
  ) {

  }

  @ClearCache
  clearcache() { }

  @Cache({
    maxAge: 3600,
    key: 'references-edition'
  })

  getReferences(): Observable<ReferencesEdition> {
    return this.http.get<object>(`/api/edition/private/referentiel`)
      // return this.http.get<object[]>(`/assets/mocks/references-edition.json`)
      .pipe(
        map(ref => this.mapper.deserializeObject(ref, ReferencesEdition))
      );
  }
}



