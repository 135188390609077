import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditCultureBioComponent } from './edit-culture-bio/edit-culture-bio.component';
import { ListeCulturesBioComponent } from './liste-cultures-bio/liste-cultures-bio.component';

@Component({
  selector: 'app-cultures-bio',
  templateUrl: './cultures-bio.component.html',
  styleUrls: ['./cultures-bio.component.scss']
})
export class CulturesBioComponent implements OnInit {

  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"]
    };
  
    static routes: Routes = [
      { path: '', component: ListeCulturesBioComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id', component: EditCultureBioComponent, data: { roles: ['ORGANISME'] } },
      { path: 'new', component: EditCultureBioComponent, data: { roles: ['ORGANISME'] } }
    ];
    
  constructor() { }

  ngOnInit() {
  }

}
