import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { Produit } from '../../habilitation/models/produit.model';

@JsonObject
export class DeclarationDrBase {
  @JsonProperty('codeProduit', String)
  codeProduit: string;

  @JsonProperty('mention', String)
  @Optional
  mention: string;

  @JsonProperty('igp', Boolean)
  @Optional
  igp: boolean;

  @JsonProperty('erreursList', [String])
  @Optional
  erreurs: string[] = [];

  produit: Produit = new Produit();
}
