import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UniteCulturale} from '../../models/unite-culturale.model';
import {UniteCulturalePost} from '../../models/unite-culturale-post.model';
import {Surgreffage} from '../../models/surgreffage.model';
import {Arrachage} from '../../models/arrachage.model';
import {InfosLastMaj} from '../../models/infos-last-maj.model';
import {map, tap} from 'rxjs/operators';
import {JsonMapperService} from '../../../../core/services/mapper/mapper.service';
import { ExportToCsv } from 'export-to-csv';
import * as moment from 'moment';
import {InfosLastValid} from "../../models/infos-last-validation.model";
import { UniteCulturaleExport } from '../../models/unite-culturale-export.model';
import { Fichier } from 'src/app/data/edition/models/fichier.model';
import { SyntheseInventaireEdition } from 'src/app/data/edition/models/synthese-inventaire-edition';
import { SyntheseInventaireGroupeeEdition } from 'src/app/data/edition/models/synthese-inventaire-groupee-edition';
import { HistoriqueDpap } from '../../models/historique-dpap.model';

@Injectable({
  providedIn: 'root'
})
export class UniteCulturaleService {
  constructor(
    private readonly http: HttpClient,
    protected mapper: JsonMapperService
  ) {
  }

  getUnitesCulturales(idEntreprise: number): Observable<UniteCulturale[]> {
    return this.http.get<UniteCulturale[]>(`/api/declaration/private/entreprises/${idEntreprise}/unitesCulturales`)
      .pipe(
        map(uniteCulturales => uniteCulturales ? this.mapper.deserializeArray(uniteCulturales, UniteCulturale) : []));
  }

  getUniteCulturale(idUniteCulturale: number): Observable<UniteCulturale> {
    return this.http.get<UniteCulturale>(`/api/declaration/private/unitesCulturales/${idUniteCulturale}`)
      .pipe(
        map(uniteCulturale => this.mapper.deserializeObject(uniteCulturale, UniteCulturale) ));
  }

  getUniteCulturaleByDpap(idDeclaration: number, idCahier: number): Observable<UniteCulturale[]> {
    return this.http.get<UniteCulturale[]>(`/api/declaration/private/dpap/${idDeclaration}/cahier/${idCahier}`)
      .pipe(
        map(uniteCulturales => uniteCulturales ? this.mapper.deserializeArray(uniteCulturales, UniteCulturale) : []));
  }

  getDateLastModifInventaireVerger(idEntreprise: number): Observable<InfosLastMaj> {
    return this.http.get<InfosLastMaj>(`/api/declaration/private/entreprises/${idEntreprise}/unitesCulturales/lastMaj`);
  }

  getDateLastValidInventaireVerger(idEntrepriseList: number[], idCampagne: number): Observable<InfosLastValid[]> {
    return this.http.get<InfosLastValid[]>(`/api/declaration/private/entreprises/campagnes/${idCampagne}/unitesCulturales/lastValidInventaire?id_entreprise=${idEntrepriseList.join(',')}`)
      .pipe(
      map(values => this.mapper.deserializeArray(values, InfosLastValid)));
  }

  
  getListMapEntrepriseDateValidationInventaire(idEntrepriseList: number[], idCampagne: number): Observable<Map<Number, Date>[]> {
    return this.http.get<Map<Number, Date>[]>(`/api/declaration/private/entreprises/campagnes/${idCampagne}/unitesCulturales/lastValidInventaireMap?id_entreprise=${idEntrepriseList.join(',')}`);
  }

  getDateLastModifInventaireVergerEntreprisesLiees(idEntreprise: number): Observable<InfosLastMaj> {
    return this.http.get<InfosLastMaj>(`/api/declaration/private/entreprises/${idEntreprise}/unitesCulturales/lastMajEntreprisesLiees`);
  }

  creerUniteCulturale(idEntreprise: number, uniteCulturale: UniteCulturalePost): Observable<void> {
    return this.http.post<void>(`/api/declaration/private/entreprises/${idEntreprise}/uniteCulturale`, uniteCulturale);
  }

  modifierUniteCulturale(idEntreprise: number, idUniteCulturale: number, uniteCulturale: UniteCulturalePost): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/entreprises/${idEntreprise}/uniteCulturales/${idUniteCulturale}`, uniteCulturale);
  }

  supprimerUniteCulturale(idEntreprise: number, idUniteCulturale: number): Observable<void> {
    return this.http.delete<void>(`/api/declaration/private/entreprises/${idEntreprise}/uniteCulturales/${idUniteCulturale}`);

  }

  validerInventaireVerger(idEntreprise: number): Observable<void> {
    return this.http.post<void>(`/api/declaration/private/entreprises/${idEntreprise}/`, null );
  }

  validerDPAP(idEntreprise: number, idCahier): Observable<void> {
    return this.http.post<void>(`/api/declaration/private/entreprises/${idEntreprise}/dpap/cahier/${idCahier}`, null );
  }

  getDPAPList(idEntreprise: number): Observable<HistoriqueDpap[]> {
    return this.http.get<HistoriqueDpap[]>(`/api/declaration/private/entreprises/${idEntreprise}/dpap`)
      .pipe(
        map(historiques => historiques ? this.mapper.deserializeArray(historiques, HistoriqueDpap) : []));
  }

  validerInventaireVergerList(idEntreprise: number[]): Observable<void> {
    return this.http.post<void>(`/api/declaration/private/entreprises?idEntreprises=${idEntreprise}`, null );
  }

  surgreffageTotalPartiel(idUniteCulturale: number, surgreffagePartiel: boolean, surgreffage: Surgreffage): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/uniteCulturales/${idUniteCulturale}/surgreffage?partiel=${surgreffagePartiel}`, surgreffage);
  }

  arrachagePartiel(idUniteCulturale: number, arrachage: Arrachage): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/uniteCulturales/${idUniteCulturale}/arrachage`, arrachage);
  }

  dupliquerUniteCulturale(idUniteCulturale: number): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/uniteCulturales/${idUniteCulturale}/dupliquer`, null);
  }

  diviserUniteCulturale(idUniteCulturale: number, surfaceNouvelleUnite: number): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/uniteCulturales/${idUniteCulturale}/diviser`, surfaceNouvelleUnite);
  }

  supprimerListeUniteCulturale(idEntreprise: number, listUniteCulturale: UniteCulturale[]): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/entreprises/${idEntreprise}/uniteCulturales/`, listUniteCulturale);

  }

  cultureBioMasseUniteCulturale(listUniteCulturale: UniteCulturale[], idCultureBio: number): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/uniteCulturales/cultureBio?idCultureBio=${idCultureBio}`, listUniteCulturale);
  }

  exporterInventaireVerger(listEntreprise: number[], idCampagne: number, est_definitive: boolean): Observable<UniteCulturaleExport[]> {
    return this.http.post<UniteCulturaleExport[]>(`/api/declaration/private/entreprises/unitesCulturales/campagnes/${idCampagne}/definitive/${est_definitive.valueOf()}`,listEntreprise);
  }

  changerProducteurList(listUniteCulturale: UniteCulturale[], idNouveauProducteur: number): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/uniteCulturales/changerProducteur?idNouveauProducteur=${idNouveauProducteur}`, listUniteCulturale);
  }


  exporterInventaireVergerCsv(uniteCulturales): void {
    const data = [];
    uniteCulturales.forEach(uniteCulturale => {
      data.push({
        raisonSocialeGp: uniteCulturale.raisonSocialeGp ? uniteCulturale.raisonSocialeGp : '',
        raisonSociale: uniteCulturale.raisonSociale ? uniteCulturale.raisonSociale : '',
        numBloc: uniteCulturale.numBlocFruitier ? "\t" + uniteCulturale.numBlocFruitier : '',
        codeParcelle: uniteCulturale.codeParcelle ? uniteCulturale.codeParcelle : '',
        refCadastrale: uniteCulturale.refCadastrale ? uniteCulturale.refCadastrale : '',
        numTracabilite: uniteCulturale.numTracabilite ? uniteCulturale.numTracabilite : '',
        lieuDit: uniteCulturale.lieuDit ? uniteCulturale.lieuDit : '',
        commune: uniteCulturale.commune,
        espece: uniteCulturale.espece,
        variete: uniteCulturale.variete,
        clone: uniteCulturale.clone ? uniteCulturale.clone : '',
        porteGreffe: uniteCulturale.porteGreffe ? uniteCulturale.porteGreffe : '',
        surfaceCalcule: uniteCulturale.surfaceCalculee,
        anneeSurgreffage: uniteCulturale.anneeSurgreffage ? uniteCulturale.anneeSurgreffage : '',
        anneePlantation: uniteCulturale.anneePlantation ? uniteCulturale.anneePlantation : '',
        anneePremiereFeuille: uniteCulturale.anneePremiereFeuille ? uniteCulturale.anneePremiereFeuille : '',
        cultureBio: uniteCulturale.cultureBio ? uniteCulturale.cultureBio : '',
        departement: uniteCulturale.departement,
        forme: uniteCulturale.forme,
        distanceInterRang: uniteCulturale.distanceInterRang,
        distanceIntraRang: uniteCulturale.distanceIntraRang,
        nbArbre: uniteCulturale.nbArbre,
        systemeIrrigation: uniteCulturale.systemeIrrigation ? uniteCulturale.systemeIrrigation : '',
        protectionGel: uniteCulturale.protectionGel ? uniteCulturale.protectionGel : '',
        protectionGrele: uniteCulturale.protectionGrele ? uniteCulturale.protectionGrele : '',
        situation: uniteCulturale.situation ? uniteCulturale.situation : '',
        confusion: uniteCulturale.confusion ? 'OUI' : 'NON'
      });
    });

    const options = {
      filename: 'EXPORT_INVENTAIRE'.concat('_').concat(moment().format('YYYYMMDD')),
      fieldSeparator: ';',
      quoteStrings: '',
      decimalSeparator: ',',
      showLabels: true,
      showTitle: false,
      title: 'EXPORT_INVENTAIRE'.concat('_').concat(moment().format('YYYYMMDD')),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        'Groupement',
        'Raison Sociale',
        'Num Bloc Fruitier',
        'Code Parcelle',
        'Ref Cadastrale',
        'Num Tracabilite',
        'Lieu Dit',
        'Commune',
        'Espece',
        'Variete',
        'Clone',
        'Porte Greffe',
        'Surface Calcule',
        'Annee Surgreffage',
        'Annee Plantation',
        'Annee Premiere Feuille',
        'Culture Bio',
        'Departement',
        'Forme',
        'Distance Inter Rang',
        'Distance Intra Rang',
        'Nb Arbre',
        'Systeme Irrigation',
        'Protection Gel',
        'Protection Grele',
        'Situation',
        'Confusion'
      ]
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }

  exporterInventaireVergerPdf(idOrganisme: number, syntheseInventaireEdition: SyntheseInventaireEdition): any {
    return this.http.post<Fichier[]>(`/api/edition/private/organismes/${idOrganisme}/generer/synthese-inventaire`, syntheseInventaireEdition);
  }

  exporterInventaireVergerGroupePdf(idOrganisme: number, syntheseInventaireGroupeeEdition: SyntheseInventaireGroupeeEdition): any {
    const convMap: any = {};
    syntheseInventaireGroupeeEdition.surfaceEspece.forEach((val: number,key: string) => {
    convMap[key] = val;
});
syntheseInventaireGroupeeEdition.surfaceEspece = convMap;
    return this.http.post(`/api/edition/private/organismes/${idOrganisme}/generer/synthese-inventaire/groupe`, syntheseInventaireGroupeeEdition);
  }
}

