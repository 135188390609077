import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {JuresService} from '../../../../../../../data/commission/services/jures/jures.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ComJure} from '../../../../../../../data/commission/models/com-jure.model';
import {ComQualif} from '../../../../../../../data/commission/models/com-qualif.model';
import {Cahier} from '../../../../../../../data/habilitation/models/cahier.model';
import {CahiersService} from '../../../../../../../data/commission/services/cahiers/cahiers.service';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddEditQualificationComponent} from '../../modal/add-edit-qualification/add-edit-qualification.component';

@Component({
  selector: 'app-update-jure',
  templateUrl: './update-jure.component.html',
  styleUrls: ['./update-jure.component.scss']
})
export class UpdateJureComponent implements OnInit {
  jure: ComJure;
  formUpdateJure: FormGroup;
  jureId: number;
  pageTitle: string;
  public qualifications: ComQualif[];
  public qualificationsInactives: ComQualif[] = [];
  cahiersPossedes: Cahier[] = [];
  cahier: Cahier;
  libelles: { [key: number]: string } = {};

  constructor(
    private route: ActivatedRoute,
    private juresService: JuresService,
    private fb: FormBuilder,
    private cahierService: CahiersService,
    private readonly router: Router,
    private translate: TranslateService,
    private modalService: NgbModal
  ) {
    this.formUpdateJure = this.fb.group({
      nom: [''],
      telFixe: [''],
      mail: [''],
      telPortable: ['']
    });
  }

  ngOnInit() {
    this.jureId = this.route.snapshot.params.id;
    if (this.jureId) {
      this.juresService.getJureById(this.jureId).subscribe(
        (data: ComJure) => {
          this.translate.get('page.admin.jure.update.title', {nom: data.nom}).subscribe((res: string) => {
            this.pageTitle = res;
          });
          this.initForm(data);
          const today = moment();
          // Séparation des qualifications actives et inactives
          this.qualificationsInactives = data.qualifications.filter(qualification =>
            qualification.dateFin && qualification.dateFin.isBefore(today));
          this.qualifications = data.qualifications.filter(qualification =>
            !qualification.dateFin || qualification.dateFin.isSameOrAfter(today));

          // Obtenir le libelle du cahier pour les qualifications
          this.qualifications.forEach(qualification => {
            this.cahierService.getCahierById(qualification.idCahier).subscribe(cahier => {
              this.libelles[qualification.idCahier] = cahier ? cahier.libelle : '';
              this.cahiersPossedes.push(cahier);
            });
          });
          this.qualificationsInactives.forEach(qualification => {
            this.cahierService.getCahierById(qualification.idCahier).subscribe(cahier => {
              this.libelles[qualification.idCahier] = cahier ? cahier.libelle : '';
              this.cahiersPossedes.push(cahier);
            });
          });
        },
        error => {
          console.error('Erreur lors du chargement du juré', error);
        }
      );
    } else {
      this.translate.get('page.admin.jure.add.title').subscribe((res: string) => {
        this.pageTitle = res;
      });
      this.initForm();
    }

    this.formUpdateJure = this.fb.group({
      nom: [''],
      mail: ['', [Validators.email]],
      telFixe: [''],
      telPortable: [''],
    });
  }

  initForm(jure?: ComJure) {
    this.formUpdateJure.patchValue({
      nom: jure ? jure.nom : '',
      telFixe: jure ? jure.telFixe || '' : '',
      mail: jure ? jure.mail : '',
      telPortable: jure ? jure.telPortable || '' : ''
    });
  }

  public getCollegesLibelle(qualification: ComQualif): string {
    return qualification.colleges ? qualification.colleges.map(college => college.libelle).join(', ') : '';
  }

  public getRaisonSocialeOperateurs(qualification: ComQualif): string {
    return qualification.operateurs ? qualification.operateurs.map(operateur => operateur.raisonSociale).join(', ') : '';
  }

  onClose() {
    this.router.navigate(['.'], {
      relativeTo: this.route.parent
    });
  }

  modifierJure() {
    if (this.formUpdateJure.valid) {
      const jureData = this.formUpdateJure.value;
      if (this.jureId) {
        this.juresService.updateJureById(this.jureId, jureData).subscribe(
          () => {
            console.log('Jure updated successfully');
          },
          error => {
            console.error('Error updating jure', error);
          }
        );
      } else {
        this.juresService.addJure(jureData).subscribe(
          (jureId) => {
            console.log('Jure added successfully, ID:', jureId);
            this.router.navigate(['/main/admin/params/jures/jure/updateJure', jureId]);
          },
          error => console.error('Error adding juré', error)
        );
      }
    }
  }

  edit(qualification: ComQualif) {
    const modal = this.modalService.open(AddEditQualificationComponent, {backdrop: 'static'});
    modal.componentInstance.idJure = this.jureId;
    modal.componentInstance.idQualif = qualification.id;
    modal.componentInstance.comQualifAUpdate = qualification;
    modal.componentInstance.operateurSearch = qualification.operateurs.length > 0 ? qualification.operateurs[0] : null;

    modal.result.then(
      (result) => {
        if (result === 'qualification_updated') {
          window.location.reload(); // Méthode pour rafraîchir les données
        }
      },
      error => {
        throw error;
      }
    );
  }

  delete(jureId: number, qualification: ComQualif) {
    jureId = this.jureId;
    this.juresService.deleteComQualif(jureId, qualification).subscribe(
      () => {
        console.log('qualification rendue inactive');
        window.location.reload(); // Méthode pour rafraîchir les données
      },
      error => {
        console.error('Error : la qualification est toujours active', error);
      }
    );
  }
  addQualification() {
    const modal = this.modalService.open(AddEditQualificationComponent, {backdrop: 'static'});
    modal.componentInstance.idJure = this.jureId;
    modal.componentInstance.cahiersPossedes = this.cahiersPossedes;

    modal.result.then(
      (result) => {
        if (result === 'qualification_added') {
          window.location.reload(); // Méthode pour rafraîchir les données
        }
      },
      error => {
        throw error;
      }
    );
  }

  reactivate(qualificationInactive: ComQualif) {
    const jureId = qualificationInactive.idJure;
    this.juresService.reactivateComQualif(jureId, qualificationInactive.id, qualificationInactive).subscribe(
      () => {
        console.log('qualification réactivée');
        window.location.reload(); // Méthode pour rafraîchir les données
      },
      error => {
        console.error('Error : la qualification est toujours inactive', error);
      }
    );
  }
}
