import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';
import { OrganismeTypeCode } from './enums/type-organisme.enum';

@JsonObject
export class OrganismeType {

  @JsonProperty('id', Number)
  public id: number = undefined;

  @JsonProperty('code', String)
  public code: OrganismeTypeCode = undefined;

  @JsonProperty('libelle', String)
  public libelle: string = undefined;
}
