import { Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';

import { FunctionalError } from 'src/app/shared/errors/functional.error';

@Injectable({
  providedIn: 'root'
})
export class ErrorModalService {
  private readonly openModals: string[] = [];
  public errors: any[] = [];

  constructor(
    private readonly modalsService: AdvBootstrapModalService,
    private readonly translate: TranslateService,
    private readonly zone: NgZone
  ) { }

  private getTranslation(key: string, context: {} = {}): string {
    const message = this.translate.instant(key, context);
    if (!message || message === key) {
      return null;
    } else {
      return message;
    }
  }

  public handleError(error: any) {
    if (!error) {
      return;
    }

    if (error instanceof HttpErrorResponse) {
      let message: string;

      if (error.error && error.error.code) {
        message = this.getTranslation(`http-error.code.${error.error.code}`, error.error.context);
      }
      if (!message) {
        message = this.getTranslation(`http-error.status.${error.status}`);
      }
      if (!message) {
        message = this.getTranslation(`http-error.defaut`);
      }

      this.openModal(message);
    } else if (error instanceof FunctionalError) {
      let message: string;

      if (error.code) {
        message = this.getTranslation(`functional-error.code.${error.code}`, error.context);
      }

      if (!message) {
        message = this.getTranslation(`functional-error.defaut`);
      }

      this.openModal(message);
    }
  }

  private openModal(message: string) {
    if (this.openModals.indexOf(message) === -1) { // avoid duplicate modals
      this.openModals.push(message);
      this.modalsService.error(message, this.translate.instant(`http-error.title`)).then(
        () => {
          const index = this.openModals.indexOf(message);
          this.openModals.splice(index, 1);
        },
        () => {
          const index = this.openModals.indexOf(message);
          this.openModals.splice(index, 1);
        }
      );
      this.zone.run(() => { });
    }
  }
}
