import { Component, OnInit } from '@angular/core';
import { Context } from 'src/app/core/services/config/app.settings';

@Component({
  selector: 'app-user-disabled',
  templateUrl: './user-disabled.component.html',
  styleUrls: ['./user-disabled.component.scss']
})
export class UserDisabledComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
