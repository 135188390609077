import { JsonObject, JsonProperty, CustomConverter, Optional, Any } from '@thorolf/json-ts-mapper';
import { SyntheseInventaireEdition } from './synthese-inventaire-edition';

@JsonObject
export class SyntheseInventaireGroupeeEdition {

    @JsonProperty('surfaceEspece', Any)
    @Optional
    surfaceEspece: Map<string,number>;
    
    @JsonProperty('surfaceTotale', Number)
    @Optional
    surfaceTotale: number;

    @JsonProperty('surfaceIrriguee', Number)
    @Optional
    surfaceIrriguee: number;

    @JsonProperty('surfaceGrele', Number)
    @Optional
    surfaceGrele: number;

    @JsonProperty('surfaceGel', Number)
    @Optional
    surfaceGel: number;

    @JsonProperty('surfaceBio', Number)
    @Optional
    surfaceBio: number;

    @JsonProperty('syntheseInventaireEditionList', SyntheseInventaireEdition)
    @Optional
    syntheseInventaireEditionList: SyntheseInventaireEdition[];
}
