import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ImportInventaireVergersResultat } from '../../models/import-inventaire-vergers-resultat.model';



type ImportInventaireVergersResponseType = HttpResponse<ImportInventaireVergersResultat>

@Injectable({
  providedIn: 'root'
})
export class ImportInventaireVergersService {

  constructor(protected http: HttpClient) { }

  public uploadInventaire(idGroupement: number, file: File): Observable<ImportInventaireVergersResponseType> {
    let formData: FormData;
    if (file) {
      formData = new FormData();
      formData.append('file', file);
    }
    return this.http.post<ImportInventaireVergersResultat>(`/api/declaration/private/entreprises/${idGroupement}/inventaire/upload`, formData, {
      observe: 'response'
    });
  }
}
