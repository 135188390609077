import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

interface ListItem<T> { item: T; selected: boolean; disabled: boolean; }

export abstract class AbstractRechercheItemComponent<T extends { id: number }> implements OnInit {

  constructor(
    public readonly modal: NgbActiveModal
  ) { }

  get selectedItems() {
    return this.items ? this.items.filter(item => item.selected).map(item => item.item) : [];
  }

  items: ListItem<T>[];
  loaded = false;

  // ########################################
  // ###########   diaplay   ################
  // ########################################

  order = {
    property: 'id',
    reverse: false
  };

  abstract getData(): Observable<T[]>;

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.getData().subscribe(
      items => {
        this.items = items
          .map(item => ({ item, selected: false, disabled: false }));

        this.loaded = true;
      },
      () => { }
    );
  }

  // ########################################
  // #######   selection & validation   #####
  // ########################################

  toggleSelectionItem(item: ListItem<T>) {
    item.selected = !item.selected;
    this.items.forEach(j => {
      if (j.item.id === item.item.id && j !== item) {
        j.disabled = item.selected;
      }
    });
  }

  cancel() {
    this.modal.dismiss();
  }

  submit() {
    this.modal.close(this.selectedItems);
  }

  setOrder(property: string) {
    if (property) {
      if (property === this.order.property) {
        this.order.reverse = !this.order.reverse;
      } else {
        this.order = {
          property,
          reverse: false
        };
      }
    }
  }

  getIcon(property: string) {
    if (property === this.order.property) {
      return this.order.reverse ? 'sort-down' : 'sort-up';
    } else {
      return 'sort';
    }
  }

}
