<div class="content">
  <div class="content-panel">
    <h2 class="section-title" translate>page.operateurs.informations.entreprise.title</h2>
    <div class="flex" *ngIf="operateur && infosDomaine">
      <div [class]="SessionContext.get('utilisateurType') !== UtilisateurTypeCode.OPERATEUR ? 'col-4' : 'col-6'">
        <div class="box-grey">
          <div *ngIf="SessionContext.get('utilisateurType') !== UtilisateurTypeCode.OPERATEUR" class="header flex justify-content-between">
            <span class="header-title"></span>
            <fa-icon class="clickable-icon" icon="edit" (click)="editInformationsMajeures()"></fa-icon>
          </div>
          <div *ngIf="operateur.structureJuridique"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.structureJuridique</label><strong>{{operateur.structureJuridique}}</strong>
          </div>
          <div *ngIf="operateur.raisonSociale"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.raisonSociale</label><strong>{{operateur.raisonSociale}}</strong>
          </div>
          <div *ngIf="operateur.siret"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.siret</label><strong>{{operateur.siret}}</strong>
          </div>
          <div *ngIf="operateur.tvaIntracommunautaire"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.tvaIntracommunautaire</label><strong>{{operateur.tvaIntracommunautaire}}</strong>
          </div>
          <div *ngFor="let info of infosDomaine"><label
            class="entreprise-label">{{info.libelle}}</label><strong>{{info.valeur}}</strong>
          </div>
        </div>
      </div>
      <div [class]="SessionContext.get('utilisateurType') !== UtilisateurTypeCode.OPERATEUR ? 'col-lg-6 col-5' : 'col-6'">
        <div class="box-grey">
          <div *ngIf="SessionContext.get('utilisateurType') !== UtilisateurTypeCode.OPERATEUR" class="header flex justify-content-between">
            <span class="header-title"></span>
            <fa-icon class="clickable-icon" icon="edit" (click)="editInformationsMineures()"></fa-icon>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12">
              <div *ngIf="operateur.nom"><label class="entreprise-label-intervenant"
                                                translate>page.operateurs.informations.entreprise.nom</label><strong>{{operateur.nom}}</strong>
              </div>

              <div *ngIf="operateur.adresse"><label class="entreprise-label-intervenant" translate>page.operateurs.informations.entreprise.adresse</label><strong>{{operateur.adresse}}</strong>
              </div>

              <div *ngIf="operateur.ville"><label class="entreprise-label-intervenant" translate>page.operateurs.informations.entreprise.ville</label><strong>{{operateur.ville}}</strong>
              </div>

              <div *ngIf="operateur.idPays"><label class="entreprise-label-intervenant" translate>page.operateurs.informations.entreprise.pays</label><strong>{{operateur.idPays?.libelle}}</strong>
              </div>

              <div *ngIf="operateur.telephoneFixe"><label class="entreprise-label-intervenant" translate>page.operateurs.informations.entreprise.telephoneFixe</label><strong>{{operateur.telephoneFixe | phoneFormat}}</strong>
              </div>

              <div *ngIf="operateur.fax"><label class="entreprise-label-intervenant"
                                                translate>page.operateurs.informations.entreprise.fax</label><strong>{{operateur.fax| phoneFormat}}</strong>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div *ngIf="operateur.prenom"><label class="entreprise-label-intervenant" translate>page.operateurs.informations.entreprise.prenom</label><strong>{{operateur.prenom}}</strong>
              </div>
              <div *ngIf="operateur.codePostal"><label class="entreprise-label-intervenant" translate>page.operateurs.informations.entreprise.codePostal</label><strong>{{operateur.codePostal}}</strong>
              </div>
              <div *ngIf="operateur.cedex"><label class="entreprise-label-intervenant" translate>page.operateurs.informations.entreprise.cedex</label><strong>{{operateur.cedex}}</strong>
              </div>
              <div *ngIf="operateur.email"><label class="entreprise-label-intervenant" translate>page.operateurs.informations.entreprise.email</label><strong>{{operateur.email}}</strong>
              </div>
              <div *ngIf="operateur.telephoneMobile"><label class="entreprise-label-intervenant" translate>page.operateurs.informations.entreprise.telephoneMobile</label><strong>{{operateur.telephoneMobile | phoneFormat}}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="SessionContext.get('utilisateurType') !== UtilisateurTypeCode.OPERATEUR"  class="col-lg-2 col-3">
        <div class="box-grey" [formGroup]="formCategorie">
          <div class="flex">
            <input type="checkbox" id="caveCoop" formControlName="caveCooperative" class="mr-1"
                   [checked]="intervenantCategorie.caveCooperative">
            <label class="categorie" for="caveCoop"
                   translate>page.operateurs.informations.categories.caveCoop</label>
          </div>
          <div  class="flex">
            <input type="checkbox" id="caveParticuliere" formControlName="caveParticuliere" class="mr-1"
              [checked]="intervenantCategorie.caveParticuliere">
            <label class="categorie" for="caveParticuliere" translate>page.operateurs.informations.categories.caveParticuliere</label>
          </div>
          <div class="flex">
            <input type="checkbox" id="negociant" formControlName="negociant" class="mr-1" [checked]="intervenantCategorie.negociant">
            <label class="categorie" for="negociant"
                      translate>page.operateurs.informations.categories.negociant</label>
          </div>
          <div class="flex">
            <input type="checkbox" id="bailleur" formControlName="bailleur"  class="mr-1" [checked]="intervenantCategorie.bailleur">
            <label class="categorie" for="bailleur"
                      translate>page.operateurs.informations.categories.bailleur</label>
          </div>
          <div class="flex">
            <input type="checkbox" id="apporteur" formControlName="apporteur" class="mr-1" [checked]="intervenantCategorie.apporteur">
            <label class="categorie" for="apporteur"
                      translate>page.operateurs.informations.categories.apporteur</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content-panel">
    <h2 class="section-title" translate>page.operateurs.informations.sites.title</h2>
    <div class="liste-sites-container">
      <table class="table liste-sites" *ngIf="sites">
        <thead>
        <tr>
          <th>#</th>
          <th translate>page.operateurs.informations.sites.table.header.adresse</th>
          <th translate>page.operateurs.informations.sites.table.header.responsable</th>
          <th translate>page.operateurs.informations.sites.table.header.activites</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let site of sites; let idx = index">
          <ng-container *ngIf="site.__form === undefined">
            <td>{{idx + 1}}</td>
            <td>{{site.adresse}}</td>
            <td>{{site.responsable}}</td>
            <td>
              <div *ngFor="let activite of site.siteActivites">
                {{activite.libelle}}
              </div>
            </td>
            <td>
              <fa-icon class="clickable-icon" icon="edit" (click)="editSite(site)"></fa-icon>
              &nbsp;
              <fa-icon class="clickable-icon" icon="trash" (click)="deleteSite(site)"></fa-icon>
            </td>
          </ng-container>
          <ng-container *ngIf="site.__form">
            <td>
              {{idx + 1}}
              <form [formGroup]="site.__form" #form="ngForm" (ngSubmit)="saveSite(site)"></form>
            </td>
            <td>
              <form [formGroup]="getFormGroup(site, 'adresse')">
                <input id="adresse_{{idx}}" formControlName="adresse"
                       [ngClass]="{'is-invalid': form.submitted && getFormControl(site, 'adresse').invalid}"
                       class="form-control"/>
              </form>
            </td>
            <td>
              <form [formGroup]="getFormGroup(site, 'responsable')">
                <input id="responsable_{{idx}}" formControlName="responsable"
                       [ngClass]="{'is-invalid': form.submitted && getFormControl(site, 'responsable').invalid}"
                       class="form-control"/>
              </form>
            </td>
            <td>
              <form [formGroup]="getFormGroup(site, 'activites')">
                <app-multiselect bindLabel="libelle" controlName="activites" [items]="sitesActivites"
                                 [ngClass]="{'is-invalid': form.submitted && getFormControl(site, 'activites').invalid}"></app-multiselect>
              </form>
            </td>
            <td>
              <fa-icon class="clickable-icon" icon="save" (click)="form.onSubmit()"></fa-icon>
              &nbsp;
              <fa-icon class="clickable-icon" [icon]="site.__new ? 'trash' : 'undo'"
                       (click)="cancelEditSite(site, idx)"></fa-icon>
            </td>
          </ng-container>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="actions-bar" *ngIf="sites">
      <button type="button" (click)="ajouterSite()" class="btn btn-primary" translate>
        page.operateurs.informations.sites.table.ajouter
      </button>
    </div>
  </div>
  <div style="height:30vh;opacity:0">
    <!-- create some empty space for dropdown display -->
  </div>
</div>
