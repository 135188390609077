import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Interaction } from '../../models/interaction.model';

@Injectable({
  providedIn: 'root'
})
export class InteractionsService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
    ) { }

    getInteractions(): Observable<Interaction[]> {
      return this.http.get<Object[]>(`/api/intervenant/private/interactions`)
        .pipe(
          map(interactions => this.mapper.deserializeArray(interactions, Interaction))
        );
    }

    getInteractionsEntreprise(idEntreprise: number): Observable<Interaction[]> {
      return this.http.get<Object[]>(`/api/intervenant/private/interactions/entreprise/${idEntreprise}`)
        .pipe(
          map(interactions => this.mapper.deserializeArray(interactions, Interaction))
        );
    }

    getInteractionsContact(idContact: number): Observable<Interaction[]> {
      return this.http.get<Object[]>(`/api/intervenant/private/interactions/contact/${idContact}`)
        .pipe(
          map(interactions => this.mapper.deserializeArray(interactions, Interaction))
        );
    }

    getInteraction(idInteraction: number): Observable<Interaction> {
      return this.http.get<any>(`/api/intervenant/private/interactions/${idInteraction}`).pipe(
        map(interaction => this.mapper.deserializeObject(interaction, Interaction))
      ); 
    }

    public modifierInteraction(interaction: Interaction): Observable<void> {
      
      return this.http.put<void>(`/api/intervenant/private/interactions/${interaction.id}`,this.mapper.serialize(interaction));
    }

    public creerInteraction(interaction: Interaction): Observable<number> {
      return this.http.post(`/api/intervenant/private/interactions`, this.mapper.serialize(interaction), { observe: 'response' }).pipe(
        map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
      );
    }

    public supprimerInteraction(idInteraction: number): Observable<void> {
      return this.http.delete<void>(`/api/intervenant/private/interactions/${idInteraction}`);
    }
}
