import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';

import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import * as moment from 'moment';

import { PortailService } from '../../services/portail/portail.service';
import { Fail } from 'src/app/shared/errors/fail.error';
import { DomaineCode } from 'src/app/data/intervenant/models/enums/domaine-code.enum';
import { InscriptionVin } from '../../models/inscription-vin.model';
import { Inscription } from '../../models/inscription.model';

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.scss']
})
export class InscriptionComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  formGroup: FormGroup;
  codesDomaines: string[];

  get identifiant() { return this.formGroup.get('identifiant'); }
  get domaine() { return this.formGroup.get('domaine'); }

  get domaineSpecControlsNames() {
    return Object.keys((this.formGroup.get('domaineSpec') as FormGroup).controls);
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly portailService: PortailService
  ) { }

  ngOnInit() {
    this.codesDomaines = [
      DomaineCode.VIN,
      DomaineCode.POMME,
      DomaineCode.VIANDE,
      DomaineCode.BOVIN,
      DomaineCode.PORCIN,
    ];

    const today = {
      year: moment().get('year'),
      month: moment().get('month') + 1,
      day: moment().get('date')
    };

    this.formGroup = this.fb.group({
      identifiant: [undefined, [Validators.required, Validators.maxLength(200)]],
      domaine: [undefined, Validators.required],
      dateDebut: [today, Validators.required],
      dateFin: [undefined],
      structureJuridique: [undefined, [Validators.maxLength(200)]],
      raisonSociale: [undefined, [Validators.required, Validators.maxLength(200)]],
      siret: [undefined, [Validators.required, Validators.maxLength(14)]],
      tvaIntra: [undefined, [Validators.maxLength(25)]],
      adresse: [undefined, [Validators.required, Validators.maxLength(200)]],
      codePostal: [undefined, [Validators.required, Validators.maxLength(10)]],
      ville: [undefined, [Validators.required, Validators.maxLength(100)]],
      pays: [undefined, Validators.required],
      cedex: [undefined, [Validators.maxLength(20)]],
      telFixe: [undefined, [Validators.maxLength(20)]],
      telPortable: [undefined, [Validators.maxLength(20)]],
      fax: [undefined, [Validators.maxLength(20)]],
      domaineSpec: this.fb.group({})
    });

    this.formGroup.get('domaine').valueChanges.subscribe(domaine => {
      this.formGroup.setControl('domaineSpec', this.populateFormDomaineSpec(domaine));
    });
  }

  populateFormDomaineSpec(codeDomaine: DomaineCode) {
    switch (codeDomaine) {
      case null:
        return this.fb.group({});
      case DomaineCode.VIN:
        return this.fb.group({
          cvi: [undefined, [Validators.required, Validators.maxLength(200)]],
          ppm: [undefined, [Validators.maxLength(200)]],
          accise: [undefined, [Validators.maxLength(200)]]
        });
      case DomaineCode.POMME:
        return this.fb.group({});
      case DomaineCode.PORCIN:
        return this.fb.group({});
      case DomaineCode.BOVIN:
        return this.fb.group({});
      case DomaineCode.VIANDE:
        return this.fb.group({});
      default:
        Fail.never(codeDomaine);
    }
  }

  submit() {
    if (this.formGroup.valid) {
      const dateDebut = this.formGroup.get('dateDebut').value;
      const dateFin = this.formGroup.get('dateFin').value;

      const inscription = Object.assign(new Inscription(), {
        email: this.formGroup.get('identifiant').value,
        domaine: this.formGroup.get('domaine').value,
        dateDebut: moment([dateDebut.year, dateDebut.month - 1, dateDebut.day, 0, 0, 0]),
        dateFin: dateFin ? moment([dateFin.year, dateFin.month - 1, dateFin.day, 0, 0, 0]) : undefined,
        structureJuridique: this.formGroup.get('structureJuridique').value,
        raisonSociale: this.formGroup.get('raisonSociale').value,
        siret: this.formGroup.get('siret').value,
        tvaIntra: this.formGroup.get('tvaIntra').value,
        adresse: this.formGroup.get('adresse').value,
        codePostal: this.formGroup.get('codePostal').value,
        ville: this.formGroup.get('ville').value,
        pays: (this.formGroup.get('pays').value as string).toUpperCase(),
        cedex: this.formGroup.get('cedex').value,
        telFixe: this.formGroup.get('telFixe').value,
        telPortable: this.formGroup.get('telPortable').value,
        fax: this.formGroup.get('fax').value,
      });

      const domaine = this.formGroup.get('domaine').value as DomaineCode;

      switch (domaine) {
        case DomaineCode.VIN:
          const inscriptionVin = Object.assign(new InscriptionVin(), inscription, {
            cvi: this.formGroup.get('domaineSpec.cvi').value,
            ppm: this.formGroup.get('domaineSpec.ppm').value,
            accise: this.formGroup.get('domaineSpec.accise').value,
          });

          this.portailService.inscriptionVin(inscriptionVin).pipe(
            this.loaderService.operator()
          ).subscribe(() => {
            this.inscriptionOk();
          });
          break;
        case DomaineCode.POMME:
          throw new Error('not implemented');
        case DomaineCode.PORCIN:
          throw new Error('not implemented');
        case DomaineCode.BOVIN:
          throw new Error('not implemented');
        case DomaineCode.VIANDE:
          throw new Error('not implemented');
        default:
          Fail.never(domaine);
      }
    }
  }

  inscriptionOk() {
    this.modalsService.alert(`L'utilisateur a bien été inscrit.`);
    this.form.resetForm();
    this.ngOnInit();
  }
}
