import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { StatutEchantillon } from './statut-echantillon.model';
import { Operateur } from '../../intervenant/models/operateur.model';
import { Animateur } from '../../commission/models/animateur.model';

@JsonObject
export class Echantillon {
    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('datePrelevement', String)
    @CustomConverter(DateConverter)
    @Optional
    datePrelevement: moment.Moment;

    @JsonProperty('codeStatut', String)
    codeStatut: string;

    @JsonProperty('codeProduit', String)
    codeProduit: string;

    @JsonProperty('libelleProduit', String)
    libelleProduit: string;

    @JsonProperty('idIntervenant', Number)
    idOperateur: number;

    @JsonProperty('idControle', Number)
    idControle: number;

    @JsonProperty('dateButoir', String)
    @CustomConverter(DateConverter)
    @Optional
    dateButoir: moment.Moment;

    @JsonProperty('idAgent', Number)
    @Optional
    idAnimateur: number;

    @JsonProperty('idMouvement', Number)
    idMouvement: number;

    @JsonProperty('numDossier', Number)
    @Optional
    numeroDossier: number;

    @JsonProperty('numeroOperateur', String)
    @Optional
    numeroOperateur: String;

    @JsonProperty('logement', String)
    @Optional
    logement: String;

    @JsonProperty('idSite', Number)
    idSite: number;

    @JsonProperty('adresseSite', String)
    adresseSite: string;

    @JsonProperty('idCommission', Number)
    @Optional
    idCommission: number;

    @JsonProperty('numCommission', String)
    @Optional
    numeroCommission: string;

    @JsonProperty('dateCommission', String)
    @CustomConverter(DateConverter)
    @Optional
    dateCommission: moment.Moment;

    animateur: Animateur;
    statut: StatutEchantillon;
    cvi: string;
    operateur: Operateur;

    /** Retourne vrai si la date butoir est antérieure à la date du jour */
    estPerime(): boolean {
        const now: Date = new Date();
        return (this.dateButoir) ? this.dateButoir.isBefore(moment([
            now.getFullYear(),
            now.getMonth(),
            now.getDate(), 0, 0, 0
        ])) : false;
    }
}
