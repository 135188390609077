<div [ngClass]="{'anpp': isAnpp}">
  <div class="modal-header">
    <h4 class="modal-title" translate>page.mon-compte.modifier-mot-passe.title</h4>
  </div>
  <div class="modal-body">
    <form *ngIf="formGroup" #form="ngForm" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <input type="password" formControlName="oldPassword" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.oldPassword.errors }" placeholder="Ancien mot de passe" />
        <div *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback">
          <div *ngIf="f.oldPassword.errors.required">L'ancien mot de passe est requis.</div>
        </div>
      </div>
      <div class="form-group">
        <input type="password" formControlName="newPassword" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }" placeholder="Nouveau mot de passe" />
        <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
          <div *ngIf="f.newPassword.errors.required">Un nouveau mot de passe est requis.</div>
          <div *ngIf="f.newPassword.errors.pattern">
            Minimum : 12 caractères, 1 majuscule, 1 minuscule, 1 chiffre, 1 caract. spé
          </div>
        </div>
      </div>
      <div class="form-group">
        <input type="password" formControlName="confirmPassword" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"
          placeholder="Nouveau mot de passe (confirmation)" onpaste="return false;" />
        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
          <div *ngIf="f.confirmPassword.errors.required">La confirmation du mot de passe est obligatoire.</div>
          <div *ngIf="f.confirmPassword.errors.confirmPwd">Les mots de passe ne correspondent pas.</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="cancel()" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary btn-valider" translate>label.valider</button>
      </div>
    </form>
  </div>
</div>