import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class OrganismeEdition {
    @JsonProperty('id', Number)
    @Optional
    id: number;

    @JsonProperty('raisonSociale', String)
    raisonSociale: string;

    @JsonProperty('adresse', String)
    adresse: string;

    @JsonProperty('codePostal', String)
    codePostal: string;

    @JsonProperty('ville', String)
    ville: string;

    @JsonProperty('mail', String)
    mail: string;

    @JsonProperty('telephone', String)
    telephone: string;

    @JsonProperty('logo', String)
    @Optional
    logo: string;
}
