import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class DREVSaisie {
  @JsonProperty('codeProduit', String)
  codeProduit: string = undefined;

  @JsonProperty('mention', String)
  mention: string = undefined;

  @JsonProperty('vciRemplacement', Number)
  vciRemplacement = 0.0;

  @JsonProperty('vciSubstitution', Number)
  vciSubstitution = 0.0;

  @JsonProperty('vciComplement', Number)
  vciComplement = 0.0;

  vciDetruit = 0.0;
  stockVci: number = null;

  get totalVciUtilise() {
    return this.vciComplement + this.vciRemplacement + this.vciSubstitution;
  }
}
