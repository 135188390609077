import * as moment from 'moment';
import { Site } from './site.model';
import { SaisieRevendication } from './saisie-revendication.model';

export class LotSaisiesRevendications {
    libelleCahier: string;
    idCahier: number;
    date: moment.Moment;
    entreposage: Site;
    campagne: string;
    idOperateur: number;
    revendications: SaisieRevendication[];

    constructor(idCahier: number, libelleCahier: string, date: moment.Moment, entreposage: Site, campagne: string, idOperateur: number) {
        this.idCahier = idCahier;
        this.libelleCahier = libelleCahier;
        this.date = date;
        this.entreposage = entreposage;
        this.campagne = campagne;
        this.idOperateur = idOperateur;
        this.revendications = [];
    }
}
