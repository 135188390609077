import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RefProtectionGrele} from '../../models/ref-protection-grele.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RefProtectionGreleService {

  constructor( private readonly http: HttpClient) { }

  getRefProtectionGrele(): Observable<RefProtectionGrele[]> {
    return this.http.get<RefProtectionGrele[]>(`/api/declaration/private/referentielProtectionGrele`);
  }

  public creerRefProtectionGrele(grele: RefProtectionGrele): Observable<number> {
    return this.http.post(`/api/declaration/private/referentielProtectionGrele`, grele, { observe: 'response' }).pipe(
      map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
    );
  }  
  
  public modifierRefProtectionGrele(grele: RefProtectionGrele ): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/referentielProtectionGrele/${grele.id}`, grele );
  }
  
  public desactiverRefProtectionGrele(grele: RefProtectionGrele): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/referentielProtectionGrele/${grele.id}?desactivation=${true}`, grele );
  }
}
