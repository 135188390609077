export enum CategorieEnum {
    COMMISSION = 'COMMISSION',
    ECHANTILLON = 'ECHANTILLON',
    CONTROLE = 'CONTROLE',
    ORGANISME = 'ORGANISME',
    COMMUN = 'COMMUN',
    DECLARATION = 'DECLARATION',
    TOURNEE = 'TOURNEE',
    MANQUEMENT= 'MANQUEMENT'
}
