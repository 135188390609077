<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
      page.controles.echantillons.modal.saisirPrelevement.title
    </h4>
  </div>

  <div class="modal-body">
    <form [formGroup]="formPrelevement" #form="ngForm" (submit)="onSubmit()">
      <div class="form-group">
        <label for="date" translate>page.controles.echantillons.modal.saisirPrelevement.form.date</label>
        <div class="input-group">
          <input id="date" ngbDatepicker #date="ngbDatepicker" formControlName="date" class="form-control" [ngClass]="{ 'is-invalid': form.submitted && formPrelevement.get('date').invalid }" />
          <div class="input-group-append">
            <button type="button" (click)="date.toggle()" class="btn btn-secondary">
              <fa-icon icon="calendar-alt"></fa-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="observation" translate>page.controles.echantillons.modal.saisirPrelevement.form.observation</label>
        <textarea id="observation" formControlName="observation" class="form-control" [ngClass]="{ 'is-invalid': form.submitted && formPrelevement.get('observation').invalid }"></textarea>
      </div>

      <div class="form-group">
        <label for="representant" translate>page.controles.echantillons.modal.saisirPrelevement.form.representant</label>
        <input type="text" id="representant" formControlName="representant" class="form-control" [ngClass]="{ 'is-invalid': form.submitted && formPrelevement.get('representant').invalid }" />
      </div>

      <div class="actions-bar">
        <button type="button" (click)="onClose()" class="btn btn-light" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary" translate>label.valider</button>
      </div>
    </form>
  </div>
</div>