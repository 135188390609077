<div class="p-2 anpp content">
  <div class="row">
    <div class="col-lg-12 pl-0 mt-2 pl-4">
      <div class="row">
        <div class="col-lg-4">
          <h4 class="pt-0 pb-0"><b translate>page.campagnes.title</b></h4>
        </div>
        <div class="col-lg-4 text-center"></div>
      </div>
    </div>
  </div>
  <div class="row mt-4 pt-2 tableau">
    <div class="col-lg-12">
      <div class="cards border-radius text-center" style="background: #fff">
        <div class="row">
          <div class="col-lg-9 text-left pt-4">
            <a id="bar1" class="pl-4" (click)="showFilter = !showFilter">
              <fa-icon icon="filter"></fa-icon
              ><span style="text-decoration: underline" translate
                >page.campagnes.filtrer</span
              >
            </a>
          </div>
          <div class="col-lg-3 text-right p-2 pr-4">
            <button
              (click)="onNouvelleCampagne()"
              class="btn btn-lg btn-secondary btnprimary"
              data-toggle="modal"
              data-target="#campagne"
              translate
            >
              <fa-icon icon="plus"></fa-icon> page.campagnes.new
            </button>
          </div>
        </div>
        <div class="tableScroll">
        <table class="table table-striped campagne">
          <thead class="sticky-top"
            *ngIf="
              entrepriseList &&
              parametreCampagneList &&
              parametreCampagneList.length > 0
            "
          >
            <tr>
              <th scope="col">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck0"
                    name="example"
                    (change)="selectAllEntreprises($event)"
                  />
                  <label
                    class="custom-control-label"
                    for="customCheck0"
                  ></label>
                </div>
              </th>
              <th scope="col" translate>page.campagnes.operations</th>
              <th scope="col">
                <div translate>page.campagnes.raison_sociale</div>
                <input
                  type="text"
                  class="form-control bar1"
                  placeholder=""
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  *ngIf="showFilter"
                  [(ngModel)]="raisonSocialeFilter"
                  (keyup)="filter()"
                />
              </th>
              <th class="th-min-width-100" scope="col">
                <div translate>
                  page.campagnes.dr
                  <fa-icon
                    icon="info-circle"
                    placement="top"
                    ngbTooltip="Déclaration récolte à faire"
                  ></fa-icon>
                </div>
                <select
                  [(ngModel)]="recolteFilter"
                  class="custom-select bar1"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  *ngIf="showFilter"
                  (change)="filter()"
                >
                  <option [ngValue]="null"></option>
                  <option [ngValue]="true" translate>page.campagnes.oui</option>
                  <option [ngValue]="false" translate>
                    page.campagnes.non
                  </option>
                </select>
              </th>
              <th class="th-min-width-100" scope="col">
                <div translate>
                  page.campagnes.ds
                  <fa-icon
                    icon="info-circle"
                    placement="top"
                    ngbTooltip="Déclaration stock à faire"
                  ></fa-icon>
                </div>
                <select
                  [(ngModel)]="stockFilter"
                  class="custom-select bar1"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  *ngIf="showFilter"
                  (change)="filter()"
                >
                  <option [ngValue]="null"></option>
                  <option [ngValue]="true" translate>page.campagnes.oui</option>
                  <option [ngValue]="false" translate>
                    page.campagnes.non
                  </option>
                </select>
              </th>
              <th class="th-min-width-100" scope="col">
                <div translate>
                  page.campagnes.de
                  <fa-icon
                    icon="info-circle"
                    placement="top"
                    ngbTooltip="Déclaration écoulement à faire"
                  ></fa-icon>
                </div>
                <select
                  [(ngModel)]="ecoulementFilter"
                  class="custom-select bar1"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  *ngIf="showFilter"
                  (change)="filter()"
                >
                  <option [ngValue]="null"></option>
                  <option [ngValue]="true" translate>page.campagnes.oui</option>
                  <option [ngValue]="false" translate>
                    page.campagnes.non
                  </option>
                </select>
              </th>
              <th scope="col">
                <div translate>page.campagnes.referent</div>
                <select
                  [(ngModel)]="referentFilter"
                  class="custom-select bar1"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  *ngIf="showFilter"
                  (change)="filter()"
                >
                  <option [ngValue]="null"></option>
                  <option value="pasReferentRecolte" translate>
                    page.campagnes.no_referent_recolte
                  </option>
                  <option value="pasReferentStock" translate>
                    page.campagnes.no_referent_stock
                  </option>
                  <option value="pasReferentEcoulement" translate>
                    page.campagnes.no_referent_ecoulement
                  </option>
                </select>
              </th>

              <th scope="col">
                <div translate>page.campagnes.type</div>
                <select
                  class="custom-select bar1"
                  id="inputGroupSelect04"
                  [(ngModel)]="typeFilter"
                  aria-label="Example select with button addon"
                  *ngIf="showFilter"
                  (change)="filter()"
                >
                  <option [ngValue]="null"></option>
                  <option *ngFor="let type of sousTypeList" [ngValue]="type">
                    {{ type.libelle }}
                  </option>
                </select>
              </th>

              <th scope="col">
                <div translate>page.campagnes.terroir</div>
                <select
                  class="custom-select bar1"
                  id="inputGroupSelect04"
                  [(ngModel)]="terroirFilter"
                  aria-label="Example select with button addon"
                  *ngIf="showFilter"
                  (change)="filter()"
                >
                  <option [ngValue]="null"></option>
                  <option
                    *ngFor="let terroir of terroirList"
                    [ngValue]="terroir"
                  >
                    {{ terroir.libelle }}
                  </option>
                </select>
              </th>

              <th scope="col">
                <div translate>page.campagnes.campagne</div>
              </th>
            </tr>
          </thead>
          <tbody *ngIf="entreprisesFitrees && parametreCampagneList">
            <tr *ngIf="parametreCampagneList.length === 0">
              <td colspan="10" translate class="p-4">
                <fa-icon icon="exclamation-triangle" class="mt-2 p-2"></fa-icon
                >page.campagnes.no_parameter
              </td>
            </tr>
            <tr *ngIf="entreprisesFitrees.length === 0">
              <td colspan="10" translate class="p-4">
                <fa-icon icon="exclamation-triangle" class="mt-2 p-2"></fa-icon
                >page.campagnes.no_entreprise_filtree
              </td>
            </tr>
            <ng-container
              *ngIf="
                entreprisesFitrees.length > 0 &&
                parametreCampagneList.length > 0
              "
            >
              <tr *ngFor="let entreprise of entreprisesFitrees">
                <td>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="{{ entreprise.id }}"
                      name="example1"
                      [checked]="entreprisesSelectionnees.includes(entreprise)"
                      (change)="selectionnerEntreprise(entreprise)"
                    />
                    <label
                      class="custom-control-label"
                      for="{{ entreprise.id }}"
                    ></label>
                  </div>
                </td>
                <td>
                  <div class="btn-group dropright">
                    <div ngbDropdown>
                      <button
                        class="btn btn-secondary dropdown-toggle btnprimary btn-sm"
                        id="dropdownConfig"
                        ngbDropdownToggle
                      >
                        <fa-icon icon="cog"></fa-icon>
                      </button>
                      <div
                        ngbDropdownMenu
                        aria-labelledby="dropdownConfig"
                        class="operation-menu"
                      >
                        <button
                          ngbDropdownItem
                          class="dropdown-item"
                          (click)="modifierReferent(entreprise)"
                          translate
                        >
                          <fa-icon icon="users"></fa-icon
                          >page.campagnes.modifier_referent
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{{ entreprise.raisonSociale }}</td>
                <td>
                  <select
                    class="custom-select"
                    [(ngModel)]="entreprise.parametreCampagne.droitRecolte"
                    (change)="addDroitsModifieList(entreprise)"
                  >
                    <option [value]="true" translate>page.campagnes.oui</option>
                    <option [value]="false" translate>
                      page.campagnes.non
                    </option>
                  </select>
                </td>
                <td>
                  <select
                    class="custom-select"
                    [(ngModel)]="entreprise.parametreCampagne.droitStock"
                    (change)="addDroitsModifieList(entreprise)"
                  >
                    <option [value]="true" translate>page.campagnes.oui</option>
                    <option [value]="false" translate>
                      page.campagnes.non
                    </option>
                  </select>
                </td>
                <td>
                  <select
                    class="custom-select"
                    [(ngModel)]="entreprise.parametreCampagne.droitEcoulement"
                    (change)="addDroitsModifieList(entreprise)"
                  >
                    <option [value]="true" translate>page.campagnes.oui</option>
                    <option [value]="false" translate>
                      page.campagnes.non
                    </option>
                  </select>
                </td>
                <td>
                  <ng-template #tipRecolte>
                    <span
                      *ngIf="
                        !entreprise.referentRecolteList ||
                        entreprise.referentRecolteList.length === 0
                      "
                      translate
                      >page.campagnes.no_referent</span
                    >
                    <ul>
                      <li
                        *ngFor="let contact of entreprise.referentRecolteList"
                      >
                        {{ contact.nom }} {{ contact.prenom }} -
                        {{ contact.mail }} - {{ contact?.telephone }}
                      </li>
                    </ul>
                  </ng-template>

                  <ng-template #tipStock>
                    <span
                      *ngIf="
                        !entreprise.referentStockList ||
                        entreprise.referentStockList.length === 0
                      "
                      >Pas de référent</span
                    >
                    <ul>
                      <li *ngFor="let contact of entreprise.referentStockList">
                        {{ contact.nom }} {{ contact.prenom }} -
                        {{ contact.mail }} - {{ contact?.telephone }}
                      </li>
                    </ul>
                  </ng-template>

                  <ng-template #tipEcoulement>
                    <span
                      *ngIf="
                        !entreprise.referentEcoulementList ||
                        entreprise.referentEcoulementList.length === 0
                      "
                      >Pas de référent</span
                    >
                    <ul>
                      <li
                        *ngFor="
                          let contact of entreprise.referentEcoulementList
                        "
                      >
                        {{ contact.nom }} {{ contact.prenom }} -
                        {{ contact.mail }} - {{ contact?.telephone }}
                      </li>
                    </ul>
                  </ng-template>
                  <span
                    class="badge badge-secondary badge-green"
                    [ngClass]="{
                      'badge-green': entreprise.parametreCampagne.droitRecolte.toString() == 'true' && entreprise.referentRecolteList.length > 0,
                      'badge-red': entreprise.parametreCampagne.droitRecolte.toString() == 'true' && entreprise.referentRecolteList.length === 0,
					            'badge-grey': entreprise.parametreCampagne.droitRecolte.toString() == 'false' && entreprise.referentRecolteList.length === 0
                    }"
                    placement="top"
                    [ngbTooltip]="tipRecolte"
                    placement="top"
                  >
                    DR
                  </span>
                  <span
                    class="badge badge-secondary"
                    [ngClass]="{
                      'badge-green': entreprise.parametreCampagne.droitStock.toString() == 'true' && entreprise.referentStockList.length > 0,
                      'badge-red': entreprise.parametreCampagne.droitStock.toString() == 'true' && entreprise.referentStockList.length === 0,
					            'badge-grey': entreprise.parametreCampagne.droitStock.toString() == 'false' && entreprise.referentStockList.length === 0
                    }"
                    placement="top"
                    [ngbTooltip]="tipStock"
                  >
                    DS
                  </span>
                  <span
                    class="badge badge-secondary badge-red"
                    [ngClass]="{
                      'badge-green': entreprise.parametreCampagne.droitEcoulement.toString() == 'true' && entreprise.referentEcoulementList.length > 0,
                      'badge-red': entreprise.parametreCampagne.droitEcoulement.toString() == 'true' && entreprise.referentEcoulementList.length === 0,
					            'badge-grey': entreprise.parametreCampagne.droitEcoulement.toString() == 'false' && entreprise.referentEcoulementList.length === 0
                    }"
                    placement="top"
                    [ngbTooltip]="tipEcoulement"
                  >
                    DE
                  </span>
                </td>

                <td>{{ entreprise.sousType.libelle }}</td>
                <td>{{ entreprise.terroir?.libelle }}</td>
                <td>
                  <span class="date">{{ campagneEncours.libelle }}</span>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      </div>
    </div>
  </div>
</div>

<div class="action-footer">
  <div class="dropdown" style="position: fixed; bottom: 10px">
    <div ngbDropdown placement="top-start">
      <button
        class="btn btn-secondary dropdown-toggle btnprimary"
        id="dropdownConfig"
        ngbDropdownToggle
        translate
      >
        <fa-icon icon="cog"></fa-icon>page.campagnes.operations_groupees
      </button>
      <div ngbDropdownMenu class="operations-groupees-menu">
        <li class="dropdown-item-custom" translate>
          page.campagnes.dr_a_faire
          <button
            type="button"
            class="btn-sm btn-secondary btnprimary mr-2 ml-2"
            (click)="modifierDR(true)"
            translate
          >
            page.campagnes.oui_maj
          </button>
          <button
            type="button"
            class="btn-sm btn-secondary btnprimary"
            (click)="modifierDR(false)"
            translate
          >
            page.campagnes.non_maj
          </button>
        </li>
        <div class="dropdown-divider"></div>
        <li class="dropdown-item-custom" translate>
          page.campagnes.ds_a_faire
          <button
            type="button"
            class="btn-sm btn-secondary btnprimary mr-2 ml-2"
            (click)="modifierStock(true)"
            translate
          >
            page.campagnes.oui_maj
          </button>
          <button
            type="button"
            class="btn-sm btn-secondary btnprimary"
            (click)="modifierStock(false)"
            translate
          >
            page.campagnes.non_maj
          </button>
        </li>
        <div class="dropdown-divider"></div>
        <li class="dropdown-item-custom" translate>
          page.campagnes.de_a_faire
          <button
            type="button"
            class="btn-sm btn-secondary btnprimary mr-2 ml-2"
            (click)="modifierEcoulement(true)"
            translate
          >
            page.campagnes.oui_maj
          </button>
          <button
            type="button"
            class="btn-sm btn-secondary btnprimary"
            (click)="modifierEcoulement(false)"
            translate
          >
            page.campagnes.non_maj
          </button>
        </li>
      </div>
    </div>
  </div>
  <div style="position: fixed; bottom: 10px; right: 25px">
    <a
      (click)="save()"
      class="btn btn-lg btn-secondary btnprimary"
      style="background: #41905c; border: 1px solid #41905c"
      translate
    >
      page.campagnes.enregistrer
    </a>
  </div>
</div>
