import {CustomConverter, JsonObject, JsonProperty} from '@thorolf/json-ts-mapper';
import {IdCodeLibelle} from '../../id-code-libelle.abstract.model';
import {DataIdConverterFactory} from '../../../core/services/mapper/data-id.converter';
import {NatureControle} from './nature-controle.model';

@JsonObject
export class ObjetControle extends IdCodeLibelle {
  @JsonProperty('idNature', Number)
  idNature: number;
}
