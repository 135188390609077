<div class="border-radius-onglet anpp">
  <div class="border-radius-onglet" *ngIf="!entrepriseId">
    <span translate><fa-icon icon="exclamation" class="mt-2 p-2"></fa-icon> page.ecoulement.no_adherent_selectionne</span>
  </div>
  <form [formGroup]="formGroup" #form="ngForm" *ngIf="entrepriseId">
    <div class="row tableau">
      <div class="col-lg-12">
        <div class="cards  border-radius-onglet text-center" style="background:#fff">
          <div class="row">
            <div class="col-lg-8 text-left pl-4 pt-4">

              <select formControlName="selectionCampagne" class="form-control" (change)="onCampagneChange()"
                style="display:inline; width:auto">
                <option [value]="null" hidden></option>
                <option *ngFor="let camp of campagnes" [ngValue]="camp" [selected]="camp.annee === campagne?.annee">
                  {{camp.libelle}}</option>
              </select>

              <fa-icon (click)="reculerMois()" icon="chevron-left" class="lienMain" [ngClass]="{'chevronDisabled' : isFirstMonth()}"></fa-icon>
              <select formControlName="selectionMois" class="form-control" (change)="onMoisChange()"
                style="display:inline; width:auto">
                <option [value]="null" hidden></option>
                <option *ngFor="let leMois of mois" [ngValue]="leMois" [selected]="leMois === numMois">
                  {{getLibelleMois(leMois)}}</option>
              </select>
              <fa-icon (click)="avancerMois()" icon="chevron-right" class="lienMain" [ngClass]="{'chevronDisabled' : isLastMonth()}"></fa-icon>
            </div>


            <div class="col-lg-4 text-right">
            </div>
          </div>
          <div class="tableScroll">
          <table class="table table-striped mt-2">
            <thead class="center sticky-top">
              <tr>
                <!--icone-->
                <th scope="col"> </th>
                <!--groupe varietal-->
                <th scope="col" class="text-left bar1">
                  <div ><span translate>page.ecoulement.table.header.groupeVarietal</span></div>
                </th>
                <!-- France-->
                <th scope="col" colspan="2" class="bar1"> <span
                    translate>page.ecoulement.table.header.france</span></th>
                <!-- Export-->
                <th scope="col" colspan="2" class="bar1"> <span
                    translate>page.ecoulement.table.header.export</span></th>
                <!-- Industrie -->
                <th scope="col" colspan="2" class="bar1"> <span
                  translate>page.ecoulement.table.header.industrie</span></th>
                <!-- Freinte -->
                <th scope="col" colspan="2" class="bar1"> <span
                  translate>page.ecoulement.table.header.freinte</span></th>
                <!-- totaux -->
                <th scope="col" colspan="2" class="bar1"> <span></span></th>
              </tr>
              <tr>
                <!--icone-->
                <th scope="col" style="position:relative; width:60px;"></th>
                <!--groupe varietal-->
                <th scope="col" style="position:relative"></th>
                <!-- France-->
                <th scope="col" class="center" ><small><strong translate>page.ecoulement.table.header.qteTotale</strong></small></th>
                <th scope="col" class="center" ><small><strong translate>page.ecoulement.table.header.qteBio</strong></small></th>
                <!-- Export-->
                <th scope="col" class="center" ><small><strong translate>page.ecoulement.table.header.qteTotale</strong></small></th>
                <th scope="col" class="center" ><small><strong translate>page.ecoulement.table.header.qteBio</strong></small></th>
                <!-- Industrie -->
                <th scope="col" class="center" ><small><strong translate>page.ecoulement.table.header.qteTotale</strong></small></th>
                <th scope="col" class="center" ><small><strong translate>page.ecoulement.table.header.qteBio</strong></small></th>
                <!-- Freinte -->
                <th scope="col" class="center" ><small><strong translate>page.ecoulement.table.header.qteTotale</strong></small></th>
                <th scope="col" class="center" ><small><strong translate>page.ecoulement.table.header.qteBio</strong></small></th>
                <!-- totaux -->
                <th scope="col" class="center bg-td" ><small><strong translate>page.ecoulement.table.header.qteTotale</strong></small></th>
                <th scope="col" class="center bg-td" ><small><strong translate>page.ecoulement.table.header.qteBio</strong></small></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="ecoulements && ecoulements.length === 0">
                <td colspan="99" class="p-4"><fa-icon icon="exclamation"></fa-icon>&nbsp;
                  <span translate>page.ecoulement.table.no-data</span>
                </td>
              </tr>
              <tr *ngFor="let ecoulement of ecoulements ; let i = index"
                [ngClass]="{'separation-pomme-poire' : ecoulement.isTotal && ecoulement.codeEspece === 'POMME', 'font-weight-bold' : ecoulement.isTotal }">
                <!-- icone pomme/poire -->
                <td>
                  <span *ngIf="ecoulement.codeEspece==='POMME'">
                    <fa-icon icon='apple-alt' style="color:black;"></fa-icon>
                    <fa-icon *ngIf="ecoulement.isTotal" icon='apple-alt' style="color:black;"></fa-icon>
                  </span>
                  <span *ngIf="ecoulement.codeEspece==='POIRE'">
                    <img src="/assets/img/pear.png" width="16px" />
                    <img *ngIf="ecoulement.isTotal" src="/assets/img/pear.png" width="16px" />
                  </span>
                </td>
                <td scope="col" class="text-left">
                  <span class="souligne" placement="top"><b>{{ecoulement.groupeVarietal}}</b></span>
                </td>

                <!-- france -->
                <td scope="col" class="center">
                  <input [disabled]="moisHorsLimites" *ngIf="isSaisissable && !ecoulement.isTotal" type="number" class="form-control ecoulement-input" placeholder="{{ 'page.ecoulement.table.header.qteTotale' | translate }}" style="width:90px; text-align: center !important;"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="ecoulement.qteTotaleFrance" min="0" max="10000"
                    name="qteTotaleFrance{{i}}" (change)="updateTotaux()" (keypress)="keyPressNumbers($event)" />
                  <span *ngIf="!isSaisissable || ecoulement.isTotal">{{ecoulement.qteTotaleFrance | saisieRecolteFormat}}</span>
                </td>
                <td scope="col" class="center">
                  <input [disabled]="moisHorsLimites" *ngIf="isSaisissable && !ecoulement.isTotal" type="number" class="form-control ecoulement-input" placeholder="{{ 'page.ecoulement.table.header.qteBio' | translate }}" style="width:90px; text-align: center !important;"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="ecoulement.qteBioFrance" min="0" max="10000"
                    name="qteBioFrance{{i}}" (change)="updateTotaux()" (keypress)="keyPressNumbers($event)" />
                  <span *ngIf="!isSaisissable || ecoulement.isTotal">{{ecoulement.qteBioFrance | saisieRecolteFormat}}</span>
                </td>

                <!-- export -->
                <td scope="col" class="center">
                  <input [disabled]="moisHorsLimites" *ngIf="isSaisissable && !ecoulement.isTotal" type="number" class="form-control ecoulement-input" placeholder="{{ 'page.ecoulement.table.header.qteTotale' | translate }}" style="width:90px; text-align: center !important;"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="ecoulement.qteTotaleExport" min="0" max="10000"
                    name="qteTotaleExport{{i}}" (change)="updateTotaux()" (keypress)="keyPressNumbers($event)" />
                  <span *ngIf="!isSaisissable || ecoulement.isTotal">{{ecoulement.qteTotaleExport | saisieRecolteFormat}}</span>
                </td>
                <td scope="col" class="center">
                  <input [disabled]="moisHorsLimites" *ngIf="isSaisissable && !ecoulement.isTotal" type="number" class="form-control ecoulement-input" placeholder="{{ 'page.ecoulement.table.header.qteBio' | translate }}" style="width:90px; text-align: center !important;"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="ecoulement.qteBioExport" min="0" max="10000"
                    name="qteBioExport{{i}}" (change)="updateTotaux()" (keypress)="keyPressNumbers($event)" />
                  <span *ngIf="!isSaisissable || ecoulement.isTotal">{{ecoulement.qteBioExport | saisieRecolteFormat}}</span>
                </td>

                <!-- industrie -->
                <td scope="col" class="center">
                  <input [disabled]="moisHorsLimites" *ngIf="isSaisissable && !ecoulement.isTotal" type="number" class="form-control ecoulement-input" placeholder="{{ 'page.ecoulement.table.header.qteTotale' | translate }}" style="width:90px; text-align: center !important;"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="ecoulement.qteTotaleIndustrie" min="0" max="10000"
                    name="qteTotaleIndustrie{{i}}" (change)="updateTotaux()" (keypress)="keyPressNumbers($event)" />
                  <span *ngIf="!isSaisissable || ecoulement.isTotal">{{ecoulement.qteTotaleIndustrie | saisieRecolteFormat}}</span>
                </td>
                <td scope="col" class="center">
                  <input [disabled]="moisHorsLimites" *ngIf="isSaisissable && !ecoulement.isTotal" type="number" class="form-control ecoulement-input" placeholder="{{ 'page.ecoulement.table.header.qteBio' | translate }}" style="width:90px; text-align: center !important;"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="ecoulement.qteBioIndustrie" min="0" max="10000" (change)="updateTotaux()"
                    name="qteBioIndustrie{{i}}" (change)="updateTotaux()" (keypress)="keyPressNumbers($event)" />
                  <span *ngIf="!isSaisissable || ecoulement.isTotal">{{ecoulement.qteBioIndustrie | saisieRecolteFormat}}</span>
                </td>
                <!-- freinte -->
                <td scope="col" class="center">
                  <input [disabled]="moisHorsLimites" *ngIf="isSaisissable && !ecoulement.isTotal" type="number" class="form-control ecoulement-input" placeholder="{{ 'page.ecoulement.table.header.qteTotale' | translate }}" style="width:90px; text-align: center !important;"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="ecoulement.qteTotaleFreinte" min="0" max="10000" (change)="updateTotaux()"
                    name="qteTotaleFreinte{{i}}" (change)="updateTotaux()" (keypress)="keyPressNumbers($event)" />
                  <span *ngIf="!isSaisissable || ecoulement.isTotal">{{ecoulement.qteTotaleFreinte | saisieRecolteFormat}}</span>
                </td>
                <td scope="col" class="center">
                  <input [disabled]="moisHorsLimites" *ngIf="isSaisissable && !ecoulement.isTotal" type="number" class="form-control ecoulement-input" placeholder="{{ 'page.ecoulement.table.header.qteBio' | translate }}" style="width:90px; text-align: center !important;"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="ecoulement.qteBioFreinte" min="0" max="10000"
                    name="qteBioFreinte{{i}}" (change)="updateTotaux()" (keypress)="keyPressNumbers($event)" />
                  <span *ngIf="!isSaisissable || ecoulement.isTotal">{{ecoulement.qteBioFreinte | saisieRecolteFormat}}</span>
                </td>
                <td scope="col" class="center bg-td">{{ecoulement.qteTotale() | saisieRecolteFormat}}</td>
                <td scope="col" class="center bg-td">{{ecoulement.qteBio() | saisieRecolteFormat}}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
      </div>
      <p>&nbsp;</p>
    </div>
    <div style="position:fixed; bottom:10px;right:25px">
      <button *ngIf="(numMoisCourant === numMois || estAnpp) && !moisHorsLimites" type="button" #submitButton (click)="onSave()" style="background:#41905c;border:1px solid #41905c"
        class="btn btn-lg btn-secondary  btnprimary">Enregistrer</button>
    </div>
  </form>
</div>
