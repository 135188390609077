import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeclarationsService } from 'src/app/data/declaration/services/declarations/declarations.service';
import { DREVSaisie } from 'src/app/data/declaration/models/drevsaisie.model';
import { Declarations } from 'src/app/data/declaration/models/declarations.model';
import { DeclarationRevendication } from 'src/app/data/declaration/models/declaration-revendication.model';
import { SaisieVciComponent } from './modals/saisie-vci/saisie-vci.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionContext, NavigationContext } from 'src/app/core/services/config/app.settings';
import { forkJoin } from 'rxjs';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';

@Component({
  selector: 'app-affichage-drev',
  templateUrl: './affichage-drev.component.html',
  styleUrls: ['./affichage-drev.component.scss']
})
export class AffichageDrevComponent implements OnInit {
  public declarations: Declarations;
  public annee: number;

  constructor(
    private readonly declarationsService: DeclarationsService,
    private readonly referentielService: ReferentielService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly modalService: NgbModal
  ) { }

  ngOnInit() {

    setTimeout(() => this.loadData());


  }

  get idIntervenant(): number {
    if (NavigationContext.has('idOperateur')) {
      return NavigationContext.get('idOperateur');
    } else {
      return SessionContext.get('idIntervenant');
    }
  }

  private loadData() {
    const annees: number[] = [];

    // Récupérer les declarations AOC
    forkJoin(
      this.referentielService.getReferentiel(),
      this.declarationsService.getDeclarationsRevendication(this.idIntervenant)
    ).subscribe(
      ([campagnes, declarations]) => {
        campagnes.campagnes.forEach(c => {
          annees.push(c.annee);
        });
        this.annee = annees.reduce((a, b) => Math.max(a, b));
        this.declarations = declarations;
      },
      error => {
        try {
          if (error.error.code === 'BACCHUS_BUSINESS_NO_DR') {
            this.declarations.aocs = [];
            this.declarations.igps = [];
            return;
          }
        } catch (e) { }
        throw error;
      });
  }

  public sendData() {
    const saisies: DREVSaisie[] = [];
    this.declarations.aocs.forEach(declaration => {
      declaration.drevSaisie.codeProduit = declaration.codeProduit;
      declaration.drevSaisie.mention = declaration.mention;
      saisies.push(declaration.drevSaisie);
    });
    this.declarationsService.saisieStockDeclarationsRevendications(saisies, this.idIntervenant).subscribe(() => {
      this.router.navigate(['../synthese'], { relativeTo: this.route });
    });
  }

  utilisationVci(declaration: DeclarationRevendication) {
    const modal = this.modalService.open(SaisieVciComponent, { backdrop: 'static', size: 'xl' as any });
    (modal.componentInstance as SaisieVciComponent).campagne = this.annee;
    (modal.componentInstance as SaisieVciComponent).drev = declaration;
    (modal.componentInstance as SaisieVciComponent).saisie = declaration.drevSaisie;

    modal.result.then((saisie: DREVSaisie) => {
      declaration.setSaisieVci(saisie);
    }, () => { });
  }
}
