import { JsonProperty, JsonObject, Optional, CustomConverter } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { DateTimeConverter } from 'src/app/core/services/mapper/converters';


@JsonObject
export class DetailsObjetFacture {
    @JsonProperty('id', Number)
    @Optional
    id: number;

    @JsonProperty('idToSend', [Number])
    @Optional
    idToSend: number[];

    @JsonProperty('dateFacturation', String)
    @Optional
    @CustomConverter(DateTimeConverter)
    dateFacturation: moment.Moment;

    @JsonProperty('mailOperateur', String)
    @Optional
    mailOperateur: string;

    @JsonProperty('montantTva', Number)
    @Optional
    montantTva: number;

    @JsonProperty('prixMini', Number)
    @Optional
    prixMini: number;

    @JsonProperty('prixUnitaire', Number)
    @Optional
    prixUnitaire: number;

    @JsonProperty('quantite', Number)
    @Optional
    quantite: number;

    @JsonProperty('totalHt', Number)
    @Optional
    totalHt: number;

    @JsonProperty('totalTtc', Number)
    @Optional
    totalTtc: number;

    @JsonProperty('tva', Number)
    @Optional
    tva: number;

    @JsonProperty('idIntervenant', Number)
    @Optional
    idIntervenant: number;

    @JsonProperty('libelleModeleArticle', String)
    @Optional
    libelleModeleArticle: string;

    @JsonProperty('idObjetFactures', [Number])
    @Optional
    idObjetFactures: number[];

    @JsonProperty('idRefQuantiteFacture', Number)
    @Optional
    idRefQuantiteFacture: number;
}
