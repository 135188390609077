import { Injectable } from '@angular/core';
import { Tournee } from '../models/tournee.model';
import { TourneeAgenda } from '../models/tournee-agenda.model';
import { HttpClient } from '@angular/common/http';
import { flatMap, map, switchMap, tap } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { AnimateursService } from '../../commission/services/animateurs/animateurs.service';
import { CahiersService } from '../../habilitation/services/cahiers/cahiers.service';
import { OrganismesService } from '../../intervenant/services/organismes/organismes.service';
import { ReferentielService } from '../../declaration/services/referentiel/referentiel.service';
import { ProduitsService } from '../../habilitation/services/produits/produits.service';
import { OperateursService } from '../../intervenant/services/operateurs/operateurs.service';
import { TourneeEchantillon } from '../models/tournee-echantillon.models';
import { OperateurSitesService } from '../../declaration/services/operateur-sites/operateur-sites.service';

@Injectable({
  providedIn: 'root'
})
export class TourneesService {

  private dateMapper: DateConverter;

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly animateursService: AnimateursService,
    private readonly cahiersService: CahiersService,
    private readonly produitsService: ProduitsService,
    private readonly operateurService: OperateursService,
    private readonly organismeService: OrganismesService,
    private readonly referentielService: ReferentielService,
    private readonly OperateurSitesService: OperateurSitesService

  ) {
    this.dateMapper = new DateConverter();
  }

  getAgendaTournees(idOrganisme: number, dateDebut: moment.Moment, dateFin: moment.Moment): Observable<TourneeAgenda[]> {
    const debut = this.dateMapper.serialize(dateDebut);
    const fin = this.dateMapper.serialize(dateFin);

    return forkJoin(
      this.http.get<object[]>(`/api/declaration/private/organismes/${idOrganisme}/tournees-agenda?debut=${debut}&fin=${fin}`),
      this.animateursService.getAnimateurs(idOrganisme)
    ).pipe(
      map(([tournees, techniciens]) => this.mapper.deserializeArray(tournees, TourneeAgenda, Object.assign({ techniciens })))
    );
  }

  getTournees(idOrganisme: number, idTournee: number): Observable<any> {
      
    return forkJoin(
      this.http.get<object>(`/api/declaration/private/organismes/${idOrganisme}/tournees/${idTournee}`),
      this.referentielService.getReferentiel(),
      this.animateursService.getAnimateurs(idOrganisme),
      this.cahiersService.getCahiers(),
      this.organismeService.getOrganismes()
    ).pipe(
      map(([tournee, referentiel, techniciens, cahiers, organismes]) =>
        this.mapper.deserializeObject(tournee, Tournee, Object.assign({ techniciens, cahiers, organismes }, referentiel ))
      )
      ,tap((tourn)=> {
        tourn.echantillons.forEach(item => {
          this.operateurService.getOperateur(item.idOperateur)
            .subscribe(operateur => item.operateur = operateur);
        });
      }),
      tap(tourn => {
        tourn.echantillons.forEach(item => {
          this.operateurService.getInformationsDomaine(item.idOperateur)
            .subscribe(infos => item.infosOperateur = infos);
        });
      })
      ,
      tap(tourn => {
        tourn.echantillons.forEach(item => {          
            this.produitsService.getProduitByCode(item.codeProduit)
              .subscribe(produit => item.produit = produit);
          });
      })
      ,
      tap(tourn => {
        tourn.echantillons.forEach(item => {          
          this.OperateurSitesService.getSiteOperateur(item.idSite)
            .subscribe(site => item.site = site);
        });
      })
    );      
  } 

  createTournee(idOrganisme: number, tournee: Tournee): Observable<{ id: number, numero: string }> {
    return this.http.post(`/api/declaration/private/organismes/${idOrganisme}/tournees`, this.mapper.serialize(tournee), { observe: 'response', responseType: 'text' }).pipe(
      map(response => {
        return {
          id: parseInt(response.headers.get('location').split('/').pop(), 10),
          numero: response.body
        };
      })
    );
  }

  modifyTournee(idOrganisme: number, tournee: Tournee): Observable<{ id: number, numero: string }> {
    return this.http.put(`/api/declaration/private/organismes/${idOrganisme}/tournees/${tournee.id}`, this.mapper.serialize(tournee), { observe: 'response', responseType: 'text' }).pipe(
      map(response => {
        return {
          id: parseInt(response.headers.get('location').split('/').pop(), 10),
          numero: response.body
        };
      })
    );
  }

  deleteTournee(idOrganisme: number, tournee: Tournee): Observable<void> {
    return this.http.delete(`/api/declaration/private/organismes/${idOrganisme}/tournees/${tournee.id}`).pipe(
      map(() => { })
    );
  }

  deleteEchantillonTournee(idOrganisme: number, echantillon: TourneeEchantillon): Observable<void> {
    return this.http.delete(`/api/declaration/private/organismes/${idOrganisme}/tournees/echantillons/${echantillon.id}`).pipe(
      map(() => { })
    );
  }

  getEchantillonsLibres(idOrganisme: number): Observable<TourneeEchantillon[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/declaration/private/organismes/${idOrganisme}/tournees/echantillons`),
      this.referentielService.getReferentiel(),
      this.organismeService.getOrganismes()
    ).pipe(
      map(([echantillons, ref, organismes]) => {
        return this.mapper.deserializeArray(echantillons, TourneeEchantillon, Object.assign({ organismes }, ref));
      }),
      this.produitsService.getProduitPipe(),
      this.operateurService.getOperateurPipe<TourneeEchantillon>(),
      tap(echantillons => {
        echantillons.forEach(item => {
          this.operateurService.getInformationsDomaine(item.idOperateur)
            .subscribe(infos => item.infosOperateur = infos);
        });
      })
    );
  }

}
