import { JsonConverter } from '@thorolf/json-ts-mapper';
import { DataPropertyConverter /*, DataPropertyConverterArray*/ } from './data-property.converter';

@JsonConverter
export abstract class DataIdConverter<T extends { id: any }> extends DataPropertyConverter<T, 'id'> {
  constructor(contextKey: string) {
    super('id', contextKey);
  }
}

export class DataIdConverterFactory {

  static build<T extends { id: any }>(clazz: new () => T, contextKey: string) {
    class Converter extends DataIdConverter<T> {
      constructor() {
        super(contextKey);
      }
    }

    return Converter;
  }
}

class Aaa {
  static mapperContextKey = 'aa';
  id: string;

}
