<div>
  <div class="modal-header">
    <h4 class="modal-title">Conditionnement</h4>
  </div>

  <div class="modal-body">
    <form [formGroup]="formSaisieConditionnement" #form="ngForm" (ngSubmit)="submit()">
      <div class="cols">
        <div class="col">
          <div class="form-group">
            <label for="produit">{{ 'page.declarations.synthese.modal.saisirConditionnement.produit' | translate }}</label>
            <input type="text" formControlName="produit" id="produit" name="produit" class="form-control" />
          </div>

          <div class="form-group">
            <label for="date">{{ 'page.declarations.synthese.modal.saisirConditionnement.dateConditionnement' | translate }}</label>
            <div class="input-group">
              <input type="text" formControlName="date" id="date" name="date" class="form-control" ngbDatepicker #datePicker="ngbDatepicker" required [ngClass]="{ 'is-invalid': form.submitted && date.invalid }" />
              <div class="input-group-append">
                <button type="button" (click)="datePicker.toggle()" class="btn btn-secondary">
                  <fa-icon icon="calendar-alt"></fa-icon>
                </button>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="cepage">{{ 'page.declarations.synthese.modal.saisirConditionnement.cepage' | translate }}</label>
            <app-multiselect id="select-cepages" maxWidth="initial" bindLabel="libelle" controlName="cepages" [items]="referentiel.cepages" [ngClass]="{ 'is-invalid': form.submitted && cepages.invalid }"></app-multiselect>
          </div>

          <div class="form-group">
            <label for="commentaire">{{ 'page.declarations.synthese.modal.saisirConditionnement.observations' | translate }}</label>
            <textarea formControlName="observations" id="observations" name="observations" class="form-control" [ngClass]="{ 'is-invalid': form.submitted && observations.invalid }"></textarea>
          </div>

          <div class="form-group">
            <label for="logement">{{ 'page.declarations.synthese.modal.saisirConditionnement.logement' | translate }}</label>
            <textarea formControlName="logement" id="logement" name="logement" class="form-control" required [ngClass]="{ 'is-invalid': form.submitted && logement.invalid }"></textarea>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="lot">{{ 'page.declarations.synthese.modal.saisirConditionnement.numeroLot' | translate }}</label>
            <input type="text" formControlName="lot" id="lot" name="lot" class="form-control" required [ngClass]="{ 'is-invalid': form.submitted && lot.invalid }" />
          </div>

          <div class="form-group">
            <label for="volume">{{ 'page.declarations.synthese.modal.saisirConditionnement.volume' | translate }} {{ campagne }} ({{ 'page.declarations.synthese.modal.saisirConditionnement.hL' | translate }})</label>
            <input type="number" lang="en" formControlName="volume" id="volume" name="volume" class="form-control" required [ngClass]="{ 'is-invalid': form.submitted && volume.invalid }" />
          </div>
          <hr />

          <div class="form-group">
            <label for="entreposage">{{ 'page.declarations.synthese.modal.saisirConditionnement.entreposage' | translate }}</label>
            <select formControlName="entreposage" id="entreposage" name="entreprosage" class="form-control" [ngClass]="{'is-invalid': form.submitted && entreposage.invalid, 'select-placeholder' : (entreposage.value === null && !entreposage.dirty)}">
              <option [ngValue]="null" hidden selected>{{ 'page.declarations.synthese.modal.changementDenomination.listeSites' | translate }}</option>
              <option *ngFor="let siteOperateur of sitesOperateur" [ngValue]="siteOperateur">{{ siteOperateur?.adresse }}</option>
            </select>
          </div>
          <hr />

          <!-- Formulaire Contenants -->
          <div formArrayName="contenants" [ngClass]="{ 'is-invalid': form.submitted && formSaisieConditionnement.get('contenants').invalid }">
            <div *ngFor="let contenant of getContenants(); let i = index" [formGroupName]="i">
              <div class="form-row" *ngIf="i == 0">
                <div class="form-group col-md-5"><label>{{ 'page.declarations.synthese.modal.saisirConditionnement.type' | translate }}</label></div>
                <div class="form-group col-md-3"><label>{{ 'page.declarations.synthese.modal.saisirConditionnement.nombre' | translate }}</label></div>
                <div class="form-group col-md-3"><label>{{ 'page.declarations.synthese.modal.saisirConditionnement.volume' | translate }} ({{ 'page.declarations.synthese.modal.saisirConditionnement.hL' | translate }})</label></div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-5">
                  <select formControlName="type" class="form-control" (change)="onTypeChange(i)" required [ngClass]="{'is-invalid': form.submitted && formSaisieConditionnement.get('contenants.' + i + '.type').invalid, 'select-placeholder' : (formSaisieConditionnement.get('contenants.' + i + '.type').value === null && !formSaisieConditionnement.get('contenants.' + i + '.type').dirty)}">
                    <option [ngValue]="null" hidden selected>{{ 'page.declarations.synthese.modal.saisirConditionnement.choisirType' | translate }}</option>
                    <option *ngFor="let refContenant of referentiel.refContenantList" [ngValue]="refContenant">{{ refContenant.libelleContenant }}</option>
                  </select>
                </div>

                <div class="form-group col-md-3">
                  <input type="number" lang="en" formControlName="nombre" class="form-control" [readonly]="formSaisieConditionnement.get('contenants.' + i + '.type').value == null" required [ngClass]="{ 'is-invalid': form.submitted && formSaisieConditionnement.get('contenants.' + i + '.nombre').invalid }" />
                </div>

                <div class="form-group col-md-3">
                  <input type="number" lang="en" formControlName="volume" class="form-control" [readonly]="formSaisieConditionnement.get('contenants.' + i + '.type').value == null" required [ngClass]="{ 'is-invalid': form.submitted && formSaisieConditionnement.get('contenants.' + i + '.volume').invalid }" />
                </div>

                <div class="form-group col-md-1">
                  <fa-icon class="pointer deleteIcon" icon="trash" title="{{ 'page.declarations.synthese.modal.saisirConditionnement.supprimerContenant' | translate }}" (click)="remove('contenants', i)"></fa-icon>
                </div>
              </div>
            </div>
          </div>

          <button type="button"  class="lienAffichage" (click)="add('contenants')">
            <fa-icon icon="plus"></fa-icon> {{ 'page.declarations.synthese.modal.saisirConditionnement.creerContenant' | translate }}
          </button>
        </div>
      </div>

      <!-- Boutons d'action du composant -->
      <div class="actions-bar">
        <button type="button" (click)="close()" class="btn btn-light" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary" translate>label.valider</button>
      </div>
    </form>
  </div>
</div>
