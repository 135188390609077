import { Injectable } from '@angular/core';

import { JsonTsMapperService } from '@thorolf/json-ts-mapper';
import { FunctionalError } from 'src/app/shared/errors/functional.error';

interface Context { [name: string]: any; }

@Injectable({
  providedIn: 'root'
})
export class JsonMapperService {

  constructor(
    private readonly mapper: JsonTsMapperService
  ) { }

  /**
   * Serialize objct to 'json object'
   * @param  obj object or array to serialize
   * @return    json object
   */
  serialize(obj: any, context?: Context): any {
    try {
      return this.mapper.serialize(obj, context);
    } catch (err) {
      throw new FunctionalError(FunctionalError.ERROR_CODE.JSON_MAPPING_ERROR_SERIALISATION, undefined, undefined, err);
    }
  }

  /**
   * Serialize objct to json string
   * @param  obj object or array to serialize
   * @return    json string
   */
  serializeToString(obj: any, context?: Context): string {
    try {
      return JSON.stringify(this.mapper.serialize(obj, context));
    } catch (err) {
      throw new FunctionalError(FunctionalError.ERROR_CODE.JSON_MAPPING_ERROR_SERIALISATION, undefined, undefined, err);
    }
  }

  /**
   * Deserialize {T}
   * @param obj object or json string to deserialize
   * @param clazz class to map object
   * @return instance of {T}
   */
  deserializeObject<T>(obj: object | string, clazz: new () => T, context?: Context): T {
    if (typeof obj === 'string') {
      obj = JSON.parse(obj);
    }

    try {
      return this.mapper.deserializeObject(obj, clazz, context);
    } catch (err) {
      throw new FunctionalError(FunctionalError.ERROR_CODE.JSON_MAPPING_ERROR_DESERIALISATION, undefined, undefined, err);
    }
  }

  /**
   * Deserialize array of {T}
   * @param obj array or json string to deserialize
   * @param clazz class to map objects
   * @return T[]
   */
  deserializeArray<T>(obj: object[] | string, clazz: new () => T, context?: Context): T[] {
    if (typeof obj === 'string') {
      obj = JSON.parse(obj);
    }

    try {
      return this.mapper.deserializeArray(obj as any[], clazz, context);
    } catch (err) {
      throw new FunctionalError(FunctionalError.ERROR_CODE.JSON_MAPPING_ERROR_DESERIALISATION, undefined, undefined, err);
    }
  }

}
