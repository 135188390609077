import {JsonObject, JsonProperty, Optional} from '@thorolf/json-ts-mapper';

@JsonObject
export class Surgreffage {

  @JsonProperty('variete', Number)
  @Optional
  public variete: number = undefined;

  @JsonProperty('anneeSurgreffage', Number)
  anneeSurgreffage: number;

  @JsonProperty('nbArbre', Number)
  nbArbre: number;

}
