import { Email } from "../interfaces/email.interface";

/**
 * Helper qui permet de gérer les envois d'e-mails
 * @author Guillaume Alabarbe <guillaume.alabarbe.ext@adventiel.fr>
 */
export class EmailsHelper {

  /**
   * Ouvre l'application par défaut pour écrire un e-mail en pré-remplissant les informations passées
   * @param email Les informations à utiliser en pré-remplissage
   */
  public static envoyerEMail(email: Email): void {
    let mailto = 'mailto:';

    mailto += (email.to && email.to.length > 0) ? email.to.join(';') : '';
    mailto += (email.subject) ? `?subject=${email.subject}` : '';
    mailto += (email.body) ? `?body=${email.body}` : '';
    mailto += (email.cc && email.cc.length > 0) ? `?cc=${email.cc.join(';')}` : '';
    mailto += (email.cci && email.cci.length > 0) ? `?bcc=${email.cci.join(';')}` : '';

    window.open(mailto, '_self');
  }

}
