import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class IntervenantEngagement {

  @JsonProperty('id', Number)
  @Optional
  public id: number = undefined;

  @JsonProperty('nom', String)
  @Optional
  public nom: string = undefined;

  @JsonProperty('prenom', String)
  @Optional
  public prenom: string = undefined;



}