<div class="anpp">
  <div class="content">
    <div class="content-panel border-radius">
      <div class="border-radius text-center" style="background:#fff">
        <div class="row">
          <div class="col-lg text-left pl-4 pt-4">
            <a href="#" class="pl-4 link-secondary" [routerLink]="['./new']">
              <span style="text-decoration:underline">
                <fa-icon icon="plus-square"></fa-icon> Ajouter une entreprise
              </span></a>
            <a>
              <span id="bar1" class="pl-4" (click)="afficheFiltre()" style="text-decoration:underline">
                <fa-icon icon="filter"></fa-icon> Filtrer
              </span></a>
          </div>
          <div class="col-lg text-left pl-4 pt-4">
            <select class="custom-select bar1" (change)="changeEstEntrepriseActiveFilter()" [(ngModel)]="estEntrepriseActiveFilter">
              <option [ngValue]="true" selected>Entreprises actives</option>
              <option [ngValue]="false" >Entreprises inactives</option>
            </select> 
          </div>

          <div class="col-lg text-right pt-2 pr-4">
            <fa-icon icon="upload" class="clickable-icon" style="font-size:25px" (click)="exporterEntreprises()">
            </fa-icon>

          </div>
        </div>
        <div class="table-responsive">
        <div class="tableScroll">
        <table class="table table-striped" *ngIf="entreprises?.length > 0">
          <thead class="sticky-top">
            <tr>
              <th scope="col">Opérations</th>
              <th *ngIf="show('raisonsociale');" scope="col" style="position:relative">
                <div translate>page.entreprises.table.raisonsociale </div>
                <div *ngIf="!filtrer;">
                  <input type="text" [id]="raisonsociale" class="form-control bar1" [(ngModel)]="raisonsocialeFilter"
                    (change)="filter()">
                </div>
              </th>
              <th *ngIf="show('commune');" scope="col" style="position:relative">
                <div translate>page.entreprises.table.commune </div>
                <div *ngIf="!filtrer;">
                  <input type="text" [id]="nom" class="form-control bar1" [(ngModel)]="communeFilter"
                    (change)="filter()">
                </div>
              </th>
              <th *ngIf="show('refsoustype');" scope="col" style="position:relative">
                <div translate>page.entreprises.table.refsoustype</div>
                <div *ngIf="!filtrer;">
                  <select id="refsoustype" class="custom-select bar1" [(ngModel)]="refsoustypeFilter"
                    (change)="filter()">
                    <option></option>
                    <option *ngFor="let refsoustype of refSousTypes" [ngValue]="refsoustype">{{refsoustype.libelle}}
                    </option>
                  </select>
                </div>
              </th>
              <th *ngIf="show('entrepriseliee');" scope="col" style="position:relative">
                <div translate>page.entreprises.table.entrepriseliee</div>
                <div *ngIf="!filtrer;">                  
                  <input type="text" [id]="entrepriseliee" class="form-control bar1" [(ngModel)]="entrepriselieeFilter"
                  (change)="filter()">
                </div>
              </th>
              <th *ngIf="show('estadherent');" scope="col" style="position:relative">
                <div translate>page.entreprises.table.estadherent</div>
                <div *ngIf="!filtrer;">
                  <select [id]="estadherent" class="custom-select bar1" [(ngModel)]="estadherentFilter"
                    (change)="filter()">
                    <option [ngValue]="undefined"></option>
                    <option [ngValue]="true">Oui</option>
                    <option [ngValue]="false">Non</option>
                  </select>
                </div>
              </th>
              <th *ngIf="show('ecoresponsable');" scope="col" style="position:relative">
                <div translate>page.entreprises.table.ecoresponsable</div>
                <div *ngIf="!filtrer;">
                  <select id="ecoresponsable" class="custom-select bar1" [(ngModel)]="ecoresponsableFilter"
                    (change)="filter()">
                    <option [ngValue]="undefined"></option>
                    <option [ngValue]="true">Oui</option>
                    <option [ngValue]="false">Non</option>
                  </select>
                </div>
              </th>
              <th *ngIf="show('culturebio');" scope="col" style="position:relative">
                <div translate>page.entreprises.table.culturebio</div>
                <div *ngIf="!filtrer;">
                  <select id="culturebio" class="custom-select bar1" [(ngModel)]="culturebioFilter" (change)="filter()">
                    <option [ngValue]="undefined"></option>
                    <option [ngValue]="true">Oui</option>
                    <option [ngValue]="false">Non</option>
                  </select>
                </div>
              </th>
              <th *ngIf="show('telephone');" scope="col" style="position:relative">
                <div translate>page.entreprises.table.telephone</div>
                <div *ngIf="!filtrer;">
                  <input type="text" [id]="telephone" class="form-control bar1" [(ngModel)]="telephoneFilter"
                    (change)="filter()">
                </div>
              </th>
              <th *ngIf="show('mail');" scope="col" style="position:relative">
                <div translate>page.entreprises.table.mail</div>
                <div *ngIf="!filtrer;">
                  <input type="text" [id]="mail" class="form-control bar1" [(ngModel)]="mailFilter" (change)="filter()">
                </div>
              </th>
              <th *ngIf="show('terroir');" scope="col" style="position:relative">
                <div translate>page.entreprises.table.terroir</div>
                <div *ngIf="!filtrer;">
                  <select id="terroir" class="custom-select bar1" [(ngModel)]="terroirFilter" (change)="filter()">
                    <option></option>
                    <option *ngFor="let terroir of terroirs" [ngValue]="terroir">{{terroir.libelle}}
                  </select>
                </div>
              </th>
              <th *ngIf="show('departement');" scope="col" style="position:relative">
                <div translate>page.entreprises.table.departement</div>
                <div *ngIf="!filtrer;">
                  <input type="text" [id]="departement" class="col" class="form-control bar1"
                    [(ngModel)]="departementFilter" (change)="filter()">
                </div>
              </th>
              <th *ngIf="show('especesproduites');" scope="col" style="position:relative">
                <div translate>page.entreprises.table.especesproduites</div>
                <div *ngIf="!filtrer;">
                  <input type="text" [id]="especesproduites" class="col" class="form-control bar1"
                    [(ngModel)]="especesproduitesFilter" (change)="filter()">
                </div>
              </th>
              <th *ngIf="show('anneedemission');" scope="col" style="position:relative">
                <div translate>page.entreprises.table.anneedemission</div>
                <div *ngIf="!filtrer;">
                  <input type="text" [id]="anneedemission" class="form-control bar1" [(ngModel)]="anneedemissionFilter"
                    (change)="filter()">
                </div>
              </th>
              <th>
                <fa-icon class="clickable-icon" icon="ellipsis-h" (click)="afficherListe()"></fa-icon>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let entreprise of entreprisesFilter | orderBy:'raisonSociale' ">
              <td>
                <div class="btn-group dropright  ">
                  <div ngbDropdown placement="right-start">
                    <button class="btn btn-secondary dropdown-toggle btnprimary btn-sm " id="dropdownConfig"
                      ngbDropdownToggle>
                      <fa-icon icon="cog"></fa-icon>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                      <button (click)="editEntreprise(entreprise)" ngbDropdownItem class="dropdown-item">
                        <fa-icon icon="id-card"></fa-icon>
                        Fiche de l'entreprise
                      </button>
                      <button *ngIf="entreprise.refSousType.code === 'PO_DEFAUT' && estEntrepriseActiveFilter" (click)="demissionPO(entreprise)"
                        ngbDropdownItem class="dropdown-item">
                        <fa-icon icon="user-slash"></fa-icon>
                        Démission du producteur
                      </button>
                      <button *ngIf="estEntrepriseActiveFilter" (click)="openConfirmationDialog(entreprise)" ngbDropdownItem class="dropdown-item">
                        <fa-icon icon="trash"></fa-icon>
                        Désactiver l'entreprise
                      </button>
                      <button *ngIf="!estEntrepriseActiveFilter" (click)="openConfirmationReactivationDialog(entreprise)" ngbDropdownItem class="dropdown-item">
                        <fa-icon icon="plus-square"></fa-icon>
                        Réactiver l'entreprise
                      </button>
                    </div>
                  </div>
                </div>
              </td>
              <td *ngIf="show('raisonsociale');">{{ entreprise.raisonSociale }}</td>
              <td *ngIf="show('commune');">{{ entreprise.commune }}</td>
              <td *ngIf="show('refsoustype');">{{ entreprise.refSousType?.libelle }}</td>
              <td *ngIf="show('entrepriseliee');">{{ entreprise.entrepriseLiee?.raisonSociale }}</td>
              <td *ngIf="show('estadherent');"> <input type="checkbox" [checked]="entreprise.estAdherent"
                  [disabled]="true" /></td>
              <td *ngIf="show('ecoresponsable');"><input type="checkbox" [checked]="entreprise.estEngageeVer" [disabled]="true" /></td>
              <td *ngIf="show('culturebio');"><input type="checkbox" [checked]="entreprise.cultureBio"
                  [disabled]="true" /></td>
              <td *ngIf="show('telephone');">{{ entreprise.telephone }}</td>
              <td *ngIf="show('mail');">{{ entreprise.mail }}</td>
              <td *ngIf="show('terroir');">{{ getTerroir(entreprise.departement) }}</td>
              <td *ngIf="show('departement');">{{ entreprise.departement }}</td>
              <td *ngIf="show('especesproduites');">
                <span *ngFor="let espece of getEspeces(entreprise.id)">{{espece}} </span>
              </td>
              <td *ngIf="show('anneedemission');">{{ getAnnee(entreprise.dateDemission) }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
      </div>
    </div>
  </div>

</div>