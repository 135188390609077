import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { RefObjetFacture } from './ref-objet-facture.model';

@JsonObject
export class RefQuantiteFacture {
    @JsonProperty('id', Number)
    id: number = undefined;

    @JsonProperty('code', String)
    code: string = undefined;

    @JsonProperty('libelle', String)
    libelle: string = undefined;

    @JsonProperty('refObjetFacture', RefObjetFacture)
    @Optional
    // @CustomConverter(DataIdConverterFactory.build(RefObjetFacture, 'refObjetFactureList'))
    refObjetFacture: RefObjetFacture;
}
