import { Component, OnInit } from '@angular/core';
import { StockCode } from 'src/app/data/declaration/models/enums/stock.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-choix-stock',
  templateUrl: './choix-stock.component.html',
  styleUrls: ['./choix-stock.component.scss']
})
export class ChoixStockComponent implements OnInit {

  stocks: StockCode[] = [StockCode.ISSU_DR, StockCode.REVENDIQUE];

  constructor(
    private readonly modal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  select(stock: StockCode) {
    this.modal.close(stock);
  }

  dismiss() {
    this.modal.dismiss();
  }

}
