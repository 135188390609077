import { JsonProperty, JsonObject, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { Pays } from '../../intervenant/models/pays.model';

@JsonObject
export class Laboratoire {
  @JsonProperty('id', Number)
  id: number;

  @JsonProperty('libelle', String)
  libelle: string;
}
