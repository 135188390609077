import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router, Route } from '@angular/router';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { PermissionsGuard } from './core/guards/permissions/permissions.guard';

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(router: Router) {
    this.addGuards(router.config);

  }

  addGuards(routesList: Route[], prefix: string = '') {
    routesList
      .filter(route => !route.data || !route.data.skipGuard)
      .forEach(route => {
        const path = prefix + (route.path ? '/' + route.path : '');
        this.addGuard(route);
        if (route.children) {
          this.addGuards(route.children, path);
        }
      });
  }

  private addGuard(route: Route): void {
    route.canActivate = route.canActivate ? route.canActivate : [AuthGuard, PermissionsGuard];
    route.canActivateChild = route.canActivateChild ? route.canActivateChild : [AuthGuard, PermissionsGuard];
  }
}
