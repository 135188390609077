import { JsonObject, JsonProperty } from "@thorolf/json-ts-mapper";

@JsonObject
export class GroupeVarietalVariete {

    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('idVariete', Number)
    idVariete: number;

    @JsonProperty('libelle', String)
    libelle: string;

    @JsonProperty('idGroupeVarietal', Number)
    idGroupeVarietal: number;    

}