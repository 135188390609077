<div class="content">
  <div class="content-panel">
    <h2 translate>page.declarations.suivi-parcellaires-viticoles.title</h2>
    <form [formGroup]="searchForm" #form="ngForm" *ngIf="searchForm" (ngSubmit)="search()" novalidate >
      <div class="cols">
        <div class="col">
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label translate>page.declarations.suivi-parcellaires-viticoles.list.annee</label>
            <select formControlName="suivi"  (change)="changeType()" class="form-control" [ngClass]="{'is-invalid': form.submitted && searchForm.get('suivi').invalid}">              
              <option Value="false">Parcellaire courant</option>
              <option Value="true">Parcellaire affecté</option>
            </select>
          </div>
        </div>
        <div class="col-md-2" *ngIf="estAffecte">
          <div class="form-group">
            <label translate>page.declarations.suivi-parcellaires-viticoles.list.annee</label>
            <input type="number" lang="en" formControlName="annee" class="form-control" [ngClass]="{'is-invalid': form.submitted && searchForm.get('annee').invalid}" />
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label translate>page.declarations.suivi-parcellaires-viticoles.list.raisonSociale</label>
            <input type="text" lang="en" formControlName="raisonSociale" class="form-control" />
          </div>
        </div>     
        
     
       
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label class="form-check-label" for="cdcs" translate>page.edition.generation-documents.exporter-declarations.form.cdcs</label>
            <app-multiselect id="cdcs"
                             controlName="cdcs"
                             [items]="refCahiers"
                             bindLabel="libelle"
                             maxWidth="initial"
                             maxSelectedItems="4"
            >
            </app-multiselect>
          </div>
        </div>
      </div>
      
      <div class="row">
  
        <div class="form-group col-md-4 row">
          <label for="debutDate" class="col-md-3 col-form-label"
            translate>page.declarations.suivi-parcellaires-viticoles.list.dateEntre</label>
          <div class="col-md-5 input-group">
            <input id="debutDate" ngbDatepicker #debutDatepicker="ngbDatepicker"
              formControlName="debutDate" class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="debutDatepicker.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group col-md-4 row">
          <label for="finDate" class="col-md-2 col-form-label"
            translate>page.declarations.suivi-parcellaires-viticoles.list.et</label>
          <div class="col-md-5 input-group">
            <input id="finDate" ngbDatepicker #finDatepicker="ngbDatepicker"
              formControlName="finDate" class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="finDatepicker.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>
    <div class="col-md-1 ">
        <button type="submit" class="btn btn-primary ps-1" translate>label.filtrer</button>
    </div>
    <div class="col-md-3 ">
      <button (click)="exporterParcellaire()" class="btn btn-primary" translate>label.exporter</button>
  </div>
  </div>
  </div>

</div>
 </form>

 <table table class="table table-striped" *ngIf="historiques?.length > 0; else aucunHistorique">
  <thead>
    <tr>
      <th class="liste-col-header"  translate>page.declarations.suivi-parcellaires-viticoles.table.header.raisonSociale
      </th>
      <th class="liste-col-header"  translate>page.declarations.suivi-parcellaires-viticoles.table.header.commune</th>
      <th class="liste-col-header"  translate>page.declarations.suivi-parcellaires-viticoles.table.header.referenceCadastrale</th>
      <th class="liste-col-header"  translate>page.declarations.suivi-parcellaires-viticoles.table.header.produit</th>
      <th class="liste-col-header"  translate>page.declarations.suivi-parcellaires-viticoles.table.header.anneePlantation</th>
      <th class="liste-col-header"  translate>page.declarations.suivi-parcellaires-viticoles.table.header.variete</th>
      <th class="liste-col-header"  translate>page.declarations.suivi-parcellaires-viticoles.table.header.surfacePlantee</th>
      <th class="liste-col-header"  translate>page.declarations.suivi-parcellaires-viticoles.table.header.surfaceEngagee</th>
      <th></th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let historique of historiques">
      <td>{{ historique.entreprise?.raisonSociale }}</td>
      <td>{{ historique.commune }}</td>
      <td>{{ historique.referenceCadastrale }}</td>
      <td>{{ historique.libelleProduit }}</td>
      <td>{{ historique.anneePlantation }}</td>
      <td>{{ historique.variete }}</td>
      <td>{{ historique.surfacePlantee }}</td>
      <td>{{ historique.surfaceEngagee }}</td>
      <td style="text-align:center">
        <div >
            <h4  (click)="onEdit(historique)">
              <fa-icon icon="info-circle"></fa-icon>                 
            </h4>
          </div>
      </td>
    </tr>
  </tbody>
</table>
</div>
</div>


<!-- Template aucun historique -->
<ng-template #aucunHistorique>
  <div class="alert alert-warning alert-block" translate>
    <fa-icon icon="exclamation"></fa-icon> page.declarations.suivi-parcellaires-viticoles.aucunHistorique
  </div>
</ng-template>
<!-- Fin template aucun historique -->
