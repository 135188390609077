<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
      page.declarations.synthese.modal.recherche-acheteur.title
    </h4>
  </div>

  <div class="modal-body">
    <div *ngIf="step === CHOIX_TYPE_ACHETEUR">
      <h3 translate>page.declarations.synthese.modal.recherche-acheteur.select-destination</h3>
      <div class="liste-choix">
        <button type="button" (click)="changeStep(SEARCH_FRANCE)" class="btn btn-primary stock-button" translate>page.declarations.synthese.modal.recherche-acheteur.destination-france</button>
        <button type="button" (click)="changeStep(SEARCH_EXPORT)" class="btn btn-primary stock-button" translate>page.declarations.synthese.modal.recherche-acheteur.destination-export</button>
      </div>

      <div class="actions-bar">
        <button class="btn btn-light" (click)="dismiss()" translate>label.annuler</button>
      </div>
    </div>

    <div *ngIf="step === SEARCH_FRANCE">
      <h3 translate>
        <fa-icon icon="arrow-left" class="pointer" (click)="changeStep(CHOIX_TYPE_ACHETEUR)"></fa-icon>page.declarations.synthese.modal.recherche-acheteur.recherche-acheteur-france
      </h3>

      <div *ngIf="acheteursFrance && acheteursFrance.length; else aucunAcheteur">
        <div class="searchBar">
          <label for="searchBar-input" translate>label.filtrer</label>
          <input type="text" id="searchBar-input2" (ngModelChange)="searchStringUpdate.next($event)" [(ngModel)]="searchStringInput" placeholder="{{'label.filtre'|translate}}">
        </div>

        <table class="table">
          <thead>
            <tr>
              <th translate>page.declarations.synthese.modal.recherche-acheteur.table.raisonSociale</th>
              <th translate>page.declarations.synthese.modal.recherche-acheteur.table.adresse</th>
              <th translate>page.declarations.synthese.modal.recherche-acheteur.table.codePostal</th>
              <th translate>page.declarations.synthese.modal.recherche-acheteur.table.ville</th>
              <th translate>page.declarations.synthese.modal.recherche-acheteur.table.pays</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let acheteur of acheteursFrance | filter : '*': searchString">
              <td>{{acheteur?.raisonSociale}}</td>
              <td>{{acheteur?.adresse}}</td>
              <td>{{acheteur?.codePostal}}</td>
              <td>{{acheteur?.ville}}</td>
              <td>{{acheteur?.pays?.libelle}}</td>
              <td class="align-right"><button class="btn btn-primary close-btn" (click)="selectAcheteurFrance(acheteur)"><span translate>label.selectionner</span></button></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="actions-bar flow flow-row" style="justify-content:space-between;">
        <button class="btn btn-primary" (click)="changeStep(ADD_FRANCE)" translate>
          <fa-icon icon="plus"></fa-icon>page.declarations.synthese.modal.recherche-acheteur.ajouter-acheteur
        </button>
        <button class="btn btn-light" (click)="dismiss()" translate>label.annuler</button>
      </div>
    </div>

    <div *ngIf="step === ADD_FRANCE">
      <h3 translate>
        <fa-icon icon="arrow-left" class="pointer" (click)="changeStep(SEARCH_FRANCE)"></fa-icon>page.declarations.synthese.modal.recherche-acheteur.ajouter-acheteur-france
      </h3>

      <form [formGroup]="formOperateur" #form="ngForm" (ngSubmit)="searchAchteurFrance()" novalidate>
        <div class="form-group">
          <label for="lot" translate>page.declarations.synthese.modal.recherche-acheteur.siret</label>
          <div class="flex flex-row siret-search">
            <input type="text" formControlName="siret" class="form-control" [ngClass]="{'is-invalid': form.submitted && formOperateur.get('siret').invalid}" />
            <button class="btn btn-primary" type="submit" translate>label.rechercher</button>
          </div>
        </div>
      </form>

      <table class="table" *ngIf="acheteursFrance && acheteursFrance.length; else aucunAcheteur">
        <thead>
          <tr>
            <th translate>page.declarations.synthese.modal.recherche-acheteur.table.raisonSociale</th>
            <th translate>page.declarations.synthese.modal.recherche-acheteur.table.adresse</th>
            <th translate>page.declarations.synthese.modal.recherche-acheteur.table.codePostal</th>
            <th translate>page.declarations.synthese.modal.recherche-acheteur.table.ville</th>
            <th translate>page.declarations.synthese.modal.recherche-acheteur.table.pays</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let acheteur of acheteursFrance">
            <td>{{acheteur?.raisonSociale}}</td>
            <td>{{acheteur?.adresse}}</td>
            <td>{{acheteur?.codePostal}}</td>
            <td>{{acheteur?.ville}}</td>
            <td>{{acheteur?.pays?.libelle}}</td>
            <td class="align-right"><button class="btn btn-primary close-btn" (click)="selectAcheteurFrance(acheteur)"><span translate>label.selectionner</span></button></td>
          </tr>
        </tbody>
      </table>

      <div class="actions-bar">
        <button class="btn btn-light" (click)="dismiss()" translate>label.annuler</button>
      </div>
    </div>

    <div *ngIf="step === SEARCH_EXPORT">
      <h3 translate>
        <fa-icon icon="arrow-left" class="pointer" (click)="changeStep(CHOIX_TYPE_ACHETEUR)"></fa-icon>page.declarations.synthese.modal.recherche-acheteur.recherche-acheteur-export
      </h3>

      <div *ngIf="acheteursExport && acheteursExport.length; else aucunAcheteur">
        <div class="searchBar">
          <label for="searchBar-input" translate>label.filtrer</label>
          <input type="text" id="searchBar-input" (ngModelChange)="searchStringUpdate.next($event)" [(ngModel)]="searchStringInput" placeholder="{{'label.filtre'|translate}}">
        </div>

        <table class="table">
          <thead>
            <tr>
              <th translate>page.declarations.synthese.modal.recherche-acheteur.table.raisonSociale</th>
              <th translate>page.declarations.synthese.modal.recherche-acheteur.table.adresse</th>
              <th translate>page.declarations.synthese.modal.recherche-acheteur.table.codePostal</th>
              <th translate>page.declarations.synthese.modal.recherche-acheteur.table.ville</th>
              <th translate>page.declarations.synthese.modal.recherche-acheteur.table.pays</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let acheteur of acheteursExport | filter : '*': searchString">
              <td>{{acheteur?.raisonSociale}}</td>
              <td>{{acheteur?.adresse}}</td>
              <td>{{acheteur?.codePostal}}</td>
              <td>{{acheteur?.ville}}</td>
              <td>{{acheteur?.pays?.libelle}}</td>
              <td class="align-right"><button class="btn btn-primary close-btn" (click)="selectAcheteurExport(acheteur)"><span translate>label.selectionner</span></button></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="actions-bar flow flow-row" style="justify-content:space-between;">
        <button class="btn btn-primary" (click)="changeStep(CREATE_EXPORT)" translate>
          <fa-icon icon="plus"></fa-icon>page.declarations.synthese.modal.recherche-acheteur.creer-acheteur
        </button>
        <button class="btn btn-light" (click)="dismiss()" translate>label.annuler</button>
      </div>
    </div>

    <div *ngIf="step === CREATE_EXPORT">
      <h3 translate>
        <fa-icon icon="arrow-left" class="pointer" (click)="changeStep(SEARCH_EXPORT)"></fa-icon>page.declarations.synthese.modal.recherche-acheteur.creer-acheteur-export
      </h3>

      <form [formGroup]="formAcheteur" #form="ngForm" (ngSubmit)="createAcheteurExport()" novalidate>
        <div class="form-group">
          <label for="raisonSociale" translate>page.declarations.synthese.modal.recherche-acheteur.raisonSociale</label>
          <input type="text" formControlName="raisonSociale" class="form-control" [ngClass]="{'is-invalid': form.submitted && formAcheteur.get('raisonSociale').invalid}" />
        </div>

        <div class="form-group">
          <label for="adresse" translate>page.declarations.synthese.modal.recherche-acheteur.adresse</label>
          <input type="text" formControlName="adresse" class="form-control" [ngClass]="{'is-invalid': form.submitted && formAcheteur.get('adresse').invalid}" />
        </div>

        <div class="form-group">
          <label for="codePostal" translate>page.declarations.synthese.modal.recherche-acheteur.codePostal</label>
          <input type="text" formControlName="codePostal" class="form-control" [ngClass]="{'is-invalid': form.submitted && formAcheteur.get('codePostal').invalid}" />
        </div>

        <div class="form-group">
          <label for="ville" translate>page.declarations.synthese.modal.recherche-acheteur.ville</label>
          <input type="text" formControlName="ville" class="form-control" [ngClass]="{'is-invalid': form.submitted && formAcheteur.get('ville').invalid}" />
        </div>

        <div class="form-group">
          <label for="ville" translate>page.declarations.synthese.modal.recherche-acheteur.pays</label>
          <select formControlName="pays" class="form-control" required
            [ngClass]="{'is-invalid': form.submitted && formAcheteur.get('pays').invalid, 'select-placeholder' : (formAcheteur.get('pays').value === null && !formAcheteur.get('pays').dirty)}">
            <option [ngValue]="null" hidden selected translate>page.declarations.synthese.modal.recherche-acheteur.pays</option>
            <option *ngFor="let _pays of pays" [ngValue]="_pays">{{ _pays.libelle }}</option>
          </select>
        </div>

        <div class="actions-bar">
          <button class="btn btn-light" (click)="dismiss()" translate>label.annuler</button>
          <button class="btn btn-primary" translate>label.valider</button>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #aucunAcheteur>
  <div translate>
    page.declarations.synthese.modal.recherche-acheteur.aucun-acheteur
  </div>
</ng-template>
