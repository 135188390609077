import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/internal/Observable';


@Component({
  selector: 'app-prevision-definitive',
  templateUrl: './prevision-definitive.component.html',
  styleUrls: ['./prevision-definitive.component.scss']
})
export class PrevisionDefinitiveComponent implements OnInit {
  @Input() public recoltes;
  @Input() public campagnePrecedente;
  @Input() public estDefinitive;
  @Input() public alerteBio;
  @Input() public alerteSaisie;

  @ViewChild('form') form: NgForm;
  formGroup: FormGroup;

  hasErrors = false;

  getData(): Observable<Document[]> {
    throw new Error('Method not implemented.');
  }

  constructor(
    private readonly loader: AdvBootstrapLoaderService,
    public readonly modal: NgbActiveModal,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly fb: FormBuilder,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
  ) {
    //super(modal);
  }


  ngOnInit() {
    this.formGroup = this.fb.group({
      previsionOuDefinitive: ['', Validators.minLength(1)],
    });




  }
  save() {
    let r = this.formGroup.get('previsionOuDefinitive').value;
    this.hasErrors = (!r || r.length === 0) && ! this.estDefinitive;
    if (!this.hasErrors) {
      this.modal.close(this.formGroup.get('previsionOuDefinitive').value || this.estDefinitive);
    }
  }

  cancel() {
    this.modal.close();
  }
}
