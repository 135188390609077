import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap, catchError, merge } from 'rxjs/operators';

import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { Operateur } from 'src/app/data/intervenant/models/operateur.model';
import { Router, ActivatedRoute } from '@angular/router';
import { DomainesService } from 'src/app/data/intervenant/services/domaines/domaines.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-recherche-operateur-affilie',
  templateUrl: './recherche-operateur-affilie.component.html',
  styleUrls: ['./recherche-operateur-affilie.component.scss']
})
export class RechercheOperateurAffilieComponent implements OnInit {
  placeholder: string;
  minLength = 2;

  searching = false;
  noResult = false;
  userSearch: string;

  constructor(
    private readonly domainesService: DomainesService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly operateursService: OperateursService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  focus$ = new Subject<string>();
  @ViewChild('someInput') searchElement: ElementRef;
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus$),
      tap(() => {
        this.searching = true;
        this.noResult = false;
      }),
      switchMap(term => {
        if ((term != null) && (term.length >= this.minLength)) {
          return this.operateursService.getOperateursAffilies(term).pipe(
            tap(operateurs => {
              this.noResult = !operateurs || (operateurs.length === 0);
            }),
            catchError(() => {
              return of([]);
            }));
        } else {
          return of([]);
        }
      }),
      tap(() => this.searching = false),
    )

  formatter = (operateur: Operateur) => `${operateur.prenom} ${operateur.nom}`;

  selectOperateur(event) {
    if (event.item != null) {
      const operateur = event.item as Operateur;
      this.router.navigate([operateur.id], { relativeTo: this.route.parent });
    }
  }

  ngOnInit() { 
    this.route.queryParams.subscribe(params => {
      this.userSearch = params.search;
      setTimeout(()=>{
        this.searchElement.nativeElement.focus();
        },100)
    });
  }
}
