<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>page.declarations.synthese.modal.synthese-saisies-revendications.title</h4>
  </div>

  <div class="modal-body">
    <div *ngIf="numeroDossier; else syntheseLot">
      <span translate>page.declarations.synthese.modal.synthese-saisies-revendications.numeroDossier</span> : {{ numeroDossier }}
    </div>
  </div>

  <!-- Boutons d'action -->
  <div class="actions-bar" *ngIf="numeroDossier">
    <button type="button" class="btn btn-light" (click)="onClose(true)" translate>label.fermer</button>
  </div>
  <div class="actions-bar" *ngIf="!numeroDossier">
    <button type="button" class="btn btn-light" (click)="onClose()" translate>label.annuler</button>
    <button type="button" class="btn btn-primary ml-2" (click)="onAddSaisie()" translate>
      page.declarations.synthese.modal.synthese-saisies-revendications.ajouterLot
    </button>
    <button type="button" class="btn btn-primary ml-2" (click)="onSubmit()" translate>label.valider</button>
  </div>
  <!-- Fin boutons d'action -->
</div>

<ng-template #syntheseLot>
  <!-- Affichage de l'entête du lot -->
  <div class="row mb-3">
    <div class="col-3" translate>page.declarations.synthese.modal.saisir-revendication.igp</div>
    <div class="col-9">
      <input type="text" value="{{ lotRevendications.libelleCahier }}" class="form-control" readonly />
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-3" translate>page.declarations.synthese.modal.saisir-revendication.date</div> 
    <div class="col-9">
      <input type="text" value="{{ lotRevendications.date | date:'dd/MM/yyyy' }}" class="form-control" readonly />
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-3" translate>page.declarations.synthese.modal.saisir-revendication.entreposage</div> 
    <div class="col-9">
      <input type="text" value="{{ lotRevendications.entreposage.adresse }}" class="form-control" readonly />
    </div>
  </div>
  <!-- Fin affichage de l'entête du lot -->

  <!-- Affichage des lots saisis -->
  <div class="tab-container">
    <table class="table">
      <thead>
        <tr>
          <th translate>page.declarations.synthese.modal.synthese-saisies-revendications.table.header.produit</th>
          <th translate>page.declarations.synthese.modal.synthese-saisies-revendications.table.header.numeroLot</th>
          <th translate>page.declarations.synthese.modal.saisir-revendication.degre</th>
          <th translate>page.declarations.synthese.modal.saisir-revendication.cepages</th>
          <th translate>page.declarations.synthese.modal.saisir-revendication.logement</th>
          <th translate>page.declarations.synthese.modal.saisir-revendication.volume</th>
          <th translate>page.declarations.synthese.modal.saisir-revendication.destinations</th>
          <th translate>page.declarations.synthese.modal.saisir-revendication.observation</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let saisie of lotRevendications.revendications; let index = index">
          <td>{{ saisie.libelleProduit }}</td>
          <td>{{ saisie.numeroLot }}</td>
          <td>{{ saisie.degre }}</td>
          <td>{{ getCepages(saisie) }}</td>
          <td>{{ saisie.logement }}</td>
          <td>{{ saisie.volume }}</td>
          <td>{{ getDestinations(saisie) }}</td>
          <td>{{ saisie.observation }}</td>
          <td>
            <fa-icon icon="edit" class="pointer" title="Modifier" (click)="onEdit(index)"></fa-icon>
            <fa-icon icon="trash" class="pointer ml-2" title="Supprimer" (click)="onDelete(index)" 
              [ngClass]="{ 'disabled': lotRevendications.revendications.length < 2 }">
            </fa-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Fin affichage des lots saisis-->
</ng-template>