import { JsonProperty, JsonObject, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { Pays } from '../../intervenant/models/pays.model';

@JsonObject
export class AcheteurExport {
  @JsonProperty('id', Number)
  @Optional
  id: number;

  @JsonProperty('raisonSociale', String)
  raisonSociale: string;

  @JsonProperty('adresse', String)
  @Optional
  adresse: string;

  @JsonProperty('codePostal', String)
  codePostal: string;

  @JsonProperty('ville', String)
  ville: string;

  @JsonProperty('idPays', Number)
  @CustomConverter(DataIdConverterFactory.build(Pays, 'pays'))
  pays: Pays;
}
