<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>page.operateurs.informations.modale.titleUpdate.infosMajeures</h4>
  </div>

  <div class="modal-body">
    <form [formGroup]="formInformationsMajeures" *ngIf="formInformationsMajeures" #form="ngForm" (submit)="onSubmit()">
      <div class="form-group">
        <label for="structureJuridique" class="col-form-label" translate>page.operateurs.informations.entreprise.structureJuridique</label>
        <input id="structureJuridique" formControlName="structureJuridique" class="form-control" type="text">

      </div>
      <div class="form-group">
        <label for="raisonSociale" translate>page.operateurs.informations.entreprise.raisonSociale</label>
        <input id="raisonSociale" formControlName="raisonSociale" class="form-control" type="text">
      </div>

      <div class="form-group">
        <label for="siret" class="col-form-label" translate>page.operateurs.informations.entreprise.siret
          <strong>*</strong></label>
        <input id="siret" formControlName="siret" class="form-control" type="text"
               (blur)="onSiretBlur()"
               [ngClass]="{'is-invalid': siret.invalid && (siret.touched || form.submitted)}">
        <ng-container *ngIf="siret.touched || form.submitted">
          <div *ngIf="siret.errors?.required" class="form-error" translate>page.operateurs.informations.modale.siret</div>
          <div *ngIf="siret.errors?.minlength" class="form-error" translate>page.operateurs.informations.modale.siret</div>
          <div *ngIf="siret.errors?.maxlength" class="form-error" translate>page.operateurs.informations.modale.siret</div>
        </ng-container>
      </div>

      <div class="form-group">
        <label for="tvaIntracommunautaire" translate>page.operateurs.informations.entreprise.tvaIntracommunautaire</label>
        <input id="tvaIntracommunautaire" formControlName="tvaIntracommunautaire" class="form-control" type="text">
      </div>

      <div formGroupName="domaineSpecs">
        <div *ngFor="let spec of domaineSpecs">
          <label class="col-form-label" [for]="spec.code">{{ spec.libelle }}</label>
          <input [id]="spec.code" [formControlName]="spec.code" class="form-control"
                 (blur)="onCviBlur(spec.code)"
                 [ngClass]="{'is-invalid': formInformationsMajeures.get('domaineSpecs').get(spec.code).invalid &&
                   (formInformationsMajeures.get('domaineSpecs').get(spec.code).touched || form.submitted)}">
          <ng-container *ngIf="formInformationsMajeures.get('domaineSpecs').get(spec.code).touched || form.submitted">
            <div *ngIf="formInformationsMajeures.get('domaineSpecs').get(spec.code).errors?.required ||
                        formInformationsMajeures.get('domaineSpecs').get(spec.code).errors?.minlength ||
                        formInformationsMajeures.get('domaineSpecs').get(spec.code).errors?.maxlength" class="form-error"
                 translate>
              page.operateurs.informations.modale.cvi
            </div>
          </ng-container>
        </div>
      </div>


      <div class="actions-bar">
        <button type="button" (click)="onClose()" class="btn btn-light" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary ml-2" translate>label.valider</button>
      </div>

    </form>
  </div>
</div>
