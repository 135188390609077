<div class="anpp content">
  <div class="content-panel" *ngIf="interaction">
    <div class="title-bar">
      <h4>
        {{ title | translate }}
      </h4>
    </div>
    <form *ngIf="formGroup" [formGroup]="formGroup" #form="ngForm" (submit)="submit()" novalidate>
      <div class="m-3">
         <div class="row">
          <div class="form-group  col-lg-3">
            <label for="date_interaction" translate>page.interactions.form.label.date</label>
            <div >
            <div class="input-group">
              <input id="date_interaction" ngbDatepicker #dateInteractionpicker="ngbDatepicker" formControlName="date_interaction"
                (click)="dateInteractionpicker.toggle()" placeholder="jj-mm-aaaa"  class="form-control" [ngClass]="{ 'is-invalid': form.submitted && formGroup.get('date_interaction').invalid   }"
                 />
                <div class="input-group-append">
                  <button type="button" (click)="dateInteractionpicker.toggle()" class="btn btn-outline-secondary">
                    <fa-icon icon="calendar-alt"></fa-icon>
                  </button>
                </div>
              </div>
               <ng-container *ngIf="form.submitted">
              <div *ngIf="date_interaction.errors?.required || date_interaction.invalid" class="form-error" translate>label.obligatoire</div>
            </ng-container>
            </div>
          </div>
            <div class="form-group col-lg-3">
               <ngb-timepicker id="heure_interaction" formControlName="heure_interaction" [ngClass]="{'timepicker-invalid': form.submitted && heure_interaction.invalid}">
              </ngb-timepicker>
              <ng-container *ngIf="form.submitted">
                <div *ngIf="heure_interaction.errors?.required" class="form-error" translate>label.obligatoire</div>
              </ng-container>
            </div>
          </div>
          <div class="form-group">
            <div *ngIf="!(idEntreprise || idContact)" >
            <label for="entrepriseBase" translate>page.interactions.form.label.entreprise</label>           
              <app-search-adherent-actif-bar  (newItemEvent)="selectItem($event)" ></app-search-adherent-actif-bar>
            </div>
              <div>
                <strong>Entreprise : </strong><span *ngIf="entrepriseBase"  class="col-lg-4">{{ entrepriseBase.raisonSociale }}</span>
              </div>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="entrepriseBase.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group">
            <label for="contact" translate>page.interactions.form.label.contact</label>
            <select id="contact" formControlName="contact" class="form-control"  [ngClass]="{'is-invalid': form.submitted && contact.invalid}">
              <option *ngFor="let contact of contacts" [ngValue]="contact">{{contact.nom}} {{contact.prenom}}
              </option>
            </select>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="contact.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group">
            <label for="contact_anpp" translate>page.interactions.form.label.contact_anpp</label>
            <select id="contact_anpp" formControlName="contact_anpp" class="form-control"  [ngClass]="{'is-invalid': form.submitted && contact_anpp.invalid}">
              <option *ngFor="let contact_anpp of contacts_anpp" [ngValue]="contact_anpp">{{contact_anpp.nom}} {{contact_anpp.prenom}}
              </option>
            </select>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="contact_anpp.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group">
            <label for="type_interaction" translate>page.interactions.form.label.type_interaction</label>
            <select id="type_interaction" formControlName="type_interaction" class="form-control" [ngClass]="{'is-invalid': form.submitted && type_interaction.invalid}" >
              <option *ngFor="let type_interaction of refTypeInteractions" [ngValue]="type_interaction">{{type_interaction.libelle}}              </option>
            </select>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="contact_anpp.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group">
            <label for="type" translate>page.interactions.form.label.sujet_interaction</label>
            <input type="text" id="sujet" formControlName="sujet" [ngClass]="{'is-invalid': form.submitted && sujet.invalid}"
              class="form-control" />
            <ng-container *ngIf="form.submitted">
              <div *ngIf="sujet.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group">
            <label for="compte_rendu" translate>page.interactions.form.label.compte_rendu</label>
            <textarea type="text" rows="3" id="compte_rendu" formControlName="compte_rendu"
              [ngClass]="{'is-invalid': form.submitted && compte_rendu.invalid}" class="form-control" ></textarea>           
          </div>        
      </div>
      <div class="actions-bar" fxLayoutAlign="space-between">
        <button type="button" class="btn btn-primary btnprimary" (click)="onCancel()" translate>label.retour</button>
        <button type="submit" class="btn btn-primary btnprimary" translate>{{!creation? 'label.suivant': 'label.valider'}}</button>
      </div>
    </form>
  </div>
</div>
