<div class="anpp">
  <div class="row tableau">
    <div class="col-lg-12">
      <br /><br />
      <h4 translate>
        <b translate>page.declarations.import-inventaire-vergers.titre</b>
      </h4>
      <form
        name="formGroup"
        class="width70"
        role="form"
        novalidate
        (ngSubmit)="uploadInventaire()"
        [formGroup]="formGroup"
      >
        <div class="form-group">
          <h5 translate>
            page.declarations.import-inventaire-vergers.groupements
          </h5>
          <select
            formControlName="selectionGroupement"
            class="form-control col-3"
            (change)="onValueGroupementChange()"
          >
            <option [value]="null" hidden></option>
            <option
              *ngFor="let grp of entreprises"
              [ngValue]="grp"
              [selected]="grp.id === groupement?.id"
            >
              {{ grp.raisonSociale }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <h5 translate>page.declarations.import-inventaire-vergers.import</h5>
          <div class="file buttons center">
            <input
              formControlName="myInputFile"
              id="myInputFile"
              class=""
              type="file"
              #myInputFile
              (change)="handleFileInput($event.target.files)"
              name="lv"
            />
            <fa-icon
              icon="trash"
              *ngIf="fileToUpload"
              (click)="reset()"
            ></fa-icon>
          </div>
        </div>

        <div class="buttons center">
          <button
            type="submit"
            class="btn btn-primary"
            color="accent"
            id="save-entity"
            translate
            [disabled]="!fileToUpload || isSaving"
          >
            label.valider
          </button>
        </div>
      </form>

      <br /><br />
      <div *ngIf="retourImport">
        <h5 translate>
          page.declarations.import-inventaire-vergers.titre-retour
        </h5>
        <div *ngIf="retourImport.importOk">
          <fa-icon icon="check-circle"></fa-icon>&nbsp;Succès
        </div>
        <div *ngIf="!retourImport.importOk">
          <fa-icon icon="exclamation"></fa-icon>&nbsp;Echec de l'import
        </div>
        <div class="card">
          <div>
            <label translate
              >page.declarations.import-inventaire-vergers.nombre-producteurs-import</label
            >
            :&nbsp;{{ retourImport.nbProducteurs }}
          </div>
          <div>
            <label translate
              >page.declarations.import-inventaire-vergers.nombre-producteurs-erreur</label
            >
            :&nbsp;{{ getNbProducteursEnErreur() }}
          </div>
          <div>
            <label translate
              >page.declarations.import-inventaire-vergers.surface</label
            >
            :&nbsp;{{ retourImport.surfaceTotale | number : "1.3-3" }} ha
          </div>
        </div>
        <br />
        <h5 translate>
          page.declarations.import-inventaire-vergers.resultat-producteurs-inconnus
        </h5>
        <table *ngIf="retourImport" class="table table-striped mt-2">
          <thead class="center">
            <tr>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.raison-sociale</span
                >
              </th>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.nom</span
                >
              </th>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.commune</span
                >
              </th>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.departement</span
                >
              </th>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.especes</span
                >
              </th>
              <th scope="col" colspan="100"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngIf="
                retourImport.producteursEnErreurInconnus &&
                retourImport.producteursEnErreurInconnus.length === 0
              "
            >
              <td colspan="99" class="p-4">
                <fa-icon icon="exclamation"></fa-icon>&nbsp;
                <span translate
                  >page.declarations.import-inventaire-vergers.table.no-error</span
                >
              </td>
            </tr>
            <tr
              *ngFor="
                let producteur of retourImport.producteursEnErreurInconnus;
                let i = index
              "
            >
              <td scope="col" class="text-left">
                {{ producteur.raisonSociale }}
              </td>
              <td scope="col" class="text-left">{{ producteur.nom }}</td>
              <td scope="col" class="text-left">{{ producteur.commune }}</td>
              <td scope="col" class="text-left">
                {{ producteur.departement }}
              </td>
              <td
                *ngFor="let col of producteur.especes"
                scope="col"
                class="text-left"
              >
                {{ col[0] }}<br />{{ col[1] }} ha
              </td>
              <td colspan="99"></td>
            </tr>
          </tbody>
        </table>

        <br />
        <h5 translate>
          page.declarations.import-inventaire-vergers.resultat-producteurs-inconnus-groupement
        </h5>
        <table *ngIf="retourImport" class="table table-striped mt-2">
          <thead class="center">
            <tr>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.raison-sociale</span
                >
              </th>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.nom</span
                >
              </th>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.commune</span
                >
              </th>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.departement</span
                >
              </th>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.especes</span
                >
              </th>
              <th scope="col" colspan="100"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngIf="
                retourImport.producteursEnErreurAbsentsGP &&
                retourImport.producteursEnErreurAbsentsGP.length === 0
              "
            >
              <td colspan="99" class="p-4">
                <fa-icon icon="exclamation"></fa-icon>&nbsp;
                <span translate
                  >page.declarations.import-inventaire-vergers.table.no-error</span
                >
              </td>
            </tr>
            <tr
              *ngFor="
                let producteur of retourImport.producteursEnErreurAbsentsGP;
                let i = index
              "
            >
              <td scope="col" class="text-left">
                {{ producteur.raisonSociale }}
              </td>
              <td scope="col" class="text-left">{{ producteur.nom }}</td>
              <td scope="col" class="text-left">{{ producteur.commune }}</td>
              <td scope="col" class="text-left">
                {{ producteur.departement }}
              </td>
              <td
                *ngFor="let col of producteur.especes"
                scope="col"
                class="text-left"
              >
                {{ col[0] }}<br />{{ col[1] }} ha
              </td>
              <td colspan="99"></td>
            </tr>
          </tbody>
        </table>

        <br />
        <h5 translate>
          page.declarations.import-inventaire-vergers.resultat-producteurs-importes
        </h5>
        <table *ngIf="retourImport" class="table table-striped mt-2">
          <thead class="center">
            <tr>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.raison-sociale</span
                >
              </th>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.nom</span
                >
              </th>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.commune</span
                >
              </th>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.departement</span
                >
              </th>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.especes</span
                >
              </th>
              <th scope="col" colspan="100"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngIf="
                retourImport.producteursDejaExistants &&
                retourImport.producteursDejaExistants.length === 0
              "
            >
              <td colspan="99" class="p-4">
                <fa-icon icon="exclamation"></fa-icon>&nbsp;
                <span translate
                  >page.declarations.import-inventaire-vergers.table.no-data</span
                >
              </td>
            </tr>
            <tr
              *ngFor="
                let producteur of retourImport.producteursDejaExistants;
                let i = index
              "
            >
              <td scope="col" class="text-left">
                {{ producteur.raisonSociale }}
              </td>
              <td scope="col" class="text-left">{{ producteur.nom }}</td>
              <td scope="col" class="text-left">{{ producteur.commune }}</td>
              <td scope="col" class="text-left">
                {{ producteur.departement }}
              </td>
              <td
                *ngFor="let col of producteur.especes"
                scope="col"
                class="text-left"
              >
                {{ col[0] }}<br />{{ col[1] }} ha
              </td>
              <td colspan="99"></td>
            </tr>
          </tbody>
        </table>

        <br />
        <h5 translate>
          page.declarations.import-inventaire-vergers.resultat-producteurs-absents
        </h5>
        <table
          *ngIf="retourImport"
          class="table table-striped mt-2 border-radius"
        >
          <thead class="center">
            <tr>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.raison-sociale</span
                >
              </th>
              <!--<th scope="col"><span translate>page.declarations.import-inventaire-vergers.table.header.nom</span></th>
              <th scope="col"><span translate>page.declarations.import-inventaire-vergers.table.header.commune</span> </th>-->
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.departement</span
                >
              </th>
              <!--<th scope="col"><span translate>page.declarations.import-inventaire-vergers.table.header.especes</span></th>-->
              <th scope="col" colspan="100"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngIf="
                retourImport.producteursNonPresentsImport &&
                retourImport.producteursNonPresentsImport.length === 0
              "
            >
              <td colspan="99" class="p-4">
                <fa-icon icon="exclamation"></fa-icon>&nbsp;
                <span translate
                  >page.declarations.import-inventaire-vergers.table.no-data</span
                >
              </td>
            </tr>
            <tr
              *ngFor="
                let producteur of retourImport.producteursNonPresentsImport;
                let i = index
              "
            >
              <td scope="col" class="text-left">
                {{ producteur.raisonSociale }}
              </td>
              <!--<td scope="col" class="text-left">{{producteur.nom}}</td>
              <td scope="col" class="text-left">{{producteur.commune}}</td>-->
              <td scope="col" class="text-left">
                {{ producteur.departement }}
              </td>
              <!--<td *ngFor="let col of producteur.especes" scope="col" class="text-left">{{col[0]}}<br/>{{col[1]}} ha</td>-->
              <td colspan="99"></td>
            </tr>
          </tbody>
        </table>
        <br />
        <h5 translate>
          page.declarations.import-inventaire-vergers.resultat-erreurs
        </h5>
        <table *ngIf="retourImport" class="table table-striped mt-2">
          <thead class="center">
            <tr>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.raison-sociale</span
                >
              </th>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.nom</span
                >
              </th>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.commune</span
                >
              </th>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.departement</span
                >
              </th>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.champ</span
                >
              </th>
              <th scope="col">
                <span translate
                  >page.declarations.import-inventaire-vergers.table.header.erreur</span
                >
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngIf="
                retourImport.producteursEnErreur &&
                retourImport.producteursEnErreur.length === 0
              "
            >
              <td colspan="99" class="p-4">
                <fa-icon icon="exclamation"></fa-icon>&nbsp;
                <span translate
                  >page.declarations.import-inventaire-vergers.table.no-error</span
                >
              </td>
            </tr>
            <tr
              *ngFor="
                let producteur of retourImport.producteursEnErreur;
                let i = index
              "
            >
              <td scope="col" class="text-left">
                {{ producteur.raisonSociale }}
              </td>
              <td scope="col" class="text-left">{{ producteur.nom }}</td>
              <td scope="col" class="text-left">{{ producteur.commune }}</td>
              <td scope="col" class="text-left">
                {{ producteur.departement }}
              </td>
              <td scope="col" class="text-left">
                {{ producteur.champErreur }}
              </td>
              <td scope="col" class="text-left">{{ producteur.erreur }}</td>
              <td colspan="99"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
