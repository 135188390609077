import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';

@JsonObject
export class Conditionnement {
  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('code', String)
  code: string = undefined;

  @JsonProperty('libelle', String)
  libelle: string = undefined;
}
