import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ExportToCsv } from 'export-to-csv';
import { remove } from 'lodash';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { Interaction } from 'src/app/data/intervenant/models/interaction.model';
import { RefTypeInteraction } from 'src/app/data/intervenant/models/ref-type-interaction.model';
import { InteractionsService } from 'src/app/data/intervenant/services/interactions/interactions.service';
import { InterlocuteursService } from 'src/app/data/intervenant/services/interlocuteurs/interlocuteurs.service';
import { RefTypeInteractionsService } from 'src/app/data/intervenant/services/ref-type-interaction/ref-type-interactions.service';

@Component({
  selector: 'app-liste-interactions',
  templateUrl: './liste-interactions.component.html',
  styleUrls: ['./liste-interactions.component.scss']
})
export class ListeInteractionsComponent implements OnInit {

  filtrer: boolean = true;

  interactions: Interaction[];
  interactionFilter: Interaction[];

  refTypeInteractions: RefTypeInteraction[];

  nomPrenomFilter: string;
  dateDebutFilter: NgbDate;
  dateFinFilter: NgbDate;
  typeInteractionFilter: RefTypeInteraction;
  sujetFilter: string;
  raisonSocialeFilter: string;
  interlocuteurFilter: string;

  exportData: {};
  
  get idOrganisme() { return SessionContext.get('idOrganisme'); }

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly refTypeInteractionService: RefTypeInteractionsService,
    private readonly interactionService: InteractionsService,
    private readonly interlocuteurService: InterlocuteursService, 
    private readonly modalsService : AdvBootstrapModalService,
    private readonly translate: TranslateService
  ) { }

  ngOnInit() {

   
    forkJoin(
      this.refTypeInteractionService.getTypeInteractions(),
      this.interlocuteurService.getInterlocuteurs(this.idOrganisme),
      this.interactionService.getInteractions()
      )
      .pipe(
        this.loaderService.operator()
      ).subscribe(([refType, interlocuteurs, interactions]) => {

        this.interactionFilter = interactions
        this.interactions = interactions;
        this.refTypeInteractions = refType;


      });

  }

  editInteraction(interaction: Interaction) {
    this.router.navigate(['edit', interaction.id], { relativeTo: this.route });
  }

  afficheFiltre() {
    this.filtrer = !this.filtrer;
  }

  datePart(date: Date) {
    let d = new Date(date);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    return d.toLocaleDateString('fr');
  }
  
  filter() {
    if (this.interactions && this.interactions.length) {
      this.interactionFilter = this.interactions.filter(item => {
        if (this.nomPrenomFilter && (item.contact.nom + ' ' + item.contact.prenom).toLowerCase().indexOf(this.nomPrenomFilter.toLowerCase()) === -1) {
          return false;
        }
        if (this.interlocuteurFilter && (item.contact_anpp.nom + ' ' + item.contact_anpp.prenom).toLowerCase().indexOf(this.interlocuteurFilter.toLowerCase()) === -1) {
          return false;
        }
        if (this.raisonSocialeFilter && item.contact.raisonsociale.toLowerCase().indexOf(this.raisonSocialeFilter.toLowerCase()) === -1) {
          return false;
        }
        if (this.typeInteractionFilter && (item.refTypeInteraction == null || item.refTypeInteraction.id !== this.typeInteractionFilter.id)) {
          return false;
        }
        if (this.sujetFilter && item.sujetInteraction.toLowerCase().indexOf(this.sujetFilter.toLowerCase()) === -1) {
          return false;
        }
        if(this.dateDebutFilter && moment([this.dateDebutFilter.year, this.dateDebutFilter.month - 1, this.dateDebutFilter.day, 0, 0, 0, 0]).isSameOrAfter(item.dateInteraction) ){
          return false;
        }
        if(this.dateFinFilter && moment([this.dateFinFilter.year, this.dateFinFilter.month - 1, this.dateFinFilter.day, 23, 59, 0, 0]).isSameOrBefore(item.dateInteraction) ){
          return false;
        }
        
      return true;
      });
    }
  }

  openConfirmationDialog(interaction: Interaction) { 

    this.modalsService.confirm(
      'Veuillez confirmer',
      'Voulez vous supprimer l\'interaction ?', {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.deleteInteraction(interaction);
    }, () => { });

  }

  deleteInteraction(interaction: Interaction) {
    this.interactionService.supprimerInteraction(interaction.id).subscribe(() => {
      remove(this.interactions, interaction);
    });
  }

  //Export CSV
  exporterInteractions(): void {
    const data = [];
    const headersCSV = [
    "Date de l'interaction",
    "Contact",
    "Raison sociale",
    "Contact ANPP",
    "Type",
    "Sujet"];

    this.interactionFilter.forEach(interaction => {

      this.exportData = {};

      this.exportData[0] = interaction.dateInteraction.format('DD/MM/YYYY');
      this.exportData[1]= interaction.contact.prenom + " " + interaction.contact.nom;
      this.exportData[2] = interaction.contact.raisonsociale;
      this.exportData[3] = interaction.contact_anpp.prenom + " " + interaction.contact_anpp.nom;
      this.exportData[4] = interaction.refTypeInteraction.libelle;
      this.exportData[5] = interaction.sujetInteraction;     

      data.push(this.exportData
      );

    });


    const options = {
      filename: 'EXPORT_INTERACTIONS'.concat('_').concat(moment().format('YYYYMMDD')),
      fieldSeparator: ';',
      quoteStrings: '',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'EXPORT_INTERACTIONS'.concat('_').concat(moment().format('YYYYMMDD')),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: headersCSV
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);

  }

  private getCustomDateFromMoment(date: moment.Moment): {
    year: number,
    month: number,
    day: number
  } {
    return (date) 
      ? {
        year: date.year(),
        month: date.month() + 1,
        day: date.date()
      } 
      : undefined;
    }


  

}
