import { JsonObject, JsonProperty } from '@thorolf/json-ts-mapper';
import { ParametreCampagne } from './parametre-campagne.model';
import { ContactReferent } from './contact-referent.model';
import { RefSoustypeUtilisateur } from '../../droits/models/ref-soustype-utilisateur.model';

@JsonObject
export class EntrepriseDroit {
  @JsonProperty('idEntreprise', Number)
  public id: number = undefined;

  @JsonProperty('droitRecolte', Number)
  public droitRecolte: boolean = undefined;

  @JsonProperty('droitEcoulement', Number)
  public droitEcoulement: boolean = undefined;

  @JsonProperty('droitStock', Number)
  public droitStock: boolean = undefined;

  constructor() {}
}
