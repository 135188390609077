import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as bcrypt from 'bcryptjs';


@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor(
    protected readonly http: HttpClient,
    protected readonly mapper: JsonMapperService
  ) { }
  /**
   * Modifie mot de passe à l'utilisateur
   * @param mail mail de l'utilisateur récupéré dans les params
   * @param tokenPassword tokenPassword de l'utilisateur récupéré dans les params
   * @see password mot de passe envoyé, crypté en bscrypt
   */
  createPwd(mail: string, tokenPassword: string, password: string): Observable<void> {
    const params = new HttpParams()
      .set('tokenPassword', tokenPassword)
      .set('mail', mail);
    const bcryptPassword = this.bcryptHash(password);
    return this.http.patch(`/api/intervenant/public/utilisateurs/creer-mdp`, bcryptPassword, { params }).pipe(
      map(() => { }),
    );
  }

  /**
   * Permet de cryter une valeur en bcrypt
   * @param value valeur à crypter en bcrypt
   */
  bcryptHash(value: string): string {
    const salt = bcrypt.genSaltSync(12);
    return bcrypt.hashSync(value, 12);
  }

  /**
   * Permet de générer un mail de regénération de mot de passe en cas d'oubli
   * @param mail mail de l'utilisateur
   */
  forgotPwd(mail: string): Observable<any> {
    return this.http.post(`/api/intervenant/public/utilisateurs/mdp-oublie`, mail).pipe(
      map(() => { })
    );
  }

  /**
   * Permet de modifier le mot de passe de l'utilisateur
   * @param oldPwd ancien mot de passe
   * @param newPwd nouveau mot de passe
   * @param idIntervenant id de l'intervenant (utilisateur connecté)
   */
  editPwd(oldPwd: string, newPwd: string, confirmNewPwd: string, idIntervenant: number): Observable<any> {
    return this.http.patch(`/api/intervenant/private/utilisateurs/${idIntervenant}/modifier-mdp`,
      {
        ancienPassword: oldPwd, // this.bcryptHash(oldPwd),
        nouveauPassword: newPwd, // this.bcryptHash(newPwd),
        confirmationPassword: this.bcryptHash(confirmNewPwd)
      }).pipe(
        map(() => { }),
      );
  }


}
