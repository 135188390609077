import { AdvBootstrapLoaderService } from "@adv/bootstrap-loader";
import { AdvBootstrapModalService } from "@adv/bootstrap-modal";
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { flattenStyles } from "@angular/platform-browser/src/dom/dom_renderer";
import { ActivatedRoute, Router, Routes } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin } from "rxjs";
import { AuthService } from "src/app/core/services/auth/auth.service";
import { SessionContext } from "src/app/core/services/config/app.settings";
import { RefCampagnesService } from "src/app/data/declaration/services/ref-campagnes/ref-campagnes.service";
import { ContactEngagement } from "src/app/data/habilitation/models/engagements/contact-engagement";
import { EngagementBase } from "src/app/data/habilitation/models/engagements/engagement-base";
import { EngagementDetail } from "src/app/data/habilitation/models/engagements/engagement-detail";
import { EngagementSc } from "src/app/data/habilitation/models/engagements/engagement-sc";
import { EntrepriseEngagement } from "src/app/data/habilitation/models/engagements/entreprise-engagement";
import { IntervenantEngagement } from "src/app/data/habilitation/models/engagements/intervenant-engagement";
import { LaboratoireAnpp } from "src/app/data/habilitation/models/engagements/laboratoire-anpp.model";
import { RefCampagne } from "src/app/data/habilitation/models/engagements/ref.campagne.model";
import { EngagementVergersEcoService } from "src/app/data/habilitation/services/engagements/engagement-vergers-eco.service";
import { LaboratoireAnppService } from "src/app/data/habilitation/services/laboratoires/laboratoire-anpp.service";
import { Contact } from "src/app/data/intervenant/models/contact.model";
import { EntrepriseBase } from "src/app/data/intervenant/models/entreprise-base.model";
import { Entreprise } from "src/app/data/intervenant/models/entreprise.model";
import { Utilisateur } from "src/app/data/intervenant/models/utilisateur.model";
import { ContactsService } from "src/app/data/intervenant/services/contacts/contacts.service";
import { EntreprisesService } from "src/app/data/intervenant/services/entreprises/entreprises.service";
import { RefSousTypesService } from "src/app/data/intervenant/services/ref-sous-types/ref-sous-types.service";
import { UtilisateursService } from "src/app/data/intervenant/services/utilisateurs/utilisateurs.service";

@Component({
  selector: "app-formulaire-gp",
  templateUrl: "./formulaire-gp.component.html",
  styleUrls: ["./formulaire-gp.component.scss"],
})
export class FormulaireGpComponent implements OnInit {
  static routes: Routes = [
    {
      path: "",
      component: FormulaireGpComponent,
      data: {
        domaines: ["ANPP"],
        menu: {
          libelle: "formulaire",
        },
      },
    },
  ];

  @Input() public idEngagement: number;
  public formGroup: FormGroup;
  public engagement: EngagementBase;
  public entreprise: Entreprise;
  public contacts: Contact[];
  public contactsReferents: Contact[];
  public conseillers: Utilisateur[];
  public conseillersGroupe: Utilisateur[] = [];
  public refCampagne: RefCampagne;
  public organismesCertification: EntrepriseEngagement[];
  public creation: Boolean = false;
  public isAnpp: Boolean;
  public readOnly: Boolean = false;
  public utilisateur: Utilisateur;
  public refSousTypes: any;
  public laboratoireResidusList: LaboratoireAnpp[];
  public laboratoireSolsList: LaboratoireAnpp[];

  public get raisonsociale() {
    return this.formGroup.get("raisonsociale");
  }
  public get responsable() {
    return this.formGroup.get("responsable");
  }
  public get activiteProduction() {
    return this.formGroup.get("activiteProduction");
  }
  public get activiteConditionnement() {
    return this.formGroup.get("activiteConditionnement");
  }
  public get utilisationLogo() {
    return this.formGroup.get("utilisationLogo");
  }
  public get contactReferentEco() {
    return this.formGroup.get("contactReferentEco");
  }
  public get conseillerTechnique() {
    return this.formGroup.get("conseillerTechnique");
  }
  public get conseillerTechnique2() {
    return this.formGroup.get("conseillerTechnique2");
  }
  public get laboratoireResidus() {
    return this.formGroup.get("laboratoireResidus");
  }
  public get laboratoireSols() {
    return this.formGroup.get("laboratoireSols");
  }
  public get organismeCertification() {
    return this.formGroup.get("organismeCertification");
  }
  public get engagementAnimationTechnique() {
    return this.formGroup.get("engagementAnimationTechnique");
  }

  constructor(
    private fb: FormBuilder,
    private loaderService: AdvBootstrapLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private readonly utilisateursService: UtilisateursService,
    private readonly contactService: ContactsService,
    private readonly translate: TranslateService,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly engagementVergersEcoService: EngagementVergersEcoService,
    private readonly refCampagnesService: RefCampagnesService,
    private readonly authService: AuthService,
    private readonly entrepriseService: EntreprisesService,
    private readonly refSousTypeservice: RefSousTypesService,
    private readonly laboratoireAnppService: LaboratoireAnppService
  ) {}

  ngOnInit() {
    this.isAnpp = this.authService.isAnpp();

    //Vérification si il n'a pas d'id dans l'url auquel cas on active le mode création
    let id;
    if (this.idEngagement) {
      id = this.idEngagement;
    } else {
      id = parseInt(this.route.snapshot.paramMap.get("id"), 10);
    }

    if (!id) {
      this.initForm();
      this.creation = true;
      this.utilisateursService
        .getUtilisateur(SessionContext.get("idIntervenant"))
        .subscribe((utilisateur) => {
          this.utilisateur = utilisateur;
          this.entrepriseService
            .getEntreprisesByIdEntrepriseLiee(this.utilisateur.entreprise.id)
            .pipe(this.loaderService.operator())
            .subscribe((entreprises) => {});
          this.loadData(utilisateur.entreprise);
        });
    } else {
      //Si on est en mode modification et que l'utilisateur n'est pas anpp alors on vérouille le formulaire en lecture seule
      if (this.isAnpp == false || this.idEngagement) {
        this.readOnly = true;
      }
      this.initForm();
      //Si l'id est pésent dans l'url, on récupère l'engagement en base de données
      this.engagementVergersEcoService
        .getEngagementEcoById(id)
        .pipe(this.loaderService.operator())
        .subscribe((engagement) => {
          this.engagement = engagement;
          //remplissage du formulaire
          this.loadData(engagement.entreprise);
        });
    }
  }

  initForm() {
    this.formGroup = this.fb.group({
      raisonsociale: [
        { value: undefined, disabled: true },
        Validators.required,
      ],
      responsable: [
        { value: undefined, disabled: this.readOnly },
        Validators.required,
      ],
      activiteProduction: [
        { value: undefined, disabled: this.readOnly },
        Validators.required,
      ],
      activiteConditionnement: [
        { value: undefined, disabled: this.readOnly },
        Validators.required,
      ],
      utilisationLogo: [{ value: undefined, disabled: this.readOnly }],
      contactReferentEco: [
        { value: undefined, disabled: this.readOnly },
        Validators.required,
      ],
      conseillerTechnique: [
        { value: undefined, disabled: this.readOnly },
        Validators.required,
      ],
      conseillerTechnique2: [{ value: undefined, disabled: this.readOnly }],
      laboratoireResidus: [{ value: undefined, disabled: this.readOnly }],
      laboratoireSols: [{ value: undefined, disabled: this.readOnly }],
      organismeCertification: [
        { value: undefined, disabled: this.readOnly },
        Validators.required,
      ],
      engagementAnimationTechnique: [
        { value: true, disabled: true },
        Validators.required,
      ],
    });
  }

  loadData(entreprise: Entreprise) {
    const date = new Date();
    forkJoin([
      this.entrepriseService.getEntreprisesBySousRefType(["OC_DEFAUT"]),
      this.refCampagnesService.getCampagneAnnee(date.getFullYear()),
      this.refSousTypeservice.getRefSousType(),
      this.laboratoireAnppService.getLaboratoires(),
    ])
      .pipe(this.loaderService.operator())
      .subscribe(
        ([organismesCertificateurs, campagne, refSousTypes, laboratoires]) => {
          this.refCampagne = <RefCampagne>{
            id: campagne.id,
            code: campagne.code,
            annee: campagne.annee,
          };
          this.refSousTypes = refSousTypes;
          this.organismesCertification = organismesCertificateurs;
          this.entreprise = entreprise;
          this.laboratoireResidusList = laboratoires.filter(
            (l) => l.typeCode === "RESIDUS"
          );
          this.laboratoireSolsList = laboratoires.filter(
            (l) => l.typeCode === "SOLS"
          );
          forkJoin([
            this.contactService.getContactsByEntreprise(entreprise.id),
            this.utilisateursService.getConseillersTechniques(entreprise.id),
            this.utilisateursService.getConseillersTechniquesGroupe(
              entreprise.id
            ),
          ]).subscribe(
            ([
              contacts,
              conseillersTechniques,
              conseillersTechniquesGroupe,
            ]) => {
              this.contacts = contacts;
              this.contactsReferents = contacts.filter((c) =>
                c.referents.find((r) => r.code === "VER")
              );
              this.conseillers = conseillersTechniques.sort((a, b) =>
                a.nom < b.nom ? -1 : 1
              );
              this.conseillersGroupe = conseillersTechniquesGroupe.sort(
                (a, b) => (a.nom < b.nom ? -1 : 1)
              );
              if (this.conseillersGroupe.length == 0) {
                this.conseillerTechnique.setValidators(null);
                this.conseillerTechnique2.setValidators([Validators.required]);
                this.conseillerTechnique.updateValueAndValidity();
                this.conseillerTechnique2.updateValueAndValidity();
              }
              if (this.creation) {
                this.refCampagnesService
                  .getCampagneVerPrecedente()
                  .subscribe((campagnePrecedente) => {
                    forkJoin([
                      this.engagementVergersEcoService.getEngagementEcoByEntrepriseAndCampagne(
                        entreprise.id,
                        campagnePrecedente.code
                      ),
                      this.entrepriseService.getEntreprisesByIdEntrepriseLieeAndRefSousType(
                        this.entreprise.id,
                        this.refSousTypes.find((r) => r.code === "PO_DEFAUT").id
                      ),
                      this.entrepriseService.getEntreprisesByIdEntrepriseLieeAndRefSousType(
                        this.entreprise.id,
                        this.refSousTypes.find((r) => r.code === "SC_DEFAUT").id
                      ),
                    ])
                      .pipe(this.loaderService.operator())
                      .subscribe(
                        ([
                          dernierEngagement,
                          listEntreprisesPo,
                          listEntreprisesSc,
                        ]) => {
                          if (dernierEngagement != null) {
                            this.engagement = dernierEngagement;
                            this.engagement.refCampagne = this.refCampagne;
                            this.engagement.listEngagementDetail =
                              this.initListEngagementDetail(listEntreprisesPo);
                            this.engagement.listEngagementSc =
                              this.initListEngagementSc(listEntreprisesSc);
                            this.loadForm();
                          } else {
                            this.initEngagement(
                              listEntreprisesPo,
                              listEntreprisesSc
                            );
                          }
                        }
                      );
                  });
              } else {
                this.loadForm();
              }
            }
          );
          this.raisonsociale.setValue(entreprise.raisonSociale);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  initEngagement(
    listEntreprisesPo: Array<EntrepriseBase>,
    listEntreprisesSc: Array<EntrepriseBase>
  ) {
    this.engagement = <EngagementBase>{
      id: null,
      refCampagne: this.refCampagne,
      entreprise: <EntrepriseEngagement>{
        id: this.entreprise.id,
        raisonSociale: this.entreprise.raisonSociale,
        adresse: this.entreprise.adresse,
        codePostal: this.entreprise.codePostal,
        commune: this.entreprise.commune,
        departement: this.entreprise.departement,
        siret: this.entreprise.siret,
      },
      responsable: null,
      activiteProduction: false,
      activiteConditionnement: false,
      utilisationLogo: false,
      referent: null,
      conseillerTechnique: null,
      organismeCertification: null,
      dateAudit: null,
      idStatut: null,
      libelleStatut: null,
      codeStatut: null,
      engagementAnimationTechnique: null,
      //TODO Load les PO
      listEngagementDetail: this.initListEngagementDetail(listEntreprisesPo),
      //TODO Load les SC
      listEngagementSc: this.initListEngagementSc(listEntreprisesSc),
    };
  }

  initListEngagementDetail(listEntreprisesPo: Array<EntrepriseBase>) {
    let engagementsPo: EngagementDetail[] = [];
    for (var entr of listEntreprisesPo) {
      let detailModifie;
      if (this.engagement && this.engagement.listEngagementDetail) {
        detailModifie = this.engagement.listEngagementDetail.find(
          (detailPrecedent) => {
            return detailPrecedent.entreprise.id == entr.id;
          }
        );
      }
      if (detailModifie) {
        engagementsPo.push(detailModifie);
      } else {
        engagementsPo.push(<EngagementDetail>{
          id: null,
          activiteConditionnement: false,
          activiteProduction: false,
          engagementGlobalGap: false,
          engagementHve: false,
          engagementVergersEcoresponsables: false,
          certificationBrc: false,
          certificationIfs: false,
          entreprise: <EntrepriseBase>{
            id: entr.id,
            raisonSociale: entr.raisonSociale,
          },
        });
      }
    }
    return engagementsPo;
  }

  initListEngagementSc(listEntreprisesSc: Array<EntrepriseBase>) {
    let engagementsSc: EngagementSc[] = [];

    for (var entr of listEntreprisesSc) {
      let scModifie;
      if (this.engagement && this.engagement.listEngagementSc) {
        scModifie = this.engagement.listEngagementSc.find((scPrecedent) => {
          return scPrecedent.entreprise.id == entr.id;
        });
      }

      if (scModifie) {
        engagementsSc.push(scModifie);
      } else {
        engagementsSc.push(<EngagementSc>{
          certificationBrc: false,
          certificationIfs: false,
          utilisationLogo: false,
          entreprise: <EntrepriseBase>{
            id: entr.id,
            raisonSociale: entr.raisonSociale,
          },
        });
      }
    }

    return engagementsSc;
  }

  loadForm() {
    this.responsable.setValue(this.engagement.responsable.id);
    this.activiteProduction.setValue(this.engagement.activiteProduction);
    this.activiteConditionnement.setValue(
      this.engagement.activiteConditionnement
    );
    this.utilisationLogo.setValue(this.engagement.utilisationLogo);
    this.contactReferentEco.setValue(this.engagement.referent.id);
    if (this.engagement.conseillerTechnique) {
      this.conseillerTechnique.setValue(this.engagement.conseillerTechnique.id);
    }

    if (this.engagement.conseillerTechnique2 != null) {
      this.conseillerTechnique2.setValue(
        this.engagement.conseillerTechnique2.id
      );
    }
    if (this.engagement.laboratoireResidus != null) {
      const laboResidus = this.laboratoireResidusList.find(
        (laboResidus) =>
          laboResidus.id === this.engagement.laboratoireResidus.id
      );
      this.laboratoireResidus.setValue(laboResidus);
    }
    if (this.engagement.laboratoireSols != null) {
      const laboSols = this.laboratoireSolsList.find(
        (laboSols) => laboSols.id === this.engagement.laboratoireSols.id
      );
      this.laboratoireSols.setValue(laboSols);
    }
    const org = this.organismesCertification.find(
      (organisme) => organisme.id === this.engagement.organismeCertification.id
    );
    this.organismeCertification.setValue(org);
  }

  submit() {
    if (this.formGroup.valid) {
      let modalConfirmText: string = "";
      if (!this.isAnpp && this.creation) {
        modalConfirmText = this.translate.instant(
          "page.engagements.modals.confirm-creation"
        );
      } else if (this.isAnpp) {
        modalConfirmText = this.translate.instant(
          "page.engagements.modals.confirm-modification"
        );
      }

      this.modalsService
        .confirm(
          this.translate.instant(modalConfirmText),
          this.translate.instant("page.engagements.modals.title"),
          {
            cancelText: this.translate.instant(
              "page.engagements.modals.cancel-button"
            ),
            submitText: this.translate.instant(
              "page.engagements.modals.valid-button"
            ),
          }
        )
        .then(
          () => {
            this.saveEngagement();
          },
          () => {}
        );
    }
  }

  saveEngagement() {
    this.engagement.responsable = <ContactEngagement>{
      id: this.responsable.value,
    };
    this.engagement.activiteProduction = this.activiteProduction.value;
    this.engagement.activiteConditionnement =
      this.activiteConditionnement.value;
    this.engagement.engagementAnimationTechnique =
      this.engagementAnimationTechnique.value;
    if (this.utilisationLogo.value !== undefined) {
      this.engagement.utilisationLogo = this.utilisationLogo.value;
    }
    this.engagement.referent = <ContactEngagement>{
      id: this.contactReferentEco.value,
    };
    this.engagement.conseillerTechnique = <IntervenantEngagement>{
      id: this.conseillerTechnique.value,
    };
    let organismeCertificateur: EntrepriseEngagement =
      this.organismeCertification.value;
    this.engagement.organismeCertification = <EntrepriseEngagement>{
      id: organismeCertificateur.id,
      raisonSociale: organismeCertificateur.raisonSociale,
    };
    if (this.conseillerTechnique2.value) {
      this.engagement.conseillerTechnique2 = <IntervenantEngagement>{
        id: this.conseillerTechnique2.value,
      };
    } else {
      this.engagement.conseillerTechnique2 = null;
    }
    if (
      this.laboratoireResidus.value !== undefined &&
      this.laboratoireResidus.value !== ""
    ) {
      let laboratoireResidus: LaboratoireAnpp = this.laboratoireResidus.value;
      this.engagement.laboratoireResidus = laboratoireResidus;
    } else {
      this.engagement.laboratoireResidus = null;
    }
    if (
      this.laboratoireSols.value !== undefined &&
      this.laboratoireSols.value !== ""
    ) {
      let laboratoireSols: LaboratoireAnpp = this.laboratoireSols.value;
      this.engagement.laboratoireSols = laboratoireSols;
    } else {
      this.engagement.laboratoireSols = null;
    }
    if (this.creation) {
      this.engagementVergersEcoService
        .creerEngagementVergersEco(this.engagement, "Groupement producteur")
        .subscribe((idEngagenemnt: number) => this.retour());
    } else {
      this.engagementVergersEcoService
        .modifierEngagementVergersEco(this.engagement)
        .subscribe((retour) => this.retour());
    }
  }

  retour() {
    this.router.navigate(["main/engagements"]);
  }
}
