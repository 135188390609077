import { JsonConverter } from '@thorolf/json-ts-mapper';

import { DataIdConverter } from 'src/app/core/services/mapper/data-id.converter';
import { Organisme } from '../organisme.model';

@JsonConverter
export class OrganismeOptionalConverter extends DataIdConverter<Organisme> {
  constructor() {
    super('organismes');
  }

  deserialize(data: Organisme['id'], context: any): Organisme {
    try {
      return this.mapper.deserialize(this.field, data, context.organismes);
    } catch (e) {
      console.warn('Organisme mapper error : invalid data', data);
      return undefined;
    }
  }

  serialize(data: Organisme): Organisme['id'] {
    try {
      return this.mapper.serialize(this.field, data);
    } catch (e) {
      console.warn('Organisme mapper error : invalid data', data);
      return undefined;
    }
  }
}
