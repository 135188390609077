import { JsonProperty, JsonObject, Optional, CustomConverter } from '@thorolf/json-ts-mapper';

import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { RefAnneeType } from './ref-annee-type.model';
import { RefObjetFacture } from './ref-objet-facture.model';
import { ModeleArticle } from './modele-article.model';
import { Cahier } from '../../habilitation/models/cahier.model';
import { RefQuantiteFacture } from './ref-quantite-facture.model';
import { Operateur } from '../../intervenant/models/operateur.model';

@JsonObject
export class ObjetFactureDTO {
    @JsonProperty('id', Number)
    @Optional
    id: number;

    @JsonProperty('idObjet', Number)
    idObjet: number;

    @JsonProperty('fonctionnelId', String)
    fonctionnelId: string;

    @JsonProperty('idOperateur', Number)
    idOperateur: number;   

    @JsonProperty('idCahier', Number)
    @Optional
    idCahier: number;

    @JsonProperty('annee', Number)
    annee: number;

    @JsonProperty('quantite', Number)
    quantite: number;

    @JsonProperty('codeRefQuantiteFacture', String)
    @Optional
    codeRefQuantiteFacture: string;

    

    
}
