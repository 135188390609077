import { JsonProperty, JsonObject, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { RefMouvement } from './ref-mouvement.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { OperateurInformationsDomaine } from '../../intervenant/models/operateur-informations-domaine.model';
import { Operateur } from '../../intervenant/models/operateur.model';
import { Produit } from '../../habilitation/models/produit.model';

@JsonObject
export class PressionResultatItem {
  @JsonProperty('idIntervenant', Number)
  idOperateur: number;
  operateur: Operateur;
  infosOperateur: OperateurInformationsDomaine[] = [];

  @JsonProperty('codeProduit', String)
  codeProduit: string;
  produit: Produit;

  @JsonProperty('adresse', String)
  @Optional
  adresse: string;

  @JsonProperty('idMouvement', Number)
  @Optional
  idMouvement: number;

  @JsonProperty('idRefMouvement', Number)
  @CustomConverter(DataIdConverterFactory.build(RefMouvement, 'refMouvements'))
  @Optional
  typeMouvement: RefMouvement;

  @JsonProperty('dateMouvement', String)
  @CustomConverter(DateConverter)
  dateMouvement: moment.Moment;

  @JsonProperty('estRealise', Boolean)
  estRealise: boolean;

  @JsonProperty('estNonControlable', Boolean)
  estNonControlable: boolean;

  @JsonProperty('numeroDossier', Number)
  @Optional
  numeroDossier: number;

  @JsonProperty('volume', Number)
  @Optional
  volume: number;

  @JsonProperty('numeroLot', String)
  @Optional
  numeroLot: string;
}
