import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { remove } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { Groupe } from 'src/app/data/intervenant/models/groupe.models';
import { ContactsService } from 'src/app/data/intervenant/services/contacts/contacts.service';
import { GroupesDiffusionService } from 'src/app/data/intervenant/services/groupes-diffusion/groupes-diffusion.service';
import { EmailsHelper } from 'src/app/shared/helpers/emails.helper';
import { Email } from 'src/app/shared/interfaces/email.interface';
import { NavigatorExtended } from 'src/app/shared/interfaces/navigator-extended.interface';

@Component({
  selector: 'app-liste-groupes-diffusion',
  templateUrl: './liste-groupes-diffusion.component.html',
  styleUrls: ['./liste-groupes-diffusion.component.scss']
})
export class ListeGroupesDiffusionComponent implements OnInit {

  groupes: Groupe[];
  mails: String[];
  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly translateService: TranslateService,
    private readonly groupeService: GroupesDiffusionService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly contactService: ContactsService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {

    forkJoin(
      this.groupeService.getGroupes()
    )
      .pipe(this.loaderService.operator())
      .subscribe(([groupes]) => {
        this.groupes = groupes;
      });



  }

  openConfirmationDialog(groupe: Groupe) {

    this.modalsService.confirm(
      'Veuillez confirmer',
      'Voulez vous supprimer ' + this.translateService.instant(`label.groupeDiffusion`) + ' ' + groupe.libelle + ' ?', {
      cancelText: this.translateService.instant(`label.annuler`),
      submitText: this.translateService.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.deleteGroupe(groupe);
    }, () => { });

  }

  deleteGroupe(groupe: Groupe) {
    this.groupeService.supprimerGroupe(groupe.id).subscribe(() => {
      remove(this.groupes, groupe);
    });
  }

  editGroupe(groupe: Groupe) {
    this.modalsService.confirm(
      'Veuillez confirmer',
      this.translateService.instant(`page.valeur_parametrable.message_modification`), {
      cancelText: this.translateService.instant(`label.annuler`),
      submitText: this.translateService.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.router.navigate(['edit', groupe.id], { relativeTo: this.route });
    }, () => { });
  }

  envoiMail(groupe: Groupe) {

    forkJoin(
      this.contactService.getMailContactByGroupes(groupe.id)
    )
      .pipe(this.loaderService.operator())
      .subscribe(([mails]) => {

        if (mails != null && mails.length > 0) {
          (navigator as NavigatorExtended).clipboard.writeText(mails.join(';')).then(() => {
            this.toastrService.success(
              this.translateService.instant('page.suivi.suivi-stocks.emails-copies-succes'),
              this.translateService.instant('page.suivi.suivi-stocks.emails-copies-titre'),
              { timeOut: 10 * 1000 }
            );
          }
          ).catch(e => console.error(e));
        } else {
          this.toastrService.error(this.translateService.instant('page.valeur_parametrable.absence_emails'));
        }
      });

  }

}
