import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditSituationComponent } from './edit-situation/edit-situation.component';
import { ListeSituationsComponent } from './liste-situations/liste-situations.component';

@Component({
  selector: 'app-situations',
  templateUrl: './situations.component.html',
  styleUrls: ['./situations.component.scss']
})
export class SituationsComponent implements OnInit {

  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"]
    };
  
    static routes: Routes = [
      { path: '', component: ListeSituationsComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id', component: EditSituationComponent, data: { roles: ['ORGANISME'] } },
      { path: 'new', component: EditSituationComponent, data: { roles: ['ORGANISME'] } }
    ];

  constructor() { }

  ngOnInit() {
  }

}
