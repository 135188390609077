import { JsonObject, JsonProperty, CustomConverter } from '@thorolf/json-ts-mapper';
import { RefUnite } from './ref-unite.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';

@JsonObject
export class RefContenant {
  @JsonProperty('idContenant', Number)
  idContenant: number = undefined;

  @JsonProperty('codeContenant', String)
  codeContenant: string = undefined;

  @JsonProperty('libelleContenant', String)
  libelleContenant: string = undefined;

  @JsonProperty('volumeContenant', Number)
  volumeContenant: number = undefined;

  @JsonProperty('idUniteContenant', Number)
  @CustomConverter(DataIdConverterFactory.build(RefUnite, 'refUnite'))
  refUnite: RefUnite = undefined;
}
