import { NgModule } from '@angular/core';
import { LoginComponent } from './pages/login/login.component';
import { AuthentificationRoutingModule } from './authentification-routing.module';
import { SharedModule } from '../shared/shared.module';
import { MainComponent } from './pages/main/main.component';
import { ChoixDomaineComponent } from './pages/choix-domaine/choix-domaine.component';
import { ChoixOrganismeComponent } from './pages/choix-organisme/choix-organisme.component';
import { CookieService } from 'ngx-cookie-service';
import { InscriptionComponent } from './pages/inscription/inscription.component';
import { RedirectionComponent } from './pages/redirection/redirection.component';
import { NoRolesComponent } from './pages/no-roles/no-roles.component';
import { ConnexionOperateurComponent } from './pages/connexion-operateur/connexion-operateur.component';
import { UserDisabledComponent } from './pages/user-disabled/user-disabled.component';
import { CreerMdpComponent } from './pages/password/creer-mdp/creer-mdp.component';
import { AgrimakerComponent } from './pages/agrimaker/agrimaker.component';

@NgModule({
  declarations: [
    MainComponent,
    LoginComponent,
    ChoixDomaineComponent,
    ChoixOrganismeComponent,
    InscriptionComponent,
    RedirectionComponent,
    NoRolesComponent,
    ConnexionOperateurComponent,
    UserDisabledComponent,
    CreerMdpComponent,
    AgrimakerComponent],
  imports: [
    // HttpClientModule,
    SharedModule,
    AuthentificationRoutingModule
  ],
  providers: [
    CookieService
  ]
})
export class AuthentificationModule { }
