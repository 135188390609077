<div class="anpp">
  <div class="content">
    <div class="content-panel" *ngIf="variete">
      <div class="title-bar">
        <h4>
          {{ title | translate }}
        </h4>
      </div>

      <form *ngIf="formGroup" [formGroup]="formGroup" #form="ngForm" (submit)="submit()" novalidate>

        <div class="m-3">
          <div class="form-group">
            <label for="espece" translate>page.valeur_parametrable.form.label.espece</label>
              <select id="espece" formControlName="espece" class="form-control">
                <option *ngFor="let espece of especes" [ngValue]="espece">{{espece.libelle}}
                </option>
              </select>

            <ng-container *ngIf="form.submitted">
              <div *ngIf="espece.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group">
            <label for="code" translate>page.valeur_parametrable.form.label.code</label>

            <input type="text" id="code" formControlName="code"
              [ngClass]="{'is-invalid': form.submitted && code.invalid}" class="form-control" />

            <ng-container *ngIf="form.submitted">
              <div *ngIf="code.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group">
            <label for="libelle" translate>page.valeur_parametrable.form.label.libelle</label>

            <input type="text" id="libelle" formControlName="libelle"
              [ngClass]="{'is-invalid': form.submitted && libelle.invalid}" class="form-control" />

            <ng-container *ngIf="form.submitted">
              <div *ngIf="libelle.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
        </div>

        <div class="actions-bar" fxLayoutAlign="space-between">
          <button class="btn btn-primary" *ngIf="!creation" [routerLink]="'../..'" translate>label.retour</button>
          <button class="btn btn-primary" *ngIf="creation" [routerLink]="'../'" translate>label.retour</button>

          <button type="submit" class="btn btn-primary" translate>{{!creation
            ? 'label.suivant': 'label.valider'}}</button>
        </div>
      </form>
    </div>
  </div>
</div>