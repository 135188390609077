<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel"> <fa-icon icon="file-alt"></fa-icon> <b translate> page.inventaire-verger.modals.valid-inventaire-verger.titleModal</b></h5>
    <button (click)="cancel()" type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">

    <p>&nbsp;</p>
    <div class="row justify-content-md-center">
      <div class="col col-lg-10 text-center"><h4></h4>
        <p>&nbsp;</p></div>
      <div class="col col-lg-5 text-center"> 
        <div class="form-group">
            <select id="cahiers" class="form-control bar1" [(ngModel)]="cahier">
              <option [ngValue]="null"></option>
              <option *ngFor="let cahier of cahiers" [ngValue]="cahier">{{cahier.libelle}}</option>
            </select>
        </div>
        <div class="align-center">
          <button type="button" (click)="cancel()"  class="btn btn-primary mr-2" translate>label.annuler</button>
          <button (click)="validerDeclaration()"  type="button" class="btn btn-primary"
                  translate>label.valider</button>
        </div>
      </div>
    </div>

  </div>
</div>

