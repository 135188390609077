import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { EditSystemeIrrigationComponent } from './edit-systeme-irrigation/edit-systeme-irrigation.component';
import { ListeSystemesIrrigationsComponent } from './liste-systemes-irrigations/liste-systemes-irrigations.component';

@Component({
  selector: 'app-systemes-irrigations',
  templateUrl: './systemes-irrigations.component.html',
  styleUrls: ['./systemes-irrigations.component.scss']
})
export class SystemesIrrigationsComponent implements OnInit {

  static routeData = {
    role: 'ORGANISME',
    domaines: ["ANPP"]
    };
  
    static routes: Routes = [
      { path: '', component: ListeSystemesIrrigationsComponent, data: { roles: ['ORGANISME'] } },
      { path: 'edit/:id', component: EditSystemeIrrigationComponent, data: { roles: ['ORGANISME'] } },
      { path: 'new', component: EditSystemeIrrigationComponent, data: { roles: ['ORGANISME'] } }
    ];

  constructor() { }

  ngOnInit() {
  }

}
