import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { RefTypeInteraction } from '../../models/ref-type-interaction.model';

@Injectable({
  providedIn: 'root'
})
export class RefTypeInteractionsService {

  constructor(  
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
    ) { }

    getTypeInteractions(): Observable<RefTypeInteraction[]> {
      return  this.http.get<RefTypeInteraction[]>(`/api/intervenant/private/typeInteractions`).pipe(
        map(typeInteraction => this.mapper.deserializeArray(typeInteraction, RefTypeInteraction))
      );        
    }

    getTypeInteraction(idTypeInteraction : number): Observable<RefTypeInteraction> {
      return  this.http.get<RefTypeInteraction>(`/api/intervenant/private/typeInteractions/${idTypeInteraction}`).pipe(
        map(fonction => this.mapper.deserializeObject(fonction, RefTypeInteraction))
      );        
    }

    public creerTypeInteraction(typeInteraction: RefTypeInteraction): Observable<number> {
      return this.http.post(`/api/intervenant/private/typeInteractions`, typeInteraction, { observe: 'response' }).pipe(
        map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
      );
    }  
    
    public modifierTypeInteraction(typeInteraction: RefTypeInteraction): Observable<void> {
      return this.http.put<void>(`/api/intervenant/private/typeInteractions/${typeInteraction.id}`, typeInteraction);
    }

    public supprimerTypeInteraction(idTypeInteraction: number): Observable<void> {
      return this.http.delete<void>(`/api/intervenant/private/typeInteractions/${idTypeInteraction}`);
    }
}
