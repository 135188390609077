import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Observable, forkJoin, throwError } from 'rxjs';
import { PressionControle } from '../../models/pression-controle.model';
import { map, tap, flatMap } from 'rxjs/operators';
import { ReferentielService } from '../referentiel/referentiel.service';
import { CahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import { ActivitesService } from 'src/app/data/habilitation/services/activites/activites.service';
import { ProduitsService } from 'src/app/data/habilitation/services/produits/produits.service';
import { FunctionalError } from 'src/app/shared/errors/functional.error';

@Injectable({
  providedIn: 'root'
})
export class PressionsService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly referentielService: ReferentielService,
    private readonly cahiersService: CahiersService,
    private readonly activitesService: ActivitesService,
    private readonly produitsService: ProduitsService
  ) { }

  /**
   * Retourne une pression de contrôle en fonction d'un ID
   * @param id L'ID de la pression de contrôle
   * @returns Un objet de la classe PressionControle
   * @see PressionControle
   */
  getPressionControle(id: number): Observable<PressionControle> {
    return forkJoin(
      this.http.get<object>(`/api/declaration/private/pressions/${id}`),
      this.referentielService.getReferentiel(),
      this.cahiersService.getCahiers(),
      this.activitesService.getActivites()
    ).pipe(
      map(([pression, ref, cahiers, activites]) =>
        this.mapper.deserializeObject(pression, PressionControle, Object.assign({
          cahiers, activites
        }, ref))
      ),
      this.produitsService.getProduitsPipe<PressionControle>()
    );
  }

  getPressionsControle(): Observable<PressionControle[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/declaration/private/pressions`),
      this.referentielService.getReferentiel(),
      this.cahiersService.getCahiers(),
      this.activitesService.getActivites()
    ).pipe(
      map(([pressions, ref, cahiers, activites]) =>
        this.mapper.deserializeArray(pressions, PressionControle, Object.assign({
          cahiers, activites
        }, ref))
      ),
      this.produitsService.getProduitsPipe()
    );
  }

  /**
   * Crée une pression de contrôle
   * @param pression La pression de contrôle à créer
   * @returns Un entier représentant l'id de la pression créée
   */
  postPressionControle(pression: PressionControle): Observable<number> {
    return this.http.post(
      '/api/declaration/private/pressions',
      this.mapper.serialize(pression, PressionControle),
      { observe: 'response' }
    ).pipe(
      map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
    );
  }

  /**
   * Modifie une pression de contrôle
   * @param pression La pression de contrôle à modifier
   */
  putPressionControle(pression: PressionControle): Observable<void> {
    return this.http.put(`/api/declaration/private/pressions/${pression.id}`, this.mapper.serialize(pression)).pipe(
      map(() => { })
    );
  }

  ajouterCampagne(pression: PressionControle, annee: number): Observable<void> {
    // make a pseudocopy of pression to avoid mofiying the original one
    pression = Object.assign(new PressionControle(), pression);
    pression.campagnes = pression.campagnes.slice();

    return this.referentielService.getReferentiel().pipe(
      map(ref => ref.campagnes.find(c => c.annee === annee)),
      tap(campagne => {
        if (!campagne) {
          throw new FunctionalError('CAMPAGNE_INEXISTANTE', { annee });
        }
      }),
      flatMap(campagne => {
        pression.campagnes.push(campagne);
        return this.putPressionControle(pression);
      })
    );
  }
}
