<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>page.operateurs.informations.modale.titleUpdate.infosMineures</h4>
  </div>

  <div class="modal-body">
    <form [formGroup]="formInformationsMineures" *ngIf="formInformationsMineures" #form="ngForm" (submit)="onSubmit()">
      <div class="flex">
        <div class="col-6">
          <div class="form-group">
            <label for="nom" class="col-form-label" translate>page.operateurs.informations.entreprise.nom</label>
            <input id="nom" formControlName="nom" class="form-control" type="text">

          </div>
          <div class="form-group">
            <label for="adresse" translate>page.operateurs.informations.entreprise.adresse</label>
            <input id="adresse" formControlName="adresse" class="form-control" type="text">
          </div>

          <div class="form-group">
            <label for="ville" translate>page.operateurs.informations.entreprise.ville</label>
            <input id="ville" formControlName="ville" class="form-control" type="text">
          </div>

          <div class="form-group">
            <label for="pays" translate>page.operateurs.informations.entreprise.pays</label>
            <select id="pays" formControlName="pays" class="form-control">
              <option [value]="null" hidden></option>
              <option *ngFor="let pays of refPays" [ngValue]="pays.id">{{pays.libelle}}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="telFixe" translate>page.operateurs.informations.entreprise.telephoneFixe</label>
            <input id="telFixe" formControlName="telFixe" class="form-control" type="tel"
                   (input)="formatPhoneNumber('telFixe')" (blur)="formatPhoneNumber('telFixe')">
          </div>
          <div class="form-group">
            <label for="fax" translate>page.operateurs.informations.entreprise.fax</label>
            <input id="fax" formControlName="fax" class="form-control" type="tel"
                   (input)="formatPhoneNumber('telMobile')" (blur)="formatPhoneNumber('telMobile')">
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="prenom" class="col-form-label" translate>page.operateurs.informations.entreprise.prenom</label>
            <input id="prenom" formControlName="prenom" class="form-control" type="text">

          </div>
          <div class="form-group">
            <label for="codePostal" translate>page.operateurs.informations.entreprise.codePostal</label>
            <input id="codePostal" formControlName="codePostal" class="form-control" type="text">
          </div>

          <div class="form-group">
            <label for="cedex" translate>page.operateurs.informations.entreprise.cedex</label>
            <input id="cedex" formControlName="cedex" class="form-control" type="text">
          </div>

          <div class="form-group">
            <label for="email" translate>page.operateurs.informations.entreprise.email</label>
            <input id="email" formControlName="email" class="form-control" type="email"
                   [ngClass]="{'is-invalid': email.invalid && (email.touched || form.submitted)}"
                   (blur)="onEmailBlur()"
                   (input)="onEmailInput()">
            <ng-container *ngIf="email.invalid && (email.touched || form.submitted)">
              <div *ngIf="email.errors?.required" class="form-error" translate>page.operateurs.informations.modale.email.required</div>
              <div *ngIf="email.errors?.multipleEmails" class="form-error" translate>page.operateurs.informations.modale.email.multiple</div>
              <div *ngIf="email.errors?.invalidEmail" class="form-error" translate>page.operateurs.informations.modale.email.invalid</div>
            </ng-container>
          </div>


          <div class="form-group">
            <label for="telMobile" translate>page.operateurs.informations.entreprise.telephoneMobile</label>
            <input id="telMobile" formControlName="telMobile" class="form-control" type="tel"
                   (input)="formatPhoneNumber('telMobile')" (blur)="formatPhoneNumber('telMobile')">
          </div>

        </div>
      </div>
      <div class="actions-bar">
        <button type="button" (click)="onClose()" class="btn btn-light" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary ml-2" translate>label.valider</button>
      </div>

    </form>
  </div>
</div>
