import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { AdvBootstrapModalOptions } from '@adv/bootstrap-modal/lib/adv-bootstrap-modal-options';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { GedFacade } from 'src/app/data/edition/facades/ged.facade';
import { VisibiliteEnum } from 'src/app/data/edition/models/enums/visibilite.enum';
import { GedFichier } from 'src/app/data/edition/models/ged-fichier.model';
import { RefTypeFichier } from 'src/app/data/edition/models/ref-type-fichier.model';
import { NotificationUpload } from 'src/app/data/intervenant/models/notification-upload.model';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { RefSousTypesService } from 'src/app/data/intervenant/services/ref-sous-types/ref-sous-types.service';
import { UtilisateursService } from 'src/app/data/intervenant/services/utilisateurs/utilisateurs.service';
import { EOPNOTSUPP } from 'constants';

@Component({
  selector: 'app-edit-document',
  templateUrl: './edit-document.component.html',
  styleUrls: ['./edit-document.component.scss']
})
export class EditDocumentComponent implements OnInit, OnDestroy {

  @Input() public estEdition = false;
  @Input() public fichierEdit: GedFichier = null;
  private file: File;
  private resourcesPrefix = 'page.documents.modals';
  private unsubscribe$ = new Subject<void>();
  private uuid: string;
  public entreprises$ = this.entrepriseService.getEntreprisesReferents();
  public estAnpp = this.authService.isAnpp();
  public formDocument: FormGroup;
  public operateurs$ = this.utilisateursService.getUtilisateurs();
  public titleResource: string;
  public refSousTypes = this.refSousTypesService.getRefSousType();
  public refTypesFichier: Array<RefTypeFichier> = [];
  public uploadEnCours = false;
  public visibiliteEnum = VisibiliteEnum;
  public extension: String;

  public get nom(): AbstractControl {
    return this.formDocument.get('nom');
  }
  public get typeFichier(): AbstractControl {
    return this.formDocument.get('typeFichier');
  }
  public get description(): AbstractControl {
    return this.formDocument.get('description');
  }
  public get visibilite(): AbstractControl {
    return this.formDocument.get('visibilite');
  }
  public get fichier(): AbstractControl {
    return this.formDocument.get('fichier');
  }
  public get intervenant(): AbstractControl {
    return this.formDocument.get('intervenant');
  }
  public get entreprise(): AbstractControl {
    return this.formDocument.get('entreprise');
  }
  public get refSousType(): AbstractControl {
    return this.formDocument.get('refSousType');
  }

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private entrepriseService: EntreprisesService,
    private formBuilder: FormBuilder, 
    private gedFacade: GedFacade,
    private modalService: AdvBootstrapModalService,
    private refSousTypesService: RefSousTypesService,
    private translateService: TranslateService,
    private utilisateursService: UtilisateursService
  ) { }

  public ngOnInit(): void {
    // A mapper ici pour pouvoir pré-sélectionner le type en cas de modification
    this.gedFacade.refTypesFichier$.pipe(
      takeUntil(this.unsubscribe$),
      map((refTypesFichier: Array<RefTypeFichier>) => this.refTypesFichier = refTypesFichier)
    ).subscribe(() => this.initForm());
    this.gedFacade.loadRefTypesFichier();

    const resourcesSuffix = this.estEdition ? 'edit' : 'create';
    this.titleResource = `${this.resourcesPrefix}.${resourcesSuffix}.title`;
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }

  public onFileChange(event: any): void {
    // Uploader le fichier physique dans la GED
    if (event.target.files && event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.uploadEnCours = true;
      this.gedFacade.postUploadFile(this.file).subscribe((uuid: string) => {
        this.uuid = uuid;
        this.uploadEnCours = false;
        if (!this.nom.value) {
          this.nom.setValue(this.file.name.split('.').slice(0, -1).join('.'));
        }
        this.extension = this.file.name.split(".").pop();
      }, (error) => {
        if ((error.status === 0) || error.error.exception === 'MaxUploadSizeExceededException') {
          this.uploadEnCours = false;
          this.modalService.error(
            this.translateService.instant('page.documents.modals.upload-error.content'),
              this.translateService.instant('page.documents.modals.upload-error.title'),
          );
        }
      });
    }
  }

  public onSubmit(): void {
    if (this.formDocument.valid) {
      const nom: string = `${this.nom.value}.${this.extension}`;
      const typeFichier: RefTypeFichier = this.typeFichier.value;
      const description: string = this.description.value;

      if (this.estEdition) {
        const fichierDto = Object.assign(new GedFichier(), {
          id: this.fichierEdit.id,
          libelle: nom,
          typeFichier: typeFichier,
          description: description
        });
        this.gedFacade.patchFile(fichierDto).subscribe(() => this.activeModal.close('patch'));
      } else if (this.file && this.uuid) {
        const visibilite: VisibiliteEnum = this.visibilite.value;
        const estPublic: boolean = visibilite !== VisibiliteEnum.PRIVE;
       
        const fichierDto = Object.assign(new GedFichier(), {
          libelle: nom,
          uuid: this.uuid,
          typeFichier: typeFichier,
          description: description,
          visibilite: visibilite,
          estPublic: estPublic
        });
        const notificationUpload:NotificationUpload = Object.assign(new NotificationUpload(), {
          nomDocument: nom
        })
        if(visibilite === VisibiliteEnum.PRIVE) {
          if(this.intervenant.value) {
            fichierDto.intervenants = [this.intervenant.value];
            notificationUpload.idIntervenant = this.intervenant.value;
          }
          if(this.entreprise.value) {
            fichierDto.entreprises = [this.entreprise.value];
            notificationUpload.idEntreprise = this.entreprise.value;
          }
          if(this.refSousType.value) {
            fichierDto.codeSousTypes = [this.refSousType.value];
            notificationUpload.codeRefSousType = this.refSousType.value;
          }
        }
     
        ;  
        this.gedFacade.postFile(fichierDto).subscribe(
          () => {
            this.utilisateursService.sendNotificationsUpload(notificationUpload).subscribe();
            this.activeModal.close('post');
          },
          (error) => {
            // Cas où un fichier porte déjà le même nom
            if (error.error.exception === 'BusinessBadRequestException') {
              // On demande à l'utilisateur s'il veut l'écraser
              this.modalService.confirm(
                this.translateService.instant('page.documents.modals.post-error.content'),
                this.translateService.instant('page.documents.modals.post-error.title'),
                <AdvBootstrapModalOptions>{
                  submitText: this.translateService.instant('label.confirmer'),
                  cancelText: this.translateService.instant('label.annuler')
                }
              ).then(() => {
                this.gedFacade.putFile(fichierDto).subscribe(() => {
                  this.utilisateursService.sendNotificationsUpload(notificationUpload).subscribe();
                  this.activeModal.close('put');
                });
              }, () => {});
            }
          }
        );
      }
    }
  }
  private inervenantsValidator(control: FormControl): { invalid: boolean } {
    return (this.visibilite.value === VisibiliteEnum.PRIVE && !this.refSousType.value && !control.value && !this.estAnpp) 
      ? { invalid: true }
      : null;
  }
  private entreprisesValidator(control: FormControl): { invalid: boolean } {
    return (this.estAnpp && this.visibilite.value === VisibiliteEnum.PRIVE && !this.refSousType.value && !control.value) 
      ? { invalid: true }
      : null;
  }

  private typeDestinatairesValidator(control: FormControl): { invalid: boolean } {
    return (this.visibilite.value === VisibiliteEnum.PRIVE && !this.entreprise.value && !control.value) 
      ? { invalid: true }
      : null;
  }

  private initForm(): void {
    if (this.estEdition && this.fichierEdit) {
      // Moins de champs disponibles dans le cas d'une modification
      let nom = this.fichierEdit.libelle.split('.').slice(0, -1).join('.');
      this.extension = this.fichierEdit.libelle.split(".").pop();
      this.formDocument = this.formBuilder.group({
        nom: [nom, Validators.required],
        typeFichier: [
          this.refTypesFichier.find((typeFichier: RefTypeFichier) => 
            typeFichier.id === this.fichierEdit.typeFichier.id
          ),
          Validators.required
        ],
        description: [this.fichierEdit.description]
      });
    } else {
      this.formDocument = this.formBuilder.group({
        nom: [undefined, Validators.required],
        typeFichier: [null, Validators.required],
        description: [undefined],
        visibilite: [VisibiliteEnum.PUBLIC_UTILISATEURS, Validators.required],
        fichier: [undefined, Validators.required],
        intervenant: [{ value: null, disabled: true }, this.inervenantsValidator.bind(this)],
        entreprise: [{ value: null, disabled: true }, this.entreprisesValidator.bind(this)],
        refSousType: [{ value: null, disabled: true }, this.typeDestinatairesValidator.bind(this)]
      });

      this.visibilite.valueChanges.subscribe((value: VisibiliteEnum) => {
        if (value === VisibiliteEnum.PRIVE) { 
          this.intervenant.enable();
          this.entreprise.enable();
          this.refSousType.enable();
        } else {
          this.intervenant.setValue(null);
          this.intervenant.disable();
          this.entreprise.setValue(null);
          this.entreprise.disable();
          this.refSousType.setValue(null);
          this.refSousType.disable();
        }
      });

      // Ne rend possible la saisie que d'une entreprise ou d'un type d'entreprise destinataire à la fois
      if (this.estAnpp) {
        this.entreprise.valueChanges
          .pipe(distinctUntilChanged()) // Evite l'erreur JS too much recursions
          .subscribe((value: number) => {
            if (value) {
              this.refSousType.setValue(null);
              this.refSousType.disable();
            } else {
              this.refSousType.enable();
            }
          });

        this.refSousType.valueChanges
          .pipe(distinctUntilChanged()) // Evite l'erreur JS too much recursions
          .subscribe((value: number) => {
            if (value) {
              this.entreprise.setValue(null);
              this.entreprise.disable();
            } else {
              this.entreprise.enable();
            }
          });
      }
    }
  }

}
