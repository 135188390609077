import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { remove } from 'lodash';
import { forkJoin } from 'rxjs';
import { Terroir } from 'src/app/data/intervenant/models/terroir.model';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';
import { TerroirsService } from 'src/app/data/intervenant/services/terroirs/terroirs.service';

@Component({
  selector: 'app-liste-terroirs',
  templateUrl: './liste-terroirs.component.html',
  styleUrls: ['./liste-terroirs.component.scss']
})
export class ListeTerroirsComponent implements OnInit {

  terroirs : Terroir[];
  departements : string[];

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalsService : AdvBootstrapModalService,
    private readonly translate: TranslateService,
    private readonly terroirService : TerroirsService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
 

    forkJoin(
      this.terroirService.getTerroirs()
      )
    .pipe(this.loaderService.operator())
    .subscribe(([terroirs]) => {
      this.terroirs = terroirs;    
      
      this.departements = this.terroirs.map(t =>{ return t.departements; }).join(",").split(",");
      
    });

    

  }

  openConfirmationDialog(terroir: Terroir) { 

    this.modalsService.confirm(
      'Veuillez confirmer',
      'Voulez vous supprimer le terroir '+ terroir.libelle + ' ?', {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.deleteTerroir(terroir);
    }, () => { });

  }

  deleteTerroir(terroir: Terroir) {
    this.terroirService.supprimerTerroir(terroir.id).subscribe(() => {
      remove(this.terroirs, terroir);
    });
  }

  editTerroir(terroir: Terroir) {
    this.modalsService.confirm(
      'Veuillez confirmer',
      this.translate.instant(`page.valeur_parametrable.message_modification`), {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.router.navigate(['edit', terroir.id], { relativeTo: this.route });
    }, () => { });
  }

}
