import { JsonProperty, JsonObject, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class ControleSiteEdition {
    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('nom', String)
    @Optional
    nom: string;

    @JsonProperty('adresse', String)
    @Optional
    adresse: string;

    @JsonProperty('codePostal', String)
    @Optional
    codePostal: string;

    @JsonProperty('ville', String)
    @Optional
    ville: string;

    @JsonProperty('telephone', String)
    @Optional
    telephone: string;
}
