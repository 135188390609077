import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { GedFacade } from 'src/app/data/edition/facades/ged.facade';
import { GedOngletEnum } from 'src/app/data/edition/models/enums/ged-onglet.enum';
import { GedFichier } from 'src/app/data/edition/models/ged-fichier.model';
import { UtilisateursService } from 'src/app/data/intervenant/services/utilisateurs/utilisateurs.service';
import { EditDocumentComponent } from './modals/edit-document/edit-document.component';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  public static routes: Routes = [
    {
      path: '', 
      component: DocumentsComponent, 
      data: {
        menu: {
          icon: 'folder',
          libelle: 'menu.documents'
        }
      }
    }
  ];

  public fichiersArchives$ = this.gedFacade.fichiersArchives$;
  public fichiersPubliques$ = this.gedFacade.fichiersPubliques$;
  public fichiersPrives$ = this.gedFacade.fichiersPrives$;
  public gedOngletEnum = GedOngletEnum;
  public selectedTab$ = this.gedFacade.selectedTab$;

  constructor(private gedFacade: GedFacade, 
    private modalService: NgbModal,
    private utilisateursService: UtilisateursService) {}

  public ngOnInit(): void {
    this.utilisateursService.getUtilisateur(SessionContext.get('idIntervenant')).subscribe(utilisateur => {
      this.gedFacade.setIdIntervenant(utilisateur.id);
      if(utilisateur.entreprise) {
        this.gedFacade.setIdEntreprise(utilisateur.entreprise.id);
        this.gedFacade.setCodeRefSousType(utilisateur.entreprise.refSousType.code);
      }
      this.gedFacade.loadFiles();
    });
  }

  public onAddFile(): void {
    this.modalService
      .open(EditDocumentComponent, { size: 'lg', backdrop: 'static' })
      .result.then(() => {
        this.gedFacade.loadFiles();
      }, error => {});
  }

  public onArchiver(id: number): void {
    this.gedFacade.archiveFile(id);
  }

  public onChangeSelectedTab(gedOngletTab: GedOngletEnum): void {
    this.gedFacade.changeSelectedTab(gedOngletTab);
  }

  public onDownloadFile(uuid: string): void {
    this.gedFacade.downloadFile(uuid);
  }

  public onFileChange(event: any, uuid: string): void {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this.gedFacade.putUploadFile(file, uuid).subscribe(
        () => {
          // TODO : toastr
        }, () => {

        }
      );
    }
  }

  public onUpdateFile(file: GedFichier): void {
    const modalRef = this.modalService.open(EditDocumentComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.estEdition = true;
    modalRef.componentInstance.fichierEdit = file;
    modalRef.result.then(() => {
      this.gedFacade.loadFiles();
    }, error => {});
  }

}
