import { Component, OnInit, Input } from '@angular/core';
import { TypeCahierCode } from 'src/app/data/habilitation/models/enums/type-cahier-code.enum';
import { ProduitsService } from 'src/app/data/declaration/services/produits/produits.service';
import { Produit } from 'src/app/data/habilitation/models/produit.model';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';

@Component({
  selector: 'app-recherche-produit',
  templateUrl: './recherche-produit.component.html',
  styleUrls: ['./recherche-produit.component.scss']
})
export class RechercheProduitComponent implements OnInit {

  @Input()
  idOperateur: number;

  @Input()
  typeCahierCode: TypeCahierCode;

  @Input()
  produitBacchus: boolean;

  @Input()
  alreadyPresentIds: number[] = [];

  produits: Produit[];

  searchStringInput: string;
  searchString: string;
  searchStringUpdate = new Subject<string>();
  loaded = false;

  constructor(
    private readonly produitsService: ProduitsService,
    private readonly modal: NgbActiveModal,
    private readonly loader: AdvBootstrapLoaderService
  ) { }

  ngOnInit() {
    this.searchStringUpdate.pipe(
      debounceTime(200),
      distinctUntilChanged())
      .subscribe(value => {
        this.searchString = value;
      });

    setTimeout(() => this.loadData());
  }

  loadData() {
    this.produitsService.getProduits(this.idOperateur, this.produitBacchus, this.typeCahierCode).pipe(
      map(produits => produits.filter(produit => !this.alreadyPresentIds.find(id => id === produit.id))),
      this.loader.operator()
    ).subscribe(produits => {
      this.produits = produits;
      this.loaded = true;
    }, err => {
      this.modal.dismiss(err);
      throw err;
    });
  }

  dismiss() {
    this.modal.dismiss();
  }

  close(produit: Produit) {
    this.modal.close(produit);
  }

}
