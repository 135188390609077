import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { DashBoardDeclaration } from '../../models/dashboard-declaration.model';
import { DashBoardInventaire } from '../../models/dashboard-inventaire.model';
import { DashBoardSurface } from '../../models/dashboard-surface.model';
import { HistogrammeEcoulement } from '../../models/histogramme-ecoulement';
import { HistogrammeRecolte } from '../../models/histogramme-recolte';
import { HistogrammeRendement } from '../../models/histogramme-rendement';
import { HistogrammeStock } from '../../models/histogramme-stock';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private readonly http: HttpClient,
    private readonly mapper: JsonMapperService) { }

  getHistogrammeRecolte(idEntreprise): Observable<HistogrammeRecolte> {
    return this.http.get<any>(`/api/declaration/private/dashboard/${idEntreprise}/histogramme-recoltes`).pipe(
      map(histogrammeRecolte => this.mapper.deserializeObject(histogrammeRecolte, HistogrammeRecolte))
    );        
  }  

  getHistogrammeRendement(idEntreprise): Observable<HistogrammeRendement[]> {
    return this.http.get<Object[]>(`/api/declaration/private/dashboard/${idEntreprise}/histogramme-rendement`)
    .pipe(
      map(histogrammeRendement => this.mapper.deserializeArray(histogrammeRendement, HistogrammeRendement))
    );       
  }  

  getDerniereDateInventaireVerger(idEntreprise): Observable<Date> {
    return this.http.get<Date>(`/api/declaration/private/dashboard/${idEntreprise}/inventaire-verger/derniere-date-maj`);        
  } 

  getDernieresDeclarations(idEntreprise): Observable<DashBoardDeclaration[]> {
    return this.http.get<Object[]>(`/api/declaration/private/dashboard/${idEntreprise}/declarations`)
      .pipe(
        map(declarations => this.mapper.deserializeArray(declarations, DashBoardDeclaration))
      );
  }

  getSurfaces(): Observable<DashBoardSurface[]> {
    return this.http.get<Object[]>(`/api/declaration/private/dashboard/surfaces`)
      .pipe(
        map(surfaces => this.mapper.deserializeArray(surfaces, DashBoardSurface))
      );
  }

  getDerniersInventaireVerger(): Observable<DashBoardInventaire[]> {
    return this.http.get<Object[]>(`/api/declaration/private/dashboard/inventaires-verger`)
      .pipe(
        map(inventaires => this.mapper.deserializeArray(inventaires, DashBoardInventaire))
      );
  }


  getHistogrammeStocks(idEntreprise): Observable<HistogrammeStock[]> {
    return this.http.get<any>(`/api/declaration/private/dashboard/${idEntreprise}/histogramme-stocks`).pipe(
      map(histogramme => this.mapper.deserializeArray(histogramme, HistogrammeStock))
    );        
  }  
  getHistogrammeEcoulements(idEntreprise): Observable<HistogrammeEcoulement[]> {
    return this.http.get<any>(`/api/declaration/private/dashboard/${idEntreprise}/histogramme-ecoulements`).pipe(
      map(histogramme => this.mapper.deserializeArray(histogramme, HistogrammeEcoulement))
    );        
  }  
}
