<div class="content">
  <div class="content-panel">
    <h2 translate>page.facturation.generer-factures.title</h2>
    <form *ngIf="formGroup" [formGroup]="formGroup" #form="ngForm" (ngSubmit)="submit()" novalidate>
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label translate>page.facturation.generer-factures.modeles-facturation</label>
            <select formControlName="modeleFacturation" class="form-control"
              [ngClass]="{'is-invalid': form.submitted && formGroup.get('modeleFacturation').invalid}">
              <option [value]="null" hidden></option>
              <option *ngFor="let modeleFacturation of modelesFacturation" [ngValue]="modeleFacturation">
                {{modeleFacturation.libelle}}</option>
            </select>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label translate>page.facturation.generer-factures.annee</label>
            <input type="number" lang="en" formControlName="annee" class="form-control"
              [ngClass]="{'is-invalid': form.submitted && formGroup.get('annee').invalid}" />
          </div>
        </div>

        <div class="col-md-1">
          <div class="form-group">
            <label translate>
              <input type="radio" formControlName="modeRecherche" name="modeRecherche" [value]="false" />
              page.facturation.generer-factures.non-facture
            </label>
            <label translate>
              <input type="radio" formControlName="modeRecherche" name="modeRecherche" [value]="true" />
              page.facturation.generer-factures.facture
            </label>
          </div>
        </div>

        <div class="col-md-1">
          <button type="submit" class="btn btn-primary" translate>label.rechercher</button>
        </div>
      </div>
    </form>

    <div class="col-md-1" *ngIf="!modeRecherche && submitted && objetsFacture.length > 0">
      <button style="margin-bottom: 5px;" type="button" class="btn btn-primary btn-facture" (click)="facturer()"
        translate>page.facturation.generer-factures.facturer</button>
    </div>

    <table class="table table-striped" *ngIf="objetsFacture.length > 0 && loaded; else aucunObjetsFacture">
      <thead>
        <tr>
          <th *ngIf="!modeRecherche"></th>
          <th translate>page.facturation.generer-factures.table.header.identifiant</th>
          <th translate>page.facturation.generer-factures.table.header.annee</th>
          <th translate>page.facturation.generer-factures.table.header.quantite</th>
          <th translate>page.facturation.generer-factures.table.header.operateur</th>
          <th translate *ngIf="modeRecherche">page.facturation.generer-factures.table.header.details</th>
        </tr>
      </thead>

      <tbody *ngIf="!currentModeleFacturation.isGroupeOperateur">
        <tr *ngFor="let objetFacture of objetsFactureTriNormal; let i = index">
          <td *ngIf="!modeRecherche">
            <!--input type="checkbox" [(ngModel)]="objetFacture[i].checked"-->
            <input type="checkbox" [(ngModel)]="objetsFactureTriNormal[i].checked">
          </td>
          <td>
            {{objetFacture.fonctionnelId}}
          </td>
          <td>{{objetFacture.annee}}</td>
          <td>
            <ul *ngFor="let qteInfos of objetFacture.quantiteInfos">
              {{qteInfos.refQuantiteFacture.libelle + ' : ' + qteInfos.quantite}}
            </ul>
          </td>
          <td>{{objetFacture.raisonSocialeOperateur}}</td>
          <td *ngIf="modeRecherche">
            <span>
              <fa-icon class="clickable-icon" icon="info-circle"
                (click)="getDonneesFacturation(objetFacture.idsToSend)">
              </fa-icon>
            </span>
          </td>
        </tr>
      </tbody>


      <tbody *ngIf="currentModeleFacturation.isGroupeOperateur">
        <tr *ngFor="let objetFacture of objetsFactureTriOperateur; let i = index">
          <td *ngIf="!modeRecherche">
            <input type="checkbox" [(ngModel)]="objetsFactureTriOperateur[i].checked">
          </td>
          <td>
            <ul *ngFor="let fctId of objetFacture.fonctionnelsId">
              <li>
                {{fctId}}
              </li>
            </ul>
          </td>
          <td>{{objetFacture.annee}}</td>
          <td>
            <ul *ngFor="let qteInfosOpGroupe of objetFacture.quantiteInfosOperateurGroupe">
              <li>
                {{qteInfosOpGroupe.refQuantiteFacture.libelle + ' : ' + qteInfosOpGroupe.quantite}}
              </li>
            </ul>
          </td>
          <td>{{objetFacture.raisonSocialeOperateur}}</td>
          <td *ngIf="modeRecherche">
            <span>
              <fa-icon class="clickable-icon" icon="info-circle"
                (click)="getDonneesFacturation(objetFacture.idsToSend)">
              </fa-icon>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #aucunObjetsFacture>
  <div translate>page.facturation.generer-factures.aucunObjetFacture</div>
</ng-template>