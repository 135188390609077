import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EcoulementsService } from 'src/app/data/declaration/services/ecoulements/ecoulements.service';
import { EstimationEcoulement } from 'src/app/data/suivis/models/estimation-ecoulement.model';

@Component({
  selector: 'app-estimation-ecoulement',
  templateUrl: './estimation-ecoulement.component.html',
  styleUrls: ['./estimation-ecoulement.component.scss']
})
export class EstimationEcoulementComponent implements OnInit {
  estimations: EstimationEcoulement[];

  constructor(   
    private activeModal: NgbActiveModal, 
    private readonly ecoulementService: EcoulementsService,
    ) { }

  ngOnInit() {
    this.ecoulementService.getEstimationEcoulements().subscribe(r=>{
      this.estimations =       
          r.sort((a, b) => {
            return a.sorting().localeCompare(b.sorting());
          });;
    });

  }


  public onClose(): void {
    this.activeModal.dismiss();
  }

}
