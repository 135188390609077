import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { DREVSaisie } from './drevsaisie.model';
import { Produit } from '../../habilitation/models/produit.model';

@JsonObject
export class DeclarationRevendication {
  @JsonProperty('codeProduit', String)
  codeProduit: string;

  @JsonProperty('mention', String)
  @Optional
  mention: string;

  @JsonProperty('surface', Number)
  surface: number;

  @JsonProperty('volumeNetRevendiqueEncours', Number)
  volumeNetRevendiqueEncours: number;

  /**
   * VCI déclaré l'année précédente != stock VCI réel
   */
  @JsonProperty('vciPasse', Number)
  vciPasse: number;

  @JsonProperty('vinsEnCours', Number)
  vinsEnCours: number;

  @JsonProperty('reserveVsiLiberee', Number)
  reserveVsiLiberee: number;

  @JsonProperty('vciEnCours', Number)
  vciEnCours: number;

  @JsonProperty('vsi', Number)
  vsi: number;

  drevSaisie: DREVSaisie = new DREVSaisie();
  produit: Produit = new Produit();

  /**
   * Retourne le stock VCI de l'année N-1 d'une déclaration
   * @returns Un Number
   */
  public getStockVciLastYear(): number {
    return (this.drevSaisie.totalVciUtilise) ?
      this.drevSaisie.totalVciUtilise : this.vciPasse;
  }

  /**
   * Retourne le stock de vin de l'année courant d'une déclaration
   * @returns Un Number
   */
  public getStockWineCurrentYear(): number {
    return (
      this.vinsEnCours
      - this.drevSaisie.vciRemplacement
      - this.drevSaisie.vciSubstitution
    );
  }

  /**
   * Retourne le stock VCI courant d'une déclaration
   * @returns Un Number
   */
  public getStockVciCurrentYear(): number {
    return (this.vciEnCours + this.drevSaisie.vciRemplacement);
  }

  /**
   * Affecte une saisie d'un VCI à une DREV et calcule le volume net
   * revendiqué pour l'année en cours
   * @param saisie La saisie d'un VCI à affecter
   */
  public setSaisieVci(saisie: DREVSaisie): void {
    this.drevSaisie = saisie;
    this.calculateVolumeNetRevendique();
  }

  /** Calcule le volume net revendiqué pour l'année en cours */
  private calculateVolumeNetRevendique(): void {
    this.volumeNetRevendiqueEncours = (
      this.drevSaisie.totalVciUtilise +
      this.getStockWineCurrentYear() +
      this.reserveVsiLiberee
    );
  }
}
