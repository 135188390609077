<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
      page.tournees.edit.modals.echantillons.title
    </h4>
  </div>

    <div class="modal-body" *ngIf="loaded">
      <div class="row">

      <div class="col">
        <app-search-bar [(value)]="searchString" *ngIf="items.length > 0"></app-search-bar>
      </div>   
      </div>
      <table class="table" *ngIf="items.length > 0; else aucunJure">
        <thead>
              <!-- <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'item.produit.libelleCahier', libelle:'page.tournees.edit.modals.echantillons.table.header.cahier'}"></ng-container> -->
              <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'item.produit.libelle', libelle:'page.tournees.edit.modals.echantillons.table.header.produit'}"></ng-container>
              <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'item.millesime', libelle:'page.tournees.edit.modals.echantillons.table.header.millesime'}"></ng-container>
              <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'item.operateur.siret', libelle:'page.tournees.edit.modals.echantillons.table.header.operateur'}"></ng-container>
              <th translate>page.tournees.edit.modals.echantillons.table.header.volume</th>
              <th translate>page.tournees.edit.modals.echantillons.table.header.logement</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of items | filter : '*': searchString | orderBy:order.property:order.reverse:true" [ngClass]="{'selected':item.selected, 'disabled':item.disabled}" (click)="toggleSelectionItem(item)">
                <!-- <td>{{item.item.produit?.libelleCahier}}</td> -->
                <td>{{item.item.produit?.libelle}}</td>
                <td>{{item.item.millesime}}</td>
                <td>{{item.item.operateur?.siret}} - {{item.item.operateur?.raisonSociale}}</td>
                <td>{{item.item.volume}}</td>
                <td>{{item.item.logement}}</td>
              </tr>
            </tbody>
          </table>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-light" (click)="cancel()" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary" (click)="submit()" [disabled]="selectedItems.length === 0" [translateParams]="{nb:selectedItems.length}" translate>page.tournees.edit.modals.echantillons.valider</button>
      </div>
<!--  -->


<ng-template #aucunJure>
    <div translate>page.tournees.edit.modals.echantillons.table.aucun_echantillon</div>
</ng-template>

<ng-template #tableHeader let-property="sortProperty" let-libelle="libelle">
  <th *ngIf="property" (click)="setOrder(property)" [class.sortable]="property">
    {{libelle | translate}}
    <fa-icon *ngIf="property" [icon]="getIcon(property)"></fa-icon>
  </th>
  <th *ngIf="!property">{{libelle | translate}}</th>
</ng-template>
