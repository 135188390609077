import { Component, OnInit, Input } from '@angular/core';
import { PaginateResponseStream } from '../../services/pagination/pagination.service';

type PaginationInfos = PaginateResponseStream<any>['pagination'];

@Component({
  selector: 'app-pagination-bar',
  templateUrl: './pagination-bar.component.html',
  styleUrls: ['./pagination-bar.component.scss']
})
export class PaginationBarComponent implements OnInit {

  @Input()
  pagination: PaginationInfos;

  constructor() { }

  ngOnInit() {
  }



}
