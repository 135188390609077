import { JsonObject, JsonProperty, Optional, CustomConverter } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { OrganismeOptionalConverter } from './converters/organisme.converter';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { Organisme } from './organisme.model';
import { UtilisateurType } from './utilisateur-type.model';
import { UtilisateurBase } from './utilisateur-base.model';

@JsonObject
export class Utilisateur extends UtilisateurBase {
  @JsonProperty('actif', Boolean)
  actif: boolean = undefined;

  @JsonProperty('organismes', [Number])
  @CustomConverter(OrganismeOptionalConverter)
  @Optional
  public organismes: Organisme[] = [];

  @JsonProperty('dateCreation', String)
  @CustomConverter(DateConverter)
  @Optional
  dateCreation: moment.Moment = undefined;
}
