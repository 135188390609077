import { CustomConverter, JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { CodeEspece } from './enums/code-espece.enum';

@JsonObject
export class Ecoulement {

    @JsonProperty('idRecolte', Number)
    @Optional
    id: number = undefined;

    @JsonProperty('idEspece', Number)
    @Optional
    public idEspece: number = null;
    @JsonProperty('codeEspece', String)
    public codeEspece: string = null;

    @JsonProperty('idGroupeVarietal', Number)
    idGroupeVarietal: number = null;
    @JsonProperty('codeGroupeVarietal', String)
    codeGroupeVarietal: string;
    @JsonProperty('groupeVarietal', String)
    groupeVarietal: string = null;



    @JsonProperty('qteTotaleFrance', Number)
    @Optional
    qteTotaleFrance: number = null;
    @JsonProperty('qteBioFrance', Number)
    @Optional
    qteBioFrance: number = null;

    @JsonProperty('qteTotaleExport', Number)
    @Optional
    qteTotaleExport: number = null;
    @JsonProperty('qteBioExport', Number)
    @Optional
    qteBioExport: number = null;


    @JsonProperty('qteTotaleIndustrie', Number)
    @Optional
    qteTotaleIndustrie: number = null;
    @JsonProperty('qteBioIndustrie', Number)
    @Optional
    qteBioIndustrie: number = null;


    @JsonProperty('qteTotaleFreinte', Number)
    @Optional
    qteTotaleFreinte: number = null;
    @JsonProperty('qteBioFreinte', Number)
    @Optional
    qteBioFreinte: number = null;



    public qteTotale(): number {
        return (this.qteTotaleFrance?this.qteTotaleFrance:0) + (this.qteTotaleExport?this.qteTotaleExport:0) + (this.qteTotaleIndustrie?this.qteTotaleIndustrie:0) + (this.qteTotaleFreinte?this.qteTotaleFreinte:0);
    }
    qteBio(): number {
        return this.qteBioFrance + this.qteBioExport + this.qteBioIndustrie + this.qteBioFreinte;
    }


    qteTotaleFrancePC() : number {
        return (this.qteTotale() ? this.qteTotaleFrance / this.qteTotale() : undefined);
    };
    qteBioFrancePC() : number {
        return (this.qteBio() ? this.qteBioFrance / this.qteBio() : undefined);
    };
    qteTotaleExportPC() : number {
        return (this.qteTotale() ? this.qteTotaleExport / this.qteTotale() : undefined);
    };
    qteBioExportPC() : number {
        return (this.qteBio() ? this.qteBioExport / this.qteBio() : undefined);
    };
    qteTotaleIndustriePC() : number {
        return (this.qteTotale() ? this.qteTotaleIndustrie / this.qteTotale() : undefined);
    };
    qteBioIndustriePC() : number {
        return (this.qteBio() ? this.qteBioIndustrie / this.qteBio() : undefined);
    };
    qteTotaleFreintePC() : number {
        return (this.qteTotale() ? this.qteTotaleFreinte / this.qteTotale() : undefined);
    };
    qteBioFreintePC() : number {
        return (this.qteBio() ? this.qteBioFreinte / this.qteBio() : undefined);
    };


    isTotal: boolean = false;
    isAutre(): boolean {
        return ['autVarpomme', 'autVarpoir', 'autVarpoire'].indexOf(this.codeGroupeVarietal) > -1
    };

    sorting(): string {
        var x =
            ''.concat(
                this.codeEspece === CodeEspece.POMME ? '0' : (this.codeEspece === CodeEspece.POIRE ? '1' : '2'),
                this.isTotal ? '1' : '0',
                this.isAutre() ? '1' : '0',
                this.groupeVarietal.toUpperCase());
        return x;
    }
}