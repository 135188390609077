import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { SyntheseInventaireEspeceEdition } from './synthese-inventaire-espece-edition';

@JsonObject
export class SyntheseInventaireEdition {

    @JsonProperty('campagne', Number)
    idEntreprise: Number;

    @JsonProperty('campagne', String)
    campagne: String;

    @JsonProperty('dateInventaire', String)
    @Optional
    dateInventaire: string;

    @JsonProperty('raisonExploitation', String)
    @Optional
    raisonExploitation: string;

    @JsonProperty('raisonGp', String)
    @Optional
    raisonGp: string;

    @JsonProperty('adresse', String)
    @Optional
    adresse: string;

    @JsonProperty('commune', String)
    @Optional
    commune: string;

    @JsonProperty('siret', String)
    @Optional
    siret: string;

    @JsonProperty('telephone', String)
    @Optional
    telephone: string;

    @JsonProperty('mail', String)
    @Optional
    mail: string;

    @JsonProperty('tva', String)
    @Optional
    tva: string;

    @JsonProperty('estEcoResp', Boolean)
    @Optional
    estEcoResp: boolean;

    @JsonProperty('estBio', Boolean)
    @Optional
    estBio: boolean;

    @JsonProperty('syntheseInventaireEspeceEditionList', [SyntheseInventaireEspeceEdition])
    @Optional
    syntheseInventaireEspeceEditionList: SyntheseInventaireEspeceEdition[];

    @JsonProperty('surfaceTotale', Number)
    @Optional
    surfaceTotale: number

    @JsonProperty('surfaceIrriguee', Number)
    @Optional
    surfaceIrriguee: number

    @JsonProperty('surfaceGrele', Number)
    @Optional
    surfaceGrele: number

    @JsonProperty('surfaceGel', Number)
    @Optional
    surfaceGel: number

    @JsonProperty('surfaceBio', Number)
    @Optional
    surfaceBio: number

}
