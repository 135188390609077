import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthorizationInterceptor } from 'src/app/shared/interceptors/http-token.interceptor';
import { HttpRequest } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Injectable()
export class TokenInterceptor extends AuthorizationInterceptor {

  constructor(
    private readonly authService: AuthService
  ) {
    super();
  }

  buildHeaders(token: string): { [name: string]: string | string[] } {
    // console.debug('[TokenInterceptor] : add token');
    return { Authorization: 'Bearer ' + token };
  }
  getToken(): string {
    // console.debug('[TokenInterceptor] : get token');
    return this.authService.getToken();
  }
  refreshToken(): Observable<string> {
    // console.debug('[TokenInterceptor] : refresh token');
    return this.authService.refreshToken();
  }
  logOut(): void {
    this.authService.logOut();
  }
  applyHeadersFilter(req: HttpRequest<any>): boolean {
    return req.url.indexOf('/public') === -1 && req.url.indexOf('/api') !== -1 && !!this.getToken();
  }
}
