import {Any, JsonObject, JsonProperty} from '@thorolf/json-ts-mapper';
import { ComplementPoPdfVer } from '../../intervenant/models/complementPoPdfVer.model';

@JsonObject
export class InfosPoSurface {

  @JsonProperty('listPoSurfacePomme', Any)
  listPoSurfacePomme: Map<number, number>;

  @JsonProperty('listPoSurfacePoire', Any)
  listPoSurfacePoire: Map<number, number>;

  @JsonProperty('listComplementPoPdfVer', Any)
  listComplementPoPdfVer: ComplementPoPdfVer[];
}
