import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { DeclarationDrBase } from './declaration-dr.base.model';

@JsonObject
export class DeclarationSV12 extends DeclarationDrBase {
  @JsonProperty('s_recolte', Number)
  s_recolte: number;

  @JsonProperty('qte_achat_vf', Number)
  qte_achat_vf: number;

  @JsonProperty('vol_achat_m', Number)
  vol_achat_m: number;

  @JsonProperty('vol_issu_vf', Number)
  vol_issu_vf: number;

  @JsonProperty('vol_issu_m', Number)
  vol_issu_m: number;

  @JsonProperty('list', [DeclarationSV12])
  @Optional
  details: DeclarationSV12[] = [];
}
