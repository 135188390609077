import { JsonObject, JsonProperty, Optional, CustomConverter } from '@thorolf/json-ts-mapper';

import * as moment from 'moment';

import { DateTimeConverter } from 'src/app/core/services/mapper/converters';

@JsonObject
export class Jwt {

  @JsonProperty('token', String)
  token: string = undefined;

  @JsonProperty('refreshToken', String)
  refreshToken: string = undefined;

  @JsonProperty('tokenTTL', String)
  @CustomConverter(DateTimeConverter)
  tokenTTL: moment.Moment = undefined;

  @JsonProperty('refreshTokenTTL', String)
  @CustomConverter(DateTimeConverter)
  refreshTokenTTL: moment.Moment = undefined;

  @JsonProperty('type', String)
  @Optional
  type: string = undefined;

  @JsonProperty('roles', [String])
  @Optional
  roles: string[] = [];

  @JsonProperty('idIntervenant', Number)
  @Optional
  idIntervenant: number = undefined;

  @JsonProperty('mailIntervenant', String)
  @Optional
  mailIntervenant: string = undefined;

  @JsonProperty('idPortail', Number)
  @Optional
  idPortail: number = undefined;

  @JsonProperty('codePortail', String)
  @Optional
  codePortail: string = undefined;
}
