import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Variete} from '../../models/variete.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VarieteService {

  constructor(private readonly http: HttpClient) {
  }
  getVarietes(): Observable<Variete[]> {
    return this.http.get<Variete[]>(`/api/declaration/private/varietes`);
  }

  getVariete(idVariete: number): Observable<Variete> {
    return this.http.get<Variete>(`/api/declaration/private/varietes/${idVariete}`);
  }

  getVarietesClone(): Observable<Variete[]> {
    return this.http.get<Variete[]>(`/api/declaration/private/varietesClone`);
  }

  getClonesByVariete(idVariete: number): Observable<Variete[]> {
    return this.http.get<Variete[]>(`/api/declaration/private/varietes/${idVariete}/varietesClone`);
  }

  getVarietesEspece(idEspece: number): Observable<Variete[]> {
    return this.http.get<Variete[]>(`/api/declaration/private/especes/${idEspece}/varietesEspece`);
  }

  public creerVariete(variete: Variete): Observable<number> {
    return this.http.post(`/api/declaration/private/varietes`, variete, { observe: 'response' }).pipe(
      map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
    );
  }

  public modifierVariete(variete: Variete ): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/varietes/${variete.id}`, variete );
  }

  public desactiverVariete(variete: Variete): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/varietes/${variete.id}?desactivation=${true}`, variete );
  }


}
