import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Espece} from '../../models/espece.model';

@Injectable({
  providedIn: 'root'
})
export class EspeceService {

  constructor(private readonly http: HttpClient) {
  }

  getEspeces(): Observable<Espece[]> {
    return this.http.get<Espece[]>(`/api/declaration/private/especes`);
  }


}
