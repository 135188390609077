import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RefForme} from '../../models/ref-forme.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RefFormeService {

  constructor( private readonly http: HttpClient) { }

  getRefForme(): Observable<RefForme[]> {
    return this.http.get<RefForme[]>(`/api/declaration/private/referentielForme`);
  }

  public creerRefForme(forme: RefForme): Observable<number> {
    return this.http.post(`/api/declaration/private/referentielForme`, forme, { observe: 'response' }).pipe(
      map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
    );
  }  
  
  public modifierRefForme(forme: RefForme ): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/referentielForme/${forme.id}`, forme );
  }
  
  public desactiverRefForme(forme: RefForme): Observable<void> {
    return this.http.put<void>(`/api/declaration/private/referentielForme/${forme.id}?desactivation=${true}`, forme );
  }
}
