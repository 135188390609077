import { Component, Input, OnInit } from '@angular/core';

import { StatutHabilitation } from 'src/app/data/habilitation/models/statut-habilitation.model';

@Component({
  selector: 'app-historique-item',
  templateUrl: './historique-item.component.html',
  styleUrls: ['./historique-item.component.scss']
})
export class HistoriqueItemComponent implements OnInit {
  @Input() statut: StatutHabilitation;

  constructor() { }

  ngOnInit() {
  }
}
