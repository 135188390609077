import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { find } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';

import { UtilisateurType } from 'src/app/data/intervenant/models/utilisateur-type.model';
import { Utilisateur } from 'src/app/data/intervenant/models/utilisateur.model';
import { UtilisateursService } from 'src/app/data/intervenant/services/utilisateurs/utilisateurs.service';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';
import { UtilisateurTypeCode } from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { map } from 'rxjs/operators';
import { UtilisateurBase } from 'src/app/data/intervenant/models/utilisateur-base.model';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { RefCampagnesService } from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';
import { EntrepriseDroit } from 'src/app/data/intervenant/models/entreprise-droit.model';

@Component({
  selector: 'app-edit-utilisateur',
  templateUrl: './edit-utilisateur.component.html',
  styleUrls: ['./edit-utilisateur.component.scss']
})
export class EditUtilisateurComponent implements OnInit {

  private canEdit = false;
  public formGroup: FormGroup;
  public creation = false;
  public isSalarieAnpp = false;
  public showEntreprise = false;
  public isUtilisateurAnpp = false;
  public isConseillerTechnique = false;
  public disableIsCt = false;
  public title: string;
  public types: UtilisateurType[];
  public entreprises: Entreprise[];
  public utilisateur: Utilisateur;

  get email() { return this.formGroup.get('email'); }
  get nom() { return this.formGroup.get('nom'); }
  get prenom() { return this.formGroup.get('prenom'); }
  get type() { return this.formGroup.get('type'); }
  get entreprise() { return this.formGroup.get('entreprise'); }

  constructor(
    private readonly authService: AuthService,
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly utilisateursService: UtilisateursService,
    private readonly entreprisesService: EntreprisesService,
    private readonly refCampagnesService: RefCampagnesService
  ) { }

  public ngOnInit(): void {
    const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    if (!id) {
      this.creation = true;
    }

    this.canEdit = this.authService.hasRole('INTERVENANT_UTILISATEUR_ECRITURE');

    this.formGroup = this.fb.group({
      email: [{ value: undefined, disabled: true }, { updateOn: 'blur' }],
      nom: [{ value: undefined, disabled: true }, Validators.required],
      prenom: [{ value: undefined, disabled: true }, Validators.required],
      type: [undefined, Validators.required],
      entreprise: [undefined]
    });
    this.email.setValidators([Validators.email, Validators.required]);
    this.email.updateValueAndValidity();
    if (this.isAnpp()) {
      this.entreprise.setValidators([Validators.required]);
      this.entreprise.updateValueAndValidity();
      if (!this.isSalarieAnpp) {
        this.showEntreprise = true;
      }
    }

    this.type.valueChanges.subscribe(v => {
      if (!!v) {
        this.email.enable();
        this.nom.enable();
        this.prenom.enable();
      } else {
        this.email.disable();
        this.nom.disable();
        this.prenom.disable();
      }
    });

    this.email.valueChanges.subscribe(() => this.checkUser());

    if (!this.canEdit) {
      this.formGroup.disable();
    }
    this.entreprisesService.getEntreprises().pipe(this.loaderService.operator()).subscribe(entreprises => {
      this.entreprises = entreprises;
      setTimeout(() => { this.loadData(id); });
    });
  }

  public onSubmit(): void {
    if (this.formGroup.valid) {
      this.utilisateur.email = this.email.value;
      this.utilisateur.nom = this.nom.value;
      this.utilisateur.prenom = this.prenom.value;
      this.utilisateur.type = this.type.value;
      this.utilisateur.entreprise = this.entreprise.value;
      this.utilisateur.utilisateurAnpp = this.isUtilisateurAnpp;
      this.utilisateur.estConseillerTechnique = this.isConseillerTechnique;
      let obs: Observable<number>;
      if (this.utilisateur.id == null) {
        obs = this.utilisateursService.createUtilisateur(Object.assign(new UtilisateurBase(), this.utilisateur));
      } else {
        obs = this.utilisateursService.updateUtilisateur(this.utilisateur).pipe(
          map(() => this.utilisateur.id)
        );
      }
      obs.pipe(
        this.loaderService.operator()
      ).subscribe(idUtilisateur => {
        if (this.utilisateur.entreprise) {
          this.enregistrerDroitsEntreprise(this.utilisateur.entreprise);
        }
        if (this.authService.isAnpp()) {
          this.router.navigate(['.'], { relativeTo: this.route.parent });
        } else if (this.creation && (this.utilisateur.type.code === UtilisateurTypeCode.ORGANISME) && !this.isUtilisateurAnpp) {
          this.router.navigate([idUtilisateur, 'droits'], { relativeTo: this.route.parent });
        } else {
          this.router.navigate(['.'], { relativeTo: this.route.parent });
        }
      }, (error) => { 
        if (error.error && error.error.code === 'INTERVENANT_BACCHUS_BUSINESS_USER_TYPE') {
          this.throwAlreadyUsedEmailError(() => 
            this.email.setValue(undefined)
          );
        } else {
          throw error;
        }
      });
    }
  }

  public isAnpp(): boolean {
    return this.authService.isAnpp();
  }

  public onSalarieAnppCheckedChange(event: any): void {
    if (event.target.value === 'OUI') {
      this.changeEntrepriseValidator(false);
    } else {
      this.changeEntrepriseValidator(true);
    }
  }


  public onUtilisateurAnppCheckedChange(event: any): void {
    if (event.target.value === 'OUI') {
      this.isUtilisateurAnpp = true;
    } else {
      this.isUtilisateurAnpp = false;
    }
  }

  public onEstConseillerTechniqueCheckedChange(event: any): void {
    if (event.target.value === 'OUI') {
      this.isConseillerTechnique = true;
    } else {
      this.isConseillerTechnique = false;
    }
  }


  public onTypeChange(event: any): void {
    const utilisateurType = event.value as UtilisateurType;
    if (utilisateurType.code == 'PI' || utilisateurType.code == 'PO' || utilisateurType.code == 'GP') {
      this.changeEntrepriseValidator(true);
    } else {
      this.changeEntrepriseValidator(false);
    }
  }


  private changeEntrepriseValidator(activer: boolean): void {
    if (activer) {
      this.isSalarieAnpp = false;
      this.showEntreprise = true;
      this.entreprise.setValidators([Validators.required]);
      this.entreprise.updateValueAndValidity();
    } else {
      this.isSalarieAnpp = true;
      this.showEntreprise = false;
      this.entreprise.clearValidators();
      this.entreprise.updateValueAndValidity();
    }
  }

  public onEntrepriseChange(entreprise: Entreprise) {
    if(entreprise.refSousType.code == 'CT_DEFAUT') {
      this.isConseillerTechnique = true;
      this.disableIsCt = true;
    } else {
      this.disableIsCt = false;
    }
  }
  private checkUser(): void {
    if (this.creation && this.email.valid) {
      const email = this.email.value;

      if (email != null) {
        this.utilisateursService.getUtilisateursByEmail(email, this.type.value.code).pipe(
          this.loaderService.operator()
        ).subscribe(utilisateur => {
          this.modalsService.confirm(
            this.translate.instant(`page.admin.utilisateur.form.utilisateur-existant.message`),
            this.translate.instant(`page.admin.utilisateur.form.utilisateur-existant.title`), {
            cancelText: this.translate.instant(`label.annuler`),
            submitText: this.translate.instant(`label.valider`)
          }).then(() => {
            this.utilisateur.id = utilisateur.id;

            this.nom.setValue(utilisateur.nom);
            this.prenom.setValue(utilisateur.prenom);

            this.email.disable({ emitEvent: false, onlySelf: true });
            this.type.disable({ emitEvent: false, onlySelf: true });
          }, () => {
            this.email.setValue(undefined);
          });
        }, error => {
          if (error.status === 404) {
            return;
          } else if (error.error && error.error.code === 'INTERVENANT_BACCHUS_BUSINESS_USER_TYPE') {
            this.throwAlreadyUsedEmailError(() => 
              this.email.setValue(undefined)
            );
          } else {
            throw error;
          }
        });
      }
    }
  }

  private enregistrerDroitsEntreprise(entreprise: Entreprise): void {
    const entrepriseDroitList: EntrepriseDroit[] = [];
    this.refCampagnesService.getCampagneEnCours().subscribe((campagneEncours) => {
      this.refCampagnesService.getParametreCampagneByEntreprise(campagneEncours.id, entreprise.id).subscribe(parametreCampagne => {
        let entrepriseDroit: EntrepriseDroit = new EntrepriseDroit();
        entrepriseDroit.id = entreprise.id;
        entrepriseDroit.droitRecolte = parametreCampagne?parametreCampagne.droitRecolte: false;
        entrepriseDroit.droitStock = parametreCampagne?parametreCampagne.droitStock: false;
        entrepriseDroit.droitEcoulement = parametreCampagne?parametreCampagne.droitEcoulement: false;
        entrepriseDroitList.push(entrepriseDroit);
        this.entreprisesService.enregistrerDroits(entrepriseDroitList).subscribe();
      });
    });
  }

  private loadData(id: number): void {
    this.types = ReferencesService.getReference('typesUtilisateurs');

    if (this.creation) {
      this.utilisateur = new Utilisateur();
      this.utilisateur.actif = true;

      if (SessionContext.get('utilisateurType') !== UtilisateurTypeCode.ADMIN) {
        this.type.setValue(find(this.types, item => item.code === UtilisateurTypeCode.ORGANISME));
        this.type.disable();
      }
      this.title = 'page.admin.utilisateur.form.title-add';
    } else {
      this.utilisateursService.getUtilisateur(+id).pipe(
        this.loaderService.operator()
      ).subscribe((utilisateur: Utilisateur) => {
        this.utilisateur = utilisateur;

        this.email.setValue(this.utilisateur.email);
        this.nom.setValue(this.utilisateur.nom);
        this.prenom.setValue(this.utilisateur.prenom);
        this.type.setValue(this.utilisateur.type);
        this.isUtilisateurAnpp = this.utilisateur.utilisateurAnpp;
        this.isConseillerTechnique = this.utilisateur.estConseillerTechnique;
        if (this.utilisateur.entreprise) {
          const entre = this.entreprises.find(entreprise => entreprise.id === this.utilisateur.entreprise.id);
          this.entreprise.setValue(entre);
          this.showEntreprise = true;
          this.entreprise.setValidators([Validators.required]);
          this.onEntrepriseChange(this.utilisateur.entreprise);
        }
        if (this.isAnpp() && !this.utilisateur.entreprise) {
          this.isSalarieAnpp = true;
          this.showEntreprise = false;
          this.entreprise.clearValidators();
        }
       

        this.type.disable({ emitEvent: false, onlySelf: true });
        if (!utilisateur.utilisateurAnpp) {
          this.email.disable({ emitEvent: false, onlySelf: true });
        }

        this.title = 'page.admin.utilisateur.form.title-edit';
      });
    }
  }

  private throwAlreadyUsedEmailError(callback: () => void): void {
    this.modalsService.error(
      this.translate.instant(`page.admin.utilisateur.form.utilisateur-existant.erreur`),
      this.translate.instant(`page.admin.utilisateur.form.utilisateur-existant.title`), {
        cancelText: this.translate.instant(`label.annuler`)
      }
    ).then(() => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  }

}
