import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class ContactReferent {
  @JsonProperty('id', Number)
  public id: number = undefined;

  @JsonProperty('nom', String)
  public nom: string = undefined;

  @JsonProperty('prenom', String)
  public prenom: string = undefined;

  @JsonProperty('mail', String)
  public mail: string = undefined;

  @JsonProperty('telephone', String)
  @Optional
  public telephone: string = undefined;
}
