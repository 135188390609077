import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { IAppConfigApp } from './models/config-app.model';

@Injectable({
  providedIn: 'root'
})
export class AppConfig {
  // public env: IAppConfigEnv;
  public app: IAppConfigApp;
  constructor(
    private readonly http: HttpClient
  ) { }

  load() {
    return forkJoin(
      // this.loadFile<IAppConfigEnv>(`assets/config/config.env.json`).pipe(map((data: IAppConfigEnv) => { this.env = data; })),
      this.http.get<IAppConfigApp>(`assets/config/config.app.json`).pipe(map((data: IAppConfigApp) => { this.app = data; }))
    ).toPromise();
  }
}
