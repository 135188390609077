import { JsonObject, JsonProperty, Any } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';

import { DateTimeConverter } from 'src/app/core/services/mapper/converters';

import { Navigator } from './navigator.model';

@JsonObject
export class LogError {
  constructor() {
    this.navigator = new Navigator();
  }

  @JsonProperty('message', String)
  public message: string = undefined;

  @JsonProperty('date', DateTimeConverter)
  public date: moment.Moment = undefined;

  @JsonProperty('navigator', Navigator)
  public navigator: Navigator = undefined;

  @JsonProperty('stackTrace', Any)
  public stackTrace: any = undefined;
}
