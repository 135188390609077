<div>
  <div class="modal-header">
    <h4 class="modal-title">Conditionnement</h4>
  </div>

  <div class="modal-body">
    <form [formGroup]="formSaisieConditionnement" #form="ngForm" (ngSubmit)="submit()">
      <div class="cols">
        <div class="col">
          <div class="form-group">
            <label for="produit">{{ 'page.declarations.synthese.modal.saisirConditionnement.produit' | translate }}</label>
            <input type="text" formControlName="produit" id="produit" name="produit" class="form-control" />
          </div>

          <div class="form-group">
            <label for="date">{{ 'page.declarations.synthese.modal.saisirConditionnement.dateConditionnement' | translate }}</label>
            <div class="input-group">
              <input type="text" formControlName="date" id="date" name="date" class="form-control" ngbDatepicker #datePicker="ngbDatepicker" required [ngClass]="{'is-invalid': form.submitted && date.invalid, 'select-placeholder' : (date.value === null && !date.dirty)}" />
              <div class="input-group-append">
                <button type="button" (click)="datePicker.toggle()" class="btn btn-secondary">
                  <fa-icon icon="calendar-alt"></fa-icon>
                </button>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="logement">{{ 'page.declarations.synthese.modal.saisirConditionnement.logement' | translate }}</label>
            <textarea formControlName="logement" id="logement" name="logement" class="form-control" required [ngClass]="{'is-invalid': form.submitted && logement.invalid, 'select-placeholder' : (logement.value === null && !logement.dirty)}"></textarea>
          </div>

          <div class="form-group">
            <label for="lot">{{ 'page.declarations.synthese.modal.saisirConditionnement.numeroLot' | translate }}</label>
            <input type="text" formControlName="lot" id="lot" name="lot" class="form-control" required [ngClass]="{'is-invalid': form.submitted && lot.invalid, 'select-placeholder' : (lot.value === null && !lot.dirty)}" />
          </div>

          <div class="form-row">
            <div class="col-md-7">
              <label for="volume">{{ 'page.declarations.synthese.modal.saisirConditionnement.volume' | translate }} {{ campagne }} ({{ 'page.declarations.synthese.modal.saisirConditionnement.hL' | translate }})</label>
              <input type="number" lang="en" formControlName="volume" id="volume" name="volume" class="form-control" required [ngClass]="{'is-invalid': form.submitted && volume.invalid, 'select-placeholder' : (volume.value === null && !volume.dirty)}" />
              <div class="alert-information" *ngIf="displayAlertMillesime" translate>
                <fa-icon icon="exclamation"></fa-icon>
                page.declarations.synthese.modal.saisirConditionnement.alertMillesime
              </div>
            </div>
            <div class="col-md-5"><span class="volumeMax">({{ 'page.declarations.synthese.modal.saisirConditionnement.max' | translate }} : {{ stockCourant.stock | number:'1.2-2' }} {{ 'page.declarations.synthese.modal.saisirConditionnement.hL' | translate }})</span></div>
          </div>
          <hr />
        </div>

        <div class="col">
          <!-- Formulaire assemblages -->
          <div formArrayName="assemblages">
            <div *ngFor="let assemblage of getAssemblages(); let i = index" [formGroupName]="i">
              <div class="form-row" *ngIf="i == 0">
                <div class="form-group col-md-4"><label>{{ 'page.declarations.synthese.modal.saisirConditionnement.annee' | translate }}</label></div>
                <div class="form-group col-md-8"><label>{{ 'page.declarations.synthese.modal.saisirConditionnement.volume' | translate }} ({{ 'page.declarations.synthese.modal.saisirConditionnement.hL' | translate }})</label></div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-4">
                  <select formControlName="annee" class="form-control" required [ngClass]="{'is-invalid': form.submitted && formSaisieConditionnement.get('assemblages.' + i + '.annee').invalid, 'select-placeholder' : (formSaisieConditionnement.get('assemblages.' + i + '.annee').value === null && !formSaisieConditionnement.get('assemblages.' + i + '.annee').dirty)}">
                    <option [ngValue]="null" hidden selected>{{ 'page.declarations.synthese.modal.saisirConditionnement.annee' | translate }}</option>
                    <option *ngFor="let stock of stocksProduit" [ngValue]="stock">{{ stock.annee }}</option>
                  </select>
                </div>

                <div class="form-group col-md-4">
                  <input type="number" lang="en" formControlName="volume" class="form-control" [readonly]="formSaisieConditionnement.get('assemblages.' + i + '.annee').value == null" required [ngClass]="{'is-invalid': form.submitted && formSaisieConditionnement.get('assemblages.' + i + '.volume').invalid, 'select-placeholder' : (formSaisieConditionnement.get('assemblages.' + i + '.volume').value === null && !formSaisieConditionnement.get('assemblages.' + i + '.volume').dirty)}" />
                </div>

                <div class="form-group col-md-3">
                  <span class="volumeMaxRow" *ngIf="formSaisieConditionnement.get('assemblages.' + i + '.annee').value">
                    ({{ 'page.declarations.synthese.modal.saisirConditionnement.max' | translate }} : {{ formSaisieConditionnement.get('assemblages.' + i + '.annee')?.value?.stock }} {{ 'page.declarations.synthese.modal.saisirConditionnement.hL' | translate }})
                  </span>
                </div>

                <div class="form-group col-md-1">
                  <fa-icon class="pointer deleteIcon" icon="trash" title="{{ 'page.declarations.synthese.modal.saisirConditionnement.supprimerAssemblage' | translate }}" (click)="remove('assemblages', i)"></fa-icon>
                </div>
              </div>
            </div>
          </div>

          <div class="alert-information" *ngIf="stocksProduit.length == 0" translate>
            <fa-icon icon="exclamation"></fa-icon>
            page.declarations.synthese.modal.saisirConditionnement.alertAucunStock
          </div>

          <button class="lienAffichage" type="button" (click)="add('assemblages')" [disabled]="stocksProduit.length == 0">
            <fa-icon icon="plus"></fa-icon> {{ 'page.declarations.synthese.modal.saisirConditionnement.creerAssemblage' | translate }}
          </button>
          <hr />
          <!-- Fin formulaire assemblages -->

          <div class="form-group">
            <label for="volumeTotal">{{ 'page.declarations.synthese.modal.saisirConditionnement.volumeTotal' | translate }} ({{ 'page.declarations.synthese.modal.saisirConditionnement.hL' | translate }})</label>
            <input type="number" lang="en" [ngModelOptions]="{standalone: true}" [ngModel]="volumeTotalValue" id="volumeTotal" name="volumeTotal" class="form-control" disabled/>
          </div>

          <div class="form-group">
            <label for="entreposage">{{ 'page.declarations.synthese.modal.saisirConditionnement.entreposage' | translate }}</label>
            <select formControlName="entreposage" id="entreposage" name="entreprosage" class="form-control" [ngClass]="{'is-invalid': form.submitted && entreposage.invalid, 'select-placeholder' : (entreposage.value === null && !entreposage.dirty)}">
              <option [ngValue]="null" hidden selected>{{ 'page.declarations.synthese.modal.changementDenomination.listeSites' | translate }}</option>
              <option *ngFor="let siteOperateur of sitesOperateur" [ngValue]="siteOperateur">{{ siteOperateur?.adresse }}</option>
            </select>
          </div>
          <hr />

          <!-- Formulaire Contenants -->
          <div formArrayName="contenants" [ngClass]="{'is-invalid': form.submitted && formSaisieConditionnement.get('assemblages').invalid, 'select-placeholder' : (formSaisieConditionnement.get('assemblages').value === null && !formSaisieConditionnement.get('assemblages').dirty)}">
            <div *ngFor="let contenant of getContenants(); let i = index" [formGroupName]="i">
              <div class="form-row" *ngIf="i == 0">
                <div class="form-group col-md-5"><label>{{ 'page.declarations.synthese.modal.saisirConditionnement.type' | translate }}</label></div>
                <div class="form-group col-md-3"><label>{{ 'page.declarations.synthese.modal.saisirConditionnement.nombre' | translate }}</label></div>
                <div class="form-group col-md-3"><label>{{ 'page.declarations.synthese.modal.saisirConditionnement.volume' | translate }} ({{ 'page.declarations.synthese.modal.saisirConditionnement.hL' | translate }})</label></div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-5">
                  <select formControlName="type" class="form-control" (change)="onTypeChange(i)" required [ngClass]="{'is-invalid': form.submitted && formSaisieConditionnement.get('contenants.' + i + '.type').invalid, 'select-placeholder' : (formSaisieConditionnement.get('contenants.' + i + '.type').value === null && !formSaisieConditionnement.get('contenants.' + i + '.type').dirty)}">
                    <option [ngValue]="null" hidden selected>{{ 'page.declarations.synthese.modal.saisirConditionnement.choisirType' | translate }}</option>
                    <option *ngFor="let refContenant of referentiel.refContenantList" [ngValue]="refContenant">{{ refContenant.libelleContenant }}</option>
                  </select>
                </div>

                <div class="form-group col-md-3">
                  <input type="number" lang="en" formControlName="nombre" class="form-control" [readonly]="formSaisieConditionnement.get('contenants.' + i + '.type').value == null" required [ngClass]="{'is-invalid': form.submitted && formSaisieConditionnement.get('contenants.' + i + '.nombre').invalid}" />
                </div>

                <div class="form-group col-md-3">
                  <input type="number" lang="en" formControlName="volume" class="form-control" [readonly]="formSaisieConditionnement.get('contenants.' + i + '.type').value == null" required [ngClass]="{'is-invalid': form.submitted && formSaisieConditionnement.get('contenants.' + i + '.volume').invalid}" />
                </div>

                <div class="form-group col-md-1">
                  <fa-icon class="pointer deleteIcon" icon="trash" title="{{ 'page.declarations.synthese.modal.saisirConditionnement.supprimerContenant' | translate }}" (click)="remove('contenants', i)"></fa-icon>
                </div>
              </div>
            </div>
          </div>

          <button class="lienAffichage" type="button" (click)="add('contenants')">
            <fa-icon icon="plus"></fa-icon> {{ 'page.declarations.synthese.modal.saisirConditionnement.creerContenant' | translate }}
          </button>
        </div>
      </div>

      <!-- Boutons d'action du composant -->
      <div class="actions-bar">
        <button type="button" (click)="close()" class="btn btn-light" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary" translate>label.valider</button>
      </div>
    </form>
  </div>
</div>
