import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { find, some } from 'lodash';
import { forkJoin } from 'rxjs';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { SiteActivite } from 'src/app/data/declaration/models/site-activite.model';
import { Site } from 'src/app/data/declaration/models/site.model';
import { OperateurSitesService } from 'src/app/data/declaration/services/operateur-sites/operateur-sites.service';
import { SitesActivitesService } from 'src/app/data/declaration/services/sites-activites/sites-activites.service';
import { Activite } from 'src/app/data/habilitation/models/activite.model';
import { Cahier } from 'src/app/data/habilitation/models/cahier.model';
import { Habilitation } from 'src/app/data/habilitation/models/habilitation.model';
import { ActivitesService } from 'src/app/data/habilitation/services/activites/activites.service';
import { CahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import { HabilitationsService } from 'src/app/data/habilitation/services/habilitations/habilitations.service';
import { OperateurInformationsDomaine } from 'src/app/data/intervenant/models/operateur-informations-domaine.model';
import { Operateur } from 'src/app/data/intervenant/models/operateur.model';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';

@Component({
  selector: 'app-detail-operateur',
  templateUrl: './detail-operateur.component.html',
  styleUrls: ['./detail-operateur.component.scss']
})
export class DetailOperateurComponent implements OnInit {

  @Input() public idOperateur: number;

  public sites: Site[];
  public operateur: Operateur;
  public infosDomaine: OperateurInformationsDomaine[];
  public sitesActivites: SiteActivite[];
  cahiersFiltered: Cahier[];
  cahiers: Cahier[];
  activites: Activite[];
  activitesFiltered: Activite[];
  habilitations: Habilitation[];

  constructor(    
    private readonly sitesService: OperateurSitesService,
    private readonly operateurService: OperateursService,
    private readonly habilitationsService: HabilitationsService,
    public readonly cahiersService: CahiersService,
    private readonly sitesActivitesService: SitesActivitesService,
    protected readonly activitesService: ActivitesService,
    private readonly loaderService: AdvBootstrapLoaderService,
    public readonly modal: NgbActiveModal
  ) { }

  ngOnInit() {
    setTimeout(() => this.loadData());
  }

  loadData() {
    forkJoin(
      this.operateurService.getOperateur(this.idOperateur),
      this.operateurService.getInformationsDomaine(this.idOperateur),
      this.sitesService.getSitesOperateur(this.idOperateur),
      this.sitesActivitesService.getSitesAcitivtes(SessionContext.get('idDomaine')),
      this.cahiersService.getCahiers(),
      this.habilitationsService.getOperateurHabilitations(this.idOperateur),
      this.activitesService.getActivites()
    ).pipe(
      this.loaderService.operator()
    ).subscribe(([operateur, infosDomaine, sites, sitesActivites, cahiers, habilitations, activites]) => {
      this.operateur = operateur;
      this.infosDomaine = infosDomaine;
      this.sites = sites;
      this.sitesActivites = sitesActivites;
      this.cahiers = cahiers;     
      this.habilitations = habilitations ;
      this.activites = activites;

      this.filterCahiers();
      this.filtersActivites();
    });
  }

  filterCahiers() {
    this.cahiersFiltered = this.cahiers.filter(cahier => {
      return some(this.habilitations, (habilitation: Habilitation) => {
        return (habilitation.cahier.id === cahier.id);
      });
    });
  }

  filtersActivites(){
    this.activitesFiltered = this.activites.filter(activite => {
      return some(this.habilitations, (habilitation: Habilitation) => {
        return (habilitation.activite.id === activite.id);
      });
    });
  }

  getHabilitation(cahier: Cahier, activite: Activite): Habilitation {
    return find(this.habilitations, (habilitation: Habilitation) => {
      return (habilitation.cahier.id === cahier.id) && (habilitation.activite.id === activite.id);
    });
  }
  
  cahierHasActivite(cahier: Cahier, activite: Activite) {
    return some(cahier.activites, (a: Activite) => a.id === activite.id);
  }

   /** Ferme la fenête */
   public onClose(): void {
    this.modal.dismiss();
  }

}
