<div class="anpp ">
  <div class="content">
    <div [ngClass]="{'content-panel': creation}" *ngIf="entreprise">
      <div class="title-bar" *ngIf="creation">
        <h4 >
          {{ title | translate}}
        </h4>
      </div>

      <form [formGroup]="formGroup" *ngIf="formGroup" #form="ngForm" (submit)="submit()" novalidate>

        <div class="m-3">

          <div class="row">

            <div class="form-group col-lg-6">
              <label for="raisonsociale" translate>page.entreprises.form.label.raisonsociale</label>
              <input type="text" formControlName="raisonsociale"
                [ngClass]="{'is-invalid': form.submitted && raisonsociale.invalid}" class="form-control" />
              <ng-container *ngIf="form.submitted">
                <div *ngIf="raisonsociale.errors?.required" class="form-error" translate>label.obligatoire</div>
              </ng-container>
            </div>
            <div class="form-group col-lg-3">
              <label for="estadherent" translate>page.entreprises.form.label.estadherent</label>
              <div>
                <label>
                  <input type="radio" class="mr-1" formControlName="estadherent" (click)="numadherentValidatorsRequired()" [value]="true" />
                  <span>Oui</span>
                </label>
                <label>
                  <input type="radio" class="ml-5 mr-1" formControlName="estadherent" (click)="numadherentValidatorsRequired()" [value]="false" />
                  <span>Non</span>
                </label>
              </div>
            </div>
            <div class="form-group col-lg-3">
              <label for="numadherent" translate>page.entreprises.form.label.numadherent</label>

              <input type="text" id="numadherent" formControlName="numadherent"
                [ngClass]="{'is-invalid': form.submitted && numadherent.invalid }" class="form-control" />

              <ng-container *ngIf="form.submitted">
                <div *ngIf="numadherent.errors?.required" class="form-error" translate>label.obligatoire</div>
              </ng-container>
            </div>

          </div>

          <div class="form-group">
            <label for="cotisationAJour" translate>page.entreprises.form.label.cotisationAJour</label>

            <div class="form-control">
              <label>
                <input type="radio" class="mr-1" formControlName="cotisationAJour" [value]="true" />
                <span>Oui</span>
              </label>
              <label>
                <input type="radio" class="ml-5 mr-1" formControlName="cotisationAJour" [value]="false" />
                <span>Non</span>
              </label>
            </div>
            <ng-container *ngIf="form.submitted">
              <div *ngIf="cotisationAJour.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>

          <div class="row">
            <div class="form-group col-lg-6">
              <label for="dateAdhesion" translate>page.entreprises.form.label.dateAdhesion</label>
              <div class="input-group">
                <input id="dateAdhesion" ngbDatepicker #dateAdhesion="ngbDatepicker" formControlName="dateAdhesion"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': form.submitted && formGroup.get('dateAdhesion').invalid }" />
                <div class="input-group-append">
                  <button type="button" (click)="dateAdhesion.toggle()" class="btn btn-secondary">
                    <fa-icon icon="calendar-alt"></fa-icon>
                  </button>
                </div>
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label for="dateDemission" translate>page.entreprises.form.label.dateDemission</label>
              <div class="input-group">
                <input id="dateDemission" ngbDatepicker #dateDemission="ngbDatepicker" formControlName="dateDemission"
                  class="form-control" />
                <div class="input-group-append">
                  <button type="button" (click)="dateDemission.toggle()" class="btn btn-secondary">
                    <fa-icon icon="calendar-alt"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
          <div class="form-group col-lg-4">
            <label for="estMorale" translate>page.entreprises.form.label.estMorale</label>

            <div>
              <input type="radio" class="mr-1" id="oui" formControlName="estMorale" [value]="true" />
              <label for="oui">Oui</label>
              <input type="radio" class="ml-5 mr-1" id="non" formControlName="estMorale" [value]="false" />
              <label for="non">Non</label>
            </div>
          </div>
          <div class="form-group col-lg-8">
            <label for="refStructure" translate>page.entreprises.form.label.refStrusture</label>

            <select id="refStructure" formControlName="refStructure" class="form-control" [ngClass]="{'is-invalid': form.submitted && refStructure.invalid}">
              <option *ngFor="let refStructure of refStructures" [ngValue]="refStructure">{{refStructure.libelle}}
              </option>
            </select>

            <ng-container *ngIf="form.submitted">
              <div *ngIf="refStructure.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
        </div>
          <div class="row">
            <div class="form-group col-lg-6">
              <label for="siret" translate>page.entreprises.form.label.siret</label>

              <input type="text" id="siret" formControlName="siret"
                [ngClass]="{'is-invalid': form.submitted && siret.invalid}" class="form-control" />
                
                <ng-container *ngIf="form.submitted">
                  <div *ngIf="siret.errors?.pattern" class="form-error" translate>label.format-incorrect</div>
                </ng-container>

            </div>
            <div class="form-group col-lg-6">
              <label for="numeroTVA" translate>page.entreprises.form.label.numeroTVA</label>

              <input type="text" id="numeroTVA" formControlName="numeroTVA" class="form-control"
                [ngClass]="{'is-invalid': form.submitted && numeroTVA.invalid}" />

            </div>
          </div>
          <div class="form-group">
            <label for="adresse" translate>page.entreprises.form.label.adresse</label>

            <input type="text" id="adresse" formControlName="adresse"
              [ngClass]="{'is-invalid': form.submitted && adresse.invalid}" class="form-control" />

            <ng-container *ngIf="form.submitted">
              <div *ngIf="adresse.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="row">
            <div class="form-group col-lg-2">
              <label for="codePostal" translate>page.entreprises.form.label.codePostal</label>
              <input (keyup)="changeValueCodePostal($event)" type="text" id="codePostal" formControlName="codePostal"
                [ngClass]="{'is-invalid': form.submitted && codePostal.invalid || (communes && communes.length === 0)}" class="form-control" />
              <ng-container *ngIf="form.submitted">
                <div *ngIf="codePostal.errors?.required" class="form-error" translate>label.obligatoire</div>   
              </ng-container>
              <ng-container >
                <div *ngIf="communes && communes.length === 0" class="form-error" translate>label.aucune-commune</div>       
              </ng-container>
            </div>
            <div class="form-group col-lg-6">
              <label for="commune" translate>page.entreprises.form.label.commune</label>

              <select formControlName="commune" class="form-control"
                [ngClass]="{'is-invalid': form.submitted && commune.invalid || (communes && communes.length === 0)}">

                <option *ngFor="let commune of communes" [ngValue]="commune">{{commune.nom}}</option>
              </select>

              <ng-container *ngIf="form.submitted">
                <div *ngIf="commune.errors?.required" class="form-error" translate>label.obligatoire</div>
              </ng-container>
              
            </div>
          <div class="form-group col-lg-4">
            <label for="departement" translate>page.entreprises.form.label.departement</label>

            <div class="form-control"><span *ngIf="departement">{{ departement.code }} - {{ departement.nom }}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-2">
            <label for="telephone" translate>page.entreprises.form.label.telephone</label>

            <input type="text" id="telephone" formControlName="telephone"
              [ngClass]="{'is-invalid': form.submitted && telephone.invalid}" class="form-control" />
            <ng-container *ngIf="form.submitted">
              <div *ngIf="telephone.errors?.pattern" class="form-error" translate>label.format-tel-incorrect</div>
            </ng-container>
          </div>
          <div class="form-group col-lg-10">
            <label for="mail" translate>page.entreprises.form.label.mail</label>

            <input type="text" id="mail" formControlName="mail"
              [ngClass]="{'is-invalid': form.submitted && mail.invalid}" class="form-control" />
            <ng-container *ngIf="form.submitted">
              <div *ngIf="mail.errors?.pattern" class="form-error" translate>label.format-incorrect</div>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-6">
            <label for="refSousType" translate>page.entreprises.form.label.refSousType</label>

            <select id="refSousType" formControlName="refSousType" (change)="numeroReconnaissanceValidatorsRequired();entrepriseLieeValidatorsRequired()"  class="form-control" [ngClass]="{'is-invalid': form.submitted && refSousType.invalid}" >
              <option *ngFor="let refsousType of refSousTypes"[ngValue]="refsousType">{{refsousType.libelle}}</option>
            </select>

            <ng-container *ngIf="form.submitted">
              <div *ngIf="refSousType.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group col-lg-6">
            <label for="numeroReconnaissance" translate>page.entreprises.form.label.numeroReconnaissance</label>

            <input type="text" id="numeroReconnaissance" formControlName="numeroReconnaissance"
              [ngClass]="{'is-invalid': form.submitted && numeroReconnaissance.invalid}" class="form-control" />

            <ng-container *ngIf="form.submitted">
              <div *ngIf="numeroReconnaissance.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
        </div>
          <div class="form-group">
            <label for="entrepriseLiee" translate>page.entreprises.form.label.entreprise</label>

            <select id="entrepriseLiee" formControlName="entrepriseLiee" class="form-control" (change)="entrepriseLieeValidatorsRequired()"
            [ngClass]="{'is-invalid': form.submitted && EntrepriseLieeRequise }">
              <option [ngValue]="undefined" ></option>
              <option *ngFor="let entreprise of entreprises" [ngValue]="entreprise">{{entreprise.raisonSociale}}
              </option>
            </select>

            <ng-container *ngIf="form.submitted">
              <div *ngIf="EntrepriseLieeRequise" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="row">
          <div class="form-group col-lg-4">
            <label for="cultureBio" translate>page.entreprises.form.label.cultureBio</label>

            <div class="form-control">
              <label>
                <input type="radio" class="mr-1" formControlName="cultureBio" [value]="true" />
                <span>Oui</span>
              </label>
              <label>
                <input type="radio" class="ml-5 mr-1" formControlName="cultureBio" [value]="false" />
                <span>Non</span>
              </label>
            </div>

            <ng-container *ngIf="form.submitted">
              <div *ngIf="cultureBio.errors?.required" class="form-error" translate>label.obligatoire</div>
            </ng-container>
          </div>
          <div class="form-group col-lg-4">
            <label for="ecoresponsable" translate>page.entreprises.form.label.ecoresponsable</label>

            <div class="form-control">
              <label>
                <input type="radio" class="mr-1" formControlName="ecoresponsable" [value]="true" />
                <span>Oui</span>
              </label>
              <label>
                <input type="radio" class="ml-5 mr-1" formControlName="ecoresponsable"  [value]="false" />
                <span>Non</span>
              </label>
            </div>

            
          </div>
          <div class="form-group col-lg-4">
            <label for="codeComptable" translate>page.entreprises.form.label.codeComptable</label>
            <input type="text"  id="codeComptable" (change)="changeValueCodeComptable($event)"
              formControlName="codeComptable" [ngClass]="{'is-invalid': codeComptableExiste}" class="form-control" />
            <ng-container *ngIf="codeComptableExiste">
              <div class="form-error" translate>label.code-comptable-existe</div>
            </ng-container>
          </div>
        </div>
          <div class="form-group">
            <label for="commentaire" translate>page.entreprises.form.label.commentaire</label>

            <textarea rows="2" id="commentaire" formControlName="commentaire"
              [ngClass]="{'is-invalid': form.submitted && commentaire.invalid}" class="form-control" >
            </textarea>          

          </div>

        </div>

        <div class="actions-bar" fxLayoutAlign="space-between">
          <button class="btn  btn-secondary btnprimary " (click)="onClose()" translate>label.retour</button>

          <button type="submit" class="btn btn-secondary btnprimary" translate>{{!creation
            ? 'label.suivant': 'label.valider'}}</button>
        </div>
      </form>




    </div>
  </div>
</div>