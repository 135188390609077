import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {College} from '../../../../../../../data/commission/models/college.model';
import {Cahier} from '../../../../../../../data/habilitation/models/cahier.model';
import {forkJoin} from 'rxjs';
import {SessionContext} from '../../../../../../../core/services/config/app.settings';
import {CahiersService} from '../../../../../../../data/commission/services/cahiers/cahiers.service';
import {CollegeService} from '../../../../../../../data/commission/services/college/college.service';
import {OrganismesService} from '../../../../../../../data/intervenant/services/organismes/organismes.service';
import {Operateur} from '../../../../../../../data/intervenant/models/operateur.model';
import {OperateursService} from '../../../../../../../data/intervenant/services/operateurs/operateurs.service';
import {ComQualif} from '../../../../../../../data/commission/models/com-qualif.model';
import {JuresService} from '../../../../../../../data/commission/services/jures/jures.service';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-add-edit-qualification',
  templateUrl: './add-edit-qualification.component.html',
  styleUrls: ['./add-edit-qualification.component.scss']
})
export class AddEditQualificationComponent implements OnInit {
  formQualification: FormGroup;
  comQualifAUpdate: ComQualif;
  pageTitle: string;
  qualification: ComQualif;
  idQualif: number;
  operateurs: Operateur[];
  operateurSearch: Operateur;
  refColleges: College[];
  cdcs: Cahier[];
  idOrganisme: number;
  idJure: number;
  idCdc: number;
  cahiersPossedes: Cahier[];

  get cdc() {
    return this.formQualification.get('cdc');
  }

  get college() {
    return this.formQualification.get('college');
  }

  get operateur() {
    return this.formQualification.get('operateur');
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly modal: NgbActiveModal,
    private readonly cahierService: CahiersService,
    private readonly collegeService: CollegeService,
    private readonly operateurService: OperateursService,
    private readonly jureService: JuresService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.idOrganisme = SessionContext.get('idOrganisme');
    if (this.idQualif) {
      this.jureService.getQualificationById(this.idQualif).subscribe(
        (data) => {
          // this.initForm();
          this.translate.get('page.admin.jure.qualifications.titleUpdate').subscribe((res: string) => {
            this.pageTitle = res;
          });
          this.idCdc = data.idCahier;
          this.loadData(data);
        },
        error => console.error('Erreur lors du chargement de la qualification', error)
      );
    } else {
      // Cas d'ajout d'une nouvelle qualification
      // this.initForm();
      this.translate.get('page.admin.jure.qualifications.titleAdd').subscribe((res: string) => {
        this.pageTitle = res;
      });
      this.loadData();
    }
  }

  private loadData(qualif?: ComQualif): void {
    forkJoin(
      this.cahierService.getCahiers(),
      this.collegeService.getColleges(),
    //  this.operateurService.getOperateur(qualif.idsOperateurs[0]),
     // this.organismeService.getOrganisme(SessionContext.get('idOrganisme')),
    ).subscribe(([cahiers, colleges]) => {
      this.cdcs = this.idQualif ? cahiers :
        cahiers.filter(cahier => !this.cahiersPossedes.some(cahierPossede => cahierPossede.id === cahier.id));
      this.refColleges = colleges;
      if(qualif && qualif.operateurs.length > 0){
        this.operateurSearch = qualif.operateurs[0];
      }
        
      if (qualif) {
        this.initForm(qualif);
      } else {
        this.initForm();
      }
    });
  }

  private initForm(qualif?: ComQualif) {
    this.formQualification = this.fb.group({
      college: [qualif ? this.refColleges.find(college => college.id === qualif.colleges[0].id) : null, Validators.required],
      cdc: {
        value: qualif ? this.cdcs.find(cahier => cahier.id === qualif.idCahier) : null,
        disabled: qualif
      },
      dateFormationIni: qualif ? this.toNgbDateStruct(qualif.dateFormationInit) : null,
      dateFormationDer: [qualif && qualif.dateFormationDer ? this.toNgbDateStruct(qualif.dateFormationDer) : null],
      operateur: [(qualif && qualif.idsOperateurs.length > 0) ?
        this.operateurSearch : null],
    });
  }

  private toNgbDateStruct(date: moment.Moment | string | null): NgbDateStruct | null {
    if (!date) {
      return null; // Retourne null si la date est null ou non définie
    }
    const momentDate = moment.isMoment(date) ? date : moment(date);
    return {year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date()};
  }

  onSubmit() {
    if (this.formQualification.valid) {
      const formValue = this.formQualification.value;
      const comQualif = this.idQualif ? this.comQualifAUpdate : new ComQualif();

      comQualif.idJure = Number(this.idJure);
      comQualif.idCahier = this.idQualif ? this.idCdc : formValue.cdc.id;
      comQualif.colleges = [formValue.college];
      if(this.operateurSearch)
      {
        comQualif.idsOperateurs = [this.operateurSearch.id];
      }
      else
        comQualif.idsOperateurs = formValue.operateur ? [formValue.operateur.id] : [];

      comQualif.dateFormationInit = formValue.dateFormationIni ?
        moment([formValue.dateFormationIni.year, formValue.dateFormationIni.month - 1, formValue.dateFormationIni.day]) :
        null;

      comQualif.dateFormationDer = formValue.dateFormationDer ?
        moment([formValue.dateFormationDer.year, formValue.dateFormationDer.month - 1, formValue.dateFormationDer.day]) :
        null;

      const action = this.idQualif ?
        this.jureService.updateQualifById(this.idQualif, comQualif) :
        this.jureService.addQualification(comQualif);

      action.subscribe(
        () => {
          console.log(`Qualification ${this.idQualif ? 'updated' : 'added'} successfully`);
          this.modal.close('qualification_added');
          window.location.reload();
        },
        error => {
          console.error(`Error ${this.idQualif ? 'updating' : 'adding'} qualification`, error);
        }
      );

    }
  }

  onClose() {
    this.modal.dismiss();
  }

  selectOperateur(newItem: Operateur) {
    this.formQualification.patchValue({'operateur':newItem});
    this.operateurSearch = newItem;
  }
}
