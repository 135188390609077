export class UnexpectedArgument extends Error {
  constructor(message: string, arg: any) {

    let argStr: string;
    if (typeof arg === 'object') {
      try {
        argStr = JSON.stringify(arg);
      } catch (err) {
        try {
          argStr = arg.toString();
        } catch (err) {
          argStr = arg as string;
        }
      }
      super(message + ' : ' + argStr);
    } else {
      super(message + ' : ' + arg);
    }

    // Maintenir dans la pile une trace adéquate de l'endroit où l'erreur a été déclenchée (disponible seulement en V8)
    if ((Error as any).hasOwnProperty('captureStackTrace')) {
      (Error as any).captureStackTrace(this, UnexpectedArgument);
    }
    this.name = 'UnexpectedArgument';

    Object.setPrototypeOf(this, UnexpectedArgument.prototype);
  }
}
