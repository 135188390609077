import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Domaine } from '../../models/domaine.model';
import { DomaineSpec } from '../../models/domaine-spec.model';

@Injectable({
  providedIn: 'root'
})
export class DomainesService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
  ) { }


  getDomaines(): Observable<Domaine[]> {
    return this.http.get<object[]>(`/api/intervenant/private/domaines`)
      .pipe(
        map(domaines => this.mapper.deserializeArray(domaines, Domaine))
      );
  }

  getSpecs(idDomaine: number): Observable<DomaineSpec[]> {
    return this.http.get<object[]>(`/api/intervenant/private/domaines/${idDomaine}/specs`)
      .pipe(
        map(specs => this.mapper.deserializeArray(specs, DomaineSpec)),
        map(specs => {
          specs.push(Object.assign(new DomaineSpec(), { code: 'SIRET', libelle: 'SIRET' }));
          return specs;
        })
      );
  }
}
